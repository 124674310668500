import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { FakeButtonLink } from '../../components/FakeLink';
import { Table } from '../../components/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { mapUserIdToStatementActor } from '../../constants/payment';
import { locations } from '../../routes/locations';
import { mastercardSelector } from '../../redux/modules/mastercard/mastercard.selectors';
import { MastercardSessionStatus } from '../../types/mastercard';
import { fetchMastercardSessions } from '../../redux/modules/mastercard/mastercard.actions';

export function MastercardSessionsTab() {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id' },
    {
      field: 'orderId',
      headerName: 'Order ID',
      width: 100,
    },
    {
      field: 'transactionId',
      headerName: 'Transaction ID',
      width: 100,
    },
    {
      field: 'userId',
      headerName: 'User Id',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        const staticStatementActor = mapUserIdToStatementActor[params.value];

        if (staticStatementActor) {
          return staticStatementActor;
        }

        return (
          <Tooltip title={params.value} followCursor>
            <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.user(params.value)}>
              {params.value}
            </FakeButtonLink>
          </Tooltip>
        );
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 100,
    },
    {
      field: 'currency',
      headerName: 'Currency',
      width: 100,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(MastercardSessionStatus),
      width: 100,
    },
    {
      field: 'sessionId',
      headerName: 'Session ID',
      width: 150,
    },
    {
      field: 'successIndicator',
      headerName: 'Success Indicator',
      width: 150,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: false,
      type: 'dateTime',
      width: 170,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      sortable: false,
      type: 'dateTime',
      width: 170,
    },
  ]);
  const { totalMastercardSessions, mastercardSessions, fetchMastercardSessionsStatus } =
    useSelector(mastercardSelector);

  return (
    <Table
      fetchItems={fetchMastercardSessions}
      rows={mastercardSessions}
      columns={columns.current}
      loading={fetchMastercardSessionsStatus === fetchStatuses.pending}
      rowCount={totalMastercardSessions}
      initialColumnVisibilityModel={{ id: false, metadata: false }}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      toolbarExportProps={{
        excelOptions: { fileName: `statements-${Date.now()}` },
        csvOptions: { fileName: `statements-${Date.now()}` },
      }}
    />
  );
}
