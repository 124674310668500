import { PaginatedRequest } from '../types/api';
import {
  SaveUserFeeDeductionRequest,
  CreateUserFeeDeductionResponse,
  FetchUserFeeDeductionsResponse,
  FetchUserFeeDeductionResponse,
  UpdateUserFeeDeductionRequest,
  UpdateUserFeeDeductionResponse,
  UpdateUserFeeDeductionStatusRequest,
  UpdateUserFeeDeductionStatusResponse,
  FetchUserFeeDeductionStatusOptionsResponse,
} from '../types/userFeeDeduction';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class UserFeeDeductionService extends ApiService {
  pathname = 'admin';

  fetchUserFeeDeductions = async (data: PaginatedRequest) =>
    this.get<FetchUserFeeDeductionsResponse>(`${this.pathname}/user-fee-deduction?${toQueryParams(data)}`);

  fetchUserFeeDeduction = async (id: number) =>
    this.get<FetchUserFeeDeductionResponse>(`${this.pathname}/user-fee-deduction/${id}`);

  deleteUserFeeDeduction = async (id: number) =>
    this.delete<FetchUserFeeDeductionResponse>(`${this.pathname}/user-fee-deduction/${id}`);

  saveUserFeeDeduction = async (userFeeDeduction: SaveUserFeeDeductionRequest) =>
    this.post<CreateUserFeeDeductionResponse, SaveUserFeeDeductionRequest>(
      `${this.pathname}/user-fee-deduction`,
      userFeeDeduction,
    );

  updateUserFeeDeduction = async ({ id, ...userFeeDeduction }: UpdateUserFeeDeductionRequest) =>
    this.put<UpdateUserFeeDeductionResponse, Omit<UpdateUserFeeDeductionRequest, 'id'>>(
      `${this.pathname}/user-fee-deduction/${id}`,
      userFeeDeduction,
    );

  updateUserFeeDeductionStatus = async ({ id, status }: UpdateUserFeeDeductionStatusRequest) =>
    this.put<UpdateUserFeeDeductionStatusResponse, Omit<UpdateUserFeeDeductionStatusRequest, 'id'>>(
      `${this.pathname}/user-fee-deduction/${id}/status`,
      { status },
    );

  fetchUserFeeDeductionStatusOptions = async () =>
    this.get<FetchUserFeeDeductionStatusOptionsResponse>(`${this.pathname}/user-fee-deduction/status/options`);
}

export const userFeeDeductionService = new UserFeeDeductionService();
