import Switch from '@mui/material/Switch';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import PaymentsIcon from '@mui/icons-material/Payments';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import FormControlLabel from '@mui/material/FormControlLabel';
import Tooltip from '@mui/material/Tooltip';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useAppDispatch } from '../../redux/hooks';
import { importExecutionFileFeeBatch } from '../../redux/modules/feeBatch/feeBatch.actions';
import { feeBatchSelector } from '../../redux/modules/feeBatch/feeBatch.selectors';

interface ImportExecutionFileDialogProps {
  open: boolean;
  batchId: string;
  onClose: (isReload: boolean) => void;
}

const fileSizeLimit = 1 * 1024 * 1024;

export function ImportExecutionFileDialog(props: ImportExecutionFileDialogProps) {
  const { open, batchId, onClose } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation('apiError');
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File>();
  const [base, setBase] = useState<'amount' | 'units'>('amount');

  const { importExecutionFileStatus, error } = useSelector(feeBatchSelector);

  useEffect(() => {
    setFile(undefined);
  }, [open]);

  const onImportExecutionFile = async () => {
    if (file && batchId) {
      try {
        const payload = { batchId, file };

        await dispatch(importExecutionFileFeeBatch(payload)).unwrap();

        enqueueSnackbar('Execution file was imported successfully!', { variant: 'success' });
        onClose(true);
      } catch (err: any) {
        enqueueSnackbar(`Import execution file was failed: ${t(err?.message)} ${JSON.stringify(err?.data)}`, {
          variant: 'error',
        });
      }
    }
  };

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newFile = event.target?.files?.[0];

    if (newFile && newFile.size > fileSizeLimit) {
      newFile = undefined;
      enqueueSnackbar('Allowed Size 1 MB!', { variant: 'error' });
    }

    if (newFile) {
      setFile(newFile);
    }
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Import execution file for: {batchId}</DialogTitle>
      <DialogContent>
        <DialogContentText display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          {error && <Alert severity="error">{error.message}</Alert>}
          <Button
            id="ImportExecutionFileButton"
            variant="outlined"
            component="label"
            aria-label="import execution file"
            color="primary"
            startIcon={<UploadIcon />}
          >
            {file ? file.name : 'Select import execution file'}
            <input ref={inputRef} hidden accept="text/csv" type="file" onChange={onChangeFile} />
          </Button>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between" flexDirection="row">
          <Grid item>
            <LoadingButton
              type="button"
              color="primary"
              variant="contained"
              endIcon={<UploadIcon />}
              loading={importExecutionFileStatus === fetchStatuses.pending}
              loadingPosition="end"
              onClick={onImportExecutionFile}
            >
              Import
            </LoadingButton>
            <Button sx={{ ml: 2 }} color="secondary" variant="contained" onClick={() => onClose(false)}>
              Discard
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
