import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { StyledTableCell } from './PermissionsTableElements';

type PermissionsTableActionRowRoleCellProps = {
  action: string;
  resource: string;
  roleName: string;
  checked: boolean;
  onChange: (data: { resource: string; action: string; roleName: string; checked: boolean }) => void;
};

export const PermissionsTableActionRowRoleCell: React.FC<PermissionsTableActionRowRoleCellProps> = React.memo(
  (props) => {
    const { action, resource, roleName, checked, onChange } = props;

    return (
      <StyledTableCell className="normal" align="left">
        <FormControlLabel
          label={action}
          labelPlacement="end"
          control={
            <Checkbox
              size="small"
              sx={{ zIndex: 1 }}
              name={`permission-${resource}`}
              checked={checked}
              onChange={(e, v) => {
                onChange({ resource, action, roleName, checked: v });
              }}
            />
          }
        />
      </StyledTableCell>
    );
  },
);
