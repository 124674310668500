import React from 'react';
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import { satelliteAccountSelector } from '../../redux/modules/satelliteAccount/satelliteAccount.selectors';
import { IndexFundNavList } from './IndexFundNavList';

export function IndexFundNavTab() {
  const { error } = useSelector(satelliteAccountSelector);

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      <IndexFundNavList />
    </>
  );
}
