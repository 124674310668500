import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FieldArray, FormikErrors, FormikProvider, useFormik } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import InputAdornment from '@mui/material/InputAdornment';
import UploadIcon from '@mui/icons-material/Upload';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DecimalNumberField } from '../../components/DecimalNumberField';
import { ImportFundChartDataFileDialog } from '../../components/ImportFundChartDataFileDialog';
import { UploadFileButton } from '../../components/UploadFileButton';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { indexFundFormFields } from '../../forms/fields/formFields';
import { indexFundFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import { fetchAssets } from '../../redux/modules/asset/asset.actions';
import { assetSelector } from '../../redux/modules/asset/asset.selectors';
import { fetchBenchmarks } from '../../redux/modules/benchmark/benchmark.actions';
import { benchmarkSelector } from '../../redux/modules/benchmark/benchmark.selectors';
import { fetchCurrencys } from '../../redux/modules/currency/currency.actions';
import { currencySelector } from '../../redux/modules/currency/currency.selectors';
import { fetchFundGeographicalFocuses } from '../../redux/modules/fundGeographicalFocus/fundGeographicalFocus.actions';
import { fundGeographicalFocusSelector } from '../../redux/modules/fundGeographicalFocus/fundGeographicalFocus.selectors';
import { fetchFundManagers } from '../../redux/modules/fundManager/fundManager.actions';
import { fundManagerSelector } from '../../redux/modules/fundManager/fundManager.selectors';
import { fetchFundAssetClasses } from '../../redux/modules/fundAssetClass/fundAssetClass.actions';
import { fundAssetClassSelector } from '../../redux/modules/fundAssetClass/fundAssetClass.selectors';
import { fetchFundValuationDays } from '../../redux/modules/fundValuationDay/fundValuationDay.actions';
import { fundValuationDaySelector } from '../../redux/modules/fundValuationDay/fundValuationDay.selectors';
import { fetchFundValuationFrequencies } from '../../redux/modules/fundValuationFrequency/fundValuationFrequency.actions';
import { fundValuationFrequencySelector } from '../../redux/modules/fundValuationFrequency/fundValuationFrequency.selectors';
import { indexFundSelector } from '../../redux/modules/indexFund/indexFund.selectors';
import {
  deleteIndexFund,
  fetchIndexFund,
  fetchFundRiskLevelOptions,
  fetchFundCategoryOptions,
  fetchFundStatusOptions,
  fetchFundTypeOptions,
  resetEditIndexFund,
  createIndexFund,
  fetchFundSourceOptions,
  fetchGicsOptions,
} from '../../redux/modules/indexFund/indexFund.actions';
import { locations } from '../../routes/locations';
import {
  FundAttachmentType,
  FundRiskLevel,
  FundStrategy,
  GicsWeight,
  UpdateIndexFundAttachment,
} from '../../types/indexFund';
import { ImportBenchmarkNavDataFileDialog } from '../../components/ImportBenchmarkNavDataFileDialog';
import { ImportFundNavDataFileDialog } from '../../components/ImportFundNavDataFileDialog';
import { UploadEntity } from '../../types/media';
import * as permissions from '../../utils/permissions';
import { ExportNavDataButton } from './ExportNavDataButton';
import { ProtectedContent } from '../../components/ProtectedContent';

export function IndexFund() {
  const dispatch = useAppDispatch();
  const id = Number(useParams().id);
  const navigate = useNavigate();
  const [openImportFund, setOpenImportFund] = useState(false);
  const [openImportBenchmark, setOpenImportBenchmark] = useState(false);
  const [openImportChartData, setOpenImportChartData] = useState(false);
  const {
    fetchStatus,
    editIndexFund,
    deleteStatus,
    createStatus,
    updateStatus,
    fundRiskLevelOptions,
    fundCategoryOptions,
    fundStatusOptions,
    fundTypeOptions,
    fundSourceOptions,
    gicsOptions,
    fetchFundRiskLevelOptionsStatus,
    fetchFundCategoryOptionsStatus,
    fetchFundStatusOptionsStatus,
    fetchFundTypeOptionsStatus,
    fetchFundSourceOptionsStatus,
    fetchGicsOptionsStatus,
    error: indexFundError,
  } = useSelector(indexFundSelector);
  const { fetchStatus: assetFetchStatus, assets, error: assetError } = useSelector(assetSelector);
  const {
    fetchStatus: fundAssetClassFetchStatus,
    fundAssetClasses,
    error: fundAssetClassError,
  } = useSelector(fundAssetClassSelector);
  const {
    fetchStatus: fundManagerFetchStatus,
    fundManagers,
    error: fundManagerError,
  } = useSelector(fundManagerSelector);
  const {
    fetchStatus: fundGeographicalFocusFetchStatus,
    fundGeographicalFocuses,
    error: fundGeographicalFocusError,
  } = useSelector(fundGeographicalFocusSelector);
  const {
    fetchStatus: fundValuationDayFetchStatus,
    fundValuationDays,
    error: fundValuationDayError,
  } = useSelector(fundValuationDaySelector);
  const {
    fetchStatus: fundValuationFrequencyFetchStatus,
    fundValuationFrequencies,
    error: fundValuationFrequencyError,
  } = useSelector(fundValuationFrequencySelector);
  const { fetchStatus: currencyFetchStatus, currencys, error: currencyError } = useSelector(currencySelector);
  const { fetchStatus: benchmarkFetchStatus, benchmarks, error: benchmarkError } = useSelector(benchmarkSelector);
  const { enqueueSnackbar } = useSnackbar();
  const isLoading =
    fetchStatus === fetchStatuses.pending ||
    assetFetchStatus === fetchStatuses.pending ||
    currencyFetchStatus === fetchStatuses.pending ||
    benchmarkFetchStatus === fetchStatuses.pending ||
    fundManagerFetchStatus === fetchStatuses.pending ||
    fundAssetClassFetchStatus === fetchStatuses.pending ||
    fundValuationDayFetchStatus === fetchStatuses.pending ||
    fundValuationFrequencyFetchStatus === fetchStatuses.pending ||
    fundGeographicalFocusFetchStatus === fetchStatuses.pending ||
    fetchFundRiskLevelOptionsStatus === fetchStatuses.pending ||
    fetchFundCategoryOptionsStatus === fetchStatuses.pending ||
    fetchFundStatusOptionsStatus === fetchStatuses.pending ||
    fetchFundTypeOptionsStatus === fetchStatuses.pending ||
    fetchFundSourceOptionsStatus === fetchStatuses.pending ||
    fetchGicsOptionsStatus === fetchStatuses.pending;
  const isDeleteLoading = isLoading || deleteStatus === fetchStatuses.pending;
  const isSaveLoading = isLoading || createStatus === fetchStatuses.pending || updateStatus === fetchStatuses.pending;
  const error =
    indexFundError ||
    currencyError ||
    assetError ||
    benchmarkError ||
    fundAssetClassError ||
    fundManagerError ||
    fundGeographicalFocusError ||
    fundValuationDayError ||
    fundValuationFrequencyError;
  const formik = useFormik({
    initialValues: {
      [indexFundFormFields.bloombergTicker]: '',
      [indexFundFormFields.code]: '',
      [indexFundFormFields.nameEn]: '',
      [indexFundFormFields.nameAr]: '',
      [indexFundFormFields.secondNameEn]: '',
      [indexFundFormFields.secondNameAr]: '',
      [indexFundFormFields.asset]: 0,
      [indexFundFormFields.gics]: [],
      [indexFundFormFields.source]: 0,
      [indexFundFormFields.assetClass]: 0,
      [indexFundFormFields.strategy]: FundStrategy.Active,
      [indexFundFormFields.manager]: 0,
      [indexFundFormFields.objectiveEn]: '',
      [indexFundFormFields.objectiveAr]: '',
      [indexFundFormFields.geographicalFocus]: 0,
      [indexFundFormFields.shariaaComplaint]: true,
      [indexFundFormFields.allowFractions]: true,
      [indexFundFormFields.customDisplay]: true,
      [indexFundFormFields.expenseRatio]: 0,
      [indexFundFormFields.valuationDay]: 0,
      [indexFundFormFields.valuationFrequency]: 0,
      [indexFundFormFields.type]: 0,
      [indexFundFormFields.category]: 0,
      [indexFundFormFields.currency]: 0,
      [indexFundFormFields.status]: 0,
      [indexFundFormFields.netAssetValuePerUnit]: 0,
      [indexFundFormFields.originalNav]: 0,
      [indexFundFormFields.fxRate]: 0,
      [indexFundFormFields.expectedReturn]: 0,
      [indexFundFormFields.assetUnderManagement]: 0,
      [indexFundFormFields.inceptionDate]: new Date(),
      [indexFundFormFields.managementFee]: 0,
      [indexFundFormFields.minimumSubscription]: 0,
      [indexFundFormFields.minimumRedemption]: 0,
      [indexFundFormFields.subscriptionFee]: 0,
      [indexFundFormFields.redemptionFee]: 0,
      [indexFundFormFields.otherFee]: 0,
      [indexFundFormFields.benchmark]: 0,
      [indexFundFormFields.topHoldings]: '',
      [indexFundFormFields.topHoldingsAr]: '',
      [indexFundFormFields.numberHoldings]: 0,
      [indexFundFormFields.riskLevel]: 0,
      [indexFundFormFields.y1mReturn]: 0,
      [indexFundFormFields.y3mReturn]: 0,
      [indexFundFormFields.y5mReturn]: 0,
      [indexFundFormFields.y1mStdev]: 0,
      [indexFundFormFields.y3mStdev]: 0,
      [indexFundFormFields.y5mStdev]: 0,
      [indexFundFormFields.y1mReturnRisk]: 0,
      [indexFundFormFields.y3mReturnRisk]: 0,
      [indexFundFormFields.y5mReturnRisk]: 0,
      [indexFundFormFields.attachments]: [] as UpdateIndexFundAttachment[],
    },
    validationSchema: indexFundFormSchema,
    onSubmit: (formValues) => {
      const gics: GicsWeight[] = formValues.gics.map((g: GicsWeight) => ({ gics: g.gics, weight: +g.weight }));
      dispatch(
        createIndexFund({
          id,
          ...formValues,
          gics,
          expenseRatio: +formValues.expenseRatio,
          netAssetValuePerUnit: +formValues.netAssetValuePerUnit,
          assetUnderManagement: +formValues.assetUnderManagement,
          managementFee: +formValues.managementFee,
          minimumSubscription: +formValues.minimumSubscription,
          minimumRedemption: +formValues.minimumRedemption,
          subscriptionFee: +formValues.subscriptionFee,
          redemptionFee: +formValues.redemptionFee,
          otherFee: +formValues.otherFee,
        }),
      );
    },
  });
  const { handleSubmit, values, handleChange, touched, errors, setValues, setFieldValue } = formik;
  const selectedBenchmark = benchmarks.find((i) => i.id === values[indexFundFormFields.benchmark]);

  useEffect(() => {
    dispatch(fetchCurrencys({ limit: 100, offset: 0 }));
    dispatch(fetchAssets({ limit: 100, offset: 0 }));
    dispatch(fetchFundAssetClasses({ limit: 100, offset: 0 }));
    dispatch(fetchFundManagers({ limit: 100, offset: 0 }));
    dispatch(fetchFundGeographicalFocuses({ limit: 100, offset: 0 }));
    dispatch(fetchFundValuationDays({ limit: 100, offset: 0 }));
    dispatch(fetchFundValuationFrequencies({ limit: 100, offset: 0 }));
    dispatch(fetchBenchmarks({ limit: 100, offset: 0 }));
    dispatch(fetchFundRiskLevelOptions());
    dispatch(fetchFundCategoryOptions());
    dispatch(fetchFundStatusOptions());
    dispatch(fetchFundTypeOptions());
    dispatch(fetchFundSourceOptions());
    dispatch(fetchGicsOptions());

    return () => {
      dispatch(resetEditIndexFund());
    };
  }, []);

  useEffect(() => {
    if (fetchFundRiskLevelOptionsStatus === fetchStatuses.success && !values[indexFundFormFields.riskLevel]) {
      const activeStatus = fundRiskLevelOptions.find((item) => item.name === FundRiskLevel.MEDIUM);

      setFieldValue(indexFundFormFields.riskLevel, activeStatus?.id || 0);
    }
  }, [
    fetchFundRiskLevelOptionsStatus,
    fetchFundCategoryOptionsStatus,
    fetchFundStatusOptionsStatus,
    fetchFundTypeOptionsStatus,
  ]);

  useEffect(() => {
    if (deleteStatus === fetchStatuses.success) {
      enqueueSnackbar('IndexFund deleted!', { variant: 'success' });
      navigate(locations.indexFundList());
    }
    if (createStatus === fetchStatuses.success) {
      if (id) {
        dispatch(fetchIndexFund(id));
      }
      enqueueSnackbar('IndexFund created!', { variant: 'success' });
    }
    if (updateStatus === fetchStatuses.success) {
      enqueueSnackbar('IndexFund updated!', { variant: 'success' });
    }
  }, [deleteStatus, createStatus, updateStatus]);

  useEffect(() => {
    if (id) {
      dispatch(fetchIndexFund(id));
    }
  }, [id]);

  const getDefaultFiles = () => [
    { documentLink: '', type: FundAttachmentType.FACT_SHEET },
    { documentLink: '', type: FundAttachmentType.FACT_SHEET_AR },
  ];

  useEffect(() => {
    if (editIndexFund?.id && editIndexFund?.id !== id) {
      navigate(locations.indexFund(editIndexFund?.id));
      return;
    }

    if (editIndexFund) {
      const existingAttachments = (editIndexFund.attachments || [])?.map((f) => ({
        id: f.id,
        documentLink: f.documentLink,
        type: f.type,
      }));

      getDefaultFiles().forEach((df) => {
        if (!existingAttachments.find((f) => f.type === df.type)) {
          existingAttachments.unshift({ ...df, id: 0 });
        }
      });

      const gics = (editIndexFund as any).gics
        ? (editIndexFund.gics as any).map((g) => ({ weight: g.weight, gics: g.gics.id }))
        : [];

      setValues({
        [indexFundFormFields.bloombergTicker]: editIndexFund?.bloombergTicker || '',
        [indexFundFormFields.code]: editIndexFund?.code || '',
        [indexFundFormFields.nameEn]: editIndexFund?.nameEn || '',
        [indexFundFormFields.nameAr]: editIndexFund?.nameAr || '',
        [indexFundFormFields.secondNameEn]: editIndexFund?.secondNameEn || '',
        [indexFundFormFields.secondNameAr]: editIndexFund?.secondNameAr || '',
        [indexFundFormFields.asset]: editIndexFund?.asset?.id || 0,
        [indexFundFormFields.source]: editIndexFund?.source?.id || 0,
        [indexFundFormFields.strategy]: editIndexFund?.strategy || FundStrategy.Active,
        [indexFundFormFields.assetClass]: editIndexFund?.assetClass?.id || 0,
        [indexFundFormFields.manager]: editIndexFund?.manager?.id || 0,
        [indexFundFormFields.objectiveEn]: editIndexFund?.objectiveEn || '',
        [indexFundFormFields.objectiveAr]: editIndexFund?.objectiveAr || '',
        [indexFundFormFields.geographicalFocus]: editIndexFund?.geographicalFocus?.id || 0,
        [indexFundFormFields.shariaaComplaint]: editIndexFund?.shariaaComplaint || false,
        [indexFundFormFields.allowFractions]: editIndexFund?.allowFractions || false,
        [indexFundFormFields.customDisplay]: editIndexFund?.customDisplay,
        [indexFundFormFields.expenseRatio]: editIndexFund?.expenseRatio,
        [indexFundFormFields.valuationDay]: editIndexFund?.valuationDay?.id || 0,
        [indexFundFormFields.valuationFrequency]: editIndexFund?.valuationFrequency?.id || 0,
        [indexFundFormFields.type]: editIndexFund?.type?.id || 0,
        [indexFundFormFields.category]: editIndexFund?.category?.id || 0,
        [indexFundFormFields.currency]: editIndexFund?.currency?.id || 0,
        [indexFundFormFields.status]: editIndexFund?.status?.id || 0,
        [indexFundFormFields.netAssetValuePerUnit]: editIndexFund?.netAssetValuePerUnit || 0,
        [indexFundFormFields.originalNav]: editIndexFund?.originalNav || 0,
        [indexFundFormFields.fxRate]: editIndexFund?.fxRate || 0,
        [indexFundFormFields.expectedReturn]: editIndexFund?.expectedReturn || 0,
        [indexFundFormFields.assetUnderManagement]: editIndexFund?.assetUnderManagement || 0,
        [indexFundFormFields.inceptionDate]: editIndexFund?.inceptionDate
          ? new Date(editIndexFund?.inceptionDate)
          : new Date(),
        [indexFundFormFields.managementFee]: editIndexFund?.managementFee || 0,
        [indexFundFormFields.minimumSubscription]: editIndexFund?.minimumSubscription || 0,
        [indexFundFormFields.minimumRedemption]: editIndexFund?.minimumRedemption || 0,
        [indexFundFormFields.subscriptionFee]: editIndexFund?.subscriptionFee || 0,
        [indexFundFormFields.redemptionFee]: editIndexFund?.redemptionFee || 0,
        [indexFundFormFields.otherFee]: editIndexFund?.otherFee || 0,
        [indexFundFormFields.benchmark]: editIndexFund?.benchmark?.id || 0,
        [indexFundFormFields.topHoldings]: editIndexFund?.topHoldings || '',
        [indexFundFormFields.topHoldingsAr]: editIndexFund?.topHoldingsAr || '',
        [indexFundFormFields.numberHoldings]: editIndexFund?.numberHoldings || 0,
        [indexFundFormFields.riskLevel]: editIndexFund?.riskLevel?.id || 0,
        [indexFundFormFields.y1mReturn]: editIndexFund?.y1mReturn || 0,
        [indexFundFormFields.y3mReturn]: editIndexFund?.y3mReturn || 0,
        [indexFundFormFields.y5mReturn]: editIndexFund?.y5mReturn || 0,
        [indexFundFormFields.y1mStdev]: editIndexFund?.y1mStdev || 0,
        [indexFundFormFields.y3mStdev]: editIndexFund?.y3mStdev || 0,
        [indexFundFormFields.y5mStdev]: editIndexFund?.y5mStdev || 0,
        [indexFundFormFields.y1mReturnRisk]: editIndexFund?.y1mReturnRisk || 0,
        [indexFundFormFields.y3mReturnRisk]: editIndexFund?.y3mReturnRisk || 0,
        [indexFundFormFields.y5mReturnRisk]: editIndexFund?.y5mReturnRisk || 0,
        [indexFundFormFields.attachments]: existingAttachments.length ? existingAttachments : [],
        [indexFundFormFields.gics]: gics,
      });
    }
  }, [editIndexFund]);

  const onDeleteIndexFund = () => {
    dispatch(deleteIndexFund(id));
  };

  const getFilesKey = (index: number) => `file-${index}`;

  const getGicsKey = (index: number) => `gics-${index}`;

  const renderFileDeleteButton = (item: UpdateIndexFundAttachment, index, arrayHelpers) => (
    <IconButton
      edge="end"
      aria-label="delete"
      onClick={() => {
        arrayHelpers.remove(index);
      }}
    >
      <DeleteIcon />
    </IconButton>
  );

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
            <Typography color="text.primary" component="h4" gutterBottom>
              Index Fund
            </Typography>

            <Grid container spacing={2} paddingX={2}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id={indexFundFormFields.bloombergTicker}
                    name={indexFundFormFields.bloombergTicker}
                    label="Bloomberg Ticker"
                    value={values[indexFundFormFields.bloombergTicker]}
                    onChange={handleChange}
                    error={touched.bloombergTicker && !!errors.bloombergTicker}
                    helperText={touched.bloombergTicker && errors.bloombergTicker}
                    autoComplete="index-fund-bloomberg-ticker"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id={indexFundFormFields.code}
                    name={indexFundFormFields.code}
                    label="Code"
                    value={values[indexFundFormFields.code]}
                    onChange={handleChange}
                    error={touched.code && !!errors.code}
                    helperText={touched.code && errors.code}
                    autoComplete="index-fund-code"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id={indexFundFormFields.nameEn}
                    name={indexFundFormFields.nameEn}
                    label="Name En"
                    value={values[indexFundFormFields.nameEn]}
                    onChange={handleChange}
                    error={touched.nameEn && !!errors.nameEn}
                    helperText={touched.nameEn && errors.nameEn}
                    autoComplete="index-fund-nameEn"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    dir="rtl"
                    required
                    fullWidth
                    id={indexFundFormFields.nameAr}
                    name={indexFundFormFields.nameAr}
                    label="Name Ar"
                    value={values[indexFundFormFields.nameAr]}
                    onChange={handleChange}
                    error={touched.nameAr && !!errors.nameAr}
                    helperText={touched.nameAr && errors.nameAr}
                    autoComplete="index-fund-nameAr"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id={indexFundFormFields.secondNameEn}
                    name={indexFundFormFields.secondNameEn}
                    label="Secondary Name En"
                    value={values[indexFundFormFields.secondNameEn]}
                    onChange={handleChange}
                    error={touched.secondNameEn && !!errors.secondNameEn}
                    helperText={touched.secondNameEn && errors.secondNameEn}
                    autoComplete="index-fund-secondNameEn"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    dir="rtl"
                    required
                    fullWidth
                    id={indexFundFormFields.secondNameAr}
                    name={indexFundFormFields.secondNameAr}
                    label="Secondary Name Ar"
                    value={values[indexFundFormFields.secondNameAr]}
                    onChange={handleChange}
                    error={touched.secondNameAr && !!errors.secondNameAr}
                    helperText={touched.secondNameAr && errors.secondNameAr}
                    autoComplete="index-fund-secondNameAr"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.asset && !!errors.asset}>
                    <InputLabel id="asset-label">Asset 👁</InputLabel>
                    <Select
                      labelId="asset-label"
                      id={indexFundFormFields.asset}
                      name={indexFundFormFields.asset}
                      value={values[indexFundFormFields.asset]}
                      label="Asset"
                      onChange={handleChange}
                    >
                      {assets.map((a) => (
                        <MenuItem key={a.id} value={a.id}>
                          {a.nameEn}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.asset && <FormHelperText>{errors.asset}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.source && !!errors.source}>
                    <InputLabel id="source-label">Source</InputLabel>
                    <Select
                      labelId="source-label"
                      id={indexFundFormFields.source}
                      name={indexFundFormFields.source}
                      value={values[indexFundFormFields.source]}
                      label="Source"
                      onChange={handleChange}
                    >
                      <MenuItem key="source-null" value={0}>
                        NO SOURCE
                      </MenuItem>
                      {fundSourceOptions.map((a) => (
                        <MenuItem key={a.id} value={a.id}>
                          {a.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.source && <FormHelperText>{errors.source}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.assetClass && !!errors.assetClass}>
                    <InputLabel id="asset-class-label">Asset Class 👁</InputLabel>
                    <Select
                      labelId="asset-class-label"
                      id={indexFundFormFields.assetClass}
                      name={indexFundFormFields.assetClass}
                      value={values[indexFundFormFields.assetClass]}
                      label="Asset Class"
                      onChange={handleChange}
                    >
                      {fundAssetClasses.map((a) => (
                        <MenuItem key={a.id} value={a.id}>
                          {a.nameEn}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.assetClass && <FormHelperText>{errors.assetClass}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.strategy && !!errors.strategy}>
                    <InputLabel id="strategy-label">Strategy 👁</InputLabel>
                    <Select
                      labelId="strategy-label"
                      id={indexFundFormFields.strategy}
                      name={indexFundFormFields.strategy}
                      value={values[indexFundFormFields.strategy]}
                      label="strategy"
                      onChange={handleChange}
                    >
                      {Object.keys(FundStrategy).map((key) => (
                        <MenuItem key={key} value={FundStrategy[key]}>
                          {FundStrategy[key]}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.strategy && <FormHelperText>{errors.strategy}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.manager && !!errors.manager}>
                    <InputLabel id="manager-label">Manager</InputLabel>
                    <Select
                      labelId="manager-label"
                      id={indexFundFormFields.manager}
                      name={indexFundFormFields.manager}
                      value={values[indexFundFormFields.manager]}
                      label="Manager"
                      onChange={handleChange}
                    >
                      {fundManagers.map((a) => (
                        <MenuItem key={a.id} value={a.id}>
                          {a.nameEn}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.manager && <FormHelperText>{errors.manager}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id={indexFundFormFields.objectiveEn}
                    name={indexFundFormFields.objectiveEn}
                    label="Objective En 👁"
                    value={values[indexFundFormFields.objectiveEn]}
                    onChange={handleChange}
                    error={touched.objectiveEn && !!errors.objectiveEn}
                    helperText={touched.objectiveEn && errors.objectiveEn}
                    autoComplete="index-fund-objectiveEn"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    dir="rtl"
                    required
                    fullWidth
                    id={indexFundFormFields.objectiveAr}
                    name={indexFundFormFields.objectiveAr}
                    label="Objective Ar 👁"
                    value={values[indexFundFormFields.objectiveAr]}
                    onChange={handleChange}
                    error={touched.objectiveAr && !!errors.objectiveAr}
                    helperText={touched.objectiveAr && errors.objectiveAr}
                    autoComplete="index-fund-objectiveAr"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl
                    fullWidth
                    margin="normal"
                    error={touched.geographicalFocus && !!errors.geographicalFocus}
                  >
                    <InputLabel id="geographicalFocus-label">Geographical Focus 👁</InputLabel>
                    <Select
                      labelId="geographicalFocus-label"
                      id={indexFundFormFields.geographicalFocus}
                      name={indexFundFormFields.geographicalFocus}
                      value={values[indexFundFormFields.geographicalFocus]}
                      label="Geographical Focus"
                      onChange={handleChange}
                    >
                      {fundGeographicalFocuses.map((a) => (
                        <MenuItem key={a.id} value={a.id}>
                          {a.nameEn}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.geographicalFocus && <FormHelperText>{errors.geographicalFocus}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width={192} height={38} sx={{ mt: 2 }} />
                ) : (
                  <FormControlLabel
                    sx={{ mt: 2 }}
                    label="Display For Custom Product"
                    labelPlacement="start"
                    componentsProps={{
                      typography: { textTransform: 'capitalize' },
                    }}
                    name={indexFundFormFields.customDisplay}
                    id={indexFundFormFields.customDisplay}
                    control={
                      <Switch
                        name={indexFundFormFields.customDisplay}
                        checked={values[indexFundFormFields.customDisplay]}
                        onChange={handleChange}
                      />
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width={192} height={38} sx={{ mt: 2 }} />
                ) : (
                  <FormControlLabel
                    sx={{ mt: 2 }}
                    label="Shariaa Complaint 👁"
                    labelPlacement="start"
                    componentsProps={{
                      typography: { textTransform: 'capitalize' },
                    }}
                    name={indexFundFormFields.shariaaComplaint}
                    id={indexFundFormFields.shariaaComplaint}
                    control={
                      <Switch
                        name={indexFundFormFields.shariaaComplaint}
                        checked={values[indexFundFormFields.shariaaComplaint]}
                        onChange={handleChange}
                      />
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width={192} height={38} sx={{ mt: 2 }} />
                ) : (
                  <FormControlLabel
                    sx={{ mt: 2 }}
                    label="Allow Fractions"
                    labelPlacement="start"
                    componentsProps={{
                      typography: { textTransform: 'capitalize' },
                    }}
                    name={indexFundFormFields.allowFractions}
                    id={indexFundFormFields.allowFractions}
                    control={
                      <Switch
                        name={indexFundFormFields.allowFractions}
                        checked={values[indexFundFormFields.allowFractions]}
                        onChange={handleChange}
                      />
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <DecimalNumberField
                    margin="normal"
                    fullWidth
                    type="number"
                    inputProps={{ min: 0, max: 100 }}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    id={indexFundFormFields.expenseRatio}
                    name={indexFundFormFields.expenseRatio}
                    label="Expense Ration"
                    value={values[indexFundFormFields.expenseRatio]}
                    onChange={handleChange}
                    error={touched.expenseRatio && !!errors.expenseRatio}
                    helperText={touched.expenseRatio && errors.expenseRatio}
                    autoComplete="index-fund-expenseRatio"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.valuationDay && !!errors.valuationDay}>
                    <InputLabel id="valuationDay-label">Valuation Day</InputLabel>
                    <Select
                      labelId="valuationDay-label"
                      id={indexFundFormFields.valuationDay}
                      name={indexFundFormFields.valuationDay}
                      value={values[indexFundFormFields.valuationDay]}
                      label="Valuation Day"
                      onChange={handleChange}
                    >
                      {fundValuationDays.map((a) => (
                        <MenuItem key={a.id} value={a.id}>
                          {a.nameEn}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.valuationDay && <FormHelperText>{errors.valuationDay}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl
                    fullWidth
                    margin="normal"
                    error={touched.valuationFrequency && !!errors.valuationFrequency}
                  >
                    <InputLabel id="valuationFrequency-label">Valuation Frequency 👁</InputLabel>
                    <Select
                      labelId="valuationFrequency-label"
                      id={indexFundFormFields.valuationFrequency}
                      name={indexFundFormFields.valuationFrequency}
                      value={values[indexFundFormFields.valuationFrequency]}
                      label="Valuation Frequency"
                      onChange={handleChange}
                    >
                      {fundValuationFrequencies.map((a) => (
                        <MenuItem key={a.id} value={a.id}>
                          {a.nameEn}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.valuationFrequency && <FormHelperText>{errors.valuationFrequency}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.type && !!errors.type}>
                    <InputLabel id="type-label">Type</InputLabel>
                    <Select
                      labelId="type-label"
                      id={indexFundFormFields.type}
                      name={indexFundFormFields.type}
                      value={values[indexFundFormFields.type]}
                      label="Type"
                      onChange={handleChange}
                    >
                      {fundTypeOptions.map((a) => (
                        <MenuItem key={a.id} value={a.id}>
                          {a.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.type && <FormHelperText>{errors.type}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.category && !!errors.category}>
                    <InputLabel id="category-label">Category</InputLabel>
                    <Select
                      labelId="category-label"
                      id={indexFundFormFields.category}
                      name={indexFundFormFields.category}
                      value={values[indexFundFormFields.category]}
                      label="Category"
                      onChange={handleChange}
                    >
                      {fundCategoryOptions.map((a) => (
                        <MenuItem key={a.id} value={a.id}>
                          {a.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.category && <FormHelperText>{errors.category}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.currency && !!errors.currency}>
                    <InputLabel id="currency-label">Currency</InputLabel>
                    <Select
                      labelId="currency-label"
                      id={indexFundFormFields.currency}
                      name={indexFundFormFields.currency}
                      value={values[indexFundFormFields.currency]}
                      label="Currency"
                      onChange={handleChange}
                    >
                      {currencys.map((a) => (
                        <MenuItem key={a.id} value={a.id}>
                          {a.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.currency && <FormHelperText>{errors.currency}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.status && !!errors.status}>
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                      id={indexFundFormFields.status}
                      name={indexFundFormFields.status}
                      value={values[indexFundFormFields.status]}
                      label="Status"
                      onChange={handleChange}
                    >
                      {fundStatusOptions.map((a) => (
                        <MenuItem key={a.id} value={a.id}>
                          {a.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.status && <FormHelperText>{errors.status}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <DecimalNumberField
                    margin="normal"
                    fullWidth
                    type="number"
                    id={indexFundFormFields.originalNav}
                    name={indexFundFormFields.originalNav}
                    label="Original Price"
                    value={values[indexFundFormFields.originalNav]}
                    onChange={handleChange}
                    error={touched.originalNav && !!errors.originalNav}
                    helperText={touched.originalNav && errors.originalNav}
                    autoComplete="index-fund-originalNav"
                    maximumFractionDigits={6}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <DecimalNumberField
                    margin="normal"
                    fullWidth
                    type="number"
                    id={indexFundFormFields.fxRate}
                    name={indexFundFormFields.fxRate}
                    label="FX Rate"
                    value={values[indexFundFormFields.fxRate]}
                    onChange={handleChange}
                    error={touched.fxRate && !!errors.fxRate}
                    helperText={touched.fxRate && errors.fxRate}
                    autoComplete="index-fund-fxRate"
                    disabled
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <DecimalNumberField
                    margin="normal"
                    fullWidth
                    type="number"
                    id={indexFundFormFields.expectedReturn}
                    name={indexFundFormFields.expectedReturn}
                    label="Expected Return"
                    value={values[indexFundFormFields.expectedReturn]}
                    onChange={handleChange}
                    error={touched.fxRate && !!errors.expectedReturn}
                    helperText={touched.expectedReturn && errors.expectedReturn}
                    autoComplete="index-fund-expectedReturn"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <DecimalNumberField
                    margin="normal"
                    fullWidth
                    type="number"
                    id={indexFundFormFields.netAssetValuePerUnit}
                    name={indexFundFormFields.netAssetValuePerUnit}
                    label="Net Asset Value Per Unit"
                    value={values[indexFundFormFields.netAssetValuePerUnit]}
                    onChange={handleChange}
                    error={touched.netAssetValuePerUnit && !!errors.netAssetValuePerUnit}
                    helperText={touched.netAssetValuePerUnit && errors.netAssetValuePerUnit}
                    autoComplete="index-fund-netAssetValuePerUnit"
                    disabled
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <DecimalNumberField
                    margin="normal"
                    fullWidth
                    type="number"
                    id={indexFundFormFields.assetUnderManagement}
                    name={indexFundFormFields.assetUnderManagement}
                    label="Asset Under Management"
                    value={values[indexFundFormFields.assetUnderManagement]}
                    onChange={handleChange}
                    error={touched.assetUnderManagement && !!errors.assetUnderManagement}
                    helperText={touched.assetUnderManagement && errors.assetUnderManagement}
                    autoComplete="index-fund-assetUnderManagement"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="none" error={touched.inceptionDate && !!errors.inceptionDate}>
                    <DatePicker
                      label="inception Date"
                      value={values[indexFundFormFields.inceptionDate]}
                      onChange={(date) => {
                        setFieldValue(indexFundFormFields.inceptionDate, date);
                      }}
                      slotProps={{
                        textField: {
                          name: indexFundFormFields.inceptionDate,
                          id: indexFundFormFields.inceptionDate,
                          label: 'inception Date',
                          fullWidth: true,
                          margin: 'normal',
                        },
                      }}
                    />
                    {touched.inceptionDate && <FormHelperText>{errors.inceptionDate as string}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <DecimalNumberField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    inputProps={{ min: 0, max: 100 }}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    id={indexFundFormFields.managementFee}
                    name={indexFundFormFields.managementFee}
                    label="Management Fee"
                    value={values[indexFundFormFields.managementFee]}
                    onChange={handleChange}
                    error={touched.managementFee && !!errors.managementFee}
                    helperText={touched.managementFee && errors.managementFee}
                    autoComplete="index-fund-managementFee"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <DecimalNumberField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    inputProps={{ min: 0 }}
                    id={indexFundFormFields.minimumSubscription}
                    name={indexFundFormFields.minimumSubscription}
                    label="Minimum Subscription"
                    value={values[indexFundFormFields.minimumSubscription]}
                    onChange={handleChange}
                    error={touched.minimumSubscription && !!errors.minimumSubscription}
                    helperText={touched.minimumSubscription && errors.minimumSubscription}
                    autoComplete="index-fund-minimumSubscription"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <DecimalNumberField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    inputProps={{ min: 0 }}
                    id={indexFundFormFields.minimumRedemption}
                    name={indexFundFormFields.minimumRedemption}
                    label="Minimum Redemption"
                    value={values[indexFundFormFields.minimumRedemption]}
                    onChange={handleChange}
                    error={touched.minimumRedemption && !!errors.minimumRedemption}
                    helperText={touched.minimumRedemption && errors.minimumRedemption}
                    autoComplete="index-fund-minimumRedemption"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <DecimalNumberField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    inputProps={{ min: 0, max: 100 }}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    id={indexFundFormFields.subscriptionFee}
                    name={indexFundFormFields.subscriptionFee}
                    label="Subscription Fee"
                    value={values[indexFundFormFields.subscriptionFee]}
                    onChange={handleChange}
                    error={touched.subscriptionFee && !!errors.subscriptionFee}
                    helperText={touched.subscriptionFee && errors.subscriptionFee}
                    autoComplete="index-fund-subscriptionFee"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <DecimalNumberField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    inputProps={{ min: 0, max: 100 }}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    id={indexFundFormFields.redemptionFee}
                    name={indexFundFormFields.redemptionFee}
                    label="Redemption Fee"
                    value={values[indexFundFormFields.redemptionFee]}
                    onChange={handleChange}
                    error={touched.redemptionFee && !!errors.redemptionFee}
                    helperText={touched.redemptionFee && errors.redemptionFee}
                    autoComplete="index-fund-redemptionFee"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <DecimalNumberField
                    margin="normal"
                    required
                    fullWidth
                    type="number"
                    inputProps={{ min: 0, max: 100 }}
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    id={indexFundFormFields.otherFee}
                    name={indexFundFormFields.otherFee}
                    label="Other Fee"
                    value={values[indexFundFormFields.otherFee]}
                    onChange={handleChange}
                    error={touched.otherFee && !!errors.otherFee}
                    helperText={touched.otherFee && errors.otherFee}
                    autoComplete="index-fund-otherFee"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.benchmark && !!errors.benchmark}>
                    <InputLabel id="benchmark-label" required>
                      Benchmark
                    </InputLabel>
                    <Select
                      labelId="benchmark-label"
                      name={indexFundFormFields.benchmark}
                      value={values[indexFundFormFields.benchmark] || ''}
                      label="Benchmark 👁"
                      onChange={handleChange}
                      required
                      defaultValue={0}
                    >
                      {benchmarks.map((ps) => (
                        <MenuItem key={ps.id} value={ps.id}>
                          {ps.nameAr}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.benchmark && <FormHelperText>{errors.benchmark}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    fullWidth
                    multiline
                    id={indexFundFormFields.topHoldings}
                    name={indexFundFormFields.topHoldings}
                    label="Top Holdings"
                    value={values[indexFundFormFields.topHoldings]}
                    onChange={handleChange}
                    error={touched.topHoldings && !!errors.topHoldings}
                    helperText={touched.topHoldings && errors.topHoldings}
                    autoComplete="index-fund-topHoldings"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    dir="rtl"
                    fullWidth
                    multiline
                    id={indexFundFormFields.topHoldingsAr}
                    name={indexFundFormFields.topHoldingsAr}
                    label="Top Holdings Ar"
                    value={values[indexFundFormFields.topHoldingsAr]}
                    onChange={handleChange}
                    error={touched.topHoldingsAr && !!errors.topHoldingsAr}
                    helperText={touched.topHoldingsAr && errors.topHoldingsAr}
                    autoComplete="index-fund-topHoldingsAr"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    fullWidth
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    id={indexFundFormFields.numberHoldings}
                    name={indexFundFormFields.numberHoldings}
                    label="Number Holdings"
                    value={values[indexFundFormFields.numberHoldings]}
                    onChange={handleChange}
                    error={touched.numberHoldings && !!errors.numberHoldings}
                    helperText={touched.numberHoldings && errors.numberHoldings}
                    autoComplete="index-fund-numberHoldings"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.riskLevel && !!errors.riskLevel}>
                    <InputLabel id="riskLevel-label">Risk Level 👁</InputLabel>
                    <Select
                      labelId="riskLevel-label"
                      id={indexFundFormFields.riskLevel}
                      name={indexFundFormFields.riskLevel}
                      value={values[indexFundFormFields.riskLevel]}
                      label="Risk Level  👁"
                      onChange={handleChange}
                    >
                      {fundRiskLevelOptions.map((a) => (
                        <MenuItem key={a.id} value={a.id}>
                          {a.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.riskLevel && <FormHelperText>{errors.riskLevel}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
            <Typography color="text.primary" component="h4" gutterBottom>
              Attachments
            </Typography>
            <FormikProvider value={formik}>
              <FieldArray
                name="attachments"
                render={(arrayHelpers) => (
                  <>
                    <List>
                      {values[indexFundFormFields.attachments].map((item, index) => (
                        <ListItem
                          key={getFilesKey(index)}
                          secondaryAction={renderFileDeleteButton(item, index, arrayHelpers)}
                        >
                          <ListItemAvatar>
                            <Avatar>
                              <UploadFileButton
                                accept="application/pdf"
                                selectedFile={item.documentLink}
                                uploadEntity={UploadEntity.indexFund}
                                multiple
                                onChange={(uploadedFiles) => {
                                  if (uploadedFiles.length) {
                                    for (let i = 0; i < uploadedFiles.length; i++) {
                                      if (index + i > values[indexFundFormFields.attachments].length - 1) {
                                        arrayHelpers.push({ documentLink: '' });
                                      }
                                      setFieldValue(`attachments.${index + i}.documentLink`, uploadedFiles[i]);
                                    }
                                  }
                                }}
                                onError={(uploadError) => {
                                  enqueueSnackbar(uploadError, { variant: 'error' });
                                }}
                              />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={item.type}
                            secondary={
                              item.documentLink ||
                              (errors.attachments?.[index] as FormikErrors<UpdateIndexFundAttachment>)?.documentLink
                            }
                            primaryTypographyProps={{ fontWeight: 600 }}
                            secondaryTypographyProps={{ color: item.documentLink ? 'unset' : 'red' }}
                          />
                        </ListItem>
                      ))}
                    </List>
                    <Box>
                      <Button variant="outlined" onClick={() => arrayHelpers.push({ documentLink: '' })}>
                        Add
                      </Button>
                    </Box>
                  </>
                )}
              />
              {!!errors.attachments && typeof errors.attachments === 'string' && (
                <FormHelperText error>{errors.attachments}</FormHelperText>
              )}
            </FormikProvider>
          </Paper>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
            <Typography color="text.primary" sx={{ mb: 2 }} component="h4" gutterBottom>
              Gics
            </Typography>
            <FormikProvider value={formik}>
              <FieldArray
                name="gics"
                render={(arrayHelpers) => (
                  <Grid container spacing={2} paddingX={2}>
                    {values[indexFundFormFields.gics] && values[indexFundFormFields.gics].length ? (
                      values[indexFundFormFields.gics].map((item: GicsWeight, index) => (
                        <Grid container item spacing={3} key={getGicsKey(index)}>
                          <Grid item xs={12} md={5} lg={5}>
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <FormControl
                                fullWidth
                                margin="normal"
                                error={touched.gics?.[index] && !!errors.gics?.[index]}
                              >
                                <InputLabel id="fund-label" required>
                                  Gics
                                </InputLabel>
                                <Select
                                  labelId="fund-label"
                                  id={`gics.${index}.gics`}
                                  name={`gics.${index}.gics`}
                                  value={item.gics}
                                  label="Gics"
                                  onChange={handleChange}
                                >
                                  {gicsOptions.map((g) => (
                                    <MenuItem key={g.id} value={g.id}>
                                      {g.nameEn}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {touched.gics?.[index] && (
                                  <FormHelperText>{(errors.gics?.[index] as any)?.gics}</FormHelperText>
                                )}
                              </FormControl>
                            )}
                          </Grid>
                          <Grid item xs={10} md={5} lg={5} display="flex" alignItems="center">
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <DecimalNumberField
                                margin="normal"
                                fullWidth
                                id={`gics.${index}.weight`}
                                name={`gics.${index}.weight`}
                                label="Weight %"
                                value={item.weight || 0}
                                onChange={handleChange}
                                autoComplete="Weight"
                                error={touched.gics?.[index] && !!errors.gics?.[index]}
                                helperText={touched.gics?.[index] && errors.gics?.[index]}
                              />
                            )}
                          </Grid>
                          <Grid container item xs={2} md={2} lg={2} spacing={2} display="flex" alignItems="center">
                            <Grid item>
                              <IconButton
                                color="primary"
                                aria-label="Remove Gics"
                                size="small"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <RemoveIcon />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <IconButton
                                color="primary"
                                aria-label="Add Gics"
                                size="small"
                                onClick={() => arrayHelpers.insert(index + 1, { gics: 0, weight: 0 })}
                              >
                                <AddIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))
                    ) : (
                      <Box>
                        <Button variant="outlined" onClick={() => arrayHelpers.push({ gics: 0, weight: 0 })}>
                          Add Gics
                        </Button>
                      </Box>
                    )}
                  </Grid>
                )}
              />
              {!!errors.gics && typeof errors.gics === 'string' && <FormHelperText error>{errors.gics}</FormHelperText>}
            </FormikProvider>
          </Paper>
        </Grid>
      </Grid>
      <Grid>
        <Box m={1} p={1} display="flex" justifyContent="right" alignItems="flex-end">
          {editIndexFund ? (
            <ProtectedContent items={[permissions.IndexFund.delete]}>
              <LoadingButton
                type="button"
                color="error"
                variant="contained"
                sx={{ mt: 3, mb: 2, mr: 2 }}
                endIcon={<DeleteIcon />}
                loading={isDeleteLoading}
                loadingPosition="end"
                onClick={onDeleteIndexFund}
              >
                Delete
              </LoadingButton>
            </ProtectedContent>
          ) : (
            <div />
          )}
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            endIcon={<SaveIcon />}
            loading={isSaveLoading}
            loadingPosition="end"
          >
            {editIndexFund ? 'Save' : 'Create'}
          </LoadingButton>
        </Box>
      </Grid>
    </form>
  );

  const importFundNavDataFile = (): void => {
    setOpenImportFund(true);
  };

  const importBenchmarkNavDataFile = (): void => {
    setOpenImportBenchmark(true);
  };

  const importChartDataFile = (): void => {
    setOpenImportChartData(true);
  };

  const handleClose = () => {
    setOpenImportFund(false);
    setOpenImportBenchmark(false);
    setOpenImportChartData(false);
  };

  return (
    <Container component="main" maxWidth="xl">
      {error && (
        <Alert severity="error">
          <pre>{error.message}</pre>
        </Alert>
      )}

      <Grid container display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <ExportNavDataButton indexFund={editIndexFund} />
        </Grid>
        <Grid item>
          <Button
            startIcon={<UploadIcon />}
            variant="outlined"
            disabled={!selectedBenchmark}
            onClick={importBenchmarkNavDataFile}
          >
            Import Benchmark Nav Data File
          </Button>
          <Button
            sx={{ ml: 2 }}
            startIcon={<UploadIcon />}
            variant="outlined"
            disabled={!editIndexFund}
            onClick={importFundNavDataFile}
          >
            Import Fund Nav Data File
          </Button>
          <Button
            sx={{ ml: 2 }}
            startIcon={<UploadIcon />}
            variant="outlined"
            disabled={!editIndexFund}
            onClick={importChartDataFile}
          >
            Import Chart Data File
          </Button>
        </Grid>
      </Grid>

      <Box mt={2}>{renderForm()}</Box>

      {!!editIndexFund && selectedBenchmark && (
        <ImportFundChartDataFileDialog
          open={openImportChartData}
          onClose={handleClose}
          indexFund={editIndexFund}
          benchmark={selectedBenchmark}
        />
      )}

      {!!editIndexFund && (
        <ImportFundNavDataFileDialog open={openImportFund} onClose={handleClose} indexFund={editIndexFund} />
      )}
      {selectedBenchmark && (
        <ImportBenchmarkNavDataFileDialog
          open={openImportBenchmark}
          onClose={handleClose}
          benchmark={selectedBenchmark}
        />
      )}
    </Container>
  );
}
