import { PaginatedRequest } from '../types/api';
import {
  CreateSatelliteAccountRequest,
  CreateSatelliteAccountResponse,
  FetchSatelliteAccountsResponse,
  FetchSatelliteAccountResponse,
  UpdateSatelliteAccountRequest,
  UpdateSatelliteAccountResponse,
  FetchSatelliteAccountUnitsResponse,
  FetchSatelliteAccountUnitResponse,
  CreateSatelliteAccountUnitRequest,
  CreateSatelliteAccountUnitResponse,
  UpdateSatelliteAccountUnitRequest,
  UpdateSatelliteAccountUnitResponse,
  FetchSatelliteAccountDetailsHistoryResponse,
} from '../types/satelliteAccount';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class SatelliteAccountService extends ApiService {
  pathname = 'admin/satellite-account';

  fetchSatelliteAccounts = async () => this.get<FetchSatelliteAccountsResponse>(`${this.pathname}`);

  fetchSatelliteAccountDetailsHistory = async (data: PaginatedRequest) =>
    this.get<FetchSatelliteAccountDetailsHistoryResponse>(`${this.pathname}/history?${toQueryParams(data)}`);

  fetchSatelliteAccountUnits = async () => this.get<FetchSatelliteAccountUnitsResponse>(`${this.pathname}/unit`);

  fetchSatelliteAccount = async (id: number) => this.get<FetchSatelliteAccountResponse>(`${this.pathname}/${id}`);

  fetchSatelliteAccountUnit = async (id: number) =>
    this.get<FetchSatelliteAccountUnitResponse>(`${this.pathname}/unit/${id}`);

  deleteSatelliteAccount = async (id: number) => this.delete<FetchSatelliteAccountResponse>(`${this.pathname}/${id}`);

  deleteSatelliteAccountUnit = async (id: number) =>
    this.delete<FetchSatelliteAccountUnitResponse>(`${this.pathname}/unit/${id}`);

  createSatelliteAccount = async (action: CreateSatelliteAccountRequest) =>
    this.post<CreateSatelliteAccountResponse, CreateSatelliteAccountRequest>(`${this.pathname}`, action);

  createSatelliteAccountUnit = async (action: CreateSatelliteAccountUnitRequest) =>
    this.post<CreateSatelliteAccountUnitResponse, CreateSatelliteAccountUnitRequest>(`${this.pathname}/unit`, action);

  updateSatelliteAccount = async ({ id, ...action }: UpdateSatelliteAccountRequest) =>
    this.put<UpdateSatelliteAccountResponse, Omit<UpdateSatelliteAccountRequest, 'id'>>(
      `${this.pathname}/${id}`,
      action,
    );

  updateSatelliteAccountUnit = async ({ id, ...action }: UpdateSatelliteAccountUnitRequest) =>
    this.put<UpdateSatelliteAccountUnitResponse, Omit<UpdateSatelliteAccountUnitRequest, 'id'>>(
      `${this.pathname}/unit/${id}`,
      action,
    );
}

export const satelliteAccountService = new SatelliteAccountService();
