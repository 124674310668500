import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { feeConfigFormFields } from '../../forms/fields/formFields';
import { getFeeConfigFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import { fetchProductTypes } from '../../redux/modules/productType/product-type.actions';
import { productTypeSelector } from '../../redux/modules/productType/product-type.selectors';
import {
  deleteFeeConfig,
  fetchFeeConfig,
  resetEditFeeConfig,
  createFeeConfigItem,
  fetchFeeConfigStatusOptions,
  updateFeeConfig,
} from '../../redux/modules/feeConfig/feeConfig.actions';
import { feeConfigSelector } from '../../redux/modules/feeConfig/feeConfig.selectors';
import { locations } from '../../routes/locations';
import { PeriodicityTypes, FeeConfigStatus, CreateFeeConfigRequest } from '../../types/feeConfig';
import { FeeConfigHistoryTable } from './FeeConfigHistoryTable';

export function FeeConfig() {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [formSchema, setFormSchema] = useState(getFeeConfigFormSchema());
  const {
    fetchStatus,
    editFeeConfig,
    deleteStatus,
    createStatus,
    feeConfigStatusOptions,
    fetchFeeConfigStatusOptionsStatus,
    error: feeConfigError,
  } = useSelector(feeConfigSelector);
  const {
    fetchStatus: productTypeFetchStatus,
    productTypes,
    error: productTypeError,
  } = useSelector(productTypeSelector);

  const { enqueueSnackbar } = useSnackbar();
  const isLoading =
    fetchStatus === fetchStatuses.pending ||
    productTypeFetchStatus === fetchStatuses.pending ||
    fetchFeeConfigStatusOptionsStatus === fetchStatuses.pending;
  const isDeleteLoading = isLoading || deleteStatus === fetchStatuses.pending;
  const isSaveLoading = isLoading || createStatus === fetchStatuses.pending;
  const error = feeConfigError || productTypeError;
  const formik = useFormik({
    initialValues: {
      [feeConfigFormFields.formula]: '',
      [feeConfigFormFields.minSubscription]: 0,
      [feeConfigFormFields.percentage]: 0,
      [feeConfigFormFields.vat]: 0,
      [feeConfigFormFields.status]: 0,
      [feeConfigFormFields.calculationPeriodicity]: 0,
      [feeConfigFormFields.deductionPeriodicity]: 0,
      [feeConfigFormFields.workDaysOnly]: true,
      [feeConfigFormFields.productTypeId]: 0,
    },
    validationSchema: formSchema,
    onSubmit: (formValues) => {
      // return alert(JSON.stringify(formValues, null, 4));
      formValues.productTypeId = +formValues.productTypeId;
      const payload: CreateFeeConfigRequest = {
        formula: formValues.formula,
        percentage: formValues.percentage,
        minSubscription: formValues.minSubscription,
        vat: formValues.vat,
        status: formValues.status,
        productTypeId: formValues.productTypeId,
        workDaysOnly: formValues.workDaysOnly,
        calculationPeriodicity: formValues.calculationPeriodicity,
        deductionPeriodicity: formValues.deductionPeriodicity,
      };

      if (Number(id)) {
        dispatch(updateFeeConfig({ id: Number(id), ...payload }));
      } else {
        dispatch(createFeeConfigItem(payload));
      }
    },
  });
  const { handleSubmit, values, handleChange, touched, errors, setValues, setFieldValue } = formik;

  useEffect(() => {
    dispatch(fetchFeeConfigStatusOptions());
    dispatch(fetchProductTypes({ limit: 100, offset: 0 }));
    return () => {
      dispatch(resetEditFeeConfig());
    };
  }, []);

  useEffect(() => {
    if (fetchFeeConfigStatusOptionsStatus === fetchStatuses.success && !values[feeConfigFormFields.status]) {
      const activeStatus = feeConfigStatusOptions.find((item) => item.name === FeeConfigStatus.ACTIVE);
      setFieldValue(feeConfigFormFields.status, activeStatus?.id || 0);
    }
  }, [feeConfigStatusOptions, fetchFeeConfigStatusOptionsStatus]);

  useEffect(() => {
    if (deleteStatus === fetchStatuses.success) {
      enqueueSnackbar('Fee Config deleted!', { variant: 'success' });
      navigate(locations.feeConfigList());
    }
    if (createStatus === fetchStatuses.success) {
      if (Number(id)) {
        dispatch(fetchFeeConfig(Number(id)));
      }
      enqueueSnackbar('Fee Config saved!', { variant: 'success' });
    }
  }, [deleteStatus, createStatus]);

  useEffect(() => {
    if (Number(id)) {
      dispatch(fetchFeeConfig(Number(id)));
    }
  }, [id]);

  useEffect(() => {
    if (editFeeConfig?.id && editFeeConfig?.id !== Number(id)) {
      navigate(locations.feeConfig(editFeeConfig?.id));
      return;
    }

    if (editFeeConfig) {
      setValues({
        [feeConfigFormFields.formula]: editFeeConfig.formula,
        [feeConfigFormFields.minSubscription]: editFeeConfig.minSubscription,
        [feeConfigFormFields.percentage]: editFeeConfig.percentage,
        [feeConfigFormFields.vat]: editFeeConfig.vat,
        [feeConfigFormFields.status]: editFeeConfig.status?.id,
        [feeConfigFormFields.calculationPeriodicity]: editFeeConfig.calculationPeriodicity.id,
        [feeConfigFormFields.deductionPeriodicity]: editFeeConfig.deductionPeriodicity.id,
        [feeConfigFormFields.workDaysOnly]: editFeeConfig.workDaysOnly,
        [feeConfigFormFields.productTypeId]: editFeeConfig.productTypeId,
      });
    }
  }, [editFeeConfig]);

  const onDeleteFeeConfig = () => {
    dispatch(deleteFeeConfig(Number(id)));
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
            <Typography color="text.primary" component="h4" gutterBottom>
              Fee Config
            </Typography>
            <Grid container spacing={2} paddingX={2}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="feeConfigFormual"
                    name={feeConfigFormFields.formula}
                    label="Fee Config Formula"
                    type="feeConfigFormual"
                    value={values[feeConfigFormFields.formula]}
                    onChange={handleChange}
                    error={touched.formula && !!errors.formula}
                    helperText={touched.formula && errors.formula}
                    autoComplete="fee-config-formula"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    id={feeConfigFormFields.percentage}
                    fullWidth
                    name={feeConfigFormFields.percentage}
                    label="Percentage %"
                    type="number"
                    value={values[feeConfigFormFields.percentage]}
                    onChange={handleChange}
                    error={touched.percentage && !!errors.percentage}
                    helperText={touched.percentage && errors.percentage}
                    autoComplete="fee-config-percentage"
                    margin="normal"
                    required
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id={feeConfigFormFields.minSubscription}
                    name={feeConfigFormFields.minSubscription}
                    label="Min Subscription"
                    type="number"
                    value={values[feeConfigFormFields.minSubscription]}
                    onChange={handleChange}
                    error={touched.minSubscription && !!errors.minSubscription}
                    helperText={touched.minSubscription && errors.minSubscription}
                    autoComplete="fee-config-min-subscription"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <TextField
                    id={feeConfigFormFields.vat}
                    fullWidth
                    name={feeConfigFormFields.vat}
                    label="VAT %"
                    type="number"
                    value={values[feeConfigFormFields.vat]}
                    onChange={handleChange}
                    error={touched.vat && !!errors.vat}
                    helperText={touched.vat && errors.vat}
                    autoComplete="fee-config-vat"
                    margin="normal"
                    required
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl
                    fullWidth
                    margin="normal"
                    error={touched.calculationPeriodicity && !!errors.calculationPeriodicity}
                  >
                    <InputLabel id="calculation-periodicity-type-label" required>
                      Calculation Periodicity Type
                    </InputLabel>
                    <Select
                      labelId="calculation-periodicity-type-label"
                      name={feeConfigFormFields.calculationPeriodicity}
                      value={values[feeConfigFormFields.calculationPeriodicity]}
                      label="Calculation Periodicity Type"
                      onChange={handleChange}
                      error={touched.calculationPeriodicity && !!errors.calculationPeriodicity}
                    >
                      <MenuItem key="0" value="1">
                        {PeriodicityTypes.DAILY}
                      </MenuItem>
                      <MenuItem key="1" value="2">
                        {PeriodicityTypes.WEEKLY}
                      </MenuItem>
                      <MenuItem key="2" value="3">
                        {PeriodicityTypes.MONTHLY}
                      </MenuItem>
                      <MenuItem key="3" value="4">
                        {PeriodicityTypes.ANNUALLY}
                      </MenuItem>
                    </Select>
                    {touched.calculationPeriodicity && <FormHelperText>{errors.calculationPeriodicity}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl
                    fullWidth
                    margin="normal"
                    error={touched.deductionPeriodicity && !!errors.deductionPeriodicity}
                  >
                    <InputLabel id="deduction-periodicity-type-label" required>
                      Deduction Periodicity Type
                    </InputLabel>
                    <Select
                      labelId="deduction-periodicity-type-label"
                      name={feeConfigFormFields.deductionPeriodicity}
                      value={values[feeConfigFormFields.deductionPeriodicity]}
                      label="Deduction Periodicity Type"
                      onChange={handleChange}
                      error={touched.deductionPeriodicity && !!errors.deductionPeriodicity}
                    >
                      <MenuItem value="1">{PeriodicityTypes.DAILY}</MenuItem>
                      <MenuItem value="2">{PeriodicityTypes.WEEKLY}</MenuItem>
                      <MenuItem value="3">{PeriodicityTypes.MONTHLY}</MenuItem>
                      <MenuItem value="4">{PeriodicityTypes.ANNUALLY}</MenuItem>
                    </Select>
                    {touched.deductionPeriodicity && <FormHelperText>{errors.deductionPeriodicity}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.productTypeId && !!errors.productTypeId}>
                    <InputLabel id="product-type-label" required>
                      Product Type
                    </InputLabel>
                    <Select
                      labelId="product-type-label"
                      name={feeConfigFormFields.productTypeId}
                      value={values[feeConfigFormFields.productTypeId] || ''}
                      label="Product Type"
                      onChange={handleChange}
                      required
                      defaultValue={0}
                    >
                      {productTypes.map((pt) => (
                        <MenuItem key={pt.id} value={pt.id}>
                          {pt.nameEn}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.productTypeId && <FormHelperText>{errors.productTypeId}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControl fullWidth margin="normal" error={touched.status && !!errors.status}>
                    <InputLabel id="status-label" required>
                      Status
                    </InputLabel>
                    <Select
                      labelId="status-label"
                      name={feeConfigFormFields.status}
                      value={values[feeConfigFormFields.status] || ''}
                      label="Status"
                      onChange={handleChange}
                    >
                      {feeConfigStatusOptions.map((ps) => (
                        <MenuItem key={ps.id} value={ps.id}>
                          {ps.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.status && <FormHelperText>{errors.status}</FormHelperText>}
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="100%" height={80} />
                ) : (
                  <FormControlLabel
                    sx={{ mt: 2 }}
                    label="Work Days Only"
                    labelPlacement="start"
                    componentsProps={{
                      typography: { textTransform: 'capitalize' },
                    }}
                    name={feeConfigFormFields.workDaysOnly}
                    id={feeConfigFormFields.workDaysOnly}
                    control={
                      <Switch
                        name={feeConfigFormFields.workDaysOnly}
                        checked={values[feeConfigFormFields.workDaysOnly]}
                        onChange={handleChange}
                      />
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
            <Typography color="text.primary" component="h4" gutterBottom>
              History
            </Typography>
            <FeeConfigHistoryTable id={Number(id)} />
          </Paper>
        </Grid>
      </Grid>
      <Grid>
        <Box m={1} p={1} display="flex" justifyContent="right" alignItems="flex-end">
          {editFeeConfig ? (
            <LoadingButton
              type="button"
              color="error"
              variant="contained"
              sx={{ mt: 3, mb: 2, mr: 2 }}
              endIcon={<DeleteIcon />}
              loading={isDeleteLoading}
              loadingPosition="end"
              onClick={onDeleteFeeConfig}
            >
              Delete
            </LoadingButton>
          ) : (
            <div />
          )}
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            endIcon={<SaveIcon />}
            loading={isSaveLoading}
            loadingPosition="end"
          >
            {editFeeConfig ? 'Save' : 'Create'}
          </LoadingButton>
        </Box>
      </Grid>
    </form>
  );

  return (
    <Container component="main" maxWidth="xl">
      {error && (
        <Alert severity="error">
          <pre>{error.message}</pre>
        </Alert>
      )}

      <Box mt={2}>{renderForm()}</Box>
    </Container>
  );
}
