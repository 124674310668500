import { PaginatedRequest } from '../types/api';
import {
  CreateUserAddressRequest,
  CreateUserAddressResponse,
  FetchUserAddressesResponse,
  FetchUserAddressResponse,
  UpdateUserAddressRequest,
  UpdateUserAddressResponse,
} from '../types/userAddress';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class UserAddressService extends ApiService {
  pathname = 'admin/user-address';

  fetchUserAddresses = async (data: PaginatedRequest) =>
    this.get<FetchUserAddressesResponse>(`${this.pathname}?${toQueryParams(data)}`);

  fetchUserAddress = async (id: number) => this.get<FetchUserAddressResponse>(`${this.pathname}/${id}`);

  createUserAddress = async (data: CreateUserAddressRequest) =>
    this.post<CreateUserAddressResponse, Omit<CreateUserAddressRequest, 'id' | 'userId'>>(`${this.pathname}`, data);

  updateUserAddress = async ({ id, ...data }: UpdateUserAddressRequest) =>
    this.put<UpdateUserAddressResponse, Omit<UpdateUserAddressRequest, 'id'>>(`${this.pathname}/${id}`, data);

  deleteUserAddress = async (id: number) => this.delete<FetchUserAddressResponse>(`${this.pathname}/${id}`);

  setPrimaryUserAddress = async (id: number) =>
    this.put<FetchUserAddressResponse, object>(`${this.pathname}/user-group/${id}/default`, {});
}

export const userAddressService = new UserAddressService();
