import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { productTypeService } from '../../../api';
import { CreateProductTypeRequest, UpdateProductTypeRequest } from '../../../types/productType';
import { PaginatedRequest } from '../../../types/api';

export const fetchProductTypes = createAsyncThunk('product-type/fetch-all', (data: PaginatedRequest) =>
  productTypeService.fetchProductTypes(data),
);

export const fetchProductType = createAsyncThunk('product-type/get', (id: number) =>
  productTypeService.fetchProductType(id),
);

export const deleteProductType = createAsyncThunk('product-type/delete', (id: number) =>
  productTypeService.deleteProductType(id),
);

export const createProductType = createAsyncThunk('product-type/create', (productType: CreateProductTypeRequest) =>
  productTypeService.createProductType(productType),
);

export const updateProductType = createAsyncThunk('product-type/update', (productType: UpdateProductTypeRequest) =>
  productTypeService.updateProductType(productType),
);
export const fetchProductTypeFileTypeOptions = createAsyncThunk(
  'product-type/fetch-product-type-file-type-options',
  () => productTypeService.fetchProductTypeFileTypeOptions(),
);

export const resetEditProductType = createAction('product-type/reset-edit');

export const cleanProductTypes = createAction('product-type/clean');
