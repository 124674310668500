import { getGridStringOperators, GridFilterOperator } from '@mui/x-data-grid-premium';
import { CustomGridFilterInputMultipleValue } from '../filterComponents/CustomGridFilterInputMultipleValue';

export const gridStringOperators: GridFilterOperator[] = getGridStringOperators().map((filter) => {
  if (filter.value === 'isAnyOf') {
    filter.InputComponent = CustomGridFilterInputMultipleValue;
  }

  return filter;
});
