import { useAutoLogin } from '../../hooks';

interface IFooterProps {
  children: JSX.Element;
}

export function Autologin({ children }: IFooterProps): JSX.Element {
  useAutoLogin();

  return children;
}
