import { ActionType } from '../../types/action';

const listPermission = {
  resource: 'batch-executions',
  action: ActionType.READ,
};

const readPermission = {
  resource: 'batch-execution',
  action: ActionType.READ,
};

export const BatchExecution = {
  list: listPermission,
  read: readPermission,
};
