import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchEmployeeResponse, FetchEmployeesResponse } from '../../../types/employee';
import {
  fetchEmployee,
  fetchEmployees,
  resetEditEmployee,
  createEmployee,
  deleteEmployee,
  updateEmployee,
  cleanEmployees,
  updateEmployeeStatus,
  resetEmployeePassword,
} from './employee.actions';
import { EmployeeState } from './employee.types';

const initialState: EmployeeState = {
  employees: [],
  totalEmployees: 0,
  editEmployee: null,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  updateStatusStatus: fetchStatuses.idle,
  resetPasswordStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEmployees.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchEmployees.fulfilled, (state, action: PayloadAction<FetchEmployeesResponse>) => {
      state.employees = action.payload.data?.items;
      state.totalEmployees = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchEmployees.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchEmployee.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchEmployee.fulfilled, (state, action: PayloadAction<FetchEmployeeResponse>) => {
      state.editEmployee = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchEmployee.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateEmployee.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
    });
    builder.addCase(updateEmployee.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateEmployee.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateEmployeeStatus.pending, (state) => {
      state.error = null;
      state.updateStatusStatus = fetchStatuses.pending;
      state.resetPasswordStatus = fetchStatuses.idle;
    });
    builder.addCase(updateEmployeeStatus.fulfilled, (state) => {
      state.updateStatusStatus = fetchStatuses.success;
    });
    builder.addCase(updateEmployeeStatus.rejected, (state, action) => {
      state.updateStatusStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEmployeePassword.pending, (state) => {
      state.error = null;
      state.resetPasswordStatus = fetchStatuses.pending;
      state.updateStatusStatus = fetchStatuses.idle;
    });
    builder.addCase(resetEmployeePassword.fulfilled, (state) => {
      state.resetPasswordStatus = fetchStatuses.success;
    });
    builder.addCase(resetEmployeePassword.rejected, (state, action) => {
      state.resetPasswordStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createEmployee.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
    });
    builder.addCase(createEmployee.fulfilled, (state, action: PayloadAction<FetchEmployeeResponse>) => {
      state.editEmployee = action.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createEmployee.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteEmployee.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
    });
    builder.addCase(deleteEmployee.fulfilled, (state) => {
      state.editEmployee = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteEmployee.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditEmployee.type, (state) => {
      state.editEmployee = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanEmployees.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const employeeReducer = employeesSlice.reducer;
