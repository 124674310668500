import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Fab from '@mui/material/Fab';
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Table, TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useAppDispatch } from '../../redux/hooks';
import { cleanActions, deleteAction, fetchActions } from '../../redux/modules/action/action.actions';
import { actionSelector } from '../../redux/modules/action/action.selectors';
import { Action } from '../../types/action';
import { ActionDialog } from './ActionDialog';

export function ActionList() {
  const dispatch = useAppDispatch();
  const tableRef = useRef<TableRef>(null);
  const [open, setOpen] = React.useState(false);
  const [editValue, setEditValue] = React.useState<Action | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const onDeleteAction = (id: number) => {
    dispatch(deleteAction(id));
  };

  const onEditAction = (row: Action): void => {
    setEditValue(row);
    setOpen(true);
  };

  const columns = useRef<GridColDef[]>([
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      filterable: false,
      sortable: false,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params: GridRowParams) => [
        // eslint-disable-next-line react/jsx-key
        <GridActionsCellItem
          icon={<DeleteOutlineOutlinedIcon />}
          label="Link"
          onClick={() => onDeleteAction(params.row.id)}
        />,
        // eslint-disable-next-line react/jsx-key
        <GridActionsCellItem icon={<EditOutlinedIcon />} label="Actions" onClick={() => onEditAction(params.row)} />,
      ],
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 70,
    },
    {
      field: 'resource.name',
      headerName: 'Resource',
      flex: 1,
      valueGetter: (params) => params?.row?.resource?.name,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 100,
    },
  ]);
  const { totalActions, actions, fetchStatus, deleteStatus } = useSelector(actionSelector);

  const onAddNew = (): void => {
    setOpen(true);
  };

  const handleClose = (isReload: boolean) => {
    if (isReload) {
      tableRef?.current?.reload();
    }
    setEditValue(null);
    setOpen(false);
  };

  useEffect(
    () => () => {
      dispatch(cleanActions());
    },
    [],
  );

  useEffect(() => {
    if (deleteStatus === fetchStatuses.success) {
      handleClose(true);
      enqueueSnackbar('Action deleted!', { variant: 'success' });
    }
    if (deleteStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Action deletion error!', { variant: 'error' });
    }
  }, [deleteStatus]);

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">
          Actions &nbsp;
          <Fab color="primary" size="small" aria-label="Create Action" onClick={onAddNew}>
            <AddIcon />
          </Fab>
        </Typography>
      </Box>

      <ActionDialog open={open} initialValue={editValue} onClose={handleClose} />

      <Table
        ref={tableRef}
        fetchItems={fetchActions}
        rows={actions}
        columns={columns.current}
        loading={fetchStatus === fetchStatuses.pending}
        rowCount={totalActions}
      />
    </Container>
  );
}
