import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { userIndexFundService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { SaveUserIndexFundRequest, UpdateUserIndexFundRequest } from '../../../types/userIndexFund';

export const fetchUserIndexFunds = createAsyncThunk('user-index-fund/fetch-all', (data: PaginatedRequest) =>
  userIndexFundService.fetchUserIndexFunds(data),
);

export const fetchUserIndexFundsByUser = createAsyncThunk(
  'user-index-fund/fetch-all-by-user',
  (data: PaginatedRequest & { userId: number }) => userIndexFundService.fetchUserIndexFundsByUser(data),
);

export const fetchUserIndexFund = createAsyncThunk('user-index-fund/fetch-by-id', (id: number) =>
  userIndexFundService.fetchUserIndexFund(id),
);

export const deleteUserIndexFund = createAsyncThunk('user-index-fund/delete', (id: number) =>
  userIndexFundService.deleteUserIndexFund(id),
);

export const saveUserIndexFund = createAsyncThunk('user-index-fund/save', (portfolio: SaveUserIndexFundRequest) =>
  userIndexFundService.saveUserIndexFund(portfolio),
);

export const updateUserIndexFund = createAsyncThunk('user-index-fund/update', (portfolio: UpdateUserIndexFundRequest) =>
  userIndexFundService.updateUserIndexFund(portfolio),
);

export const cleanUserIndexFunds = createAction('user-index-fund/clean');

export const resetEditUserIndexFund = createAction('user-index-fund/reset-edit');
