import * as React from 'react';
import Paper from '@mui/material/Paper';
import { IAlpacaTransfer } from '../../../../types/alpaca';
import { AlpacaOrderList } from './AlpacaOrderList';

interface AlpacaTransferDetailPanelContentProps {
  alpacaTransfer: IAlpacaTransfer;
}

export const AlpacaTransferDetailPanelContent: React.FC<AlpacaTransferDetailPanelContentProps> = ({
  alpacaTransfer,
}) => (
  <Paper sx={{ flex: 1, m: 4, p: 1 }}>
    <AlpacaOrderList alpacaTransferId={alpacaTransfer.id} />
  </Paper>
);
