export const toQueryParams = (data: { [prop: string]: any }): string => {
  const params = new URLSearchParams();

  for (const dataKey in data) {
    if (data[dataKey] !== undefined && data[dataKey] !== null) {
      if (Array.isArray(data[dataKey])) {
        for (const datumElement of data[dataKey]) {
          params.append(dataKey, datumElement);
        }
      } else {
        params.append(dataKey, String(data[dataKey]));
      }
    }
  }

  return params.toString();
};
