import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { reportSelector } from '../../../redux/modules/report/report.selectors';
import { JadwaOrdersFxRevenueTable } from './JadwaOrdersFxRevenueTable';

export function JadwaOrdersFxRevenueTab() {
  const { error } = useSelector(reportSelector);

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: 0 }} spacing={2}>
        <Typography variant="h6">Jadwa Orders FX</Typography>
        <JadwaOrdersFxRevenueTable />
      </Grid>
    </>
  );
}
