import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { reportSelector } from '../../redux/modules/report/report.selectors';
import { UsersStatsWidget } from './UsersStatsWidget';
import { FunnelChartWidget } from './FunnelChartWidget';
import { RFMChartWidget } from './RFMChartWidget';

export function UserTab() {
  const { error } = useSelector(reportSelector);

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <UsersStatsWidget />
        <FunnelChartWidget />
        <RFMChartWidget />
      </Grid>
    </>
  );
}
