import { ApiResponse } from './api';
import { FeeCalculation } from './feeCalculation';
import { UserFeeDeduction } from './userFeeDeduction';

export enum ConsolidateFeeRequestStatus {
  CREATED = 'Created',
  IN_PROGRESS = 'In Progress',
  REJECTED = 'Rejected',
  EXECUTED = 'Executed',
}

export interface ConsolidateFeeRequestStatusOption {
  id: number;
  name: ConsolidateFeeRequestStatus;
}

export enum FeeBatchStatus {
  CREATED = 'Created',
  IN_PROGRESS = 'In Progress',
  CANCELLED = 'Cancelled',
  PARTIALLY_PUSHED = 'Partially Pushed',
  PUSHED = 'Pushed',
  PARTIALLY_EXECUTED = 'Partially Executed',
  EXECUTED = 'Executed',
}

export interface FeeBatchStatusOption {
  id: number;
  name: FeeBatchStatus;
}

export enum FeeBatchExecutionStatus {
  EXECUTION = 'Execution',
  IN_REVIEW = 'In Review',
  RECONCILIATION = 'Reconciliation',
  APPROVED = 'Approved',
}

export interface FeeBatchExecutionStatusOption {
  id: number;
  name: FeeBatchExecutionStatus;
}

export enum BatchAttachmentType {
  EXECUTION_REPORT_FILE = 'Execution Report File',
  EXECUTION_FILE = 'Execution File',
  CONSOLIDATE_EXECUTION_FILE = 'Consolidate Execution File',
  OTHER = 'Other',
}

export interface BatchAttachmentTypeOption {
  id: number;
  name: BatchAttachmentType;
}

export interface BatchAttachment {
  id: number;
  orderId: string;
  createdBy: number;
  documentLink: string;
  batchAttachmentType: BatchAttachmentTypeOption;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export type FeeBatch = {
  id: number;
  userFeeDeduction: UserFeeDeduction;
  status: FeeBatchStatusOption;
  batchId: string;
  employeeId: number;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FeeBatchExecution = {
  id: number;
  feeBatch: FeeBatch;
  status: FeeBatchExecutionStatusOption;
  batchId: string | null;
  userId: number;
  indexFunds:
    | {
        numOfUnits: number;
        indexFundId: number;
        netAssetValuePerUnit: number;
        returnAmount: number;
      }[]
    | null;
  returnAmount: number;
  amount: number;
  hold: number;
  cashAllocationValue: number;
  createdAt: Date | string;
  updatedAt: Date | string;
};

// TODO: update fields later
export type ConsolidateFeeRequest = {
  id: number;
  calculatedTotalFee: number;
  actualTotalFee: number;
  feeCalculation: FeeCalculation;
  status: ConsolidateFeeRequestStatusOption;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchFeeBatchResponse = ApiResponse<FeeBatch>;
export type FetchFeeBatchesResponse = ApiResponse<{
  items: FeeBatch[];
  totalItems: number;
}>;
export type FetchFeeBatchExecutionsResponse = ApiResponse<FeeBatchExecution[]>;

export type FetchConsolidateFeeRequestsResponse = ApiResponse<{
  items: ConsolidateFeeRequest[];
  totalItems: number;
}>;

export type FetchFeeBatchStatusOptionsResponse = ApiResponse<FeeBatchStatusOption[]>;
export type FetchBatchAttachmentsTypeOptionsResponse = ApiResponse<BatchAttachmentTypeOption[]>;
export type FetchBatchAttachmentsResponse = ApiResponse<{
  items: BatchAttachment[];
  totalItems: number;
}>;

export type UpdateFeeBatchStatusRequest = Pick<FeeBatch, 'id'> & { status: FeeBatchStatus };
export type UpdateFeeBatchStatusResponse = ApiResponse<{ message: string }>;

export type CreateFeeBatchRequest = { userFeeDeductionIds: number[] };
export type SaveFeeBatchRequest = Omit<FeeBatch, 'id' | 'status' | 'userFeeDeduction' | 'createdAt' | 'updatedAt'> & {
  status: number;
  userFeeDeduction: number;
};
export type CreateFeeBatchResponse = ApiResponse<{ batchId: string; executionId: string }>;

export type CreateBatchAttachmentRequest = {
  batchAttachmentTypeId: number;
  documentLink: string;
};
export type CreateBatchAttachmentResponse = ApiResponse<BatchAttachment>;

export type UploadBatchAttachmentRequest = {
  type: BatchAttachmentType;
  fileData: string;
  fileName: string;
};
export type UploadBatchAttachmentResponse = ApiResponse<BatchAttachment>;

export type ImportExecutionFeeBatch = { batchId: string; file: File; base?: 'amount' | 'units' };

export type UpdateFeeBatchRequest = Omit<FeeBatch, 'status' | 'userFeeDeduction' | 'createdAt' | 'updatedAt'> & {
  status: number;
  userFeeDeduction: number;
};
export type UpdateFeeBatchResponse = ApiResponse<FeeBatch>;

export type ReconcileBatchExecutionsByBatchIdRequest = {
  batchId: string;
  file: File;
};
