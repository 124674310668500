import {
  CreateAnalyticsConfigRequest,
  CreateAnalyticsConfigResponse,
  FetchAnalyticsConfigsResponse,
  FetchAnalyticsConfigResponse,
  UpdateAnalyticsConfigRequest,
  UpdateAnalyticsConfigResponse,
  UpdateAnalyticsConfigsRequest,
} from '../types/analyticsConfig';
import { ApiService } from './common/ApiService';

class AnalyticsConfigService extends ApiService {
  pathname = 'admin/analytics-config';

  fetchAnalyticsConfigs = async () => this.get<FetchAnalyticsConfigsResponse>(`${this.pathname}`);

  fetchAnalyticsConfig = async (key: string) => this.get<FetchAnalyticsConfigResponse>(`${this.pathname}/${key}`);

  deleteAnalyticsConfig = async (key: string) => this.delete<FetchAnalyticsConfigResponse>(`${this.pathname}/${key}`);

  createAnalyticsConfig = async (action: CreateAnalyticsConfigRequest) =>
    this.post<CreateAnalyticsConfigResponse, CreateAnalyticsConfigRequest>(`${this.pathname}`, action);

  updateAnalyticsConfig = async ({ key, ...action }: UpdateAnalyticsConfigRequest) =>
    this.put<UpdateAnalyticsConfigResponse, Omit<UpdateAnalyticsConfigRequest, 'key'>>(
      `${this.pathname}/${key}`,
      action,
    );

  updateAnalyticsConfigs = async (data: UpdateAnalyticsConfigRequest[]) =>
    this.put<FetchAnalyticsConfigsResponse, UpdateAnalyticsConfigsRequest>(`${this.pathname}`, { items: data });
}

export const analyticsConfigService = new AnalyticsConfigService();
