import { createSelector } from '@reduxjs/toolkit';
import { ReducerState } from '../../store.types';
import { OrderExecutionState } from './orderExecution.types';

export const orderExecutionSelector = ({ orderExecution }: ReducerState) => orderExecution;

export const orderExecutionByOrderIdSelector = createSelector(
  orderExecutionSelector,
  (state: ReducerState, orderId: string) => orderId,
  (orderExecution: OrderExecutionState, orderId: string) => orderExecution.orderExecutionByOrderId[orderId],
);

export const orderExecutionByRequestIdSelector = createSelector(
  orderExecutionSelector,
  (state: ReducerState, requestId: string) => requestId,
  (orderExecution: OrderExecutionState, requestId: string) => ({
    orderExecutions: orderExecution.orderExecutions[requestId] || [],
    totalOrderExecutions: orderExecution.totalOrderExecutions[requestId] || 0,
    fetchStatus: orderExecution.fetchStatuses[requestId],
  }),
);
