import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchFundValuationDayResponse,
  FetchFundValuationDaysAutocompleteResponse,
  FetchFundValuationDaysResponse,
} from '../../../types/fundValuationDay';
import {
  fetchFundValuationDay,
  fetchFundValuationDays,
  resetEditFundValuationDay,
  cleanFundValuationDays,
  updateFundValuationDay,
  createFundValuationDay,
  deleteFundValuationDay,
  fetchFundValuationDaysAutocomplete,
} from './fundValuationDay.actions';
import { FundValuationDayState } from './fundValuationDay.types';

const initialState: FundValuationDayState = {
  fundValuationDays: [],
  fundValuationDaysAutocomplete: [],
  totalFundValuationDays: 0,
  editFundValuationDay: null,
  fetchAutocompleteStatus: fetchStatuses.idle,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const fundValuationDaysSlice = createSlice({
  name: 'fundValuationDays',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFundValuationDays.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchFundValuationDays.fulfilled,
      (state, action: PayloadAction<FetchFundValuationDaysResponse>) => {
        state.fundValuationDays = action.payload.data?.items;
        state.totalFundValuationDays = action.payload.data?.totalItems;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFundValuationDays.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFundValuationDaysAutocomplete.pending, (state) => {
      state.fetchAutocompleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchFundValuationDaysAutocomplete.fulfilled,
      (state, action: PayloadAction<FetchFundValuationDaysAutocompleteResponse>) => {
        state.fundValuationDaysAutocomplete = action.payload.data;
        state.fetchAutocompleteStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFundValuationDaysAutocomplete.rejected, (state, action) => {
      state.fetchAutocompleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFundValuationDay.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchFundValuationDay.fulfilled, (state, action: PayloadAction<FetchFundValuationDayResponse>) => {
      state.editFundValuationDay = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFundValuationDay.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateFundValuationDay.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateFundValuationDay.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateFundValuationDay.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createFundValuationDay.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(createFundValuationDay.fulfilled, (state, action: PayloadAction<FetchFundValuationDayResponse>) => {
      state.editFundValuationDay = action.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createFundValuationDay.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteFundValuationDay.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteFundValuationDay.fulfilled, (state) => {
      state.editFundValuationDay = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteFundValuationDay.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditFundValuationDay.type, (state) => {
      state.editFundValuationDay = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanFundValuationDays.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const fundValuationDayReducer = fundValuationDaysSlice.reducer;
