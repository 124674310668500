import React, { useEffect, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import { useFetchRolesQuery } from '../../../redux/features/role/role.apiSlice';
import {
  useFetchAccessPermissionsQuery,
  useFetchRolePermissionsQuery,
} from '../../../redux/features/rolePermission/rolePermission.apiSlice';
import { nonSuperAdminRoleSelector } from '../../../redux/features/role/role.selectors';
import { PermissionsTableActionRow } from './PermissionsTableActionRow';
import { StyledTable, StyledTableCell } from './PermissionsTableElements';
import { PermissionsTableResourceRow } from './PermissionsTableResourceRow';

type RolesDataSet = Record<string, boolean>;
type ActionsDataSet = Record<string, RolesDataSet>;
type ResourcesDataSet = Record<string, ActionsDataSet>;

export interface PermissionsTableRef {
  getPermissions: () => ResourcesDataSet;
  isReady: () => boolean;
}

export const PermissionsTable = React.forwardRef<PermissionsTableRef>((props, ref) => {
  const [permissions, setPermissions] = React.useState<ResourcesDataSet>({});
  const [configured, setConfigured] = React.useState(false);

  const { data: accessPermissions, isSuccess: isAccessPermissionsSuccess } = useFetchAccessPermissionsQuery();
  const { isSuccess: isRoleSuccess } = useFetchRolesQuery({ limit: 1000, offset: 0 });
  const { data: rolePermissions, isSuccess: isRolePermissionsSuccess } = useFetchRolePermissionsQuery({
    limit: 1000,
    offset: 0,
  });
  const roles = useSelector(nonSuperAdminRoleSelector);

  const initialized = isAccessPermissionsSuccess && isRolePermissionsSuccess && isRoleSuccess;

  useEffect(() => {
    if (initialized) {
      const newPermissions = Object.entries(accessPermissions || {}).reduce((res, [resource, actions]) => {
        const actionsDataSet = actions.reduce((actionRes, action) => {
          const rolesDataSet = roles.reduce((roleRes, role) => {
            const hasPermission = Boolean(
              (rolePermissions?.items || []).find(
                (rp) =>
                  rp?.role?.name === role.name &&
                  rp?.action?.type === action &&
                  rp?.action?.resource?.name === resource,
              ),
            );

            return { ...roleRes, [role.name]: hasPermission };
          }, {} as RolesDataSet);

          return { ...actionRes, [action]: rolesDataSet };
        }, {} as ActionsDataSet);

        return { ...res, [resource]: actionsDataSet };
      }, {} as ResourcesDataSet);

      setPermissions(newPermissions);
      setConfigured(true);
    }
  }, [initialized]);

  const onChangeAllPermissions = React.useCallback((data: { resource: string; roleName: string; checked: boolean }) => {
    setPermissions((prev) => {
      const actionDataSet = Object.keys(prev[data.resource]).reduce((res, action) => {
        res[action] = {
          ...prev[data.resource][action],
          [data.roleName]: data.checked,
        };

        return res;
      }, {} as ActionsDataSet);

      return {
        ...prev,
        [data.resource]: { ...actionDataSet },
      };
    });
  }, []);

  const onChangePermissions = React.useCallback(
    (data: { resource: string; action: string; roleName: string; checked: boolean }) => {
      setPermissions((prev) => ({
        ...prev,
        [data.resource]: {
          ...prev[data.resource],
          [data.action]: {
            ...prev[data.resource][data.action],
            [data.roleName]: data.checked,
          },
        },
      }));
    },
    [],
  );

  useImperativeHandle(ref, () => ({
    getPermissions: () => permissions,
    isReady: () => initialized && configured,
  }));

  return !initialized || !configured ? (
    <Typography>Loading...</Typography>
  ) : (
    <TableContainer component={Paper} sx={{ maxHeight: 700 }}>
      <StyledTable stickyHeader size="small" aria-label="Permissions table">
        <TableHead>
          <TableRow>
            <StyledTableCell className="sticky action">Action</StyledTableCell>
            {roles.map((role) => (
              <StyledTableCell key={role.id} className="normal" align="center">
                {role.name}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(accessPermissions || {}).map(([resource, actions]) => (
            <React.Fragment key={`${resource}-block}`}>
              <PermissionsTableResourceRow
                resource={resource}
                actionsDataSet={permissions[resource]}
                onChange={onChangeAllPermissions}
              />
              {actions.map((action) => (
                <PermissionsTableActionRow
                  key={`permissions-table-action-row-${resource}-${action}`}
                  resource={resource}
                  action={action}
                  checked={permissions[resource]?.[action]}
                  onChange={onChangePermissions}
                />
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
});
