import React, { useCallback, useEffect, useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useTheme } from '@mui/material/styles';
import { Autologin } from '../../components/Autologin';
import { LogoutIdleTimer } from '../../components/LogoutIdleTimer';
import { useLogoutMutation } from '../../redux/features/auth/auth.apiSlice';
import { locations } from '../../routes/locations';
import { parseJSON } from '../../utils/json';
import { getMenuItems } from './menuItems';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { DrawerHeader } from './Sidebar/SidebarElements';
import { MenuItems } from './Sidebar/types';

export default function AppPageLayout() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [logout] = useLogoutMutation();
  // const { pathname } = useLocation();
  const [open, setOpen] = React.useState(parseJSON(localStorage.getItem('menuOpen') || 'true'));

  const handleDrawerOpen = useCallback(() => {
    localStorage.setItem('menuOpen', 'true');
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    localStorage.setItem('menuOpen', 'false');
    setOpen(false);
  }, []);

  const handleLogout = useCallback(() => {
    logout({});
    navigate(locations.login(), { replace: true });
  }, []);

  const menuItems = useRef<MenuItems>(getMenuItems({ handleLogout }));

  useEffect(() => {
    const onNotAuthorized = (event: MessageEvent) => {
      if (event.data === 'NotAuthorized') {
        window.location.replace(`${window.location.origin}/login`);
        navigate(locations.login(), { state: { from: `${location.pathname}?${location.search}` } });
      }
    };

    window.addEventListener('message', onNotAuthorized, false);

    return () => {
      window.removeEventListener('message', onNotAuthorized, false);
    };
  }, [location]);

  return (
    <Autologin>
      <Box sx={{ display: 'flex', height: '100%' }}>
        <CssBaseline />
        <LogoutIdleTimer />
        <Navbar
          open={open}
          handleDrawerClose={handleDrawerClose}
          handleDrawerOpen={handleDrawerOpen}
          handleLogout={handleLogout}
        />
        <Sidebar menuItems={menuItems.current} theme={theme} open={open} handleDrawerClose={handleDrawerClose} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            m: 0,
            p: 0,
            pt: 2,
            maxWidth: '100%',
            height: '100%',
            overflowX: 'hidden',
          }}
        >
          <DrawerHeader />
          <Outlet />
        </Box>
      </Box>
    </Autologin>
  );
}
