import { PaginatedRequest } from '../types/api';
import { OrderExecutionReportType } from '../types/orderExecution';
import {
  SaveFeeBatchRequest,
  CreateFeeBatchResponse,
  FetchFeeBatchesResponse,
  FetchFeeBatchResponse,
  UpdateFeeBatchRequest,
  UpdateFeeBatchResponse,
  UpdateFeeBatchStatusRequest,
  UpdateFeeBatchStatusResponse,
  FeeBatchStatusOption,
  CreateFeeBatchRequest,
  ImportExecutionFeeBatch,
  CreateBatchAttachmentRequest,
  CreateBatchAttachmentResponse,
  FetchBatchAttachmentsTypeOptionsResponse,
  FetchBatchAttachmentsResponse,
  FetchFeeBatchExecutionsResponse,
  ReconcileBatchExecutionsByBatchIdRequest,
  UploadBatchAttachmentRequest,
  UploadBatchAttachmentResponse,
  FetchFeeBatchStatusOptionsResponse,
  FetchConsolidateFeeRequestsResponse,
} from '../types/feeBatch';
import { FetchFeeCalculationsResponse } from '../types/feeCalculation';
import { FetchUserFeeValidationsResponse } from '../types/userFeeValidation';
// import { FetchFeeBatchExecutionReportResponse } from '../types/report';
import { FetchUserFeeDeductionsResponse } from '../types/userFeeDeduction';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';
import { FetchFeeBatchValidationResponse } from '../types/feeBatchValidation';

class FeeBatchService extends ApiService {
  pathname = 'admin';

  fetchFeeBatches = async (data: PaginatedRequest) =>
    this.get<FetchFeeBatchesResponse>(`${this.pathname}/fee-batch?${toQueryParams(data)}`);

  fetchFeeBatchesByExecutionId = async ({ executionId, ...data }: PaginatedRequest & { executionId: string }) =>
    this.get<FetchFeeBatchesResponse>(`${this.pathname}/fee-batch/execution/${executionId}?${toQueryParams(data)}`);

  fetchBatchAttachmentsTypeOptions = async () =>
    this.get<FetchBatchAttachmentsTypeOptionsResponse>(`${this.pathname}/batch-attachment/attachment-type/options`);

  fetchFeeBatchesByBatchId = async ({ batchId, ...data }: PaginatedRequest & { batchId: string }) =>
    this.get<FetchFeeBatchesResponse>(`${this.pathname}/fee-batch/${batchId}/?${toQueryParams(data)}`);

  fetchFeeBatchExecutionsByBatchId = async (batchId: string) =>
    this.get<FetchFeeBatchExecutionsResponse>(`${this.pathname}/fee-batch/${batchId}/executions`);

  // fetchBatchExecutionsReportByBatchId = async (batchId: string, reportType: OrderExecutionReportType) =>
  //   this.get<FetchFeeBatchExecutionReportResponse>(
  //     `${this.pathname}/fee-batch/${batchId}/executions-report`,
  //     {
  //       params: { reportType },
  //     },
  //   );

  discardBatchExecutionsByBatchId = async (batchId: string) =>
    this.post(`${this.pathname}/fee-batch/${batchId}/executions-discard`, {});

  approveBatchExecutionsByBatchId = async (batchId: string) =>
    this.post(`${this.pathname}/fee-batch/${batchId}/executions-approve`, {});

  changeBatchExecutionsToReconciliationByBatchId = async (batchId: string) =>
    this.post(`${this.pathname}/fee-batch/${batchId}/executions-to-reconciliation`, {});

  reconcileBatchExecutionsByBatchId = async ({ batchId, file }: ReconcileBatchExecutionsByBatchIdRequest) => {
    const formData = new FormData();
    formData.append('file', file);

    return this.post(`${this.pathname}/fee-batch/${batchId}/executions-reconcile`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  fetchUserFeeDeductionsByBatchId = async ({ batchId, ...data }: PaginatedRequest & { batchId: string }) =>
    this.get<FetchUserFeeDeductionsResponse>(
      `${this.pathname}/fee-batch/${batchId}/user-fee-deduction?${toQueryParams(data)}`,
    );

  fetchFeeCalculationsByBatchId = async ({ batchId, ...data }: PaginatedRequest & { batchId: string }) =>
    this.get<FetchFeeCalculationsResponse>(
      `${this.pathname}/fee-batch/${batchId}/fee-calculation?${toQueryParams(data)}`,
    );

  fetchUserFeeValidationsByBatchId = async ({ batchId, ...data }: PaginatedRequest & { batchId: string }) =>
    this.get<FetchUserFeeValidationsResponse>(
      `${this.pathname}/fee-batch/${batchId}/user-fee-validation?${toQueryParams(data)}`,
    );

  fetchFeeBatchValidationByBatchId = async (batchId: string) =>
    this.get<FetchFeeBatchValidationResponse>(`${this.pathname}/fee-batch/${batchId}/fee-batch-validation`);

  fetchConsolidateFeeRequestsByBatchId = async ({ batchId, ...data }: PaginatedRequest & { batchId: string }) =>
    this.get<FetchConsolidateFeeRequestsResponse>(
      `${this.pathname}/fee-batch/${batchId}/consolidate-fee-request?${toQueryParams(data)}`,
    );

  fetchFeeBatch = async (id: number) => this.get<FetchFeeBatchResponse>(`${this.pathname}/fee-batch/${id}`);

  deleteFeeBatch = async (batchId: string) =>
    this.delete<FetchFeeBatchResponse>(`${this.pathname}/fee-batch/${batchId}`);

  createFeeBatch = async (portfolio: CreateFeeBatchRequest) =>
    this.post<CreateFeeBatchResponse, CreateFeeBatchRequest>(`${this.pathname}/fee-batch`, portfolio);

  saveFeeBatch = async (portfolio: SaveFeeBatchRequest) =>
    this.post<CreateFeeBatchResponse, SaveFeeBatchRequest>(`${this.pathname}/fee-batch`, portfolio);

  updateFeeBatch = async ({ id, ...portfolio }: UpdateFeeBatchRequest) =>
    this.put<UpdateFeeBatchResponse, Omit<UpdateFeeBatchRequest, 'id'>>(`${this.pathname}/fee-batch/${id}`, portfolio);

  updateFeeBatchStatus = async ({ id, status }: UpdateFeeBatchStatusRequest) =>
    this.put<UpdateFeeBatchStatusResponse, Omit<UpdateFeeBatchStatusRequest, 'id'>>(
      `${this.pathname}/fee-batch/${id}/status`,
      { status },
    );

  executeFeeBatch = async (batchId: string) => this.post(`${this.pathname}/fee-batch/${batchId}/execute`, {});

  cancelExecutionFeeBatch = async (batchId: string) =>
    this.post(`${this.pathname}/fee-batch/${batchId}/cancel-execution`, {});

  consolidateFeeCalculations = async (feeCalculationIds: number[]) =>
    this.post(`${this.pathname}/fee-batch/consolidate`, { feeCalculationIds });

  importExecutionFileFeeBatch = async (payload: ImportExecutionFeeBatch) => {
    const formData = new FormData();
    formData.append('file', payload.file);

    if (payload.base) {
      formData.append('base', payload.base);
    }

    return this.post(`${this.pathname}/fee-batch/${payload.batchId}/import-execution-file`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  importConsolidateExecutionFileFeeBatch = async (payload: ImportExecutionFeeBatch) => {
    const formData = new FormData();
    formData.append('file', payload.file);

    return this.post(`${this.pathname}/fee-batch/${payload.batchId}/import-consolidate-execution-file`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  fetchFeeBatchStatusOptions = async () =>
    this.get<FetchFeeBatchStatusOptionsResponse>(`${this.pathname}/fee-batch/status/options`);

  fetchBatchAttachments = async ({ batchId, ...data }: PaginatedRequest & { batchId: string }) =>
    this.get<FetchBatchAttachmentsResponse>(`${this.pathname}/batch-attachment/${batchId}?${toQueryParams(data)}`);

  createBatchAttachment = async ({ batchId, ...data }: CreateBatchAttachmentRequest & { batchId: string }) =>
    this.post<CreateBatchAttachmentResponse, Omit<CreateBatchAttachmentRequest, 'id'>>(
      `${this.pathname}/batch-attachment/${batchId}`,
      data,
    );

  uploadBatchAttachment = async (data: UploadBatchAttachmentRequest & { batchId: string }) => {
    const blob = new Blob([data.fileData], { type: 'text/csv' });
    const formData = new FormData();
    formData.append('file', blob, data.fileName);

    return this.post<UploadBatchAttachmentResponse, FormData>(
      `${this.pathname}/batch-attachment/${data.batchId}/upload?type=${data.type}`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );
  };
}

export const feeBatchService = new FeeBatchService();
