import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Chip from '@mui/material/Chip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { GridActionsCellItemProps, GridRenderCellParams, GridColDef, GridRowParams } from '@mui/x-data-grid-premium';
import { GridActionsCellItemLink } from '../../components/FakeLink';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { feeBatchStatusToInfo } from '../../constants/tables/feeBatch';
import { feeBatchExecutionStatusToInfo } from '../../constants/tables/feeBatchExecution';
import { usePermissions } from '../../hooks';
import { feeBatchByRequestIdSelector, feeBatchSelector } from '../../redux/modules/feeBatch/feeBatch.selectors';
import { fetchFeeBatches } from '../../redux/modules/feeBatch/feeBatch.actions';
import { ReducerState } from '../../redux/store.types';
import { locations } from '../../routes/locations';
import { FeeBatchExecutionStatus, FeeBatchStatus } from '../../types/feeBatch';
import { UserFeeDeductionTypes } from '../../types/userFeeDeduction';
import * as permissions from '../../utils/permissions';

export function FeeBatchTable() {
  const requestId = 'fee-batch-list';
  const { enqueueSnackbar } = useSnackbar();
  const tableRef = useRef<TableRef>(null);
  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
    },
    {
      field: 'batchId',
      headerName: 'Batch Id',
      type: 'string',
      width: 350,
    },
    {
      field: 'userFeeDeduction.id',
      headerName: 'Deduction Id',
      valueGetter: (params) => params.row?.userFeeDeduction?.id,
      groupingValueGetter: (params) => params.row?.userFeeDeduction?.id,
      width: 100,
    },
    {
      field: 'userFeeDeduction.type.name',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: Object.values(UserFeeDeductionTypes),
      minWidth: 150,
      valueGetter: (params) => params.row?.userFeeDeduction?.type?.name,
    },
    {
      field: 'userFeeDeduction.totalFee',
      headerName: 'Total Fee',
      type: 'number',
      valueGetter: (params) => params.row?.userFeeDeduction?.totalFee,
      width: 100,
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(FeeBatchStatus),
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = feeBatchStatusToInfo[params.row?.status?.name as FeeBatchStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.row?.status?.name;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.row?.status?.name} />;
      },
    },
    {
      field: 'feeBatchExecution.status',
      headerName: 'Execution Status',
      type: 'singleSelect',
      sortable: false,
      filterable: false,
      valueOptions: Object.values(FeeBatchExecutionStatus),
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => params.value,
      valueGetter: (params) => params.row.feeBatchExecution?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = feeBatchExecutionStatusToInfo[params.value as FeeBatchExecutionStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.value;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.value} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const canDoActions = usePermissions({ items: [permissions.PortfolioOrder.execute] });
  const { updateStatusStatus } = useSelector(feeBatchSelector);
  const { totalfeeBatchs, feeBatches, fetchStatus } = useSelector((state: ReducerState) =>
    feeBatchByRequestIdSelector(state, requestId),
  );
  const loading = fetchStatus === fetchStatuses.pending;
  // TODO: Find a way to store this
  // const [initialFilterModel, setInitialFilterModel] = useSearchParamsState('filter', '');
  // const [initialSortModel, setInitialSortModel] = useSearchParamsState(
  //   'sort',
  //   JSON.stringify([{ field: 'id', sort: 'desc' }]),
  // );
  // const [initialPage, setInitialPage] = useSearchParamsState('page', '0');
  // const [initialPageSize, setInitialPageSize] = useSearchParamsState('pageSize', '100');

  useEffect(() => {
    if (updateStatusStatus === fetchStatuses.success) {
      tableRef?.current?.reload();
      enqueueSnackbar('Fee Batch status updated!', { variant: 'success' });
    }
    if (updateStatusStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Fee Batch update status error!', { variant: 'error' });
    }
  }, [updateStatusStatus]);

  useEffect(() => {
    const actionColumn = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      filterable: false,
      sortable: false,
      getActions: (params: GridRowParams) => {
        const actions: React.ReactElement<GridActionsCellItemProps>[] = [];

        if (`${params.id}`.split('/').length === 2) {
          const batchId = `${params.id}`.split('/').pop();
          actions.push(
            <GridActionsCellItemLink
              key={`${params.id}-fee-batch-list-actions`}
              icon={<OpenInNewIcon />}
              label="Link"
              href={locations.feeBatch(batchId)}
            />,
          );
        }

        return actions;
      },
    };

    if (canDoActions) {
      setColumns([actionColumn, ...columns]);
    }
  }, [canDoActions]);

  return (
    <Table
      ref={tableRef}
      fetchItems={fetchFeeBatches}
      rows={feeBatches}
      columns={columns}
      loading={loading}
      rowCount={totalfeeBatchs}
      disableRowGrouping={false}
      rowGroupingColumnMode="single"
      initialRowGroupingModel={['batchId']}
      defaultGroupingExpansionDepth={2}
      initialColumnVisibilityModel={{ batchId: false, 'userFeeDeduction.type.name': false }}
      // initialFilterModel={parseJSON(initialFilterModel)}
      // initialSortModel={parseJSON(initialSortModel)}
      // initialPage={+initialPage}
      // initialPageSize={+initialPageSize}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      sortingMode="client"
      fetchItemsCustomParams={{ requestId }}
      // requiredFilters={[{ field: 'userFeeDeduction.type.name', value: type, operator: 'is', id: 1 }]}
      // onFilterModelChange={(filterModel) => setInitialFilterModel(JSON.stringify(filterModel))}
      // onSortModelChange={(sortModel) => setInitialSortModel(JSON.stringify(sortModel))}
      // onPageChange={(page) => setInitialPage(`${page}`)}
      // onPageSizeChange={(pageSize) => setInitialPageSize(`${pageSize}`)}
    />
  );
}
