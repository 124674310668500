import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchEarlySignupUsersResponse } from '../../../types/anonymous';
import { fetchEarlySignupUsers, cleanAnonymous } from './anonymous.actions';
import { AnonymousState } from './anonymous.types';

const initialState: AnonymousState = {
  earlySignupUsers: [],
  totalEarlySignupUser: 0,
  fetchStatus: fetchStatuses.idle,
  error: null,
};

const anonymousSlice = createSlice({
  name: 'anonymous',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEarlySignupUsers.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchEarlySignupUsers.fulfilled, (state, action: PayloadAction<FetchEarlySignupUsersResponse>) => {
      state.earlySignupUsers = action.payload.data?.items;
      state.totalEarlySignupUser = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchEarlySignupUsers.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(cleanAnonymous.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const anonymousReducer = anonymousSlice.reducer;
