import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchOrderExecutionByOrderIdResponse, FetchOrderExecutionsResponse } from '../../../types/orderExecution';
import { fetchOrderExecutions, fetchOrderExecutionByOrderId, cleanOrderExecutions } from './orderExecution.actions';
import { OrderExecutionState } from './orderExecution.types';

const initialState: OrderExecutionState = {
  orderExecutions: {},
  totalOrderExecutions: {},
  fetchStatuses: {},

  orderExecutionByOrderId: {},
  fetchOrderExecutionByOrderIdStatus: fetchStatuses.idle,

  error: null,
};

const orderExecutionSlice = createSlice({
  name: 'orderExecution',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrderExecutions.pending, (state, action) => {
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(fetchOrderExecutions.fulfilled, (state, action: PayloadAction<FetchOrderExecutionsResponse>) => {
      const requestId = (action as any).meta.arg.requestId || (action as any).meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.success;

      if (action.payload) {
        state.orderExecutions[requestId] = action.payload.data?.items;
        state.totalOrderExecutions[requestId] = action.payload.data?.totalItems;
      }
    });
    builder.addCase(fetchOrderExecutions.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.rejected;

      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchOrderExecutionByOrderId.pending, (state) => {
      state.error = null;
      state.fetchOrderExecutionByOrderIdStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchOrderExecutionByOrderId.fulfilled,
      (state, action: PayloadAction<FetchOrderExecutionByOrderIdResponse>) => {
        const orderId = (action as any).meta.arg || (action as any).payload.data?.orderId;

        state.orderExecutionByOrderId[orderId] = action.payload.data;
        state.fetchOrderExecutionByOrderIdStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchOrderExecutionByOrderId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.fetchOrderExecutionByOrderIdStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(cleanOrderExecutions.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const orderExecutionReducer = orderExecutionSlice.reducer;
