import { PaginatedRequest } from '../types/api';
import {
  FetchAnbPaymentDetailsResponse,
  FetchPaymentApiLogsResponse,
  FetchPaymentStatementsResponse,
  FetchAnbBalanceResponse,
} from '../types/payment';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class PaymentService extends ApiService {
  pathname = 'admin/payment';

  fetchAnbPaymentDetails = async (paymentId: string) =>
    this.get<FetchAnbPaymentDetailsResponse>(`${this.pathname}/anb/${paymentId}`);

  fetchStatements = async (data: PaginatedRequest) =>
    this.get<FetchPaymentStatementsResponse>(`${this.pathname}/statements?${toQueryParams(data)}`);

  fetchApiLogs = async (data: PaginatedRequest) =>
    this.get<FetchPaymentApiLogsResponse>(`${this.pathname}/api-logs?${toQueryParams(data)}`);

  fetchAnbBalance = async () => this.get<FetchAnbBalanceResponse>(`${this.pathname}/balance`);
}

export const paymentService = new PaymentService();
