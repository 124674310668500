import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { fundManagerFormFields } from '../../forms/fields/formFields';
import { fundManagerFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import { createFundManager, updateFundManager } from '../../redux/modules/fundManager/fundManager.actions';
import { fundManagerSelector } from '../../redux/modules/fundManager/fundManager.selectors';
import { FundManager } from '../../types/fundManager';

interface FundManagerDialogProps {
  open: boolean;
  initialValue: FundManager | null;
  onClose: (isReload: boolean) => void;
}

export function FundManagerDialog({ open, initialValue, onClose }: FundManagerDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, values, handleChange, touched, errors, submitForm, setValues, resetForm } = useFormik({
    initialValues: {
      [fundManagerFormFields.nameEn]: '',
      [fundManagerFormFields.nameAr]: '',
      [fundManagerFormFields.descriptionEn]: '',
      [fundManagerFormFields.descriptionAr]: '',
      [fundManagerFormFields.locationEn]: '',
      [fundManagerFormFields.locationAr]: '',
    },
    validationSchema: fundManagerFormSchema,
    onSubmit: (formValues) => {
      if (initialValue?.id) {
        dispatch(updateFundManager({ id: initialValue.id, ...formValues }));
      } else {
        dispatch(createFundManager(formValues));
      }
    },
  });
  const { createStatus, updateStatus, error } = useSelector(fundManagerSelector);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    setValues({
      nameEn: initialValue?.nameEn || '',
      nameAr: initialValue?.nameAr || '',
      descriptionEn: initialValue?.descriptionEn || '',
      descriptionAr: initialValue?.descriptionAr || '',
      locationEn: initialValue?.locationEn || '',
      locationAr: initialValue?.locationAr || '',
    });
  }, [initialValue]);

  useEffect(() => {
    if (createStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Fund Manager created!', { variant: 'success' });
    }
    if (createStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Fund Manager creation error!', { variant: 'error' });
    }

    if (updateStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Fund Manager updated!', { variant: 'success' });
    }
  }, [createStatus, updateStatus]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{initialValue?.id ? 'Edit fundManager' : 'Create a new FundManager'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error && <Alert severity="error">{error.message}</Alert>}
          Enter the Fund Manager name
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="nameEn"
            name={fundManagerFormFields.nameEn}
            label="Name En"
            value={values[fundManagerFormFields.nameEn]}
            onChange={handleChange}
            error={touched.nameEn && !!errors.nameEn}
            helperText={touched.nameEn && errors.nameEn}
          />
          <TextField
            dir="rtl"
            margin="normal"
            required
            fullWidth
            id="nameAr"
            name={fundManagerFormFields.nameAr}
            label="Name Ar"
            value={values[fundManagerFormFields.nameAr]}
            onChange={handleChange}
            error={touched.nameAr && !!errors.nameAr}
            helperText={touched.nameAr && errors.nameAr}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            multiline
            id={fundManagerFormFields.descriptionEn}
            name={fundManagerFormFields.descriptionEn}
            label="Description En"
            value={values[fundManagerFormFields.descriptionEn]}
            onChange={handleChange}
            error={touched.descriptionEn && !!errors.descriptionEn}
            helperText={touched.descriptionEn && errors.descriptionEn}
          />
          <TextField
            dir="rtl"
            margin="normal"
            required
            fullWidth
            multiline
            id={fundManagerFormFields.descriptionAr}
            name={fundManagerFormFields.descriptionAr}
            label="Description Ar"
            value={values[fundManagerFormFields.descriptionAr]}
            onChange={handleChange}
            error={touched.descriptionAr && !!errors.descriptionAr}
            helperText={touched.descriptionAr && errors.descriptionAr}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id={fundManagerFormFields.locationEn}
            name={fundManagerFormFields.locationEn}
            label="Location En"
            value={values[fundManagerFormFields.locationEn]}
            onChange={handleChange}
            error={touched.locationEn && !!errors.locationEn}
            helperText={touched.locationEn && errors.locationEn}
          />
          <TextField
            dir="rtl"
            margin="normal"
            required
            fullWidth
            id={fundManagerFormFields.locationAr}
            name={fundManagerFormFields.locationAr}
            label="Location Ar"
            value={values[fundManagerFormFields.locationAr]}
            onChange={handleChange}
            error={touched.locationAr && !!errors.locationAr}
            helperText={touched.locationAr && errors.locationAr}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={createStatus === fetchStatuses.pending}
          loadingPosition="end"
          onClick={submitForm}
        >
          {initialValue?.id ? 'Save' : 'Create'}
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
