import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { useSnackbar } from 'notistack';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { AsyncDialog, AsyncDialogRef } from '../../../../components/AsyncDialog/AsyncDialog';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { useInterval } from '../../../../hooks/useInterval';
import { useAppDispatch } from '../../../../redux/hooks';
import { updateJadwaTransfer } from '../../../../redux/modules/jadwa/jadwa.actions';
import { jadwaSelector } from '../../../../redux/modules/jadwa/jadwa.selectors';
import { IJadwaOrder, IJadwaTransfer } from '../../../../types/jadwa';
import { isDispatchApiSuccess } from '../../../../utils/api';
import { JadwaTransfer } from './JadwaTransfer';
import { JadwaOrder } from './JadwaOrder';
import { UpdateTransferDialog } from './UpdateTransferDialog';

interface JadwaDetailsProps {
  order: IJadwaOrder;
  refresh: () => void;
}

const actionDelay = 5000;

export const JadwaDetails = React.memo(({ order, refresh }: JadwaDetailsProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const interval = useInterval();
  const dispatch = useAppDispatch();
  const asyncDialogRef = useRef<AsyncDialogRef>(null);
  const [selectedTab, setSelectedTab] = React.useState<string>('0');
  const { updateTransferStatus } = useSelector(jadwaSelector);
  const isLoading = updateTransferStatus === fetchStatuses.pending || interval.time > 0;

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const updateTransfer = async (transfer: IJadwaTransfer) => {
    try {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { amount, status } = await asyncDialogRef?.current?.show(transfer);

      const result: any = await dispatch(updateJadwaTransfer({ id: transfer.id, amount, status }));

      if (isDispatchApiSuccess(result)) {
        interval.start(1000);
        refresh();

        enqueueSnackbar('Jadwa transfer updated!', { variant: 'success' });
      } else {
        enqueueSnackbar(`Jadwa transfer update Error: ${result?.error?.message}`, { variant: 'error' });
      }
    } catch (e) {
      console.error(e);
    }
  };

  React.useEffect(() => {
    if (interval.time >= actionDelay) {
      refresh();
      interval.stop();
    }
  }, [interval.time, actionDelay]);

  React.useEffect(
    () => () => {
      interval.stop();
    },
    [],
  );

  return (
    <>
      <JadwaOrder order={order} />
      <Grid item container flexDirection="column" sx={{ p: 0, m: 0, mt: '24px' }}>
        <Typography variant="h6">Transfers</Typography>
        <TabContext value={selectedTab}>
          <Box sx={{ width: '100%' }}>
            <TabList
              value={selectedTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="jadwa fund orders"
            >
              {order.transfers.map((transfer, index) => (
                <Tab
                  key={`jadwa-details-order-${order.id}-transfer-${transfer.id}`}
                  value={index.toString()}
                  label={`Transfer #${transfer.id}`}
                  sx={{ textTransform: 'capitalize' }}
                  disableRipple
                />
              ))}
            </TabList>
          </Box>
          {order.transfers.map((transfer, index) => (
            <TabPanel
              key={`jadwa-details-order-${order.id}-panel-transfer-${transfer.id}`}
              value={index.toString()}
              sx={{ width: '100%' }}
            >
              <JadwaTransfer transfer={transfer} updateTransfer={updateTransfer} isLoading={isLoading} />
            </TabPanel>
          ))}
        </TabContext>
      </Grid>

      <AsyncDialog ref={asyncDialogRef} dialog={UpdateTransferDialog} />
    </>
  );
});
