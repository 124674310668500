import * as React from 'react';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { GridFilterInputValueProps, GridFilterItem, GridFilterOperator } from '@mui/x-data-grid-premium';
import SyncIcon from '@mui/icons-material/Sync';

const SUBMIT_FILTER_STROKE_TIME = 500;

function InputNumberInterval(props: GridFilterInputValueProps) {
  const { item, applyValue, focusElementRef = null } = props;

  const filterTimeout = React.useRef<any>();
  const [filterValueState, setFilterValueState] = React.useState<[string, string]>(item.value ?? '');
  const [applying, setIsApplying] = React.useState(false);

  React.useEffect(
    () => () => {
      clearTimeout(filterTimeout.current);
    },
    [],
  );

  React.useEffect(() => {
    const itemValue = item.value ?? [undefined, undefined];
    setFilterValueState(itemValue);
  }, [item.value]);

  const updateFilterValue = (path: string, value: string) => {
    clearTimeout(filterTimeout.current);
    setFilterValueState([path, value]);

    setIsApplying(true);
    filterTimeout.current = setTimeout(() => {
      setIsApplying(false);
      applyValue({ ...item, value: [path, value] });
    }, SUBMIT_FILTER_STROKE_TIME);
  };

  const handleValueFilterChange: TextFieldProps['onChange'] = (event) => {
    const newValue = event.target.value;

    updateFilterValue(filterValueState[0], newValue);
  };
  const handlePathFilterChange: TextFieldProps['onChange'] = (event) => {
    const newPath = event.target.value;

    updateFilterValue(newPath, filterValueState[1]);
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'end',
        height: 48,
        pl: '20px',
      }}
    >
      <TextField
        name="json-pach-input"
        placeholder="Patch"
        label="path"
        variant="standard"
        value={filterValueState[0]}
        onChange={handlePathFilterChange}
        type="string"
        inputRef={focusElementRef}
        sx={{ mr: 2 }}
      />
      <TextField
        name="json-value-input"
        placeholder="Value"
        label="value"
        variant="standard"
        value={filterValueState[1]}
        onChange={handleValueFilterChange}
        type="string"
        InputProps={applying ? { endAdornment: <SyncIcon /> } : {}}
      />
    </Box>
  );
}

export const jsonFilterOperators: GridFilterOperator[] = [
  {
    label: 'Json',
    value: 'json',
    getApplyFilterFn: (filterItem: GridFilterItem) => {
      if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
        return null;
      }
      if (filterItem.value[0] == null || filterItem.value[1] == null) {
        return null;
      }

      return ({ value }) => value !== null && filterItem.value[0] <= value && value <= filterItem.value[1];
    },
    InputComponent: InputNumberInterval,
    getValueAsString: (v) => JSON.stringify(v),
  },
];
