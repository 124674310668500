import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { GridActionsCellItem, GridActionsCellItemProps, GridRowParams, GridColDef } from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { usePermissions, useSearchParamsState } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import {
  cleanProductTypes,
  deleteProductType,
  fetchProductTypes,
} from '../../redux/modules/productType/product-type.actions';
import { productTypeSelector } from '../../redux/modules/productType/product-type.selectors';
import { ProductType } from '../../types/productType';
import { parseJSON } from '../../utils/json';
import * as permissions from '../../utils/permissions';
import { ProductTypeDialog } from './ProductTypeDialog';
import { ProtectedContent } from '../../components/ProtectedContent';

export function ProductTypeList() {
  const dispatch = useAppDispatch();
  const tableRef = useRef<TableRef>(null);
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState<ProductType | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'nameEn',
      headerName: 'Name En',
      flex: 1,
    },
    {
      field: 'nameAr',
      headerName: 'Name Ar',
      flex: 1,
    },
    {
      field: 'descriptionEn',
      headerName: 'Description En',
      flex: 1,
    },
    {
      field: 'descriptionAr',
      headerName: 'Description Ar',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      width: 200,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 200,
    },
  ]);
  const canDelete = usePermissions({ items: [permissions.ProductType.delete] });
  const canUpdate = usePermissions({ items: [permissions.ProductType.update] });
  const [initialFilterModel, setInitialFilterModel] = useSearchParamsState('filter', '');
  const [initialPage, setInitialPage] = useSearchParamsState('page', '0');
  const [initialPageSize, setInitialPageSize] = useSearchParamsState('pageSize', '10');
  const [initialSortModel, setInitialSortModel] = useSearchParamsState('sort', '');

  const onDeleteProductType = (id: number) => {
    dispatch(deleteProductType(id));
  };

  const onEditProductType = (row: ProductType): void => {
    setEditValue(row);
    setOpen(true);
  };

  const { totalProductTypes, productTypes, fetchStatus, deleteStatus } = useSelector(productTypeSelector);

  const onAddNew = (): void => {
    setOpen(true);
  };

  const handleClose = (isReload: boolean) => {
    if (isReload) {
      tableRef?.current?.reload();
    }
    setEditValue(null);
    setOpen(false);
  };

  useEffect(() => {
    const actionColumn = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      getActions: (params: GridRowParams) => {
        const actions: React.ReactElement<GridActionsCellItemProps>[] = [];

        if (canDelete) {
          actions.push(
            <GridActionsCellItem
              key={`product-type-list-delete-${params.row.id}`}
              icon={<DeleteOutlineOutlinedIcon />}
              label="Link"
              onClick={() => onDeleteProductType(params.row.id)}
            />,
          );
        }

        if (canUpdate) {
          actions.push(
            <GridActionsCellItem
              key={`product-type-list-update-${params.row.id}`}
              icon={<EditOutlinedIcon />}
              label="Actions"
              onClick={() => onEditProductType(params.row)}
            />,
          );
        }

        return actions;
      },
    };

    if (canDelete || canUpdate) {
      setColumns([actionColumn, ...columns]);
    }
  }, [canDelete, canUpdate]);

  useEffect(
    () => () => {
      dispatch(cleanProductTypes());
    },
    [],
  );

  useEffect(() => {
    if (deleteStatus === fetchStatuses.success) {
      handleClose(true);
      enqueueSnackbar('Product Type deleted!', { variant: 'success' });
    }
    if (deleteStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Product Type deletion error!', { variant: 'error' });
    }
  }, [deleteStatus]);

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">
          Product Types &nbsp;
          <ProtectedContent items={[permissions.ProductType.create]}>
            <Fab color="primary" size="small" aria-label="Create Product Type" onClick={onAddNew}>
              <AddIcon />
            </Fab>
          </ProtectedContent>
        </Typography>
      </Box>

      <ProductTypeDialog open={open} onClose={handleClose} initialValue={editValue} />

      <Table
        ref={tableRef}
        fetchItems={fetchProductTypes}
        rows={productTypes}
        columns={columns}
        loading={fetchStatus === fetchStatuses.pending}
        rowCount={totalProductTypes}
        initialFilterModel={parseJSON(initialFilterModel)}
        initialPage={+initialPage}
        initialPageSize={+initialPageSize}
        initialSortModel={parseJSON(initialSortModel)}
        onSortModelChange={(sortModel) => setInitialSortModel(JSON.stringify(sortModel))}
        onFilterModelChange={(filterModel) => setInitialFilterModel(JSON.stringify(filterModel))}
        onPageChange={(page) => setInitialPage(`${page}`)}
        onPageSizeChange={(pageSize) => setInitialPageSize(`${pageSize}`)}
      />
    </Container>
  );
}
