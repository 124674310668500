import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useAppDispatch } from '../../redux/hooks';
import { fetchPortfoliosPerformance } from '../../redux/modules/analytics/analytics.actions';
import { analyticsSelector } from '../../redux/modules/analytics/analytics.selectors';
import { round2Format } from '../../utils/number';

export function PortfoliosPerformanceWidget() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchPortfoliosPerformance());
  }, []);

  const { portfoliosPerformance } = useSelector(analyticsSelector);

  const renderItem = ({ metricName, metricValue, index }) => (
    <Grid
      item
      container
      sm={12}
      md={6}
      lg={4}
      sx={{ mb: 2 }}
      key={`portfolio-performance-widget-element-${metricName}-${index}`}
    >
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">{metricName}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} flexDirection="row" display="flex" alignItems="center">
        <Typography sx={{ ml: 1 }} variant="body2">
          {round2Format(+(metricValue || 0))}
        </Typography>
      </Grid>
    </Grid>
  );

  const renderPortfoliosPerformanceWidgets = () => (
    <Grid item container flexDirection="row" sx={{ mt: 0 }} xs={12}>
      {portfoliosPerformance.map(({ portfolioName, metrics }, i: number) => (
        <Grid container flexDirection="column" key={`portfolio-performance-widget-${portfolioName}`} sx={{ mb: 4 }}>
          <Grid item container xs={12}>
            <Typography variant="subtitle1" fontWeight="bold">
              {portfolioName}
            </Typography>

            <Grid item container flexDirection="row" sx={{ mt: 0 }} xs={12}>
              {Object.entries(metrics).map(([metricName, metricValue], index) =>
                renderItem({ metricName, metricValue, index }),
              )}
            </Grid>

            {i < portfoliosPerformance.length - 1 && (
              <Grid item sx={{ my: 2 }} xs={12}>
                <Divider variant="inset" />
              </Grid>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Grid item container sx={{ mt: 4 }}>
      {renderPortfoliosPerformanceWidgets()}
    </Grid>
  );
}
