export enum ApiTagType {
  Role = 'Role',
  AlpacaOrder = 'AlpacaOrder',
  RolePermission = 'RolePermission',
  WithdrawRequest = 'WithdrawRequest',
  UserFeeDeductionRequest = 'UserFeeDeductionRequest',
  JadwaTransfer = 'JadwaTransfer',
  SubscriberPortfolio = 'SubscriberPortfolio',
  UserIndexFund = 'UserIndexFund',
}
