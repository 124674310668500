import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { LedgerTable } from './LedgerTable';

interface LedgerProps {
  fetchAccounts: () => any;
  accountId: number;
  fetchLedger: (params: { account: string[] }) => any;
  displayAccounts?: boolean;
}

export function Ledger({ fetchAccounts, fetchLedger, displayAccounts = false, accountId }: LedgerProps) {
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);

  const { data: ledgerAccounts } = fetchAccounts();

  useEffect(() => {
    if (!ledgerAccounts?.length) {
      return;
    }

    const filteredAccounts = ledgerAccounts.filter((account) => account.includes(`:${accountId}`));

    setSelectedAccounts(filteredAccounts);
  }, [ledgerAccounts]);

  return (
    <Grid container>
      {displayAccounts && (
        <Grid item>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            value={selectedAccounts}
            onChange={(event: any, newValue: string[]) => {
              setSelectedAccounts(newValue);
            }}
            options={ledgerAccounts || []}
            sx={{ width: 300 }}
            multiple
            renderInput={(params) => <TextField {...params} label="Account" />}
          />
        </Grid>
      )}
      {selectedAccounts?.length > 0 && <LedgerTable accounts={selectedAccounts} fetchLedger={fetchLedger} />}
    </Grid>
  );
}
