import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import PaymentsIcon from '@mui/icons-material/Payments';
import InsightsIcon from '@mui/icons-material/Insights';
import ApiIcon from '@mui/icons-material/Api';
import { useSearchParamsState } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import { cleanPayments } from '../../redux/modules/payment/payment.actions';
import { ApiLogsTab } from './ApiLogsTab';
import { StatementsTab } from './StatementsTab';
import { AlpacaTransfersTab } from './AlpacaTransfersTab';
import { AnbTab } from './AnbTab';
import { MastercardSessionsTab } from './MastercardSessionsTab';
import { UserBanksTab } from './UserBanksTab';
import { WithdrawsTab } from './WithdrawsTab';
import { JadwaTransfersTab } from './JadwaTransfersTab';
import { HyperPaySessionsTab } from './HyperPaySessionsTab';

enum TABS {
  STATEMENTS = 'statements',
  WITHDRAWS = 'withdraws',
  API_LOGS = 'apiLogs',
  ALPACA_TRANSFERS = 'alpacaTransfers',
  JADWA_TRANSFERS = 'jadwaTransfers',
  ANB = 'anb',
  MASTERCARD_SESSIONS = 'mastercardSessions',
  HYPER_PAY_SESSIONS = 'hyperPaySessions',
  USER_BANKS = 'userBanks',
}

export function Payment() {
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useSearchParamsState('tab', TABS.STATEMENTS);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  useEffect(
    () => () => {
      dispatch(cleanPayments());
    },
    [],
  );

  const renderTabList = () => (
    <TabContext value={selectedTab}>
      <Box>
        <TabList
          value={selectedTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="Payment tabs"
        >
          <Tab
            icon={<PaymentsIcon />}
            iconPosition="start"
            label="Statements"
            value={TABS.STATEMENTS}
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<PaymentsIcon />}
            iconPosition="start"
            label="Withdraws"
            value={TABS.WITHDRAWS}
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<ApiIcon />}
            iconPosition="start"
            label="Api Logs"
            value={TABS.API_LOGS}
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<InsightsIcon />}
            iconPosition="start"
            label="Alpaca Transfers"
            value={TABS.ALPACA_TRANSFERS}
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<InsightsIcon />}
            iconPosition="start"
            label="Jadwa Transfers"
            value={TABS.JADWA_TRANSFERS}
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<InsightsIcon />}
            iconPosition="start"
            label="ANB"
            value={TABS.ANB}
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<InsightsIcon />}
            iconPosition="start"
            label="Mastercard Sessions"
            value={TABS.MASTERCARD_SESSIONS}
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<InsightsIcon />}
            iconPosition="start"
            label="HyperPay Sessions"
            value={TABS.HYPER_PAY_SESSIONS}
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<InsightsIcon />}
            iconPosition="start"
            label="User Banks"
            value={TABS.USER_BANKS}
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
        </TabList>
      </Box>
      <TabPanel value={TABS.STATEMENTS}>
        <StatementsTab />
      </TabPanel>
      <TabPanel value={TABS.WITHDRAWS}>
        <WithdrawsTab />
      </TabPanel>
      <TabPanel value={TABS.API_LOGS}>
        <ApiLogsTab />
      </TabPanel>
      <TabPanel value={TABS.ALPACA_TRANSFERS}>
        <AlpacaTransfersTab />
      </TabPanel>
      <TabPanel value={TABS.JADWA_TRANSFERS}>
        <JadwaTransfersTab />
      </TabPanel>
      <TabPanel value={TABS.ANB}>
        <AnbTab />
      </TabPanel>
      <TabPanel value={TABS.MASTERCARD_SESSIONS}>
        <MastercardSessionsTab />
      </TabPanel>
      <TabPanel value={TABS.HYPER_PAY_SESSIONS}>
        <HyperPaySessionsTab />
      </TabPanel>
      <TabPanel value={TABS.USER_BANKS}>
        <UserBanksTab />
      </TabPanel>
    </TabContext>
  );

  return (
    <Container component="main" maxWidth="xl">
      {renderTabList()}
    </Container>
  );
}
