import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { useAppDispatch } from '../../../../redux/hooks';
import { fetchUserFees } from '../../../../redux/modules/userFee/userFee.actions';
import { userSelector } from '../../../../redux/modules/user/user.selectors';
import { UserFeesTabList } from './UserFeesTabList';
import { productTypeSelector } from '../../../../redux/modules/productType/product-type.selectors';
import { fetchProductTypes } from '../../../../redux/modules/productType/product-type.actions';
import { FeeDetailsTab } from './FeeDetailsTab';

interface FeesTabProps {
  userId: number;
}

export function FeesTab({ userId }: FeesTabProps) {
  const dispatch = useAppDispatch();
  const { error } = useSelector(userSelector);
  const { productTypes, fetchStatus } = useSelector(productTypeSelector);
  const isLoading = fetchStatus === fetchStatuses.pending;

  useEffect(() => {
    dispatch(fetchProductTypes({ limit: 100, offset: 0 }));
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(
        fetchUserFees({
          limit: 100,
          offset: 0,
          sortField: 'id',
          sortOrder: 'desc',
          filter: JSON.stringify({
            items: [
              { columnField: 'userId', operatorValue: '=', value: userId },
              // { columnField: 'status.name', operatorValue: 'is', value: SubscribedPortfolioStatus.ACCEPTED },
            ],
          }),
          // relations: ['subscribedPortfolioAssociatedIndexFund'],
        }),
      );
    }
  }, []);

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      <Grid
        item
        container
        flexDirection="column"
        component={Paper}
        sx={{ p: '24px', mt: 0, height: '450px' }}
        spacing={2}
      >
        <Grid item>
          <Typography variant="h6">Fee Details</Typography>
        </Grid>
        <Grid item sx={{ position: 'relative', width: '100%' }}>
          {!isLoading && <FeeDetailsTab />}
        </Grid>
      </Grid>
      <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}>
        <Grid item>
          <Typography variant="h6">User Fees</Typography>
        </Grid>
        <Grid item sx={{ position: 'relative', width: '100%' }}>
          {!isLoading && <UserFeesTabList userId={userId} productTypes={productTypes} />}
        </Grid>
      </Grid>
    </>
  );
}
