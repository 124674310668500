import { PaginatedRequest } from '../types/api';
import {
  CreateBenchmarkRequest,
  CreateBenchmarkResponse,
  FetchBenchmarkNavDataResponse,
  FetchBenchmarkResponse,
  FetchBenchmarksAutocompleteResponse,
  FetchBenchmarksResponse,
  ImportBenchmarkNavDateFile,
  UpdateBenchmarkRequest,
  UpdateBenchmarkResponse,
} from '../types/benchmark';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class BenchmarkService extends ApiService {
  pathname = 'admin';

  fetchBenchmarks = async (data: PaginatedRequest) =>
    this.get<FetchBenchmarksResponse>(`${this.pathname}/benchmark?${toQueryParams(data)}`);

  fetchBenchmarksAutocomplete = async (term: string) =>
    this.get<FetchBenchmarksAutocompleteResponse>(`${this.pathname}/benchmark/autocomplete?term=${term}`);

  fetchBenchmark = async (id: number) => this.get<FetchBenchmarkResponse>(`${this.pathname}/benchmark/${id}`);

  deleteBenchmark = async (id: number) => this.delete<FetchBenchmarkResponse>(`${this.pathname}/benchmark/${id}`);

  createBenchmark = async (benchmark: CreateBenchmarkRequest) =>
    this.post<CreateBenchmarkResponse, CreateBenchmarkRequest>(`${this.pathname}/benchmark`, benchmark);

  updateBenchmark = async ({ id, ...benchmark }: UpdateBenchmarkRequest) =>
    this.put<UpdateBenchmarkResponse, Omit<UpdateBenchmarkRequest, 'id'>>(
      `${this.pathname}/benchmark/${id}`,
      benchmark,
    );

  importNavDataFile = async (payload: ImportBenchmarkNavDateFile) => {
    const formData = new FormData();
    formData.append('file', payload.file);

    return this.post(`${this.pathname}/benchmark/${payload.benchmarkId}/import-nav-data-file`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  fetchNavDataByFund = async (id: number) =>
    this.get<FetchBenchmarkNavDataResponse>(`${this.pathname}/benchmark/${id}/nav-data`);
}

export const benchmarkService = new BenchmarkService();
