import Grid from '@mui/material/Grid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import COLORS from '../../../constants/colors';
import { authSelector } from '../../../redux/features/auth/auth.selectors';
import { AppBar } from './NavbarElements';

interface INavbarProps {
  open: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  handleLogout: () => void;
}

function Navbar({ open, handleDrawerOpen, handleDrawerClose, handleLogout }: INavbarProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user } = useSelector(authSelector);
  const { i18n } = useTranslation();
  // const isArabic = i18n.language === 'ar';

  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-account-menu';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleChangeLanguage = () => {
    i18n.changeLanguage(i18n.language === 'en' ? 'ar' : 'en');
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar position="fixed" open={open} elevation={0}>
        <Toolbar
          sx={{
            padding: '28px 32px!important',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          component={Grid}
        >
          <Grid item display="flex" justifyContent="center" alignItems="center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={open ? handleDrawerClose : handleDrawerOpen}
              sx={open ? { ml: 0 } : { ml: '96px' }}
              edge="start"
            >
              <MenuIcon sx={{ color: COLORS.MAIN_DARK, fontSize: '24px' }} />
            </IconButton>
            <Typography variant="h6" noWrap component="div" />
          </Grid>
          <Grid item sx={{ flexGrow: 1 }} />
          <Grid container justifyContent="end" alignItems="center" columnSpacing="33px">
            {/* <Grid item> */}
            {/*   <Button */}
            {/*     onClick={handleChangeLanguage} */}
            {/*     sx={{ */}
            {/*       '&:hover': { */}
            {/*         backgroundColor: COLORS.TRANSPARENT, */}
            {/*       }, */}
            {/*     }} */}
            {/*   > */}
            {/*     <Typography variant={isArabic ? 'body1' : 'cairoM'} color="black" sx={{ textTransform: 'capitalize' }}> */}
            {/*       {language} */}
            {/*     </Typography> */}
            {/*   </Button> */}
            {/* </Grid> */}
            {/* <Grid item> */}
            {/*  <IconButton */}
            {/*    size="small" */}
            {/*    edge="end" */}
            {/*    aria-label="settings" */}
            {/*  > */}
            {/*    <NotificationsOutlinedIcon sx={{ color: COLORS.MAIN_DARK, fontSize: "24px" }} /> */}
            {/*  </IconButton> */}
            {/* </Grid> */}
            {/* <Grid item> */}
            {/*  <IconButton */}
            {/*    size="small" */}
            {/*    edge="end" */}
            {/*    aria-label="settings" */}
            {/*  > */}
            {/*    <SettingsOutlinedIcon sx={{ color: COLORS.MAIN_DARK, fontSize: "24px" }} /> */}
            {/*  </IconButton> */}
            {/* </Grid> */}
            <Grid item>
              <Typography variant="h6" noWrap component="div" color={COLORS.MAIN_DARK}>
                Welcome, {user?.name}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar sx={{ width: 32, height: 32 }}>
                  {user?.name
                    .split(' ')
                    .slice(0, 2)
                    .map((i: string) => i.charAt(0))
                    .join('')}
                </Avatar>
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </>
  );
}

export default Navbar;
