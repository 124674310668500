import { ApiResponse } from './api';

export enum AnalyticsConfigKey {
  TOTAL_VISITORS = 'total_visitors',
  CONVERSION_STAGE = 'conversion_stage',
}

export type AnalyticsConfig = {
  key: AnalyticsConfigKey;
  value: string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchAnalyticsConfigResponse = ApiResponse<AnalyticsConfig>;
export type FetchAnalyticsConfigsResponse = ApiResponse<AnalyticsConfig[]>;

export type CreateAnalyticsConfigRequest = Omit<AnalyticsConfig, 'id' | 'createdAt' | 'updatedAt'>;
export type CreateAnalyticsConfigResponse = ApiResponse<AnalyticsConfig>;

export type UpdateAnalyticsConfigRequest = Omit<AnalyticsConfig, 'key' | 'createdAt' | 'updatedAt'> & { key: string };
export type UpdateAnalyticsConfigsRequest = {
  items: UpdateAnalyticsConfigRequest[];
};
export type UpdateAnalyticsConfigResponse = ApiResponse<AnalyticsConfig>;
