import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { analyticsService } from '../../../api';

export const fetchUsersStats = createAsyncThunk('analytics/users-stats', () => analyticsService.fetchUsersStats());

export const fetchFunnelChart = createAsyncThunk('analytics/funnel-chart', () => analyticsService.fetchFunnelChart());

export const fetchRfmChart = createAsyncThunk('analytics/rfm-chart', () => analyticsService.fetchRfmChart());

export const fetchPortfolioStats = createAsyncThunk('analytics/portfolio-stats', () =>
  analyticsService.fetchPortfolioStats(),
);

export const fetchPortfoliosPerformance = createAsyncThunk('analytics/portfolios-performance', () =>
  analyticsService.fetchPortfoliosPerformance(),
);

export const cleanAnalytics = createAction('analytics/clean');
