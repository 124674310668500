import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import Chip from '@mui/material/Chip';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { consolidateRequestStatusToInfo } from '../../constants/tables/consolidateRequest';
import { fetchConsolidateRequestsByOrderId } from '../../redux/modules/portfolioOrder/portfolioOrder.actions';
import {
  portfolioOrderSelector,
  viewDetailsByOrderIdSelector,
} from '../../redux/modules/portfolioOrder/portfolioOrder.selectors';
import { ConsolidateRequestStatus } from '../../types/portfolioOrder';
import { round2Format, round4Format } from '../../utils/number';

interface ConsolidateRequestsTabProps {
  orderId: string;
}

export function ConsolidateRequestsTab({ orderId }: ConsolidateRequestsTabProps) {
  const tableRef = useRef<TableRef>(null);
  const [columns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Id',
      width: 100,
    },
    {
      field: 'sellAmount',
      headerName: 'Sell Amount',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'sellNumOfUnits',
      headerName: 'Sell Units',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round4Format(params.value),
    },
    {
      field: 'buyAmount',
      headerName: 'Buy Amount',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'buyNumOfUnits',
      headerName: 'Buy Units',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round4Format(params.value),
    },
    {
      field: 'fundOrder',
      headerName: 'Fund',
      type: 'string',
      width: 200,
      valueGetter: (params) => params.row?.fundOrder?.indexFund?.nameEn,
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(ConsolidateRequestStatus),
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = consolidateRequestStatusToInfo[params.row?.status?.name as ConsolidateRequestStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.row?.status?.name;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.row?.status?.name} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 100,
    },
  ]);
  const { totalConsolidateRequests, consolidateRequests, fetchConsolidateRequestsStatus } =
    useSelector(viewDetailsByOrderIdSelector);
  const { executeStatus, importExecutionFileStatus } = useSelector(portfolioOrderSelector);
  const loading =
    fetchConsolidateRequestsStatus === fetchStatuses.pending ||
    importExecutionFileStatus === fetchStatuses.pending ||
    executeStatus === fetchStatuses.pending;

  useEffect(() => {
    if (importExecutionFileStatus === fetchStatuses.success || executeStatus === fetchStatuses.success) {
      tableRef.current?.reload();
    }
  }, [importExecutionFileStatus, executeStatus]);

  return (
    <Table
      ref={tableRef}
      fetchItems={fetchConsolidateRequestsByOrderId}
      rows={consolidateRequests}
      columns={columns}
      loading={loading}
      rowCount={totalConsolidateRequests}
      fetchItemsCustomParams={{ orderId }}
      toolbarExportProps={{
        csvOptions: { fileName: `consolidate-requests-${orderId}` },
        excelOptions: { fileName: `consolidate-requests-${orderId}` },
      }}
    />
  );
}
