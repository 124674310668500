import { createAsyncThunk } from '@reduxjs/toolkit';
import { mediaService } from '../../../api';
import { FetchMediaFileResponse, UploadMediaFileRequest } from '../../../types/media';

export const uploadPrivate = createAsyncThunk('names/upload-private', (data: UploadMediaFileRequest) =>
  mediaService.uploadPrivate(data),
);

export const uploadPublic = createAsyncThunk('names/upload-public', (data: UploadMediaFileRequest) =>
  mediaService.uploadPublic(data),
);

export const getPrivateFile = createAsyncThunk<FetchMediaFileResponse, string>(
  'names/get-private-file',
  (fileName: string) => mediaService.getPrivateFile(fileName),
);

export const getPublicFile = createAsyncThunk<FetchMediaFileResponse, string>(
  'names/get-public-file',
  (fileName: string) => mediaService.getPublicFile(fileName),
);
