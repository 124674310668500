import {
  FetchUsersStatsResponse,
  FetchFunnelChartResponse,
  FetchRfmChartResponse,
  FetchPortfolioStatsResponse,
  FetchPortfoliosPerformanceResponse,
} from '../types/analytics';
import { ApiService } from './common/ApiService';

class AnalyticsService extends ApiService {
  pathname = 'admin/analytics';

  fetchUsersStats = async () => this.get<FetchUsersStatsResponse>(`${this.pathname}/user-analytics/users-stats`);

  fetchFunnelChart = async () => this.get<FetchFunnelChartResponse>(`${this.pathname}/user-analytics/funnel-chart`);

  fetchRfmChart = async () => this.get<FetchRfmChartResponse>(`${this.pathname}/user-analytics/rfm-chart`);

  fetchPortfolioStats = async () =>
    this.get<FetchPortfolioStatsResponse>(`${this.pathname}/portfolio-analytics/portfolio-stats`);

  fetchPortfoliosPerformance = async () =>
    this.get<FetchPortfoliosPerformanceResponse>(`${this.pathname}/portfolio-analytics/portfolios-performance`);
}

export const analyticsService = new AnalyticsService();
