import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import { SatelliteAccountRequestStatus } from '../../types/portfolioOrder';

export const satelliteRequestStatusToInfo: {
  [prop in SatelliteAccountRequestStatus]: {
    color: 'error' | 'info' | 'success' | 'warning';
    icon: React.ElementType;
    menuAction: string;
  };
} = {
  [SatelliteAccountRequestStatus.CREATED]: {
    color: 'info',
    menuAction: 'Change to Created',
    icon: InfoIcon,
  },
  [SatelliteAccountRequestStatus.IN_PROGRESS]: {
    color: 'info',
    menuAction: 'Change to In Progress',
    icon: InfoIcon,
  },
  [SatelliteAccountRequestStatus.REJECTED]: {
    color: 'error',
    menuAction: 'Reject',
    icon: CheckCircleIcon,
  },
  [SatelliteAccountRequestStatus.EXECUTED]: {
    color: 'success',
    menuAction: 'Execute',
    icon: CheckCircleIcon,
  },
};
