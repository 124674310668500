import Chip from '@mui/material/Chip';
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAppDispatch } from '../../redux/hooks';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { CopyToClipboardButton } from '../../components/CopyToClipboardButton';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { userFeeValidationStatusToInfo } from '../../constants/tables/userFeeValidation';
import {
  fetchFeeBatchValidationByBatchId,
  fetchUserFeeValidationsByBatchId,
} from '../../redux/modules/feeBatch/feeBatch.actions';
import { feeBatchSelector, viewDetailsByBatchIdSelector } from '../../redux/modules/feeBatch/feeBatch.selectors';
import { UserFeeValidationStatus } from '../../types/userFeeValidation';
import { UserFeeDeduction } from '../../types/userFeeDeduction';
import { round2Format } from '../../utils/number';

interface FeeValidationTabProps {
  batchId: string;
}

export function FeeValidationTab({ batchId }: FeeValidationTabProps) {
  const dispatch = useAppDispatch();
  const tableRef = useRef<TableRef>(null);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const { totalUserFeeValidations, userFeeValidations, fetchUserFeeValidationsStatus } =
    useSelector(viewDetailsByBatchIdSelector);
  const { feeBatchValidation, fetchFeeBatchValidationStatus } = useSelector(viewDetailsByBatchIdSelector);
  const { executeStatus, importExecutionFileStatus } = useSelector(feeBatchSelector);

  const loading =
    fetchUserFeeValidationsStatus === fetchStatuses.pending ||
    fetchFeeBatchValidationStatus === fetchStatuses.pending ||
    importExecutionFileStatus === fetchStatuses.pending ||
    executeStatus === fetchStatuses.pending;

  const getColumns = (): GridColDef[] => [
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
      aggregable: false,
    },
    {
      field: 'userId',
      headerName: 'User',
      width: 120,
      type: 'string',
    },
    {
      field: 'calculatedFee',
      headerName: 'Calculated  Fee',
      type: 'number',
      width: 140,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'actualFee',
      headerName: 'Actual Fee',
      type: 'number',
      width: 140,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(UserFeeValidationStatus),
      minWidth: 120,
      flex: 1,
      aggregable: false,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = userFeeValidationStatusToInfo[params.row?.status?.name as UserFeeValidationStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.row?.status?.name;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.row?.status?.name} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      minWidth: 100,
      aggregable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      minWidth: 100,
      aggregable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ];

  const [columns, setColumns] = useState<GridColDef[]>(getColumns());

  useEffect(() => {
    if (importExecutionFileStatus === fetchStatuses.success || executeStatus === fetchStatuses.success) {
      tableRef.current?.reload();
    }
  }, [importExecutionFileStatus, executeStatus]);

  useEffect(() => {
    setSelectedIds([]);
  }, [userFeeValidations]);

  useEffect(() => {
    if (batchId) {
      dispatch(fetchFeeBatchValidationByBatchId(batchId));
    }
  }, []);

  const isRowSelectable = (params: GridRowParams<UserFeeDeduction>): boolean => true;

  const renderFeeValidationInfo = () => {
    if (!feeBatchValidation) {
      return;
    }

    return (
      <>
        <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: 0 }} spacing={2}>
          <Grid item container xs={8}>
            <Grid item xs={6}>
              <Typography variant="h6">Fee Batch Validation Id</Typography>
            </Grid>
            <Grid item xs={6}>
              <CopyToClipboardButton text={feeBatchValidation.id.toString()} value={feeBatchValidation.id.toString()} />
            </Grid>
          </Grid>
          <Grid item container xs={8}>
            <Grid item xs={6}>
              <Typography variant="h6">Status</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">{feeBatchValidation.status.name}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}>
          <Typography variant="h6">User Fee Validations</Typography>
          <Table
            ref={tableRef}
            fetchItems={fetchUserFeeValidationsByBatchId}
            rows={userFeeValidations}
            columns={columns}
            loading={loading}
            rowCount={totalUserFeeValidations}
            fetchItemsCustomParams={{ batchId }}
            checkboxSelection
            rowSelectionModel={selectedIds}
            isRowSelectable={isRowSelectable}
            onSelectionModelChange={(sm) => setSelectedIds(sm as number[])}
            initialAggregationModel={{
              estimatedAmount: 'sum',
              satelliteAmount: 'sum',
              actualAmount: 'sum',
              sharesCount: 'sum',
              satelliteUnits: 'sum',
              actualSharesCount: 'sum',
            }}
            toolbarExportProps={{
              csvOptions: { fileName: `user-fee-validations-${batchId}` },
              excelOptions: { fileName: `user-fee-validations-${batchId}` },
            }}
          />
        </Grid>
      </>
    );
  };

  return renderFeeValidationInfo();
}
