import { ActionType } from '../../types/action';

const readEventsPermission = {
  resource: 'alpaca-events',
  action: ActionType.READ,
};
const readOrderPermission = {
  resource: 'alpaca-order',
  action: ActionType.READ,
};
const createAccountPermission = {
  resource: 'alpaca-account',
  action: ActionType.CREATE,
};
const readAccountPermission = {
  resource: 'alpaca-account',
  action: ActionType.READ,
};
const sendToJournalQueuePermission = {
  resource: 'manual-alpaca-journal-queue',
  action: ActionType.CREATE,
};
const sendToCompleteQueuePermission = {
  resource: 'manual-alpaca-complete-queue',
  action: ActionType.CREATE,
};
const sendToTransferQueuePermission = {
  resource: 'manual-alpaca-transfer-queue',
  action: ActionType.CREATE,
};
const sendToOrderQueuePermission = {
  resource: 'manual-alpaca-order-queue',
  action: ActionType.CREATE,
};

export const AlpacaPermissions = {
  readEvents: readEventsPermission,
  readOrder: readOrderPermission,
  createAccount: createAccountPermission,
  readAccount: readAccountPermission,
  sendToJournalQueue: sendToJournalQueuePermission,
  sendToCompleteQueue: sendToCompleteQueuePermission,
  sendToTransferQueue: sendToTransferQueuePermission,
  sendToOrderQueue: sendToOrderQueuePermission,
};
