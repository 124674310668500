import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useAppDispatch } from '../../redux/hooks';
import { fetchUsersStats } from '../../redux/modules/analytics/analytics.actions';
import { analyticsSelector } from '../../redux/modules/analytics/analytics.selectors';
import { Stats } from '../../types/analytics';

export const UsersStatsWidget = () => {
  const dispatch = useAppDispatch();

  const { usersStats, fetchUsersStatsStatus } = useSelector(analyticsSelector);

  const isLoading = fetchUsersStatsStatus === fetchStatuses.pending;

  useEffect(() => {
    dispatch(fetchUsersStats());
  }, []);

  const renderStatItem = (item: Stats) => (
    <>
      <Grid container>
        <Box padding={1}>
          <Typography variant="body1" sx={{ fontSize: 14 }} color="text.secondary">
            {item.statName}
          </Typography>
        </Box>
      </Grid>
      <Grid container display="flex">
        <Grid item xs={9} padding={2}>
          <Typography variant="body1">
            <b>{item.value}</b>
          </Typography>
        </Grid>
        {/* {item.statName.includes('Rate') && <Grid xs={3} padding={2}><Typography variant="body2">%</Typography></Grid>} */}
      </Grid>
    </>
  );

  if (isLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      {usersStats.map((us) => (
        <Grid
          item
          component={Paper}
          key={`${us.statName}`}
          elevation={1}
          m={4}
          xs={12}
          sm={2}
          sx={{ borderRadius: 1.5, height: '100%' }}
        >
          {renderStatItem(us)}
        </Grid>
      ))}
    </>
  );
};
