import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, RouteObject, useRoutes } from 'react-router-dom';
import AppPageLayout from '../layouts/AppPageLayout';
import { PermissionPropsItem } from '../layouts/AppPageLayout/Sidebar/types';
import PublicPageLayout from '../layouts/PublicPageLayout';
import { ActionList } from '../pages/ActionList';
import { AssetList } from '../pages/AssetList';
import { CurrencyList } from '../pages/CurrencyList';
import { Dashboard } from '../pages/Dashboard';
import { EarlySignupUserList } from '../pages/EarlySignupUserList';
import { Employee } from '../pages/Employee';
import { EmployeeList } from '../pages/EmployeeList';
import { Error } from '../pages/Error';
import { ForgotPassword } from '../pages/ForgotPassword';
import { FundGeographicalFocusList } from '../pages/FundGeographicalFocusList';
import { FundManagerList } from '../pages/FundManagerList';
import { FundAssetClassList } from '../pages/FundAssetClassList';
import { FundValuationDayList } from '../pages/FundValuationDayList';
import { FundValuationFrequencyList } from '../pages/FundValuationFrequencyList';
import { IndexFundList } from '../pages/IndexFundList';
import { IndexFundNav } from '../pages/IndexFundNavList';
import { Login } from '../pages/Login';
import { OrderExecutionList } from '../pages/OrderExecutionList';
import { Payment } from '../pages/Payment';
import { Permissions } from '../pages/Permissions';
import { PortfolioOrder } from '../pages/PortfolioOrder';
import { Report } from '../pages/Report';
import { Analytics } from '../pages/Analytics';
import { ResourceList } from '../pages/ResourceList';
import { RoleList } from '../pages/RoleList';
import { RolePermissionList } from '../pages/RolePermissionList';
import { Settings } from '../pages/Settings';
import { SubscribedPortfolio } from '../pages/SubscribedPortfolio';
import { SubscribedPortfolioList } from '../pages/SubscribedPortfolioList';
import { User } from '../pages/User';
import { UserCategoryList } from '../pages/UserCategoryList';
import { UserGroupList } from '../pages/UserGroupList';
import { UserList } from '../pages/UserList';
import { UserPortfolioRequest } from '../pages/UserPortfolioRequest';
import { UserPortfolioRequestList } from '../pages/UserPortfolioRequestList';
import { authSelector } from '../redux/features/auth/auth.selectors';
import { useFetchMyPermissionsQuery } from '../redux/features/rolePermission/rolePermission.apiSlice';
import * as permissions from '../utils/permissions';
import { locations } from './locations';
import { PortfolioList } from '../pages/PortfolioList';
import { Portfolio } from '../pages/Portfolio';
import { BenchmarkList } from '../pages/BenchmarkList';
import { PortfolioOrderList } from '../pages/PortfolioOrderList';
import { IndexFund } from '../pages/IndexFund';
import { OrderExecution } from '../pages/OrderExecution';
import { ProductTypeList } from '../pages/ProductTypeList';
import { UserFeeDeduction } from '../pages/UserFeeDeduction';
import { UserFeeDeductionList } from '../pages/UserFeeDeductionList';
import { FeeBatchList } from '../pages/FeeBatchList';
import { FeeBatch } from '../pages/FeeBatch';
import { BatchExecutionList } from '../pages/BatchExecutionList';
import { BatchExecution } from '../pages/BatchExecution';
import { FeeConfigList } from '../pages/FeeConfigList';
import { FeeConfig } from '../pages/FeeConfig';

interface PermissionRouteObject extends Omit<RouteObject, 'children'> {
  permission?: PermissionPropsItem;
  children?: PermissionRouteObject[];
}

const routesByPermissions: PermissionRouteObject[] = [
  {
    path: locations.home(),
    element: <AppPageLayout />,
    children: [
      {
        index: true,
        path: locations.dashboard(),
        element: <Dashboard />,
      },
      {
        path: locations.userList(),
        permission: permissions.User.list,
        element: <Outlet />,
        children: [
          {
            index: true,
            path: locations.userList(),
            permission: permissions.User.list,
            element: <UserList />,
          },
          {
            path: locations.user(),
            permission: permissions.User.read,
            element: <User />,
          },
        ],
      },
      {
        path: locations.earlySignupUserList(),
        permission: permissions.Anonymous.listEarlySignupUsers,
        element: <EarlySignupUserList />,
      },
      {
        path: locations.userGroupList(),
        permission: permissions.UserGroup.list,
        element: <UserGroupList />,
      },
      {
        path: locations.employeeList(),
        permission: permissions.Employee.list,
        element: <Outlet />,
        children: [
          {
            index: true,
            path: locations.employeeList(),
            permission: permissions.Employee.list,
            element: <EmployeeList />,
          },
          {
            path: locations.employee(),
            permission: permissions.Employee.read,
            element: <Employee />,
          },
        ],
      },
      {
        path: locations.roleList(),
        permission: permissions.Role.list,
        element: <RoleList />,
      },
      {
        path: locations.currencyList(),
        permission: permissions.Currency.list,
        element: <CurrencyList />,
      },
      {
        path: locations.userCategoryList(),
        permission: permissions.Currency.list,
        element: <UserCategoryList />,
      },
      {
        path: locations.assetList(),
        permission: permissions.Asset.list,
        element: <AssetList />,
      },
      {
        path: locations.productTypeList(),
        permission: permissions.ProductType.list,
        element: <ProductTypeList />,
      },
      {
        path: locations.indexFundList(),
        permission: permissions.IndexFund.list,
        element: <Outlet />,
        children: [
          {
            index: true,
            path: locations.indexFundList(),
            permission: permissions.IndexFund.list,
            element: <IndexFundList />,
          },
          {
            path: locations.indexFund(),
            permission: permissions.IndexFund.read,
            element: <IndexFund />,
          },
        ],
      },
      {
        path: locations.indexFundNavList(),
        permission: permissions.IndexFund.list,
        element: <IndexFundNav />,
      },
      {
        path: locations.portfolioList(),
        permission: permissions.Portfolio.list,
        element: <Outlet />,
        children: [
          {
            index: true,
            path: locations.portfolioList(),
            permission: permissions.Portfolio.list,
            element: <PortfolioList />,
          },
          {
            path: locations.portfolio(),
            permission: permissions.Portfolio.read,
            element: <Portfolio />,
          },
        ],
      },
      {
        path: locations.benchmarkList(),
        permission: permissions.Benchmark.list,
        element: <Outlet />,
        children: [
          {
            index: true,
            path: locations.benchmarkList(),
            permission: permissions.Benchmark.list,
            element: <BenchmarkList />,
          },
          // {
          //   path: locations.benchmark(),
          //   permission: permissions.Benchmark.read,
          //   element: <Benchmark />,
          // },
        ],
      },
      {
        path: locations.portfolioOrderList(),
        permission: permissions.PortfolioOrder.list,
        element: <Outlet />,
        children: [
          {
            index: true,
            path: locations.portfolioOrderList(),
            permission: permissions.PortfolioOrder.list,
            element: <PortfolioOrderList />,
          },
          {
            path: locations.portfolioOrder(),
            permission: permissions.PortfolioOrder.read,
            element: <PortfolioOrder />,
          },
        ],
      },
      {
        path: locations.orderExecutionList(),
        permission: permissions.OrderExecution.list,
        element: <Outlet />,
        children: [
          {
            index: true,
            path: locations.orderExecutionList(),
            permission: permissions.OrderExecution.list,
            element: <OrderExecutionList />,
          },
          {
            path: locations.orderExecution(),
            permission: permissions.OrderExecution.read,
            element: <OrderExecution />,
          },
        ],
      },
      {
        path: locations.subscribedPortfolioList(),
        permission: permissions.SubscribedPortfolio.list,
        element: <Outlet />,
        children: [
          {
            index: true,
            path: locations.subscribedPortfolioList(),
            permission: permissions.SubscribedPortfolio.list,
            element: <SubscribedPortfolioList />,
          },
          {
            path: locations.subscribedPortfolio(),
            permission: permissions.SubscribedPortfolio.read,
            element: <SubscribedPortfolio />,
          },
        ],
      },
      {
        path: locations.userPortfolioRequestList(),
        permission: permissions.UserPortfolioRequest.list,
        element: <Outlet />,
        children: [
          {
            index: true,
            path: locations.userPortfolioRequestList(),
            permission: permissions.UserPortfolioRequest.list,
            element: <UserPortfolioRequestList />,
          },
          {
            path: locations.userPortfolioRequest(),
            permission: permissions.UserPortfolioRequest.list,
            element: <UserPortfolioRequest />,
          },
        ],
      },
      {
        path: locations.settings(),
        permission: permissions.AppConfig.list,
        element: <Outlet />,
        children: [
          {
            index: true,
            path: locations.settings(),
            permission: permissions.AppConfig.list,
            element: <Settings />,
          },
          // {
          //   path: locations.subscribedPortfolio(),
          //   permission: permissions.UserPortfolioRequest.read,
          //   element: <UserPortfolioRequest />,
          // },
        ],
      },
      {
        path: locations.resourceList(),
        permission: permissions.Resource.list,
        element: <ResourceList />,
      },
      {
        path: locations.fundGeographicalFocus(),
        permission: permissions.FundGeographicalFocus.list,
        element: <FundGeographicalFocusList />,
      },
      {
        path: locations.fundManager(),
        permission: permissions.FundManager.list,
        element: <FundManagerList />,
      },
      {
        path: locations.fundAssetClass(),
        permission: permissions.FundAssetClass.list,
        element: <FundAssetClassList />,
      },
      {
        path: locations.fundValuationDays(),
        permission: permissions.FundValuationDay.list,
        element: <FundValuationDayList />,
      },
      {
        path: locations.fundValuationFrequency(),
        permission: permissions.FundValuationFrequency.list,
        element: <FundValuationFrequencyList />,
      },
      {
        path: locations.actionList(),
        permission: permissions.Action.list,
        element: <ActionList />,
      },
      {
        path: locations.rolePermissionList(),
        permission: permissions.RolePermission.list,
        element: <RolePermissionList />,
      },
      {
        path: locations.rolePermissionList2(),
        permission: permissions.RolePermission.list,
        element: <Permissions />,
      },
      {
        path: locations.payment(),
        permission: permissions.PaymentPermission.paymentStatement,
        element: <Payment />,
      },
      {
        path: locations.feeConfigList(),
        // permission: permissions.Portfolio.list,
        element: <Outlet />,
        children: [
          {
            index: true,
            path: locations.feeConfigList(),
            // permission: permissions.Portfolio.list,
            element: <FeeConfigList />,
          },
          {
            path: locations.feeConfig(),
            // permission: permissions.Portfolio.read,
            element: <FeeConfig />,
          },
        ],
      },
      {
        path: locations.report(),
        permission: permissions.ReportPermission.list,
        element: <Report />,
      },
      {
        path: locations.analytics(),
        // TODO: add analytics to admin-serivce to use permission functionality
        // permission: permissions.AnalyticsPermission.list,
        element: <Analytics />,
      },
      {
        path: locations.userFeeDeductionList(),
        // permission: permissions.UserFeeDeduction.list,
        element: <Outlet />,
        children: [
          {
            index: true,
            path: locations.userFeeDeductionList(),
            // permission: permissions.UserFeeDeduction.list,
            element: <UserFeeDeductionList />,
          },
          {
            path: locations.userFeeDeduction(),
            // permission: permissions.UserFeeDeduction.list,
            element: <UserFeeDeduction />,
          },
        ],
      },
      {
        path: locations.feeBatchList(),
        // permission: permissions.FeeBatch.list,
        element: <Outlet />,
        children: [
          {
            index: true,
            path: locations.feeBatchList(),
            // permission: permissions.FeeBatch.list,
            element: <FeeBatchList />,
          },
          {
            path: locations.feeBatch(),
            // permission: permissions.FeeBatch.read,
            element: <FeeBatch />,
          },
        ],
      },
      {
        path: locations.batchExecutionList(),
        // permission: permissions.BatchExecution.list,
        element: <Outlet />,
        children: [
          {
            index: true,
            path: locations.batchExecutionList(),
            // permission: permissions.BatchExecution.list,
            element: <BatchExecutionList />,
          },
          {
            path: locations.batchExecution(),
            // permission: permissions.BatchExecution.read,
            element: <BatchExecution />,
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to={locations.notFound()} />,
      },
    ],
  },
  {
    path: locations.home(),
    element: <PublicPageLayout />,
    children: [
      {
        path: locations.login(),
        element: <Login />,
      },
      {
        path: locations.forgotPassword(),
        element: <ForgotPassword />,
      },
      {
        path: locations.notFound(),
        element: <Error />,
      },
      {
        path: '*',
        element: <Navigate to={locations.notFound()} />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={locations.home()} replace />,
  },
];

export function AppRoutes() {
  const { isLoggedIn } = useSelector(authSelector);
  const { data: myPermissions, isSuccess } = useFetchMyPermissionsQuery(undefined, {
    pollingInterval: 5 * 60000, // every 5 min
    skip: !isLoggedIn,
  });
  const [routes, setRoutes] = useState<RouteObject[]>(routesByPermissions as RouteObject[]);

  const filterRoutesByPermissions = (initialRoutes: PermissionRouteObject[]) =>
    initialRoutes.reduce((res, { permission, ...item }) => {
      if (!permission || (myPermissions || {})[permission.resource]?.includes(permission.action)) {
        if (item.children) {
          res.push({
            ...item,
            children: filterRoutesByPermissions(item.children),
          } as RouteObject);
        } else {
          res.push(item as RouteObject);
        }
      }

      return res;
    }, [] as RouteObject[]);

  useEffect(() => {
    if (isSuccess) {
      const newRoutes = filterRoutesByPermissions(routesByPermissions);

      setRoutes(newRoutes);
    }
  }, [myPermissions, isSuccess]);

  return useRoutes(routes);
}
