import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchAlpacaAccountByUserIdResponse,
  FetchAlpacaEventsResponse,
  FetchAlpacaOrdersByMadkholOrderIdResponse,
  FetchAlpacaOrdersResponse,
  FetchAlpacaTransfersResponse,
  FetchTradingAccountResponse,
} from '../../../types/alpaca';
import {
  fetchAlpacaEvents,
  fetchOrdersByMadkholOrderId,
  cleanAlpacas,
  fetchAccountByUserId,
  sendOrderToCompleteQueue,
  sendOrderToJournalQueue,
  sendOrderToOrderQueue,
  sendOrderToTransferQueue,
  fetchAlpacaOrders,
  createAlpacaAccount,
  fetchTradingAccount,
  fetchAlpacaTransfers,
} from './alpaca.actions';
import { AlpacaState } from './alpaca.types';

const initialState: AlpacaState = {
  events: [],
  totalEvents: 0,
  orders: {},
  totalOrders: {},
  transfers: {},
  totalTransfers: {},
  account: null,
  tradingAccount: null,
  fetchStatuses: {},
  fetchOrdersStatus: fetchStatuses.idle,
  fetchAccountStatus: fetchStatuses.idle,
  fetchStatus: fetchStatuses.idle,
  sendOrderToJournalQueueStatus: fetchStatuses.idle,
  sendOrderToCompleteQueueStatus: fetchStatuses.idle,
  sendOrderToTransferQueueStatus: fetchStatuses.idle,
  sendOrderToOrderQueueStatus: fetchStatuses.idle,
  createAlpacaAccountStatus: fetchStatuses.idle,
  fetchTradingAccountStatus: fetchStatuses.idle,
  error: null,
};

const alpacaSlice = createSlice({
  name: 'alpaca',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAlpacaEvents.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchAlpacaEvents.fulfilled, (state, action: PayloadAction<FetchAlpacaEventsResponse>) => {
      state.events = action.payload.data?.items;
      state.totalEvents = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchAlpacaEvents.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchAlpacaOrders.pending, (state, action) => {
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.pending;
    });
    builder.addCase(fetchAlpacaOrders.fulfilled, (state, action: PayloadAction<FetchAlpacaOrdersResponse>) => {
      const requestId = (action as any).meta.arg.requestId || (action as any).meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.success;

      if (action.payload) {
        state.orders[requestId] = action.payload.data?.items;
        state.totalOrders[requestId] = action.payload.data?.totalItems;
      }
    });
    builder.addCase(fetchAlpacaOrders.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.rejected;

      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchAlpacaTransfers.pending, (state, action) => {
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.pending;
    });
    builder.addCase(fetchAlpacaTransfers.fulfilled, (state, action: PayloadAction<FetchAlpacaTransfersResponse>) => {
      const requestId = (action as any).meta.arg.requestId || (action as any).meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.success;

      if (action.payload) {
        state.transfers[requestId] = action.payload.data?.items;
        state.totalTransfers[requestId] = action.payload.data?.totalItems;
      }
    });
    builder.addCase(fetchAlpacaTransfers.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.rejected;

      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchOrdersByMadkholOrderId.pending, (state) => {
      state.fetchOrdersStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchOrdersByMadkholOrderId.fulfilled,
      (state, action: PayloadAction<FetchAlpacaOrdersByMadkholOrderIdResponse>) => {
        state.orders['by-madkhol-order-id'] = action.payload.data;
        state.fetchOrdersStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchOrdersByMadkholOrderId.rejected, (state, action) => {
      state.fetchOrdersStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchAccountByUserId.pending, (state) => {
      state.fetchOrdersStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchAccountByUserId.fulfilled,
      (state, action: PayloadAction<FetchAlpacaAccountByUserIdResponse>) => {
        state.account = action.payload.data;
        state.fetchOrdersStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchAccountByUserId.rejected, (state, action) => {
      state.fetchOrdersStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(sendOrderToJournalQueue.pending, (state) => {
      state.sendOrderToJournalQueueStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
    });
    builder.addCase(sendOrderToJournalQueue.fulfilled, (state) => {
      state.sendOrderToJournalQueueStatus = fetchStatuses.success;
    });
    builder.addCase(sendOrderToJournalQueue.rejected, (state, action) => {
      state.sendOrderToJournalQueueStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(sendOrderToCompleteQueue.pending, (state) => {
      state.sendOrderToCompleteQueueStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
    });
    builder.addCase(sendOrderToCompleteQueue.fulfilled, (state) => {
      state.sendOrderToCompleteQueueStatus = fetchStatuses.success;
    });
    builder.addCase(sendOrderToCompleteQueue.rejected, (state, action) => {
      state.sendOrderToCompleteQueueStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(sendOrderToTransferQueue.pending, (state) => {
      state.sendOrderToTransferQueueStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
    });
    builder.addCase(sendOrderToTransferQueue.fulfilled, (state) => {
      state.sendOrderToTransferQueueStatus = fetchStatuses.success;
    });
    builder.addCase(sendOrderToTransferQueue.rejected, (state, action) => {
      state.sendOrderToTransferQueueStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(sendOrderToOrderQueue.pending, (state) => {
      state.sendOrderToOrderQueueStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
    });
    builder.addCase(sendOrderToOrderQueue.fulfilled, (state) => {
      state.sendOrderToOrderQueueStatus = fetchStatuses.success;
    });
    builder.addCase(sendOrderToOrderQueue.rejected, (state, action) => {
      state.sendOrderToOrderQueueStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createAlpacaAccount.pending, (state) => {
      state.createAlpacaAccountStatus = fetchStatuses.pending;
    });
    builder.addCase(createAlpacaAccount.fulfilled, (state) => {
      state.createAlpacaAccountStatus = fetchStatuses.success;
    });
    builder.addCase(createAlpacaAccount.rejected, (state, action) => {
      state.createAlpacaAccountStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchTradingAccount.pending, (state) => {
      state.fetchTradingAccountStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchTradingAccount.fulfilled, (state, action: PayloadAction<FetchTradingAccountResponse>) => {
      state.tradingAccount = action.payload.data;
      state.fetchTradingAccountStatus = fetchStatuses.success;
    });
    builder.addCase(fetchTradingAccount.rejected, (state, action) => {
      state.fetchTradingAccountStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(cleanAlpacas.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const alpacaReducer = alpacaSlice.reducer;
