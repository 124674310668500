import { PaginatedRequest } from '../types/api';
import {
  SaveUserBankRequest,
  CreateUserBankResponse,
  FetchUserBanksResponse,
  FetchUserBankResponse,
  UpdateUserBankRequest,
  UpdateUserBankResponse,
  FetchUserBankTransactionsResponse,
  FetchUserBankTransactionsLogsResponse,
  AddFundsRequest,
  AddFundsResponse,
  FetchUserBankDetailsHistoryResponse,
} from '../types/userBank';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class UserBankService extends ApiService {
  pathname = 'admin/user-bank';

  fetchUserBanks = async (data: PaginatedRequest) =>
    this.get<FetchUserBanksResponse>(`${this.pathname}?${toQueryParams(data)}`);

  fetchUserBankDetailsHistory = async (data: PaginatedRequest) =>
    this.get<FetchUserBankDetailsHistoryResponse>(`${this.pathname}/history?${toQueryParams(data)}`);

  fetchUserBanksByUser = async ({ userId, ...data }: PaginatedRequest & { userId: number }) =>
    this.get<FetchUserBanksResponse>(`${this.pathname}/${userId}?${toQueryParams(data)}`);

  fetchUserBankTransactions = async ({ userBankId, ...data }: PaginatedRequest & { userBankId: number }) =>
    this.get<FetchUserBankTransactionsResponse>(`${this.pathname}/${userBankId}/transaction?${toQueryParams(data)}`);

  fetchUserBankTransactionsLogs = async ({ userBankId, ...data }: PaginatedRequest & { userBankId: number }) =>
    this.get<FetchUserBankTransactionsLogsResponse>(
      `${this.pathname}/${userBankId}/transactions-log?${toQueryParams(data)}`,
    );

  fetchUserBank = async (id: number) => this.get<FetchUserBankResponse>(`${this.pathname}/${id}`);

  deleteUserBank = async (id: number) => this.delete<FetchUserBankResponse>(`${this.pathname}/${id}`);

  saveUserBank = async (portfolio: SaveUserBankRequest) =>
    this.post<CreateUserBankResponse, SaveUserBankRequest>(`${this.pathname}`, portfolio);

  updateUserBank = async ({ id, ...data }: UpdateUserBankRequest) =>
    this.put<UpdateUserBankResponse, Omit<UpdateUserBankRequest, 'id'>>(`${this.pathname}/${id}`, data);

  addFunds = async (data: AddFundsRequest) =>
    this.put<AddFundsResponse, AddFundsRequest>(`${this.pathname}/add-funds`, data);
}

export const userBankService = new UserBankService();
