import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { currencyService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { CreateCurrencyRequest, UpdateCurrencyRequest } from '../../../types/currency';

export const fetchCurrencys = createAsyncThunk('currency/fetch-all', (data: PaginatedRequest) =>
  currencyService.fetchCurrencys(data),
);

export const fetchCurrency = createAsyncThunk('currency/get', (id: number) => currencyService.fetchCurrency(id));

export const deleteCurrency = createAsyncThunk('currency/delete', (id: number) => currencyService.deleteCurrency(id));

export const createCurrency = createAsyncThunk('currency/create', (currency: CreateCurrencyRequest) =>
  currencyService.createCurrency(currency),
);

export const updateCurrency = createAsyncThunk('currency/update', (currency: UpdateCurrencyRequest) =>
  currencyService.updateCurrency(currency),
);

export const resetEditCurrency = createAction('currency/reset-edit');

export const cleanCurrencys = createAction('currency/clean');
