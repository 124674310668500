import React from 'react';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { nonSuperAdminRoleSelector } from '../../../redux/features/role/role.selectors';
import { StyledTableCell } from './PermissionsTableElements';
import { PermissionsTableResourceRowRoleCell } from './PermissionsTableResourceRowRoleCell';

type RolesDataSet = Record<string, boolean>;
type ActionsDataSet = Record<string, RolesDataSet>;

type PermissionsTableResourceRowProps = {
  resource: string;
  actionsDataSet: ActionsDataSet;
  onChange: (data: { resource: string; roleName: string; checked: boolean }) => void;
};

export const PermissionsTableResourceRow: React.FC<PermissionsTableResourceRowProps> = React.memo((props) => {
  const { resource, onChange, actionsDataSet } = props;

  const roles = useSelector(nonSuperAdminRoleSelector);

  const isChecked = (roleName: string) => {
    const checks = Object.entries(actionsDataSet).map(([, rolesDS]) => rolesDS[roleName]);

    return checks.length ? checks.every(Boolean) : false;
  };

  return (
    <TableRow>
      <StyledTableCell className="sticky resource" component="th" variant="head" scope="row">
        <Typography sx={{ fontWeight: 600, whiteSpace: 'nowrap' }}>{resource}</Typography>
      </StyledTableCell>
      {roles.map((role) => (
        <PermissionsTableResourceRowRoleCell
          resource={resource}
          roleName={role.name}
          key={`${resource}-all-${role.name}`}
          checked={isChecked(role.name)}
          onChange={onChange}
        />
      ))}
    </TableRow>
  );
});
