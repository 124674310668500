import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchKycGenderOptionsResponse,
  FetchKycResponse,
  FetchKycStatusOptionsResponse,
  FetchKycTypeOptionsResponse,
} from '../../../types/kyc';
import {
  FetchUserAttachmentsDocumentTypeOptionsResponse,
  FetchUserAttachmentsResponse,
  FetchUserBankInfoResponse,
  FetchUserHistoryResponse,
  FetchUserResponse,
  FetchUserSignAgreementsResponse,
  FetchUsersResponse,
  FetchUserStatusOptionsResponse,
  FetchUserSurveyResultsResponse,
  FetchUserTransactionsResponse,
  FetchUserWorldCheckScreenResponse,
  FetchUserSubscriptionsResponse,
} from '../../../types/user';
import {
  cleanUsers,
  createUser,
  fetchKycByUserId,
  fetchKycGenderOptions,
  fetchKycStatusOptions,
  fetchKycTypeOptions,
  fetchUser,
  fetchUserBankInfo,
  fetchUserHistory,
  fetchUsers,
  fetchUserStatusOptions,
  fetchUserSurveyResults,
  resetEditUser,
  resetUserApiStatuses,
  updateKyc,
  updateUser,
  updateUserStatus,
  fetchUserAttachments,
  fetchUserAttachmentsDocumentTypeOptions,
  createUserAttachments,
  withdraw,
  fetchUserTransactions,
  fetchUserWorldCheckScreen,
  fetchUserSignAgreements,
  fetchUserSubscriptions,
} from './user.actions';
import { UserState } from './user.types';

const initialState: UserState = {
  users: [],
  totalUsers: 0,
  editUser: null,
  userStatusOptions: [],
  kycTypeOptions: [],
  kycStatusOptions: [],
  kycGenderOptions: [],
  editUserKyc: null,
  fetchStatus: fetchStatuses.idle,
  fetchUserStatusOptionsStatus: fetchStatuses.idle,
  fetchKycStatus: fetchStatuses.idle,
  fetchKycTypeOptionsStatus: fetchStatuses.idle,
  fetchKycStatusOptionsStatus: fetchStatuses.idle,
  fetchKycGenderOptionsStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  updateStatusStatus: fetchStatuses.idle,
  updateKycStatus: fetchStatuses.idle,

  withdrawStatus: fetchStatuses.idle,

  userHistoryItems: [],
  totalUserHistoryItems: 0,
  fetchUserHistoryStatus: fetchStatuses.idle,

  editUserBankInfo: [],
  fetchBankInfoStatus: fetchStatuses.idle,

  userSurveyResultsItems: [],
  totalUserSurveyResultsItems: 0,
  fetchUserSurveyResultsStatus: fetchStatuses.idle,

  userAttachments: [],
  fetchUserAttachmentsStatus: fetchStatuses.idle,

  userAttachmentsDocumentTypeOptions: [],
  fetchUserAttachmentsDocumentTypeOptionsStatus: fetchStatuses.idle,
  createUserAttachmentStatus: fetchStatuses.idle,

  userTransactionItems: [],
  totalUserTransactionItems: 0,
  fetchUserTransactionsStatus: fetchStatuses.idle,

  userSignAgreements: [],
  totalUserSignAgreements: 0,
  fetchUserSignAgreements: fetchStatuses.idle,

  userWorldCheckScreenData: null,
  fetchUserWorldCheckScreenStatus: fetchStatuses.idle,

  userSubscriptionItems: [],
  totalUserSubscriptionItems: 0,
  fetchUserSubscriptionsStatus: fetchStatuses.idle,

  error: null,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action: PayloadAction<FetchUsersResponse>) => {
      state.users = action.payload.data?.items;
      state.totalUsers = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUser.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUser.fulfilled, (state, action: PayloadAction<FetchUserResponse>) => {
      state.editUser = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserWorldCheckScreen.pending, (state) => {
      state.error = null;
      state.fetchUserWorldCheckScreenStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserWorldCheckScreen.fulfilled,
      (state, action: PayloadAction<FetchUserWorldCheckScreenResponse>) => {
        state.userWorldCheckScreenData = action.payload.data;
        state.fetchUserWorldCheckScreenStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserWorldCheckScreen.rejected, (state, action) => {
      state.fetchUserWorldCheckScreenStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserSignAgreements.pending, (state) => {
      state.error = null;
      state.fetchUserSignAgreements = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserSignAgreements.fulfilled,
      (state, action: PayloadAction<FetchUserSignAgreementsResponse>) => {
        state.userSignAgreements = action.payload.data.items;
        state.totalUserSignAgreements = action.payload.data.totalItems;
        state.fetchUserSignAgreements = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserSignAgreements.rejected, (state, action) => {
      state.fetchUserSignAgreements = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserHistory.pending, (state) => {
      state.error = null;
      state.fetchUserHistoryStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUserHistory.fulfilled, (state, action: PayloadAction<FetchUserHistoryResponse>) => {
      state.userHistoryItems = action.payload.data?.items;
      state.totalUserHistoryItems = action.payload.data?.totalItems;
      state.fetchUserHistoryStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserHistory.rejected, (state, action) => {
      state.fetchUserHistoryStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchKycByUserId.pending, (state) => {
      state.error = null;
      state.fetchKycStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchKycByUserId.fulfilled, (state, action: PayloadAction<FetchKycResponse>) => {
      state.editUserKyc = action.payload.data;
      state.fetchKycStatus = fetchStatuses.success;
    });
    builder.addCase(fetchKycByUserId.rejected, (state, action) => {
      state.fetchKycStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserBankInfo.pending, (state) => {
      state.error = null;
      state.fetchBankInfoStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUserBankInfo.fulfilled, (state, action: PayloadAction<FetchUserBankInfoResponse>) => {
      state.editUserBankInfo = action.payload.data;
      state.fetchBankInfoStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserBankInfo.rejected, (state, action) => {
      state.fetchBankInfoStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserSurveyResults.pending, (state) => {
      state.error = null;
      state.fetchUserSurveyResultsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserSurveyResults.fulfilled,
      (state, action: PayloadAction<FetchUserSurveyResultsResponse>) => {
        state.userSurveyResultsItems = action.payload.data?.items;
        state.totalUserSurveyResultsItems = action.payload.data?.totalItems;
        state.fetchUserSurveyResultsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserSurveyResults.rejected, (state, action) => {
      state.fetchUserSurveyResultsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserAttachments.pending, (state) => {
      state.error = null;
      state.fetchUserAttachmentsStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUserAttachments.fulfilled, (state, action: PayloadAction<FetchUserAttachmentsResponse>) => {
      state.userAttachments = action.payload.data;
      state.fetchUserAttachmentsStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserAttachments.rejected, (state, action) => {
      state.fetchUserAttachmentsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserStatusOptions.pending, (state) => {
      state.error = null;
      state.fetchUserStatusOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserStatusOptions.fulfilled,
      (state, action: PayloadAction<FetchUserStatusOptionsResponse>) => {
        state.userStatusOptions = action.payload.data;
        state.fetchUserStatusOptionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserStatusOptions.rejected, (state, action) => {
      state.fetchUserStatusOptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserAttachmentsDocumentTypeOptions.pending, (state) => {
      state.error = null;
      state.fetchUserAttachmentsDocumentTypeOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserAttachmentsDocumentTypeOptions.fulfilled,
      (state, action: PayloadAction<FetchUserAttachmentsDocumentTypeOptionsResponse>) => {
        state.userAttachmentsDocumentTypeOptions = action.payload.data;
        state.fetchUserAttachmentsDocumentTypeOptionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserAttachmentsDocumentTypeOptions.rejected, (state, action) => {
      state.fetchUserAttachmentsDocumentTypeOptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserTransactions.pending, (state) => {
      state.error = null;
      state.fetchUserTransactionsStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUserTransactions.fulfilled, (state, action: PayloadAction<FetchUserTransactionsResponse>) => {
      state.userTransactionItems = action.payload.data?.items;
      state.totalUserTransactionItems = action.payload.data?.totalItems;
      state.fetchUserTransactionsStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserTransactions.rejected, (state, action) => {
      state.fetchUserTransactionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchKycTypeOptions.pending, (state) => {
      state.error = null;
      state.fetchKycTypeOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchKycTypeOptions.fulfilled, (state, action: PayloadAction<FetchKycTypeOptionsResponse>) => {
      state.kycTypeOptions = action.payload.data;
      state.fetchKycTypeOptionsStatus = fetchStatuses.success;
    });
    builder.addCase(fetchKycTypeOptions.rejected, (state, action) => {
      state.fetchKycStatusOptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchKycStatusOptions.pending, (state) => {
      state.error = null;
      state.fetchKycStatusOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchKycStatusOptions.fulfilled, (state, action: PayloadAction<FetchKycStatusOptionsResponse>) => {
      state.kycStatusOptions = action.payload.data;
      state.fetchKycStatusOptionsStatus = fetchStatuses.success;
    });
    builder.addCase(fetchKycStatusOptions.rejected, (state, action) => {
      state.fetchKycStatusOptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchKycGenderOptions.pending, (state) => {
      state.error = null;
      state.fetchKycGenderOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchKycGenderOptions.fulfilled, (state, action: PayloadAction<FetchKycGenderOptionsResponse>) => {
      state.kycGenderOptions = action.payload.data;
      state.fetchKycGenderOptionsStatus = fetchStatuses.success;
    });
    builder.addCase(fetchKycGenderOptions.rejected, (state, action) => {
      state.fetchKycGenderOptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateKyc.pending, (state) => {
      state.error = null;
      state.updateKycStatus = fetchStatuses.pending;
    });
    builder.addCase(updateKyc.fulfilled, (state) => {
      state.updateKycStatus = fetchStatuses.success;
    });
    builder.addCase(updateKyc.rejected, (state, action) => {
      state.updateKycStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createUser.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
    });
    builder.addCase(createUser.fulfilled, (state, action: PayloadAction<FetchUserResponse>) => {
      state.editUser = action.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateUser.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.updateStatusStatus = fetchStatuses.idle;
    });
    builder.addCase(updateUser.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateUserStatus.pending, (state) => {
      state.error = null;
      state.updateStatusStatus = fetchStatuses.pending;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(updateUserStatus.fulfilled, (state) => {
      state.updateStatusStatus = fetchStatuses.success;
    });
    builder.addCase(updateUserStatus.rejected, (state, action) => {
      state.updateStatusStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(withdraw.pending, (state) => {
      state.error = null;
      state.withdrawStatus = fetchStatuses.idle;
    });
    builder.addCase(withdraw.fulfilled, (state) => {
      state.withdrawStatus = fetchStatuses.success;
    });
    builder.addCase(withdraw.rejected, (state, action) => {
      state.withdrawStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createUserAttachments.pending, (state) => {
      state.error = null;
      state.createUserAttachmentStatus = fetchStatuses.idle;
    });
    builder.addCase(createUserAttachments.fulfilled, (state) => {
      state.createUserAttachmentStatus = fetchStatuses.success;
    });
    builder.addCase(createUserAttachments.rejected, (state, action) => {
      state.createUserAttachmentStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserSubscriptions.pending, (state) => {
      state.error = null;
      state.fetchUserTransactionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserSubscriptions.fulfilled,
      (state, action: PayloadAction<FetchUserSubscriptionsResponse>) => {
        state.userSubscriptionItems = action.payload.data?.items;
        state.totalUserSubscriptionItems = action.payload.data?.totalItems;
        state.fetchUserSubscriptionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserSubscriptions.rejected, (state, action) => {
      state.fetchUserSubscriptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditUser.type, (state) => {
      state.editUser = null;
      state.editUserKyc = null;
      state.userHistoryItems = [];
      state.totalUserHistoryItems = 0;
    });

    builder.addCase(resetUserApiStatuses.type, (state) => {
      state.fetchStatus = fetchStatuses.idle;
      state.fetchUserStatusOptionsStatus = fetchStatuses.idle;
      state.fetchKycStatus = fetchStatuses.idle;
      state.fetchKycTypeOptionsStatus = fetchStatuses.idle;
      state.fetchKycStatusOptionsStatus = fetchStatuses.idle;
      state.fetchKycGenderOptionsStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.updateStatusStatus = fetchStatuses.idle;
      state.updateKycStatus = fetchStatuses.idle;
      state.fetchUserHistoryStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanUsers.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const userReducer = usersSlice.reducer;
