import { ApiResponse } from './api';
import { Role } from './role';

export enum EmployeeStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export type Employee = {
  id: number;
  name: string;
  role: Role;
  status: EmployeeStatus;
  email: string;
  phone: string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchEmployeeResponse = ApiResponse<Employee>;
export type FetchEmployeesResponse = ApiResponse<{
  items: Employee[];
  totalItems: number;
}>;

export type UpdateEmployeeStatusRequest = Pick<Employee, 'id' | 'status'>;
export type UpdateEmployeeStatusResponse = ApiResponse<{ message: string }>;

export type ResetEmployeePasswordResponse = ApiResponse<{ message: string }>;

export type CreateEmployeeRequest = Pick<Employee, 'email' | 'name' | 'phone'> & { role: number };
export type CreateEmployeeResponse = ApiResponse<Employee>;

export type UpdateEmployeeRequest = Pick<Employee, 'id' | 'email' | 'name' | 'phone'> & { role: number };
export type UpdateEmployeeResponse = ApiResponse<Employee>;
