import Tooltip from '@mui/material/Tooltip';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium';
import { CopyToClipboardButton } from '../../../../components/CopyToClipboardButton';
import { FakeButtonLink } from '../../../../components/FakeLink';
import { Table } from '../../../../components/Table';
import { TableRef } from '../../../../components/Table/Table';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { fetchAlpacaOrders } from '../../../../redux/modules/alpaca/alpaca.actions';
import { alpacaOrderByRequestIdSelector } from '../../../../redux/modules/alpaca/alpaca.selectors';
import { ReducerState } from '../../../../redux/store.types';
import { locations } from '../../../../routes/locations';
import { AlpacaOrderSide, AlpacaOrderStatus, AlpacaOrderStep } from '../../../../types/alpaca';
import { round2Format, round4Format } from '../../../../utils/number';

interface AlpacaOrderTableProps {
  userId: number;
}

export function AlpacaOrderTable({ userId }: AlpacaOrderTableProps) {
  const requestId = `user-${userId}-owned-shares-alpaca-order-list`;
  const tableRef = useRef<TableRef>(null);
  const [columns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
    },
    {
      field: 'orderId',
      headerName: 'Order Id',
      type: 'string',
      width: 300,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return (
          <Tooltip title={params.value} followCursor>
            <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.portfolioOrder(params.value)}>
              {params.value}
            </FakeButtonLink>
          </Tooltip>
        );
      },
    },
    {
      field: 'side',
      headerName: 'Side',
      type: 'singleSelect',
      valueOptions: Object.values(AlpacaOrderSide),
      minWidth: 50,
    },
    {
      field: 'alpacaJournal.id',
      headerName: 'Journal Id',
      type: 'string',
      valueGetter: (params) => params.row?.alpacaJournal?.journalId,
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return <CopyToClipboardButton value={params.value || '-'} />;
      },
    },
    {
      field: 'step',
      headerName: 'Step',
      type: 'singleSelect',
      valueOptions: Object.values(AlpacaOrderStep),
      minWidth: 100,
    },
    {
      field: 'alpacaTransfer.id',
      headerName: 'Transfer Id',
      type: 'string',
      valueGetter: (params) => params.row?.alpacaTransfer?.transferId,
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return <CopyToClipboardButton value={params.value || '-'} />;
      },
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(AlpacaOrderStatus),
      minWidth: 100,
      flex: 1,
      valueGetter: (params) => params.row?.status?.name,
    },
    {
      field: 'alpacaActualAmount',
      headerName: 'Alpaca Actual Amount',
      type: 'number',
      width: 100,
      renderCell: (params) => (params.value ? round2Format(params.value) : ''),
    },
    {
      field: 'alpacaActualUnits',
      headerName: 'Alpaca Actual Units',
      type: 'number',
      width: 100,
      renderCell: (params) => (params.value ? round4Format(params.value) : ''),
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const { totalOrders, orders, fetchStatus } = useSelector((state: ReducerState) =>
    alpacaOrderByRequestIdSelector(state, requestId),
  );
  const loading = fetchStatus === fetchStatuses.pending;

  return (
    <Table
      ref={tableRef}
      wrapperHeight={300}
      fetchItems={fetchAlpacaOrders}
      rows={orders}
      columns={columns}
      loading={loading}
      rowCount={totalOrders}
      disableColumnFilter
      disableColumnMenu
      density="compact"
      fetchItemsCustomParams={{ requestId }}
      requiredFilters={[{ field: 'user.userId', value: userId, operator: 'is' }]}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      toolbarExportProps={{
        csvOptions: { fileName: `alpaca-orders-user-${userId}` },
        excelOptions: { fileName: `alpaca-orders-user-${userId}` },
      }}
    />
  );
}
