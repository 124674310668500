import { PaginatedRequest } from '../types/api';
import {
  CreateCurrencyRequest,
  CreateCurrencyResponse,
  FetchCurrencysResponse,
  FetchCurrencyResponse,
  UpdateCurrencyRequest,
  UpdateCurrencyResponse,
} from '../types/currency';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class CurrencyService extends ApiService {
  pathname = 'admin';

  fetchCurrencys = async (data: PaginatedRequest) =>
    this.get<FetchCurrencysResponse>(`${this.pathname}/currency?${toQueryParams(data)}`);

  fetchCurrency = async (id: number) => this.get<FetchCurrencyResponse>(`${this.pathname}/currency/${id}`);

  deleteCurrency = async (id: number) => this.delete<FetchCurrencyResponse>(`${this.pathname}/currency/${id}`);

  createCurrency = async (currency: CreateCurrencyRequest) =>
    this.post<CreateCurrencyResponse, CreateCurrencyRequest>(`${this.pathname}/currency`, currency);

  updateCurrency = async ({ id, ...currency }: UpdateCurrencyRequest) =>
    this.put<UpdateCurrencyResponse, Omit<UpdateCurrencyRequest, 'id'>>(`${this.pathname}/currency/${id}`, currency);
}

export const currencyService = new CurrencyService();
