import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { CopyToClipboardButton } from '../../../../components/CopyToClipboardButton';
import { IAlpacaOrder } from '../../../../types/alpaca';
import { stringifyJSON } from '../../../../utils/json';
import { round2Format, round4Format } from '../../../../utils/number';

interface AlpacaOrderProps {
  order: IAlpacaOrder;
}

export function AlpacaOrder({ order }: AlpacaOrderProps) {
  return (
    <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: 6 }} spacing={2}>
      <Typography variant="h6">Order</Typography>
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Id</Typography>
        </Grid>
        <Grid item xs={6}>
          <CopyToClipboardButton text={order.alpacaOrderId} value={order.alpacaOrderId} />
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Step</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{order.step}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Status</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{order.status?.name}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Fund Order Id</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{order.fundOrderId}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Alpaca Actual Amount</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{round2Format(order.alpacaActualAmount)}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Alpaca Actual Price</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{round2Format(order.alpacaActualPrice)}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Alpaca Actual Units</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{round4Format(order.alpacaActualUnits)}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Account Statement Report Created</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{order.accountStatementReportCreated}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Trade Confirmation Report Created</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{order.tradeConfirmationReportCreated}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Error</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{stringifyJSON(order.error as any).toString()}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
