import AttachmentIcon from '@mui/icons-material/Attachment';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { addUserAttachmentFormFields } from '../../../../forms/fields/formFields';
import { addUserAttachmentFormSchema } from '../../../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../../../redux/hooks';
import { uploadPrivate } from '../../../../redux/modules/media/media.actions';
import { mediaLoadingSelector } from '../../../../redux/modules/media/media.selectors';
import {
  createUserAttachments,
  fetchUserAttachmentsDocumentTypeOptions,
} from '../../../../redux/modules/user/user.actions';
import { userSelector } from '../../../../redux/modules/user/user.selectors';
import { UploadEntity, UploadMediaFileResponse } from '../../../../types/media';
import { DocumentTypes } from '../../../../types/user';
import { isDispatchApiSuccess } from '../../../../utils/api';

interface AddAttachmentDialogProps {
  userId: number;
  open: boolean;
  onClose: (isReload: boolean) => void;
}

// Allowed Formats Pdf, JPG, JPeG, PNG
const acceptFiles = 'image/png,image/jpeg,image/jpg,application/pdf';
// Allowed Size 2 MB per each file
const fileSizeLimit = 2 * 1024 * 1024;

const mapDocumentTypeToEntity: Record<DocumentTypes, UploadEntity> = {
  [DocumentTypes.SELFIE]: UploadEntity.selfie,
  [DocumentTypes.VERIFICATION_DOCUMENT]: UploadEntity.nationalId,
  [DocumentTypes.OTHER]: UploadEntity.other,
  [DocumentTypes.ACCOUNT_STATEMENT]: UploadEntity.accountStatement,
  [DocumentTypes.TRADE_CONFIRMATION]: UploadEntity.tradeConfirmation,
};

export function AddAttachmentDialog({ open, onClose, userId }: AddAttachmentDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    values,
    handleChange,
    touched,
    errors,
    isValid,
    submitForm,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      [addUserAttachmentFormFields.documentType]: 0,
      [addUserAttachmentFormFields.documentLink]: '',
    },
    validationSchema: addUserAttachmentFormSchema,
    onSubmit: async (formValues) => {
      const result: any = await dispatch(
        createUserAttachments({
          userId,
          items: [
            {
              documentTypeId: formValues.documentType,
              link: formValues.documentLink,
            },
          ],
        }),
      );

      if (isDispatchApiSuccess(result)) {
        enqueueSnackbar('Attachment created!', { variant: 'success' });
        onClose(true);
      } else {
        enqueueSnackbar(`Create Attachment Error: ${result?.error?.message}`, { variant: 'error' });
      }
    },
  });
  const { userAttachmentsDocumentTypeOptions, createUserAttachmentStatus, error } = useSelector(userSelector);
  const isMediaLoading = useSelector(mediaLoadingSelector);
  const isLoading = createUserAttachmentStatus === fetchStatuses.pending;

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];

    if (!file) {
      return;
    }

    if (file.size > fileSizeLimit) {
      await setFieldTouched(addUserAttachmentFormFields.documentLink, true);
      return setFieldError(addUserAttachmentFormFields.documentLink, 'Allowed Size 2 MB');
    }

    const documentType = userAttachmentsDocumentTypeOptions.find(
      (item) => item.id === values[addUserAttachmentFormFields.documentType],
    );

    if (!documentType) {
      return;
    }

    const entity = mapDocumentTypeToEntity[documentType.type];

    const result = await dispatch(uploadPrivate({ file, entity }));

    if (isDispatchApiSuccess<UploadMediaFileResponse>(result)) {
      await setFieldValue(addUserAttachmentFormFields.documentLink, result.payload.data.filename);
    } else {
      enqueueSnackbar(`Error uploading: ${result?.error?.message}`, { variant: 'error' });
    }
  };

  useEffect(() => {
    if (open) {
      dispatch(fetchUserAttachmentsDocumentTypeOptions());
    } else {
      resetForm();
    }
  }, [open]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Add an attachment</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error && <Alert severity="error">{error.message}</Alert>}
          Enter the action name
        </DialogContentText>
        <Grid
          onSubmit={handleSubmit}
          component="form"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={80} />
          ) : (
            <FormControl fullWidth margin="normal">
              <InputLabel id="document-type-label">Document Type</InputLabel>
              <Select
                labelId="document-type-label"
                name={addUserAttachmentFormFields.documentType}
                value={values[addUserAttachmentFormFields.documentType]}
                label="Document Type"
                onChange={handleChange}
                error={touched.documentType && !!errors.documentType}
              >
                {userAttachmentsDocumentTypeOptions.map((dt) => (
                  <MenuItem key={dt.id} value={dt.id}>
                    {dt.type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            disabled
            id={addUserAttachmentFormFields.documentLink}
            name={addUserAttachmentFormFields.documentLink}
            label="Document link"
            type="text"
            value={values[addUserAttachmentFormFields.documentLink]}
            error={touched.documentLink && !!errors.documentLink}
            helperText={touched.documentLink && errors.documentLink}
          />
          <LoadingButton
            variant="contained"
            component="label"
            loading={isMediaLoading}
            endIcon={<AttachmentIcon />}
            disabled={!values[addUserAttachmentFormFields.documentType]}
          >
            Upload file
            <input hidden accept={acceptFiles} type="file" onChange={uploadFile} />
          </LoadingButton>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={isLoading}
          disabled={!isValid}
          loadingPosition="end"
          onClick={submitForm}
        >
          Create Attachment
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
