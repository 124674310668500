import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { GridActionsCellItem, GridActionsCellItemProps, GridRowParams, GridColDef } from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { usePermissions } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import { cleanResources, deleteResource, fetchResources } from '../../redux/modules/resource/resource.actions';
import { resourceSelector } from '../../redux/modules/resource/resource.selectors';
import { Resource } from '../../types/resource';
import * as permissions from '../../utils/permissions';
import { ResourceDialog } from './ResourceDialog';

export function ResourceList() {
  const dispatch = useAppDispatch();
  const tableRef = useRef<TableRef>(null);
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState<Resource | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const canDelete = usePermissions({ items: [permissions.Resource.delete] });
  const canUpdate = usePermissions({ items: [permissions.Resource.update] });
  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 100,
    },
  ]);

  const { totalResources, resources, fetchStatus, deleteStatus } = useSelector(resourceSelector);

  const onDeleteResource = (id: number) => {
    dispatch(deleteResource(id));
  };

  const onEditResource = (row: Resource): void => {
    setEditValue(row);
    setOpen(true);
  };

  const onAddNew = (): void => {
    setOpen(true);
  };

  const handleClose = (isReload: boolean) => {
    if (isReload) {
      tableRef?.current?.reload();
    }

    setEditValue(null);
    setOpen(false);
  };

  useEffect(
    () => () => {
      dispatch(cleanResources());
    },
    [],
  );

  useEffect(() => {
    if (deleteStatus === fetchStatuses.success) {
      handleClose(true);
      enqueueSnackbar('Resource deleted!', { variant: 'success' });
    }
    if (deleteStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Resource deletion error!', { variant: 'error' });
    }
  }, [deleteStatus]);

  useEffect(() => {
    const actionColumn = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      filterable: false,
      sortable: false,
      getActions: (params: GridRowParams) => {
        const actions: React.ReactElement<GridActionsCellItemProps>[] = [];

        if (canDelete) {
          actions.push(
            <GridActionsCellItem
              key={`resource-list-delete-${params.row.id}`}
              icon={<DeleteOutlineOutlinedIcon />}
              label="Link"
              onClick={() => onDeleteResource(params.row.id)}
            />,
          );
        }

        if (canUpdate) {
          actions.push(
            <GridActionsCellItem
              key={`resource-list-update-${params.row.id}`}
              icon={<EditOutlinedIcon />}
              label="Actions"
              onClick={() => onEditResource(params.row)}
            />,
          );
        }

        return actions;
      },
    };

    if (canDelete || canUpdate) {
      setColumns([actionColumn, ...columns]);
    }
  }, [canDelete, canUpdate]);

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">
          Resources &nbsp;
          <Fab color="primary" size="small" aria-label="Create Resource" onClick={onAddNew}>
            <AddIcon />
          </Fab>
        </Typography>
      </Box>

      <ResourceDialog open={open} onClose={handleClose} initialValue={editValue} />

      <Table
        ref={tableRef}
        fetchItems={fetchResources}
        rows={resources}
        columns={columns}
        loading={fetchStatus === fetchStatuses.pending}
        rowCount={totalResources}
      />
    </Container>
  );
}
