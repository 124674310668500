import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { userPortfolioRequestByRequestIdSelector } from '../../redux/modules/userPortfolioRequest/userPortfolioRequest.selectors';
import { ReducerState } from '../../redux/store.types';
import { locations } from '../../routes/locations';
import { UserPortfolio } from '../../types/userPortfolioRequest';
import { PortfolioOrderTable } from './PortfolioOrderTable';
import { UserPortfolioRequestTable } from './UserPortfolioRequestTable';
import { round2Format } from '../../utils/number';

export function UserPortfolioRequest() {
  const id = Number(useParams().id);
  const navigate = useNavigate();

  const [userPortfolio, setUserPortfolio] = useState<UserPortfolio>();
  const { userPortfolioRequests, fetchStatus } = useSelector((state: ReducerState) =>
    userPortfolioRequestByRequestIdSelector(state, `user-portfolio-details-${id}`),
  );

  const [isRequestsLoaded, setIsRequestsLoaded] = useState(false);
  const [requestIds, setRequestIds] = useState<number[]>([]);

  useEffect(() => {
    if (!id) {
      return navigate(locations.userPortfolioRequestList(), { replace: true });
    }
  }, [id]);

  useEffect(() => {
    setRequestIds(userPortfolioRequests.map((upr) => upr.id));
  }, [userPortfolioRequests]);

  useEffect(() => {
    if (!isRequestsLoaded && fetchStatus === fetchStatuses.success) {
      setUserPortfolio(userPortfolioRequests?.[0]?.userPortfolio);
      setIsRequestsLoaded(true);
    }
  }, [fetchStatus, isRequestsLoaded]);

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography color="text.primary" variant="h4" sx={{ pb: 5 }}>
          Request Details
        </Typography>
      </Box>

      <Grid container rowSpacing={2}>
        {userPortfolio && (
          <Grid item container flexDirection="column" component={Paper} sx={{ p: 4 }} rowSpacing={2}>
            <Grid item container>
              <Grid item xs={6}>
                <Typography variant="subtitle1">Fund Amount</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{round2Format(userPortfolio.fundAmount)}</Typography>
              </Grid>
            </Grid>
            <Divider variant="inset" />
            <Grid item container>
              <Grid item xs={6}>
                <Typography variant="subtitle1">Cash Allocation Percentage</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{userPortfolio.cacheAllocationPercentage}%</Typography>
              </Grid>
            </Grid>
            <Divider variant="inset" />
            <Grid item container>
              <Grid item xs={6}>
                <Typography variant="subtitle1">Cash Allocation Value</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2">{round2Format(userPortfolio.cacheAllocationValue)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )}

        <Grid item sx={{ width: '100%' }}>
          <UserPortfolioRequestTable userPortfolioId={id} />
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          {isRequestsLoaded && <PortfolioOrderTable requestIds={requestIds} />}
        </Grid>
      </Grid>
    </Container>
  );
}
