import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchJadwaConfigResponse, FetchJadwaConfigsResponse } from '../../../types/jadwaConfig';
import {
  fetchJadwaConfig,
  fetchJadwaConfigs,
  resetEditJadwaConfig,
  cleanJadwaConfigs,
  updateJadwaConfig,
  createJadwaConfigItem,
  deleteJadwaConfig,
} from './jadwaConfig.actions';
import { JadwaConfigState } from './jadwaConfig.types';

const initialState: JadwaConfigState = {
  jadwaConfigs: [],
  editJadwaConfig: null,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const jadwaConfigsSlice = createSlice({
  name: 'jadwaConfigs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchJadwaConfigs.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchJadwaConfigs.fulfilled, (state, jadwaConfig: PayloadAction<FetchJadwaConfigsResponse>) => {
      state.jadwaConfigs = jadwaConfig.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchJadwaConfigs.rejected, (state, jadwaConfig) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = jadwaConfig.error;
    });

    builder.addCase(fetchJadwaConfig.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchJadwaConfig.fulfilled, (state, jadwaConfig: PayloadAction<FetchJadwaConfigResponse>) => {
      state.editJadwaConfig = jadwaConfig.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchJadwaConfig.rejected, (state, jadwaConfig) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = jadwaConfig.error;
    });

    builder.addCase(updateJadwaConfig.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateJadwaConfig.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateJadwaConfig.rejected, (state, jadwaConfig) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = jadwaConfig.error;
    });

    builder.addCase(createJadwaConfigItem.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(createJadwaConfigItem.fulfilled, (state, jadwaConfig: PayloadAction<FetchJadwaConfigResponse>) => {
      state.editJadwaConfig = jadwaConfig.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createJadwaConfigItem.rejected, (state, jadwaConfig) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = jadwaConfig.error;
    });

    builder.addCase(deleteJadwaConfig.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteJadwaConfig.fulfilled, (state) => {
      state.editJadwaConfig = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteJadwaConfig.rejected, (state, jadwaConfig) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = jadwaConfig.error;
    });

    builder.addCase(resetEditJadwaConfig.type, (state) => {
      state.editJadwaConfig = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanJadwaConfigs.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const jadwaConfigReducer = jadwaConfigsSlice.reducer;
