import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/system/Box';
import React from 'react';
import Tab from '@mui/material/Tab';
import { UserFee } from '../../../../types/userFee';
import { FeeCalculationTable } from './FeeCalculationTable';
import { MTabPanel } from '../../../../components/TabPanel';
import { UserFeeHistoryTable } from './UserFeeHistoryTable';

interface UserFeeInfoTabListProps {
  userFee: UserFee;
}

const enum TABS {
  CALCULATIONS = 'calculations',
  HISTORY = 'history',
  DEDUCTIONS = 'deductions',
}

export function UserFeeInfoTabList({ userFee }: UserFeeInfoTabListProps) {
  const [selectedTab, setSelectedTab] = React.useState<string>(TABS.CALCULATIONS);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <TabContext value={selectedTab}>
      <Box>
        <TabList
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="user fee info"
        >
          <Tab value={TABS.CALCULATIONS} label="Calculations" sx={{ textTransform: 'capitalize' }} disableRipple />
          <Tab value={TABS.HISTORY} label="History" sx={{ textTransform: 'capitalize' }} disableRipple />
        </TabList>
      </Box>
      <MTabPanel value={TABS.CALCULATIONS}>
        <FeeCalculationTable userFeeId={userFee.id} />
      </MTabPanel>
      <MTabPanel value={TABS.HISTORY}>
        <UserFeeHistoryTable userFeeId={userFee.id} />
      </MTabPanel>
      {/* <MTabPanel value={TABS.DEDUCTIONS}>
        <PortfolioPositionsTable portfolio={portfolio} userIndexFunds={userIndexFunds?.items || []} />
      </MTabPanel> */}
    </TabContext>
  );
}
