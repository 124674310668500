import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { useSnackbar } from 'notistack';
import SwitchAccessShortcutAddIcon from '@mui/icons-material/SwitchAccessShortcutAdd';
import Button from '@mui/material/Button';
import {
  GridColDef,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import { GridCellExpand, Table } from '../../components/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { fetchPortfolioOrderExecutionReport } from '../../redux/modules/report/report.actions';
import { reportSelector } from '../../redux/modules/report/report.selectors';
import { prepareOrderExecutionsReportData } from '../../utils/portfolioOrder';

interface CustomToolbarProps {
  onExportReport: () => void;
}

const csvDivider = ',';

function CustomToolbar({ onExportReport }: CustomToolbarProps) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <Button id="ExportOrderReport" startIcon={<SwitchAccessShortcutAddIcon />} onClick={onExportReport}>
        Export Report
      </Button>
    </GridToolbarContainer>
  );
}

export function PortfolioOrderExecutionsTable() {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id' },
    {
      headerName: 'User Id',
      field: 'userId',
    },
    {
      headerName: 'Order Id',
      field: 'orderId',
      type: 'string',
      width: 300,
    },
    {
      headerName: 'Execution Id',
      field: 'executionId',
      type: 'string',
      width: 300,
    },
    {
      field: 'indexFunds',
      headerName: 'Index Funds',
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <GridCellExpand value={JSON.stringify(params.value, null, 2) || ''} width={params.colDef.computedWidth} />
      ),
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: false,
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 100,
    },
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const [reportData, setReportData] = useState('');
  const csvOrderExecutionsReportLink = useRef<CSVLink>();

  const { portfolioOrderExecutions, totalPortfolioOrderExecutions, fetchPortfolioOrderExecutionsStatus } =
    useSelector(reportSelector);

  const onExportReport = () => {
    try {
      const data = prepareOrderExecutionsReportData(portfolioOrderExecutions, csvDivider);

      setReportData(data);

      setTimeout(() => {
        if (csvOrderExecutionsReportLink.current) {
          csvOrderExecutionsReportLink.current.link.click();
        }
      }, 0);
    } catch (error: any) {
      enqueueSnackbar(`Export Portfolio Order Executions Report failed: ${error?.message}`, { variant: 'error' });
    }
  };

  return (
    <>
      <Table
        wrapperHeight={700}
        fetchItems={fetchPortfolioOrderExecutionReport}
        rows={portfolioOrderExecutions}
        columns={columns.current}
        loading={fetchPortfolioOrderExecutionsStatus === fetchStatuses.pending}
        rowCount={totalPortfolioOrderExecutions}
        slots={{
          toolbar: () => CustomToolbar({ onExportReport }),
        }}
        initialColumnVisibilityModel={{ id: false, orderId: false }}
        initialSortModel={[{ field: 'id', sort: 'desc' }]}
      />

      <CSVLink
        data={reportData}
        filename={`order-execution-report-${Date.now()}.csv`}
        className="hidden"
        separator={csvDivider}
        ref={csvOrderExecutionsReportLink}
        target="_blank"
      />
    </>
  );
}
