import { PaginatedRequest } from '../types/api';
import {
  FetchCashReportResponse,
  FetchOrderFxRevenueReportResponse,
  FetchPortfolioOrderExecutionsReportResponse,
  FetchSystemCashReportResponse,
  FetchSystemUnitsReportResponse,
  FetchTransferFxRevenueReportResponse,
  FetchUnitReportResponse,
  ReconcileSystemUnitsReportRequest,
  ReconcileSystemUnitsReportResponse,
} from '../types/report';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class ReportService extends ApiService {
  pathname = 'admin';

  fetchPortfolioOrderExecutionReport = async (data: PaginatedRequest) =>
    this.get<FetchPortfolioOrderExecutionsReportResponse>(
      `${this.pathname}/report/portfolio-order-execution?${toQueryParams(data)}`,
    );

  fetchCashReport = async (data: PaginatedRequest) =>
    this.get<FetchCashReportResponse>(`${this.pathname}/report/cash?${toQueryParams(data)}`);

  fetchSystemCashReport = async (data: PaginatedRequest) =>
    this.get<FetchSystemCashReportResponse>(`${this.pathname}/report/cash/system?${toQueryParams(data)}`);

  fetchUnitsReport = async (data: PaginatedRequest) =>
    this.get<FetchUnitReportResponse>(`${this.pathname}/report/units?${toQueryParams(data)}`);

  fetchSystemUnitsReport = async () => this.get<FetchSystemUnitsReportResponse>(`${this.pathname}/report/units/system`);

  reconcileSystemUnitsReport = async ({ id, ...systemReport }: ReconcileSystemUnitsReportRequest) =>
    this.put<ReconcileSystemUnitsReportResponse, Omit<ReconcileSystemUnitsReportRequest, 'id'>>(
      `${this.pathname}/report/units/${id}/reconciliation`,
      systemReport,
    );

  fetchFxRevenueAlpacaOrdersReport = async (data: PaginatedRequest) =>
    this.get<FetchOrderFxRevenueReportResponse>(`${this.pathname}/alpaca/report/orders?${toQueryParams(data)}`);

  fetchFxRevenueAlpacaTransfersReport = async (data: PaginatedRequest) =>
    this.get<FetchTransferFxRevenueReportResponse>(`${this.pathname}/alpaca/report/transfers?${toQueryParams(data)}`);

  fetchFxRevenueJadwaOrdersReport = async (data: PaginatedRequest) =>
    this.get<FetchOrderFxRevenueReportResponse>(`${this.pathname}/jadwa/report/orders?${toQueryParams(data)}`);

  fetchFxRevenueJadwaTransfersReport = async (data: PaginatedRequest) =>
    this.get<FetchTransferFxRevenueReportResponse>(`${this.pathname}/jadwa/report/transfers?${toQueryParams(data)}`);
}

export const reportService = new ReportService();
