import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { alpacaService } from '../../../api';
import { FetchAlpacaEventsRequest } from '../../../types/alpaca';
import { PaginatedRequest } from '../../../types/api';

export const fetchAlpacaEvents = createAsyncThunk('alpaca/fetch-events', (data: FetchAlpacaEventsRequest) =>
  alpacaService.fetchEvents(data),
);

export const fetchAlpacaOrders = createAsyncThunk(
  'alpaca/fetch-all-orders',
  (data: PaginatedRequest & { requestId: string }, { rejectWithValue }) =>
    alpacaService.fetchOrders(data).catch((error) => rejectWithValue(error)),
);

export const fetchAlpacaTransfers = createAsyncThunk(
  'alpaca/fetch-all-transfers',
  (data: PaginatedRequest & { requestId: string }, { rejectWithValue }) =>
    alpacaService.fetchTransfers(data).catch((error) => rejectWithValue(error)),
);

export const fetchOrdersByMadkholOrderId = createAsyncThunk(
  'alpaca/fetch-orders-by-madkhol-order-id',
  (madkholOrderId: string) => alpacaService.fetchOrdersByMadkholOrderId(madkholOrderId),
);

export const sendOrderToJournalQueue = createAsyncThunk(
  'alpaca/send-order-to-journal-queue',
  (madkholOrderId: string) => alpacaService.sendOrderToJournalQueue(madkholOrderId),
);

export const sendOrderToCompleteQueue = createAsyncThunk(
  'alpaca/send-order-to-complete-queue',
  (madkholOrderId: string) => alpacaService.sendOrderToCompleteQueue(madkholOrderId),
);

export const sendOrderToTransferQueue = createAsyncThunk(
  'alpaca/send-order-to-transfer-queue',
  (madkholOrderId: string) => alpacaService.sendOrderToTransferQueue(madkholOrderId),
);

export const sendOrderToOrderQueue = createAsyncThunk('alpaca/send-order-to-order-queue', (madkholOrderId: string) =>
  alpacaService.sendOrderToOrderQueue(madkholOrderId),
);

export const fetchAccountByUserId = createAsyncThunk('alpaca/fetch-account-by-user-id', (userId: number) =>
  alpacaService.fetchAccountByUserId(userId),
);

export const createAlpacaAccount = createAsyncThunk('alpaca/create-account', (userId: number) =>
  alpacaService.createAccount(userId),
);

export const fetchTradingAccount = createAsyncThunk('alpaca/trading-account', () =>
  alpacaService.fetchTradingAccount(),
);

export const cleanAlpacas = createAction('alpaca/clean');
