import { ApiResponse } from './api';
import { UserFee } from './userFee';

export interface IFeeCalculationIndexFundItem extends IAnyObject {
  numOfUnits: number;
  indexFundId: number;
  netAssetValuePerUnit: number;
}

export interface IFeeCalculationIndexFunds {
  items: IFeeCalculationIndexFundItem[];
}

export enum FeeCalculationTypes {
  REGULAR_FEE = 'Regular Fee',
  FULLY_PAID_MONTHLY_FEE = 'Fully Paid Monthly Fee',
  FULL_REDEMPTION_FEE = 'Full Redemption Fee',
}

export interface FeeCalculationTypesOption {
  id: number;
  name: FeeCalculationTypes;
}

export enum FeeCalculationStatus {
  CREATED = 'Created',
  APPROVED = 'Approved',
  RETURNED_FOR_CORRECTION = 'Returned For Correction',
  REJECTED = 'Rejected',
  EXECUTING = 'Executing',
  DONE = 'Done',
  FAILED = 'Failed',
}

export interface FeeCalculationStatusOption {
  id: number;
  name: FeeCalculationStatus;
}

export type FeeCalculation = {
  id: number;
  batchId: string | null;
  totalMarketValue: number;
  calculatedTotalFee: number | null;
  actualTotalFee: number;
  managementFee: number;
  vat: number;
  indexFunds: IFeeCalculationIndexFunds | null;
  date: Date | string;
  userFee: UserFee;
  type: FeeCalculationTypesOption;
  status: FeeCalculationStatusOption;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchFeeCalculationResponse = ApiResponse<FeeCalculation>;
export type FetchFeeCalculationsResponse = ApiResponse<{
  items: FeeCalculation[];
  totalItems: number;
}>;
