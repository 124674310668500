import LoginIcon from '@mui/icons-material/Login';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Copyright } from '../../components/Copyright';
import { formFields } from '../../forms/fields/formFields';
import { forgotPasswordFormSchema, restorePasswordFormSchema } from '../../forms/validationSchema/formSchema';
import { parseApiError } from '../../helpers/api';
import { useForgotPasswordMutation, useRestorePasswordMutation } from '../../redux/features/auth/auth.apiSlice';
import { locations } from '../../routes/locations';

export function ForgotPassword() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const [restore, setRestore] = useState(false);
  const [restorePassword, { isLoading: isRestorePasswordLoading, error: errorRestorePassword }] =
    useRestorePasswordMutation();
  const [forgotPassword, { isLoading: isForgotPasswordLoading, error: errorForgotPassword }] =
    useForgotPasswordMutation();
  const loading = isRestorePasswordLoading || isForgotPasswordLoading;
  const forgotPasswordForm = useFormik({
    initialValues: { [formFields.email]: '' },
    validationSchema: forgotPasswordFormSchema,
    onSubmit: async (formValues) => {
      try {
        await forgotPassword({
          email: formValues.email.trim(),
        }).unwrap();
        setRestore(true);
      } catch (err) {
        forgotPasswordForm.resetForm();
      }
    },
  });
  const restorePasswordForm = useFormik({
    initialValues: {
      [formFields.password]: '',
      [formFields.passwordConfirmation]: '',
      [formFields.token]: '',
    },
    validationSchema: restorePasswordFormSchema,
    onSubmit: async (formValues) => {
      try {
        await restorePassword({
          ...formValues,
          token: formValues.token.trim(),
        }).unwrap();

        navigate(locations.login());
      } catch (err) {
        restorePasswordForm.resetForm();
      }
    },
  });

  const alreadyHaveToken = () => {
    restorePasswordForm.resetForm();
    setRestore(true);
  };

  const tryAnotherEmail = () => {
    forgotPasswordForm.resetForm();
    setRestore(false);
  };

  const backToLogin = () => {
    navigate(locations.login(), { replace: true });
  };

  const renderForgotPasswordForm = () => (
    <form style={{ width: '100%' }} onSubmit={forgotPasswordForm.handleSubmit}>
      <TextField
        id="email"
        fullWidth
        name={formFields.email}
        label="Email"
        value={forgotPasswordForm.values[formFields.email]}
        onChange={forgotPasswordForm.handleChange}
        error={forgotPasswordForm.touched.email && !!forgotPasswordForm.errors.email}
        helperText={forgotPasswordForm.touched.email && forgotPasswordForm.errors.email}
        autoComplete="username"
        margin="normal"
        required
        autoFocus
      />
      {errorForgotPassword && <Alert severity="error">{parseApiError(errorForgotPassword)?.data?.message}</Alert>}
      <LoadingButton
        type="submit"
        color="primary"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        endIcon={<LoginIcon />}
        loading={loading}
        loadingPosition="end"
      >
        Send
      </LoadingButton>
      <Grid container flexDirection="column">
        <Grid item xs>
          <Link variant="body2" onClick={alreadyHaveToken}>
            Already have a token
          </Link>
        </Grid>
        <Grid item xs>
          <Link variant="body2" onClick={backToLogin}>
            Back to login
          </Link>
        </Grid>
      </Grid>
    </form>
  );

  const renderRestorePasswordForm = () => (
    <form style={{ width: '100%' }} onSubmit={restorePasswordForm.handleSubmit}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="password"
        name={formFields.password}
        label="Password"
        type="password"
        value={restorePasswordForm.values[formFields.password]}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          restorePasswordForm.handleChange({
            target: {
              name: formFields.password,
              type: 'password',
              value: event.target.value.replace(/\s/g, ''),
            },
          });
        }}
        error={restorePasswordForm.touched.password && !!restorePasswordForm.errors.password}
        helperText={restorePasswordForm.touched.password && restorePasswordForm.errors.password}
        autoComplete="new-password"
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="password-confirmation"
        name={formFields.passwordConfirmation}
        label="Password Confirmation"
        type="password"
        value={restorePasswordForm.values[formFields.passwordConfirmation]}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          restorePasswordForm.handleChange({
            target: {
              name: formFields.passwordConfirmation,
              type: 'password',
              value: event.target.value.replace(/\s/g, ''),
            },
          });
        }}
        error={restorePasswordForm.touched.passwordConfirmation && !!restorePasswordForm.errors.passwordConfirmation}
        helperText={restorePasswordForm.touched.passwordConfirmation && restorePasswordForm.errors.passwordConfirmation}
        autoComplete="new-password-confirmation"
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="token"
        name={formFields.token}
        label="Token from email"
        type="token"
        value={restorePasswordForm.values[formFields.token]}
        onChange={restorePasswordForm.handleChange}
        error={restorePasswordForm.touched.token && !!restorePasswordForm.errors.token}
        helperText={restorePasswordForm.touched.token && restorePasswordForm.errors.token}
      />
      {errorRestorePassword && <Alert severity="error">{parseApiError(errorRestorePassword)?.data?.message}</Alert>}
      <LoadingButton
        type="submit"
        color="primary"
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
        endIcon={<LoginIcon />}
        loading={loading}
        loadingPosition="end"
      >
        Send
      </LoadingButton>
      <Grid container flexDirection="column">
        <Grid item xs>
          <Link variant="body2" onClick={tryAnotherEmail}>
            Try another email
          </Link>
        </Grid>
        <Grid item xs>
          <Link variant="body2" onClick={backToLogin}>
            Back to login
          </Link>
        </Grid>
      </Grid>
    </form>
  );

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ height: '100%', mb: 16 }}>
          <img
            style={{
              transition: 'width .3s',
              objectFit: 'contain',
              objectPosition: isArabic ? '100% 0' : '0 0',
            }}
            width="100%"
            height="100%"
            onClick={() => navigate(locations.home())}
            src={isArabic ? '/images/logo-with-text-ar.png' : '/images/logo-with-text-en.png'}
            alt="madkhol logo"
          />
        </Box>
        <Typography component="h1" variant="h5">
          {restore ? 'Restore Password' : 'Forgot Password'}
        </Typography>
        {restore ? renderRestorePasswordForm() : renderForgotPasswordForm()}
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
