import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import Chip from '@mui/material/Chip';
import { FakeButtonLink } from '../../components/FakeLink';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { satelliteRequestStatusToInfo } from '../../constants/tables/satelliteRequest';
import { fetchSatelliteRequestsByOrderId } from '../../redux/modules/portfolioOrder/portfolioOrder.actions';
import {
  portfolioOrderSelector,
  viewDetailsByOrderIdSelector,
} from '../../redux/modules/portfolioOrder/portfolioOrder.selectors';
import { locations } from '../../routes/locations';
import { SatelliteAccountRequestStatus } from '../../types/portfolioOrder';
import { round2Format, round4Format } from '../../utils/number';

interface SatelliteRequestsTabProps {
  orderId: string;
}

export function SatelliteRequestsTab({ orderId }: SatelliteRequestsTabProps) {
  const tableRef = useRef<TableRef>(null);
  const [columns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Id',
      width: 100,
      aggregable: false,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'numOfUnits',
      headerName: 'Units',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round4Format(params.value),
    },
    {
      field: 'actualAmount',
      headerName: 'Actual Amount',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'actualNumOfUnits',
      headerName: 'Actual Units',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round4Format(params.value),
    },
    {
      field: 'fundOrder.indexFund.nameEn',
      headerName: 'Fund',
      type: 'string',
      width: 300,
      aggregable: false,
      valueGetter: (params) => {
        if (!params.row?.fundOrder?.indexFund?.id) {
          return '';
        }
        return `(${params.row?.fundOrder?.indexFund?.id}) ${params.row?.fundOrder?.indexFund?.nameEn}`;
      },
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return (
          <Tooltip title={params.value} followCursor>
            <FakeButtonLink
              startIcon={<OpenInNewIcon />}
              href={locations.indexFund(params.row?.fundOrder?.indexFund?.id)}
            >
              {params.value}
            </FakeButtonLink>
          </Tooltip>
        );
      },
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(SatelliteAccountRequestStatus),
      minWidth: 200,
      flex: 1,
      aggregable: false,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = satelliteRequestStatusToInfo[params.row?.status?.name as SatelliteAccountRequestStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.row?.status?.name;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.row?.status?.name} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      minWidth: 100,
      aggregable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      minWidth: 100,
      aggregable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const { totalSatelliteAccountRequests, satelliteAccountRequests, fetchSatelliteAccountRequestsStatus } =
    useSelector(viewDetailsByOrderIdSelector);
  const { executeStatus, importExecutionFileStatus } = useSelector(portfolioOrderSelector);
  const loading =
    fetchSatelliteAccountRequestsStatus === fetchStatuses.pending ||
    importExecutionFileStatus === fetchStatuses.pending ||
    executeStatus === fetchStatuses.pending;

  useEffect(() => {
    if (importExecutionFileStatus === fetchStatuses.success || executeStatus === fetchStatuses.success) {
      tableRef.current?.reload();
    }
  }, [importExecutionFileStatus, executeStatus]);

  return (
    <Table
      ref={tableRef}
      fetchItems={fetchSatelliteRequestsByOrderId}
      rows={satelliteAccountRequests}
      columns={columns}
      loading={loading}
      rowCount={totalSatelliteAccountRequests}
      fetchItemsCustomParams={{ orderId }}
      initialAggregationModel={{ amount: 'sum', numOfUnits: 'sum', actualAmount: 'sum', actualNumOfUnits: 'sum' }}
      toolbarExportProps={{
        csvOptions: { fileName: `satellite-account-requests-${orderId}` },
        excelOptions: { fileName: `satellite-account-requests-${orderId}` },
      }}
    />
  );
}
