import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

export const CircularIndeterminate: React.FC = () => (
  <Grid
    container
    display="flex"
    position="relative"
    alignItems="center"
    justifyContent="center"
    width="100%"
    height="100vh"
  >
    <CircularProgress />
  </Grid>
);
