import { PaginatedRequest } from '../types/api';
import { FetchFeeCalculationResponse, FetchFeeCalculationsResponse } from '../types/feeCalculation';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class FeeCalculationService extends ApiService {
  pathname = 'admin/fee-calculation';

  fetchFeeCalculations = async (data: PaginatedRequest) =>
    this.get<FetchFeeCalculationsResponse>(`${this.pathname}?${toQueryParams(data)}`);

  fetchFeeCalculation = async (id: number) => this.get<FetchFeeCalculationResponse>(`${this.pathname}/${id}`);
}

export const feeCalculationService = new FeeCalculationService();
