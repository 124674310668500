import { PaginatedRequest } from '../types/api';
import {
  CreateFundValuationDayRequest,
  CreateFundValuationDayResponse,
  FetchFundValuationDayResponse,
  FetchFundValuationDaysAutocompleteResponse,
  FetchFundValuationDaysResponse,
  UpdateFundValuationDayRequest,
  UpdateFundValuationDayResponse,
} from '../types/fundValuationDay';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class FundValuationDayService extends ApiService {
  pathname = 'admin';

  fetchFundValuationDays = async (data: PaginatedRequest) =>
    this.get<FetchFundValuationDaysResponse>(`${this.pathname}/fund-valuation-day?${toQueryParams(data)}`);

  fetchFundValuationDaysAutocomplete = async (term: string) =>
    this.get<FetchFundValuationDaysAutocompleteResponse>(
      `${this.pathname}/fund-valuation-day/autocomplete?term=${term}`,
    );

  fetchFundValuationDay = async (id: number) =>
    this.get<FetchFundValuationDayResponse>(`${this.pathname}/fund-valuation-day/${id}`);

  deleteFundValuationDay = async (id: number) =>
    this.delete<FetchFundValuationDayResponse>(`${this.pathname}/fund-valuation-day/${id}`);

  createFundValuationDay = async (fundValuationDay: CreateFundValuationDayRequest) =>
    this.post<CreateFundValuationDayResponse, CreateFundValuationDayRequest>(
      `${this.pathname}/fund-valuation-day`,
      fundValuationDay,
    );

  updateFundValuationDay = async ({ id, ...fundValuationDay }: UpdateFundValuationDayRequest) =>
    this.put<UpdateFundValuationDayResponse, Omit<UpdateFundValuationDayRequest, 'id'>>(
      `${this.pathname}/fund-valuation-day/${id}`,
      fundValuationDay,
    );
}

export const fundValuationDayService = new FundValuationDayService();
