import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { userFeeService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { SaveUserFeeRequest, UpdateUserFeeRequest } from '../../../types/userFee';

export const fetchUserFees = createAsyncThunk('user-fee/fetch-all', (data: PaginatedRequest) =>
  userFeeService.fetchUserFees(data),
);

export const fetchUserFeeHistory = createAsyncThunk('user-fee/fetch-fee-history', (data: PaginatedRequest) =>
  userFeeService.fetchUserFeeHistory(data),
);

export const fetchUserFeesByUser = createAsyncThunk(
  'user-fee/fetch-all-by-user',
  (data: PaginatedRequest & { userId: number }) => userFeeService.fetchUserFeesByUser(data),
);

export const fetchUserFee = createAsyncThunk('user-fee/fetch-by-id', (id: number) => userFeeService.fetchUserFee(id));

export const deleteUserFee = createAsyncThunk('user-fee/delete', (id: number) => userFeeService.deleteUserFee(id));

export const saveUserFee = createAsyncThunk('user-fee/save', (data: SaveUserFeeRequest) =>
  userFeeService.saveUserFee(data),
);

export const updateUserFee = createAsyncThunk('user-fee/update', (data: UpdateUserFeeRequest) =>
  userFeeService.updateUserFee(data),
);

export const cleanUserFees = createAction('user-fee/clean');

export const resetEditUserFee = createAction('user-fee/reset-edit');
