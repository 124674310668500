import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import { UserPortfolioRequestStatus } from '../../types/userPortfolioRequest';

export const userPortfolioRequestStatusToInfo: {
  [prop in UserPortfolioRequestStatus]: {
    color: 'error' | 'info' | 'success' | 'warning';
    icon: React.ElementType;
    menuAction: string;
  };
} = {
  [UserPortfolioRequestStatus.PENDING]: {
    color: 'info',
    menuAction: 'Change to Pending',
    icon: InfoIcon,
  },
  [UserPortfolioRequestStatus.IN_PROGRESS]: {
    color: 'info',
    menuAction: 'Change to In Progress',
    icon: InfoIcon,
  },
  [UserPortfolioRequestStatus.APPROVED]: {
    color: 'success',
    menuAction: 'Approve',
    icon: CheckCircleIcon,
  },
  [UserPortfolioRequestStatus.WAITING_CAST_APPROVAL]: {
    color: 'info',
    menuAction: 'Change to waiting cast approval',
    icon: CheckCircleIcon,
  },
  [UserPortfolioRequestStatus.APPROVED_BY_CAST]: {
    color: 'success',
    menuAction: 'Change to approved by cast',
    icon: CheckCircleIcon,
  },
  [UserPortfolioRequestStatus.CREATED]: {
    color: 'success',
    menuAction: 'Change to created',
    icon: CheckCircleIcon,
  },
  [UserPortfolioRequestStatus.FAILED]: {
    color: 'error',
    menuAction: 'Change to failed',
    icon: CheckCircleIcon,
  },
  [UserPortfolioRequestStatus.PARTIAL_APPROVED_BY_CAST]: {
    color: 'warning',
    menuAction: 'Partial approve by cast',
    icon: CheckCircleIcon,
  },
  [UserPortfolioRequestStatus.PARTIAL_APPROVED]: {
    color: 'warning',
    menuAction: 'Partial approve',
    icon: CheckCircleIcon,
  },
  [UserPortfolioRequestStatus.REJECTED]: {
    color: 'error',
    menuAction: 'Reject',
    icon: WarningIcon,
  },
  [UserPortfolioRequestStatus.CANCELED]: {
    color: 'error',
    menuAction: 'Cancel',
    icon: CancelScheduleSendIcon,
  },
};
