import { createSelector } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { ReducerState } from '../../store.types';
import { PortfolioOrderState } from './portfolioOrder.types';

export const portfolioOrderSelector = ({ portfolioOrder }: ReducerState) => portfolioOrder;
export const viewDetailsByOrderIdSelector = ({ portfolioOrder }: ReducerState) => portfolioOrder.viewDetailsByOrderId;
export const portfolioOrderLoadingSelector = createSelector(
  portfolioOrderSelector,
  ({ fetchStatus }) => fetchStatus === fetchStatuses.pending,
);
export const portfolioOrderByRequestIdSelector = createSelector(
  portfolioOrderSelector,
  (state: ReducerState, requestId: string) => requestId,
  (portfolioOrderState: PortfolioOrderState, requestId: string) => ({
    portfolioOrders: portfolioOrderState.portfolioOrders[requestId] || [],
    totalPortfolioOrders: portfolioOrderState.totalPortfolioOrders[requestId] || 0,
    fetchStatus: portfolioOrderState.fetchStatuses[requestId],
  }),
);
export const orderAttachmentsLoadingSelector = createSelector(
  portfolioOrderSelector,
  ({ fetchOrderAttachmentsStatus }) => fetchOrderAttachmentsStatus === fetchStatuses.pending,
);
export const orderAttachmentsSelector = createSelector(
  portfolioOrderSelector,
  ({ orderAttachments }) => orderAttachments,
);
