import { ActionType } from '../../types/action';

const listPermission = {
  resource: 'index-funds',
  action: ActionType.READ,
};

const createPermission = {
  resource: 'index-fund',
  action: ActionType.CREATE,
};
const updatePermission = {
  resource: 'index-fund',
  action: ActionType.UPDATE,
};
const navBulkUpdatePermission = {
  resource: 'index-fund-nav-bulk',
  action: ActionType.UPDATE,
};
const navHistoryPermission = {
  resource: 'fund-nav-history',
  action: ActionType.UPDATE,
};
const readPermission = {
  resource: 'index-fund',
  action: ActionType.READ,
};
const deletePermission = {
  resource: 'index-fund',
  action: ActionType.UPDATE,
};

export const IndexFund = {
  list: listPermission,
  read: readPermission,
  create: createPermission,
  update: updatePermission,
  navBulkUpdate: navBulkUpdatePermission,
  navHistory: navHistoryPermission,
  delete: deletePermission,
};
