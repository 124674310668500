import { createSlice } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { createManualAlpacaTransfer, runAlpacaPrefunding } from './alpaca-transfer.actions';
import { AlpacaTransferState } from './alpaca-transfer.types';

const initialState: AlpacaTransferState = {
  createStatus: fetchStatuses.idle,
  error: null,
};

const transferSlice = createSlice({
  name: 'alpacaTransfer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createManualAlpacaTransfer.pending, (state) => {
      state.createStatus = fetchStatuses.pending;
    });
    builder.addCase(createManualAlpacaTransfer.fulfilled, (state) => {
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createManualAlpacaTransfer.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(runAlpacaPrefunding.pending, (state) => {
      state.createStatus = fetchStatuses.pending;
    });
    builder.addCase(runAlpacaPrefunding.fulfilled, (state) => {
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(runAlpacaPrefunding.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });
  },
});

export const alpacaTransferReducer = transferSlice.reducer;
