import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { MastercardState } from './mastercard.types';
import { fetchMastercardSessions } from './mastercard.actions';
import { FetchMastercardSessionsResponse } from '../../../types/mastercard';

const initialState: MastercardState = {
  mastercardSessions: [],
  totalMastercardSessions: 0,
  fetchMastercardSessionsStatus: fetchStatuses.idle,
  error: null,
};

const mastercardSlice = createSlice({
  name: 'mastercard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMastercardSessions.pending, (state) => {
      state.fetchMastercardSessionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchMastercardSessions.fulfilled,
      (state, action: PayloadAction<FetchMastercardSessionsResponse>) => {
        state.mastercardSessions = action.payload.data.items;
        state.totalMastercardSessions = action.payload.data.totalItems;
        state.fetchMastercardSessionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchMastercardSessions.rejected, (state, action) => {
      state.fetchMastercardSessionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });
  },
});

export const mastercardReducer = mastercardSlice.reducer;
