import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { useAppDispatch } from '../../../../redux/hooks';
import { fetchAnbPaymentDetails } from '../../../../redux/modules/payment/payment.actions';
import { anbPaymentDetailsSelector, paymentSelector } from '../../../../redux/modules/payment/payment.selectors';
import { ReducerState } from '../../../../redux/store.types';
import { IJadwaTransfer, JadwaTransferStatus } from '../../../../types/jadwa';
import { round2Format } from '../../../../utils/number';

interface JadwaTransferProps {
  isLoading: boolean;
  transfer: IJadwaTransfer;
  updateTransfer: (transfer: IJadwaTransfer) => void;
}

export function JadwaTransfer({ transfer, updateTransfer, isLoading }: JadwaTransferProps) {
  const { t } = useTranslation('apiError');
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { fetchAnbPaymentDetailsStatus, error } = useSelector(paymentSelector);
  const paymentDetails = useSelector((state: ReducerState) => anbPaymentDetailsSelector(state, transfer.anbTransferId));

  const fetchAnbTransferDetails = async () => {
    if (transfer.anbTransferId) {
      try {
        await dispatch(fetchAnbPaymentDetails(transfer.anbTransferId)).unwrap();

        enqueueSnackbar('Anb Payment details found successfully!', { variant: 'success' });
      } catch (err: any) {
        enqueueSnackbar(`Fetch Anb Payment details failed: ${t(err?.message)} ${JSON.stringify(err?.data)}`, {
          variant: 'error',
        });
      }
    }
  };

  const renderPaymentDetails = () => {
    if (error) {
      return (
        <>
          <Divider variant="inset" />
          <Grid item container>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Payment Details</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <Alert severity="error">{error.message}</Alert>
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Divider variant="inset" />
        <Grid item container>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Payment Details</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" component="pre" style={{ padding: 8, overflow: 'auto' }}>
              {paymentDetails ? JSON.stringify(paymentDetails, null, 2) : 'Not found'}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: 6 }} spacing={2}>
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Id</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{transfer.id}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Status</Typography>
        </Grid>
        <Grid item container columnSpacing={2} xs={6} display="flex" alignItems="center">
          <Grid item>
            <Typography variant="body2">{transfer.status}</Typography>
          </Grid>
          {transfer.status === JadwaTransferStatus.QUEUED && (
            <Grid item display="flex" alignSelf="baseline">
              <IconButton
                edge="end"
                aria-label="update"
                disabled={isLoading}
                onClick={() => updateTransfer(transfer)}
                sx={{ p: 0 }}
              >
                <PublishedWithChangesIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Fx Rate</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{transfer.fxRate}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Real Fx Rate</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{transfer.realFxRate}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Amount</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{round2Format(transfer.amount)}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Actual Amount</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{round2Format(transfer.actualAmount)}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Original Amount</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{round2Format(transfer.originalAmount)}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Currency</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{transfer.currency}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Direction</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{transfer.direction}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Anb Transfer Id</Typography>
        </Grid>
        <Grid item xs={6}>
          <LoadingButton
            type="button"
            color="primary"
            size="small"
            variant="text"
            endIcon={<SavedSearchIcon />}
            disabled={!transfer.anbTransferId}
            loading={fetchAnbPaymentDetailsStatus === fetchStatuses.pending}
            loadingPosition="end"
            onClick={() => fetchAnbTransferDetails()}
          >
            {transfer.anbTransferId}
          </LoadingButton>
        </Grid>
      </Grid>
      {renderPaymentDetails()}
    </Grid>
  );
}
