import { GridNativeColTypes, GridFilterOperator } from '@mui/x-data-grid-premium';
import { gridNumericOperators } from './components/filterOperators/gridNumericOperators';
import { gridStringOperators } from './components/filterOperators/gridStringOperators';

export const mapColumTypeToFilterOperators: {
  [prop in GridNativeColTypes | string]: GridFilterOperator[];
} = {
  number: gridNumericOperators,
  string: gridStringOperators,
};
