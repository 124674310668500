import React from 'react';
import { Grid, Chip, ListItem } from '@mui/material';

interface QuickFilterProps {
  color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  filters: string[];
  selectedFilters: string[];
  onSelectFilter: (filter: any, selectedTab: any) => void;
  selectedTab: number | string;
}

const QuickFilter = ({ color = 'info', filters, onSelectFilter, selectedFilters, selectedTab }: QuickFilterProps) => {
  const getStyle = (item) => {
    switch (item) {
      case 'Select All':
      case 'Clear All':
        return '1px transparent solid';

      default:
        return selectedFilters.includes(item) ? '1px transparent solid' : '';
    }
  };
  return (
    <Grid flexDirection="row" sx={{ display: 'flex', maxWidth: '100%', overflowY: 'auto', gap: 2, p: 0.5, m: 0 }}>
      {filters.map((item) => (
        <ListItem key={item} sx={{ width: 'fit-content', p: 0 }}>
          <Chip
            label={item}
            variant={selectedFilters.includes(item) ? 'filled' : 'outlined'}
            color={color}
            onClick={() => onSelectFilter(item, selectedTab)}
            sx={{
              border: getStyle(item),
            }}
          />
        </ListItem>
      ))}
    </Grid>
  );
};

export default QuickFilter;
