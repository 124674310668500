import { ActionType } from '../../types/action';

const listPermission = {
  resource: 'users',
  action: ActionType.READ,
};
const createPermission = {
  resource: 'user',
  action: ActionType.CREATE,
};
const updatePermission = {
  resource: 'user',
  action: ActionType.UPDATE,
};
const readPermission = {
  resource: 'user',
  action: ActionType.READ,
};
const statusUpdatePermission = {
  resource: 'user-status',
  action: ActionType.UPDATE,
};
const withdrawPermission = {
  resource: 'user-withdraw',
  action: ActionType.CREATE,
};
const readAddressPermission = {
  resource: 'user-address',
  action: ActionType.READ,
};

export const User = {
  list: listPermission,
  read: readPermission,
  create: createPermission,
  update: updatePermission,
  withdraw: withdrawPermission,
  statusUpdate: statusUpdatePermission,
  readAddress: readAddressPermission,
};
