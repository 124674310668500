import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { jadwaConfigService } from '../../../api';
import { CreateJadwaConfigRequest, UpdateJadwaConfigRequest } from '../../../types/jadwaConfig';

export const fetchJadwaConfigs = createAsyncThunk('jadwaConfig/fetch-all', () =>
  jadwaConfigService.fetchJadwaConfigs(),
);

export const fetchJadwaConfig = createAsyncThunk('jadwaConfig/get', (key: string) =>
  jadwaConfigService.fetchJadwaConfig(key),
);

export const deleteJadwaConfig = createAsyncThunk('jadwaConfig/delete', (key: string) =>
  jadwaConfigService.deleteJadwaConfig(key),
);

export const createJadwaConfigItem = createAsyncThunk('jadwaConfig/create', (jadwaConfig: CreateJadwaConfigRequest) =>
  jadwaConfigService.createJadwaConfig(jadwaConfig),
);

export const updateJadwaConfig = createAsyncThunk('jadwaConfig/update', (jadwaConfig: UpdateJadwaConfigRequest) =>
  jadwaConfigService.updateJadwaConfig(jadwaConfig),
);

export const updateJadwaConfigs = createAsyncThunk(
  'jadwaConfig/update-all',
  (jadwaConfigs: UpdateJadwaConfigRequest[]) => jadwaConfigService.updateJadwaConfigs(jadwaConfigs),
);

export const resetEditJadwaConfig = createAction('jadwaConfig/reset-edit');

export const cleanJadwaConfigs = createAction('jadwaConfig/clean');
