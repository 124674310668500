import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import WarningIcon from '@mui/icons-material/Warning';
import { GridActionsCellItemProps, GridRenderCellParams, GridColDef, GridRowParams } from '@mui/x-data-grid-premium';
import { FakeButtonLink, GridActionsCellItemLink } from '../../components/FakeLink';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { usePermissions, useSearchParamsState } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import { cleanBatchExecutions, fetchBatchExecutions } from '../../redux/modules/batchExecution/batchExecution.actions';
import { batchExecutionByRequestIdSelector } from '../../redux/modules/batchExecution/batchExecution.selectors';
import { ReducerState } from '../../redux/store.types';
import { locations } from '../../routes/locations';
import { FeeBatchExecutionStatus } from '../../types/feeBatch';
import { parseJSON } from '../../utils/json';
import * as permissions from '../../utils/permissions';

const batchExecutionStatusToInfo: {
  [prop in FeeBatchExecutionStatus]: {
    color: 'error' | 'info' | 'success' | 'warning';
    icon: React.ElementType;
  };
} = {
  [FeeBatchExecutionStatus.EXECUTION]: {
    color: 'info',
    icon: InfoIcon,
  },
  [FeeBatchExecutionStatus.APPROVED]: {
    color: 'success',
    icon: CheckCircleIcon,
  },
  [FeeBatchExecutionStatus.IN_REVIEW]: {
    color: 'warning',
    icon: WarningIcon,
  },
  [FeeBatchExecutionStatus.RECONCILIATION]: {
    color: 'warning',
    icon: WarningIcon,
  },
};

export function BatchExecutionList() {
  const requestId = 'batch-executions-list-all';
  const dispatch = useAppDispatch();
  const tableRef = useRef<TableRef>(null);
  const canRead = usePermissions({ items: [permissions.BatchExecution.read] });
  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Id',
      width: 80,
    },
    {
      field: 'executionId',
      headerName: 'Execution Id',
      type: 'string',
      width: 300,
    },
    {
      field: 'batchId',
      headerName: 'BatchId',
      type: 'string',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return (
          <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.feeBatch(params.value)}>
            {params.value}
          </FakeButtonLink>
        );
      },
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(FeeBatchExecutionStatus),
      minWidth: 150,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = batchExecutionStatusToInfo[params.row?.status?.name as FeeBatchExecutionStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.row?.status?.name;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.row?.status?.name} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const { totalbatchExecutions, batchExecutions, fetchStatus } = useSelector((state: ReducerState) =>
    batchExecutionByRequestIdSelector(state, requestId),
  );
  const loading = fetchStatus === fetchStatuses.pending;
  const [initialFilterModel, setInitialFilterModel] = useSearchParamsState('filter', '');
  const [initialPage, setInitialPage] = useSearchParamsState('page', '0');
  const [initialPageSize, setInitialPageSize] = useSearchParamsState('pageSize', '50');

  useEffect(
    () => () => {
      dispatch(cleanBatchExecutions());
    },
    [],
  );

  useEffect(() => {
    const actionColumn = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      filterable: false,
      sortable: false,
      getActions: (params: GridRowParams) => {
        const actions: React.ReactElement<GridActionsCellItemProps>[] = [];

        if (canRead && `${params.id}`.split('/').length === 2) {
          const executionId = `${params.id}`.split('/').pop();

          actions.push(
            <GridActionsCellItemLink
              key={`batch-execution-list-read-${executionId}`}
              icon={<OpenInNewIcon />}
              label="Link"
              href={locations.batchExecution(executionId)}
            />,
          );
        }

        return actions;
      },
    };

    if (canRead) {
      setColumns([actionColumn, ...columns]);
    }
  }, [canRead]);

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">Batch Executions &nbsp;</Typography>
      </Box>
      <Table
        ref={tableRef}
        fetchItems={fetchBatchExecutions}
        rows={batchExecutions}
        columns={columns}
        loading={loading}
        rowCount={totalbatchExecutions}
        initialFilterModel={parseJSON(initialFilterModel)}
        initialPage={+initialPage}
        initialPageSize={+initialPageSize}
        fetchItemsCustomParams={{ requestId }}
        initialSortModel={[{ field: 'id', sort: 'desc' }]}
        initialColumnVisibilityModel={{ id: false, executionId: false }}
        onFilterModelChange={(filterModel) => setInitialFilterModel(JSON.stringify(filterModel))}
        onPageChange={(page) => setInitialPage(`${page}`)}
        onPageSizeChange={(pageSize) => setInitialPageSize(`${pageSize}`)}
        disableRowGrouping={false}
        rowGroupingColumnMode="single"
        initialRowGroupingModel={['executionId']}
        defaultGroupingExpansionDepth={1}
      />
    </Container>
  );
}
