import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FakeButtonLink } from '../../../../components/FakeLink';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { subscribedPortfolioSelector } from '../../../../redux/modules/subscribedPortfolio/subscribedPortfolio.selectors';
import { locations } from '../../../../routes/locations';
import { SubscribedPortfolio } from '../../../../types/subscribedPortfolio';
import { IndexFundsTabList } from './IndexFundsTabList';

interface SubscribedPortfolioTabListProps {
  userId: number;
}

export function SubscribedPortfolioTabList({ userId }: SubscribedPortfolioTabListProps) {
  const { subscribedPortfolios, fetchStatus } = useSelector(subscribedPortfolioSelector);

  const [selectedTab, setSelectedTab] = React.useState<string>(
    subscribedPortfolios.length ? `${subscribedPortfolios[0]?.id}` : '',
  );

  const selectedPortfolio = React.useMemo(
    () => subscribedPortfolios.find((sp) => `${sp.id}` === selectedTab),
    [subscribedPortfolios, selectedTab],
  );

  const isLoading = fetchStatus === fetchStatuses.pending;

  const handleChangeSatelliteAccountTab = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (subscribedPortfolios.length && !selectedTab) {
      setSelectedTab(`${subscribedPortfolios[0].id}`);
    }
  }, [subscribedPortfolios.length]);

  const renderDetailsInfo = (subscribedPortfolio: SubscribedPortfolio) => (
    <Grid container>
      <Grid item container flexDirection="row" sx={{ mt: 0 }} spacing={2} xs={12}>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Id</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.subscribedPortfolio(subscribedPortfolio?.id)}>
              {subscribedPortfolio?.id}
            </FakeButtonLink>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ pt: 4, pb: 2 }}>
        <Divider />
      </Grid>

      <Grid item container flexDirection="row" sx={{ mt: 0 }} spacing={2} xs={12}>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Name</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{`${subscribedPortfolio?.nameEn} - ${subscribedPortfolio?.productType.nameEn}`}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Description</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{subscribedPortfolio?.descriptionEn}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ pt: 4, pb: 2 }}>
        <Divider />
      </Grid>

      <Grid item container flexDirection="row" sx={{ mt: 0 }} spacing={2} xs={12}>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Status</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{subscribedPortfolio?.status?.name}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderTabList = () => (
    <>
      <Box sx={{ width: '100%' }}>
        <TabList
          value={selectedTab}
          onChange={handleChangeSatelliteAccountTab}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="subscribed portfolio"
        >
          {subscribedPortfolios.map((sa) => (
            <Tab
              key={`subscribed-portfolio-${sa.id}`}
              value={sa.id.toString()}
              label={`#${sa.id} - ${sa.nameEn} - ${sa.productType.nameEn}`}
            />
          ))}
        </TabList>
      </Box>
      {subscribedPortfolios.map((sa) => (
        <TabPanel key={`subscribed-portfolio-panel-${sa.id}`} value={sa.id.toString()} sx={{ width: '100%' }}>
          {renderDetailsInfo(sa)}
        </TabPanel>
      ))}
    </>
  );

  if (isLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <TabContext value={selectedTab}>
      {subscribedPortfolios.length && selectedTab ? renderTabList() : <InsertPageBreakIcon />}
      {selectedTab && selectedPortfolio?.id && (
        <Grid
          item
          container
          flexDirection="column"
          component={Paper}
          sx={{ p: '24px', mt: '24px', height: '600px' }}
          spacing={2}
        >
          <Grid item>
            <Typography variant="h6">Index Funds</Typography>
          </Grid>
          <Grid item sx={{ position: 'relative', width: '100%' }}>
            <IndexFundsTabList
              key={`user-subscribed-portfolio-${selectedPortfolio.id}-index-funds-tab`}
              userId={userId}
              subscribedPortfolio={selectedPortfolio}
            />
          </Grid>
        </Grid>
      )}
    </TabContext>
  );
}
