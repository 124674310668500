import { PaginatedRequest } from '../types/api';
import { FetchBatchExecutionByBatchIdResponse, FetchBatchExecutionsResponse } from '../types/batchExecution';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class BatchExecutionService extends ApiService {
  pathname = 'admin/batch-execution';

  fetchBatchExecutions = async (data: PaginatedRequest) =>
    this.get<FetchBatchExecutionsResponse>(`${this.pathname}?${toQueryParams(data)}`);

  fetchBatchExecutionByBatchId = async (batchId: string) =>
    this.get<FetchBatchExecutionByBatchIdResponse>(`${this.pathname}/batch/${batchId}`);
}

export const batchExecutionService = new BatchExecutionService();
