import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchUserIndexFundResponse, FetchUserIndexFundsResponse } from '../../../types/userIndexFund';
import {
  fetchUserIndexFunds,
  resetEditUserIndexFund,
  saveUserIndexFund,
  deleteUserIndexFund,
  cleanUserIndexFunds,
  updateUserIndexFund,
  fetchUserIndexFundsByUser,
  fetchUserIndexFund,
} from './userIndexFund.actions';
import { UserIndexFundState } from './userIndexFund.types';

const initialState: UserIndexFundState = {
  userIndexFunds: [],
  totalUserIndexFunds: 0,
  editUserIndexFund: null,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  saveStatus: fetchStatuses.idle,
  error: null,
};

const userIndexFundsSlice = createSlice({
  name: 'userIndexFunds',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserIndexFunds.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUserIndexFunds.fulfilled, (state, action: PayloadAction<FetchUserIndexFundsResponse>) => {
      state.userIndexFunds = action.payload.data?.items;
      state.totalUserIndexFunds = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserIndexFunds.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });
    builder.addCase(fetchUserIndexFundsByUser.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserIndexFundsByUser.fulfilled,
      (state, action: PayloadAction<FetchUserIndexFundsResponse>) => {
        state.userIndexFunds = action.payload.data?.items;
        state.totalUserIndexFunds = action.payload.data?.totalItems;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserIndexFundsByUser.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserIndexFund.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUserIndexFund.fulfilled, (state, action: PayloadAction<FetchUserIndexFundResponse>) => {
      state.editUserIndexFund = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserIndexFund.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(saveUserIndexFund.pending, (state) => {
      state.error = null;
      state.saveStatus = fetchStatuses.pending;
    });
    builder.addCase(saveUserIndexFund.fulfilled, (state, action: PayloadAction<FetchUserIndexFundResponse>) => {
      state.editUserIndexFund = state.editUserIndexFund || ({} as any);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Object.assign(state.editUserIndexFund!, {
        ...action.payload.data,
        indexFund: { id: action.payload.data.indexFund },
      });
      state.saveStatus = fetchStatuses.success;
    });
    builder.addCase(saveUserIndexFund.rejected, (state, action) => {
      state.saveStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateUserIndexFund.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
    });
    builder.addCase(updateUserIndexFund.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateUserIndexFund.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteUserIndexFund.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
    });
    builder.addCase(deleteUserIndexFund.fulfilled, (state) => {
      state.editUserIndexFund = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteUserIndexFund.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditUserIndexFund.type, (state) => {
      state.editUserIndexFund = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.saveStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanUserIndexFunds.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const userIndexFundReducer = userIndexFundsSlice.reducer;
