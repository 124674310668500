import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchUsersStatsResponse,
  FetchFunnelChartResponse,
  FetchRfmChartResponse,
  FetchPortfolioStatsResponse,
  FetchPortfoliosPerformanceResponse,
} from '../../../types/analytics';
import {
  fetchUsersStats,
  fetchFunnelChart,
  fetchRfmChart,
  fetchPortfolioStats,
  fetchPortfoliosPerformance,
  cleanAnalytics,
} from './analytics.actions';
import { AnalyticsState } from './analytics.types';

const initialState: AnalyticsState = {
  usersStats: [],
  portfolioStats: [],
  portfoliosPerformance: [],
  funnelChart: null,
  rfmChart: null,
  fetchUsersStatsStatus: fetchStatuses.idle,
  fetchPortfolioStatsStatus: fetchStatuses.idle,
  fetchPortfoliosPerformanceStatus: fetchStatuses.idle,
  fetchFunnelChartStatus: fetchStatuses.idle,
  fetchRfmChartStatus: fetchStatuses.idle,
  error: null,
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUsersStats.pending, (state) => {
      state.fetchUsersStatsStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUsersStats.fulfilled, (state, action: PayloadAction<FetchUsersStatsResponse>) => {
      state.usersStats = action.payload.data;
      state.fetchUsersStatsStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUsersStats.rejected, (state, action) => {
      state.fetchUsersStatsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFunnelChart.pending, (state) => {
      state.fetchFunnelChartStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchFunnelChart.fulfilled, (state, action: PayloadAction<FetchFunnelChartResponse>) => {
      state.funnelChart = action.payload.data;
      state.fetchFunnelChartStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFunnelChart.rejected, (state, action) => {
      state.fetchFunnelChartStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchRfmChart.pending, (state) => {
      state.fetchRfmChartStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchRfmChart.fulfilled, (state, action: PayloadAction<FetchRfmChartResponse>) => {
      state.rfmChart = action.payload.data;
      state.fetchRfmChartStatus = fetchStatuses.success;
    });
    builder.addCase(fetchRfmChart.rejected, (state, action) => {
      state.fetchRfmChartStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchPortfolioStats.pending, (state) => {
      state.fetchPortfolioStatsStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchPortfolioStats.fulfilled, (state, action: PayloadAction<FetchPortfolioStatsResponse>) => {
      state.portfolioStats = action.payload.data;
      state.fetchPortfolioStatsStatus = fetchStatuses.success;
    });
    builder.addCase(fetchPortfolioStats.rejected, (state, action) => {
      state.fetchPortfolioStatsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchPortfoliosPerformance.pending, (state) => {
      state.fetchPortfoliosPerformanceStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchPortfoliosPerformance.fulfilled,
      (state, action: PayloadAction<FetchPortfoliosPerformanceResponse>) => {
        state.portfoliosPerformance = action.payload.data;
        state.fetchPortfoliosPerformanceStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchPortfoliosPerformance.rejected, (state, action) => {
      state.fetchPortfoliosPerformanceStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(cleanAnalytics.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const analyticsReducer = analyticsSlice.reducer;
