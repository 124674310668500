import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Table } from '../../../components/Table';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { fetchFxRevenueAlpacaTransfersReport } from '../../../redux/modules/report/report.actions';
import { reportSelector } from '../../../redux/modules/report/report.selectors';

export function AlpacaTransfersFxRevenueTable() {
  const columns = useRef<GridColDef[]>([
    {
      headerName: 'Transfer ID',
      field: 'transferId',
      type: 'string',
      width: 100,
    },
    {
      headerName: 'Type',
      field: 'type',
    },
    {
      headerName: 'SAR Amount',
      field: 'sarAmount',
      type: 'number',
      width: 100,
    },
    {
      headerName: 'System FX',
      field: 'systemFxRate',
      type: 'number',
      width: 100,
    },
    {
      headerName: 'Real FX',
      field: 'exchangeRate',
      type: 'number',
      width: 100,
    },
    {
      headerName: 'System USD Amount',
      field: 'systemUsdAmount',
      type: 'number',
      width: 100,
    },
    {
      headerName: 'Real USD Amount',
      field: 'realUsdAmount',
      type: 'number',
      width: 100,
    },
    {
      headerName: 'USD Revenue',
      field: 'usdRevenue',
      type: 'number',
      width: 100,
    },
    {
      headerName: 'SAR Revenue',
      field: 'sarRevenue',
      type: 'number',
      width: 100,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: false,
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 100,
    },
  ]);

  const { alpacaFxRevenueTransfers, totalAlpacaFxRevenueTransfers, fetchAlpacaFxRevenueTransferStatus } =
    useSelector(reportSelector);

  return (
    <Table
      wrapperHeight={700}
      fetchItems={fetchFxRevenueAlpacaTransfersReport}
      rows={alpacaFxRevenueTransfers}
      columns={columns.current}
      loading={fetchAlpacaFxRevenueTransferStatus === fetchStatuses.pending}
      rowCount={totalAlpacaFxRevenueTransfers}
      getRowId={(row) => row.transferId}
      initialSortModel={[{ field: 'createdAt', sort: 'desc' }]}
    />
  );
}
