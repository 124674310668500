import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchSubscribedPortfolioResponse,
  FetchSubscribedPortfoliosResponse,
  FetchSubscribedPortfolioStatusOptionsResponse,
} from '../../../types/subscribedPortfolio';
import {
  fetchSubscribedPortfolio,
  fetchSubscribedPortfolios,
  resetEditSubscribedPortfolio,
  saveSubscribedPortfolio,
  deleteSubscribedPortfolio,
  cleanSubscribedPortfolios,
  updateSubscribedPortfolioStatus,
  fetchSubscribedPortfolioStatusOptions,
  updateSubscribedPortfolio,
} from './subscribedPortfolio.actions';
import { SubscribedPortfolioState } from './subscribedPortfolio.types';

const initialState: SubscribedPortfolioState = {
  subscribedPortfolios: [],
  totalSubscribedPortfolios: 0,
  editSubscribedPortfolio: null,
  subscribedPortfolioStatusOptions: [],
  fetchSubscribedPortfolioStatusOptionsStatus: fetchStatuses.idle,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  updateStatusStatus: fetchStatuses.idle,
  saveStatus: fetchStatuses.idle,
  error: null,
};

const portfoliosSlice = createSlice({
  name: 'portfolios',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSubscribedPortfolios.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchSubscribedPortfolios.fulfilled,
      (state, action: PayloadAction<FetchSubscribedPortfoliosResponse>) => {
        state.subscribedPortfolios = action.payload.data?.items;
        state.totalSubscribedPortfolios = action.payload.data?.totalItems;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchSubscribedPortfolios.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchSubscribedPortfolio.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchSubscribedPortfolio.fulfilled,
      (state, action: PayloadAction<FetchSubscribedPortfolioResponse>) => {
        state.editSubscribedPortfolio = action.payload.data;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchSubscribedPortfolio.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchSubscribedPortfolioStatusOptions.pending, (state) => {
      state.error = null;
      state.fetchSubscribedPortfolioStatusOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchSubscribedPortfolioStatusOptions.fulfilled,
      (state, action: PayloadAction<FetchSubscribedPortfolioStatusOptionsResponse>) => {
        state.subscribedPortfolioStatusOptions = action.payload.data;
        state.fetchSubscribedPortfolioStatusOptionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchSubscribedPortfolioStatusOptions.rejected, (state, action) => {
      state.fetchSubscribedPortfolioStatusOptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateSubscribedPortfolioStatus.pending, (state) => {
      state.error = null;
      state.updateStatusStatus = fetchStatuses.pending;
    });
    builder.addCase(updateSubscribedPortfolioStatus.fulfilled, (state) => {
      state.updateStatusStatus = fetchStatuses.success;
    });
    builder.addCase(updateSubscribedPortfolioStatus.rejected, (state, action) => {
      state.updateStatusStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(saveSubscribedPortfolio.pending, (state) => {
      state.error = null;
      state.saveStatus = fetchStatuses.pending;
    });
    builder.addCase(
      saveSubscribedPortfolio.fulfilled,
      (state, action: PayloadAction<FetchSubscribedPortfolioResponse>) => {
        state.editSubscribedPortfolio = state.editSubscribedPortfolio || ({} as any);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        Object.assign(state.editSubscribedPortfolio!, {
          ...action.payload.data,
          status: { id: action.payload.data.status },
          currency: { id: action.payload.data.currency },
        });
        state.saveStatus = fetchStatuses.success;
      },
    );
    builder.addCase(saveSubscribedPortfolio.rejected, (state, action) => {
      state.saveStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateSubscribedPortfolio.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
    });
    builder.addCase(updateSubscribedPortfolio.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateSubscribedPortfolio.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteSubscribedPortfolio.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
    });
    builder.addCase(deleteSubscribedPortfolio.fulfilled, (state) => {
      state.editSubscribedPortfolio = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteSubscribedPortfolio.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditSubscribedPortfolio.type, (state) => {
      state.editSubscribedPortfolio = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.saveStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanSubscribedPortfolios.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const subscribedPortfolioReducer = portfoliosSlice.reducer;
