import { createSelector } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { ReducerState } from '../../store.types';
import { FeeBatchState } from './feeBatch.types';

export const feeBatchSelector = ({ feeBatch }: ReducerState) => feeBatch;
export const viewDetailsByBatchIdSelector = ({ feeBatch }: ReducerState) => feeBatch.viewDetailsByBatchId;
export const feeBatchLoadingSelector = createSelector(
  feeBatchSelector,
  ({ fetchStatus }) => fetchStatus === fetchStatuses.pending,
);
export const feeBatchByRequestIdSelector = createSelector(
  feeBatchSelector,
  (state: ReducerState, requestId: string) => requestId,
  (feeBatchState: FeeBatchState, requestId: string) => ({
    feeBatches: feeBatchState.feeBatches[requestId] || [],
    totalfeeBatchs: feeBatchState.totalFeeBatches[requestId] || 0,
    fetchStatus: feeBatchState.fetchStatuses[requestId],
  }),
);
export const batchAttachmentsLoadingSelector = createSelector(
  feeBatchSelector,
  ({ fetchBatchAttachmentsStatus }) => fetchBatchAttachmentsStatus === fetchStatuses.pending,
);
export const batchAttachmentsSelector = createSelector(feeBatchSelector, ({ batchAttachments }) => batchAttachments);
