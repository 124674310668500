import Grid from '@mui/material/Grid';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import Alert from '@mui/material/Alert';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { userCategoryFormFields } from '../../forms/fields/formFields';
import { userCategoryFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import { createUserCategory, updateUserCategory } from '../../redux/modules/userCategory/userCategory.actions';
import { userCategorySelector } from '../../redux/modules/userCategory/userCategory.selectors';
import { CreateUserCategoryRequest, UpdateUserCategoryRequest, UserCategory } from '../../types/userCategory';

interface UserCategoryDialogProps {
  open: boolean;
  initialValue: UserCategory | null;
  onClose: (isReload: boolean) => void;
}

export function UserCategoryDialog({ open, initialValue, onClose }: UserCategoryDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, values, handleChange, touched, errors, submitForm, setValues, resetForm } = useFormik({
    initialValues: {
      [userCategoryFormFields.nameEn]: '',
      [userCategoryFormFields.nameAr]: '',
      [userCategoryFormFields.descriptionEn]: '',
      [userCategoryFormFields.descriptionAr]: '',
      [userCategoryFormFields.minScore]: 0,
      [userCategoryFormFields.maxScore]: 0,
    },
    validationSchema: userCategoryFormSchema,
    onSubmit: (formValues) => {
      formValues = userCategoryFormSchema.cast(formValues) as UpdateUserCategoryRequest | CreateUserCategoryRequest;

      if (initialValue?.id) {
        dispatch(updateUserCategory({ id: initialValue.id, ...formValues }));
      } else {
        dispatch(createUserCategory(formValues));
      }
    },
  });
  const { createStatus, updateStatus, error } = useSelector(userCategorySelector);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    setValues({
      nameEn: initialValue?.nameEn || '',
      nameAr: initialValue?.nameAr || '',
      descriptionAr: initialValue?.descriptionAr || '',
      descriptionEn: initialValue?.descriptionEn || '',
      minScore: initialValue?.minScore || 0,
      maxScore: initialValue?.maxScore || 0,
    });
  }, [initialValue]);

  useEffect(() => {
    if (createStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('User Category created!', { variant: 'success' });
    }
    if (createStatus === fetchStatuses.rejected) {
      enqueueSnackbar('User Category creation error!', { variant: 'error' });
    }

    if (updateStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('User Category updated!', { variant: 'success' });
    }
  }, [createStatus, updateStatus]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{initialValue?.id ? 'Edit User Category' : 'Create a new User Category'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error && <Alert severity="error">{error.message}</Alert>}
          Enter the userCategory name
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="userCategoryNameEn"
            name={userCategoryFormFields.nameEn}
            label="User Category Name En"
            type="userCategoryNameEn"
            value={values[userCategoryFormFields.nameEn]}
            onChange={handleChange}
            error={touched.nameEn && !!errors.nameEn}
            helperText={touched.nameEn && errors.nameEn}
            inputProps={{ style: { textTransform: 'capitalize' } }}
            autoComplete="user-category-name-en"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            dir="rtl"
            id="userCategoryNameAr"
            name={userCategoryFormFields.nameAr}
            label="User Category Name Ar"
            type="userCategoryNameAr"
            value={values[userCategoryFormFields.nameAr]}
            onChange={handleChange}
            error={touched.nameAr && !!errors.nameAr}
            helperText={touched.nameAr && errors.nameAr}
            inputProps={{ style: { textTransform: 'capitalize' } }}
            autoComplete="user-category-name-ar"
          />

          <Grid container display="flex" spacing={2} justifyContent="space-around" alignItems="center">
            <Grid item xs={12} sm={5} sx={{ width: '100%' }}>
              <TextField
                margin="normal"
                fullWidth
                type="number"
                InputProps={{ inputProps: { min: 0, max: 100 } }}
                id={userCategoryFormFields.minScore}
                name={userCategoryFormFields.minScore}
                label="Min Score"
                value={values[userCategoryFormFields.minScore]}
                onChange={handleChange}
                error={touched.minScore && !!errors.minScore}
                helperText={touched.minScore && errors.minScore}
                autoComplete="user-category-min-score"
              />
            </Grid>

            <Grid item xs={12} sm={5} sx={{ width: '100%' }}>
              <TextField
                margin="normal"
                fullWidth
                type="number"
                InputProps={{ inputProps: { min: values[userCategoryFormFields.minScore] || 0, max: 100 } }}
                id={userCategoryFormFields.maxScore}
                name={userCategoryFormFields.maxScore}
                label="Max Score"
                value={values[userCategoryFormFields.maxScore]}
                onChange={handleChange}
                error={touched.maxScore && !!errors.maxScore}
                helperText={touched.maxScore && errors.maxScore}
                autoComplete="user-category-max-score"
              />
            </Grid>
          </Grid>

          <TextField
            margin="normal"
            multiline
            minRows={6}
            fullWidth
            id="userCategoryDescriptionEn"
            name={userCategoryFormFields.descriptionEn}
            label="User Category Description En"
            type="userCategoryDescriptionEn"
            value={values[userCategoryFormFields.descriptionEn]}
            onChange={handleChange}
            error={touched.descriptionEn && !!errors.descriptionEn}
            helperText={touched.descriptionEn && errors.descriptionEn}
            autoComplete="user-category-description-en"
          />
          <TextField
            margin="normal"
            multiline
            minRows={6}
            fullWidth
            dir="rtl"
            id="userCategoryDescriptionAr"
            name={userCategoryFormFields.descriptionAr}
            label="User Category Description Ar"
            type="userCategoryDescriptionAr"
            value={values[userCategoryFormFields.descriptionAr]}
            onChange={handleChange}
            error={touched.descriptionAr && !!errors.descriptionAr}
            helperText={touched.descriptionAr && errors.descriptionAr}
            autoComplete="user-category-description-ar"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={createStatus === fetchStatuses.pending}
          loadingPosition="end"
          onClick={submitForm}
        >
          {initialValue?.id ? 'Save' : 'Create'}
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
