import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
import React, { useRef, useState } from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { FakeButtonLink } from '../../../../components/FakeLink';
import { RtkQueryTable, RtkQueryTableModels } from '../../../../components/Table/RtkQueryTable';
import { TableRef } from '../../../../components/Table/Table';
import { converTableModelsToApiArgs } from '../../../../helpers/api';
import { useFetchOrdersQuery } from '../../../../redux/features/alpaca/alpaca.apiSlice';
import { locations } from '../../../../routes/locations';
import { AlpacaOrderSide, AlpacaOrderStatus, AlpacaOrderStep } from '../../../../types/alpaca';

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
  },
  {
    field: 'fundOrderId',
    headerName: 'Fund Order Id',
  },
  {
    field: 'orderId',
    headerName: 'Order Id',
  },
  {
    field: 'alpacaOrderId',
    headerName: 'Alpaca Order Id',
  },
  {
    field: 'side',
    headerName: 'Side',
    type: 'singleSelect',
    valueOptions: Object.values(AlpacaOrderSide),
  },
  {
    field: 'step',
    headerName: 'Step',
    type: 'singleSelect',
    valueOptions: Object.values(AlpacaOrderStep),
  },
  {
    field: 'status.name',
    headerName: 'Status',
    type: 'singleSelect',
    valueOptions: Object.values(AlpacaOrderStatus),
    valueGetter: ({ row }) => row?.status?.name,
  },
  {
    field: 'user',
    headerName: 'User',
    width: 100,
    renderCell: (params: GridRenderCellParams) => {
      if (!params.value) {
        return;
      }

      return (
        <Tooltip title={params.value?.id} followCursor>
          <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.user(params.value?.id)}>
            {params.value?.id}
          </FakeButtonLink>
        </Tooltip>
      );
    },
  },
  {
    field: 'alpacaActualAmount',
    headerName: 'Alpaca Actual Amount',
  },
  {
    field: 'alpacaActualUnits',
    headerName: 'Alpaca Actual Units',
  },
  {
    field: 'alpacaActualPrice',
    headerName: 'Alpaca Actual Price',
  },
  {
    field: 'alpacaActualPrice',
    headerName: 'Alpaca Actual Price',
  },
  {
    field: 'tradeConfirmationReportCreated',
    headerName: 'Trade Confirmation Report Created',
    valueFormatter: ({ value }) => (value ? 'YES' : 'NO'),
  },
  {
    field: 'accountStatementReportCreated',
    headerName: 'Account Statement Report Created',
    valueFormatter: ({ value }) => (value ? 'YES' : 'NO'),
  },
  {
    field: 'createdAt',
    headerName: 'Created',
    type: 'date',
    width: 100,
  },
  {
    field: 'updatedAt',
    headerName: 'Updated',
    type: 'date',
    width: 100,
  },
];

interface AlpacaOrderListProps {
  alpacaTransferId: number;
}

export function AlpacaOrderList({ alpacaTransferId }: AlpacaOrderListProps) {
  const tableRef = useRef<TableRef>(null);

  const transfersIdFilter = {
    field: 'alpacaTransfers.id',
    operator: 'is',
    value: alpacaTransferId,
    id: 0,
  };

  const transferIdFilter = {
    field: 'alpacaTransfer.id',
    operator: 'is',
    value: alpacaTransferId,
    id: 0,
  };

  const [tableModels, setTableModels] = useState<RtkQueryTableModels>({
    paginationModel: { page: 0, pageSize: 100 },
    filterModel: { items: [transfersIdFilter, transferIdFilter] },
  });

  const { data: orders, isFetching } = useFetchOrdersQuery(converTableModelsToApiArgs(tableModels));

  const onModelsChange = (models: RtkQueryTableModels) => {
    setTableModels(models);
  };

  return (
    <RtkQueryTable
      ref={tableRef}
      wrapperHeight={350}
      models={tableModels}
      onModelsChange={onModelsChange}
      rows={orders?.items || []}
      columns={columns}
      loading={isFetching}
      density="compact"
      requiredFilters={[transfersIdFilter, transferIdFilter]}
      rowCount={orders?.totalItems || 0}
    />
  );
}
