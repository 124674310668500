import { PaginatedRequest } from '../types/api';
import {
  CreateEmployeeRequest,
  CreateEmployeeResponse,
  FetchEmployeesResponse,
  FetchEmployeeResponse,
  UpdateEmployeeRequest,
  UpdateEmployeeResponse,
  UpdateEmployeeStatusRequest,
  UpdateEmployeeStatusResponse,
  ResetEmployeePasswordResponse,
} from '../types/employee';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class EmployeeService extends ApiService {
  pathname = 'admin';

  fetchEmployees = async (data: PaginatedRequest) =>
    this.get<FetchEmployeesResponse>(`${this.pathname}/employee?${toQueryParams(data)}`);

  fetchEmployee = async (id: number) => this.get<FetchEmployeeResponse>(`${this.pathname}/employee/${id}`);

  deleteEmployee = async (id: number) => this.delete<FetchEmployeeResponse>(`${this.pathname}/employee/${id}`);

  createEmployee = async (employee: CreateEmployeeRequest) =>
    this.post<CreateEmployeeResponse, CreateEmployeeRequest>(`${this.pathname}/employee`, employee);

  updateEmployee = async ({ id, ...employee }: UpdateEmployeeRequest) =>
    this.put<UpdateEmployeeResponse, Omit<UpdateEmployeeRequest, 'id'>>(`${this.pathname}/employee/${id}`, employee);

  updateEmployeeStatus = async ({ id, status }: UpdateEmployeeStatusRequest) =>
    this.put<UpdateEmployeeStatusResponse, Omit<UpdateEmployeeStatusRequest, 'id'>>(
      `${this.pathname}/employee/${id}/status`,
      { status },
    );

  resetEmployeePassword = async (id: number) =>
    this.put<ResetEmployeePasswordResponse, object>(`${this.pathname}/employee/${id}/reset-password`, {});
}

export const employeeService = new EmployeeService();
