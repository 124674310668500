import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { userCategoryService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { CreateUserCategoryRequest, UpdateUserCategoryRequest } from '../../../types/userCategory';

export const fetchUserCategorys = createAsyncThunk('user-category/fetch-all', (data: PaginatedRequest) =>
  userCategoryService.fetchUserCategorys(data),
);

export const fetchUserCategory = createAsyncThunk('user-category/get', (id: number) =>
  userCategoryService.fetchUserCategory(id),
);

export const deleteUserCategory = createAsyncThunk('user-category/delete', (id: number) =>
  userCategoryService.deleteUserCategory(id),
);

export const createUserCategory = createAsyncThunk('user-category/create', (userCategory: CreateUserCategoryRequest) =>
  userCategoryService.createUserCategory(userCategory),
);

export const updateUserCategory = createAsyncThunk('user-category/update', (userCategory: UpdateUserCategoryRequest) =>
  userCategoryService.updateUserCategory(userCategory),
);

export const resetEditUserCategory = createAction('user-category/reset-edit');

export const cleanUserCategorys = createAction('user-category/clean');
