import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchUserBankDetailsHistoryResponse,
  FetchUserBankResponse,
  FetchUserBanksResponse,
  FetchUserBankTransactionsLogsResponse,
  FetchUserBankTransactionsResponse,
} from '../../../types/userBank';
import {
  cleanUserBanks,
  deleteUserBank,
  fetchUserBank,
  fetchUserBankDetailsHistory,
  fetchUserBanks,
  fetchUserBanksByUser,
  fetchUserBankTransactions,
  fetchUserBankTransactionsLogs,
  resetEditUserBank,
  saveUserBank,
  updateUserBank,
  addFunds,
} from './userBank.actions';
import { UserBankState } from './userBank.types';

const initialState: UserBankState = {
  userBanks: [],
  userBankTransactions: [],
  userBankTransactionsLogs: [],
  totalUserBanks: 0,
  totalUserBankTransactions: 0,
  totalUserBankTransactionsLogs: 0,
  editUserBank: null,
  fetchStatus: fetchStatuses.idle,
  fetchUserBankTransactionsStatus: fetchStatuses.idle,
  fetchUserBankTransactionsLogsStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  addFundsStatus: fetchStatuses.idle,
  saveStatus: fetchStatuses.idle,

  userBankDetailsHistory: [],
  totalUserBankDetailsHistory: 0,
  fetchUserBankDetailsHistoryStatus: fetchStatuses.idle,

  error: null,
};

const userBanksSlice = createSlice({
  name: 'userBanks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserBanks.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUserBanks.fulfilled, (state, action: PayloadAction<FetchUserBanksResponse>) => {
      state.userBanks = action.payload.data?.items;
      state.totalUserBanks = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserBanks.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserBankDetailsHistory.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserBankDetailsHistory.fulfilled,
      (state, action: PayloadAction<FetchUserBankDetailsHistoryResponse>) => {
        state.userBankDetailsHistory = action.payload.data?.items;
        state.totalUserBankDetailsHistory = action.payload.data?.totalItems;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserBankDetailsHistory.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserBankTransactions.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserBankTransactions.fulfilled,
      (state, action: PayloadAction<FetchUserBankTransactionsResponse>) => {
        state.userBankTransactions = action.payload.data?.items;
        state.totalUserBankTransactions = action.payload.data?.totalItems;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserBankTransactions.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserBankTransactionsLogs.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserBankTransactionsLogs.fulfilled,
      (state, action: PayloadAction<FetchUserBankTransactionsLogsResponse>) => {
        state.userBankTransactionsLogs = action.payload.data?.items;
        state.totalUserBankTransactionsLogs = action.payload.data?.totalItems;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserBankTransactionsLogs.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserBanksByUser.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUserBanksByUser.fulfilled, (state, action: PayloadAction<FetchUserBanksResponse>) => {
      state.userBanks = action.payload.data?.items;
      state.totalUserBanks = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserBanksByUser.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserBank.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUserBank.fulfilled, (state, action: PayloadAction<FetchUserBankResponse>) => {
      state.editUserBank = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserBank.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(saveUserBank.pending, (state) => {
      state.error = null;
      state.saveStatus = fetchStatuses.pending;
    });
    builder.addCase(saveUserBank.fulfilled, (state, action: PayloadAction<FetchUserBankResponse>) => {
      state.editUserBank = state.editUserBank || ({} as any);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Object.assign(state.editUserBank!, {
        ...action.payload.data,
        bankType: { id: action.payload.data.bankType },
        user: { id: action.payload.data.user },
        status: { id: action.payload.data.status },
        userBankDetails: { id: action.payload.data.userBankDetails },
      });
      state.saveStatus = fetchStatuses.success;
    });
    builder.addCase(saveUserBank.rejected, (state, action) => {
      state.saveStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateUserBank.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
    });
    builder.addCase(updateUserBank.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateUserBank.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(addFunds.pending, (state) => {
      state.error = null;
      state.addFundsStatus = fetchStatuses.pending;
    });
    builder.addCase(addFunds.fulfilled, (state) => {
      state.addFundsStatus = fetchStatuses.success;
    });
    builder.addCase(addFunds.rejected, (state, action) => {
      state.addFundsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteUserBank.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
    });
    builder.addCase(deleteUserBank.fulfilled, (state) => {
      state.editUserBank = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteUserBank.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditUserBank.type, (state) => {
      state.editUserBank = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.saveStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanUserBanks.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const userBankReducer = userBanksSlice.reducer;
