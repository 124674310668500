import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { DownwardIcon, NoopIcon, UpwardIcon } from '../../components/Icon';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { fetchSatelliteAccountDetailsHistory } from '../../redux/modules/satelliteAccount/satelliteAccount.actions';
import { satelliteAccountSelector } from '../../redux/modules/satelliteAccount/satelliteAccount.selectors';
import { round2Format } from '../../utils/number';
import { GridCellExpand, Table } from '../../components/Table';

interface SatelliteAccountDetailsHistoryTableProps {
  satelliteAccountDetailsId: number;
}

export function SatelliteAccountDetailsHistoryTable({
  satelliteAccountDetailsId,
}: SatelliteAccountDetailsHistoryTableProps) {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id', filterable: false },
    { field: 'satelliteAccountDetailsId', headerName: 'Satellite Account Details Id', type: 'number' },
    {
      field: 'prevBalance',
      headerName: 'Prev Balance',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'prevHold',
      headerName: 'Prev Hold',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'balance',
      headerName: 'Balance',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => {
        let Icon = NoopIcon;

        if (params.value > 0) {
          Icon = UpwardIcon;
        }

        if (params.value < 0) {
          Icon = DownwardIcon;
        }

        return (
          <>
            <Icon />
            {round2Format(params.value)}
          </>
        );
      },
    },
    {
      field: 'hold',
      headerName: 'Hold',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => {
        let Icon = NoopIcon;

        if (params.value > 0) {
          Icon = UpwardIcon;
        }

        if (params.value < 0) {
          Icon = DownwardIcon;
        }

        return (
          <>
            <Icon />
            {round2Format(params.value)}
          </>
        );
      },
    },
    {
      field: 'newBalance',
      headerName: 'New Balance',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'newHold',
      headerName: 'New Hold',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'metadata',
      headerName: 'Metadata',
      sortable: false,
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <GridCellExpand value={JSON.stringify(params.value, null, 2) || ''} width={params.colDef.computedWidth} />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'dateTime',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const {
    satelliteAccountDetailsHistory,
    totalSatelliteAccountDetailsHistory,
    fetchSatelliteAccountDetailsHistoryStatus,
  } = useSelector(satelliteAccountSelector);

  return (
    <Table
      fetchItems={fetchSatelliteAccountDetailsHistory}
      rows={satelliteAccountDetailsHistory}
      columns={columns.current}
      loading={fetchSatelliteAccountDetailsHistoryStatus === fetchStatuses.pending}
      rowCount={totalSatelliteAccountDetailsHistory}
      disableRowGrouping
      density="compact"
      initialColumnVisibilityModel={{ id: false, satelliteAccountDetailsId: false, metadata: false }}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      requiredFilters={[
        { field: 'satelliteAccountDetailsId', value: `${satelliteAccountDetailsId}`, operator: 'is', id: 0 },
      ]}
      toolbarExportProps={{
        csvOptions: {
          fileName: `satellite-account-details-${satelliteAccountDetailsId}-history-${new Date().toLocaleString()}`,
        },
        excelOptions: {
          fileName: `satellite-account-details-${satelliteAccountDetailsId}-history-${new Date().toLocaleString()}`,
        },
      }}
      wrapperHeight={300}
    />
  );
}
