import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Table } from '../../../../components/Table';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { fetchUserBankTransactions } from '../../../../redux/modules/userBank/userBank.actions';
import { userBankSelector } from '../../../../redux/modules/userBank/userBank.selectors';
import { round2Format } from '../../../../utils/number';

interface TransactionsTableProps {
  userBankId: number;
}

export function TransactionsTable({ userBankId }: TransactionsTableProps) {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id' },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 150,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'fees',
      headerName: 'Fees',
      width: 150,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'hold',
      headerName: 'Hold',
      width: 150,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'registerdPaymentId',
      headerName: 'Registered Payment Id',
      width: 200,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'boolean',
      width: 150,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 150,
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: false,
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      sortable: false,
      type: 'date',
      width: 100,
    },
  ]);
  const { totalUserBankTransactions, userBankTransactions, fetchUserBankTransactionsStatus } =
    useSelector(userBankSelector);

  return (
    <Table
      fetchItems={fetchUserBankTransactions}
      fetchItemsCustomParams={{ userBankId }}
      rows={userBankTransactions}
      columns={columns.current}
      loading={fetchUserBankTransactionsStatus === fetchStatuses.pending}
      rowCount={totalUserBankTransactions}
      disableColumnFilter
      disableColumnMenu
      density="compact"
      initialColumnVisibilityModel={{ id: false }}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      requiredFilters={[{ field: 'type', operator: '=', value: 'VIRTUAL' }]}
      initialFilterModel={{ items: [{ field: 'type', operator: '=', value: 'VIRTUAL' }] }}
      wrapperHeight={300}
      toolbarExportProps={{
        csvOptions: { fileName: `user-bank-${userBankId}-transactions-${Date.now()}` },
        excelOptions: { fileName: `user-bank-${userBankId}-transactions-${Date.now()}` },
      }}
    />
  );
}
