import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchCurrencyResponse, FetchCurrencysResponse } from '../../../types/currency';
import {
  fetchCurrency,
  fetchCurrencys,
  resetEditCurrency,
  cleanCurrencys,
  updateCurrency,
  createCurrency,
  deleteCurrency,
} from './currency.actions';
import { CurrencyState } from './currency.types';

const initialState: CurrencyState = {
  currencys: [],
  totalCurrencys: 0,
  editCurrency: null,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const currencysSlice = createSlice({
  name: 'currencys',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCurrencys.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchCurrencys.fulfilled, (state, action: PayloadAction<FetchCurrencysResponse>) => {
      state.currencys = action.payload.data?.items;
      state.totalCurrencys = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchCurrencys.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchCurrency.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchCurrency.fulfilled, (state, action: PayloadAction<FetchCurrencyResponse>) => {
      state.editCurrency = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchCurrency.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateCurrency.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateCurrency.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateCurrency.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createCurrency.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(createCurrency.fulfilled, (state, action: PayloadAction<FetchCurrencyResponse>) => {
      state.editCurrency = action.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createCurrency.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteCurrency.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteCurrency.fulfilled, (state) => {
      state.editCurrency = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteCurrency.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditCurrency.type, (state) => {
      state.editCurrency = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanCurrencys.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const currencyReducer = currencysSlice.reducer;
