import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchFeeBatchResponse,
  FetchFeeBatchesResponse,
  FetchFeeBatchStatusOptionsResponse,
  FetchBatchAttachmentsTypeOptionsResponse,
  FetchBatchAttachmentsResponse,
  FetchFeeBatchExecutionsResponse,
  CreateFeeBatchResponse,
} from '../../../types/feeBatch';
import { FetchFeeCalculationsResponse } from '../../../types/feeCalculation';
import { FetchUserFeeValidationsResponse } from '../../../types/userFeeValidation';
import { FeeBatchValidation, FetchFeeBatchValidationResponse } from '../../../types/feeBatchValidation';
// import { FetchFeeBatchExecutionReportResponse } from '../../../types/report';
import { FetchUserFeeDeductionsResponse } from '../../../types/userFeeDeduction';
import {
  fetchFeeBatch,
  createBatchAttachment,
  fetchFeeBatches,
  resetEditFeeBatch,
  saveFeeBatch,
  deleteFeeBatch,
  cleanFeeBatches,
  updateFeeBatchStatus,
  fetchFeeBatchStatusOptions,
  createFeeBatch,
  fetchFeeBatchesByBatchId,
  fetchUserFeeDeductionsByBatchId,
  cleanViewDetailsByBatchId,
  fetchFeeCalculationsByBatchId,
  fetchUserFeeValidationsByBatchId,
  fetchFeeBatchValidationByBatchId,
  executeFeeBatch,
  importExecutionFileFeeBatch,
  consolidateFeeCalculations,
  importConsolidateExecutionFileFeeBatch,
  fetchBatchAttachmentsTypeOptions,
  fetchBatchAttachments,
  fetchFeeBatchExecutionsByBatchId,
  // fetchBatchExecutionsReportByBatchId,
  discardBatchExecutionsByBatchId,
  changeBatchExecutionsToReconciliationByBatchId,
  approveBatchExecutionsByBatchId,
  reconcileBatchExecutionsByBatchId,
  uploadBatchAttachment,
  fetchFeeBatchesByExecutionId,
  cancelExecutionFeeBatch,
} from './feeBatch.actions';
import { FeeBatchState } from './feeBatch.types';

const initialState: FeeBatchState = {
  feeBatches: {},
  totalFeeBatches: {},
  editFeeBatch: null,
  viewDetailsByBatchId: {
    feeBatches: [],
    totalFeeBatches: 0,
    userFeeDeductions: [],
    totalUserFeeDeductions: 0,
    feeCalculations: [],
    totalFeeCalculations: 0,
    userFeeValidations: [],
    totalUserFeeValidations: 0,
    feeBatchValidation: null,
    consolidateFeeRequests: [],
    totalConsolidateFeeRequests: 0,
    fetchFeeCalculationsStatus: fetchStatuses.idle,
    fetchUserFeeValidationsStatus: fetchStatuses.idle,
    fetchFeeBatchValidationStatus: fetchStatuses.idle,
    fetchFeeBatchesStatus: fetchStatuses.idle,
    fetchUserFeeDeductionsStatus: fetchStatuses.idle,
    fetchConsolidateFeeRequestsStatus: fetchStatuses.idle,
  },
  feeBatchStatusOptions: [],
  fetchFeeBatchStatusOptionsStatus: fetchStatuses.idle,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatusStatus: fetchStatuses.idle,
  saveStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  executeStatus: fetchStatuses.idle,
  consolidateStatus: fetchStatuses.idle,
  cancelExecutionStatus: fetchStatuses.idle,
  importExecutionFileStatus: fetchStatuses.idle,
  importConsolidateExecutionFileStatus: fetchStatuses.idle,

  batchAttachments: [],
  totalBatchAttachments: 0,
  fetchBatchAttachmentsStatus: fetchStatuses.idle,

  batchAttachmentsTypeOptions: [],
  fetchBatchAttachmentsTypeOptionsStatus: fetchStatuses.idle,
  createBatchAttachmentStatus: fetchStatuses.idle,
  uploadBatchAttachmentStatus: fetchStatuses.idle,

  feeBatchExecutions: [],
  fetchFeeBatchExecutionsStatus: fetchStatuses.idle,

  discardBatchExecutionsStatus: fetchStatuses.idle,

  changeBatchExecutionsToReconciliationStatus: fetchStatuses.idle,

  reconcileBatchExecutionsStatus: fetchStatuses.idle,

  approveBatchExecutionsStatus: fetchStatuses.idle,

  fetchStatuses: {},

  error: null,
};

const FeeBatchesSlice = createSlice({
  name: 'FeeBatches',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFeeBatches.pending, (state, action) => {
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(fetchFeeBatches.fulfilled, (state, action: PayloadAction<FetchFeeBatchesResponse>) => {
      const requestId = (action as any).meta.arg.requestId || (action as any).meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.success;

      if (action.payload) {
        state.feeBatches[requestId] = action.payload.data?.items;
        state.totalFeeBatches[requestId] = action.payload.data?.totalItems;
      }
    });
    builder.addCase(fetchFeeBatches.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.rejected;

      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchFeeBatchesByExecutionId.pending, (state, action) => {
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(fetchFeeBatchesByExecutionId.fulfilled, (state, action: PayloadAction<FetchFeeBatchesResponse>) => {
      const requestId = (action as any).meta.arg.requestId || (action as any).meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.success;

      if (action.payload) {
        state.feeBatches[requestId] = action.payload.data?.items;
        state.totalFeeBatches[requestId] = action.payload.data?.totalItems;
      }
    });
    builder.addCase(fetchFeeBatchesByExecutionId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.rejected;

      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchBatchAttachments.pending, (state) => {
      state.error = null;
      state.fetchBatchAttachmentsStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchBatchAttachments.fulfilled, (state, action: PayloadAction<FetchBatchAttachmentsResponse>) => {
      state.batchAttachments = action.payload.data?.items;
      state.totalBatchAttachments = action.payload.data?.totalItems;
      state.fetchBatchAttachmentsStatus = fetchStatuses.success;
    });
    builder.addCase(fetchBatchAttachments.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.fetchBatchAttachmentsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchBatchAttachmentsTypeOptions.pending, (state) => {
      state.error = null;
      state.fetchBatchAttachmentsTypeOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchBatchAttachmentsTypeOptions.fulfilled,
      (state, action: PayloadAction<FetchBatchAttachmentsTypeOptionsResponse>) => {
        state.batchAttachmentsTypeOptions = action.payload.data;
        state.fetchBatchAttachmentsTypeOptionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchBatchAttachmentsTypeOptions.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.fetchBatchAttachmentsTypeOptionsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchFeeBatchesByBatchId.pending, (state) => {
      state.error = null;
      state.viewDetailsByBatchId.fetchFeeBatchesStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchFeeBatchesByBatchId.fulfilled, (state, action: PayloadAction<FetchFeeBatchesResponse>) => {
      state.viewDetailsByBatchId.feeBatches = action.payload.data?.items;
      state.viewDetailsByBatchId.totalFeeBatches = action.payload.data?.totalItems;
      state.viewDetailsByBatchId.fetchFeeBatchesStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFeeBatchesByBatchId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.viewDetailsByBatchId.fetchFeeBatchesStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchFeeBatchExecutionsByBatchId.pending, (state) => {
      state.error = null;
      state.fetchFeeBatchExecutionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchFeeBatchExecutionsByBatchId.fulfilled,
      (state, action: PayloadAction<FetchFeeBatchExecutionsResponse>) => {
        state.feeBatchExecutions = action.payload.data;
        // state.FeeBatchExecutions = [];
        state.fetchFeeBatchExecutionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFeeBatchExecutionsByBatchId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.fetchFeeBatchExecutionsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(discardBatchExecutionsByBatchId.pending, (state) => {
      state.error = null;
      state.discardBatchExecutionsStatus = fetchStatuses.pending;
    });
    builder.addCase(discardBatchExecutionsByBatchId.fulfilled, (state) => {
      state.discardBatchExecutionsStatus = fetchStatuses.success;
    });
    builder.addCase(discardBatchExecutionsByBatchId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.discardBatchExecutionsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(reconcileBatchExecutionsByBatchId.pending, (state) => {
      state.error = null;
      state.reconcileBatchExecutionsStatus = fetchStatuses.pending;
    });
    builder.addCase(reconcileBatchExecutionsByBatchId.fulfilled, (state) => {
      state.reconcileBatchExecutionsStatus = fetchStatuses.success;
    });
    builder.addCase(reconcileBatchExecutionsByBatchId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.reconcileBatchExecutionsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(approveBatchExecutionsByBatchId.pending, (state) => {
      state.error = null;
      state.approveBatchExecutionsStatus = fetchStatuses.pending;
    });
    builder.addCase(approveBatchExecutionsByBatchId.fulfilled, (state) => {
      state.approveBatchExecutionsStatus = fetchStatuses.success;
    });
    builder.addCase(approveBatchExecutionsByBatchId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.approveBatchExecutionsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(changeBatchExecutionsToReconciliationByBatchId.pending, (state) => {
      state.error = null;
      state.changeBatchExecutionsToReconciliationStatus = fetchStatuses.pending;
    });
    builder.addCase(changeBatchExecutionsToReconciliationByBatchId.fulfilled, (state) => {
      state.changeBatchExecutionsToReconciliationStatus = fetchStatuses.success;
    });
    builder.addCase(changeBatchExecutionsToReconciliationByBatchId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.changeBatchExecutionsToReconciliationStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchUserFeeDeductionsByBatchId.pending, (state) => {
      state.error = null;
      state.viewDetailsByBatchId.fetchUserFeeDeductionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserFeeDeductionsByBatchId.fulfilled,
      (state, action: PayloadAction<FetchUserFeeDeductionsResponse>) => {
        state.viewDetailsByBatchId.userFeeDeductions = action.payload.data?.items;
        state.viewDetailsByBatchId.totalUserFeeDeductions = action.payload.data?.totalItems;
        state.viewDetailsByBatchId.fetchUserFeeDeductionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserFeeDeductionsByBatchId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.viewDetailsByBatchId.fetchUserFeeDeductionsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchFeeCalculationsByBatchId.pending, (state) => {
      state.error = null;
      state.viewDetailsByBatchId.fetchFeeCalculationsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchFeeCalculationsByBatchId.fulfilled,
      (state, action: PayloadAction<FetchFeeCalculationsResponse>) => {
        state.viewDetailsByBatchId.feeCalculations = action.payload.data?.items;
        state.viewDetailsByBatchId.totalFeeCalculations = action.payload.data?.totalItems;
        state.viewDetailsByBatchId.fetchFeeCalculationsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFeeCalculationsByBatchId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.viewDetailsByBatchId.fetchFeeCalculationsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchUserFeeValidationsByBatchId.pending, (state) => {
      state.error = null;
      state.viewDetailsByBatchId.fetchUserFeeValidationsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserFeeValidationsByBatchId.fulfilled,
      (state, action: PayloadAction<FetchUserFeeValidationsResponse>) => {
        state.viewDetailsByBatchId.userFeeValidations = action.payload.data?.items;
        state.viewDetailsByBatchId.totalUserFeeValidations = action.payload.data?.totalItems;
        state.viewDetailsByBatchId.fetchUserFeeValidationsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserFeeValidationsByBatchId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.viewDetailsByBatchId.fetchUserFeeValidationsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchFeeBatchValidationByBatchId.pending, (state) => {
      state.viewDetailsByBatchId.fetchFeeBatchValidationStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchFeeBatchValidationByBatchId.fulfilled,
      (state, action: PayloadAction<FetchFeeBatchValidationResponse>) => {
        state.viewDetailsByBatchId.feeBatchValidation = action.payload.data;
        state.viewDetailsByBatchId.fetchFeeBatchValidationStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFeeBatchValidationByBatchId.rejected, (state, action) => {
      state.viewDetailsByBatchId.fetchFeeBatchValidationStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFeeBatch.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchFeeBatch.fulfilled, (state, action: PayloadAction<FetchFeeBatchResponse>) => {
      state.editFeeBatch = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFeeBatch.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.fetchStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchFeeBatchStatusOptions.pending, (state) => {
      state.error = null;
      state.fetchFeeBatchStatusOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchFeeBatchStatusOptions.fulfilled,
      (state, action: PayloadAction<FetchFeeBatchStatusOptionsResponse>) => {
        state.feeBatchStatusOptions = action.payload.data;
        state.fetchFeeBatchStatusOptionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFeeBatchStatusOptions.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.fetchFeeBatchStatusOptionsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(updateFeeBatchStatus.pending, (state) => {
      state.error = null;
      state.updateStatusStatus = fetchStatuses.pending;
    });
    builder.addCase(updateFeeBatchStatus.fulfilled, (state) => {
      state.updateStatusStatus = fetchStatuses.success;
    });
    builder.addCase(updateFeeBatchStatus.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.updateStatusStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(saveFeeBatch.pending, (state) => {
      state.error = null;
      state.saveStatus = fetchStatuses.pending;
    });
    builder.addCase(saveFeeBatch.fulfilled, (state, action: PayloadAction<CreateFeeBatchResponse>) => {
      state.editFeeBatch = state.editFeeBatch || ({} as any);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      // Object.assign(state.editFeeBatch!, {
      //   ...action.payload.data,
      //   status: { id: action.payload.data.status },
      //   userPortfolioRequest: { id: action.payload.data.userPortfolioRequest },
      // });
      state.saveStatus = fetchStatuses.success;
    });
    builder.addCase(saveFeeBatch.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.saveStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(createFeeBatch.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
    });
    builder.addCase(createFeeBatch.fulfilled, (state) => {
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createFeeBatch.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.createStatus = fetchStatuses.rejected;
      state.fetchFeeBatchExecutionsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(executeFeeBatch.pending, (state) => {
      state.error = null;
      state.executeStatus = fetchStatuses.pending;
    });
    builder.addCase(executeFeeBatch.fulfilled, (state) => {
      state.executeStatus = fetchStatuses.success;
    });
    builder.addCase(executeFeeBatch.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.executeStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(cancelExecutionFeeBatch.pending, (state) => {
      state.error = null;
      state.cancelExecutionStatus = fetchStatuses.pending;
    });
    builder.addCase(cancelExecutionFeeBatch.fulfilled, (state) => {
      state.cancelExecutionStatus = fetchStatuses.success;
    });
    builder.addCase(cancelExecutionFeeBatch.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.cancelExecutionStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(consolidateFeeCalculations.pending, (state) => {
      state.error = null;
      state.consolidateStatus = fetchStatuses.pending;
    });
    builder.addCase(consolidateFeeCalculations.fulfilled, (state) => {
      state.consolidateStatus = fetchStatuses.success;
    });
    builder.addCase(consolidateFeeCalculations.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.consolidateStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(importExecutionFileFeeBatch.pending, (state) => {
      state.error = null;
      state.importExecutionFileStatus = fetchStatuses.pending;
    });
    builder.addCase(importExecutionFileFeeBatch.fulfilled, (state) => {
      state.importExecutionFileStatus = fetchStatuses.success;
    });
    builder.addCase(importExecutionFileFeeBatch.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.importExecutionFileStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(importConsolidateExecutionFileFeeBatch.pending, (state) => {
      state.error = null;
      state.importConsolidateExecutionFileStatus = fetchStatuses.pending;
    });
    builder.addCase(importConsolidateExecutionFileFeeBatch.fulfilled, (state) => {
      state.importConsolidateExecutionFileStatus = fetchStatuses.success;
    });
    builder.addCase(importConsolidateExecutionFileFeeBatch.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.importConsolidateExecutionFileStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(deleteFeeBatch.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
    });
    builder.addCase(deleteFeeBatch.fulfilled, (state) => {
      state.editFeeBatch = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteFeeBatch.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.deleteStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(createBatchAttachment.pending, (state) => {
      state.error = null;
      state.createBatchAttachmentStatus = fetchStatuses.idle;
    });
    builder.addCase(createBatchAttachment.fulfilled, (state) => {
      state.createBatchAttachmentStatus = fetchStatuses.success;
    });
    builder.addCase(createBatchAttachment.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.createBatchAttachmentStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(uploadBatchAttachment.pending, (state) => {
      state.error = null;
      state.uploadBatchAttachmentStatus = fetchStatuses.idle;
    });
    builder.addCase(uploadBatchAttachment.fulfilled, (state) => {
      state.uploadBatchAttachmentStatus = fetchStatuses.success;
    });
    builder.addCase(uploadBatchAttachment.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.uploadBatchAttachmentStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(resetEditFeeBatch.type, (state) => {
      state.editFeeBatch = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.saveStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanViewDetailsByBatchId.type, (state) => {
      state.viewDetailsByBatchId = initialState.viewDetailsByBatchId;
    });

    builder.addCase(cleanFeeBatches.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const feeBatchReducer = FeeBatchesSlice.reducer;
