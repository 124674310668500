import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CopyToClipboardButton } from '../../../../components/CopyToClipboardButton';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { useAppDispatch } from '../../../../redux/hooks';
import { fetchAnbPaymentDetails } from '../../../../redux/modules/payment/payment.actions';
import { anbPaymentDetailsSelector, paymentSelector } from '../../../../redux/modules/payment/payment.selectors';
import { ReducerState } from '../../../../redux/store.types';
import { IAlpacaTransfer } from '../../../../types/alpaca';
import { round2Format } from '../../../../utils/number';

interface AlpacaTransferProps {
  alpacaTransfer: IAlpacaTransfer;
}

export function AlpacaTransfer({ alpacaTransfer }: AlpacaTransferProps) {
  const { t } = useTranslation('apiError');
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { fetchAnbPaymentDetailsStatus, error } = useSelector(paymentSelector);
  const paymentDetails = useSelector((state: ReducerState) =>
    anbPaymentDetailsSelector(state, alpacaTransfer.anbTransferId),
  );

  const fetchAnbTransferDetails = async () => {
    if (alpacaTransfer.anbTransferId) {
      try {
        await dispatch(fetchAnbPaymentDetails(alpacaTransfer.anbTransferId)).unwrap();

        enqueueSnackbar('Anb Payment details found successfully!', { variant: 'success' });
      } catch (err: any) {
        enqueueSnackbar(`Fetch Anb Payment details failed: ${t(err?.message)} ${JSON.stringify(err?.data)}`, {
          variant: 'error',
        });
      }
    }
  };

  const renderPaymentDetails = () => {
    if (error) {
      return (
        <>
          <Divider variant="inset" />
          <Grid item container>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Payment Details</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <Alert severity="error">{error.message}</Alert>
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Divider variant="inset" />
        <Grid item container>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Payment Details</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" component="pre" style={{ padding: 8, overflow: 'auto' }}>
              {paymentDetails ? JSON.stringify(paymentDetails, null, 2) : 'Not found'}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: 6 }} spacing={2}>
      <Typography variant="h6">Transfer</Typography>
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Id</Typography>
        </Grid>
        <Grid item xs={6}>
          <CopyToClipboardButton text={alpacaTransfer.transferId} value={alpacaTransfer.transferId} />
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">NTA Id</Typography>
        </Grid>
        <Grid item xs={6}>
          <CopyToClipboardButton text={alpacaTransfer.ntaId} value={alpacaTransfer.ntaId} />
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Status</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{alpacaTransfer.status}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Fx Rate</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{alpacaTransfer.fxRate}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Real Fx Rate</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{alpacaTransfer.realFxRate}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Sar Amount</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{round2Format(alpacaTransfer.sarAmount)}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Actual Sar Amount</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{round2Format(alpacaTransfer.actualSarAmount)}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Usd Amount</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{round2Format(alpacaTransfer.usdAmount)}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Original Usd Amount</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{round2Format(alpacaTransfer.originalUsdAmount)}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Actual Usd Amount</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{round2Format(alpacaTransfer.actualUsdAmount)}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Direction</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{alpacaTransfer.direction}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Anb Transfer Id</Typography>
        </Grid>
        <Grid item xs={6}>
          <LoadingButton
            type="button"
            color="primary"
            size="small"
            variant="text"
            endIcon={<SavedSearchIcon />}
            disabled={!alpacaTransfer.anbTransferId}
            loading={fetchAnbPaymentDetailsStatus === fetchStatuses.pending}
            loadingPosition="end"
            onClick={fetchAnbTransferDetails}
          >
            {alpacaTransfer.anbTransferId}
          </LoadingButton>
        </Grid>
      </Grid>
      {renderPaymentDetails()}
    </Grid>
  );
}
