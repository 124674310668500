import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { alpacaConfigService } from '../../../api';
import { CreateAlpacaConfigRequest, UpdateAlpacaConfigRequest } from '../../../types/alpacaConfig';

export const fetchAlpacaConfigs = createAsyncThunk('alpacaConfig/fetch-all', () =>
  alpacaConfigService.fetchAlpacaConfigs(),
);

export const fetchAlpacaConfig = createAsyncThunk('alpacaConfig/get', (key: string) =>
  alpacaConfigService.fetchAlpacaConfig(key),
);

export const deleteAlpacaConfig = createAsyncThunk('alpacaConfig/delete', (key: string) =>
  alpacaConfigService.deleteAlpacaConfig(key),
);

export const createAlpacaConfigItem = createAsyncThunk(
  'alpacaConfig/create',
  (alpacaConfig: CreateAlpacaConfigRequest) => alpacaConfigService.createAlpacaConfig(alpacaConfig),
);

export const updateAlpacaConfig = createAsyncThunk('alpacaConfig/update', (alpacaConfig: UpdateAlpacaConfigRequest) =>
  alpacaConfigService.updateAlpacaConfig(alpacaConfig),
);

export const updateAlpacaConfigs = createAsyncThunk(
  'alpacaConfig/update-all',
  (alpacaConfigs: UpdateAlpacaConfigRequest[]) => alpacaConfigService.updateAlpacaConfigs(alpacaConfigs),
);

export const resetEditAlpacaConfig = createAction('alpacaConfig/reset-edit');

export const cleanAlpacaConfigs = createAction('alpacaConfig/clean');
