import {
  CreateAppConfigRequest,
  CreateAppConfigResponse,
  FetchAppConfigsResponse,
  FetchAppConfigResponse,
  UpdateAppConfigRequest,
  UpdateAppConfigResponse,
  UpdateAppConfigsRequest,
} from '../types/appConfig';
import { ApiService } from './common/ApiService';

class AppConfigService extends ApiService {
  pathname = 'admin/app-config';

  fetchAppConfigs = async () => this.get<FetchAppConfigsResponse>(`${this.pathname}`);

  fetchAppConfig = async (key: string) => this.get<FetchAppConfigResponse>(`${this.pathname}/${key}`);

  deleteAppConfig = async (key: string) => this.delete<FetchAppConfigResponse>(`${this.pathname}/${key}`);

  createAppConfig = async (action: CreateAppConfigRequest) =>
    this.post<CreateAppConfigResponse, CreateAppConfigRequest>(`${this.pathname}`, action);

  updateAppConfig = async ({ key, ...action }: UpdateAppConfigRequest) =>
    this.put<UpdateAppConfigResponse, Omit<UpdateAppConfigRequest, 'key'>>(`${this.pathname}/${key}`, action);

  updateAppConfigs = async (data: UpdateAppConfigRequest[]) =>
    this.put<FetchAppConfigsResponse, UpdateAppConfigsRequest>(`${this.pathname}`, { items: data });
}

export const appConfigService = new AppConfigService();
