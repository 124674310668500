import * as yup from 'yup';
import { fundGeographicalFocusFormFields } from '../../fields/formFields';

export const fundGeographicalFocusFormSchema = yup.object({
  [fundGeographicalFocusFormFields.nameEn]: yup
    .string()
    .max(150, 'Name En must be at most 150 characters')
    .required('Name En is required')
    .trim(),
  [fundGeographicalFocusFormFields.nameAr]: yup
    .string()
    .max(150, 'Name Ar must be at most 150 characters')
    .required('Name Ar is required')
    .trim(),
});
