import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { feesConfigService } from '../../../api';
import { CreateFeesConfigRequest, UpdateFeesConfigRequest } from '../../../types/feesConfig';

export const fetchFeesConfigs = createAsyncThunk('feesConfig/fetch-all', () => feesConfigService.fetchFeesConfigs());

export const fetchFeesConfig = createAsyncThunk('feesConfig/get', (key: string) =>
  feesConfigService.fetchFeesConfig(key),
);

export const deleteFeesConfig = createAsyncThunk('feesConfig/delete', (key: string) =>
  feesConfigService.deleteFeesConfig(key),
);

export const createFeesConfigItem = createAsyncThunk('feesConfig/create', (feesConfig: CreateFeesConfigRequest) =>
  feesConfigService.createFeesConfig(feesConfig),
);

export const updateFeesConfig = createAsyncThunk('feesConfig/update', (feesConfig: UpdateFeesConfigRequest) =>
  feesConfigService.updateFeesConfig(feesConfig),
);

export const updateFeesConfigs = createAsyncThunk('feesConfig/update-all', (feesConfigs: UpdateFeesConfigRequest[]) =>
  feesConfigService.updateFeesConfigs(feesConfigs),
);

export const resetEditFeesConfig = createAction('feesConfig/reset-edit');

export const cleanFeesConfigs = createAction('feesConfig/clean');
