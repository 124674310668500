import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { userBankService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { SaveUserBankRequest, AddFundsRequest, UpdateUserBankRequest } from '../../../types/userBank';

export const fetchUserBanks = createAsyncThunk('user-bank/fetch-all', (data: PaginatedRequest) =>
  userBankService.fetchUserBanks(data),
);

export const fetchUserBankDetailsHistory = createAsyncThunk(
  'user-bank/fetch-details-history',
  (data: PaginatedRequest) => userBankService.fetchUserBankDetailsHistory(data),
);

export const fetchUserBanksByUser = createAsyncThunk(
  'user-bank/fetch-all-by-user',
  (data: PaginatedRequest & { userId: number }) => userBankService.fetchUserBanksByUser(data),
);

export const fetchUserBankTransactions = createAsyncThunk(
  'user-bank/fetch-user-bank-transactions',
  (data: PaginatedRequest & { userBankId: number }) => userBankService.fetchUserBankTransactions(data),
);

export const fetchUserBankTransactionsLogs = createAsyncThunk(
  'user-bank/fetch-user-bank-transactions-logs',
  (data: PaginatedRequest & { userBankId: number }) => userBankService.fetchUserBankTransactionsLogs(data),
);

export const fetchUserBank = createAsyncThunk('user-bank/fetch-by-id', (id: number) =>
  userBankService.fetchUserBank(id),
);

export const deleteUserBank = createAsyncThunk('user-bank/delete', (id: number) => userBankService.deleteUserBank(id));

export const saveUserBank = createAsyncThunk('user-bank/save', (data: SaveUserBankRequest) =>
  userBankService.saveUserBank(data),
);

export const updateUserBank = createAsyncThunk('user-bank/update', (data: UpdateUserBankRequest) =>
  userBankService.updateUserBank(data),
);

export const addFunds = createAsyncThunk('user-bank/add-funds', (data: AddFundsRequest) =>
  userBankService.addFunds(data),
);

export const cleanUserBanks = createAction('user-bank/clean');

export const resetEditUserBank = createAction('user-bank/reset-edit');
