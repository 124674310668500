import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppDispatch } from '../../redux/hooks';
import { getPrivateFile, getPublicFile, uploadPrivate, uploadPublic } from '../../redux/modules/media/media.actions';
import { mediaCacheSelector } from '../../redux/modules/media/media.selectors';
import { FetchMediaFileResponse, UploadEntity, UploadMediaFileResponse } from '../../types/media';
import { isDispatchApiSuccess } from '../../utils/api';

interface UploadFileButtonProps {
  isPrivate?: boolean;
  uploadEntity?: UploadEntity;
  accept?: string;
  multiple?: boolean;
  sizeLimit?: number;
  selectedFile?: string;
  onChange?: (files: string[]) => void;
  onError?: (error: string) => void;
}

const mediaCacheTTL = 5 * 60 * 1000; // 5 mins

export const UploadFileButton = (props: UploadFileButtonProps) => {
  const {
    accept = '*',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onChange = () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onError = () => {},
    selectedFile,
    uploadEntity = UploadEntity.other,
    sizeLimit = Infinity,
    multiple = false,
    isPrivate = true,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<FileList>();
  const dispatch = useAppDispatch();
  const mediaCache = useSelector(mediaCacheSelector);

  const openInNewTab = async (url: string) => {
    if (mediaCache[url]?.url && mediaCache[url].timestamp + mediaCacheTTL > Date.now()) {
      return window.open(mediaCache[url].url, '_blank', 'noopener,noreferrer');
    }

    setLoading(true);

    const getFileFn = isPrivate ? getPrivateFile : getPublicFile;

    const result = await dispatch(getFileFn(url));

    setLoading(false);

    if (isDispatchApiSuccess<FetchMediaFileResponse>(result)) {
      window.open(result.payload.data.url, '_blank', 'noopener,noreferrer');
    } else {
      onError(`Could not download file: ${result.error.message}`);
    }
  };

  const handleFiles = async (files: FileList) => {
    for (const file of files) {
      if (file.size > sizeLimit) {
        onError('File size exceeded');
      }
    }
    const uploadFileFn = isPrivate ? uploadPrivate : uploadPublic;

    const promises = Array.from(files).map((file) => dispatch(uploadFileFn({ file, entity: uploadEntity })));

    setLoading(true);

    const results = await Promise.all(promises);
    const uploadedFiles: string[] = [];

    setLoading(false);

    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      if (isDispatchApiSuccess<UploadMediaFileResponse>(result)) {
        uploadedFiles.push(result.payload.data.filename);
      } else {
        onError(`Failed to upload file "${files[i].name}": ${(result as any).error.message}`);
      }
    }

    onChange(uploadedFiles);
  };

  const onFilesChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target?.files;

    if (!files) {
      return;
    }

    setSelectedFiles(files);

    await handleFiles(files);
  };

  useEffect(() => {
    const handler = async () => {
      if (selectedFiles) {
        await handleFiles(selectedFiles);
      }
    };

    handler();
  }, [isPrivate]);

  const onClick = () => {
    if (selectedFile) {
      return openInNewTab(selectedFile);
    }

    inputRef?.current?.click();
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <IconButton edge="end" size="small" sx={{ m: 0 }} onClick={onClick}>
      {selectedFile ? <FileDownloadIcon /> : <FileUploadIcon />}
      <input ref={inputRef} hidden multiple={multiple} accept={accept} type="file" onChange={onFilesChange} />
    </IconButton>
  );
};
