import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchUserFeeHistoryResponse, FetchUserFeeResponse, FetchUserFeesResponse } from '../../../types/userFee';
import {
  cleanUserFees,
  deleteUserFee,
  fetchUserFee,
  fetchUserFeeHistory,
  fetchUserFees,
  fetchUserFeesByUser,
  resetEditUserFee,
  saveUserFee,
  updateUserFee,
} from './userFee.actions';
import { UserFeeState } from './userFee.types';

const initialState: UserFeeState = {
  userFees: [],
  totalUserFees: 0,
  editUserFee: null,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  saveStatus: fetchStatuses.idle,

  userFeeHistory: [],
  totalUserFeeHistory: 0,
  fetchUserFeeHistoryStatus: fetchStatuses.idle,

  error: null,
};

const userFeesSlice = createSlice({
  name: 'userFees',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserFees.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUserFees.fulfilled, (state, action: PayloadAction<FetchUserFeesResponse>) => {
      state.userFees = action.payload.data?.items;
      state.totalUserFees = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserFees.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserFeeHistory.pending, (state) => {
      state.error = null;
      state.fetchUserFeeHistoryStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUserFeeHistory.fulfilled, (state, action: PayloadAction<FetchUserFeeHistoryResponse>) => {
      state.userFeeHistory = action.payload.data?.items;
      state.totalUserFeeHistory = action.payload.data?.totalItems;
      state.fetchUserFeeHistoryStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserFeeHistory.rejected, (state, action) => {
      state.fetchUserFeeHistoryStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserFeesByUser.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUserFeesByUser.fulfilled, (state, action: PayloadAction<FetchUserFeesResponse>) => {
      state.userFees = action.payload.data?.items;
      state.totalUserFees = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserFeesByUser.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserFee.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUserFee.fulfilled, (state, action: PayloadAction<FetchUserFeeResponse>) => {
      state.editUserFee = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserFee.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(saveUserFee.pending, (state) => {
      state.error = null;
      state.saveStatus = fetchStatuses.pending;
    });
    builder.addCase(saveUserFee.fulfilled, (state, action: PayloadAction<FetchUserFeeResponse>) => {
      state.editUserFee = state.editUserFee || ({} as any);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Object.assign(state.editUserFee!, {
        ...action.payload.data,
        productType: { id: action.payload.data.productTypeId },
        userId: { id: action.payload.data.userId },
        status: { id: action.payload.data.status },
        feeConfig: { id: action.payload.data.feeConfig },
      });
      state.saveStatus = fetchStatuses.success;
    });
    builder.addCase(saveUserFee.rejected, (state, action) => {
      state.saveStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateUserFee.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
    });
    builder.addCase(updateUserFee.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateUserFee.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteUserFee.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
    });
    builder.addCase(deleteUserFee.fulfilled, (state) => {
      state.editUserFee = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteUserFee.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditUserFee.type, (state) => {
      state.editUserFee = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.saveStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanUserFees.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const userFeeReducer = userFeesSlice.reducer;
