import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium';
import { diff } from 'deep-object-diff';
import { JsonDifferComponent } from 'react-json-differ';
import 'react-json-differ/dist/style.css';
import { GridCellExpand, Table } from '../../components/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { fetchUserHistory } from '../../redux/modules/user/user.actions';
import { userSelector } from '../../redux/modules/user/user.selectors';

interface UserHistoryTabProps {
  id: number;
}

export function UserHistoryTab({ id }: UserHistoryTabProps) {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id' },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      width: 200,
    },
    {
      field: 'prevValue',
      headerName: 'Prev Value',
      sortable: false,
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <GridCellExpand value={JSON.stringify(params.value, null, 2) || ''} width={params.colDef.computedWidth} />
      ),
    },
    {
      field: 'newValue',
      headerName: 'New Value',
      sortable: false,
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <GridCellExpand value={JSON.stringify(params.value, null, 2) || ''} width={params.colDef.computedWidth} />
      ),
    },
    {
      field: 'diff',
      headerName: 'Difference',
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <GridCellExpand
          value={JSON.stringify(diff(params.row.prevValue || {}, params.row.newValue || {}), null, 2) || ''}
          width={params.colDef.computedWidth}
          showPopperAlways
          ExpandedComponent={
            <JsonDifferComponent object1={params.row.prevValue || {}} object2={params.row.newValue || {}} />
          }
        />
      ),
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: false,
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      sortable: false,
      type: 'date',
      width: 100,
    },
  ]);
  const { totalUserHistoryItems, userHistoryItems, fetchUserHistoryStatus } = useSelector(userSelector);

  return (
    <Table
      fetchItems={fetchUserHistory}
      fetchItemsCustomParams={{ userId: id }}
      rows={userHistoryItems}
      columns={columns.current}
      loading={fetchUserHistoryStatus === fetchStatuses.pending}
      rowCount={totalUserHistoryItems}
      disableColumnFilter
      disableColumnMenu
      slots={{}}
      initialColumnVisibilityModel={{ id: false }}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
    />
  );
}
