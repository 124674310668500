import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { subscribedPortfolioService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import {
  SaveSubscribedPortfolioRequest,
  UpdateSubscribedPortfolioRequest,
  UpdateSubscribedPortfolioStatusRequest,
} from '../../../types/subscribedPortfolio';

export const fetchSubscribedPortfolios = createAsyncThunk('subscribed-portfolio/fetch-all', (data: PaginatedRequest) =>
  subscribedPortfolioService.fetchSubscribedPortfolios(data),
);

export const fetchSubscribedPortfolio = createAsyncThunk('subscribed-portfolio/get', (id: number) =>
  subscribedPortfolioService.fetchSubscribedPortfolio(id),
);

export const deleteSubscribedPortfolio = createAsyncThunk('subscribed-portfolio/delete', (id: number) =>
  subscribedPortfolioService.deleteSubscribedPortfolio(id),
);

export const saveSubscribedPortfolio = createAsyncThunk(
  'subscribed-portfolio/save',
  (portfolio: SaveSubscribedPortfolioRequest) => subscribedPortfolioService.saveSubscribedPortfolio(portfolio),
);

export const updateSubscribedPortfolio = createAsyncThunk(
  'subscribed-portfolio/update',
  (portfolio: UpdateSubscribedPortfolioRequest) => subscribedPortfolioService.updateSubscribedPortfolio(portfolio),
);

export const updateSubscribedPortfolioStatus = createAsyncThunk(
  'subscribed-portfolio/update-status',
  (data: UpdateSubscribedPortfolioStatusRequest) => subscribedPortfolioService.updateSubscribedPortfolioStatus(data),
);

export const fetchSubscribedPortfolioStatusOptions = createAsyncThunk(
  'subscribed-portfolio/fetch-portfolio-status-options',
  () => subscribedPortfolioService.fetchSubscribedPortfolioStatusOptions(),
);

export const cleanSubscribedPortfolios = createAction('subscribed-portfolio/clean');

export const resetEditSubscribedPortfolio = createAction('subscribed-portfolio/reset-edit');
