import { FetchUserCustodianDataResponse } from '../types/userCustodianData';
import { ApiService } from './common/ApiService';

class UserCustodianDataService extends ApiService {
  pathname = 'admin';

  findByUserId = async (id: number) =>
    this.get<FetchUserCustodianDataResponse>(`${this.pathname}/user-custodian-data/user/${id}`);
}

export const userCustodianDataService = new UserCustodianDataService();
