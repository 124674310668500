import { PaginatedRequest } from '../types/api';
import {
  CreateRoleRequest,
  CreateRoleResponse,
  FetchRolesResponse,
  FetchRoleResponse,
  UpdateRoleRequest,
  UpdateRoleResponse,
} from '../types/role';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class RoleService extends ApiService {
  pathname = 'admin';

  fetchRoles = async (data: PaginatedRequest) =>
    this.get<FetchRolesResponse>(`${this.pathname}/role?${toQueryParams(data)}`);

  fetchRole = async (id: number) => this.get<FetchRoleResponse>(`${this.pathname}/role/${id}`);

  deleteRole = async (id: number) => this.delete<FetchRoleResponse>(`${this.pathname}/role/${id}`);

  createRole = async (role: CreateRoleRequest) =>
    this.post<CreateRoleResponse, CreateRoleRequest>(`${this.pathname}/role`, role);

  updateRole = async ({ id, ...role }: UpdateRoleRequest) =>
    this.put<UpdateRoleResponse, Omit<UpdateRoleRequest, 'id'>>(`${this.pathname}/role/${id}`, role);
}

export const roleService = new RoleService();
