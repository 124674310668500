import { createSelector } from '@reduxjs/toolkit';
import { ReducerState } from '../../store.types';
import { BatchExecutionState } from './batchExecution.types';

export const batchExecutionSelector = ({ batchExecution }: ReducerState) => batchExecution;

export const batchExecutionBybatchIdSelector = createSelector(
  batchExecutionSelector,
  (state: ReducerState, batchId: string) => batchId,
  (batchExecution: BatchExecutionState, batchId: string) => batchExecution.batchExecutionByBatchId[batchId],
);

export const batchExecutionByRequestIdSelector = createSelector(
  batchExecutionSelector,
  (state: ReducerState, requestId: string) => requestId,
  (batchExecution: BatchExecutionState, requestId: string) => ({
    batchExecutions: batchExecution.batchExecutions[requestId] || [],
    totalbatchExecutions: batchExecution.totalBatchExecutions[requestId] || 0,
    fetchStatus: batchExecution.fetchStatuses[requestId],
  }),
);
