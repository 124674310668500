import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import moment from 'moment';
import { round2 } from '../../utils/number';
import { GridCellExpand } from '../Table';
import { FetchLedgerResponse, Ledger } from '../../types/ledger';

type LedgerTableProps = {
  accounts: string[];
  fetchLedger: (params: { account: string[] }) => { data: FetchLedgerResponse };
};

export function LedgerTable({ accounts, fetchLedger }: LedgerTableProps) {
  const [ledgerItems, setLedgerItems] = useState<Ledger[]>([]);
  const { data: ledger } = fetchLedger({ account: accounts });

  useEffect(() => {
    if (ledger?.results) {
      setLedgerItems(ledger.results);
    }
  }, [ledger]);

  const onFilterChanged: TextFieldProps['onChange'] = (event) => {
    const filterValue = event.target.value;

    setLedgerItems(ledger.results.filter((item) => JSON.stringify(item).includes(filterValue)));
  };

  const renderLedgerItems = () => {
    let isGray = true;
    let prevJournal: string | null = null;

    return ledgerItems.map((result) => {
      if (prevJournal && prevJournal !== result.journal) {
        isGray = !isGray;
      }

      prevJournal = result.journal;

      return (
        <Grid
          item
          container
          width="100%"
          flexDirection="row"
          key={result.id}
          sx={{ backgroundColor: isGray ? 'lightgray' : 'unset' }}
        >
          <Grid item xs={1}>
            <Typography fontSize={12} fontWeight={400}>
              {moment(result.datetime).format('DD-MM-YYYY HH:mm:ss')}
            </Typography>
          </Grid>
          <Grid item xs={2.5}>
            <Typography fontSize={12} fontWeight={400} sx={{ paddingLeft: '4px' }}>
              {result.meta.transactionId}
            </Typography>
          </Grid>
          <Grid item xs={2.5}>
            <Typography fontSize={12} fontWeight={400}>
              {result.accounts}
            </Typography>
          </Grid>
          <Grid item xs={1} sx={{ borderRight: '1px solid black' }}>
            <Typography fontSize={12} fontWeight={400}>
              {round2(result.debit)}
            </Typography>
          </Grid>
          <Grid item xs={1} sx={{ borderLeft: '1px solid black' }}>
            <Typography fontSize={12} fontWeight={400}>
              {round2(result.credit)}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography fontSize={12} fontWeight={400}>
              {result.memo}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <GridCellExpand value={JSON.stringify(result.meta, null, 2) || ''} width={600} />
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Grid item xs={12} component={Paper} sx={{ p: 2, mt: 2 }}>
      <Grid container xs={12}>
        <TextField
          name="filter"
          placeholder="Filter"
          label="Filter"
          variant="standard"
          onChange={onFilterChanged}
          type="string"
        />
      </Grid>
      <Grid container flexDirection="column">
        <Grid
          item
          container
          width="100%"
          flexDirection="row"
          sx={{ borderBottom: '2px solid black', marginTop: '12px' }}
        >
          <Grid item xs={1}>
            Datetime
          </Grid>
          <Grid item xs={2.5}>
            Transaction ID
          </Grid>
          <Grid item xs={2.5}>
            Accounts
          </Grid>
          <Grid item xs={1} sx={{ borderRight: '1px solid black' }}>
            Debit
          </Grid>
          <Grid item xs={1} sx={{ borderLeft: '1px solid black' }}>
            Credit
          </Grid>
          <Grid item xs={2}>
            Memo
          </Grid>
          <Grid item xs={1}>
            Payload
          </Grid>
        </Grid>

        {renderLedgerItems()}

        <Grid
          item
          container
          width="100%"
          flexDirection="row"
          sx={{ borderBottom: '2px solid black', borderTop: '2px solid black' }}
        >
          <Grid item xs={1}>
            &nbsp;
          </Grid>
          <Grid item xs={2.5}>
            &nbsp;
          </Grid>
          <Grid item xs={2.5}>
            &nbsp;
          </Grid>
          <Grid item xs={1} sx={{ borderRight: '1px solid black' }}>
            <Typography fontSize={14} fontWeight={400}>
              {round2(ledgerItems.reduce((res, item) => res + item.debit, 0) || 0)}
            </Typography>
          </Grid>
          <Grid item xs={1} sx={{ borderLeft: '1px solid black' }}>
            <Typography fontSize={14} fontWeight={400}>
              {round2(ledgerItems.reduce((res, item) => res + item.credit, 0) || 0)}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            &nbsp;
          </Grid>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
        </Grid>
      </Grid>
      <Typography variant="h6">Total: {ledgerItems.length}</Typography>
    </Grid>
  );
}
