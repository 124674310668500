import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { feeBatchService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import {
  CreateBatchAttachmentRequest,
  CreateFeeBatchRequest,
  ImportExecutionFeeBatch,
  ReconcileBatchExecutionsByBatchIdRequest,
  SaveFeeBatchRequest,
  UpdateFeeBatchRequest,
  UpdateFeeBatchStatusRequest,
  UploadBatchAttachmentRequest,
} from '../../../types/feeBatch';
// import { OrderExecutionReportType } from '../../../types/orderExecution';

export const fetchFeeBatches = createAsyncThunk(
  'feeBatch/fetch-all',
  (data: PaginatedRequest & { requestId: string }, { rejectWithValue }) =>
    feeBatchService.fetchFeeBatches(data).catch((error) => rejectWithValue(error)),
);

export const fetchFeeBatchesByExecutionId = createAsyncThunk(
  'feeBatch/fetch-all-by-execution-id',
  (data: PaginatedRequest & { executionId: string; requestId: string }, { rejectWithValue }) =>
    feeBatchService.fetchFeeBatchesByExecutionId(data).catch((error) => rejectWithValue(error)),
);

export const fetchBatchAttachments = createAsyncThunk(
  'feeBatch/get-attachments',
  (
    data: PaginatedRequest & {
      batchId: string;
    },
    { rejectWithValue },
  ) => feeBatchService.fetchBatchAttachments(data).catch((error) => rejectWithValue(error)),
);

export const fetchBatchAttachmentsTypeOptions = createAsyncThunk(
  'feeBatch/fetch-batch-attachments-type-options',
  (_, { rejectWithValue }) =>
    feeBatchService.fetchBatchAttachmentsTypeOptions().catch((error) => rejectWithValue(error)),
);

export const fetchFeeBatchesByBatchId = createAsyncThunk(
  'feeBatch/fetch-all-by-batch-id',
  (
    data: PaginatedRequest & {
      batchId: string;
    },
    { rejectWithValue },
  ) => feeBatchService.fetchFeeBatchesByBatchId(data).catch((error) => rejectWithValue(error)),
);

export const fetchFeeBatchExecutionsByBatchId = createAsyncThunk(
  'feeBatch/fetch-executions-by-batch-id',
  (batchId: string, { rejectWithValue }) =>
    feeBatchService.fetchFeeBatchExecutionsByBatchId(batchId).catch((error) => rejectWithValue(error)),
);

// export const fetchBatchExecutionsReportByBatchId = createAsyncThunk(
//   'feeBatch/fetch-batch-executions-report-by-batch-id',
//   (payload: { batchId: string; reportType: BatchExecutionReportType }, { rejectWithValue }) =>
//     feeBatchService
//       .fetchBatchExecutionsReportByBatchId(payload.batchId, payload.reportType)
//       .catch((error) => rejectWithValue(error)),
// );

export const discardBatchExecutionsByBatchId = createAsyncThunk(
  'feeBatch/discard-batch-executions-by-batch-id',
  (batchId: string, { rejectWithValue }) =>
    feeBatchService.discardBatchExecutionsByBatchId(batchId).catch((error) => rejectWithValue(error)),
);

export const approveBatchExecutionsByBatchId = createAsyncThunk(
  'feeBatch/approve-batch-executions-by-batch-id',
  (batchId: string, { rejectWithValue }) =>
    feeBatchService.approveBatchExecutionsByBatchId(batchId).catch((error) => rejectWithValue(error)),
);

export const changeBatchExecutionsToReconciliationByBatchId = createAsyncThunk(
  'feeBatch/change-batch-executions-to-reconciliation-by-batch-id',
  (batchId: string, { rejectWithValue }) =>
    feeBatchService.changeBatchExecutionsToReconciliationByBatchId(batchId).catch((error) => rejectWithValue(error)),
);

export const reconcileBatchExecutionsByBatchId = createAsyncThunk(
  'feeBatch/reconcile-batch-executions-by-batch-id',
  (payload: ReconcileBatchExecutionsByBatchIdRequest, { rejectWithValue }) =>
    feeBatchService.reconcileBatchExecutionsByBatchId(payload).catch((error) => rejectWithValue(error)),
);

export const fetchUserFeeDeductionsByBatchId = createAsyncThunk(
  'feeBatch/user-fee-deduction/fetch-all-by-batch-id',
  (
    data: PaginatedRequest & {
      batchId: string;
    },
    { rejectWithValue },
  ) => feeBatchService.fetchUserFeeDeductionsByBatchId(data).catch((error) => rejectWithValue(error)),
);

export const fetchFeeCalculationsByBatchId = createAsyncThunk(
  'feeBatch/fee-calculaions/fetch-all-by-batch-id',
  (
    data: PaginatedRequest & {
      batchId: string;
    },
    { rejectWithValue },
  ) => feeBatchService.fetchFeeCalculationsByBatchId(data).catch((error) => rejectWithValue(error)),
);

export const fetchUserFeeValidationsByBatchId = createAsyncThunk(
  'feeBatch/user-fee-validations/fetch-all-by-batch-id',
  (
    data: PaginatedRequest & {
      batchId: string;
    },
    { rejectWithValue },
  ) => feeBatchService.fetchUserFeeValidationsByBatchId(data).catch((error) => rejectWithValue(error)),
);

export const fetchFeeBatchValidationByBatchId = createAsyncThunk(
  'feeBatch/fee-batch-validations/fetch-by-batch-id',
  (batchId: string) => feeBatchService.fetchFeeBatchValidationByBatchId(batchId),
);

export const fetchConsolidateFeeRequestsByBatchId = createAsyncThunk(
  'feeBatch/consolidate-request/fetch-all-by-batch-id',
  (
    data: PaginatedRequest & {
      batchId: string;
    },
    { rejectWithValue },
  ) => feeBatchService.fetchConsolidateFeeRequestsByBatchId(data).catch((error) => rejectWithValue(error)),
);

export const fetchFeeBatch = createAsyncThunk('feeBatch/get', (id: number, { rejectWithValue }) =>
  feeBatchService.fetchFeeBatch(id).catch((error) => rejectWithValue(error)),
);

export const deleteFeeBatch = createAsyncThunk('feeBatch/delete', (batchId: string, { rejectWithValue }) =>
  feeBatchService.deleteFeeBatch(batchId).catch((error) => rejectWithValue(error)),
);

export const saveFeeBatch = createAsyncThunk('feeBatch/save', (feeBatch: SaveFeeBatchRequest, { rejectWithValue }) =>
  feeBatchService.saveFeeBatch(feeBatch).catch((error) => rejectWithValue(error)),
);

export const createFeeBatch = createAsyncThunk(
  'feeBatch/create',
  (payload: CreateFeeBatchRequest, { rejectWithValue }) =>
    feeBatchService.createFeeBatch(payload).catch((error) => rejectWithValue(error)),
);

export const updateFeeBatch = createAsyncThunk(
  'feeBatch/update',
  (feeBatch: UpdateFeeBatchRequest, { rejectWithValue }) =>
    feeBatchService.updateFeeBatch(feeBatch).catch((error) => rejectWithValue(error)),
);

export const updateFeeBatchStatus = createAsyncThunk(
  'feeBatch/update-status',
  (data: UpdateFeeBatchStatusRequest, { rejectWithValue }) =>
    feeBatchService.updateFeeBatchStatus(data).catch((error) => rejectWithValue(error)),
);

export const executeFeeBatch = createAsyncThunk('feeBatch/execute', (batchId: string, { rejectWithValue }) =>
  feeBatchService.executeFeeBatch(batchId).catch((error) => rejectWithValue(error)),
);

export const cancelExecutionFeeBatch = createAsyncThunk(
  'feeBatch/cancel-execution',
  (batchId: string, { rejectWithValue }) =>
    feeBatchService.cancelExecutionFeeBatch(batchId).catch((error) => rejectWithValue(error)),
);

export const consolidateFeeCalculations = createAsyncThunk(
  'feeBatch/consolidate-fee-claculations',
  (feeCalculationIds: number[], { rejectWithValue }) =>
    feeBatchService.consolidateFeeCalculations(feeCalculationIds).catch((error) => rejectWithValue(error)),
);

export const importExecutionFileFeeBatch = createAsyncThunk(
  'feeBatch/import-execution-file',
  (payload: ImportExecutionFeeBatch, { rejectWithValue }) =>
    feeBatchService.importExecutionFileFeeBatch(payload).catch((error) => rejectWithValue(error)),
);

export const importConsolidateExecutionFileFeeBatch = createAsyncThunk(
  'feeBatch/import-consolidate-execution-file',
  (payload: ImportExecutionFeeBatch, { rejectWithValue }) =>
    feeBatchService.importConsolidateExecutionFileFeeBatch(payload).catch((error) => rejectWithValue(error)),
);

export const fetchFeeBatchStatusOptions = createAsyncThunk(
  'feeBatch/fetch-fee-batch-status-options',
  (_, { rejectWithValue }) => feeBatchService.fetchFeeBatchStatusOptions().catch((error) => rejectWithValue(error)),
);

export const createBatchAttachment = createAsyncThunk(
  'feeBatch/create-attachment',
  (
    req: CreateBatchAttachmentRequest & {
      batchId: string;
    },
    { rejectWithValue },
  ) => feeBatchService.createBatchAttachment(req).catch((error) => rejectWithValue(error)),
);

export const uploadBatchAttachment = createAsyncThunk(
  'feeBatch/upload-attachment',
  (
    req: UploadBatchAttachmentRequest & {
      batchId: string;
    },
    { rejectWithValue },
  ) => feeBatchService.uploadBatchAttachment(req).catch((error) => rejectWithValue(error)),
);

export const cleanFeeBatches = createAction('feeBatch/clean');

export const cleanViewDetailsByBatchId = createAction('feeBatch/clean-view-details-by-batch-id');

export const resetEditFeeBatch = createAction('feeBatch/reset-edit');
