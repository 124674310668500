import { useParams } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { addFundsFormFields } from '../../forms/fields/formFields';
import { addFundsFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import { addFunds } from '../../redux/modules/userBank/userBank.actions';
import { UserBankInfo } from '../../types/user';
import { userBankSelector } from '../../redux/modules/userBank/userBank.selectors';

interface AddFundsDialogProps {
  open: boolean;
  bankInfo: UserBankInfo | undefined;
  onClose: (isReload: boolean) => void;
}

const debitAccounts = ['0108095164500016', '0108071089680019', '0108065554100010'];

export function AddFundsDialog({ open, onClose, bankInfo }: AddFundsDialogProps) {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { addFundsStatus } = useSelector(userBankSelector);
  const { handleSubmit, values, handleChange, touched, errors, submitForm } = useFormik({
    initialValues: {
      [addFundsFormFields.amount]: 1000,
      [addFundsFormFields.debitAccount]: debitAccounts[0],
    } as { amount: number; debitAccount: string },
    validationSchema: addFundsFormSchema,
    onSubmit: async (formValues) => {
      try {
        await dispatch(
          addFunds({
            ...formValues,
            amount: +formValues.amount,
            accountNumber: bankInfo!.userBankDetails!.accountNumber,
            currency: 'SAR',
            userId: Number(id),
          }),
        ).unwrap();
        onClose(true);
        enqueueSnackbar('Funds added!', { variant: 'success' });
      } catch (err: any) {
        enqueueSnackbar(`Failed to add funds: ${err.message}`, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Add funds</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id={addFundsFormFields.amount}
            name={addFundsFormFields.amount}
            label="SAR Amount"
            value={values[addFundsFormFields.amount]}
            onChange={handleChange}
            error={touched.amount && !!errors.amount}
            helperText={touched.amount && errors.amount}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="age-label">Debit Account</InputLabel>
            <Select
              labelId="age-label"
              name={addFundsFormFields.debitAccount}
              value={values[addFundsFormFields.debitAccount]}
              label="Debit Account"
              onChange={handleChange}
            >
              {debitAccounts.map((d) => (
                <MenuItem key={d} value={d}>
                  {d}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={addFundsStatus === fetchStatuses.pending}
          loadingPosition="end"
          onClick={submitForm}
        >
          Save
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
