import { apiSlice } from '../../../api/common/apiSlice';
import { PaginatedRequest } from '../../../types/api';
import { toQueryParams } from '../../../utils/request';
import { ApiTagType } from '../../../constants/apiTagType';
import {
  FetchWithdrawRequestsResponse,
  UpdateWithdrawRequestRequest,
  UpdateWithdrawRequestResponse,
} from '../../../types/payment';

export const withdrawApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchWithdraws: builder.query<FetchWithdrawRequestsResponse, PaginatedRequest>({
      query: (data) => ({
        url: `/admin/payment/withdraw?${toQueryParams(data)}`,
        method: 'GET',
      }),
      providesTags: (res) => {
        if (res) {
          return [
            ...(res.items || []).map(({ id }) => ({ type: ApiTagType.WithdrawRequest as const, id })),
            { type: ApiTagType.WithdrawRequest, id: 'LIST' },
          ];
        }
        return [{ type: ApiTagType.WithdrawRequest, id: 'LIST' }];
      },
    }),
    updateWithdrawStatus: builder.mutation<UpdateWithdrawRequestResponse, UpdateWithdrawRequestRequest>({
      query: ({ id, ...data }) => ({
        url: `/admin/payment/withdraw/${id}/status`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (res, error, req) => [
        { type: ApiTagType.WithdrawRequest, id: req.id },
        { type: ApiTagType.WithdrawRequest, id: 'LIST' },
      ],
    }),
  }),
});

export const { useFetchWithdrawsQuery, useUpdateWithdrawStatusMutation } = withdrawApiSlice;
