const getFormatter = (language: string, options: Intl.NumberFormatOptions) =>
  Intl.NumberFormat(language === 'ar' ? 'ar-SA' : 'en-US', options);

function getRoundCustom(fractions: number, withFormat: false): (value: number) => number;
function getRoundCustom(
  fractions: number,
  withFormat: true,
): (value: number, language?: string, options?: Intl.NumberFormatOptions) => string;
function getRoundCustom(
  fractions: number,
  withFormat: boolean,
): (value: number, language?: string, options?: Intl.NumberFormatOptions) => number | string {
  return (value: number, language = 'en', options: Intl.NumberFormatOptions = {}) => {
    let resValue = Math.round((value + Number.EPSILON) * fractions) / fractions;

    // eslint-disable-next-line no-compare-neg-zero
    if (resValue === -0) {
      resValue = 0;
    }

    if (!withFormat) {
      return resValue;
    }

    const fractionDigits = `${fractions}`.split('').filter((d) => d === '0').length;
    const formatter = getFormatter(language, {
      ...options,
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    });

    return formatter.format(resValue);
  };
}

export const round2 = getRoundCustom(100, false);
export const round4 = getRoundCustom(10000, false);

export const round2Format = getRoundCustom(100, true);
export const round4Format = getRoundCustom(10000, true);
