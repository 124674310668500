import { ActionType } from '../../types/action';

const listPermission = {
  resource: 'analytics',
  action: ActionType.READ,
};

export const AnalyticsPermission = {
  list: listPermission,
};
