import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchAccessResourcesResponse,
  FetchResourceResponse,
  FetchResourcesAutocompleteResponse,
  FetchResourcesResponse,
} from '../../../types/resource';
import {
  fetchResource,
  fetchResources,
  resetEditResource,
  cleanResources,
  updateResource,
  createResource,
  deleteResource,
  fetchAccessResources,
  fetchResourcesAutocomplete,
} from './resource.actions';
import { ResourceState } from './resource.types';

const initialState: ResourceState = {
  resources: [],
  accessResources: [],
  resourcesAutocomplete: [],
  totalResources: 0,
  editResource: null,
  fetchAutocompleteStatus: fetchStatuses.idle,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchResources.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchResources.fulfilled, (state, action: PayloadAction<FetchResourcesResponse>) => {
      state.resources = action.payload.data?.items;
      state.totalResources = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchResources.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchResourcesAutocomplete.pending, (state) => {
      state.fetchAutocompleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchResourcesAutocomplete.fulfilled,
      (state, action: PayloadAction<FetchResourcesAutocompleteResponse>) => {
        state.resourcesAutocomplete = action.payload.data;
        state.fetchAutocompleteStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchResourcesAutocomplete.rejected, (state, action) => {
      state.fetchAutocompleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchAccessResources.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchAccessResources.fulfilled, (state, action: PayloadAction<FetchAccessResourcesResponse>) => {
      state.accessResources = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchAccessResources.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchResource.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchResource.fulfilled, (state, action: PayloadAction<FetchResourceResponse>) => {
      state.editResource = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchResource.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateResource.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateResource.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateResource.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createResource.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(createResource.fulfilled, (state, action: PayloadAction<FetchResourceResponse>) => {
      state.editResource = action.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createResource.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteResource.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteResource.fulfilled, (state) => {
      state.editResource = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteResource.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditResource.type, (state) => {
      state.editResource = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanResources.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const resourceReducer = resourcesSlice.reducer;
