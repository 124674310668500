import { apiSlice } from '../../../api/common/apiSlice';
import { ApiTagType } from '../../../constants/apiTagType';
import { PaginatedRequest } from '../../../types/api';
import {
  CreateRolePermissionRequest,
  CreateRolePermissionResponse,
  FetchAccessPermissionsResponse,
  FetchMyPermissionsResponse,
  FetchRolePermissionResponse,
  FetchRolePermissionsResponse,
  UpdateRolePermissionRequest,
  UpdateRolePermissionResponse,
  UpdateRolePermissionsRequest,
  UpdateRolePermissionsResponse,
} from '../../../types/rolePermission';
import { toQueryParams } from '../../../utils/request';

export const rolePermissionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchRolePermissions: builder.query<FetchRolePermissionsResponse, PaginatedRequest>({
      query: (data) => ({
        url: `/admin/role-permission?${toQueryParams(data)}`,
        method: 'GET',
      }),
      providesTags: (res) => {
        if (res) {
          return [
            ...(res?.items || []).map(({ id }) => ({ type: ApiTagType.RolePermission as const, id })),
            { type: ApiTagType.RolePermission, id: 'LIST' },
          ];
        }
        return [{ type: ApiTagType.RolePermission, id: 'LIST' }];
      },
    }),
    fetchAccessPermissions: builder.query<FetchAccessPermissionsResponse, void>({
      query: () => ({
        url: '/admin/access/permissions',
        method: 'GET',
      }),
    }),
    fetchMyPermissions: builder.query<FetchMyPermissionsResponse, void>({
      query: () => ({
        url: '/admin/role-permission/my',
        method: 'GET',
      }),
    }),
    deleteRolePermission: builder.mutation<FetchRolePermissionResponse, number>({
      query: (id) => ({
        url: `/admin/role-permission/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (res, error, req) => [
        { type: ApiTagType.RolePermission, id: req },
        { type: ApiTagType.RolePermission, id: 'LIST' },
      ],
    }),
    createRolePermission: builder.mutation<CreateRolePermissionResponse, CreateRolePermissionRequest>({
      query: (data) => ({
        url: '/admin/role-permission',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: ApiTagType.RolePermission, id: 'LIST' }],
    }),
    updateRolePermission: builder.mutation<UpdateRolePermissionResponse, UpdateRolePermissionRequest>({
      query: ({ id, ...data }) => ({
        url: `/admin/role-permission/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (res, error, req) => [
        { type: ApiTagType.RolePermission, id: req.id },
        { type: ApiTagType.RolePermission, id: 'LIST' },
      ],
    }),
    updateRolePermissions: builder.mutation<UpdateRolePermissionsResponse, UpdateRolePermissionsRequest>({
      query: (data) => ({
        url: '/admin/role-permission',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [{ type: ApiTagType.RolePermission, id: 'LIST' }],
    }),
  }),
});

export const {
  useFetchRolePermissionsQuery,
  useFetchAccessPermissionsQuery,
  useFetchMyPermissionsQuery,
  useDeleteRolePermissionMutation,
  useCreateRolePermissionMutation,
  useUpdateRolePermissionMutation,
  useUpdateRolePermissionsMutation,
} = rolePermissionApiSlice;
