import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { satelliteAccountService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import {
  CreateSatelliteAccountRequest,
  CreateSatelliteAccountUnitRequest,
  UpdateSatelliteAccountRequest,
  UpdateSatelliteAccountUnitRequest,
} from '../../../types/satelliteAccount';

export const fetchSatelliteAccounts = createAsyncThunk('satelliteAccount/fetch-all', () =>
  satelliteAccountService.fetchSatelliteAccounts(),
);

export const fetchSatelliteAccountDetailsHistory = createAsyncThunk(
  'satelliteAccount/fetch-details-history',
  (data: PaginatedRequest) => satelliteAccountService.fetchSatelliteAccountDetailsHistory(data),
);

export const fetchSatelliteAccountUnits = createAsyncThunk('satelliteAccount/fetch-all-units', () =>
  satelliteAccountService.fetchSatelliteAccountUnits(),
);

export const fetchSatelliteAccount = createAsyncThunk('satelliteAccount/get', (id: number) =>
  satelliteAccountService.fetchSatelliteAccount(id),
);

export const fetchSatelliteAccountUnit = createAsyncThunk('satelliteAccount/get-unit', (id: number) =>
  satelliteAccountService.fetchSatelliteAccountUnit(id),
);

export const deleteSatelliteAccount = createAsyncThunk('satelliteAccount/delete', (id: number) =>
  satelliteAccountService.deleteSatelliteAccount(id),
);

export const deleteSatelliteAccountUnit = createAsyncThunk('satelliteAccount/delete-unit', (id: number) =>
  satelliteAccountService.deleteSatelliteAccountUnit(id),
);

export const createSatelliteAccountItem = createAsyncThunk(
  'satelliteAccount/create',
  (satelliteAccount: CreateSatelliteAccountRequest) => satelliteAccountService.createSatelliteAccount(satelliteAccount),
);

export const createSatelliteAccountItemUnit = createAsyncThunk(
  'satelliteAccount/create-unit',
  (satelliteAccountUnit: CreateSatelliteAccountUnitRequest) =>
    satelliteAccountService.createSatelliteAccountUnit(satelliteAccountUnit),
);

export const updateSatelliteAccount = createAsyncThunk(
  'satelliteAccount/update',
  (satelliteAccount: UpdateSatelliteAccountRequest) => satelliteAccountService.updateSatelliteAccount(satelliteAccount),
);

export const updateSatelliteAccountUnit = createAsyncThunk(
  'satelliteAccount/update-unit',
  (satelliteAccountUnit: UpdateSatelliteAccountUnitRequest) =>
    satelliteAccountService.updateSatelliteAccountUnit(satelliteAccountUnit),
);

export const resetEditSatelliteAccount = createAction('satelliteAccount/reset-edit');

export const resetEditSatelliteAccountUnit = createAction('satelliteAccount/reset-edit-unit');

export const cleanSatelliteAccounts = createAction('satelliteAccount/clean');
