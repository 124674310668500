import { ApiResponse } from './api';

export enum UserFeeValidationStatus {
  PENDING = 'Pending',
  MINOR_DIFFERENCE = 'Minor Difference',
  MATERIAL_DIFFERENCE = 'Material Difference',
  MATCHED = 'Matched',
}

export interface UserFeeValidationStatusOption {
  id: number;
  name: UserFeeValidationStatus;
}

export type UserFeeValidation = {
  id: number;
  userId: number;
  calculatedFee: number;
  actualFee: number;
  status: UserFeeValidationStatusOption;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchUserFeeValidationResponse = ApiResponse<UserFeeValidation>;
export type FetchUserFeeValidationsResponse = ApiResponse<{
  items: UserFeeValidation[];
  totalItems: number;
}>;
