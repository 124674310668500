import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { userFeeSelector } from '../../../../redux/modules/userFee/userFee.selectors';
import { UserFee } from '../../../../types/userFee';
import { CopyToClipboardButton } from '../../../../components/CopyToClipboardButton';
import { round2Format } from '../../../../utils/number';
import { ProductType } from '../../../../types/productType';
import { UserFeeInfoTabList } from './UserFeeInfoTabList';

interface UserFeesTabListProps {
  userId: number;
  productTypes: ProductType[];
}

export function UserFeesTabList({ userId, productTypes }: UserFeesTabListProps) {
  const { userFees, fetchStatus } = useSelector(userFeeSelector);

  const [selectedTab, setSelectedTab] = React.useState<string>(userFees.length ? `${userFees[0]?.id}` : '');

  const selectedFee = React.useMemo(() => userFees.find((uf) => `${uf.id}` === selectedTab), [userFees, selectedTab]);

  const isLoading = fetchStatus === fetchStatuses.pending;

  const handleChangeUserFeeTab = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (userFees.length && !selectedTab) {
      setSelectedTab(`${userFees[0].id}`);
    }
  }, [userFees.length]);

  const getProductTypeNameById = (id: number): string | undefined =>
    productTypes.find((item) => item.id === id)?.nameEn;

  const renderDetailsInfo = (userFee: UserFee) => (
    <Grid item container display="flex" flexDirection="row" sx={{ mt: '24px' }} spacing={2}>
      <Grid item container xs={8}>
        <Grid item xs={6}>
          <Typography variant="h6">Id</Typography>
        </Grid>
        <Grid item xs={6}>
          <CopyToClipboardButton value={(userFee?.id || '').toString()} text={(userFee?.id || '').toString()} />
        </Grid>
      </Grid>
      <Grid item container xs={8}>
        <Grid item xs={6}>
          <Typography variant="h6">Billable</Typography>
        </Grid>
        <Grid item xs={6}>
          <CopyToClipboardButton
            value={(userFee?.totalFee || 0).toString()}
            text={round2Format(userFee?.totalFee || 0)}
          />
        </Grid>
      </Grid>
      <Grid item container xs={8}>
        <Grid item xs={6}>
          <Typography variant="h6">Managment Fee</Typography>
        </Grid>
        <Grid item xs={6}>
          <CopyToClipboardButton
            value={(userFee?.managementFee || 0).toString()}
            text={round2Format(userFee?.managementFee || 0)}
          />
        </Grid>
      </Grid>
      <Grid item container xs={8}>
        <Grid item xs={6}>
          <Typography variant="h6">VAT</Typography>
        </Grid>
        <Grid item xs={6}>
          <CopyToClipboardButton value={(userFee?.vat || 0).toString()} text={round2Format(userFee?.vat || 0)} />
        </Grid>
      </Grid>
    </Grid>
  );

  const renderTabList = () => (
    <>
      <Box sx={{ width: '100%' }}>
        <TabList
          value={selectedTab}
          onChange={handleChangeUserFeeTab}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="user fee"
        >
          {userFees.map((uf) => (
            <Tab
              key={`user-fee-${uf.id}`}
              value={uf.id.toString()}
              label={`#${uf.id} - ${getProductTypeNameById(uf.productTypeId)}`}
            />
          ))}
        </TabList>
      </Box>
      {userFees.map((uf) => (
        <TabPanel key={`user-fee-panel-${uf.id}`} value={uf.id.toString()} sx={{ width: '100%' }}>
          {renderDetailsInfo(uf)}
        </TabPanel>
      ))}
    </>
  );

  if (isLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <TabContext value={selectedTab}>
      {userFees.length && selectedTab ? renderTabList() : <InsertPageBreakIcon />}
      {selectedTab && selectedFee?.id && (
        <Grid
          item
          container
          flexDirection="column"
          component={Paper}
          sx={{ p: '24px', mt: '24px', height: '600px' }}
          spacing={2}
        >
          <Grid item>
            <Typography variant="h6">User Fee Info</Typography>
          </Grid>
          <Grid item sx={{ position: 'relative', width: '100%' }}>
            <UserFeeInfoTabList userFee={selectedFee} />
          </Grid>
        </Grid>
      )}
    </TabContext>
  );
}
