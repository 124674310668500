import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { fundGeographicalFocusService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import {
  CreateFundGeographicalFocusRequest,
  UpdateFundGeographicalFocusRequest,
} from '../../../types/fundGeographicalFocus';

export const fetchFundGeographicalFocuses = createAsyncThunk(
  'fundGeographicalFocus/fetch-all',
  (data: PaginatedRequest) => fundGeographicalFocusService.fetchFundGeographicalFocuses(data),
);

export const fetchFundGeographicalFocus = createAsyncThunk('fundGeographicalFocus/get', (id: number) =>
  fundGeographicalFocusService.fetchFundGeographicalFocus(id),
);

export const deleteFundGeographicalFocus = createAsyncThunk('fundGeographicalFocus/delete', (id: number) =>
  fundGeographicalFocusService.deleteFundGeographicalFocus(id),
);

export const createFundGeographicalFocus = createAsyncThunk(
  'fundGeographicalFocus/create',
  (fundGeographicalFocus: CreateFundGeographicalFocusRequest) =>
    fundGeographicalFocusService.createFundGeographicalFocus(fundGeographicalFocus),
);

export const updateFundGeographicalFocus = createAsyncThunk(
  'fundGeographicalFocus/update',
  (fundGeographicalFocus: UpdateFundGeographicalFocusRequest) =>
    fundGeographicalFocusService.updateFundGeographicalFocus(fundGeographicalFocus),
);

export const resetEditFundGeographicalFocus = createAction('fundGeographicalFocus/reset-edit');

export const cleanFundGeographicalFocuses = createAction('fundGeographicalFocus/clean');
