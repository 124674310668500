import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { FundOrderStatus } from '../../types/portfolioOrder';

export const fundOrderStatusToInfo: {
  [prop in FundOrderStatus]: {
    color: 'error' | 'info' | 'success' | 'warning';
    icon: React.ElementType;
    menuAction: string;
  };
} = {
  [FundOrderStatus.CREATED]: {
    color: 'info',
    menuAction: 'Change to Created',
    icon: InfoIcon,
  },
  [FundOrderStatus.APPROVED]: {
    color: 'success',
    menuAction: 'Approve',
    icon: CheckCircleIcon,
  },
  [FundOrderStatus.RETURNED_FOR_CORRECTION]: {
    color: 'warning',
    menuAction: 'Returned for correction',
    icon: WarningIcon,
  },
  [FundOrderStatus.REJECTED]: {
    color: 'error',
    menuAction: 'Reject',
    icon: CheckCircleIcon,
  },
  [FundOrderStatus.EXECUTING]: {
    color: 'success',
    menuAction: 'Execute',
    icon: CheckCircleIcon,
  },
  [FundOrderStatus.DONE]: {
    color: 'success',
    menuAction: 'Change to Done',
    icon: CheckCircleIcon,
  },
  [FundOrderStatus.FAILED]: {
    color: 'error',
    menuAction: 'Change to Failed',
    icon: CheckCircleIcon,
  },
};
