import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { batchExecutionService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';

export const fetchBatchExecutions = createAsyncThunk(
  'batchExecution/fetch-all',
  (data: PaginatedRequest & { requestId: string }, { rejectWithValue }) =>
    batchExecutionService.fetchBatchExecutions(data).catch((error) => rejectWithValue(error)),
);

export const fetchBatchExecutionByBatchId = createAsyncThunk(
  'batchExecution/fetch-by-batch-id',
  (batchId: string, { rejectWithValue }) =>
    batchExecutionService.fetchBatchExecutionByBatchId(batchId).catch((error) => rejectWithValue(error)),
);

export const cleanBatchExecutions = createAction('batchExecution/clean');
