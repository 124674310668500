import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useSearchParamsState } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import { cleanAnonymous, fetchEarlySignupUsers } from '../../redux/modules/anonymous/anonymous.actions';
import { anonymousSelector } from '../../redux/modules/anonymous/anonymous.selectors';
import { parseJSON } from '../../utils/json';

export function EarlySignupUserList() {
  const dispatch = useAppDispatch();
  const tableRef = useRef<TableRef>(null);
  const [columns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Id',
      width: 80,
    },
    {
      field: 'name',
      headerName: 'Name',
      type: 'string',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'string',
      flex: 1,
    },
    {
      field: 'recordCreation',
      headerName: 'Created',
      type: 'dateTime',
      width: 200,
    },
  ]);
  const { earlySignupUsers, totalEarlySignupUser, fetchStatus } = useSelector(anonymousSelector);
  const loading = fetchStatus === fetchStatuses.pending;
  const [initialFilterModel, setInitialFilterModel] = useSearchParamsState('filter', '');
  const [initialSortModel, setInitialSortModel] = useSearchParamsState(
    'sort',
    JSON.stringify([{ field: 'id', sort: 'desc' }]),
  );
  const [initialPage, setInitialPage] = useSearchParamsState('page', '0');
  const [initialPageSize, setInitialPageSize] = useSearchParamsState('pageSize', '100');

  useEffect(
    () => () => {
      dispatch(cleanAnonymous());
    },
    [],
  );

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">Early Signup Users &nbsp;</Typography>
      </Box>
      <Table
        ref={tableRef}
        fetchItems={fetchEarlySignupUsers}
        rows={earlySignupUsers}
        columns={columns}
        loading={loading}
        rowCount={totalEarlySignupUser}
        initialFilterModel={parseJSON(initialFilterModel)}
        initialSortModel={parseJSON(initialSortModel)}
        initialPage={+initialPage}
        initialPageSize={+initialPageSize}
        onFilterModelChange={(filterModel) => setInitialFilterModel(JSON.stringify(filterModel))}
        onSortModelChange={(sortModel) => setInitialSortModel(JSON.stringify(sortModel))}
        onPageChange={(page) => setInitialPage(`${page}`)}
        onPageSizeChange={(pageSize) => setInitialPageSize(`${pageSize}`)}
      />
    </Container>
  );
}
