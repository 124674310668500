import { apiSlice } from '../../../api/common/apiSlice';
import { ApiTagType } from '../../../constants/apiTagType';
import { PaginatedRequest } from '../../../types/api';
import {
  CreateRoleRequest,
  CreateRoleResponse,
  FetchRoleResponse,
  FetchRolesResponse,
  UpdateRoleRequest,
  UpdateRoleResponse,
} from '../../../types/role';
import { toQueryParams } from '../../../utils/request';

export const roleApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchRoles: builder.query<FetchRolesResponse, PaginatedRequest>({
      query: (data) => ({
        url: `/admin/role?${toQueryParams(data)}`,
        method: 'GET',
      }),
      providesTags: (res) => {
        if (res) {
          return [
            ...(res?.items || []).map(({ id }) => ({ type: ApiTagType.Role as const, id })),
            { type: ApiTagType.Role, id: 'LIST' },
          ];
        }
        return [{ type: ApiTagType.Role, id: 'LIST' }];
      },
    }),
    fetchRole: builder.query<FetchRoleResponse, number>({
      query: (id) => ({
        url: `/admin/role?${id}`,
        method: 'GET',
      }),
      providesTags: (res, error, req) => [{ type: ApiTagType.Role as const, id: req }],
    }),
    deleteRole: builder.mutation<FetchRoleResponse, number>({
      query: (id) => ({
        url: `/admin/role/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (res, error, req) => [
        { type: ApiTagType.Role, id: req },
        { type: ApiTagType.Role, id: 'LIST' },
      ],
    }),
    createRole: builder.mutation<CreateRoleResponse, CreateRoleRequest>({
      query: (data) => ({
        url: '/admin/role',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: ApiTagType.Role, id: 'LIST' }],
    }),
    updateRole: builder.mutation<UpdateRoleResponse, UpdateRoleRequest>({
      query: ({ id, ...data }) => ({
        url: `/admin/role/${id}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (res, error, req) => [
        { type: ApiTagType.Role, id: req.id },
        { type: ApiTagType.Role, id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useFetchRolesQuery,
  useFetchRoleQuery,
  useDeleteRoleMutation,
  useCreateRoleMutation,
  useUpdateRoleMutation,
} = roleApiSlice;
