import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useAppDispatch } from '../../redux/hooks';
import { benchmarkSelector } from '../../redux/modules/benchmark/benchmark.selectors';
import { getExampleFundChartDataFile, importFundChartDataFile } from '../../redux/modules/indexFund/indexFund.actions';
import { Benchmark } from '../../types/benchmark';
import { IndexFund } from '../../types/indexFund';

interface ImportFundChartDataFileDialogProps {
  open: boolean;
  benchmark: Benchmark;
  indexFund: IndexFund;
  onClose: (isReload: boolean) => void;
}

export function ImportFundChartDataFileDialog({
  open,
  benchmark,
  indexFund,
  onClose,
}: ImportFundChartDataFileDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File>();

  const { importFundChartDataFileStatus, error } = useSelector(benchmarkSelector);

  useEffect(() => {
    if (importFundChartDataFileStatus === fetchStatuses.success) {
      enqueueSnackbar('Chart data file imported successfully!', { variant: 'success' });
      onClose(true);
    }
    if (importFundChartDataFileStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Import chart data file was failed!', { variant: 'error' });
    }
  }, [importFundChartDataFileStatus]);

  useEffect(() => {
    setFile(undefined);
  }, [open]);

  const onImportNavDataFile = () => {
    if (file) {
      dispatch(importFundChartDataFile({ benchmarkId: benchmark.id, indexFundId: indexFund.id, file }));
    }
  };

  const onDownloadSampleFile = async () => {
    const res = await dispatch(getExampleFundChartDataFile());

    const blob = new Blob([res.payload as string], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'example-fund-chart-data.csv';
    link.click();
    link.remove();
  };

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target?.files?.[0];

    if (newFile) {
      setFile(newFile);
    }
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        Update Fund Chart Data for fund: {indexFund.nameEn} and benchmark: {benchmark.nameEn}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          {error && <Alert severity="error">{error.message}</Alert>}
          <Button
            variant="outlined"
            component="label"
            aria-label="import nav data file"
            color="primary"
            startIcon={<UploadIcon />}
          >
            {file ? file.name : 'Select Chart Data file'}
            <input ref={inputRef} hidden accept="text/csv" type="file" onChange={onChangeFile} />
          </Button>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            <LoadingButton
              type="button"
              color="primary"
              variant="outlined"
              endIcon={<UploadIcon />}
              loading={importFundChartDataFileStatus === fetchStatuses.pending}
              loadingPosition="end"
              onClick={onDownloadSampleFile}
            >
              Download Sample File
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              type="button"
              color="primary"
              variant="contained"
              endIcon={<UploadIcon />}
              loading={importFundChartDataFileStatus === fetchStatuses.pending}
              loadingPosition="end"
              onClick={onImportNavDataFile}
            >
              Import
            </LoadingButton>
            <Button sx={{ ml: 2 }} color="secondary" variant="contained" onClick={() => onClose(false)}>
              Discard
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
