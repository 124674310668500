import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { actionService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { CreateActionRequest, UpdateActionRequest } from '../../../types/action';

export const fetchActions = createAsyncThunk('action/fetch-all', (data: PaginatedRequest) => actionService.fetchActions(data));

export const fetchActionsAutocomplete = createAsyncThunk('action/fetch-autocomplete', (term: string) => actionService.fetchActionsAutocomplete(term));

export const fetchAction = createAsyncThunk('action/get', (id: number) => actionService.fetchAction(id));

export const deleteAction = createAsyncThunk('action/delete', (id: number) => actionService.deleteAction(id));

export const createActionItem = createAsyncThunk('action/create', (action: CreateActionRequest) => actionService.createAction(action));

export const updateAction = createAsyncThunk('action/update', (action: UpdateActionRequest) => actionService.updateAction(action));

export const resetEditAction = createAction('action/reset-edit');

export const cleanActions = createAction('action/clean');
