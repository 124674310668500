import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef } from '@mui/x-data-grid-premium';
import { fetchUnitsReport } from '../../redux/modules/report/report.actions';
import { reportSelector } from '../../redux/modules/report/report.selectors';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { Table } from '../../components/Table';
import { indexFundSelector } from '../../redux/modules/indexFund/indexFund.selectors';

const columnGroups = [
  { name: 'UNITS', field: 'numOfUnits' },
  { name: 'BASE', field: 'costBase' },
  { name: 'TOTAL', field: 'totalCostBase' },
];

export function UnitsTable() {
  const { fetchUnitsReportItemsStatus, unitItems, totalUnitItems } = useSelector(reportSelector);
  const { indexFunds } = useSelector(indexFundSelector);

  const indexFundsColumns = indexFunds
    .map((indexFund) =>
      columnGroups.map(({ name, field }) => ({
        headerName: `[${name}] ${indexFund.nameEn}`,
        field: `[${name}] ${indexFund.nameEn}`,
        type: 'number',
        width: 130,
        filterable: false,
        valueGetter: ({ row }) => {
          const indexFundUnits = row.items.find((ifu) => ifu.indexFundId === indexFund.id);

          return indexFundUnits?.[field] ? indexFundUnits[field] : 0;
        },
      })),
    )
    .flat();

  const columns = useRef<GridColDef[]>([
    {
      headerName: 'User Id',
      field: 'userId',
    },
    {
      headerName: 'Custodian Number',
      field: 'custodianNumber',
      type: 'string',
      width: 200,
      valueGetter: ({ row }) => row.items[0].custodianNumber,
    },
    ...indexFundsColumns,
  ]);

  return (
    <Table
      wrapperHeight={600}
      fetchItems={fetchUnitsReport}
      rows={unitItems}
      columns={columns.current}
      loading={fetchUnitsReportItemsStatus === fetchStatuses.pending}
      rowCount={Number(totalUnitItems)}
      getRowId={(row) => row.userId}
    />
  );
}
