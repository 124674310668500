import { PaginatedRequest } from '../types/api';
import {
  CreateFundManagerRequest,
  CreateFundManagerResponse,
  FetchFundManagerResponse,
  FetchFundManagersAutocompleteResponse,
  FetchFundManagersResponse,
  UpdateFundManagerRequest,
  UpdateFundManagerResponse,
} from '../types/fundManager';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class FundManagerService extends ApiService {
  pathname = 'admin';

  fetchFundManagers = async (data: PaginatedRequest) =>
    this.get<FetchFundManagersResponse>(`${this.pathname}/fund-manager?${toQueryParams(data)}`);

  fetchFundManagersAutocomplete = async (term: string) =>
    this.get<FetchFundManagersAutocompleteResponse>(`${this.pathname}/fund-manager/autocomplete?term=${term}`);

  fetchFundManager = async (id: number) => this.get<FetchFundManagerResponse>(`${this.pathname}/fund-manager/${id}`);

  deleteFundManager = async (id: number) =>
    this.delete<FetchFundManagerResponse>(`${this.pathname}/fund-manager/${id}`);

  createFundManager = async (fundManager: CreateFundManagerRequest) =>
    this.post<CreateFundManagerResponse, CreateFundManagerRequest>(`${this.pathname}/fund-manager`, fundManager);

  updateFundManager = async ({ id, ...fundManager }: UpdateFundManagerRequest) =>
    this.put<UpdateFundManagerResponse, Omit<UpdateFundManagerRequest, 'id'>>(
      `${this.pathname}/fund-manager/${id}`,
      fundManager,
    );
}

export const fundManagerService = new FundManagerService();
