import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchUserFeeDeductionResponse,
  FetchUserFeeDeductionsResponse,
  FetchUserFeeDeductionStatusOptionsResponse,
} from '../../../types/userFeeDeduction';
import {
  fetchUserFeeDeduction,
  fetchUserFeeDeductions,
  resetEditUserFeeDeduction,
  saveUserFeeDeduction,
  deleteUserFeeDeduction,
  cleanUserFeeDeductions,
  updateUserFeeDeductionStatus,
  fetchUserFeeDeductionStatusOptions,
  updateUserFeeDeduction,
} from './userFeeDeduction.actions';
import { UserFeeDeductionState } from './userFeeDeduction.types';

const initialState: UserFeeDeductionState = {
  userFeeDeductions: {},
  totalUserFeeDeductions: {},
  editUserFeeDeduction: null,
  userFeeDeductionStatusOptions: [],
  fetchUserFeeDeductionStatusOptionsStatus: fetchStatuses.idle,
  fetchStatuses: {},
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  updateStatusStatus: fetchStatuses.idle,
  saveStatus: fetchStatuses.idle,
  error: null,
};

const userFeeDeductionSlice = createSlice({
  name: 'userFeeDeductions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserFeeDeductions.pending, (state, action) => {
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(
      fetchUserFeeDeductions.fulfilled,
      (state, action: PayloadAction<FetchUserFeeDeductionsResponse, any, any>) => {
        const requestId = (action as any).meta.arg.requestId || (action as any).meta.requestId;

        state.fetchStatuses[requestId] = fetchStatuses.success;

        if (action.payload) {
          state.userFeeDeductions[requestId] = action.payload.data?.items;
          state.totalUserFeeDeductions[requestId] = action.payload.data?.totalItems;
        }
      },
    );
    builder.addCase(fetchUserFeeDeductions.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.rejected;

      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchUserFeeDeduction.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUserFeeDeduction.fulfilled, (state, action: PayloadAction<FetchUserFeeDeductionResponse>) => {
      state.editUserFeeDeduction = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserFeeDeduction.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserFeeDeductionStatusOptions.pending, (state) => {
      state.error = null;
      state.fetchUserFeeDeductionStatusOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserFeeDeductionStatusOptions.fulfilled,
      (state, action: PayloadAction<FetchUserFeeDeductionStatusOptionsResponse>) => {
        state.userFeeDeductionStatusOptions = action.payload.data;
        state.fetchUserFeeDeductionStatusOptionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserFeeDeductionStatusOptions.rejected, (state, action) => {
      state.fetchUserFeeDeductionStatusOptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateUserFeeDeductionStatus.pending, (state) => {
      state.error = null;
      state.updateStatusStatus = fetchStatuses.pending;
    });
    builder.addCase(updateUserFeeDeductionStatus.fulfilled, (state) => {
      state.updateStatusStatus = fetchStatuses.success;
    });
    builder.addCase(updateUserFeeDeductionStatus.rejected, (state, action) => {
      state.updateStatusStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(saveUserFeeDeduction.pending, (state) => {
      state.error = null;
      state.saveStatus = fetchStatuses.pending;
    });
    builder.addCase(saveUserFeeDeduction.fulfilled, (state, action: PayloadAction<FetchUserFeeDeductionResponse>) => {
      state.editUserFeeDeduction = state.editUserFeeDeduction || ({} as any);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Object.assign(state.editUserFeeDeduction!, {
        ...action.payload.data,
        status: { id: action.payload.data.status },
        type: { id: action.payload.data.type },
      });
      state.saveStatus = fetchStatuses.success;
    });
    builder.addCase(saveUserFeeDeduction.rejected, (state, action) => {
      state.saveStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateUserFeeDeduction.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
    });
    builder.addCase(updateUserFeeDeduction.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateUserFeeDeduction.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteUserFeeDeduction.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
    });
    builder.addCase(deleteUserFeeDeduction.fulfilled, (state) => {
      state.editUserFeeDeduction = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteUserFeeDeduction.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditUserFeeDeduction.type, (state) => {
      state.editUserFeeDeduction = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.saveStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanUserFeeDeductions.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const userFeeDeductionReducer = userFeeDeductionSlice.reducer;
