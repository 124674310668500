import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchJadwaOrdersByMadkholOrderIdResponse } from '../../../types/jadwa';
import { fetchOrdersByMadkholOrderId, cleanJadwas, updateJadwaTransfer, triggerJadwaTransfer } from './jadwa.actions';
import { JadwaState } from './jadwa.types';

const initialState: JadwaState = {
  orders: [],
  fetchOrdersStatus: fetchStatuses.idle,
  updateTransferStatus: fetchStatuses.idle,
  triggerTransferStatus: fetchStatuses.idle,
  error: null,
};

const jadwaSlice = createSlice({
  name: 'jadwa',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOrdersByMadkholOrderId.pending, (state) => {
      state.fetchOrdersStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchOrdersByMadkholOrderId.fulfilled,
      (state, action: PayloadAction<FetchJadwaOrdersByMadkholOrderIdResponse>) => {
        state.orders = action.payload.data;
        state.fetchOrdersStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchOrdersByMadkholOrderId.rejected, (state, action) => {
      state.fetchOrdersStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateJadwaTransfer.pending, (state) => {
      state.updateTransferStatus = fetchStatuses.pending;
    });
    builder.addCase(updateJadwaTransfer.fulfilled, (state) => {
      state.updateTransferStatus = fetchStatuses.success;
    });
    builder.addCase(updateJadwaTransfer.rejected, (state, action) => {
      state.updateTransferStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(triggerJadwaTransfer.pending, (state) => {
      state.triggerTransferStatus = fetchStatuses.pending;
    });
    builder.addCase(triggerJadwaTransfer.fulfilled, (state) => {
      state.triggerTransferStatus = fetchStatuses.success;
    });
    builder.addCase(triggerJadwaTransfer.rejected, (state, action) => {
      state.triggerTransferStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(cleanJadwas.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const jadwaReducer = jadwaSlice.reducer;
