import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { fundGeographicalFocusFormFields } from '../../forms/fields/formFields';
import { fundGeographicalFocusFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import {
  createFundGeographicalFocus,
  updateFundGeographicalFocus,
} from '../../redux/modules/fundGeographicalFocus/fundGeographicalFocus.actions';
import { fundGeographicalFocusSelector } from '../../redux/modules/fundGeographicalFocus/fundGeographicalFocus.selectors';
import { FundGeographicalFocus } from '../../types/fundGeographicalFocus';

interface FundGeographicalFocusDialogProps {
  open: boolean;
  initialValue: FundGeographicalFocus | null;
  onClose: (isReload: boolean) => void;
}

export function FundGeographicalFocusDialog({ open, initialValue, onClose }: FundGeographicalFocusDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, values, handleChange, touched, errors, submitForm, setValues, resetForm } = useFormik({
    initialValues: { [fundGeographicalFocusFormFields.nameEn]: '', [fundGeographicalFocusFormFields.nameAr]: '' },
    validationSchema: fundGeographicalFocusFormSchema,
    onSubmit: (formValues) => {
      if (initialValue?.id) {
        dispatch(updateFundGeographicalFocus({ id: initialValue.id, ...formValues }));
      } else {
        dispatch(createFundGeographicalFocus(formValues));
      }
    },
  });
  const { createStatus, updateStatus, error } = useSelector(fundGeographicalFocusSelector);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    setValues({ nameEn: initialValue?.nameEn || '', nameAr: initialValue?.nameAr || '' });
  }, [initialValue]);

  useEffect(() => {
    if (createStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Fund Geographical Focus created!', { variant: 'success' });
    }
    if (createStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Fund Geographical Focus creation error!', { variant: 'error' });
    }

    if (updateStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Fund Geographical Focus updated!', { variant: 'success' });
    }
  }, [createStatus, updateStatus]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        {initialValue?.id ? 'Edit Fund Geographical Focus' : 'Create a new Fund Geographical Focus'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error && <Alert severity="error">{error.message}</Alert>}
          Enter the Fund Geographical Focus name
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="nameEn"
            name={fundGeographicalFocusFormFields.nameEn}
            label="Name En"
            value={values[fundGeographicalFocusFormFields.nameEn]}
            onChange={handleChange}
            error={touched.nameEn && !!errors.nameEn}
            helperText={touched.nameEn && errors.nameEn}
          />
          <TextField
            dir="rtl"
            margin="normal"
            required
            fullWidth
            id="nameAr"
            name={fundGeographicalFocusFormFields.nameAr}
            label="Name Ar"
            value={values[fundGeographicalFocusFormFields.nameAr]}
            onChange={handleChange}
            error={touched.nameAr && !!errors.nameAr}
            helperText={touched.nameAr && errors.nameAr}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={createStatus === fetchStatuses.pending}
          loadingPosition="end"
          onClick={submitForm}
        >
          {initialValue?.id ? 'Save' : 'Create'}
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
