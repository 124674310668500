import { PaginatedRequest } from '../types/api';
import {
  CreateActionRequest,
  CreateActionResponse,
  FetchActionsResponse,
  FetchActionResponse,
  UpdateActionRequest,
  UpdateActionResponse,
  FetchActionsAutocompleteResponse,
} from '../types/action';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class ActionService extends ApiService {
  pathname = 'admin';

  fetchActions = async (data: PaginatedRequest) =>
    this.get<FetchActionsResponse>(`${this.pathname}/action?${toQueryParams(data)}`);

  fetchActionsAutocomplete = async (term: string) =>
    this.get<FetchActionsAutocompleteResponse>(`${this.pathname}/action/autocomplete?term=${term}`);

  fetchAction = async (id: number) => this.get<FetchActionResponse>(`${this.pathname}/action/${id}`);

  deleteAction = async (id: number) => this.delete<FetchActionResponse>(`${this.pathname}/action/${id}`);

  createAction = async (action: CreateActionRequest) =>
    this.post<CreateActionResponse, CreateActionRequest>(`${this.pathname}/action`, action);

  updateAction = async ({ id, ...action }: UpdateActionRequest) =>
    this.put<UpdateActionResponse, Omit<UpdateActionRequest, 'id'>>(`${this.pathname}/action/${id}`, action);
}

export const actionService = new ActionService();
