import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { feeAccountSelector } from '../../redux/modules/feeAccount/feeAccount.selectors';
import { FeeAccount } from '../../types/feeAccount';
import { round2Format } from '../../utils/number';
import { TransactionsLogsTable } from '../User/components/OwnedShares/TransactionsLogsTable';
import { TransactionsTable } from '../User/components/OwnedShares/TransactionsTable';
import { FeeAccountDetailsHistoryTable } from './FeeAccountDetailsHistoryTable';

export function FeeAccountsDetails() {
  const { feeAccounts, fetchStatus } = useSelector(feeAccountSelector);
  const [selectedFeeAccount, setSelectedFeeAccount] = React.useState<string>('1');

  const isLoading = fetchStatus === fetchStatuses.pending;

  const handleChangeFeeAccountTab = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedFeeAccount(newValue);
  };

  useEffect(() => {
    if (feeAccounts.length && !selectedFeeAccount) {
      setSelectedFeeAccount(`${feeAccounts[0].id}`);
    }
  }, [feeAccounts]);

  const renderDetailsInfo = (feeAccount: FeeAccount) => (
    <Grid container>
      <Grid item container flexDirection="row" sx={{ mt: 0 }} spacing={2} xs={12}>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Current Balance</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{round2Format(feeAccount?.balance || 0)}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Hold amount</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{round2Format(feeAccount?.hold || 0)}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">VIBAN</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeAccount?.iban || '-'}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Account number</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeAccount?.accountNumber || '-'}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Custodian Reference Number</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeAccount?.custodianReferenceNumber || '-'}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ pt: 4, pb: 2 }}>
        <Divider />
      </Grid>

      <Grid item container flexDirection="row" sx={{ mt: 0 }} spacing={2} xs={12}>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">First Name</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeAccount?.firstNameEn || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Second Name</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeAccount?.secondNameEn || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Family Name</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeAccount?.familyNameEn || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Email</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeAccount?.email || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Phone</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeAccount?.phone || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Nationality</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeAccount?.nationalityEn || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Residence</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeAccount?.residenceEn || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Address En</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeAccount?.addressEn || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Address Ar</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeAccount?.addressAr || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Gender</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeAccount?.gender || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Id Number</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeAccount?.idNumber || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Id Type</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeAccount?.idType || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Id Expiry Date</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{feeAccount?.idExpiryDate || ''}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ pt: 4, pb: 2 }}>
        <Divider />
      </Grid>
      <Grid item container flexDirection="row" sx={{ mt: 0 }} spacing={2} xs={12}>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Last Update</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{new Date(feeAccount.updatedAt).toLocaleString('en-US')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  if (isLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <TabContext value={selectedFeeAccount}>
      <Box sx={{ width: '100%' }}>
        <TabList
          value={selectedFeeAccount}
          onChange={handleChangeFeeAccountTab}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="fee account"
        >
          {feeAccounts.map((sa) => (
            <Tab key={`fee-account-${sa.id}`} value={sa.id.toString()} label={`#${sa.id} - ${sa.idNumber}`} />
          ))}
        </TabList>
      </Box>
      {feeAccounts.map((sa) => (
        <TabPanel key={`fee-account-panel-${sa.id}`} value={sa.id.toString()} sx={{ width: '100%' }}>
          {renderDetailsInfo(sa)}
        </TabPanel>
      ))}
      {selectedFeeAccount && (
        <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}>
          <Typography variant="h6">History</Typography>
          <FeeAccountDetailsHistoryTable feeAccountDetailsId={+selectedFeeAccount} />
        </Grid>
      )}
      {selectedFeeAccount && (
        <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}>
          <Typography variant="h6">Transactions</Typography>
          <TransactionsTable userBankId={+selectedFeeAccount} />
        </Grid>
      )}
      {selectedFeeAccount && (
        <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}>
          <Typography variant="h6">Transactions Logs</Typography>
          <TransactionsLogsTable userBankId={+selectedFeeAccount} />
        </Grid>
      )}
    </TabContext>
  );
}
