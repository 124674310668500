import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, FormHelperText, TextField, TextFieldProps } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import { useFormik } from 'formik';
import debounce from 'lodash/debounce';
import { useSnackbar } from 'notistack';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { formFields } from '../../forms/fields/formFields';
import { actionFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import { createActionItem, updateAction } from '../../redux/modules/action/action.actions';
import { actionSelector } from '../../redux/modules/action/action.selectors';
import { fetchResourcesAutocomplete } from '../../redux/modules/resource/resource.actions';
import { resourceSelector } from '../../redux/modules/resource/resource.selectors';
import { Action } from '../../types/action';
import { Resource } from '../../types/resource';

interface ActionDialogProps {
  open: boolean;
  initialValue: Action | null;
  onClose: (isReload: boolean) => void;
}

export function ActionDialog({ open, initialValue, onClose }: ActionDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [initialized, setInitialized] = useState(false);
  const [resourceValue, setResourceValue] = useState<Resource | null>(null);
  const { handleSubmit, values, handleChange, touched, errors, submitForm, setFieldValue, setValues, resetForm } =
    useFormik({
      initialValues: {
        [formFields.name]: initialValue?.name || '',
        [formFields.type]: initialValue?.type || '',
        [formFields.resource]: initialValue?.resource?.id || '',
      } as { name: string; type: string; resource: number },
      validationSchema: actionFormSchema,
      onSubmit: (formValues) => {
        if (initialValue?.id) {
          dispatch(updateAction({ id: initialValue.id, ...formValues }));
        } else {
          dispatch(createActionItem(formValues));
        }
      },
    });
  const { fetchStatus, createStatus, updateStatus, error } = useSelector(actionSelector);
  const { resourcesAutocomplete, fetchAutocompleteStatus } = useSelector(resourceSelector);
  const resourcesLoading = fetchAutocompleteStatus === fetchStatuses.pending;
  const isLoading = fetchStatus === fetchStatuses.pending;

  useEffect(() => {
    if (open) {
      dispatch(fetchResourcesAutocomplete(initialValue?.resource.name || ''));
    } else {
      resetForm();
      setInitialized(false);
      setResourceValue(null);
    }
  }, [open]);

  useEffect(() => {
    if (initialized) {
      setValues({
        [formFields.name]: initialValue?.name || '',
        [formFields.type]: initialValue?.type || '',
        [formFields.resource]: initialValue?.resource?.id || '',
      } as { name: string; type: string; resource: number });
      const resource = resourcesAutocomplete.find((r) => r.id === initialValue?.resource?.id);

      if (resource) {
        setResourceValue(resource);
      }
    }
  }, [initialized]);

  useEffect(() => {
    if (fetchAutocompleteStatus === fetchStatuses.success && !initialized) {
      setInitialized(true);
    }
  }, [fetchAutocompleteStatus]);

  useEffect(() => {
    if (createStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Action created!', { variant: 'success' });
    }
    if (createStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Action creation error!', { variant: 'error' });
    }

    if (updateStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Action updated!', { variant: 'success' });
    }
  }, [createStatus, updateStatus]);

  const onAutocompleteSearch = useCallback(
    debounce((_, searchValue) => {
      if (searchValue) {
        dispatch(fetchResourcesAutocomplete(searchValue));
      }
    }),
    [],
  );

  const onAutocompleteChange = (_: SyntheticEvent, newValue: Resource | null) => {
    setResourceValue(newValue);
    setFieldValue(formFields.resource, newValue?.id || '');
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{initialValue?.id ? 'Edit action' : 'Create a new Action'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error && <Alert severity="error">{error.message}</Alert>}
          Enter the action name
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            name={formFields.name}
            label="Action Name"
            type="actionName"
            value={values[formFields.name]}
            onChange={handleChange}
            error={touched.name && !!errors.name}
            helperText={touched.name && errors.name}
            autoComplete="action-name"
          />
          <Autocomplete
            title="Resource"
            options={resourcesLoading ? [] : resourcesAutocomplete}
            getOptionLabel={(option: Resource) => option?.name}
            renderInput={(params: TextFieldProps) => (
              <TextField
                {...params}
                margin="normal"
                required
                fullWidth
                id="resource"
                name={formFields.resource}
                label="Resource"
                type="resource"
                error={touched.resource && !!errors.resource}
                helperText={touched.resource && errors.resource}
              />
            )}
            onInputChange={onAutocompleteSearch}
            onChange={onAutocompleteChange}
            loading={resourcesLoading}
            value={resourceValue}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={80} />
          ) : (
            <FormControl fullWidth margin="normal" error={touched.type && !!errors.type}>
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                labelId="type-label"
                name={formFields.type}
                value={values[formFields.type]}
                label="Type"
                onChange={handleChange}
                error={touched.type && !!errors.type}
              >
                <MenuItem value="read">read</MenuItem>
                <MenuItem value="create">create</MenuItem>
                <MenuItem value="update">update</MenuItem>
                <MenuItem value="delete">delete</MenuItem>
              </Select>
              {touched.type && <FormHelperText>{errors.type}</FormHelperText>}
            </FormControl>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={createStatus === fetchStatuses.pending}
          loadingPosition="end"
          onClick={submitForm}
        >
          {initialValue?.id ? 'Save' : 'Create'}
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
