import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchFeeConfigHistoryResponse,
  FetchFeeConfigResponse,
  FetchFeeConfigsResponse,
  FetchFeeConfigStatusOptionsResponse,
} from '../../../types/feeConfig';
import {
  fetchFeeConfig,
  fetchFeeConfigs,
  resetEditFeeConfig,
  cleanFeeConfigs,
  updateFeeConfig,
  createFeeConfigItem,
  deleteFeeConfig,
  fetchFeeConfigHistory,
  fetchFeeConfigStatusOptions,
} from './feeConfig.actions';
import { FeeConfigState } from './feeConfig.types';

const initialState: FeeConfigState = {
  feeConfigs: [],
  totalFeeConfigs: 0,
  editFeeConfig: null,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,

  feeConfigStatusOptions: [],
  fetchFeeConfigStatusOptionsStatus: fetchStatuses.idle,

  feeConfigHistory: [],
  totalFeeConfigHistory: 0,
  fetchFeeConfigHistoryStatus: fetchStatuses.idle,
  error: null,
};

const feeConfigsSlice = createSlice({
  name: 'feeConfigs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFeeConfigs.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchFeeConfigs.fulfilled, (state, feeConfig: PayloadAction<FetchFeeConfigsResponse>) => {
      state.feeConfigs = feeConfig.payload.data.items;
      state.totalFeeConfigs = feeConfig.payload.data.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFeeConfigs.rejected, (state, satelliteAccount) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = satelliteAccount.error;
    });

    builder.addCase(fetchFeeConfigHistory.fulfilled, (state, action: PayloadAction<FetchFeeConfigHistoryResponse>) => {
      state.feeConfigHistory = action.payload.data?.items;
      state.totalFeeConfigHistory = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFeeConfigHistory.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFeeConfig.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchFeeConfig.fulfilled, (state, feeConfig: PayloadAction<FetchFeeConfigResponse>) => {
      state.editFeeConfig = feeConfig.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFeeConfig.rejected, (state, feeConfig) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = feeConfig.error;
    });

    builder.addCase(updateFeeConfig.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateFeeConfig.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateFeeConfig.rejected, (state, satelliteAccount) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = satelliteAccount.error;
    });

    builder.addCase(createFeeConfigItem.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(createFeeConfigItem.fulfilled, (state, feeConfig: PayloadAction<FetchFeeConfigResponse>) => {
      state.editFeeConfig = feeConfig.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createFeeConfigItem.rejected, (state, satelliteAccount) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = satelliteAccount.error;
    });

    builder.addCase(fetchFeeConfigStatusOptions.pending, (state) => {
      state.error = null;
      state.fetchFeeConfigStatusOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchFeeConfigStatusOptions.fulfilled,
      (state, action: PayloadAction<FetchFeeConfigStatusOptionsResponse>) => {
        state.feeConfigStatusOptions = action.payload.data;
        state.fetchFeeConfigStatusOptionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFeeConfigStatusOptions.rejected, (state, action) => {
      state.fetchFeeConfigStatusOptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteFeeConfig.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteFeeConfig.fulfilled, (state) => {
      state.editFeeConfig = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteFeeConfig.rejected, (state, satelliteAccount) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = satelliteAccount.error;
    });

    builder.addCase(resetEditFeeConfig.type, (state) => {
      state.editFeeConfig = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanFeeConfigs.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const feeConfigReducer = feeConfigsSlice.reducer;
