import { ActionType } from '../../types/action';

const anbPaymentDetailsPermission = {
  resource: 'anb-payment-details',
  action: ActionType.READ,
};

const paymentApiLogsPermission = {
  resource: 'payment-api-logs',
  action: ActionType.READ,
};

const paymentStatementPermission = {
  resource: 'payment-statement',
  action: ActionType.READ,
};

export const PaymentPermission = {
  anbPaymentDetails: anbPaymentDetailsPermission,
  paymentApiLogs: paymentApiLogsPermission,
  paymentStatement: paymentStatementPermission,
};
