import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchActionResponse, FetchActionsAutocompleteResponse, FetchActionsResponse } from '../../../types/action';
import {
  fetchAction,
  fetchActions,
  resetEditAction,
  cleanActions,
  updateAction,
  createActionItem,
  deleteAction,
  fetchActionsAutocomplete,
} from './action.actions';
import { ActionState } from './action.types';

const initialState: ActionState = {
  actions: [],
  totalActions: 0,
  actionsAutocomplete: [],
  editAction: null,
  fetchAutocompleteStatus: fetchStatuses.idle,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchActions.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchActions.fulfilled, (state, action: PayloadAction<FetchActionsResponse>) => {
      state.actions = action.payload.data?.items;
      state.totalActions = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchActions.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchActionsAutocomplete.pending, (state) => {
      state.fetchAutocompleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchActionsAutocomplete.fulfilled,
      (state, action: PayloadAction<FetchActionsAutocompleteResponse>) => {
        state.actionsAutocomplete = action.payload.data;
        state.fetchAutocompleteStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchActionsAutocomplete.rejected, (state, action) => {
      state.fetchAutocompleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchAction.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchAction.fulfilled, (state, action: PayloadAction<FetchActionResponse>) => {
      state.editAction = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchAction.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateAction.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateAction.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateAction.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createActionItem.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(createActionItem.fulfilled, (state, action: PayloadAction<FetchActionResponse>) => {
      state.editAction = action.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createActionItem.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteAction.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteAction.fulfilled, (state) => {
      state.editAction = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteAction.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditAction.type, (state) => {
      state.editAction = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanActions.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const actionReducer = actionsSlice.reducer;
