import { createSelector } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { ReducerState } from '../../store.types';

export const userCustodianDataSelector = ({ userCustodianData }: ReducerState) => userCustodianData;

export const userCustodianDataLoadingSelector = createSelector(
  userCustodianDataSelector,
  ({ fetchStatus }) => fetchStatus === fetchStatuses.pending,
);
