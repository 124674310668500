import { ApiResponse } from './api';
import { FundCategoryOption } from './indexFund';
import { SubscribedPortfolio } from './subscribedPortfolio';

export enum UserPortfolioRequestStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  APPROVED = 'APPROVED',
  WAITING_CAST_APPROVAL = 'WAITING_CAST_APPROVAL',
  APPROVED_BY_CAST = 'APPROVED_BY_CAST',
  CREATED = 'CREATED',
  REJECTED = 'REJECTED',
  FAILED = 'FAILED',
  PARTIAL_APPROVED_BY_CAST = 'PARTIAL_APPROVED_BY_CAST',
  PARTIAL_APPROVED = 'PARTIAL_APPROVED',
  CANCELED = 'CANCELED',
}

export enum UserPortfolioRequestType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  REDEMPTION = 'REDEMPTION',
  UPGRADE = 'UPGRADE',
}

export interface UserPortfolioRequestTypeOption {
  id: number;
  name: UserPortfolioRequestType;
}

export interface UserPortfolioRequestStatusOption {
  id: number;
  name: UserPortfolioRequestStatus;
}

export type UserPortfolio = {
  id: number;
  subscribedPortfolio?: SubscribedPortfolio;
  cacheAllocationPercentage: number;
  cacheAllocationValue: number;
  fundAmount: number;
  userId: number;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type UserPortfolioRequestChanges = IAnyObject & {
  amount?: number;
  redeemAll?: boolean;
  categoryAmount?: number;
  reason?: string;
};

export type UserPortfolioRequest = {
  id: number;
  type: UserPortfolioRequestTypeOption;
  fundCategory: FundCategoryOption;
  status: UserPortfolioRequestStatusOption;
  userPortfolio?: UserPortfolio;
  changes: UserPortfolioRequestChanges;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchUserPortfolioRequestResponse = ApiResponse<UserPortfolioRequest>;
export type FetchUserPortfolioRequestsResponse = ApiResponse<{
  items: UserPortfolioRequest[];
  totalItems: number;
}>;
export type FetchUserPortfolioRequestStatusOptionsResponse = ApiResponse<UserPortfolioRequestStatusOption[]>;

export type UpdateUserPortfolioRequestStatusRequest = Pick<UserPortfolioRequest, 'id'> & {
  status: UserPortfolioRequestStatus;
};
export type UpdateUserPortfolioRequestStatusResponse = ApiResponse<{ message: string }>;

export type SaveUserPortfolioRequestRequest = Omit<
  UserPortfolioRequest,
  'id' | 'status' | 'fundCategory' | 'type' | 'userPortfolio' | 'createdAt' | 'updatedAt'
> & {
  fundCategory: number;
  status: number;
  type: number;
};
export type CreateUserPortfolioRequestResponse = ApiResponse<UserPortfolioRequest>;

export type UpdateUserPortfolioRequestRequest = Omit<
  UserPortfolioRequest,
  'status' | 'fundCategory' | 'type' | 'currency' | 'userPortfolio' | 'createdAt' | 'updatedAt'
> & {
  status: number;
  fundCategory: number;
  type: number;
};
export type UpdateUserPortfolioRequestResponse = ApiResponse<UserPortfolioRequest>;
