import i18n from 'i18next';
import moment from 'moment';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const initialize = (onLoaded: (isLoaded: boolean) => void) =>
  i18n
    // Enables the i18next backend
    .use(Backend)
    // Enable automatic language detection
    .use(LanguageDetector)
    // Enables the hook initialization module
    .use(initReactI18next)
    .init(
      {
        lng: localStorage.getItem('i18nextLng') || 'en',
        // Standard language used
        fallbackLng: 'en',
        debug: false,
        // Detects and caches a cookie from the language provided
        detection: {
          order: ['queryString', 'cookie'],
          caches: ['cookie'],
        },
        interpolation: {
          escapeValue: false,
          // eslint-disable-next-line
          format: function (value, format, lng) {
            if (format === 'uppercase') {
              return value.toUpperCase();
            }
            if (value instanceof Date) {
              return moment(value)
                .locale(lng || 'en')
                .format(format);
            }
            return value;
          },
        },
        react: {
          useSuspense: false,
        },
      },
      () => {
        onLoaded(true);
      },
    );

export default initialize;
