import React from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useSearchParamsState } from '../../hooks';
import { appConfigSelector } from '../../redux/modules/appConfig/appConfig.selectors';
import { UserTab } from './UserTab';
import { PortfolioTab } from './PortfolioTab';

const mapTabParamToValue: { [prop: string]: string } = {
  user: '1',
  portfolio: '2',
  default: '1',
};
const mapTabValueToTabParam: { [prop: string]: string } = {
  1: 'user',
  2: 'portfolio',
};

export function Analytics() {
  const [selectedTab, setSelectedTab] = useSearchParamsState('tab', 'default');

  const { fetchStatus, updateStatus } = useSelector(appConfigSelector);

  const isLoading = fetchStatus === fetchStatuses.pending || updateStatus === fetchStatuses.pending;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(mapTabValueToTabParam[newValue]);
  };

  return (
    <Container component="main" maxWidth="xl">
      <TabContext value={mapTabParamToValue[selectedTab]}>
        <Box>
          <TabList
            value={mapTabParamToValue[selectedTab]}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="Analytics tabs"
          >
            <Tab
              icon={<ContactPageIcon />}
              iconPosition="start"
              label="User"
              value="1"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<SnippetFolderIcon />}
              iconPosition="start"
              label="Portfolio"
              value="2"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
          </TabList>
        </Box>
        <TabPanel value="2">
          <PortfolioTab />
        </TabPanel>
        <TabPanel value="1">
          <UserTab />
        </TabPanel>
      </TabContext>
    </Container>
  );
}
