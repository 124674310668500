import { PaginatedRequest } from '../types/api';
import {
  CreateProductTypeRequest,
  CreateProductTypeResponse,
  FetchProductTypeFileTypeOptionsResponse,
  FetchProductTypeResponse,
  FetchProductTypesResponse,
  UpdateProductTypeRequest,
  UpdateProductTypeResponse,
} from '../types/productType';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class ProductTypeService extends ApiService {
  pathname = 'admin/product-type';

  fetchProductTypes = async (data: PaginatedRequest) =>
    this.get<FetchProductTypesResponse>(`${this.pathname}?${toQueryParams(data)}`);

  fetchProductType = async (id: number) => this.get<FetchProductTypeResponse>(`${this.pathname}/${id}`);

  deleteProductType = async (id: number) => this.delete<FetchProductTypeResponse>(`${this.pathname}/${id}`);

  createProductType = async (productType: CreateProductTypeRequest) =>
    this.post<CreateProductTypeResponse, CreateProductTypeRequest>(`${this.pathname}`, productType);

  updateProductType = async ({ id, ...productType }: UpdateProductTypeRequest) =>
    this.put<UpdateProductTypeResponse, Omit<UpdateProductTypeRequest, 'id'>>(`${this.pathname}/${id}`, productType);

  fetchProductTypeFileTypeOptions = async () =>
    this.get<FetchProductTypeFileTypeOptionsResponse>(`${this.pathname}/product-type-file-type/options`);
}

export const productTypeService = new ProductTypeService();
