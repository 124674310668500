import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { GridCellExpand, Table } from '../../../../components/Table';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { fetchUserBankTransactionsLogs } from '../../../../redux/modules/userBank/userBank.actions';
import { userBankSelector } from '../../../../redux/modules/userBank/userBank.selectors';

interface TransactionsLogsTableProps {
  userBankId: number;
}

export function TransactionsLogsTable({ userBankId }: TransactionsLogsTableProps) {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id' },
    {
      field: 'action',
      headerName: 'Action',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'actorType',
      headerName: 'Actor Type',
      width: 150,
    },
    {
      field: 'new',
      headerName: 'New',
      flex: 1,
      valueFormatter: (params) => JSON.stringify(params.value, null, 2) || '',
      renderCell: (params: GridRenderCellParams) => (
        <GridCellExpand value={JSON.stringify(params.value, null, 2) || ''} width={params.colDef.computedWidth} />
      ),
    },
    {
      field: 'old',
      headerName: 'Old',
      flex: 1,
      valueFormatter: (params) => JSON.stringify(params.value, null, 2) || '',
      renderCell: (params: GridRenderCellParams) => (
        <GridCellExpand value={JSON.stringify(params.value, null, 2) || ''} width={params.colDef.computedWidth} />
      ),
    },
    {
      field: 'userBankTransaction.type',
      headerName: 'Type',
      flex: 0,
      width: 0,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: false,
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      sortable: false,
      type: 'date',
      width: 100,
    },
  ]);
  const { totalUserBankTransactionsLogs, userBankTransactionsLogs, fetchUserBankTransactionsLogsStatus } =
    useSelector(userBankSelector);

  return (
    <Table
      fetchItems={fetchUserBankTransactionsLogs}
      fetchItemsCustomParams={{ userBankId }}
      rows={userBankTransactionsLogs}
      columns={columns.current}
      loading={fetchUserBankTransactionsLogsStatus === fetchStatuses.pending}
      rowCount={totalUserBankTransactionsLogs}
      disableColumnFilter
      disableColumnMenu
      density="compact"
      initialColumnVisibilityModel={{ id: false }}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      initialFilterModel={{
        items: [{ field: 'userBankTransaction.type', operator: '=', value: 'VIRTUAL' }],
      }}
      requiredFilters={[{ field: 'userBankTransaction.type', operator: '=', value: 'VIRTUAL' }]}
      wrapperHeight={300}
      toolbarExportProps={{
        csvOptions: { fileName: `user-bank-${userBankId}-transactions-logs-${Date.now()}` },
        excelOptions: { fileName: `user-bank-${userBankId}-transactions-logs-${Date.now()}` },
      }}
    />
  );
}
