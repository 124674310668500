import { ApiResponse } from './api';

enum OrderSide {
  BUY = 'buy',
  SELL = 'sell',
}

enum OrderStep {
  INITIATED = 'INITIATED',
  TRANSFER_CREATED = 'TRANSFER_CREATED',
  TRANSFER_COMPLETED = 'TRANSFER_COMPLETED',
  DONE = 'DONE',
}

export enum JadwaTransferStatus {
  QUEUED = 'QUEUED', // Transfer is in queue to be processed
  APPROVAL_PENDING = 'APPROVAL_PENDING', // Transfer is pending approval
  PENDING = 'PENDING', // Transfer is pending processing
  SENT_TO_CLEARING = 'SENT_TO_CLEARING', // Transfer is being processed by the clearing firm
  REJECTED = 'REJECTED', // Transfer is rejected
  CANCELED = 'CANCELED', // Client initiated transfer cancellation
  APPROVED = 'APPROVED', // Transfer is approved
  COMPLETE = 'COMPLETE', // Transfer is completed
  RETURNED = 'RETURNED', // The bank issued an ACH return for the transfer
}

enum TransferDirection {
  INCOMING = 'INCOMING', // Funds incoming to user’s account (deposit)
  OUTGOING = 'OUTGOING', // Funds outgoing from user’s account (withdrawal)
}

export interface IJadwaTransfer {
  id: number;
  anbTransferId: null;
  fxRate: number;
  realFxRate: number;
  amount: number;
  originalAmount: number;
  actualAmount: number;
  currency: string;
  direction: TransferDirection;
  status: JadwaTransferStatus;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export interface IJadwaOrder {
  id: number;
  fundOrderId: number;
  orderId: string;
  side: OrderSide;
  step: OrderStep;
  error: string | null;
  transfers: IJadwaTransfer[];
  createdAt: Date | string;
  updatedAt: Date | string;
}

export type FetchJadwaOrdersByMadkholOrderIdResponse = ApiResponse<IJadwaOrder[]>;
export type UpdateJadwaTransferRequest = { id: number; status: string; amount: number };
export type UpdateJadwaTransferResponse = ApiResponse<IJadwaOrder>;

export type TriggerJadwaTransferRequest = { type: 'SUBSCRIPTION' | 'REDEMPTION' };
export type TriggerJadwaTransferResponse = ApiResponse<unknown>;

export type FetchJadwaTransfersResponse = { items: IJadwaTransfer[]; totalItems: number };
