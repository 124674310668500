import Switch from '@mui/material/Switch';
import { SerializedError, AsyncThunk } from '@reduxjs/toolkit';
import React, { SyntheticEvent, useEffect } from 'react';
import { FieldArray, FormikErrors, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import _snakeCase from 'lodash/snakeCase';
import SaveIcon from '@mui/icons-material/Save';
import { Autocomplete, FormControlLabel, TextFieldProps } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import { DecimalNumberField } from '../../components/DecimalNumberField';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { configsFormFields } from '../../forms/fields/formFields';
import { configsFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import { isDispatchApiSuccess } from '../../utils/api';
import { parseJSON } from '../../utils/json';

type ConfigForm = {
  keyName: string;
  value: string;
};

type ConfigItem<K = string> = {
  key: K;
  value: string;
};

interface SettingsFormProps<CK extends string = string> {
  title: string;
  options: CK[];
  fetchStatus: fetchStatuses;
  error: SerializedError | null;
  configs: ConfigItem<CK>[];
  fetchConfigs: () => void;
  updateConfigs: AsyncThunk<any, ConfigItem[], any>;
  configKeyToValueType: Record<CK, 'number' | 'text' | 'boolean' | 'boolnumber'>;
  getOptionLabel: (option: string) => string;
  getOptionType: (option: string) => string;
  getConfigKey: (key: string, index: number) => string;
}

export const SettingsForm = React.memo(<CK extends string = string>(props: SettingsFormProps<CK>) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const isLoading = props.fetchStatus === fetchStatuses.pending;

  const formik = useFormik({
    initialValues: { [configsFormFields.configs]: [] as ConfigForm[] },
    validationSchema: configsFormSchema,
    onSubmit: async (formValues) => {
      const values = formValues.configs.map(({ keyName, value }) => ({
        key: keyName,
        value: value ? `${value}` : value,
      }));

      const res = await dispatch(props.updateConfigs(values));

      if (isDispatchApiSuccess(res)) {
        props.fetchConfigs();
        enqueueSnackbar('Configs successfully updated!', { variant: 'success' });
      } else {
        enqueueSnackbar('Configs update failed!', { variant: 'error' });
      }
    },
  });
  const { handleSubmit, values, handleChange, setFieldValue, touched, errors, resetForm } = formik;

  const onResetForm = () => {
    if (props.configs) {
      resetForm({
        values: {
          [configsFormFields.configs]: props.configs.map(({ key, value }) => ({ keyName: key, value })),
        },
      });
    } else {
      resetForm();
    }
  };

  useEffect(() => {
    props.fetchConfigs();
  }, []);

  useEffect(() => {
    onResetForm();
  }, [props.configs]);

  const onAutocompleteChange = (_: SyntheticEvent, newValue: string | null, index: number) => {
    setFieldValue(`configs.${index}.keyName`, newValue ? _snakeCase(newValue) : newValue);
  };

  const renderConfigItem = (item: ConfigForm, index: number) => {
    const optionType = props.getOptionType(item.keyName);

    if (isLoading) {
      return <Skeleton variant="rectangular" width="100%" height={80} />;
    }

    if (optionType === 'boolean') {
      const checked = Boolean(parseJSON(item.value || 'false'));

      return (
        <FormControlLabel
          label={checked ? 'Yes' : 'No'}
          labelPlacement="start"
          componentsProps={{
            typography: { textTransform: 'capitalize' },
          }}
          id={`configs.${index}.value`}
          name={`configs.${index}.value`}
          control={
            <Switch
              name={`configs.${index}.value`}
              checked={checked}
              onChange={(e, value) =>
                handleChange({
                  target: {
                    name: `configs.${index}.value`,
                    value: `${value}`,
                  },
                })
              }
            />
          }
        />
      );
    }

    if (optionType === 'boolnumber') {
      const checked = parseJSON(item.value) !== null;

      return (
        <Grid container flexDirection="row" alignItems="center">
          <Grid item>
            <FormControlLabel
              label={checked ? 'Enable' : 'Disable'}
              labelPlacement="top"
              componentsProps={{
                typography: { textTransform: 'capitalize' },
              }}
              id={`configs.${index}.value`}
              name={`configs.${index}.value`}
              control={
                <Switch
                  name={`configs.${index}.value`}
                  checked={checked}
                  onChange={(e, value) =>
                    handleChange({
                      target: {
                        name: `configs.${index}.value`,
                        value: value ? '' : null,
                      },
                    })
                  }
                />
              }
            />
          </Grid>

          {checked && (
            <Grid item>
              <DecimalNumberField
                margin="normal"
                required
                fullWidth
                inputProps={{ min: 0.01 }}
                id={`configs.${index}.value`}
                name={`configs.${index}.value`}
                label="Value"
                type="number"
                value={item.value || ''}
                maximumFractionDigits={6}
                onChange={handleChange}
                error={
                  touched.configs?.[index]?.value && !!(errors.configs?.[index] as FormikErrors<ConfigForm>)?.value
                }
                helperText={
                  touched.configs?.[index]?.value && (errors.configs?.[index] as FormikErrors<ConfigForm>)?.value
                }
              />
            </Grid>
          )}
        </Grid>
      );
    }

    return (
      <FormControl
        fullWidth
        margin="none"
        error={touched.configs?.[index]?.value && !!(errors.configs?.[index] as FormikErrors<ConfigForm>)?.value}
      >
        <TextField
          margin="normal"
          required
          fullWidth
          id={`configs.${index}.value`}
          name={`configs.${index}.value`}
          label="Value"
          type={props.getOptionType(item.keyName)}
          value={item.value || ''}
          onChange={handleChange}
          error={touched.configs?.[index]?.value && !!(errors.configs?.[index] as FormikErrors<ConfigForm>)?.value}
          helperText={touched.configs?.[index]?.value && (errors.configs?.[index] as FormikErrors<ConfigForm>)?.value}
        />
      </FormControl>
    );
  };

  return (
    <>
      {props.error && <Alert severity="error">{props.error.message}</Alert>}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
              <Typography color="text.primary" sx={{ mb: 2 }} component="h4" gutterBottom>
                {props.title}
              </Typography>
              <FormikProvider value={formik}>
                <FieldArray
                  name="configs"
                  render={(arrayHelpers) => (
                    <Grid container spacing={2} paddingX={2}>
                      {values[configsFormFields.configs] && values[configsFormFields.configs].length > 0 ? (
                        values[configsFormFields.configs].map((item, index) => (
                          <Grid container item spacing={3} key={props.getConfigKey(item.keyName, index)}>
                            <Grid item xs={12} md={5}>
                              {isLoading ? (
                                <Skeleton variant="rectangular" width="100%" height={80} />
                              ) : (
                                <Autocomplete
                                  title="Key"
                                  freeSolo
                                  options={props.options}
                                  getOptionLabel={props.getOptionLabel}
                                  renderInput={(params: TextFieldProps) => (
                                    <TextField
                                      {...params}
                                      margin="normal"
                                      required
                                      fullWidth
                                      id={`configs.${index}.keyName`}
                                      name={`configs.${index}.keyName`}
                                      label="Key"
                                      error={
                                        touched.configs?.[index]?.keyName &&
                                        !!(errors.configs?.[index] as FormikErrors<ConfigForm>)?.keyName
                                      }
                                      helperText={
                                        touched.configs?.[index]?.keyName &&
                                        (errors.configs?.[index] as FormikErrors<ConfigForm>)?.keyName
                                      }
                                    />
                                  )}
                                  onChange={(_, newValue) => onAutocompleteChange(_, newValue, index)}
                                  value={item.keyName}
                                />
                              )}
                            </Grid>
                            <Grid container item xs={12} md={5} display="flex" alignItems="center">
                              {renderConfigItem(item, index)}
                            </Grid>
                            <Grid container item xs={2} spacing={2} display="flex" alignItems="center">
                              <Grid item>
                                <IconButton
                                  color="primary"
                                  aria-label="Remove config"
                                  size="small"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </Grid>
                              <Grid item>
                                <IconButton
                                  color="primary"
                                  aria-label="Add config"
                                  size="small"
                                  onClick={() => arrayHelpers.insert(index + 1, { keyName: '', value: '' })}
                                >
                                  <AddIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <Box>
                          <Button variant="outlined" onClick={() => arrayHelpers.push({ keyName: '', value: '' })}>
                            Add config
                          </Button>
                        </Box>
                      )}
                    </Grid>
                  )}
                />
                {!!errors.configs && typeof errors.configs === 'string' && (
                  <FormHelperText error>{errors.configs}</FormHelperText>
                )}
              </FormikProvider>
            </Paper>
          </Grid>
        </Grid>
        <Grid>
          <Box m={1} p={1} display="flex" justifyContent="space-between" alignItems="center">
            <Box>{/* Left Action */}</Box>
            <Box>
              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                sx={{ mt: 3, mb: 2, mr: 2 }}
                endIcon={<SaveIcon />}
                loading={isLoading}
                loadingPosition="end"
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        </Grid>
      </form>
    </>
  );
});
