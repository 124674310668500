import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchBenchmarkResponse,
  FetchBenchmarksResponse,
  FetchBenchmarkNavDataResponse,
} from '../../../types/benchmark';
import { importFundChartDataFile } from '../indexFund/indexFund.actions';
import {
  cleanBenchmarks,
  createBenchmark,
  deleteBenchmark,
  fetchBenchmark,
  fetchBenchmarks,
  fetchNavDataByFund,
  importNavDataFile,
  resetEditBenchmark,
  updateBenchmark,
} from './benchmark.actions';
import { BenchmarkState } from './benchmark.types';

const initialState: BenchmarkState = {
  benchmarks: [],
  totalBenchmarks: 0,
  editBenchmark: null,
  benchmarkNavData: [],
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  importNavDataFileStatus: fetchStatuses.idle,
  importFundChartDataFileStatus: fetchStatuses.idle,
  fetchBenchmarkNavDataStatus: fetchStatuses.idle,
  error: null,
};

const BenchmarksSlice = createSlice({
  name: 'Benchmarks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBenchmarks.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchBenchmarks.fulfilled, (state, action: PayloadAction<FetchBenchmarksResponse>) => {
      state.benchmarks = action.payload.data?.items;
      state.totalBenchmarks = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchBenchmarks.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchBenchmark.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchBenchmark.fulfilled, (state, action: PayloadAction<FetchBenchmarkResponse>) => {
      state.editBenchmark = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchBenchmark.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateBenchmark.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateBenchmark.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateBenchmark.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createBenchmark.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(createBenchmark.fulfilled, (state, action: PayloadAction<FetchBenchmarkResponse>) => {
      state.editBenchmark = action.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createBenchmark.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteBenchmark.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteBenchmark.fulfilled, (state) => {
      state.editBenchmark = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteBenchmark.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(importNavDataFile.pending, (state) => {
      state.error = null;
      state.importNavDataFileStatus = fetchStatuses.pending;
    });
    builder.addCase(importNavDataFile.fulfilled, (state) => {
      state.importNavDataFileStatus = fetchStatuses.success;
    });
    builder.addCase(importNavDataFile.rejected, (state, action) => {
      state.importNavDataFileStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(importFundChartDataFile.pending, (state) => {
      state.error = null;
      state.importFundChartDataFileStatus = fetchStatuses.pending;
    });
    builder.addCase(importFundChartDataFile.fulfilled, (state) => {
      state.importFundChartDataFileStatus = fetchStatuses.success;
    });
    builder.addCase(importFundChartDataFile.rejected, (state, action) => {
      state.importFundChartDataFileStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchNavDataByFund.pending, (state) => {
      state.error = null;
      state.fetchBenchmarkNavDataStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchNavDataByFund.fulfilled, (state, action: PayloadAction<FetchBenchmarkNavDataResponse>) => {
      state.benchmarkNavData = action.payload.data;
      state.fetchBenchmarkNavDataStatus = fetchStatuses.success;
    });
    builder.addCase(fetchNavDataByFund.rejected, (state, action) => {
      state.fetchBenchmarkNavDataStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditBenchmark.type, (state) => {
      state.editBenchmark = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanBenchmarks.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const benchmarkReducer = BenchmarksSlice.reducer;
