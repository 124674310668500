import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { PortfolioOrderStatus } from '../../types/portfolioOrder';

export const portfolioOrderStatusToInfo: {
  [prop in PortfolioOrderStatus]: {
    color: 'error' | 'info' | 'success' | 'warning';
    icon: React.ElementType;
    menuAction: string;
  };
} = {
  [PortfolioOrderStatus.CREATED]: {
    color: 'info',
    menuAction: 'Change to Created',
    icon: InfoIcon,
  },
  [PortfolioOrderStatus.IN_PROGRESS]: {
    color: 'info',
    menuAction: 'Change to In Progress',
    icon: InfoIcon,
  },
  [PortfolioOrderStatus.CANCELLED]: {
    color: 'error',
    menuAction: 'Cancel',
    icon: CheckCircleIcon,
  },
  [PortfolioOrderStatus.PARTIALLY_PUSHED]: {
    color: 'warning',
    menuAction: 'Change to waiting cast approval',
    icon: WarningIcon,
  },
  [PortfolioOrderStatus.PUSHED]: {
    color: 'warning',
    menuAction: 'Change to approved by cast',
    icon: WarningIcon,
  },
  [PortfolioOrderStatus.PARTIALLY_EXECUTED]: {
    color: 'success',
    menuAction: 'Partially execute',
    icon: CheckCircleIcon,
  },
  [PortfolioOrderStatus.EXECUTED]: {
    color: 'success',
    menuAction: 'Change to executed',
    icon: CheckCircleIcon,
  },
};
