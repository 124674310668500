import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import DialogContent from '@mui/material/DialogContent';
import { LoadingButton } from '@mui/lab';
import EmailIcon from '@mui/icons-material/Email';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { useSnackbar } from 'notistack';
import TextField from '@mui/material/TextField';
import { WithdrawRequest, WithdrawStatus } from '../../types/payment';
import { withdrawRequestRejectFormFields } from '../../forms/fields/formFields';
import { withdrawRequestRejectionFormSchema } from '../../forms/validationSchema/formSchema';
import { useUpdateWithdrawStatusMutation } from '../../redux/features/withdraw/withdraw.apiSlice';

interface SystemUnitsReconcileDialogProps {
  withdrawRequest: WithdrawRequest | undefined;
  onClose: () => void;
}

export function WithdrawRequestCancelDialog({ withdrawRequest, onClose }: SystemUnitsReconcileDialogProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [updateWithdrawStatus, { isLoading }] = useUpdateWithdrawStatusMutation();
  const { handleSubmit, values, handleChange, touched, errors, submitForm, setValues, resetForm } = useFormik({
    initialValues: {
      [withdrawRequestRejectFormFields.reviewComment]: '',
    },
    validationSchema: withdrawRequestRejectionFormSchema,
    onSubmit: async ({ reviewComment }) => {
      try {
        if (withdrawRequest?.id) {
          await updateWithdrawStatus({
            id: withdrawRequest.id,
            reviewComment,
            status: WithdrawStatus.REJECTED,
          }).unwrap();
          enqueueSnackbar('Withdraw rejected.', { variant: 'success' });
        }
      } catch (err) {
        enqueueSnackbar('Withdraw rejection error!', {
          variant: 'error',
        });
      }
      onClose();
    },
  });

  useEffect(() => {
    resetForm();
  }, [withdrawRequest]);

  return (
    <Dialog open={!!withdrawRequest}>
      <DialogTitle>Rejection reason</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            type="text"
            id={withdrawRequestRejectFormFields.reviewComment}
            name={withdrawRequestRejectFormFields.reviewComment}
            label="Comment"
            value={values[withdrawRequestRejectFormFields.reviewComment]}
            onChange={handleChange}
            error={touched.reviewComment && !!errors.reviewComment}
            helperText={touched.reviewComment && errors.reviewComment}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<EmailIcon />}
          loading={isLoading}
          loadingPosition="end"
          onClick={submitForm}
        >
          Send
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose()}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
