import { PaginatedRequest } from '../types/api';
import {
  CreateFundAssetClassRequest,
  CreateFundAssetClassResponse,
  FetchFundAssetClassResponse,
  FetchFundAssetClassesAutocompleteResponse,
  FetchFundAssetClassesResponse,
  UpdateFundAssetClassRequest,
  UpdateFundAssetClassResponse,
} from '../types/fundAssetClass';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class FundAssetClassService extends ApiService {
  pathname = 'admin';

  fetchFundAssetClasses = async (data: PaginatedRequest) =>
    this.get<FetchFundAssetClassesResponse>(`${this.pathname}/fund-asset-class?${toQueryParams(data)}`);

  fetchFundAssetClassesAutocomplete = async (term: string) =>
    this.get<FetchFundAssetClassesAutocompleteResponse>(`${this.pathname}/fund-asset-class/autocomplete?term=${term}`);

  fetchFundAssetClass = async (id: number) =>
    this.get<FetchFundAssetClassResponse>(`${this.pathname}/fund-asset-class/${id}`);

  deleteFundAssetClass = async (id: number) =>
    this.delete<FetchFundAssetClassResponse>(`${this.pathname}/fund-asset-class/${id}`);

  createFundAssetClass = async (assetClass: CreateFundAssetClassRequest) =>
    this.post<CreateFundAssetClassResponse, CreateFundAssetClassRequest>(
      `${this.pathname}/fund-asset-class`,
      assetClass,
    );

  updateFundAssetClass = async ({ id, ...assetClass }: UpdateFundAssetClassRequest) =>
    this.put<UpdateFundAssetClassResponse, Omit<UpdateFundAssetClassRequest, 'id'>>(
      `${this.pathname}/fund-asset-class/${id}`,
      assetClass,
    );
}

export const fundAssetClassService = new FundAssetClassService();
