import { PaginatedRequest } from '../types/api';
import {
  CreateFundValuationFrequencyRequest,
  CreateFundValuationFrequencyResponse,
  FetchFundValuationFrequencyResponse,
  FetchFundValuationFrequenciesAutocompleteResponse,
  FetchFundValuationFrequenciesResponse,
  UpdateFundValuationFrequencyRequest,
  UpdateFundValuationFrequencyResponse,
} from '../types/fundValuationFrequency';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class FundValuationFrequencyService extends ApiService {
  pathname = 'admin';

  fetchFundValuationFrequencies = async (data: PaginatedRequest) =>
    this.get<FetchFundValuationFrequenciesResponse>(`${this.pathname}/fund-valuation-frequency?${toQueryParams(data)}`);

  fetchFundValuationFrequenciesAutocomplete = async (term: string) =>
    this.get<FetchFundValuationFrequenciesAutocompleteResponse>(
      `${this.pathname}/fund-valuation-frequency/autocomplete?term=${term}`,
    );

  fetchFundValuationFrequency = async (id: number) =>
    this.get<FetchFundValuationFrequencyResponse>(`${this.pathname}/fund-valuation-frequency/${id}`);

  deleteFundValuationFrequency = async (id: number) =>
    this.delete<FetchFundValuationFrequencyResponse>(`${this.pathname}/fund-valuation-frequency/${id}`);

  createFundValuationFrequency = async (fundValuationFrequency: CreateFundValuationFrequencyRequest) =>
    this.post<CreateFundValuationFrequencyResponse, CreateFundValuationFrequencyRequest>(
      `${this.pathname}/fund-valuation-frequency`,
      fundValuationFrequency,
    );

  updateFundValuationFrequency = async ({ id, ...fundValuationFrequency }: UpdateFundValuationFrequencyRequest) =>
    this.put<UpdateFundValuationFrequencyResponse, Omit<UpdateFundValuationFrequencyRequest, 'id'>>(
      `${this.pathname}/fund-valuation-frequency/${id}`,
      fundValuationFrequency,
    );
}

export const fundValuationFrequencyService = new FundValuationFrequencyService();
