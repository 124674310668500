import React from 'react';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { nonSuperAdminRoleSelector } from '../../../redux/features/role/role.selectors';
import { ActionType } from '../../../types/action';
import { PermissionsTableActionRowRoleCell } from './PermissionsTableActionRowRoleCell';
import { StyledTableCell } from './PermissionsTableElements';

type PermissionsTableActionRowProps = {
  action: ActionType;
  resource: string;
  checked?: Record<string, boolean>;
  onChange: (data: { resource: string; action: string; roleName: string; checked: boolean }) => void;
};

export const PermissionsTableActionRow: React.FC<PermissionsTableActionRowProps> = React.memo((props) => {
  const { action, resource, onChange, checked } = props;

  const roles = useSelector(nonSuperAdminRoleSelector);

  return (
    <TableRow>
      <StyledTableCell className="sticky" component="th" variant="head" scope="row">
        <Typography sx={{ pl: 2 }}>{action}</Typography>
      </StyledTableCell>
      {roles.map((role) => (
        <PermissionsTableActionRowRoleCell
          key={`permissions-table-action-row-role-cell-${resource}-${action}-${role.name}`}
          resource={resource}
          action={action}
          roleName={role.name}
          checked={checked?.[role.name] || false}
          onChange={onChange}
        />
      ))}
    </TableRow>
  );
});
