import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { DownwardIcon, NoopIcon, UpwardIcon } from '../../components/Icon';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { fetchFeeAccountDetailsHistory } from '../../redux/modules/feeAccount/feeAccount.actions';
import { feeAccountSelector } from '../../redux/modules/feeAccount/feeAccount.selectors';
import { round2Format } from '../../utils/number';
import { GridCellExpand, Table } from '../../components/Table';

interface FeeAccountDetailsHistoryTableProps {
  feeAccountDetailsId: number;
}

export function FeeAccountDetailsHistoryTable({ feeAccountDetailsId }: FeeAccountDetailsHistoryTableProps) {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id', filterable: false },
    { field: 'satelliteAccountDetailsId', headerName: 'Fee Account Details Id', type: 'number' },
    {
      field: 'prevBalance',
      headerName: 'Prev Balance',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'prevHold',
      headerName: 'Prev Hold',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'balance',
      headerName: 'Balance',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => {
        let Icon = NoopIcon;

        if (params.value > 0) {
          Icon = UpwardIcon;
        }

        if (params.value < 0) {
          Icon = DownwardIcon;
        }

        return (
          <>
            <Icon />
            {round2Format(params.value)}
          </>
        );
      },
    },
    {
      field: 'hold',
      headerName: 'Hold',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => {
        let Icon = NoopIcon;

        if (params.value > 0) {
          Icon = UpwardIcon;
        }

        if (params.value < 0) {
          Icon = DownwardIcon;
        }

        return (
          <>
            <Icon />
            {round2Format(params.value)}
          </>
        );
      },
    },
    {
      field: 'newBalance',
      headerName: 'New Balance',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'newHold',
      headerName: 'New Hold',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'metadata',
      headerName: 'Metadata',
      sortable: false,
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <GridCellExpand value={JSON.stringify(params.value, null, 2) || ''} width={params.colDef.computedWidth} />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'dateTime',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const { feeAccountDetailsHistory, totalFeeAccountDetailsHistory, fetchFeeAccountDetailsHistoryStatus } =
    useSelector(feeAccountSelector);

  return (
    <Table
      fetchItems={fetchFeeAccountDetailsHistory}
      rows={feeAccountDetailsHistory}
      columns={columns.current}
      loading={fetchFeeAccountDetailsHistoryStatus === fetchStatuses.pending}
      rowCount={totalFeeAccountDetailsHistory}
      disableRowGrouping
      density="compact"
      initialColumnVisibilityModel={{ id: false, satelliteAccountDetailsId: false, metadata: false }}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      requiredFilters={[{ field: 'satelliteAccountDetailsId', value: `${feeAccountDetailsId}`, operator: 'is', id: 0 }]}
      toolbarExportProps={{
        csvOptions: {
          fileName: `fee-account-details-${feeAccountDetailsId}-history-${new Date().toLocaleString()}`,
        },
        excelOptions: {
          fileName: `fee-account-details-${feeAccountDetailsId}-history-${new Date().toLocaleString()}`,
        },
      }}
      wrapperHeight={300}
    />
  );
}
