import { GridNativeColTypes, GridValueGetterParams } from '@mui/x-data-grid-premium';

export const dateValueGetter = (params: GridValueGetterParams) => params.value && new Date(params.value);

export const mapColumTypeToGetter: {
  [prop in GridNativeColTypes | string]: (params: GridValueGetterParams) => any;
} = {
  date: dateValueGetter,
  dateTime: dateValueGetter,
};
