import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { userPortfolioRequestService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import {
  SaveUserPortfolioRequestRequest,
  UpdateUserPortfolioRequestRequest,
  UpdateUserPortfolioRequestStatusRequest,
} from '../../../types/userPortfolioRequest';

export const fetchUserPortfolioRequests = createAsyncThunk(
  'user-portfolio-request/fetch-all',
  (data: PaginatedRequest & { requestId: string }, { rejectWithValue }) =>
    userPortfolioRequestService.fetchUserPortfolioRequests(data).catch((error) => rejectWithValue(error)),
);

export const fetchUserPortfolioRequest = createAsyncThunk('user-portfolio-request/get', (id: number) =>
  userPortfolioRequestService.fetchUserPortfolioRequest(id),
);

export const deleteUserPortfolioRequest = createAsyncThunk('user-portfolio-request/delete', (id: number) =>
  userPortfolioRequestService.deleteUserPortfolioRequest(id),
);

export const saveUserPortfolioRequest = createAsyncThunk(
  'user-portfolio-request/save',
  (portfolio: SaveUserPortfolioRequestRequest) => userPortfolioRequestService.saveUserPortfolioRequest(portfolio),
);

export const updateUserPortfolioRequest = createAsyncThunk(
  'user-portfolio-request/update',
  (portfolio: UpdateUserPortfolioRequestRequest) => userPortfolioRequestService.updateUserPortfolioRequest(portfolio),
);

export const updateUserPortfolioRequestStatus = createAsyncThunk(
  'user-portfolio-request/update-status',
  (data: UpdateUserPortfolioRequestStatusRequest) => userPortfolioRequestService.updateUserPortfolioRequestStatus(data),
);

export const fetchUserPortfolioRequestStatusOptions = createAsyncThunk(
  'user-portfolio-request/fetch-portfolio-status-options',
  () => userPortfolioRequestService.fetchUserPortfolioRequestStatusOptions(),
);

export const cleanUserPortfolioRequests = createAction('user-portfolio-request/clean');

export const resetEditUserPortfolioRequest = createAction('user-portfolio-request/reset-edit');
