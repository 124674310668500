import * as yup from 'yup';
import { fundValuationDayFormFields } from '../../fields/formFields';

export const fundValuationDayFormSchema = yup.object({
  [fundValuationDayFormFields.nameEn]: yup
    .string()
    .max(150, 'Name En must be at most 150 characters')
    .required('Name En is required')
    .trim(),
  [fundValuationDayFormFields.nameAr]: yup
    .string()
    .max(150, 'Name Ar must be at most 150 characters')
    .required('Name Ar is required')
    .trim(),
  [fundValuationDayFormFields.descriptionEn]: yup
    .string()
    .max(150, 'Description En must be at most 150 characters')
    .required('Description En is required')
    .trim(),
  [fundValuationDayFormFields.descriptionAr]: yup
    .string()
    .max(150, 'Description Ar must be at most 150 characters')
    .required('Description Ar is required')
    .trim(),
  [fundValuationDayFormFields.expression]: yup
    .string()
    .max(150, 'Expression must be at most 150 characters')
    .required('Expression is required')
    .trim(),
});
