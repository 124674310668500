import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchFundManagerResponse,
  FetchFundManagersAutocompleteResponse,
  FetchFundManagersResponse,
} from '../../../types/fundManager';
import {
  fetchFundManager,
  fetchFundManagers,
  resetEditFundManager,
  cleanFundManagers,
  updateFundManager,
  createFundManager,
  deleteFundManager,
  fetchFundManagersAutocomplete,
} from './fundManager.actions';
import { FundManagerState } from './fundManager.types';

const initialState: FundManagerState = {
  fundManagers: [],
  fundManagersAutocomplete: [],
  totalFundManagers: 0,
  editFundManager: null,
  fetchAutocompleteStatus: fetchStatuses.idle,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const fundManagersSlice = createSlice({
  name: 'fundManagers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFundManagers.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchFundManagers.fulfilled, (state, action: PayloadAction<FetchFundManagersResponse>) => {
      state.fundManagers = action.payload.data?.items;
      state.totalFundManagers = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFundManagers.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFundManagersAutocomplete.pending, (state) => {
      state.fetchAutocompleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchFundManagersAutocomplete.fulfilled,
      (state, action: PayloadAction<FetchFundManagersAutocompleteResponse>) => {
        state.fundManagersAutocomplete = action.payload.data;
        state.fetchAutocompleteStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFundManagersAutocomplete.rejected, (state, action) => {
      state.fetchAutocompleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFundManager.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchFundManager.fulfilled, (state, action: PayloadAction<FetchFundManagerResponse>) => {
      state.editFundManager = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFundManager.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateFundManager.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateFundManager.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateFundManager.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createFundManager.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(createFundManager.fulfilled, (state, action: PayloadAction<FetchFundManagerResponse>) => {
      state.editFundManager = action.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createFundManager.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteFundManager.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteFundManager.fulfilled, (state) => {
      state.editFundManager = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteFundManager.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditFundManager.type, (state) => {
      state.editFundManager = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanFundManagers.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const fundManagerReducer = fundManagersSlice.reducer;
