import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { FakeButtonLink } from '../../components/FakeLink';
import { Table } from '../../components/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { mapUserIdToStatementActor } from '../../constants/payment';
import { fetchUserBanks } from '../../redux/modules/userBank/userBank.actions';
import { userBankSelector } from '../../redux/modules/userBank/userBank.selectors';
import { locations } from '../../routes/locations';
import { BankType } from '../../types/user';
import { round2Format } from '../../utils/number';

export function UserBanksTab() {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id' },
    {
      field: 'userId',
      headerName: 'User Id',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        const staticStatementActor = mapUserIdToStatementActor[params.value];

        if (staticStatementActor) {
          return staticStatementActor;
        }

        return (
          <Tooltip title={params.value} followCursor>
            <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.user(params.value)}>
              {params.value}
            </FakeButtonLink>
          </Tooltip>
        );
      },
    },
    {
      field: 'status.statusCode',
      headerName: 'Status',
      type: 'string',
      width: 120,
      valueGetter: (params) => params.row?.status?.statusCode,
    },
    {
      field: 'bankType.type',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: Object.values(BankType),
      width: 120,
      valueGetter: (params) => params.row?.bankType?.type,
    },
    {
      field: 'userBankDetails.amount',
      headerName: 'Amount',
      width: 100,
      valueGetter: (params) => params.row?.userBankDetails?.amount,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'userBankDetails.hold',
      headerName: 'Hold',
      width: 100,
      valueGetter: (params) => params.row?.userBankDetails?.hold,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'userBankDetails.cashAllocationValue',
      headerName: 'Cash Allocation Value',
      width: 100,
      valueGetter: (params) => params.row?.userBankDetails?.cashAllocationValue,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'userBankDetails.iban',
      headerName: 'Iban',
      type: 'string',
      flex: 1,
      valueGetter: (params) => params.row?.userBankDetails?.iban,
    },
    { field: 'swiftCode', headerName: 'Swift Code', type: 'string' },
    { field: 'isPrimaryAccount', headerName: 'Primary', type: 'boolean' },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: false,
      type: 'dateTime',
      width: 170,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'dateTime',
      width: 170,
    },
  ]);
  const { totalUserBanks, userBanks, fetchStatus } = useSelector(userBankSelector);

  return (
    <Table
      fetchItems={fetchUserBanks}
      rows={userBanks}
      columns={columns.current}
      loading={fetchStatus === fetchStatuses.pending}
      rowCount={totalUserBanks}
      initialColumnVisibilityModel={{
        id: false,
        'status.statusCode': false,
        swiftCode: false,
        isPrimaryAccount: false,
      }}
      initialSortModel={[{ field: 'updatedAt', sort: 'desc' }]}
      toolbarExportProps={{
        excelOptions: { fileName: `user-banks-${Date.now()}` },
        csvOptions: { fileName: `user-banks-${Date.now()}` },
      }}
    />
  );
}
