import { ApiResponse } from './api';

export enum HyperPaySessionStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
  EXPIRED = 'expired',
}

export enum HyperPaySessionType {
  RECURRING = 'RECURRING',
  ONE_TIME = 'ONE_TIME',
}

export type HyperPaySession = {
  id: number;
  requestId: string;
  transactionId: string;
  userId: number;
  amount: number;
  currency: string;
  status: HyperPaySessionStatus;
  type: HyperPaySessionType;
  sessionId: string;
  descriptor: string;
  createdAt: Date;
  updatedAt: Date;
};

export type FetchHyperPaySessionsResponse = ApiResponse<{ items: HyperPaySession[]; totalItems: number }>;
