import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchAlpacaConfigResponse, FetchAlpacaConfigsResponse } from '../../../types/alpacaConfig';
import {
  fetchAlpacaConfig,
  fetchAlpacaConfigs,
  resetEditAlpacaConfig,
  cleanAlpacaConfigs,
  updateAlpacaConfig,
  createAlpacaConfigItem,
  deleteAlpacaConfig,
} from './alpacaConfig.actions';
import { AlpacaConfigState } from './alpacaConfig.types';

const initialState: AlpacaConfigState = {
  alpacaConfigs: [],
  editAlpacaConfig: null,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const alpacaConfigsSlice = createSlice({
  name: 'alpacaConfigs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAlpacaConfigs.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchAlpacaConfigs.fulfilled, (state, alpacaConfig: PayloadAction<FetchAlpacaConfigsResponse>) => {
      state.alpacaConfigs = alpacaConfig.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchAlpacaConfigs.rejected, (state, alpacaConfig) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = alpacaConfig.error;
    });

    builder.addCase(fetchAlpacaConfig.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchAlpacaConfig.fulfilled, (state, alpacaConfig: PayloadAction<FetchAlpacaConfigResponse>) => {
      state.editAlpacaConfig = alpacaConfig.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchAlpacaConfig.rejected, (state, alpacaConfig) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = alpacaConfig.error;
    });

    builder.addCase(updateAlpacaConfig.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateAlpacaConfig.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateAlpacaConfig.rejected, (state, alpacaConfig) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = alpacaConfig.error;
    });

    builder.addCase(createAlpacaConfigItem.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(
      createAlpacaConfigItem.fulfilled,
      (state, alpacaConfig: PayloadAction<FetchAlpacaConfigResponse>) => {
        state.editAlpacaConfig = alpacaConfig.payload.data;
        state.createStatus = fetchStatuses.success;
      },
    );
    builder.addCase(createAlpacaConfigItem.rejected, (state, alpacaConfig) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = alpacaConfig.error;
    });

    builder.addCase(deleteAlpacaConfig.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteAlpacaConfig.fulfilled, (state) => {
      state.editAlpacaConfig = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteAlpacaConfig.rejected, (state, alpacaConfig) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = alpacaConfig.error;
    });

    builder.addCase(resetEditAlpacaConfig.type, (state) => {
      state.editAlpacaConfig = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanAlpacaConfigs.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const alpacaConfigReducer = alpacaConfigsSlice.reducer;
