// eslint-disable-next-line import/no-unresolved
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react';
import { captureException } from '@sentry/react';
import { Mutex } from 'async-mutex';
import { ApiTagType } from '../../constants/apiTagType';
import { httpStatusCodes } from '../../constants/httpStatusCodes';
import { setCredentials, logOut } from '../../redux/features/auth/auth.slice';
import { ApiResponse } from '../../types/api';
import { LoginResponse } from '../../types/auth';

const refreshSkipPath = ['auth/auth/login', 'auth/auth/forgot-password', 'auth/auth/restore-password'];

interface CustomFetchBaseQueryError {
  status: number;
  data?: {
    code: number;
    message: string;
    status: 'failure' | 'success';
    traceId?: string;
  };
}

type CustomBaseQueryFn = BaseQueryFn<
  string | FetchArgs, // Args
  unknown, // Result
  CustomFetchBaseQueryError, // Error
  { todo?: boolean }, // DefinitionExtraOptions
  FetchBaseQueryMeta // Meta
>;

// create a new mutex
const mutex = new Mutex();

const createBaseQuery = (baseUrl: string = process.env.REACT_APP_BASE_API_URL): CustomBaseQueryFn =>
  fetchBaseQuery({
    baseUrl,
    credentials: 'include',
    prepareHeaders: (headers) => {
      const accessToken = localStorage.getItem('accessToken');

      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`);
      }

      return headers;
    },
  }) as CustomBaseQueryFn;

const appBaseQuery = createBaseQuery();
const authBaseQuery = createBaseQuery(process.env.REACT_APP_AUTH_API_URI || process.env.REACT_APP_BASE_API_URL);
const mediaBaseQuery = createBaseQuery(process.env.REACT_APP_MEDIA_API_URI || process.env.REACT_APP_BASE_API_URL);

const handleResult = (result: QueryReturnValue<unknown, CustomFetchBaseQueryError, FetchBaseQueryMeta>) => {
  if (result?.error?.status === httpStatusCodes.forbidden) {
    console.error('[baseQueryWithReAuth error 403]', result.error);

    captureException(result.error);
  }

  if (result.data && (result?.data as ApiResponse)?.status === 'success') {
    return { ...result, data: (result.data as ApiResponse).data };
  }

  if (result?.error?.data) {
    return { ...result, data: result.error.data };
  }

  return result;
};

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();

  let result = await appBaseQuery(args, api, extraOptions);

  const url = typeof args === 'string' ? args : args.url;

  if (result?.error?.status === httpStatusCodes.unauthorized && !refreshSkipPath.includes(url)) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        // send refresh token to get new access token
        const refreshResult = await authBaseQuery(
          { url: '/auth/auth/refresh-token', method: 'POST' },
          api,
          extraOptions,
        );

        console.log('[baseQueryWithReAuth refresh result]', refreshResult);

        if (refreshResult?.data) {
          // store the new token
          api.dispatch(setCredentials(refreshResult.data as LoginResponse));
          // retry the original query with new access token
          result = await appBaseQuery(args, api, extraOptions);
        } else {
          api.dispatch(logOut());
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();

      result = await appBaseQuery(args, api, extraOptions);
    }
  }

  return handleResult(result);
};

const baseQueryDecorator = (baseQueryFn: CustomBaseQueryFn) => async (args, api, extraOptions) => {
  const result = await baseQueryFn(args, api, extraOptions);

  return handleResult(result);
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({}),
  tagTypes: Object.values(ApiTagType),
  // global configuration for the api
  refetchOnReconnect: true,
});

export const authApiSlice = createApi({
  reducerPath: 'authApi',
  baseQuery: baseQueryDecorator(authBaseQuery),
  endpoints: (builder) => ({}),
  tagTypes: [],
  // global configuration for the api
  refetchOnReconnect: true,
});

export const mediaApiSlice = createApi({
  reducerPath: 'mediaApi',
  baseQuery: baseQueryDecorator(mediaBaseQuery),
  endpoints: (builder) => ({}),
  tagTypes: [],
  // global configuration for the api
  refetchOnReconnect: true,
});
