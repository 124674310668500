import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';

export const StyledTableCell = styled(TableCell)(() => ({
  minWidth: 200,
  '&.sticky': {
    zIndex: 2,
    left: 0,
    minWidth: 250,
    position: 'sticky',
    backgroundColor: 'white',
    borderRight: '2px solid black',
  },
  '&.action': {
    zIndex: 3,
  },
  '&.resource': {
    backgroundColor: 'lightgray',
  },
}));

export const StyledTable = styled(Table)(() => ({
  overflow: 'auto',
}));
