import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid-premium';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FakeButtonLink } from '../../components/FakeLink';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { feeCalculationStatusToInfo } from '../../constants/tables/feeCalculation';
import { fetchFeeCalculationsByBatchId } from '../../redux/modules/feeBatch/feeBatch.actions';
import { feeBatchSelector, viewDetailsByBatchIdSelector } from '../../redux/modules/feeBatch/feeBatch.selectors';
import { locations } from '../../routes/locations';
import { FeeCalculation, FeeCalculationStatus, FeeCalculationTypes } from '../../types/feeCalculation';
import { UserFeeDeduction } from '../../types/userFeeDeduction';
import { round2Format, round4Format } from '../../utils/number';

interface FeeCalculationsTabProps {
  batchId: string;
  onSelectFeeCalculations: (feeCalculations: FeeCalculation[]) => void;
}

export function FeeCalculationsTab({ batchId, onSelectFeeCalculations }: FeeCalculationsTabProps) {
  const tableRef = useRef<TableRef>(null);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const { totalFeeCalculations, feeCalculations, fetchFeeCalculationsStatus } =
    useSelector(viewDetailsByBatchIdSelector);
  const { executeStatus, importExecutionFileStatus } = useSelector(feeBatchSelector);
  // const { consolidateRequests } = useSelector(viewDetailsByBatchIdSelector);
  const loading =
    fetchFeeCalculationsStatus === fetchStatuses.pending ||
    importExecutionFileStatus === fetchStatuses.pending ||
    executeStatus === fetchStatuses.pending;

  const getColumns = (): GridColDef[] => [
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
      aggregable: false,
    },
    {
      field: 'type.name',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: Object.values(FeeCalculationTypes),
      width: 140,
      aggregable: false,
      valueGetter: (params) => params.row?.type?.name,
      groupingValueGetter: (params) => params.row?.type?.name,
    },
    // TODO: show here product type
    // {
    //   field: 'indexFund.nameEn',
    //   headerName: 'Fund',
    //   type: 'string',
    //   width: 100,
    //   aggregable: false,
    //   valueGetter: (params) => {
    //     if (!params.row?.indexFund?.id) {
    //       return '';
    //     }
    //     return `(${params.row?.indexFund?.id}) ${params.row?.indexFund?.nameEn}`;
    //   },
    //   renderCell: (params: GridRenderCellParams) => {
    //     if (!params.value) {
    //       return;
    //     }

    //     return (
    //       <Tooltip title={params.value} followCursor>
    //         <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.indexFund(params.row?.indexFund?.id)}>
    //           {params.value}
    //         </FakeButtonLink>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: 'calculatedTotalFee',
      headerName: 'Calculated Total Fee',
      type: 'number',
      width: 140,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'managementFee',
      headerName: 'Management Fee',
      type: 'number',
      width: 140,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'vat',
      headerName: 'VAT',
      type: 'number',
      width: 140,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'actualTotalFee',
      headerName: 'Actual Total Fee',
      type: 'number',
      width: 160,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(FeeCalculationStatus),
      minWidth: 150,
      flex: 1,
      aggregable: false,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = feeCalculationStatusToInfo[params.row?.status?.name as FeeCalculationStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.row?.status?.name;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.row?.status?.name} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      minWidth: 100,
      aggregable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      minWidth: 100,
      aggregable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ];

  const [columns, setColumns] = useState<GridColDef[]>(getColumns());

  useEffect(() => {
    if (importExecutionFileStatus === fetchStatuses.success || executeStatus === fetchStatuses.success) {
      tableRef.current?.reload();
    }
  }, [importExecutionFileStatus, executeStatus]);

  useEffect(() => {
    setSelectedIds([]);
  }, [feeCalculations]);

  useEffect(() => {
    const items = feeCalculations.filter((item) => selectedIds.includes(item.id));

    onSelectFeeCalculations(items);
  }, [selectedIds]);

  const isRowSelectable = (params: GridRowParams<UserFeeDeduction>): boolean => true;
  // !consolidateRequests.find((item) => item.fundOrder.id === params.row.id);

  return (
    <Table
      ref={tableRef}
      fetchItems={fetchFeeCalculationsByBatchId}
      rows={feeCalculations}
      columns={columns}
      loading={loading}
      rowCount={totalFeeCalculations}
      fetchItemsCustomParams={{ batchId }}
      checkboxSelection
      rowSelectionModel={selectedIds}
      isRowSelectable={isRowSelectable}
      onSelectionModelChange={(sm) => setSelectedIds(sm as number[])}
      initialAggregationModel={{
        estimatedAmount: 'sum',
        satelliteAmount: 'sum',
        actualAmount: 'sum',
        sharesCount: 'sum',
        satelliteUnits: 'sum',
        actualSharesCount: 'sum',
      }}
      toolbarExportProps={{
        csvOptions: { fileName: `fee-calculations-${batchId}` },
        excelOptions: { fileName: `fee-calculations-${batchId}` },
      }}
    />
  );
}
