import { ApiResponse } from './api';

export enum BatchExecutionStatus {
  CREATED = 'Created',
  EXECUTION = 'Execution',
  IN_REVIEW = 'In Review',
  RECONCILIATION = 'Reconciliation',
  IN_APPROVE = 'In Approve',
  APPROVED = 'Approved',
}

export interface BatchExecutionStatusOption {
  id: number;
  name: BatchExecutionStatus;
}

export type BatchExecution = {
  id: number;
  executionId: string;
  batchId: string;
  status: BatchExecutionStatusOption;
  approvedAt: Date | string | null;
  approvedBy: number | null;
  executedAt: Date | string | null;
  executedBy: number | null;
  importedAt: Date | string | null;
  importedBy: number | null;
  reconciledAt: Date | string | null;
  reconciledBy: number | null;
  reportsGenerated: boolean;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchBatchExecutionByBatchIdResponse = ApiResponse<BatchExecution>;
export type FetchBatchExecutionsResponse = ApiResponse<{
  items: BatchExecution[];
  totalItems: number;
}>;

export enum BatchExecutionReportType {
  USER = 'USER',
  REQUEST = 'REQUEST',
}
