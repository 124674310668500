import { ApiResponse } from './api';

export enum AlpacaConfigKey {
  PREFUNDING_ENABLED = 'prefunding_enabled',
  SUBSCRIPTION_USD_FX_RATE = 'subscription_usd_fx_rate',
  REDEMPTION_USD_FX_RATE = 'redemption_usd_fx_rate',
}

export type AlpacaConfig = {
  key: AlpacaConfigKey;
  value: string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchAlpacaConfigResponse = ApiResponse<AlpacaConfig>;
export type FetchAlpacaConfigsResponse = ApiResponse<AlpacaConfig[]>;

export type CreateAlpacaConfigRequest = Omit<AlpacaConfig, 'id' | 'createdAt' | 'updatedAt'>;
export type CreateAlpacaConfigResponse = ApiResponse<AlpacaConfig>;

export type UpdateAlpacaConfigRequest = Omit<AlpacaConfig, 'key' | 'createdAt' | 'updatedAt'> & { key: string };
export type UpdateAlpacaConfigsRequest = {
  items: UpdateAlpacaConfigRequest[];
};
export type UpdateAlpacaConfigResponse = ApiResponse<AlpacaConfig>;
