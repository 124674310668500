import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { monitoringService } from '../../../api';

export const fetchServiceVersions = createAsyncThunk('monitoring/fetch-service-versions', () =>
  monitoringService.fetchServiceVersions(),
);

export const fetchQueueStatus = createAsyncThunk('monitoring/fetch-queue-status', () =>
  monitoringService.fetchQueueStatus(),
);
export const fetchQueueMessages = createAsyncThunk('monitoring/fetch-queue-messages', (queue: string) =>
  monitoringService.fetchQueueMessages(queue),
);
export const cleanMonitoring = createAction('monitoring/clean');
