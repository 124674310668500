import { ApiResponse } from './api';

export enum PortfolioConfigKey {
  CASH_ALLOCATION_PERCENTAGE = 'cash_allocation_percentage',
  NAV_USD_FX_RATE = 'nav_usd_fx_rate',
  AUTO_INVESTMENT = 'auto_investment',
  MINIMUM_PORTFOLIO_INVESTMENT = 'minimum_portfolio_investment',
  MINIMUM_ADD_FUND_INVESTMENT = 'minimum_add_fund_investment',
}

export type PortfolioConfig = {
  key: PortfolioConfigKey;
  value: string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchPortfolioConfigResponse = ApiResponse<PortfolioConfig>;
export type FetchPortfolioConfigsResponse = ApiResponse<PortfolioConfig[]>;

export type CreatePortfolioConfigRequest = Omit<PortfolioConfig, 'id' | 'createdAt' | 'updatedAt'>;
export type CreatePortfolioConfigResponse = ApiResponse<PortfolioConfig>;

export type UpdatePortfolioConfigRequest = Omit<PortfolioConfig, 'key' | 'createdAt' | 'updatedAt'> & { key: string };
export type UpdatePortfolioConfigsRequest = {
  items: UpdatePortfolioConfigRequest[];
};
export type UpdatePortfolioConfigResponse = ApiResponse<PortfolioConfig>;
