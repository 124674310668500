import * as yup from 'yup';
import { productTypeFormFields } from '../../fields/formFields';

export const productTypeFormSchema = yup.object({
  [productTypeFormFields.nameEn]: yup.string().required('Product Type Name En is required').trim(),
  [productTypeFormFields.nameAr]: yup.string().required('Product Type Name Ar is required').trim(),
  [productTypeFormFields.descriptionEn]: yup.string().required('Product Description En is required').trim(),
  [productTypeFormFields.descriptionAr]: yup.string().required('Product Description Ar is required').trim(),
  [productTypeFormFields.minimumPortfolioInvestment]: yup.number().min(1).required(),
  [productTypeFormFields.minimumAddFundInvestment]: yup.number().min(1).required(),
  [productTypeFormFields.minimumRedemption]: yup.number().min(1).required(),
  [productTypeFormFields.fee]: yup.number().min(0).max(100).required(),
  [productTypeFormFields.scoreDependent]: yup.bool(),
  [productTypeFormFields.isCustom]: yup.bool(),
  [productTypeFormFields.disabled]: yup.bool(),
});
