import { ActionType } from '../../types/action';

const listPermission = {
  resource: 'fund-manager',
  action: ActionType.READ,
};
const createPermission = {
  resource: 'fund-manager',
  action: ActionType.CREATE,
};
const updatePermission = {
  resource: 'fund-manager',
  action: ActionType.UPDATE,
};
const deletePermission = {
  resource: 'fund-manager',
  action: ActionType.UPDATE,
};

export const FundManager = {
  list: listPermission,
  create: createPermission,
  update: updatePermission,
  delete: deletePermission,
};
