import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { reportSelector } from '../../redux/modules/report/report.selectors';
import { UnitsTable } from './UnitsTable';
import { useAppDispatch } from '../../redux/hooks';
import { indexFundSelector } from '../../redux/modules/indexFund/indexFund.selectors';
import { fetchIndexFunds } from '../../redux/modules/indexFund/indexFund.actions';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { SystemUnitsWidget } from './SystemUnitsWidget';

export function UnitsTab() {
  const dispatch = useAppDispatch();
  const { error } = useSelector(reportSelector);
  const { fetchStatus } = useSelector(indexFundSelector);

  useEffect(() => {
    dispatch(fetchIndexFunds({ limit: 100 }));
  }, []);

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      <Grid item container>
        <Typography variant="h6">System Units</Typography>
        <SystemUnitsWidget />
      </Grid>
      {fetchStatus === fetchStatuses.success && (
        <Grid item container>
          <Typography variant="h6">Units Per User</Typography>
          <UnitsTable />
        </Grid>
      )}
    </>
  );
}
