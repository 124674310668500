import { PaginatedRequest } from '../types/api';
import {
  SavePortfolioRequest,
  CreatePortfolioResponse,
  FetchPortfoliosResponse,
  FetchPortfolioResponse,
  UpdatePortfolioRequest,
  UpdatePortfolioResponse,
  UpdatePortfolioStatusRequest,
  UpdatePortfolioStatusResponse,
  FetchPortfolioStatusOptionsResponse,
  FetchPortfolioCurrencyOptionsResponse,
  FetchPortfolioFileTypeOptionsResponse,
} from '../types/portfolio';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class PortfolioService extends ApiService {
  pathname = 'admin';

  fetchPortfolios = async (data: PaginatedRequest) =>
    this.get<FetchPortfoliosResponse>(`${this.pathname}/portfolio?${toQueryParams(data)}`);

  fetchPortfolio = async (id: number) => this.get<FetchPortfolioResponse>(`${this.pathname}/portfolio/${id}`);

  deletePortfolio = async (id: number) => this.delete<FetchPortfolioResponse>(`${this.pathname}/portfolio/${id}`);

  savePortfolio = async (portfolio: SavePortfolioRequest) =>
    this.post<CreatePortfolioResponse, SavePortfolioRequest>(`${this.pathname}/portfolio`, portfolio);

  updatePortfolio = async ({ id, ...portfolio }: UpdatePortfolioRequest) =>
    this.put<UpdatePortfolioResponse, Omit<UpdatePortfolioRequest, 'id'>>(
      `${this.pathname}/portfolio/${id}`,
      portfolio,
    );

  updatePortfolioStatus = async ({ id, status }: UpdatePortfolioStatusRequest) =>
    this.put<UpdatePortfolioStatusResponse, Omit<UpdatePortfolioStatusRequest, 'id'>>(
      `${this.pathname}/portfolio/${id}/status`,
      { status },
    );

  fetchPortfolioStatusOptions = async () =>
    this.get<FetchPortfolioStatusOptionsResponse>(`${this.pathname}/portfolio/status/options`);

  fetchPortfolioFileTypeOptions = async () =>
    this.get<FetchPortfolioFileTypeOptionsResponse>(`${this.pathname}/portfolio/file-type/options`);

  fetchPortfolioCurrencyOptions = async () =>
    this.get<FetchPortfolioCurrencyOptionsResponse>(`${this.pathname}/portfolio/currency/options`);
}

export const portfolioService = new PortfolioService();
