import { getGridNumericOperators, GridFilterOperator } from '@mui/x-data-grid-premium';
import { CustomGridFilterInputMultipleValue } from '../filterComponents/CustomGridFilterInputMultipleValue';

export const gridNumericOperators: GridFilterOperator[] = getGridNumericOperators().map((filter) => {
  if (filter.value === 'isAnyOf') {
    filter.InputComponent = CustomGridFilterInputMultipleValue;
  }

  return filter;
});
