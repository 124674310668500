import { MenuItemClick, MenuItemLink, MenuItemParent, MenuItems, MenuItemSimple } from './types';

function isParentMenuItem(menuItem: MenuItemSimple | MenuItemParent): menuItem is MenuItemParent {
  return (menuItem as MenuItemParent).children !== undefined;
}

function isLinkMenuItem(menuItem: MenuItemClick | MenuItemLink): menuItem is MenuItemLink {
  return (menuItem as MenuItemLink).path !== undefined;
}

function selectParentMenuItems(menuItems: MenuItems): MenuItemParent[] {
  const parentMenuItems: MenuItemParent[] = [];

  menuItems.forEach((items) => {
    items.forEach((item) => {
      if (isParentMenuItem(item)) {
        parentMenuItems.push(item);
      }
    });
  });

  return parentMenuItems;
}

function selectLinkMenuItems(menuItems: MenuItemSimple[]): MenuItemLink[] {
  const linkMenuItems: MenuItemLink[] = [];

  menuItems.forEach((item) => {
    if (isLinkMenuItem(item)) {
      linkMenuItems.push(item);
    }
  });

  return linkMenuItems;
}

export function selectOpenedParentItem(menuItems: MenuItems, pathname: string): MenuItemParent | undefined {
  return selectParentMenuItems(menuItems).find(({ children }) => {
    const linkMenuItems = selectLinkMenuItems(children);

    return (
      linkMenuItems.findIndex((item) => {
        if (item.pathRegexp) {
          return item.pathRegexp.test(pathname);
        }
        return item.path === pathname;
      }) !== -1
    );
  });
}

export function isSelectedItem(menuItem: MenuItemSimple, pathname: string): boolean {
  if (isLinkMenuItem(menuItem)) {
    if (menuItem.pathRegexp) {
      return menuItem.pathRegexp.test(pathname);
    }
    return pathname === menuItem.path;
  }

  return false;
}
