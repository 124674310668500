import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import plotComponentFactory from 'react-plotly.js/factory';
import Plotly from 'plotly.js-dist';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useAppDispatch } from '../../redux/hooks';
import { fetchFunnelChart } from '../../redux/modules/analytics/analytics.actions';
import { analyticsSelector } from '../../redux/modules/analytics/analytics.selectors';

export const FunnelChartWidget = () => {
  const dispatch = useAppDispatch();
  const Plot = plotComponentFactory(Plotly);

  const { funnelChart, fetchFunnelChartStatus } = useSelector(analyticsSelector);
  const config = { displayModeBar: false };

  const isLoading = fetchFunnelChartStatus === fetchStatuses.pending;

  useEffect(() => {
    dispatch(fetchFunnelChart());
  }, []);

  if (isLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  if (!funnelChart) {
    return null;
  }

  return (
    <Grid item m={8} xs={12}>
      <Grid container m={8}>
        <Grid item component={Paper} xs={12}>
          <Box>
            <Box padding={2}>
              <Typography variant="body2" color="text.primary">
                Funnel Analysis
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Plot data={funnelChart.data} config={config} layout={{}} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
