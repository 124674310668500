import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useAppDispatch } from '../../redux/hooks';
import { fetchQueueMessages, fetchQueueStatus } from '../../redux/modules/monitoring/monitoring.actions';
import { monitoringSelector } from '../../redux/modules/monitoring/monitoring.selectors';
import { QueueStatus, AssertQueue } from '../../types/monitoring';

export const QueueStatusTab = () => {
  const dispatch = useAppDispatch();

  const { queueStatus, fetchQueueStatusStatus } = useSelector(monitoringSelector);
  const isLoading = fetchQueueStatusStatus === fetchStatuses.pending;

  const onGetMessages = (queue: string) => {
    dispatch(fetchQueueMessages(queue));
  };

  useEffect(() => {
    dispatch(fetchQueueStatus());
  }, []);

  const renderQueueItem = (item: QueueStatus) => (
    <Card variant="outlined">
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {'queue' in item.status ? item.status.queue : `${item.queue}-unprocessed`}
        </Typography>
        <Typography variant="h5" component="div">
          {item.queue}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {'queue' in item.status && `${item.status.messageCount} items`}
        </Typography>
        <Typography variant="body2">{'message' in item.status && item.status.message}</Typography>
      </CardContent>
      {'messageCount' in item.status && item.status.messageCount > 0 && (
        <CardActions>
          <Button size="small" onClick={() => onGetMessages((item.status as AssertQueue).queue)}>
            Get messages
          </Button>
        </CardActions>
      )}
    </Card>
  );

  if (isLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {queueStatus.map((qs) => (
        <Grid item key={`queue-status-${qs.queue}`} xs={4} sx={{ height: '100%' }}>
          {renderQueueItem(qs)}
        </Grid>
      ))}
    </Grid>
  );
};
