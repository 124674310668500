import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchCashReportResponse,
  FetchOrderFxRevenueReportResponse,
  FetchPortfolioOrderExecutionsReportResponse,
  FetchSystemCashReportResponse,
  FetchSystemUnitsReportResponse,
  FetchTransferFxRevenueReportResponse,
  FetchUnitReportResponse,
} from '../../../types/report';
import {
  cleanReport,
  fetchCashReport,
  fetchFxRevenueAlpacaOrdersReport,
  fetchFxRevenueAlpacaTransfersReport,
  fetchFxRevenueJadwaOrdersReport,
  fetchFxRevenueJadwaTransfersReport,
  fetchPortfolioOrderExecutionReport,
  fetchSystemCashReport,
  fetchSystemUnitsReport,
  fetchUnitsReport,
  reconcileSystemCashReport,
} from './report.actions';
import { ReportState } from './report.types';

const initialState: ReportState = {
  portfolioOrderExecutions: [],
  totalPortfolioOrderExecutions: 0,
  fetchPortfolioOrderExecutionsStatus: fetchStatuses.idle,

  cashItems: [],
  totalCashItems: 0,
  fetchCashReportItemsStatus: fetchStatuses.idle,

  systemReport: {},
  fetchSystemCashReportStatus: fetchStatuses.idle,

  unitItems: [],
  totalUnitItems: 0,
  fetchUnitsReportItemsStatus: fetchStatuses.idle,

  systemUnitsReport: [],
  fetchSystemUnitsReportStatus: fetchStatuses.idle,
  reconcileSystemCashReportStatus: fetchStatuses.idle,

  alpacaFxRevenueOrders: [],
  totalAlpacaFxRevenueOrders: 0,
  fetchAlpacaFxRevenueOrdersStatus: fetchStatuses.idle,

  alpacaFxRevenueTransfers: [],
  totalAlpacaFxRevenueTransfers: 0,
  fetchAlpacaFxRevenueTransferStatus: fetchStatuses.idle,

  jadwaFxRevenueOrders: [],
  totalJadwaFxRevenueOrders: 0,
  fetchJadwaFxRevenueOrdersStatus: fetchStatuses.idle,

  jadwaFxRevenueTransfers: [],
  totalJadwaFxRevenueTransfers: 0,
  fetchJadwaFxRevenueTransferStatus: fetchStatuses.idle,

  error: null,
};

const portfolioOrdersSlice = createSlice({
  name: 'portfolioOrders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPortfolioOrderExecutionReport.pending, (state) => {
      state.error = null;
      state.fetchPortfolioOrderExecutionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchPortfolioOrderExecutionReport.fulfilled,
      (state, action: PayloadAction<FetchPortfolioOrderExecutionsReportResponse>) => {
        state.portfolioOrderExecutions = action.payload.data.items;
        state.totalPortfolioOrderExecutions = action.payload.data.totalItems;
        state.fetchPortfolioOrderExecutionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchPortfolioOrderExecutionReport.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.fetchPortfolioOrderExecutionsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchCashReport.pending, (state) => {
      state.error = null;
      state.fetchCashReportItemsStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchCashReport.fulfilled, (state, action: PayloadAction<FetchCashReportResponse>) => {
      state.cashItems = action.payload.data.items;
      state.totalCashItems = action.payload.data.totalItems;
      state.fetchCashReportItemsStatus = fetchStatuses.success;
    });
    builder.addCase(fetchCashReport.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.fetchCashReportItemsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchSystemCashReport.pending, (state) => {
      state.error = null;
      state.fetchSystemCashReportStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchSystemCashReport.fulfilled, (state, action: PayloadAction<FetchSystemCashReportResponse>) => {
      if (action.payload.data) {
        state.systemReport[action.payload.data.date] = action.payload.data;
      }
      state.fetchSystemCashReportStatus = fetchStatuses.success;
    });
    builder.addCase(fetchSystemCashReport.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.fetchSystemCashReportStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(reconcileSystemCashReport.pending, (state) => {
      state.error = null;
      state.reconcileSystemCashReportStatus = fetchStatuses.pending;
    });
    builder.addCase(reconcileSystemCashReport.fulfilled, (state) => {
      state.reconcileSystemCashReportStatus = fetchStatuses.success;
    });
    builder.addCase(reconcileSystemCashReport.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.reconcileSystemCashReportStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchUnitsReport.pending, (state) => {
      state.error = null;
      state.fetchUnitsReportItemsStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchUnitsReport.fulfilled, (state, action: PayloadAction<FetchUnitReportResponse>) => {
      state.unitItems = action.payload.data.items;
      state.totalUnitItems = action.payload.data.totalItems;

      state.fetchUnitsReportItemsStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUnitsReport.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.fetchUnitsReportItemsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchSystemUnitsReport.pending, (state) => {
      state.error = null;
      state.fetchSystemUnitsReportStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchSystemUnitsReport.fulfilled,
      (state, action: PayloadAction<FetchSystemUnitsReportResponse>) => {
        state.systemUnitsReport = action.payload.data;
        state.fetchSystemUnitsReportStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchSystemUnitsReport.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.fetchSystemUnitsReportStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchFxRevenueAlpacaOrdersReport.pending, (state) => {
      state.error = null;
      state.fetchAlpacaFxRevenueOrdersStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchFxRevenueAlpacaOrdersReport.fulfilled,
      (state, action: PayloadAction<FetchOrderFxRevenueReportResponse>) => {
        state.alpacaFxRevenueOrders = action.payload.data.items;
        state.totalAlpacaFxRevenueOrders = action.payload.data.totalItems;
        state.fetchAlpacaFxRevenueOrdersStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFxRevenueAlpacaOrdersReport.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;
      state.fetchAlpacaFxRevenueOrdersStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchFxRevenueAlpacaTransfersReport.pending, (state) => {
      state.error = null;
      state.fetchAlpacaFxRevenueTransferStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchFxRevenueAlpacaTransfersReport.fulfilled,
      (state, action: PayloadAction<FetchTransferFxRevenueReportResponse>) => {
        state.alpacaFxRevenueTransfers = action.payload.data.items;
        state.totalAlpacaFxRevenueTransfers = action.payload.data.totalItems;
        state.fetchAlpacaFxRevenueTransferStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFxRevenueAlpacaTransfersReport.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;
      state.fetchAlpacaFxRevenueTransferStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchFxRevenueJadwaOrdersReport.pending, (state) => {
      state.error = null;
      state.fetchJadwaFxRevenueOrdersStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchFxRevenueJadwaOrdersReport.fulfilled,
      (state, action: PayloadAction<FetchOrderFxRevenueReportResponse>) => {
        state.jadwaFxRevenueOrders = action.payload.data.items;
        state.totalJadwaFxRevenueOrders = action.payload.data.totalItems;
        state.fetchJadwaFxRevenueOrdersStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFxRevenueJadwaOrdersReport.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;
      state.fetchJadwaFxRevenueOrdersStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchFxRevenueJadwaTransfersReport.pending, (state) => {
      state.error = null;
      state.fetchJadwaFxRevenueTransferStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchFxRevenueJadwaTransfersReport.fulfilled,
      (state, action: PayloadAction<FetchTransferFxRevenueReportResponse>) => {
        state.jadwaFxRevenueTransfers = action.payload.data.items;
        state.totalJadwaFxRevenueTransfers = action.payload.data.totalItems;
        state.fetchJadwaFxRevenueTransferStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFxRevenueJadwaTransfersReport.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;
      state.fetchJadwaFxRevenueTransferStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(cleanReport.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const reportReducer = portfolioOrdersSlice.reducer;
