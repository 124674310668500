import * as yup from 'yup';

export const emailSchema = yup.string().email('Enter a valid email').required('Email is required').trim();

export const madkholEmailSchema = yup
  .string()
  .email('Enter a valid email')
  .emailDomain('@madkhol.com')
  .required('Email is required')
  .trim();

export const madkholSecretEmailSchema = yup
  .string()
  .email('Username is incorrect')
  .emailDomain('@madkhol.com', 'Username is incorrect')
  .required('Username is required')
  .trim();
