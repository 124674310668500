import { ActionType } from '../../types/action';

export const listPermission = {
  resource: 'app-config',
  action: ActionType.READ,
};

export const AppConfig = {
  list: listPermission,
};
