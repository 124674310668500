import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchFeeAccountDetailsHistoryResponse,
  FetchFeeAccountResponse,
  FetchFeeAccountsResponse,
} from '../../../types/feeAccount';
import { fetchUserBankDetailsHistory } from '../userBank/userBank.actions';
import {
  fetchFeeAccount,
  fetchFeeAccounts,
  resetEditFeeAccount,
  cleanFeeAccounts,
  updateFeeAccount,
  createFeeAccountItem,
  deleteFeeAccount,
  fetchFeeAccountDetailsHistory,
} from './feeAccount.actions';
import { FeeAccountState } from './feeAccount.types';

const initialState: FeeAccountState = {
  feeAccounts: [],
  totalFeeAccounts: 0,
  editFeeAccount: null,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,

  feeAccountDetailsHistory: [],
  totalFeeAccountDetailsHistory: 0,
  fetchFeeAccountDetailsHistoryStatus: fetchStatuses.idle,
  error: null,
};

const feeAccountsSlice = createSlice({
  name: 'feeAccounts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFeeAccounts.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchFeeAccounts.fulfilled, (state, feeAccount: PayloadAction<FetchFeeAccountsResponse>) => {
      state.feeAccounts = feeAccount.payload.data.items;
      state.totalFeeAccounts = feeAccount.payload.data.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFeeAccounts.rejected, (state, feeAccount) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = feeAccount.error;
    });

    builder.addCase(fetchUserBankDetailsHistory.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchFeeAccountDetailsHistory.fulfilled,
      (state, action: PayloadAction<FetchFeeAccountDetailsHistoryResponse>) => {
        state.feeAccountDetailsHistory = action.payload.data?.items;
        state.totalFeeAccountDetailsHistory = action.payload.data?.totalItems;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserBankDetailsHistory.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFeeAccount.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchFeeAccount.fulfilled, (state, feeAccount: PayloadAction<FetchFeeAccountResponse>) => {
      state.editFeeAccount = feeAccount.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFeeAccount.rejected, (state, feeAccount) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = feeAccount.error;
    });

    builder.addCase(updateFeeAccount.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateFeeAccount.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateFeeAccount.rejected, (state, feeAccount) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = feeAccount.error;
    });

    builder.addCase(createFeeAccountItem.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(createFeeAccountItem.fulfilled, (state, feeAccount: PayloadAction<FetchFeeAccountResponse>) => {
      state.editFeeAccount = feeAccount.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createFeeAccountItem.rejected, (state, feeAccount) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = feeAccount.error;
    });

    builder.addCase(deleteFeeAccount.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteFeeAccount.fulfilled, (state) => {
      state.editFeeAccount = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteFeeAccount.rejected, (state, feeAccount) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = feeAccount.error;
    });

    builder.addCase(resetEditFeeAccount.type, (state) => {
      state.editFeeAccount = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanFeeAccounts.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const feeAccountReducer = feeAccountsSlice.reducer;
