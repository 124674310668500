import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import AttachmentIcon from '@mui/icons-material/Attachment';
import InsightsIcon from '@mui/icons-material/Insights';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useSearchParamsState, usePermissions } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import { cleanAlpacas } from '../../redux/modules/alpaca/alpaca.actions';
import {
  fetchKycByUserId,
  fetchKycGenderOptions,
  fetchKycStatusOptions,
  fetchKycTypeOptions,
  fetchUser,
  fetchUserStatusOptions,
  fetchUserWorldCheckScreen,
  resetEditUser,
  resetUserApiStatuses,
} from '../../redux/modules/user/user.actions';
import { userKycLoadingSelector, userLoadingSelector, userSelector } from '../../redux/modules/user/user.selectors';
import { fetchUserCustodianData } from '../../redux/modules/userCustodianData/userCustodianData.actions';
import { fetchUserGroups } from '../../redux/modules/userGroup/userGroup.actions';
import * as permissions from '../../utils/permissions';
import { AttachmentsTab } from './components/Attachments/AttachmentsTab';
import { BankInfoTab } from './BankInfoTab';
import { KycTab } from './components/Kyc/KycTab';
import { OwnedSharesTab } from './components/OwnedShares/OwnedSharesTab';
import { UserHistoryTab } from './UserHistoryTab';
import { UserSignAgreementsTab } from './UserSignAgreementsTab';
import { UserSurveyResultsTab } from './UserSurveyResultsTab';
import { UserTab } from './UserTab';
import { AlpacaTab } from './components/Alpaca/AlpacaTab';
import { WorldCheckTab } from './WorldCheckTab';
import { FeesTab } from './components/Fees/FeesTab';
import { Ledger } from '../../components/Ledger';
import {
  useFetchCashAccountsQuery,
  useFetchCashLedgersQuery,
  useFetchUnitsAccountsQuery,
  useFetchUnitsLedgersQuery,
} from '../../redux/features/ledger/ledger.apiSlice';

const mapTabParamToValue: Record<string, string> = {
  general: '1',
  kyc: '2',
  bankInfo: '3',
  ownedShares: '4',
  fees: '5',
  attachments: '6',
  surveyResults: '7',
  signAgreements: '8',
  history: '9',
  worldCheck: '10',
  alpaca: '11',
  cashLedger: '12',
  unitsLedger: '13',
  default: '1',
};
const mapTabValueToTabParam: Record<string, string> = {
  1: 'general',
  2: 'kyc',
  3: 'bankInfo',
  4: 'ownedShares',
  5: 'fees',
  6: 'attachments',
  7: 'surveyResults',
  8: 'signAgreements',
  9: 'history',
  10: 'worldCheck',
  11: 'alpaca',
  12: 'cashLedger',
  13: 'unitsLedger',
};

export function User() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useSearchParamsState('tab', 'default');

  const canReadKyc = usePermissions({ items: [permissions.Kyc.read] });
  const { updateKycStatus, createStatus, updateStatus } = useSelector(userSelector);
  const isUserLoading = useSelector(userLoadingSelector);
  const isKycLoading = useSelector(userKycLoadingSelector);

  const isLoading =
    isUserLoading ||
    isKycLoading ||
    updateKycStatus === fetchStatuses.pending ||
    createStatus === fetchStatuses.pending ||
    updateStatus === fetchStatuses.pending;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(resetUserApiStatuses());
    setSelectedTab(mapTabValueToTabParam[newValue]);
  };

  useEffect(() => {
    dispatch(fetchUserStatusOptions());
    dispatch(fetchUserGroups({ limit: 1000, offset: 0 }));
    dispatch(fetchKycTypeOptions());
    dispatch(fetchKycStatusOptions());
    dispatch(fetchKycGenderOptions());

    return () => {
      dispatch(resetEditUser());
      dispatch(cleanAlpacas());
    };
  }, []);

  useEffect(() => {
    if (Number(id)) {
      dispatch(fetchUser(Number(id)));
      dispatch(fetchKycByUserId(Number(id)));
      dispatch(fetchUserWorldCheckScreen(Number(id)));
      dispatch(fetchUserCustodianData(Number(id)));
    }
  }, [id]);

  useEffect(() => {
    if (selectedTab === mapTabParamToValue.kyc && !canReadKyc) {
      setSelectedTab(mapTabParamToValue.default);
    }
  }, [selectedTab, canReadKyc]);

  const renderTabList = () => {
    if (!id) {
      return <div>Loading...</div>;
    }

    return (
      <TabContext value={mapTabParamToValue[selectedTab]}>
        <Box>
          <TabList
            value={mapTabParamToValue[selectedTab]}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="User tabs"
          >
            <Tab
              icon={<AccountBoxIcon />}
              iconPosition="start"
              label="General"
              value="1"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<PersonSearchIcon />}
              iconPosition="start"
              label="Kyc"
              value="2"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading || !canReadKyc}
            />
            <Tab
              icon={<AccountBalanceIcon />}
              iconPosition="start"
              label="Bank Info"
              value="3"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<AccountBalanceIcon />}
              iconPosition="start"
              label="Owned Shares"
              value="4"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<ReceiptLongIcon />}
              iconPosition="start"
              label="Fees"
              value="5"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<AttachmentIcon />}
              iconPosition="start"
              label="Attachments"
              value="6"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<HistoryEduIcon />}
              iconPosition="start"
              label="Survey Results"
              value="7"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<HandshakeIcon />}
              iconPosition="start"
              label="Sign Agreements"
              value="8"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<HistoryEduIcon />}
              iconPosition="start"
              label="History"
              value="9"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<TravelExploreIcon />}
              iconPosition="start"
              label="World Check"
              value="10"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<InsightsIcon />}
              iconPosition="start"
              label="Alpaca"
              value="11"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<AccountBalanceIcon />}
              iconPosition="start"
              label="Cash Ledger"
              value="12"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<AccountBalanceIcon />}
              iconPosition="start"
              label="Units Ledger"
              value="13"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <UserTab id={Number(id)} />
        </TabPanel>
        <TabPanel value="2">
          <KycTab id={Number(id)} />
        </TabPanel>
        <TabPanel value="3">
          <BankInfoTab id={Number(id)} />
        </TabPanel>
        <TabPanel value="4">
          <OwnedSharesTab userId={Number(id)} />
        </TabPanel>
        <TabPanel value="5">
          <FeesTab userId={Number(id)} />
        </TabPanel>
        <TabPanel value="6">
          <AttachmentsTab id={Number(id)} />
        </TabPanel>
        <TabPanel value="7">
          <UserSurveyResultsTab id={Number(id)} />
        </TabPanel>
        <TabPanel value="8">
          <UserSignAgreementsTab id={Number(id)} />
        </TabPanel>
        <TabPanel value="9">
          <UserHistoryTab id={Number(id)} />
        </TabPanel>
        <TabPanel value="10">
          <WorldCheckTab userId={Number(id)} />
        </TabPanel>
        <TabPanel value="11">
          <AlpacaTab id={Number(id)} />
        </TabPanel>
        <TabPanel value="12">
          <Ledger
            accountId={Number(id)}
            fetchAccounts={useFetchCashAccountsQuery}
            fetchLedger={useFetchCashLedgersQuery}
          />
        </TabPanel>
        <TabPanel value="13">
          <Ledger
            accountId={Number(id)}
            fetchAccounts={useFetchUnitsAccountsQuery}
            fetchLedger={useFetchUnitsLedgersQuery}
          />
        </TabPanel>
      </TabContext>
    );
  };

  return (
    <Container component="main" maxWidth="xl">
      {renderTabList()}
    </Container>
  );
}
