import React, { useRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowSelectionModel,
  GridSortModel,
  GridColDef,
  GridRowGroupingModel,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  LicenseInfo,
  GridToolbarExportProps,
  GridAggregationModel,
  GridApi,
  GridPaginationModel,
  GridFilterItem,
} from '@mui/x-data-grid-premium';
import { CustomFooter } from './components/CustomFooter';
import { CustomToolbar } from './components/CustomToolbar';
import { mapColumTypeToFilterOperators } from './filterOperators';
import { mapColumTypeToFormatter } from './valueFormatter';
import { mapColumTypeToGetter } from './valueGetter';

LicenseInfo.setLicenseKey('xxxxx');

export interface RtkQueryTableRef {
  setFilterModel: (filterModel: GridFilterModel) => void;
  getApi: () => GridApi;
}

export type RtkQueryTableModels = {
  paginationModel: GridPaginationModel;
  sortModel?: GridSortModel;
  filterModel?: GridFilterModel;
  columnVisibilityModel?: GridColumnVisibilityModel;
  aggregationModel?: GridAggregationModel;
  rowGroupingModel?: GridRowGroupingModel;
  rowSelectionModel?: GridRowSelectionModel;
};

interface IRtkQueryTableProps
  extends Omit<
    DataGridPremiumProps,
    | 'sortModel'
    | 'filterModel'
    | 'rowSelectionModel'
    | 'rowGroupingModel'
    | 'columnVisibilityModel'
    | 'paginationModel'
    | 'aggregationModel'
    | 'page'
    | 'pageSize'
    | 'onPageSizeChange'
    | 'onPageChange'
    | 'onSortModelChange'
    | 'onSelectionModelChange'
    | 'onFilterModelChange'
    | 'onColumnVisibilityModelChange'
    | 'onRowGroupingModelChange'
  > {
  initialPage?: number;
  initialPageSize?: number;
  initialFilterModel?: GridFilterModel;
  initialSortModel?: GridSortModel;
  initialRowGroupingModel?: GridRowGroupingModel;
  initialColumnVisibilityModel?: GridColumnVisibilityModel;
  initialAggregationModel?: GridAggregationModel;
  requiredFilters?: GridFilterItem[];
  models: RtkQueryTableModels;
  onModelsChange: (selectionModel: RtkQueryTableModels) => void;
  customToolbarItems?: React.JSX.Element | React.JSX.Element[];
  toolbarExportProps?: Partial<GridToolbarExportProps>;
  timesToSkipFilters?: number;
  wrapperHeight?: number;
  debug?: boolean;
}

const mergeFilters = (newItems: GridFilterItem[], requiredItems: GridFilterItem[]) => {
  const nextItems = [...requiredItems];

  for (const newItem of newItems) {
    if (!nextItems.find((item) => item.id === newItem.id)) {
      nextItems.push(newItem);
    }
  }

  return nextItems;
};

const defaultPageSizeOptions = [100, 500, 1000];

export const RtkQueryTable = React.forwardRef<RtkQueryTableRef, IRtkQueryTableProps>(
  (allProps: IRtkQueryTableProps, ref) => {
    const { columns: _columns, ...props } = allProps;
    const apiRef = useGridApiRef();
    const isInitialMount = useRef(false);
    const [skippedFilterModel, setSkippedFilterModel] = useState(props.timesToSkipFilters ?? 1);
    const [skippedSortModel, setSkippedSortModel] = useState(0);
    const [skippedPaginationModel, setSkippedPaginationModel] = useState(0);
    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [models, setModels] = React.useState<RtkQueryTableModels>(props.models);
    const initialState = useKeepGroupedColumnsHidden({
      apiRef,
      initialState: {
        ...(apiRef.current?.exportState ? apiRef.current?.exportState() : {}),
        sorting: { sortModel: models.sortModel || [] },
        filter: { filterModel: models.filterModel || { items: [] } },
        columns: { columnVisibilityModel: models.columnVisibilityModel || {} },
        rowGrouping: { model: models.rowGroupingModel },
        aggregation: { model: models.aggregationModel },
        pagination: { paginationModel: models.paginationModel },
      },
    });
    const pageSizeOptions = [
      ...new Set((allProps.pageSizeOptions || defaultPageSizeOptions).concat(models.paginationModel.pageSize)),
    ];

    const debugLog = (msg: string, logData?: object) => {
      if (props.debug) {
        console.debug(`[Table ${msg}]`, logData);
      }
    };

    const onPaginationModelChange = React.useCallback(
      (newPaginationModel: GridPaginationModel) => {
        debugLog('onPaginationModelChange', newPaginationModel);

        if (skippedPaginationModel > 0 && props.initialSortModel) {
          setSkippedPaginationModel((prev) => prev - 1);
          return;
        }

        // if (props.onPaginationModelChange) {
        //   props.onPaginationModelChange(newPaginationModel);
        // }

        setModels((prev) => ({
          ...prev,
          paginationModel: !newPaginationModel
            ? {
                page: props?.initialPage || 0,
                pageSize: props?.initialPageSize || 10,
              }
            : newPaginationModel,
        }));
      },
      [skippedPaginationModel],
    );

    const onSortModelChange = React.useCallback(
      (newSortModel: GridSortModel) => {
        debugLog('onSortModelChange', newSortModel);

        if (skippedSortModel > 0 && props.initialSortModel) {
          setSkippedSortModel((prev) => prev - 1);
          return;
        }

        setModels((prev) => ({
          ...prev,
          sortModel: !newSortModel?.length ? props?.initialSortModel || [] : newSortModel,
        }));
      },
      [skippedSortModel],
    );

    const onFilterModelChange = React.useCallback(
      (newFilterModel: GridFilterModel) => {
        debugLog('onFilterModelChange', newFilterModel);

        if (!skippedFilterModel && props.initialFilterModel) {
          setSkippedFilterModel((prev) => prev - 1);
          return;
        }

        const nextFilterModel = {
          ...newFilterModel,
          items: mergeFilters(newFilterModel.items, props.requiredFilters || []),
        };

        setModels((prev) => ({
          ...prev,
          filterModel: nextFilterModel,
        }));
      },
      [skippedFilterModel],
    );

    const onRowSelectionModelChange = React.useCallback((newSelectionModel: GridRowSelectionModel) => {
      debugLog('onRowSelectionModelChange', newSelectionModel);

      setModels((prev) => ({
        ...prev,
        rowSelectionModel: newSelectionModel,
      }));
    }, []);

    const onAggregationModelChange = React.useCallback((newAggregationModel: GridAggregationModel) => {
      debugLog('onAggregationModelChange', newAggregationModel);

      setModels((prev) => ({
        ...prev,
        aggregationModel: newAggregationModel,
      }));
    }, []);

    const onColumnVisibilityModelChange = React.useCallback((newColumnVisibilityModel: GridColumnVisibilityModel) => {
      debugLog('onColumnVisibilityModelChange', newColumnVisibilityModel);

      setModels((prev) => ({
        ...prev,
        columnVisibilityModel: newColumnVisibilityModel,
      }));
    }, []);

    const onRowGroupingModelChange = React.useCallback((newRowGroupingModel: GridRowGroupingModel) => {
      debugLog('onRowGroupingModelChange', newRowGroupingModel);

      setModels((prev) => ({
        ...prev,
        rowGroupingModel: newRowGroupingModel,
      }));
    }, []);

    useEffect(() => {
      debugLog('useEffect:models', models);

      if (props.onModelsChange) {
        props.onModelsChange(models);
      }
    }, [models]);

    useEffect(() => {
      debugLog('useEffect:_columns', _columns);

      // eslint-disable-next-line react/prop-types
      const newColumns = _columns.map((c: GridColDef) => {
        const colDef: GridColDef = { ...c };

        const valueFormatter = (c.type && mapColumTypeToFormatter[c.type]) || c.valueFormatter;
        const valueGetter = c.valueGetter ?? (c.type ? mapColumTypeToGetter[c.type] : undefined);
        const filterOperators =
          c.filterOperators ?? (c.type ? mapColumTypeToFilterOperators[c.type] : mapColumTypeToFilterOperators.string);

        if (valueFormatter) {
          colDef.valueFormatter = valueFormatter;
        }

        if (valueGetter) {
          colDef.valueGetter = valueGetter;
        }

        if (filterOperators) {
          colDef.filterOperators = filterOperators;
        }

        return colDef;
      });

      setColumns(newColumns);
      // eslint-disable-next-line react/prop-types
    }, [_columns]);

    useImperativeHandle(ref, () => ({
      setFilterModel: (filterModel: GridFilterModel) => {
        debugLog('setFilterModel:filterModel');
        onFilterModelChange(filterModel);
      },
      getApi: () => {
        debugLog('setFilterModel:getApi');
        return apiRef.current;
      },
    }));

    useEffect(() => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        Array.from(document.querySelectorAll('.MuiDataGrid-main > div'))
          .filter((el) =>
            ['MUI X: Missing license key', 'MUI X: Invalid license key', 'MUI X Invalid license key'].includes(
              el.textContent || '',
            ),
          )
          .map((e) => {
            e.textContent = '';

            return e;
          });
      }
    });

    return (
      <div style={{ height: allProps.wrapperHeight || 700, width: '100%' }}>
        <DataGridPremium
          apiRef={apiRef}
          disableDensitySelector
          disableRowGrouping
          disableRowSelectionOnClick
          disableColumnPinning
          pageSizeOptions={pageSizeOptions}
          slots={{
            toolbar: () =>
              CustomToolbar({ children: props.customToolbarItems || [], exportProps: props.toolbarExportProps }),
            // eslint-disable-next-line react/no-unstable-nested-components
            footer: (footerProps) => CustomFooter(footerProps),
          }}
          slotProps={{
            footer: {
              apiRef: apiRef.current,
            },
          }}
          paginationMode="server"
          sortingMode="server"
          filterMode="server"
          rowGroupingColumnMode="single"
          // TODO: Breaks filters
          groupingColDef={
            props.groupingColDef ||
            (models.rowGroupingModel?.length ? { filterable: false, sortable: false, hideable: false } : undefined)
          }
          pagination
          {...props}
          columns={columns}
          onPaginationModelChange={onPaginationModelChange}
          paginationModel={models.paginationModel}
          sortModel={models.sortModel}
          onSortModelChange={onSortModelChange}
          filterModel={models.filterModel}
          rowSelectionModel={models.rowSelectionModel}
          onRowSelectionModelChange={onRowSelectionModelChange}
          onAggregationModelChange={onAggregationModelChange}
          columnVisibilityModel={models.columnVisibilityModel}
          onFilterModelChange={onFilterModelChange}
          onColumnVisibilityModelChange={onColumnVisibilityModelChange}
          rowGroupingModel={models.rowGroupingModel}
          onRowGroupingModelChange={onRowGroupingModelChange}
          initialState={initialState}
        />
      </div>
    );
  },
);
