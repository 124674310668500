import * as yup from 'yup';
import { userAddressFormFields } from '../../fields/formFields';

export const userAddressFormSchema = yup.object({
  [userAddressFormFields.streetName]: yup
    .string()
    .max(150, 'Street Name must be at most 150 characters')
    .required('Street Name is required')
    .trim(),
  [userAddressFormFields.city]: yup
    .string()
    .max(50, 'City must be at most 50 characters')
    .required('City is required')
    .trim(),
  [userAddressFormFields.address2]: yup
    .string()
    .max(255, 'Address2 must be at most 255 characters')
    .required('Address2 is required')
    .trim(),
  [userAddressFormFields.address1]: yup
    .string()
    .max(255, 'Address1 must be at most 255 characters')
    .required('Address1 is required')
    .trim(),
  [userAddressFormFields.additionalNumber]: yup
    .string()
    .max(10, 'Additional Number must be at most 10 characters')
    .required('Additional Number is required')
    .trim(),
  [userAddressFormFields.district]: yup
    .string()
    .max(100, 'District must be at most 100 characters')
    .required('District is required')
    .trim(),
  [userAddressFormFields.unitNumber]: yup
    .string()
    .max(10, 'Unit Number must be at most 10 characters')
    .required('Unit Number is required')
    .trim(),
  [userAddressFormFields.isPrimaryAddress]: yup.boolean().required('Is Primary Address is required'),
  [userAddressFormFields.buildingNumber]: yup
    .string()
    .max(10, 'Building Number must be at most 10 characters')
    .required('Building Number is required')
    .trim(),
  [userAddressFormFields.postCode]: yup
    .string()
    .max(10, 'Post Code must be at most 10 characters')
    .required('Post Code is required')
    .trim(),
  [userAddressFormFields.locationCoordinates]: yup
    .string()
    .max(150, 'Location Coordinates must be at most 150 characters')
    .required('Location Coordinates is required')
    .trim(),
});
