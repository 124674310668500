import { createSelector } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { ReducerState } from '../../store.types';
import { AlpacaState } from './alpaca.types';

export const alpacaSelector = ({ alpaca }: ReducerState) => alpaca;
export const alpacaEventsLoadingSelector = createSelector(
  alpacaSelector,
  ({ fetchStatus }) => fetchStatus === fetchStatuses.pending,
);
export const alpacaOrdersLoadingSelector = createSelector(
  alpacaSelector,
  ({ fetchOrdersStatus }) => fetchOrdersStatus === fetchStatuses.pending,
);
export const alpacaOrderByRequestIdSelector = createSelector(
  alpacaSelector,
  (state: ReducerState, requestId: string) => requestId,
  (alpacaState: AlpacaState, requestId: string) => ({
    orders: alpacaState.orders[requestId] || [],
    totalOrders: alpacaState.totalOrders[requestId] || 0,
    fetchStatus: alpacaState.fetchStatuses[requestId],
  }),
);

export const alpacaTransfersByRequestIdSelector = createSelector(
  alpacaSelector,
  (state: ReducerState, requestId: string) => requestId,
  (alpacaState: AlpacaState, requestId: string) => ({
    transfers: alpacaState.transfers[requestId] || [],
    totalTransfers: alpacaState.totalTransfers[requestId] || 0,
    fetchStatus: alpacaState.fetchStatuses[requestId],
  }),
);
