import { ApiResponse } from './api';

export enum OrderExecutionStatus {
  CREATED = 'Created',
  EXECUTION = 'Execution',
  IN_REVIEW = 'In Review',
  RECONCILIATION = 'Reconciliation',
  IN_APPROVE = 'In Approve',
  APPROVED = 'Approved',
}

export interface OrderExecutionStatusOption {
  id: number;
  name: OrderExecutionStatus;
}

export type OrderExecution = {
  id: number;
  executionId: string;
  orderId: string;
  status: OrderExecutionStatusOption;
  approvedAt: Date | string | null;
  approvedBy: number | null;
  executedAt: Date | string | null;
  executedBy: number | null;
  importedAt: Date | string | null;
  importedBy: number | null;
  reconciledAt: Date | string | null;
  reconciledBy: number | null;
  reportsGenerated: boolean;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchOrderExecutionByOrderIdResponse = ApiResponse<OrderExecution>;
export type FetchOrderExecutionsResponse = ApiResponse<{
  items: OrderExecution[];
  totalItems: number;
}>;

export enum OrderExecutionReportType {
  USER = 'USER',
  REQUEST = 'REQUEST',
}
