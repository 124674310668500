import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { FakeButtonLink } from '../../components/FakeLink';
import { GridCellExpand, Table } from '../../components/Table';
import { jsonFilterOperators } from '../../components/Table/components/filterOperators/JsonFilterOperators';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { mapUserIdToStatementActor } from '../../constants/payment';
import { fetchStatements } from '../../redux/modules/payment/payment.actions';
import { paymentSelector } from '../../redux/modules/payment/payment.selectors';
import { locations } from '../../routes/locations';
import { PaymentApiService, PaymentStatementStatus } from '../../types/payment';

export function StatementsTab() {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id' },
    {
      field: 'service',
      headerName: 'Service',
      type: 'singleSelect',
      valueOptions: Object.values(PaymentApiService),
      width: 100,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(PaymentStatementStatus),
      width: 120,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 100,
    },
    {
      field: 'type',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: Object.values(PaymentStatementStatus),
      width: 120,
    },
    {
      field: 'userId',
      headerName: 'User Id',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        const staticStatementActor = mapUserIdToStatementActor[params.value];

        if (staticStatementActor) {
          return staticStatementActor;
        }

        return (
          <Tooltip title={params.value} followCursor>
            <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.user(params.value)}>
              {params.value}
            </FakeButtonLink>
          </Tooltip>
        );
      },
    },
    {
      field: 'statementId',
      headerName: 'Statement Id',
      width: 150,
    },
    {
      field: 'groupId',
      headerName: 'Group Id',
      width: 150,
    },
    {
      field: 'transaction',
      headerName: 'Transaction',
      sortable: false,
      type: 'json',
      filterOperators: jsonFilterOperators,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <GridCellExpand value={JSON.stringify(params.value, null, 2) || ''} width={params.colDef.computedWidth} />
      ),
    },
    {
      field: 'error',
      headerName: 'Error',
      sortable: false,
      width: 100,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: false,
      type: 'dateTime',
      width: 170,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'dateTime',
      width: 170,
    },
  ]);
  const { totalPaymentStatements, paymentStatements, fetchPaymentStatementsStatus } = useSelector(paymentSelector);

  return (
    <Table
      fetchItems={fetchStatements}
      rows={paymentStatements}
      columns={columns.current}
      loading={fetchPaymentStatementsStatus === fetchStatuses.pending}
      rowCount={totalPaymentStatements}
      initialColumnVisibilityModel={{ id: false, metadata: false }}
      initialSortModel={[{ field: 'updatedAt', sort: 'desc' }]}
      toolbarExportProps={{
        excelOptions: { fileName: `statements-${Date.now()}` },
        csvOptions: { fileName: `statements-${Date.now()}` },
      }}
    />
  );
}
