export const parseJSON = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (_e) {
    return value;
  }
};

export const stringifyJSON = (value: object) => {
  try {
    return JSON.stringify(value);
  } catch (_e) {
    return value;
  }
};
