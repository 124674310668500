import React from 'react';

export function useInterval() {
  const [time, setTime] = React.useState(0);
  const intervalId = React.useRef<number>(0);

  const clearInterval = () => {
    if (intervalId.current) {
      window.clearInterval(intervalId.current);
    }
    setTime(0);
  };

  const startInterval = (timeout = 1000) => {
    intervalId.current = window.setInterval(
      (initialActionTime) => {
        setTime(Date.now() - initialActionTime);
      },
      timeout,
      Date.now(),
    );
  };

  return {
    start: startInterval,
    stop: clearInterval,
    setTimer: setTime,
    time,
  };
}
