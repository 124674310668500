import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import Chip from '@mui/material/Chip';
import { Table } from '../../components/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { fetchUserSurveyResults } from '../../redux/modules/user/user.actions';
import { userSelector } from '../../redux/modules/user/user.selectors';
import { UserSurveyResultStatus } from '../../types/user';

interface UserSurveyResultsTabProps {
  id: number;
}

const userSurveyResultStatusToInfo: {
  [prop in UserSurveyResultStatus]: {
    color: 'error' | 'info' | 'success' | 'warning';
    icon: React.ElementType;
  };
} = {
  [UserSurveyResultStatus.PENDING]: {
    color: 'info',
    icon: InfoIcon,
  },
  [UserSurveyResultStatus.COMPLETED]: {
    color: 'success',
    icon: CheckCircleIcon,
  },
};

export function UserSurveyResultsTab({ id }: UserSurveyResultsTabProps) {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id' },
    {
      field: 'score',
      headerName: 'Score',
      sortable: false,
      width: 200,
    },
    {
      field: 'riskGroup.type',
      headerName: 'Risk Group',
      type: 'string',
      sortable: false,
      flex: 1,
      valueGetter: (params) => params.row?.riskGroup?.type,
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'string',
      sortable: false,
      minWidth: 150,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = userSurveyResultStatusToInfo[params.row?.status?.name as UserSurveyResultStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.row?.status?.name;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.row?.status?.name} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 100,
    },
  ]);
  const { totalUserSurveyResultsItems, userSurveyResultsItems, fetchUserSurveyResultsStatus } =
    useSelector(userSelector);

  return (
    <Table
      fetchItems={fetchUserSurveyResults}
      fetchItemsCustomParams={{ userId: id }}
      rows={userSurveyResultsItems}
      columns={columns.current}
      loading={fetchUserSurveyResultsStatus === fetchStatuses.pending}
      rowCount={totalUserSurveyResultsItems}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
    />
  );
}
