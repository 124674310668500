import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/system/Box';
import React from 'react';
import Tab from '@mui/material/Tab';
import { UserBankInfo } from '../../../../types/user';
import { UserBankDetailsHistoryTable } from './UserBankDetailsHistoryTable';
import { BankDetailsTab } from './BankDetailsTab';
import { MTabPanel } from '../../../../components/TabPanel';

interface BankDetailsTabListProps {
  userBankInfo: UserBankInfo;
}

export function BankDetailsTabList({ userBankInfo }: BankDetailsTabListProps) {
  const [selectedTab, setSelectedTab] = React.useState<string>('0');

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <TabContext value={selectedTab}>
      <Box>
        <TabList
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="alpaca fund orders"
        >
          <Tab value="0" label="Bank Details" sx={{ textTransform: 'capitalize' }} disableRipple />
          <Tab value="1" label="History" sx={{ textTransform: 'capitalize' }} disableRipple />
        </TabList>
      </Box>
      <MTabPanel value="0">
        <BankDetailsTab userBankInfo={userBankInfo} />
      </MTabPanel>
      <MTabPanel value="1">
        {userBankInfo?.userBankDetails?.id && (
          <UserBankDetailsHistoryTable userBankDetailsId={userBankInfo.userBankDetails.id} />
        )}
      </MTabPanel>
    </TabContext>
  );
}
