import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/system/Box';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Tab from '@mui/material/Tab';
import { useAppDispatch } from '../../redux/hooks';
import { fetchOrdersByMadkholOrderId } from '../../redux/modules/jadwa/jadwa.actions';
// eslint-disable-next-line import/no-unresolved
import { jadwaSelector } from '../../redux/modules/jadwa/jadwa.selectors';
import { JadwaDetails } from './components/jadwa';

interface JadwaTabProps {
  orderId: string;
}

export function JadwaTab({ orderId }: JadwaTabProps) {
  const dispatch = useAppDispatch();
  const { orders, error } = useSelector(jadwaSelector);
  const [selectedTab, setSelectedTab] = React.useState<string>('0');

  const fetchOrders = useCallback(() => {
    dispatch(fetchOrdersByMadkholOrderId(orderId));
  }, [orderId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (orderId) {
      fetchOrders();
    }
  }, [orderId]);

  const renderOrders = () => {
    if (!orders.length) {
      return;
    }

    return (
      <TabContext value={selectedTab}>
        <Box sx={{ width: '100%' }}>
          <TabList
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="jadwa fund orders"
          >
            {orders.map((order, index) => (
              <Tab
                key={`jadwa-fund-order-${order.id}`}
                value={index.toString()}
                label={`Fund Order #${order.fundOrderId}`}
                sx={{ textTransform: 'capitalize' }}
                disableRipple
              />
            ))}
          </TabList>
        </Box>
        {orders.map((order, index) => (
          <TabPanel key={`jadwa-fund-order-panel-${order.id}`} value={index.toString()} sx={{ width: '100%' }}>
            <JadwaDetails order={order} refresh={fetchOrders} />
          </TabPanel>
        ))}
      </TabContext>
    );
  };

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      {renderOrders()}
    </>
  );
}
