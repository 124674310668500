import { useEffect, useState } from 'react';
import i18nInitial from '../../utils/i18n';
import { Loader } from '../Loader';
import { AppView } from './AppView';
import './reset.scss';

export function App() {
  const [translationsLoaded, setTranslationsLoaded] = useState(false);

  useEffect(() => {
    i18nInitial((v) => setTranslationsLoaded(v));
  }, []);

  return translationsLoaded ? <AppView /> : <Loader />;
}
