import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchBatchExecutionByBatchIdResponse, FetchBatchExecutionsResponse } from '../../../types/batchExecution';
import { fetchBatchExecutions, fetchBatchExecutionByBatchId, cleanBatchExecutions } from './batchExecution.actions';
import { BatchExecutionState } from './batchExecution.types';

const initialState: BatchExecutionState = {
  batchExecutions: {},
  totalBatchExecutions: {},
  fetchStatuses: {},

  batchExecutionByBatchId: {},
  fetchBatchExecutionByBatchIdStatus: fetchStatuses.idle,

  error: null,
};

const batchExecutionSlice = createSlice({
  name: 'batchExecution',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBatchExecutions.pending, (state, action) => {
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(fetchBatchExecutions.fulfilled, (state, action: PayloadAction<FetchBatchExecutionsResponse>) => {
      const requestId = (action as any).meta.arg.requestId || (action as any).meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.success;

      if (action.payload) {
        state.batchExecutions[requestId] = action.payload.data?.items;
        state.totalBatchExecutions[requestId] = action.payload.data?.totalItems;
      }
    });
    builder.addCase(fetchBatchExecutions.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.rejected;

      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchBatchExecutionByBatchId.pending, (state) => {
      state.error = null;
      state.fetchBatchExecutionByBatchIdStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchBatchExecutionByBatchId.fulfilled,
      (state, action: PayloadAction<FetchBatchExecutionByBatchIdResponse>) => {
        const batchId = (action as any).meta.arg || (action as any).payload.data?.batchId;

        state.batchExecutionByBatchId[batchId] = action.payload.data;
        state.fetchBatchExecutionByBatchIdStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchBatchExecutionByBatchId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.fetchBatchExecutionByBatchIdStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(cleanBatchExecutions.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const batchExecutionReducer = batchExecutionSlice.reducer;
