import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useAppDispatch } from '../../../../redux/hooks';
import { fetchSubscribedPortfolios } from '../../../../redux/modules/subscribedPortfolio/subscribedPortfolio.actions';
import { fetchUserBankInfo } from '../../../../redux/modules/user/user.actions';
import { madkhoulBankInfoSelector, userSelector } from '../../../../redux/modules/user/user.selectors';
import { BankDetailsTabList } from './BankDetailsTabList';
import { OrdersTabList } from './OrdersTabList';
import { SubscribedPortfolioTabList } from './SubscribedPortfolioTabList';
import { TransactionsTable } from './TransactionsTable';
import { TransactionsLogsTable } from './TransactionsLogsTable';
import { UserTransactionsTable } from './UserTransactionsTable';
import { UserRecurringSubscriptionsTable } from './UserRecurringSubscriptionsTable';

interface OwnedSharesTabProps {
  userId: number;
}

export function OwnedSharesTab({ userId }: OwnedSharesTabProps) {
  const dispatch = useAppDispatch();
  const { error } = useSelector(userSelector);
  const bankInfo = useSelector(madkhoulBankInfoSelector);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserBankInfo(userId));
      dispatch(
        fetchSubscribedPortfolios({
          limit: 100,
          offset: 0,
          sortField: 'id',
          sortOrder: 'desc',
          filter: JSON.stringify({
            items: [
              { columnField: 'userId', operatorValue: '=', value: userId },
              // { columnField: 'status.name', operatorValue: 'is', value: SubscribedPortfolioStatus.ACCEPTED },
            ],
          }),
          relations: ['subscribedPortfolioAssociatedIndexFund'],
        }),
      );
    }
  }, []);

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      <Grid
        item
        container
        flexDirection="column"
        component={Paper}
        sx={{ p: '24px', mt: 0, height: '450px' }}
        spacing={2}
      >
        <Grid item>
          <Typography variant="h6">Bank Details</Typography>
        </Grid>
        <Grid item sx={{ position: 'relative', width: '100%' }}>
          {bankInfo && <BankDetailsTabList userBankInfo={bankInfo} />}
        </Grid>
      </Grid>
      <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}>
        <Grid item>
          <Typography variant="h6">Subscribed Portfolios</Typography>
        </Grid>
        <Grid item sx={{ position: 'relative', width: '100%' }}>
          <SubscribedPortfolioTabList userId={userId} />
        </Grid>
      </Grid>
      <Grid
        item
        container
        flexDirection="column"
        component={Paper}
        sx={{ p: '24px', mt: '24px', height: '450px' }}
        spacing={2}
      >
        <Grid item>
          <Typography variant="h6">Orders Details</Typography>
        </Grid>
        <Grid item sx={{ position: 'relative', width: '100%' }}>
          <OrdersTabList userId={userId} />
        </Grid>
      </Grid>
      <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}>
        <Typography variant="h6">User Recurring Subscriptions</Typography>
        <UserRecurringSubscriptionsTable userId={userId} />
      </Grid>
      {userId && (
        <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}>
          <Typography variant="h6">User Transactions</Typography>
          <UserTransactionsTable userId={userId} />
        </Grid>
      )}
      {bankInfo?.id && (
        <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}>
          <Typography variant="h6">Transactions</Typography>
          <TransactionsTable userBankId={bankInfo.id} />
        </Grid>
      )}
      {bankInfo?.id && (
        <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}>
          <Typography variant="h6">Transactions Logs</Typography>
          <TransactionsLogsTable userBankId={bankInfo.id} />
        </Grid>
      )}
    </>
  );
}
