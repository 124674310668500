import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { employeeService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { CreateEmployeeRequest, UpdateEmployeeRequest, UpdateEmployeeStatusRequest } from '../../../types/employee';

export const fetchEmployees = createAsyncThunk('employee/fetch-all', (data: PaginatedRequest) =>
  employeeService.fetchEmployees(data),
);

export const fetchEmployee = createAsyncThunk('employee/get', (id: number) => employeeService.fetchEmployee(id));

export const deleteEmployee = createAsyncThunk('employee/delete', (id: number) => employeeService.deleteEmployee(id));

export const createEmployee = createAsyncThunk('employee/create', (employee: CreateEmployeeRequest) =>
  employeeService.createEmployee(employee),
);

export const updateEmployee = createAsyncThunk('employee/update', (employee: UpdateEmployeeRequest) =>
  employeeService.updateEmployee(employee),
);

export const updateEmployeeStatus = createAsyncThunk('employee/update-status', (data: UpdateEmployeeStatusRequest) =>
  employeeService.updateEmployeeStatus(data),
);

export const resetEmployeePassword = createAsyncThunk('employee/reset-password', (id: number) =>
  employeeService.resetEmployeePassword(id),
);

export const cleanEmployees = createAction('employee/clean');

export const resetEditEmployee = createAction('employee/reset-edit');
