import InsightsIcon from '@mui/icons-material/Insights';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Alert from '@mui/material/Alert';
import { AsyncDialog, ConfirmationDialog } from '../../components/AsyncDialog';
import { AsyncDialogRef } from '../../components/AsyncDialog/AsyncDialog';
import { ConfirmationDialogData } from '../../components/AsyncDialog/ConfirmationDialog';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { usePermissions } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import { triggerJadwaTransfer } from '../../redux/modules/jadwa/jadwa.actions';
import { jadwaSelector } from '../../redux/modules/jadwa/jadwa.selectors';
import * as permissions from '../../utils/permissions';

export function ActionsTab() {
  const { t } = useTranslation('apiError');
  const dispatch = useAppDispatch();
  const asyncDialogRef = useRef<AsyncDialogRef<ConfirmationDialogData, boolean>>(null);
  const { triggerTransferStatus, error } = useSelector(jadwaSelector);
  const { enqueueSnackbar } = useSnackbar();
  const canTriggerTransfer = usePermissions({ items: [permissions.JadwaPermissions.triggerTransfer] });

  const onJadwaTransfer = async () => {
    const isConfirmed = await asyncDialogRef.current?.show({
      title: 'Trigger Jadwa Transfer',
      description: 'Are you sure you want to trigger Jadwa Transfer now?',
    });

    if (isConfirmed) {
      try {
        await dispatch(triggerJadwaTransfer({ type: 'SUBSCRIPTION' })).unwrap();

        enqueueSnackbar('Jadwa Transfer triggered successfully!', { variant: 'success' });
      } catch (err: any) {
        enqueueSnackbar(`Trigger Jadwa Transfer failed: ${t(err?.message)} ${JSON.stringify(err?.data)}`, {
          variant: 'error',
        });
      }
    }
  };

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}

      <Grid container>
        <Grid item>
          <LoadingButton
            type="button"
            color="primary"
            variant="contained"
            endIcon={<InsightsIcon />}
            loading={triggerTransferStatus === fetchStatuses.pending}
            loadingPosition="end"
            disabled={!canTriggerTransfer}
            onClick={onJadwaTransfer}
          >
            Trigger Jadwa Transfer
          </LoadingButton>
        </Grid>
      </Grid>

      <AsyncDialog ref={asyncDialogRef} dialog={ConfirmationDialog} />
    </>
  );
}
