import { createAsyncThunk } from '@reduxjs/toolkit';
import { alpacaTransferService } from '../../../api/alpacaTransferService';
import { CreateManualAlpacaTransferRequest } from '../../../types/alpacaTransfer';

export const createManualAlpacaTransfer = createAsyncThunk(
  'alpacaTransfer/create',
  (data: CreateManualAlpacaTransferRequest) => alpacaTransferService.createManualAlpacaTransfer(data),
);

export const runAlpacaPrefunding = createAsyncThunk('alpacaPrefundingTransfer/run', () =>
  alpacaTransferService.runAlpacaPrefunding(),
);
