import { CreateManualAlpacaTransferRequest } from '../types/alpacaTransfer';
import { ApiService } from './common/ApiService';

class AlpacaTransferService extends ApiService {
  pathname = 'admin/alpaca/transfer';

  createManualAlpacaTransfer = async (data: CreateManualAlpacaTransferRequest) =>
    this.put(`${this.pathname}/prefunding/manual`, data);

  runAlpacaPrefunding = async () => this.put(`${this.pathname}/prefunding/run`, {});
}

export const alpacaTransferService = new AlpacaTransferService();
