import React, { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { GridActionsCellItem, GridActionsCellItemProps, GridRowParams, GridColDef } from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { RtkQueryTable } from '../../components/Table';
import { RtkQueryTableModels } from '../../components/Table/RtkQueryTable';
import { TableRef } from '../../components/Table/Table';
import { converTableModelsToApiArgs } from '../../helpers/api';
import { usePermissions } from '../../hooks';
import { useDeleteRoleMutation, useFetchRolesQuery } from '../../redux/features/role/role.apiSlice';
import { Role } from '../../types/role';
import * as permissions from '../../utils/permissions';
import { RoleDialog } from './RoleDialog';
import { ProtectedContent } from '../../components/ProtectedContent';

export function RoleList() {
  const tableRef = useRef<TableRef>(null);
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState<Role | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 100,
    },
  ]);
  const canDelete = usePermissions({ items: [permissions.Role.delete] });
  const canUpdate = usePermissions({ items: [permissions.Role.update] });
  const [tableModels, setTableModels] = useState<RtkQueryTableModels>({
    paginationModel: { page: 0, pageSize: 100 },
  });

  const [deleteRole, { isLoading: isDeleteLoading }] = useDeleteRoleMutation();
  const { data: roles, isFetching } = useFetchRolesQuery(converTableModelsToApiArgs(tableModels));

  const handleClose = () => {
    setEditValue(null);
    setOpen(false);
  };

  const onDeleteRole = async (id: number) => {
    try {
      await deleteRole(id).unwrap();

      handleClose();
      enqueueSnackbar('Role deleted!', { variant: 'success' });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Role deletion error!', { variant: 'error' });
    }
  };

  const onEditRole = (row: Role): void => {
    setEditValue(row);
    setOpen(true);
  };

  const onAddNew = (): void => {
    setOpen(true);
  };

  const onModelsChange = (models: RtkQueryTableModels) => {
    setTableModels(models);
  };

  useEffect(() => {
    const actionColumn = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      filterable: false,
      sortable: false,
      getActions: (params: GridRowParams) => {
        const actions: React.ReactElement<GridActionsCellItemProps>[] = [];

        if (canDelete) {
          actions.push(
            <GridActionsCellItem
              key={`role-list-delete-${params.row.id}`}
              icon={<DeleteOutlineOutlinedIcon />}
              label="Link"
              onClick={() => onDeleteRole(params.row.id)}
            />,
          );
        }

        if (canUpdate) {
          actions.push(
            <GridActionsCellItem
              key={`role-list-update-${params.row.id}`}
              icon={<EditOutlinedIcon />}
              label="Actions"
              onClick={() => onEditRole(params.row)}
            />,
          );
        }

        return actions;
      },
    };

    if (canDelete || canUpdate) {
      setColumns([actionColumn, ...columns]);
    }
  }, [canDelete, canUpdate]);

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">
          Roles &nbsp;
          <ProtectedContent items={[permissions.Role.create]}>
            <Fab color="primary" size="small" aria-label="Create Role" onClick={onAddNew}>
              <AddIcon />
            </Fab>
          </ProtectedContent>
        </Typography>
      </Box>

      <RoleDialog open={open} onClose={handleClose} initialValue={editValue} />

      <RtkQueryTable
        ref={tableRef}
        models={tableModels}
        onModelsChange={onModelsChange}
        rows={roles?.items || []}
        columns={columns}
        loading={isFetching || isDeleteLoading}
        rowCount={roles?.totalItems || 0}
      />
    </Container>
  );
}
