import { ActionType } from '../../types/action';

const listPermission = {
  resource: 'portfolio-orders',
  action: ActionType.READ,
};

const createPermission = {
  resource: 'portfolio-order',
  action: ActionType.CREATE,
};
const updatePermission = {
  resource: 'portfolio-order',
  action: ActionType.UPDATE,
};
const readPermission = {
  resource: 'portfolio-order',
  action: ActionType.READ,
};
const deletePermission = {
  resource: 'portfolio-order',
  action: ActionType.DELETE,
};
const executePermission = {
  resource: 'portfolio-order-execute',
  action: ActionType.CREATE,
};

export const PortfolioOrder = {
  list: listPermission,
  read: readPermission,
  create: createPermission,
  update: updatePermission,
  delete: deletePermission,
  execute: executePermission,
};
