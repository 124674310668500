import * as React from 'react';
import { usePermissions } from '../../hooks';
import { PermissionProps } from '../../layouts/AppPageLayout/Sidebar/types';
import { useFetchMyPermissionsQuery } from '../../redux/features/rolePermission/rolePermission.apiSlice';

interface ProtectedContentProps extends PermissionProps {
  children: JSX.Element;
}

export const ProtectedContent = ({ items = [], checkType = 'every', children }: ProtectedContentProps): JSX.Element => {
  const hasPermission = usePermissions({ items, checkType });

  const { isFetching } = useFetchMyPermissionsQuery();

  if (items?.length && (isFetching || !hasPermission)) {
    return <div />;
  }

  return children;
};
