import { ActionType } from '../../types/action';

const listPermission = {
  resource: 'fee-configs',
  action: ActionType.READ,
};

const createPermission = {
  resource: 'fee-config',
  action: ActionType.CREATE,
};
const updatePermission = {
  resource: 'fee-config',
  action: ActionType.UPDATE,
};
const readPermission = {
  resource: 'fee-config',
  action: ActionType.READ,
};
const deletePermission = {
  resource: 'fee-config',
  action: ActionType.DELETE,
};
const statusUpdatePermission = {
  resource: 'fee-config-status',
  action: ActionType.UPDATE,
};

export const FeeConfig = {
  list: listPermission,
  read: readPermission,
  create: createPermission,
  update: updatePermission,
  delete: deletePermission,
  statusUpdate: statusUpdatePermission,
};
