import { captureException } from '@sentry/react';
import axios, { Axios, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { httpStatusCodes } from '../../constants/httpStatusCodes';
import { delay } from '../../utils/dateTime';

const refreshSkipPath = ['auth/auth/login', 'auth/auth/forgot-password', 'auth/auth/restore-password'];

export class ApiService {
  private readonly instance: Axios;
  private isRefreshingToken = false;

  constructor(baseURL = process.env.REACT_APP_BASE_API_URL) {
    this.instance = axios.create({
      withCredentials: true,
      baseURL: baseURL || process.env.REACT_APP_BASE_API_URL,
      timeout: 10000,
    });

    this.instance.interceptors.response.use(
      (res) => res.data,
      async (err) => {
        const originalConfig = err.config;

        if (err.response) {
          // Access Token was expired
          if (
            err.response.status === httpStatusCodes.unauthorized &&
            // eslint-disable-next-line no-underscore-dangle
            !originalConfig._retry &&
            !refreshSkipPath.includes(originalConfig.url)
          ) {
            // Wait until token has been refreshed and then call client func
            if (this.isRefreshingToken) {
              while (this.isRefreshingToken) {
                await delay(500);
              }
              return this.instance.request(originalConfig);
            }

            this.isRefreshingToken = true;
            // eslint-disable-next-line no-underscore-dangle
            originalConfig._retry = true;

            try {
              const rs = await axios.post(
                `${process.env.REACT_APP_AUTH_API_URI || process.env.REACT_APP_BASE_API_URL}/auth/auth/refresh-token`,
                {},
                { withCredentials: true },
              );
              console.warn('REFRESH', rs);

              localStorage.setItem('accessToken', rs.data.data.accessToken);
              this.isRefreshingToken = false;

              return this.instance.request(originalConfig);
            } catch (_error: unknown) {
              this.isRefreshingToken = false;
              localStorage.removeItem('accessToken');

              console.error('[ApiService refreshToken error]', _error);

              window.postMessage('NotAuthorized');

              return Promise.reject(_error);
            }
          }

          if (err.response.status === httpStatusCodes.forbidden && err.response.data) {
            console.error('[ApiService API response error 403]', err);

            captureException(err);

            return Promise.reject(err.response.data);
          }
        }
        console.error('[ApiService API response error]', err);

        if (Array.isArray(err.response.data.errors)) {
          const messages = err.response.data.errors.reduce((res, item) => {
            const constraints = Object.values(item.constraints) || [];

            return res.concat(constraints);
          }, []);

          err.response.data.message = messages.join('\n');
        }

        return Promise.reject(err.response.data);
      },
    );

    this.instance.interceptors.request.use(
      (config) => {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken) {
          (config.headers as AxiosRequestHeaders).Authorization = `Bearer ${accessToken}`;
        }

        return config;
      },
      (error: unknown) => {
        console.error('[ApiService API request error]', error);

        captureException(error);

        return Promise.reject(error);
      },
    );
  }

  get = <R>(url: string, config?: AxiosRequestConfig) => this.instance.get<unknown, R>(url, config);

  post = <R, B>(url: string, body: B, config?: AxiosRequestConfig): Promise<R> =>
    this.instance.post<unknown, R, B>(url, body, config);

  put = <R, B>(url: string, body: B): Promise<R> => this.instance.put<unknown, R, B>(url, body);

  delete = <R>(url: string, config?: AxiosRequestConfig): Promise<R> => this.instance.delete<unknown, R>(url, config);
}
