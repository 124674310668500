import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import plotComponentFactory from 'react-plotly.js/factory';
import Plotly from 'plotly.js-dist';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useAppDispatch } from '../../redux/hooks';
import { fetchRfmChart } from '../../redux/modules/analytics/analytics.actions';
import { analyticsSelector } from '../../redux/modules/analytics/analytics.selectors';

export const RFMChartWidget = () => {
  const dispatch = useAppDispatch();
  const Plot = plotComponentFactory(Plotly);

  const { rfmChart, fetchRfmChartStatus } = useSelector(analyticsSelector);
  const config = { displayModeBar: false };

  const isLoading = fetchRfmChartStatus === fetchStatuses.pending;

  useEffect(() => {
    dispatch(fetchRfmChart());
  }, []);

  if (isLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  if (!rfmChart) {
    return null;
  }

  return (
    <Grid item m={8} xs={12}>
      <Grid container m={8}>
        <Grid item component={Paper} xs={12}>
          <Box>
            <Box padding={2}>
              <Typography variant="body2" color="text.primary">
                RFM Analysis
              </Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Plot data={rfmChart.data} config={config} layout={{}} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
