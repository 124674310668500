import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import React, { useRef } from 'react';
import { ProtectedContent } from '../../components/ProtectedContent';
import { useUpdateRolePermissionsMutation } from '../../redux/features/rolePermission/rolePermission.apiSlice';
import * as permissions from '../../utils/permissions';
import { PermissionsTable } from './PermissionsTable';
import { PermissionsTableRef } from './PermissionsTable/PermissionsTable';

export function Permissions() {
  const { enqueueSnackbar } = useSnackbar();
  const permissionsTableRef = useRef<PermissionsTableRef>(null);

  const [updateRolePermissions, { isLoading }] = useUpdateRolePermissionsMutation();

  const onPermissionsSave = async () => {
    if (permissionsTableRef.current) {
      if (!permissionsTableRef.current?.isReady()) {
        return;
      }

      try {
        const payload = permissionsTableRef.current.getPermissions();

        await updateRolePermissions(payload).unwrap();

        enqueueSnackbar('Permissions update!', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Permissions update failed!', { variant: 'error' });
      }
    }
  };

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4" pb={2}>
          Role Permissions &nbsp;
        </Typography>

        <PermissionsTable ref={permissionsTableRef} />

        <Box display="flex" justifyContent="space-between" flexDirection="row" alignItems="center" sx={{ pt: 2 }}>
          <div />
          <ProtectedContent items={[permissions.RolePermission.updateAll]}>
            <LoadingButton
              loading={isLoading}
              loadingPosition="end"
              endIcon={<SaveIcon />}
              variant="outlined"
              onClick={onPermissionsSave}
            >
              Save
            </LoadingButton>
          </ProtectedContent>
        </Box>
      </Box>
    </Container>
  );
}
