import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { userConfigService } from '../../../api';
import { CreateUserConfigRequest, UpdateUserConfigRequest } from '../../../types/userConfig';

export const fetchUserConfigs = createAsyncThunk('userConfig/fetch-all', () => userConfigService.fetchUserConfigs());

export const fetchUserConfig = createAsyncThunk('userConfig/get', (key: string) =>
  userConfigService.fetchUserConfig(key),
);

export const deleteUserConfig = createAsyncThunk('userConfig/delete', (key: string) =>
  userConfigService.deleteUserConfig(key),
);

export const createUserConfigItem = createAsyncThunk('userConfig/create', (userConfig: CreateUserConfigRequest) =>
  userConfigService.createUserConfig(userConfig),
);

export const updateUserConfig = createAsyncThunk('userConfig/update', (userConfig: UpdateUserConfigRequest) =>
  userConfigService.updateUserConfig(userConfig),
);

export const updateUserConfigs = createAsyncThunk('userConfig/update-all', (userConfigs: UpdateUserConfigRequest[]) =>
  userConfigService.updateUserConfigs(userConfigs),
);

export const resetEditUserConfig = createAction('userConfig/reset-edit');

export const cleanUserConfigs = createAction('userConfig/clean');
