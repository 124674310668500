import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { assetService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { CreateAssetRequest, UpdateAssetRequest } from '../../../types/asset';

export const fetchAssets = createAsyncThunk('asset/fetch-all', (data: PaginatedRequest) =>
  assetService.fetchAssets(data),
);

export const fetchAsset = createAsyncThunk('asset/get', (id: number) => assetService.fetchAsset(id));

export const deleteAsset = createAsyncThunk('asset/delete', (id: number) => assetService.deleteAsset(id));

export const createAsset = createAsyncThunk('asset/create', (asset: CreateAssetRequest) =>
  assetService.createAsset(asset),
);

export const updateAsset = createAsyncThunk('asset/update', (asset: UpdateAssetRequest) =>
  assetService.updateAsset(asset),
);

export const resetEditAsset = createAction('asset/reset-edit');

export const cleanAssets = createAction('asset/clean');
