import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IIdleTimer, useIdleTimer } from 'react-idle-timer';
import { useSelector } from 'react-redux';
import { useLogoutMutation } from '../../redux/features/auth/auth.apiSlice';
import { authSelector } from '../../redux/features/auth/auth.selectors';

export const LogoutIdleTimer = () => {
  const [open, setOpen] = React.useState(false);
  const [remaining, setRemaining] = React.useState(0);
  const timeout = 1000 * 60 * 10;
  const promptBeforeIdle = 1000 * 60;

  const [logout] = useLogoutMutation();
  const { isLoggedIn } = useSelector(authSelector);

  const onPrompt = (event?: Event, idleTimer?: IIdleTimer) => {
    if (!isLoggedIn) {
      return setRemaining(promptBeforeIdle);
    }

    setOpen(true);
  };

  const onIdle = () => {
    setOpen(false);
    setRemaining(0);
    logout({});
  };

  const onActive = (event?: Event, idleTimer?: IIdleTimer) => {
    if (idleTimer?.isPrompted()) {
      setOpen(false);
      setRemaining(0);
    }
  };

  const { activate, isPrompted, getRemainingTime } = useIdleTimer({
    timeout,
    promptBeforeIdle,
    onPrompt,
    onIdle,
    onActive,
    startOnMount: true,
    crossTab: true,
  });

  const handleClose = () => {
    setOpen(false);
    activate();
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return (
    <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">You Have Been Idle!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You Will Get Timed Out in&nbsp;
          <span>{remaining}</span>
          &nbsp;s. You want to stay?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={onIdle}>
          Logout
        </Button>
        <Button color="primary" onClick={handleClose}>
          Stay
        </Button>
      </DialogActions>
    </Dialog>
  );
};
