import { PaginatedRequest } from '../types/api';
import {
  FetchUsersResponse,
  FetchUserResponse,
  UpdateUserRequest,
  UpdateUserResponse,
  FetchUserStatusOptionsResponse,
  UpdateUserStatusRequest,
  UpdateUserStatusResponse,
  CreateUserRequest,
  CreateUserResponse,
  FetchUserHistoryResponse,
  FetchUserTransactionsResponse,
  FetchUserBankInfoResponse,
  FetchUserSurveyResultsResponse,
  FetchUserAttachmentsResponse,
  FetchUserAttachmentsDocumentTypeOptionsResponse,
  CreateUserAttachmentsRequest,
  CreateUserAttachmentResponse,
  WithdrawUserRequest,
  WithdrawUserResponse,
  FetchUserWorldCheckScreenResponse,
  FetchUserSignAgreementsResponse,
  FetchUserSubscriptionsResponse,
} from '../types/user';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class UserService extends ApiService {
  pathname = 'admin';

  fetchUsers = async (data: PaginatedRequest) =>
    this.get<FetchUsersResponse>(`${this.pathname}/user?${toQueryParams(data)}`);

  fetchUser = async (id: number) => this.get<FetchUserResponse>(`${this.pathname}/user/${id}`);

  fetchUserWorldCheckScreen = async (id: number) =>
    this.get<FetchUserWorldCheckScreenResponse>(`${this.pathname}/user/${id}/world-check`);

  fetchUserSignAgreements = async (data: PaginatedRequest) =>
    this.get<FetchUserSignAgreementsResponse>(`${this.pathname}/user-sign-agreement?${toQueryParams(data)}`);

  fetchUserHistory = async ({ userId, ...data }: PaginatedRequest & { userId: number }) =>
    this.get<FetchUserHistoryResponse>(`${this.pathname}/user/${userId}/history?${toQueryParams(data)}`);

  fetchUserTransactions = async ({ userId, ...data }: PaginatedRequest & { userId: number }) =>
    this.get<FetchUserTransactionsResponse>(`${this.pathname}/user/${userId}/transactions?${toQueryParams(data)}`);

  fetchUserBankInfo = async (userId: number) =>
    this.get<FetchUserBankInfoResponse>(`${this.pathname}/user/${userId}/bank-info`);

  fetchUserSurveyResults = async ({ userId, ...data }: PaginatedRequest & { userId: number }) =>
    this.get<FetchUserSurveyResultsResponse>(`${this.pathname}/user/${userId}/survey-results?${toQueryParams(data)}`);

  fetchUserAttachments = async (userId: number) =>
    this.get<FetchUserAttachmentsResponse>(`${this.pathname}/user/${userId}/attachments`);

  fetchUserStatusOptions = async () => this.get<FetchUserStatusOptionsResponse>(`${this.pathname}/user/status/options`);

  fetchUserAttachmentsDocumentTypeOptions = async () =>
    this.get<FetchUserAttachmentsDocumentTypeOptionsResponse>(
      `${this.pathname}/user/attachments/document-type/options`,
    );

  createUser = async (user: CreateUserRequest) =>
    this.post<CreateUserResponse, Omit<CreateUserRequest, 'id'>>(`${this.pathname}/user`, user);

  createUserAttachments = async ({ userId, ...data }: CreateUserAttachmentsRequest & { userId: number }) =>
    this.post<CreateUserAttachmentResponse, Omit<CreateUserAttachmentsRequest, 'id'>>(
      `${this.pathname}/user/${userId}/attachments`,
      data,
    );

  updateUser = async ({ id, ...user }: UpdateUserRequest) =>
    this.put<UpdateUserResponse, Omit<UpdateUserRequest, 'id'>>(`${this.pathname}/user/${id}`, user);

  updateUserStatus = async ({ id, ...data }: UpdateUserStatusRequest) =>
    this.put<UpdateUserStatusResponse, Omit<UpdateUserStatusRequest, 'id'>>(`${this.pathname}/user/${id}/status`, data);

  withdraw = async ({ id, ...data }: WithdrawUserRequest) =>
    this.post<WithdrawUserResponse, Omit<WithdrawUserRequest, 'id'>>(`${this.pathname}/user/${id}/withdraw`, data);

  fetchUserSubscriptions = async ({ userId }: PaginatedRequest & { userId: number }) =>
    this.get<FetchUserSubscriptionsResponse>(`${this.pathname}/user/${userId}/subscriptions`);
}

export const userService = new UserService();
