import { PaginatedRequest } from '../types/api';
import {
  SaveUserFeeRequest,
  CreateUserFeeResponse,
  FetchUserFeesResponse,
  FetchUserFeeResponse,
  UpdateUserFeeRequest,
  UpdateUserFeeResponse,
  FetchUserFeeHistoryResponse,
} from '../types/userFee';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class UserFeeService extends ApiService {
  pathname = 'admin/user-fee';

  fetchUserFees = async (data: PaginatedRequest) =>
    this.get<FetchUserFeesResponse>(`${this.pathname}?${toQueryParams(data)}`);

  fetchUserFeeHistory = async (data: PaginatedRequest) =>
    this.get<FetchUserFeeHistoryResponse>(`${this.pathname}/history?${toQueryParams(data)}`);

  fetchUserFeesByUser = async ({ userId, ...data }: PaginatedRequest & { userId: number }) =>
    this.get<FetchUserFeesResponse>(`${this.pathname}/${userId}?${toQueryParams(data)}`);

  fetchUserFee = async (id: number) => this.get<FetchUserFeeResponse>(`${this.pathname}/${id}`);

  deleteUserFee = async (id: number) => this.delete<FetchUserFeeResponse>(`${this.pathname}/${id}`);

  saveUserFee = async (portfolio: SaveUserFeeRequest) =>
    this.post<CreateUserFeeResponse, SaveUserFeeRequest>(`${this.pathname}`, portfolio);

  updateUserFee = async ({ id, ...data }: UpdateUserFeeRequest) =>
    this.put<UpdateUserFeeResponse, Omit<UpdateUserFeeRequest, 'id'>>(`${this.pathname}/${id}`, data);
}

export const userFeeService = new UserFeeService();
