import { PaginatedRequest } from '../types/api';
import { toQueryParams } from '../utils/request';
import { FetchMastercardSessionsResponse } from '../types/mastercard';
import { ApiService } from './common/ApiService';

class MastercardService extends ApiService {
  pathname = 'admin/mastercard';

  fetchSessions = async (data: PaginatedRequest) =>
    this.get<FetchMastercardSessionsResponse>(`${this.pathname}/sessions?${toQueryParams(data)}`);
}

export const mastercardService = new MastercardService();
