import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FieldArray, FormikErrors, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import Alert from '@mui/material/Alert';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Switch from '@mui/material/Switch';
import DialogActions from '@mui/material/DialogActions';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemText from '@mui/material/ListItemText';
import { UploadFileButton } from '../../components/UploadFileButton';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { productTypeFormFields } from '../../forms/fields/formFields';
import { productTypeFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import {
  createProductType,
  fetchProductTypeFileTypeOptions,
} from '../../redux/modules/productType/product-type.actions';
import { productTypeSelector } from '../../redux/modules/productType/product-type.selectors';
import { ProductType, ProductTypeFile, ProductTypeFileTypeEnum, UpdateProductTypeFile } from '../../types/productType';
import { UploadEntity } from '../../types/media';

interface ProductTypeDialogProps {
  open: boolean;
  initialValue: ProductType | null;
  onClose: (isReload: boolean) => void;
}

export function ProductTypeDialog({ open, initialValue, onClose }: ProductTypeDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const formik = useFormik({
    initialValues: {
      [productTypeFormFields.nameEn]: '',
      [productTypeFormFields.nameAr]: '',
      [productTypeFormFields.descriptionEn]: '',
      [productTypeFormFields.descriptionAr]: '',
      [productTypeFormFields.disabled]: false,
      [productTypeFormFields.scoreDependent]: false,
      [productTypeFormFields.isCustom]: false,
      [productTypeFormFields.minimumPortfolioInvestment]: 1000,
      [productTypeFormFields.minimumAddFundInvestment]: 1000,
      [productTypeFormFields.minimumRedemption]: 1000,
      [productTypeFormFields.fee]: 0,
      [productTypeFormFields.files]: [] as ProductTypeFile[],
    },
    validationSchema: productTypeFormSchema,
    onSubmit: (formValues) => {
      dispatch(
        createProductType({
          id: initialValue?.id,
          ...formValues,
        }),
      );
    },
  });

  const { handleSubmit, values, handleChange, touched, errors, submitForm, setValues, resetForm, setFieldValue } =
    formik;
  const { createStatus, updateStatus, error, productTypeFileTypeOptions, fetchFileTypeOptionsStatus } =
    useSelector(productTypeSelector);

  useEffect(() => {
    if (!open) {
      resetForm();
    }

    dispatch(fetchProductTypeFileTypeOptions());
  }, [open]);

  const getDefaultFiles = () =>
    productTypeFileTypeOptions.map((ft) => ({
      fileType: ft.id,
      documentLink: '',
      isPublic: true,
    }));

  useEffect(() => {
const existingFiles =
      initialValue?.files?.map((f) => ({
        id: f?.id,
        documentLink: f.documentLink,
        fileType: (f.fileType as any)?.id,
      })) || [];

    getDefaultFiles().forEach((df) => {
      if (!existingFiles.find((f) => f.fileType === df.fileType)) {
        existingFiles.unshift({ ...df, id: 0 });
      }
    });

    setValues({
      nameEn: initialValue?.nameEn || '',
      nameAr: initialValue?.nameAr || '',
      descriptionEn: initialValue?.descriptionEn || '',
      descriptionAr: initialValue?.descriptionAr || '',
      disabled: initialValue?.disabled || false,
      scoreDependent: initialValue?.scoreDependent || false,
      isCustom: initialValue?.isCustom || false,
      minimumPortfolioInvestment: initialValue?.minimumPortfolioInvestment || 1000,
      minimumAddFundInvestment: initialValue?.minimumAddFundInvestment || 1000,
      minimumRedemption: initialValue?.minimumRedemption || 1000,
      fee: initialValue?.fee || 0,
      files: existingFiles,
    });
  }, [initialValue]);

  const getFileTypeName = (id: number) => productTypeFileTypeOptions.find((ptfo) => ptfo.id === id)?.name;

  useEffect(() => {
    if (fetchFileTypeOptionsStatus === fetchStatuses.success && !values[productTypeFormFields.files].length) {
      setFieldValue(productTypeFormFields.files, getDefaultFiles());
    }
  }, [productTypeFileTypeOptions, fetchFileTypeOptionsStatus]);

  useEffect(() => {
    if (createStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Product Type created!', { variant: 'success' });
    }
    if (createStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Product Type creation error!', { variant: 'error' });
    }

    if (updateStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Product Type updated!', { variant: 'success' });
    }
  }, [createStatus, updateStatus]);

  const getFilesKey = (index: number) => `file-${index}`;

  const renderFileDeleteButton = (item: any, index, arrayHelpers) => (
    <IconButton
      edge="end"
      aria-label="delete"
      onClick={() => {
        arrayHelpers.remove(index);
      }}
    >
      <DeleteIcon />
    </IconButton>
  );

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{initialValue?.id ? 'Edit Product Type class' : 'Create a new Product Type'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error && <Alert severity="error">{error.message}</Alert>}
          Enter the product type details
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id={productTypeFormFields.nameEn}
            name={productTypeFormFields.nameEn}
            label="Product Type Name En"
            value={values[productTypeFormFields.nameEn]}
            onChange={handleChange}
            error={touched.nameEn && !!errors.nameEn}
            helperText={touched.nameEn && errors.nameEn}
            autoComplete="productType-nameEn"
          />
          <TextField
            dir="rtl"
            margin="normal"
            required
            fullWidth
            id={productTypeFormFields.nameAr}
            name={productTypeFormFields.nameAr}
            label="Product Type Name Ar"
            value={values[productTypeFormFields.nameAr]}
            onChange={handleChange}
            error={touched.nameAr && !!errors.nameAr}
            helperText={touched.nameAr && errors.nameAr}
            autoComplete="productType-nameAr"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            multiline
            id={productTypeFormFields.descriptionEn}
            name={productTypeFormFields.descriptionEn}
            label="Product Type Description En"
            value={values[productTypeFormFields.descriptionEn]}
            onChange={handleChange}
            error={touched.descriptionEn && !!errors.descriptionEn}
            helperText={touched.descriptionEn && errors.descriptionEn}
            autoComplete="productType-descriptionEn"
          />
          <TextField
            dir="rtl"
            margin="normal"
            required
            fullWidth
            multiline
            id={productTypeFormFields.descriptionAr}
            name={productTypeFormFields.descriptionAr}
            label="Product Type Description Ar"
            value={values[productTypeFormFields.descriptionAr]}
            onChange={handleChange}
            error={touched.descriptionAr && !!errors.descriptionAr}
            helperText={touched.descriptionAr && errors.descriptionAr}
            autoComplete="productType-descriptionAr"
          />
          <TextField
            margin="normal"
            type="number"
            required
            fullWidth
            id={productTypeFormFields.minimumPortfolioInvestment}
            name={productTypeFormFields.minimumPortfolioInvestment}
            label="Minimum Portfolio Investment"
            value={values[productTypeFormFields.minimumPortfolioInvestment]}
            onChange={handleChange}
            error={touched.minimumPortfolioInvestment && !!errors.minimumPortfolioInvestment}
            helperText={touched.minimumPortfolioInvestment && errors.minimumPortfolioInvestment}
          />
          <TextField
            margin="normal"
            type="number"
            required
            fullWidth
            id={productTypeFormFields.minimumAddFundInvestment}
            name={productTypeFormFields.minimumAddFundInvestment}
            label="Minimum Add Fund Investment"
            value={values[productTypeFormFields.minimumAddFundInvestment]}
            onChange={handleChange}
            error={touched.minimumAddFundInvestment && !!errors.minimumAddFundInvestment}
            helperText={touched.minimumAddFundInvestment && errors.minimumAddFundInvestment}
          />
          <TextField
            margin="normal"
            type="number"
            required
            fullWidth
            id={productTypeFormFields.minimumRedemption}
            name={productTypeFormFields.minimumRedemption}
            label="Minimum Redemption"
            value={values[productTypeFormFields.minimumRedemption]}
            onChange={handleChange}
            error={touched.minimumRedemption && !!errors.minimumRedemption}
            helperText={touched.minimumRedemption && errors.minimumRedemption}
          />
          <TextField
            margin="normal"
            type="number"
            required
            fullWidth
            id={productTypeFormFields.fee}
            name={productTypeFormFields.fee}
            label="Fee"
            value={values[productTypeFormFields.fee]}
            onChange={handleChange}
            error={touched.fee && !!errors.fee}
            helperText={touched.fee && errors.fee}
          />
          <FormControlLabel
            label="Disabled"
            labelPlacement="start"
            componentsProps={{
              typography: { textTransform: 'capitalize' },
            }}
            name={productTypeFormFields.disabled}
            id={productTypeFormFields.disabled}
            control={
              <Switch
                name={productTypeFormFields.disabled}
                checked={values[productTypeFormFields.disabled]}
                onChange={handleChange}
              />
            }
          />
          <FormControlLabel
            label="Score Dependent"
            labelPlacement="start"
            componentsProps={{
              typography: { textTransform: 'capitalize' },
            }}
            name={productTypeFormFields.scoreDependent}
            id={productTypeFormFields.scoreDependent}
            control={
              <Switch
                name={productTypeFormFields.scoreDependent}
                checked={values[productTypeFormFields.scoreDependent]}
                onChange={handleChange}
              />
            }
          />
          <FormControlLabel
            label="Custom Product"
            labelPlacement="start"
            componentsProps={{
              typography: { textTransform: 'capitalize' },
            }}
            name={productTypeFormFields.isCustom}
            id={productTypeFormFields.isCustom}
            control={
              <Switch
                name={productTypeFormFields.isCustom}
                checked={values[productTypeFormFields.isCustom]}
                onChange={handleChange}
              />
            }
          />

          <Grid item xs={12} md={12} lg={12}>
            <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
              <Typography color="text.primary" component="h4" gutterBottom>
                Files
              </Typography>
              <FormikProvider value={formik}>
                <FieldArray
                  name="files"
                  render={(arrayHelpers) => (
                    <>
                      <List>
                        {values[productTypeFormFields.files].map((item, index) => (
                          <ListItem
                            key={getFilesKey(index)}
                            secondaryAction={renderFileDeleteButton(item, index, arrayHelpers)}
                          >
                            <ListItemAvatar>
                              <Avatar>
                                <UploadFileButton
                                  accept="application/pdf"
                                  selectedFile={item.documentLink}
                                  uploadEntity={UploadEntity.indexFund}
                                  multiple
                                  onChange={(uploadedFiles) => {
                                    if (uploadedFiles.length) {
                                      for (let i = 0; i < uploadedFiles.length; i++) {
                                        if (index + i > values[productTypeFormFields.files].length - 1) {
                                          arrayHelpers.push({ documentLink: '' });
                                        }
                                        setFieldValue(`files.${index + i}.documentLink`, uploadedFiles[i]);
                                      }
                                    }
                                  }}
                                  onError={(uploadError) => {
                                    enqueueSnackbar(uploadError, { variant: 'error' });
                                  }}
                                />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                getFileTypeName(item.fileType) ||
                                (errors.files?.[index] as FormikErrors<UpdateProductTypeFile>)?.documentLink
                              }
                              secondary={item.documentLink}
                              primaryTypographyProps={{ fontWeight: 600 }}
                              secondaryTypographyProps={{ color: item.documentLink ? 'unset' : 'red' }}
                            />
                          </ListItem>
                        ))}
                      </List>
                      {/* <Box>
                        <Button variant="outlined" onClick={() => arrayHelpers.push({ documentLink: '' })}>
                          Add
                        </Button>
                      </Box> */}
                    </>
                  )}
                />
                {!!errors.files && typeof errors.files === 'string' && (
                  <FormHelperText error>{errors.files}</FormHelperText>
                )}
              </FormikProvider>
            </Paper>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={createStatus === fetchStatuses.pending}
          loadingPosition="end"
          onClick={submitForm}
        >
          {initialValue?.id ? 'Save' : 'Create'}
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
