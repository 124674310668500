import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useAppDispatch } from '../../redux/hooks';
import { getExampleNavDataFile, importNavDataFile } from '../../redux/modules/indexFund/indexFund.actions';
import { indexFundSelector } from '../../redux/modules/indexFund/indexFund.selectors';
import { IndexFund } from '../../types/indexFund';

interface ImportFundNavDataFileDialogProps {
  open: boolean;
  indexFund: IndexFund;
  onClose: (isReload: boolean) => void;
}

export function ImportFundNavDataFileDialog({ open, indexFund, onClose }: ImportFundNavDataFileDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File>();

  const { importNavDataFileStatus, error } = useSelector(indexFundSelector);

  useEffect(() => {
    if (importNavDataFileStatus === fetchStatuses.success) {
      enqueueSnackbar('Nav data file imported successfully!', { variant: 'success' });
      onClose(true);
    }
    if (importNavDataFileStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Import nav data file was failed!', { variant: 'error' });
    }
  }, [importNavDataFileStatus]);

  useEffect(() => {
    setFile(undefined);
  }, [open]);

  const onImportNavDataFile = () => {
    if (file) {
      dispatch(importNavDataFile({ indexFundId: indexFund.id, file }));
    }
  };

  const onDownloadSampleFile = async () => {
    const res = await dispatch(getExampleNavDataFile());

    const blob = new Blob([res.payload as string], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'example-nav-data.csv';
    link.click();
    link.remove();
  };

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target?.files?.[0];

    if (newFile) {
      setFile(newFile);
    }
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Update Fund Nav Historical Data for {indexFund.nameEn}</DialogTitle>
      <DialogContent>
        <DialogContentText display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          {error && <Alert severity="error">{error.message}</Alert>}
          <Button
            variant="outlined"
            component="label"
            aria-label="import nav data file"
            color="primary"
            startIcon={<UploadIcon />}
          >
            {file ? file.name : 'Select Nav Data file'}
            <input ref={inputRef} hidden accept="text/csv" type="file" onChange={onChangeFile} />
          </Button>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            <LoadingButton
              type="button"
              color="primary"
              variant="outlined"
              endIcon={<UploadIcon />}
              loading={importNavDataFileStatus === fetchStatuses.pending}
              loadingPosition="end"
              onClick={onDownloadSampleFile}
            >
              Download Sample File
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              type="button"
              color="primary"
              variant="contained"
              endIcon={<UploadIcon />}
              loading={importNavDataFileStatus === fetchStatuses.pending}
              loadingPosition="end"
              onClick={onImportNavDataFile}
            >
              Import
            </LoadingButton>
            <Button sx={{ ml: 2 }} color="secondary" variant="contained" onClick={() => onClose(false)}>
              Discard
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
