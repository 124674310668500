import ReportIcon from '@mui/icons-material/Report';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { AsyncThunk } from '@reduxjs/toolkit';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ConfirmationDialog } from '../../../../components/AsyncDialog';
import { AsyncDialog, AsyncDialogRef } from '../../../../components/AsyncDialog/AsyncDialog';
import { ConfirmationDialogData } from '../../../../components/AsyncDialog/ConfirmationDialog';
import { ProtectedContent } from '../../../../components/ProtectedContent';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { useInterval } from '../../../../hooks/useInterval';
import { PermissionPropsItem } from '../../../../layouts/AppPageLayout/Sidebar/types';
import { useAppDispatch } from '../../../../redux/hooks';
import {
  sendOrderToCompleteQueue,
  sendOrderToJournalQueue,
  sendOrderToOrderQueue,
  sendOrderToTransferQueue,
} from '../../../../redux/modules/alpaca/alpaca.actions';
import { alpacaSelector } from '../../../../redux/modules/alpaca/alpaca.selectors';
import { AlpacaOrderSide, AlpacaOrderStep, IAlpacaOrder } from '../../../../types/alpaca';
import * as permissions from '../../../../utils/permissions';

interface AlpacaActionsProps {
  order: IAlpacaOrder;
  refresh: () => void;
}

type TransferActionData = {
  queue: string;
  fetchStatusField: string;
  permission: PermissionPropsItem;
  sendToQueue: AsyncThunk<any, any, any>;
};

const transferActionData: TransferActionData = {
  queue: 'Transfer',
  sendToQueue: sendOrderToTransferQueue,
  permission: permissions.AlpacaPermissions.sendToTransferQueue,
  fetchStatusField: 'sendOrderToTransferQueueStatus',
};
const journalActionData: TransferActionData = {
  queue: 'Journal',
  sendToQueue: sendOrderToJournalQueue,
  permission: permissions.AlpacaPermissions.sendToJournalQueue,
  fetchStatusField: 'sendOrderToJournalQueueStatus',
};
const orderActionData: TransferActionData = {
  queue: 'Order',
  sendToQueue: sendOrderToOrderQueue,
  permission: permissions.AlpacaPermissions.sendToOrderQueue,
  fetchStatusField: 'sendOrderToOrderQueueStatus',
};
const completeActionData: TransferActionData = {
  queue: 'Complete',
  sendToQueue: sendOrderToCompleteQueue,
  permission: permissions.AlpacaPermissions.sendToCompleteQueue,
  fetchStatusField: 'sendOrderToCompleteQueueStatus',
};

const mapBuyStepToActionData: Partial<Record<AlpacaOrderStep, TransferActionData>> = {
  [AlpacaOrderStep.INITIATED]: transferActionData,
  [AlpacaOrderStep.TRANSFER_COMPLETED]: journalActionData,
  [AlpacaOrderStep.JOURNAL_COMPLETED]: orderActionData,
  [AlpacaOrderStep.ORDER_COMPLETED]: completeActionData,
};

const mapSellStepToActionData: Partial<Record<AlpacaOrderStep, TransferActionData>> = {
  [AlpacaOrderStep.INITIATED]: orderActionData,
  [AlpacaOrderStep.TRANSFER_RECEIVED]: completeActionData,
  [AlpacaOrderStep.TRANSFER_COMPLETED]: completeActionData,
  [AlpacaOrderStep.JOURNAL_COMPLETED]: transferActionData,
  [AlpacaOrderStep.ORDER_COMPLETED]: journalActionData,
};

const actionDelay = 5000;

export function AlpacaActions({ order, refresh }: AlpacaActionsProps) {
  const { t } = useTranslation('apiError');
  const dispatch = useAppDispatch();
  const asyncDialogRef = useRef<AsyncDialogRef<ConfirmationDialogData, boolean>>(null);
  const { error, ...alpacaData } = useSelector(alpacaSelector);
  const { enqueueSnackbar } = useSnackbar();
  const interval = useInterval();
  const [actionData, setActionData] = useState<TransferActionData | undefined>(undefined);
  const isDelayLoading = interval.time > 0;
  const isLoading = actionData ? alpacaData?.[actionData.fetchStatusField] === fetchStatuses.pending : false;

  const onSendToQueue = async () => {
    if (!actionData) {
      return;
    }

    const isConfirmed = await asyncDialogRef.current?.show({
      title: `Send Order To ${actionData.queue} Queue`,
      description: `Are you sure you want to send this order to Alpaca ${actionData.queue} Queue now?`,
    });

    if (isConfirmed) {
      try {
        await dispatch(actionData.sendToQueue(order.orderId)).unwrap();

        interval.start(1000);
        refresh();

        enqueueSnackbar(`Order Sent To Journal ${actionData.queue} successfully!`, { variant: 'success' });
      } catch (err: any) {
        enqueueSnackbar(
          `Send Order To Journal ${actionData.queue} failed: ${t(err?.message)} ${JSON.stringify(err?.data)}`,
          {
            variant: 'error',
          },
        );
      }
    }
  };

  useEffect(() => {
    const mapStepToActionData = order.side === AlpacaOrderSide.BUY ? mapBuyStepToActionData : mapSellStepToActionData;

    setActionData(mapStepToActionData[order.step]);
  }, [order.step]);

  React.useEffect(() => {
    if (interval.time >= actionDelay) {
      refresh();
      interval.stop();
    }
  }, [interval.time, actionDelay]);

  React.useEffect(
    () => () => {
      interval.stop();
    },
    [],
  );

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: 0 }} spacing={2}>
        <Typography variant="h6">Actions</Typography>

        <Grid container spacing={2}>
          {actionData && (
            <ProtectedContent items={[actionData.permission]}>
              <Grid item>
                <LoadingButton
                  type="button"
                  color="warning"
                  variant="contained"
                  endIcon={<ReportIcon />}
                  loading={isLoading || isDelayLoading}
                  loadingPosition="end"
                  onClick={onSendToQueue}
                >
                  Send Order To {actionData.queue} Queue
                </LoadingButton>
              </Grid>
            </ProtectedContent>
          )}
        </Grid>
      </Grid>
      <AsyncDialog ref={asyncDialogRef} dialog={ConfirmationDialog} />
    </>
  );
}
