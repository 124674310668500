import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchUserConfigResponse, FetchUserConfigsResponse } from '../../../types/userConfig';
import {
  fetchUserConfig,
  fetchUserConfigs,
  resetEditUserConfig,
  cleanUserConfigs,
  updateUserConfig,
  createUserConfigItem,
  deleteUserConfig,
} from './userConfig.actions';
import { UserConfigState } from './userConfig.types';

const initialState: UserConfigState = {
  userConfigs: [],
  editUserConfig: null,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const userConfigsSlice = createSlice({
  name: 'userConfigs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserConfigs.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchUserConfigs.fulfilled, (state, userConfig: PayloadAction<FetchUserConfigsResponse>) => {
      state.userConfigs = userConfig.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserConfigs.rejected, (state, userConfig) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = userConfig.error;
    });

    builder.addCase(fetchUserConfig.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchUserConfig.fulfilled, (state, userConfig: PayloadAction<FetchUserConfigResponse>) => {
      state.editUserConfig = userConfig.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserConfig.rejected, (state, userConfig) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = userConfig.error;
    });

    builder.addCase(updateUserConfig.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateUserConfig.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateUserConfig.rejected, (state, userConfig) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = userConfig.error;
    });

    builder.addCase(createUserConfigItem.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(createUserConfigItem.fulfilled, (state, userConfig: PayloadAction<FetchUserConfigResponse>) => {
      state.editUserConfig = userConfig.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createUserConfigItem.rejected, (state, userConfig) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = userConfig.error;
    });

    builder.addCase(deleteUserConfig.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteUserConfig.fulfilled, (state) => {
      state.editUserConfig = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteUserConfig.rejected, (state, userConfig) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = userConfig.error;
    });

    builder.addCase(resetEditUserConfig.type, (state) => {
      state.editUserConfig = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanUserConfigs.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const userConfigReducer = userConfigsSlice.reducer;
