import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { PortfolioOrderExecutionStatus } from '../../types/portfolioOrder';

export const portfolioOrderExecutionStatusToInfo: {
  [prop in PortfolioOrderExecutionStatus]: {
    color: 'error' | 'info' | 'success' | 'warning';
    icon: React.ElementType;
    menuAction: string;
  };
} = {
  [PortfolioOrderExecutionStatus.EXECUTION]: {
    color: 'info',
    menuAction: 'To Execution',
    icon: InfoIcon,
  },
  [PortfolioOrderExecutionStatus.IN_REVIEW]: {
    color: 'info',
    menuAction: 'To Review',
    icon: InfoIcon,
  },
  [PortfolioOrderExecutionStatus.RECONCILIATION]: {
    color: 'warning',
    menuAction: 'To Reconciliation',
    icon: WarningIcon,
  },
  [PortfolioOrderExecutionStatus.APPROVED]: {
    color: 'success',
    menuAction: 'Change to waiting cast approval',
    icon: CheckCircleIcon,
  },
};
