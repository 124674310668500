import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IAsyncDialogProps } from '../../components/AsyncDialog/AsyncDialog';

export function CreateBatchConfirmationDialog(props: IAsyncDialogProps) {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Confirmation dialog.</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to create the batch?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onOk(true)}>Yes</Button>
        <Button onClick={() => props.onClose(false)}>No</Button>
      </DialogActions>
    </Dialog>
  );
}
