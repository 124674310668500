import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/system/Box';
import React from 'react';
import Tab from '@mui/material/Tab';
import { AlpacaOrderTable } from './AlpacaOrderTable';
import { PortfolioOrderTable } from './PortfolioOrderTable';
import { UserPortfolioRequestTable } from './UserPortfolioRequestTable';
import { MTabPanel } from '../../../../components/TabPanel';

interface OrdersTabListProps {
  userId: number;
}

export function OrdersTabList({ userId }: OrdersTabListProps) {
  const [selectedTab, setSelectedTab] = React.useState<string>('0');

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <TabContext value={selectedTab}>
      <Box>
        <TabList
          value={selectedTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="alpaca fund orders"
        >
          <Tab value="0" label="Requests" sx={{ textTransform: 'capitalize' }} disableRipple />
          <Tab value="1" label="Orders" sx={{ textTransform: 'capitalize' }} disableRipple />
          <Tab value="2" label="Alpaca Orders" sx={{ textTransform: 'capitalize' }} disableRipple />
        </TabList>
      </Box>
      <MTabPanel value="0">
        <UserPortfolioRequestTable userId={userId} />
      </MTabPanel>
      <MTabPanel value="1">
        <PortfolioOrderTable userId={userId} />
      </MTabPanel>
      <MTabPanel value="2">
        <AlpacaOrderTable userId={userId} />
      </MTabPanel>
    </TabContext>
  );
}
