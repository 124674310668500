import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import FormControl from '@mui/material/FormControl';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useAppDispatch } from '../../redux/hooks';
import { createManualAlpacaTransfer } from '../../redux/modules/alpacaTransfer/alpaca-transfer.actions';
import { alpacaTransferSelector } from '../../redux/modules/alpacaTransfer/alpaca-transfer.selectors';
import { manualTransferFormFields } from '../../forms/fields/formFields';
import { manualTransferFormSchema } from '../../forms/validationSchema/formSchema';
import { CreateManualAlpacaTransferRequest } from '../../types/alpacaTransfer';

interface ManualAlpacaTransferDialogProps {
  open: boolean;
  onClose: (isReload: boolean) => void;
}

export function ManualAlpacaTransferDialog({ open, onClose }: ManualAlpacaTransferDialogProps) {
  const transferSources = ['ANB', 'ALPACA'];
  const dispatch = useAppDispatch();
  const { handleSubmit, values, handleChange, touched, errors, submitForm, resetForm } = useFormik({
    initialValues: {
      [manualTransferFormFields.transferTo]: 'ALPACA',
      [manualTransferFormFields.amount]: 0,
    },
    validationSchema: manualTransferFormSchema,
    onSubmit: (formValues) => {
      dispatch(createManualAlpacaTransfer(formValues as CreateManualAlpacaTransferRequest));
    },
  });
  const { createStatus } = useSelector(alpacaTransferSelector);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Create transfer</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth margin="normal" error={touched.transferTo && !!errors.transferTo}>
            <InputLabel id="manager-label">Transfer To</InputLabel>
            <Select
              labelId="manager-label"
              id={manualTransferFormFields.transferTo}
              name={manualTransferFormFields.transferTo}
              value={values[manualTransferFormFields.transferTo]}
              label="Transfer To"
              onChange={handleChange}
            >
              {transferSources.map((t) => (
                <MenuItem key={t} value={t}>
                  {t}
                </MenuItem>
              ))}
            </Select>
            {touched.transferTo && <FormHelperText>{errors.transferTo}</FormHelperText>}
          </FormControl>
          <TextField
            dir="rtl"
            margin="normal"
            required
            fullWidth
            id="Amount"
            name={manualTransferFormFields.amount}
            type="number"
            label="Amount"
            value={values[manualTransferFormFields.amount]}
            onChange={handleChange}
            error={touched.amount && !!errors.amount}
            helperText={touched.amount && errors.amount}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={createStatus === fetchStatuses.pending}
          loadingPosition="end"
          onClick={submitForm}
        >
          Create
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
