import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { LedgerTable } from '../../components/Ledger/LedgerTable';

interface LedgerProps {
  fetchAccounts: () => any;
  accounts: string[];
  fetchLedger: (params: { account: string[] }) => any;
  displayAccounts?: boolean;
}

export function FeeLedger({ fetchAccounts, fetchLedger, displayAccounts = false, accounts }: LedgerProps) {
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>(accounts);

  const { data: ledgerAccounts } = fetchAccounts();

  return (
    <Grid container>
      {displayAccounts && (
        <Grid item>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            value={selectedAccounts}
            onChange={(event: any, newValue: string[]) => {
              setSelectedAccounts(newValue);
            }}
            options={ledgerAccounts || []}
            sx={{ width: 300 }}
            multiple
            renderInput={(params) => <TextField {...params} label="Account" />}
          />
        </Grid>
      )}
      {selectedAccounts?.length > 0 && <LedgerTable accounts={selectedAccounts} fetchLedger={fetchLedger} />}
    </Grid>
  );
}
