import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchAnbBalanceResponse,
  FetchAnbPaymentDetailsResponse,
  FetchPaymentApiLogsResponse,
  FetchPaymentStatementsResponse,
} from '../../../types/payment';
import {
  fetchAnbPaymentDetails,
  cleanPayments,
  fetchStatements,
  fetchApiLogs,
  fetchAnbBalance,
} from './payment.actions';
import { PaymentState } from './payment.types';

const initialState: PaymentState = {
  anbPaymentDetails: {},
  paymentStatements: [],
  totalPaymentStatements: 0,
  paymentApiLogs: [],
  totalPaymentApiLogs: 0,
  anbBalance: null,
  fetchAnbPaymentDetailsStatus: fetchStatuses.idle,
  fetchPaymentStatementsStatus: fetchStatuses.idle,
  fetchPaymentApiLogsStatus: fetchStatuses.idle,
  fetchAnbBalanceStatus: fetchStatuses.idle,
  error: null,
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAnbPaymentDetails.pending, (state) => {
      state.fetchAnbPaymentDetailsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchAnbPaymentDetails.fulfilled,
      (state, action: PayloadAction<FetchAnbPaymentDetailsResponse>) => {
        state.anbPaymentDetails[action.payload.data.id] = action.payload.data;
        state.fetchAnbPaymentDetailsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchAnbPaymentDetails.rejected, (state, action) => {
      state.fetchAnbPaymentDetailsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchStatements.pending, (state) => {
      state.fetchPaymentStatementsStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchStatements.fulfilled, (state, action: PayloadAction<FetchPaymentStatementsResponse>) => {
      state.paymentStatements = action.payload.data.items;
      state.totalPaymentStatements = action.payload.data.totalItems;
      state.fetchPaymentStatementsStatus = fetchStatuses.success;
    });
    builder.addCase(fetchStatements.rejected, (state, action) => {
      state.fetchPaymentStatementsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchApiLogs.pending, (state) => {
      state.fetchPaymentApiLogsStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchApiLogs.fulfilled, (state, action: PayloadAction<FetchPaymentApiLogsResponse>) => {
      state.paymentApiLogs = action.payload.data.items;
      state.totalPaymentApiLogs = action.payload.data.totalItems;
      state.fetchPaymentApiLogsStatus = fetchStatuses.success;
    });
    builder.addCase(fetchApiLogs.rejected, (state, action) => {
      state.fetchPaymentApiLogsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchAnbBalance.pending, (state) => {
      state.fetchAnbBalanceStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchAnbBalance.fulfilled, (state, action: PayloadAction<FetchAnbBalanceResponse>) => {
      state.anbBalance = action.payload.data;
      state.fetchAnbBalanceStatus = fetchStatuses.success;
    });
    builder.addCase(fetchAnbBalance.rejected, (state, action) => {
      state.fetchAnbBalanceStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(cleanPayments.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const paymentReducer = paymentSlice.reducer;
