import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { FieldArray, FormikErrors, FormikProvider, useFormik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import { DecimalNumberField } from '../../components/DecimalNumberField';
import { FakeButtonLink } from '../../components/FakeLink';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { indexFundBulkUpdateNavFormFields } from '../../forms/fields/formFields';
import { indexFundBulkUpdateNavFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import {
  bulkUpdateIndexFundNavs,
  cleanIndexFunds,
  fetchIndexFunds,
} from '../../redux/modules/indexFund/indexFund.actions';
import { indexFundSelector } from '../../redux/modules/indexFund/indexFund.selectors';
import { locations } from '../../routes/locations';
import { BulkUpdateIndexFundNavs, IndexFund } from '../../types/indexFund';
import { isDispatchApiSuccess } from '../../utils/api';
import * as permissions from '../../utils/permissions';
import { ProtectedContent } from '../../components/ProtectedContent';

export function IndexFundNavList() {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { indexFunds, fetchStatus, updateStatus } = useSelector(indexFundSelector);
  const isLoading = fetchStatus === fetchStatuses.pending;
  const isSaveLoading = isLoading || updateStatus === fetchStatuses.pending;

  const formik = useFormik({
    initialValues: {
      [indexFundBulkUpdateNavFormFields.items]: [] as BulkUpdateIndexFundNavs[],
    },
    validationSchema: indexFundBulkUpdateNavFormSchema,
    onSubmit: async (formValues) => {
      const items = formValues.items.map((i) => ({
        ...i,
        nav: Number(i.nav) || 0.01,
        originalNav: Number(i.originalNav),
      }));

      const res = await dispatch(bulkUpdateIndexFundNavs(items));

      if (isDispatchApiSuccess(res)) {
        dispatch(fetchIndexFunds({ limit: 100000, offset: 0 }));
        enqueueSnackbar('Navs updated successfully!', { variant: 'success' });
      } else {
        enqueueSnackbar('Navs update failed!', { variant: 'error' });
      }
    },
  });
  const { handleSubmit, values, handleChange, touched, errors, setValues } = formik;

  useEffect(() => {
    dispatch(fetchIndexFunds({ limit: 100000, offset: 0 }));

    return () => {
      dispatch(cleanIndexFunds());
    };
  }, []);

  useEffect(() => {
    const items = indexFunds.map((item) => ({
      id: item.id,
      nav: item.netAssetValuePerUnit,
      originalNav: item.originalNav,
    }));

    setValues({
      [indexFundBulkUpdateNavFormFields.items]: items,
    });
  }, [indexFunds]);

  const getIndexFundById = (id: number): IndexFund | undefined => indexFunds.find((item) => item.id === id);

  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper sx={{ width: '100%', height: '100%', padding: 2 }}>
            <FormikProvider value={formik}>
              <FieldArray
                name="items"
                render={() => (
                  <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <Grid container item spacing={3} component={ListItem}>
                      <Grid item xs={1} md={1}>
                        {isLoading ? (
                          <Skeleton variant="rectangular" width="100%" height={80} />
                        ) : (
                          <Typography variant="h6">Id</Typography>
                        )}
                      </Grid>
                      <Grid item xs={1} md={2}>
                        {isLoading ? (
                          <Skeleton variant="rectangular" width="100%" height={80} />
                        ) : (
                          <Typography variant="h6">Source</Typography>
                        )}
                      </Grid>
                      <Grid item xs={1} md={1}>
                        {isLoading ? (
                          <Skeleton variant="rectangular" width="100%" height={80} />
                        ) : (
                          <Typography variant="h6">Code</Typography>
                        )}
                      </Grid>
                      <Grid item xs={11} md={3}>
                        {isLoading ? (
                          <Skeleton variant="rectangular" width="100%" height={80} />
                        ) : (
                          <Typography variant="h6">Fund Name</Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} md={1}>
                        {isLoading ? (
                          <Skeleton variant="rectangular" width="100%" height={80} />
                        ) : (
                          <Typography variant="h6">Original price</Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} md={1}>
                        {isLoading ? (
                          <Skeleton variant="rectangular" width="100%" height={80} />
                        ) : (
                          <Typography variant="h6">FX Rate</Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} md={1}>
                        {isLoading ? (
                          <Skeleton variant="rectangular" width="100%" height={80} />
                        ) : (
                          <Typography variant="h6">SAR price</Typography>
                        )}
                      </Grid>
                      <Grid item xs={2} md={2}>
                        {isLoading ? (
                          <Skeleton variant="rectangular" width="100%" height={80} />
                        ) : (
                          <Typography variant="h6">Last Update</Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Divider variant="fullWidth" component="li" />
                    {values[indexFundBulkUpdateNavFormFields.items].map((item, index) => (
                      <React.Fragment key={item.id}>
                        <Grid container item spacing={3} component={ListItem}>
                          <Grid item xs={1} md={1}>
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <FakeButtonLink
                                startIcon={<OpenInNewIcon />}
                                href={locations.indexFund(getIndexFundById(item.id!)?.id)}
                              >
                                {getIndexFundById(item.id!)?.id}
                              </FakeButtonLink>
                            )}
                          </Grid>
                          <Grid item xs={1} md={2}>
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <Typography>{getIndexFundById(item.id!)?.source?.name}</Typography>
                            )}
                          </Grid>
                          <Grid item xs={1} md={1}>
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <Typography>{getIndexFundById(item.id!)?.code}</Typography>
                            )}
                          </Grid>
                          <Grid item xs={11} md={3}>
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <Typography>{getIndexFundById(item.id!)?.nameEn}</Typography>
                            )}
                          </Grid>
                          <Grid item xs={12} md={1}>
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <FormControl
                                fullWidth
                                error={
                                  touched.items?.[index]?.originalNav &&
                                  !!(errors.items?.[index] as FormikErrors<BulkUpdateIndexFundNavs>)?.nav
                                }
                              >
                                <DecimalNumberField
                                  size="small"
                                  fullWidth
                                  type="number"
                                  id={`items.${index}.originalNav`}
                                  name={`items.${index}.originalNav`}
                                  label="Original Nav"
                                  value={item.originalNav}
                                  onChange={handleChange}
                                  error={
                                    touched.items?.[index]?.originalNav &&
                                    !!(errors.items?.[index] as FormikErrors<BulkUpdateIndexFundNavs>)?.originalNav
                                  }
                                  helperText={
                                    touched.items?.[index]?.originalNav &&
                                    (errors.items?.[index] as FormikErrors<BulkUpdateIndexFundNavs>)?.originalNav
                                  }
                                  maximumFractionDigits={6}
                                  autoComplete="index-fund-originalNav"
                                />
                              </FormControl>
                            )}
                          </Grid>
                          <Grid item xs={11} md={1}>
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <Typography>{getIndexFundById(item.id!)?.fxRate}</Typography>
                            )}
                          </Grid>
                          <Grid item xs={11} md={1}>
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <Typography>{getIndexFundById(item.id!)?.netAssetValuePerUnit}</Typography>
                            )}
                          </Grid>
                          <Grid item xs={2} md={2}>
                            {isLoading ? (
                              <Skeleton variant="rectangular" width="100%" height={80} />
                            ) : (
                              <Typography>
                                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                                {new Date((getIndexFundById(item.id!)?.updatedAt as string) || '').toLocaleString(
                                  'en-US',
                                )}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                        {index < values[indexFundBulkUpdateNavFormFields.items].length - 1 && (
                          <Divider variant="inset" component="li" />
                        )}
                      </React.Fragment>
                    ))}
                  </List>
                )}
              />
              {!!errors.items && typeof errors.items === 'string' && (
                <FormHelperText error>{errors.items}</FormHelperText>
              )}
            </FormikProvider>
          </Paper>
        </Grid>
      </Grid>
      <ProtectedContent items={[permissions.IndexFund.navBulkUpdate]}>
        <Grid>
          <Box m={1} p={1} display="flex" justifyContent="right" alignItems="flex-end">
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              endIcon={<SaveIcon />}
              loading={isSaveLoading}
              loadingPosition="end"
            >
              Save
            </LoadingButton>
          </Box>
        </Grid>
      </ProtectedContent>
    </form>
  );

  return <Box mt={2}>{renderForm()}</Box>;
}
