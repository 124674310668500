import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchFundAssetClassResponse,
  FetchFundAssetClassesAutocompleteResponse,
  FetchFundAssetClassesResponse,
} from '../../../types/fundAssetClass';
import {
  fetchFundAssetClass,
  fetchFundAssetClasses,
  resetEditFundAssetClass,
  cleanFundAssetClass,
  updateFundAssetClass,
  createFundAssetClass,
  deleteFundAssetClass,
  fetchFundAssetClassesAutocomplete,
} from './fundAssetClass.actions';
import { FundAssetClassState } from './fundAssetClass.types';

const initialState: FundAssetClassState = {
  fundAssetClasses: [],
  fundAssetClassesAutocomplete: [],
  totalFundAssetClasses: 0,
  editFundAssetClass: null,
  fetchAutocompleteStatus: fetchStatuses.idle,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const fundStrategiesSlice = createSlice({
  name: 'fundStrategies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFundAssetClasses.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchFundAssetClasses.fulfilled, (state, action: PayloadAction<FetchFundAssetClassesResponse>) => {
      state.fundAssetClasses = action.payload.data?.items;
      state.totalFundAssetClasses = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFundAssetClasses.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFundAssetClassesAutocomplete.pending, (state) => {
      state.fetchAutocompleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchFundAssetClassesAutocomplete.fulfilled,
      (state, action: PayloadAction<FetchFundAssetClassesAutocompleteResponse>) => {
        state.fundAssetClassesAutocomplete = action.payload.data;
        state.fetchAutocompleteStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFundAssetClassesAutocomplete.rejected, (state, action) => {
      state.fetchAutocompleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFundAssetClass.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchFundAssetClass.fulfilled, (state, action: PayloadAction<FetchFundAssetClassResponse>) => {
      state.editFundAssetClass = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFundAssetClass.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateFundAssetClass.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateFundAssetClass.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateFundAssetClass.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createFundAssetClass.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(createFundAssetClass.fulfilled, (state, action: PayloadAction<FetchFundAssetClassResponse>) => {
      state.editFundAssetClass = action.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createFundAssetClass.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteFundAssetClass.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteFundAssetClass.fulfilled, (state) => {
      state.editFundAssetClass = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteFundAssetClass.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditFundAssetClass.type, (state) => {
      state.editFundAssetClass = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanFundAssetClass.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const fundAssetClassReducer = fundStrategiesSlice.reducer;
