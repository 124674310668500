import {
  CreateAlpacaConfigRequest,
  CreateAlpacaConfigResponse,
  FetchAlpacaConfigsResponse,
  FetchAlpacaConfigResponse,
  UpdateAlpacaConfigRequest,
  UpdateAlpacaConfigResponse,
  UpdateAlpacaConfigsRequest,
} from '../types/alpacaConfig';
import { ApiService } from './common/ApiService';

class AlpacaConfigService extends ApiService {
  pathname = 'admin/alpaca-config';

  fetchAlpacaConfigs = async () => this.get<FetchAlpacaConfigsResponse>(`${this.pathname}`);

  fetchAlpacaConfig = async (key: string) => this.get<FetchAlpacaConfigResponse>(`${this.pathname}/${key}`);

  deleteAlpacaConfig = async (key: string) => this.delete<FetchAlpacaConfigResponse>(`${this.pathname}/${key}`);

  createAlpacaConfig = async (action: CreateAlpacaConfigRequest) =>
    this.post<CreateAlpacaConfigResponse, CreateAlpacaConfigRequest>(`${this.pathname}`, action);

  updateAlpacaConfig = async ({ key, ...action }: UpdateAlpacaConfigRequest) =>
    this.put<UpdateAlpacaConfigResponse, Omit<UpdateAlpacaConfigRequest, 'key'>>(`${this.pathname}/${key}`, action);

  updateAlpacaConfigs = async (data: UpdateAlpacaConfigRequest[]) =>
    this.put<FetchAlpacaConfigsResponse, UpdateAlpacaConfigsRequest>(`${this.pathname}`, { items: data });
}

export const alpacaConfigService = new AlpacaConfigService();
