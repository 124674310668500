import React, { useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Fab from '@mui/material/Fab';
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { RtkQueryTableModels } from '../../components/Table/RtkQueryTable';
import { TableRef } from '../../components/Table/Table';
import { converTableModelsToApiArgs } from '../../helpers/api';
import {
  useDeleteRolePermissionMutation,
  useFetchRolePermissionsQuery,
} from '../../redux/features/rolePermission/rolePermission.apiSlice';
import { RolePermission } from '../../types/rolePermission';
import { RolePermissionDialog } from './RolePermissionDialog';
import { RtkQueryTable } from '../../components/Table';

export function RolePermissionList() {
  const tableRef = useRef<TableRef>(null);
  const [open, setOpen] = React.useState(false);
  const [editValue, setEditValue] = React.useState<RolePermission | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [tableModels, setTableModels] = useState<RtkQueryTableModels>({
    paginationModel: { page: 0, pageSize: 100 },
  });

  const [deleteRolePermission, { isLoading: isDeleteLoading }] = useDeleteRolePermissionMutation();
  const { data: rolePermissions, isFetching } = useFetchRolePermissionsQuery(converTableModelsToApiArgs(tableModels));

  const handleClose = () => {
    setEditValue(null);
    setOpen(false);
  };

  const onDeleteRolePermission = async (id: number) => {
    try {
      await deleteRolePermission(id).unwrap();

      handleClose();
      enqueueSnackbar('RolePermission deleted!', { variant: 'success' });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('RolePermission deletion error!', { variant: 'error' });
    }
  };

  const onEditRolePermission = (row: RolePermission): void => {
    setEditValue(row);
    setOpen(true);
  };

  const columns = useRef<GridColDef[]>([
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      filterable: false,
      sortable: false,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params: GridRowParams) => [
        // eslint-disable-next-line react/jsx-key
        <GridActionsCellItem
          icon={<DeleteOutlineOutlinedIcon />}
          label="Link"
          onClick={() => onDeleteRolePermission(params.row.id)}
        />,
        // eslint-disable-next-line react/jsx-key
        <GridActionsCellItem
          icon={<EditOutlinedIcon />}
          label="Actions"
          onClick={() => onEditRolePermission(params.row)}
        />,
      ],
    },
    {
      field: 'role.name',
      headerName: 'Role',
      flex: 1,
      valueGetter: (params) => params?.row?.role?.name,
    },
    {
      field: 'action.name',
      headerName: 'Action',
      flex: 1,
      valueGetter: (params) => params?.row?.action?.name,
    },
    {
      field: 'attributes',
      headerName: 'Attributes',
      width: 100,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 100,
    },
  ]);

  const onAddNew = (): void => {
    setOpen(true);
  };

  const onModelsChange = (models: RtkQueryTableModels) => {
    setTableModels(models);
  };

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">
          Role Permissions &nbsp;
          <Fab color="primary" size="small" aria-label="Create RolePermission" onClick={onAddNew}>
            <AddIcon />
          </Fab>
        </Typography>
      </Box>

      <RolePermissionDialog open={open} initialValue={editValue} onClose={handleClose} />

      <RtkQueryTable
        ref={tableRef}
        models={tableModels}
        onModelsChange={onModelsChange}
        rows={rolePermissions?.items || []}
        columns={columns.current}
        loading={isFetching || isDeleteLoading}
        rowCount={rolePermissions?.totalItems || 0}
      />
    </Container>
  );
}
