import { ApiResponse } from './api';

export enum JadwaConfigKey {
  SUBSCRIPTION_USD_FX_RATE = 'subscription_usd_fx_rate',
  REDEMPTION_USD_FX_RATE = 'redemption_usd_fx_rate',
}

export type JadwaConfig = {
  key: JadwaConfigKey;
  value: string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchJadwaConfigResponse = ApiResponse<JadwaConfig>;
export type FetchJadwaConfigsResponse = ApiResponse<JadwaConfig[]>;

export type CreateJadwaConfigRequest = Omit<JadwaConfig, 'id' | 'createdAt' | 'updatedAt'>;
export type CreateJadwaConfigResponse = ApiResponse<JadwaConfig>;

export type UpdateJadwaConfigRequest = Omit<JadwaConfig, 'key' | 'createdAt' | 'updatedAt'> & { key: string };
export type UpdateJadwaConfigsRequest = {
  items: UpdateJadwaConfigRequest[];
};
export type UpdateJadwaConfigResponse = ApiResponse<JadwaConfig>;
