import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid-premium';
import { AsyncDialog, AsyncDialogRef } from '../../../../components/AsyncDialog/AsyncDialog';
import { ConfirmationDialog, ConfirmationDialogData } from '../../../../components/AsyncDialog/ConfirmationDialog';
import { Table } from '../../../../components/Table';
import { TableRef } from '../../../../components/Table/Table';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { useAppDispatch } from '../../../../redux/hooks';
import { deleteUserAddress, fetchUserAddresses } from '../../../../redux/modules/userAddress/userAddress.actions';
import { userAddressSelector } from '../../../../redux/modules/userAddress/userAddress.selectors';
import { UserAddress } from '../../../../types/userAddress';
import { AddressDialog } from './AddressDialog';

interface AddressesTableProps {
  userId: number;
}

export function AddressesTable({ userId }: AddressesTableProps) {
  const { t } = useTranslation('apiError');
  const dispatch = useAppDispatch();
  const tableRef = useRef<TableRef>(null);
  const [open, setOpen] = React.useState(false);
  const asyncDialogRef = useRef<AsyncDialogRef<ConfirmationDialogData, boolean>>(null);
  const [editValue, setEditValue] = React.useState<UserAddress | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const onDeleteItem = async (id: number) => {
    const isConfirmed = await asyncDialogRef.current?.show({
      title: "Delete user's address",
      description: 'Are you sure you want to delete this address?',
    });

    if (isConfirmed) {
      try {
        await dispatch(deleteUserAddress(id)).unwrap();

        enqueueSnackbar('Address deleted successfully!', { variant: 'success' });
      } catch (err: any) {
        enqueueSnackbar(`Delete address failed: ${t(err?.message)} ${JSON.stringify(err?.data)}`, {
          variant: 'error',
        });
      } finally {
        tableRef?.current?.reload();
      }
    }
  };

  const onEditItem = (row: UserAddress): void => {
    setEditValue(row);
    setOpen(true);
  };

  const [columns] = useState<GridColDef[]>([
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      filterable: false,
      sortable: false,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params: GridRowParams) => [
        // eslint-disable-next-line react/jsx-key
        <GridActionsCellItem
          icon={<DeleteOutlineOutlinedIcon />}
          label="Link"
          onClick={() => onDeleteItem(params.row.id)}
        />,
        // eslint-disable-next-line react/jsx-key
        <GridActionsCellItem icon={<EditOutlinedIcon />} label="Actions" onClick={() => onEditItem(params.row)} />,
      ],
    },
    { field: 'id', headerName: 'Id' },
    { field: 'buildingNumber', headerName: 'Building Number' },
    { field: 'streetName', headerName: 'Street Name' },
    { field: 'district', headerName: 'district' },
    { field: 'address1', headerName: 'Address1', flex: 1 },
    { field: 'city', headerName: 'City' },
    { field: 'postCode', headerName: 'Post Code' },
    { field: 'additionalNumber', headerName: 'Additional Number' },
    { field: 'address2', headerName: 'Address2', flex: 1 },
    { field: 'unitNumber', headerName: 'Unit Number', width: 120 },
    { field: 'isPrimaryAddress', headerName: 'Primary', width: 100 },
    { field: 'locationCoordinates', headerName: 'Location Coordinates', width: 100 },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: false,
      type: 'date',
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      sortable: false,
      type: 'date',
    },
  ]);
  const { totalUserAddresses, userAddresses, fetchStatus } = useSelector(userAddressSelector);

  const onAddNew = (): void => {
    setOpen(true);
  };

  const handleClose = (isReload: boolean) => {
    if (isReload) {
      tableRef?.current?.reload();
    }
    setEditValue(null);
    setOpen(false);
  };

  return (
    <Grid container flexDirection="column" paddingX={2} paddingBottom={3}>
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">
          Addresses &nbsp;
          <Fab color="primary" size="small" aria-label="Create Action" onClick={onAddNew}>
            <AddIcon />
          </Fab>
        </Typography>
      </Box>
      <Grid item display="flex" sx={{ width: '100%' }}>
        <Table
          ref={tableRef}
          fetchItems={fetchUserAddresses}
          rows={userAddresses}
          columns={columns}
          loading={fetchStatus === fetchStatuses.pending}
          rowCount={totalUserAddresses}
          disableColumnFilter
          disableColumnMenu
          density="compact"
          requiredFilters={[{ field: 'userId', operator: 'is', value: userId, id: 1 }]}
          initialColumnVisibilityModel={{
            id: false,
            buildingNumber: false,
            streetName: false,
            district: false,
            city: false,
            postCode: false,
            additionalNumber: false,
          }}
          wrapperHeight={300}
        />
      </Grid>

      <AsyncDialog ref={asyncDialogRef} dialog={ConfirmationDialog} />
      <AddressDialog userId={userId} open={open} initialValue={editValue} onClose={handleClose} />
    </Grid>
  );
}
