import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import { GridFooterContainer, GridPagination, GridSlotsComponentsProps } from '@mui/x-data-grid-premium';
import { AsyncDialog } from '../../AsyncDialog';
import { AsyncDialogRef } from '../../AsyncDialog/AsyncDialog';
import { CustomRowsPerPageDialog } from './CustomRowsPerPageDialog';

export function CustomFooter(props: NonNullable<GridSlotsComponentsProps['footer']>) {
  const asyncDialogRef = useRef<AsyncDialogRef<number, number>>(null);

  const handleSetCustomRowsPerPage = async () => {
    const rowsCount = props.apiRef?.getRowsCount() || 0;

    let newRowsPerPage = (await asyncDialogRef?.current?.show(rowsCount).catch(() => 0)) || 0;

    if (newRowsPerPage > rowsCount) {
      newRowsPerPage = rowsCount;
    }

    if (newRowsPerPage) {
      props.apiRef?.setPageSize(newRowsPerPage);
      props.apiRef?.setPage(0);
    }
  };

  return (
    <GridFooterContainer>
      <Grid item>&nbsp;</Grid>
      <Grid item container flexDirection="row" flexWrap="nowrap" alignItems="center" justifyContent="right">
        <Box sx={{ p: 1, display: 'flex' }}>
          <IconButton color="primary" aria-label="Set Rows Per Page" size="small" onClick={handleSetCustomRowsPerPage}>
            <DocumentScannerIcon />
          </IconButton>
        </Box>
        <GridPagination />
      </Grid>

      <AsyncDialog ref={asyncDialogRef} dialog={CustomRowsPerPageDialog} />
    </GridFooterContainer>
  );
}
