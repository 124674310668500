import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { satelliteAccountSelector } from '../../redux/modules/satelliteAccount/satelliteAccount.selectors';
import { SatelliteAccount } from '../../types/satelliteAccount';
import { round2Format } from '../../utils/number';
import { TransactionsLogsTable } from '../User/components/OwnedShares/TransactionsLogsTable';
import { TransactionsTable } from '../User/components/OwnedShares/TransactionsTable';
import { SatelliteAccountDetailsHistoryTable } from './SatelliteAccountDetailsHistoryTable';

export function SatelliteAccountsDetails() {
  const { satelliteAccounts, fetchStatus } = useSelector(satelliteAccountSelector);
  const [selectedSatelliteAccount, setSelectedSatelliteAccount] = React.useState<string>('1');

  const isLoading = fetchStatus === fetchStatuses.pending;

  const handleChangeSatelliteAccountTab = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedSatelliteAccount(newValue);
  };

  useEffect(() => {
    if (satelliteAccounts.length && !selectedSatelliteAccount) {
      setSelectedSatelliteAccount(`${satelliteAccounts[0].id}`);
    }
  }, [satelliteAccounts]);

  const renderDetailsInfo = (satelliteAccount: SatelliteAccount) => (
    <Grid container>
      <Grid item container flexDirection="row" sx={{ mt: 0 }} spacing={2} xs={12}>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Current Balance</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{round2Format(satelliteAccount?.balance || 0)}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Hold amount</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{round2Format(satelliteAccount?.hold || 0)}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">VIBAN</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{satelliteAccount?.iban || '-'}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Account number</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{satelliteAccount?.accountNumber || '-'}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Custodian Reference Number</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{satelliteAccount?.custodianReferenceNumber || '-'}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ pt: 4, pb: 2 }}>
        <Divider />
      </Grid>

      <Grid item container flexDirection="row" sx={{ mt: 0 }} spacing={2} xs={12}>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">First Name</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{satelliteAccount?.firstNameEn || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Second Name</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{satelliteAccount?.secondNameEn || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Family Name</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{satelliteAccount?.familyNameEn || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Email</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{satelliteAccount?.email || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Phone</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{satelliteAccount?.phone || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Nationality</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{satelliteAccount?.nationalityEn || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Residence</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{satelliteAccount?.residenceEn || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Address En</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{satelliteAccount?.addressEn || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Address Ar</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{satelliteAccount?.addressAr || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Gender</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{satelliteAccount?.gender || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Id Number</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{satelliteAccount?.idNumber || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Id Type</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{satelliteAccount?.idType || ''}</Typography>
          </Grid>
        </Grid>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Id Expiry Date</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{satelliteAccount?.idExpiryDate || ''}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ pt: 4, pb: 2 }}>
        <Divider />
      </Grid>
      <Grid item container flexDirection="row" sx={{ mt: 0 }} spacing={2} xs={12}>
        <Grid item container sm={12} md={6}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">Last Update</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2">{new Date(satelliteAccount.updatedAt).toLocaleString('en-US')}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  if (isLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <TabContext value={selectedSatelliteAccount}>
      <Box sx={{ width: '100%' }}>
        <TabList
          value={selectedSatelliteAccount}
          onChange={handleChangeSatelliteAccountTab}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="satellite account"
        >
          {satelliteAccounts.map((sa) => (
            <Tab key={`satellite-account-${sa.id}`} value={sa.id.toString()} label={`#${sa.id} - ${sa.idNumber}`} />
          ))}
        </TabList>
      </Box>
      {satelliteAccounts.map((sa) => (
        <TabPanel key={`satellite-account-panel-${sa.id}`} value={sa.id.toString()} sx={{ width: '100%' }}>
          {renderDetailsInfo(sa)}
        </TabPanel>
      ))}
      {selectedSatelliteAccount && (
        <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}>
          <Typography variant="h6">History</Typography>
          <SatelliteAccountDetailsHistoryTable satelliteAccountDetailsId={+selectedSatelliteAccount} />
        </Grid>
      )}
      {selectedSatelliteAccount && (
        <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}>
          <Typography variant="h6">Transactions</Typography>
          <TransactionsTable userBankId={+selectedSatelliteAccount} />
        </Grid>
      )}
      {selectedSatelliteAccount && (
        <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}>
          <Typography variant="h6">Transactions Logs</Typography>
          <TransactionsLogsTable userBankId={+selectedSatelliteAccount} />
        </Grid>
      )}
    </TabContext>
  );
}
