import { PaginatedRequest } from '../types/api';
import {
  SaveSubscribedPortfolioRequest,
  CreateSubscribedPortfolioResponse,
  FetchSubscribedPortfoliosResponse,
  FetchSubscribedPortfolioResponse,
  UpdateSubscribedPortfolioRequest,
  UpdateSubscribedPortfolioResponse,
  UpdateSubscribedPortfolioStatusRequest,
  UpdateSubscribedPortfolioStatusResponse,
  FetchSubscribedPortfolioStatusOptionsResponse,
} from '../types/subscribedPortfolio';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class SubscribedPortfolioService extends ApiService {
  pathname = 'admin';

  fetchSubscribedPortfolios = async (data: PaginatedRequest) =>
    this.get<FetchSubscribedPortfoliosResponse>(`${this.pathname}/subscribed-portfolio?${toQueryParams(data)}`);

  fetchSubscribedPortfolio = async (id: number) =>
    this.get<FetchSubscribedPortfolioResponse>(`${this.pathname}/subscribed-portfolio/${id}`);

  deleteSubscribedPortfolio = async (id: number) =>
    this.delete<FetchSubscribedPortfolioResponse>(`${this.pathname}/subscribed-portfolio/${id}`);

  saveSubscribedPortfolio = async (portfolio: SaveSubscribedPortfolioRequest) =>
    this.post<CreateSubscribedPortfolioResponse, SaveSubscribedPortfolioRequest>(
      `${this.pathname}/subscribed-portfolio`,
      portfolio,
    );

  updateSubscribedPortfolio = async ({ id, ...portfolio }: UpdateSubscribedPortfolioRequest) =>
    this.put<UpdateSubscribedPortfolioResponse, Omit<UpdateSubscribedPortfolioRequest, 'id'>>(
      `${this.pathname}/subscribed-portfolio/${id}`,
      portfolio,
    );

  updateSubscribedPortfolioStatus = async ({ id, status }: UpdateSubscribedPortfolioStatusRequest) =>
    this.put<UpdateSubscribedPortfolioStatusResponse, Omit<UpdateSubscribedPortfolioStatusRequest, 'id'>>(
      `${this.pathname}/subscribed-portfolio/${id}/status`,
      { status },
    );

  fetchSubscribedPortfolioStatusOptions = async () =>
    this.get<FetchSubscribedPortfolioStatusOptionsResponse>(`${this.pathname}/subscribed-portfolio/status/options`);
}

export const subscribedPortfolioService = new SubscribedPortfolioService();
