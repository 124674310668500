import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchPortfolioCurrencyOptionsResponse,
  FetchPortfolioFileTypeOptionsResponse,
  FetchPortfolioResponse,
  FetchPortfoliosResponse,
  FetchPortfolioStatusOptionsResponse,
} from '../../../types/portfolio';
import {
  fetchPortfolio,
  fetchPortfolios,
  resetEditPortfolio,
  savePortfolio,
  deletePortfolio,
  cleanPortfolios,
  updatePortfolioStatus,
  fetchPortfolioStatusOptions,
  fetchPortfolioCurrencyOptions,
  fetchPortfolioFileTypeOptions,
} from './portfolio.actions';
import { PortfolioState } from './portfolio.types';

const initialState: PortfolioState = {
  portfolios: [],
  totalPortfolios: 0,
  editPortfolio: null,
  portfolioStatusOptions: [],
  portfolioFileTypeOptions: [],
  portfolioCurrencyOptions: [],
  fetchPortfolioStatusOptionsStatus: fetchStatuses.idle,
  fetchPortfolioFileTypeOptionsStatus: fetchStatuses.idle,
  fetchPortfolioCurrencyOptionsStatus: fetchStatuses.idle,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatusStatus: fetchStatuses.idle,
  saveStatus: fetchStatuses.idle,
  error: null,
};

const portfoliosSlice = createSlice({
  name: 'portfolios',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPortfolios.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchPortfolios.fulfilled, (state, action: PayloadAction<FetchPortfoliosResponse>) => {
      state.portfolios = action.payload.data?.items;
      state.totalPortfolios = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchPortfolios.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchPortfolio.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchPortfolio.fulfilled, (state, action: PayloadAction<FetchPortfolioResponse>) => {
      state.editPortfolio = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchPortfolio.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchPortfolioStatusOptions.pending, (state) => {
      state.error = null;
      state.fetchPortfolioStatusOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchPortfolioStatusOptions.fulfilled,
      (state, action: PayloadAction<FetchPortfolioStatusOptionsResponse>) => {
        state.portfolioStatusOptions = action.payload.data;
        state.fetchPortfolioStatusOptionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchPortfolioStatusOptions.rejected, (state, action) => {
      state.fetchPortfolioStatusOptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchPortfolioFileTypeOptions.pending, (state) => {
      state.error = null;
      state.fetchPortfolioFileTypeOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchPortfolioFileTypeOptions.fulfilled,
      (state, action: PayloadAction<FetchPortfolioFileTypeOptionsResponse>) => {
        state.portfolioFileTypeOptions = action.payload.data;
        state.fetchPortfolioFileTypeOptionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchPortfolioFileTypeOptions.rejected, (state, action) => {
      state.fetchPortfolioFileTypeOptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchPortfolioCurrencyOptions.pending, (state) => {
      state.error = null;
      state.fetchPortfolioCurrencyOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchPortfolioCurrencyOptions.fulfilled,
      (state, action: PayloadAction<FetchPortfolioCurrencyOptionsResponse>) => {
        state.portfolioCurrencyOptions = action.payload.data;
        state.fetchPortfolioCurrencyOptionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchPortfolioCurrencyOptions.rejected, (state, action) => {
      state.fetchPortfolioCurrencyOptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updatePortfolioStatus.pending, (state) => {
      state.error = null;
      state.updateStatusStatus = fetchStatuses.pending;
    });
    builder.addCase(updatePortfolioStatus.fulfilled, (state) => {
      state.updateStatusStatus = fetchStatuses.success;
    });
    builder.addCase(updatePortfolioStatus.rejected, (state, action) => {
      state.updateStatusStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(savePortfolio.pending, (state) => {
      state.error = null;
      state.saveStatus = fetchStatuses.pending;
    });
    builder.addCase(savePortfolio.fulfilled, (state, action: PayloadAction<FetchPortfolioResponse>) => {
      state.editPortfolio = state.editPortfolio || ({} as any);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Object.assign(state.editPortfolio!, {
        ...action.payload.data,
        status: { id: action.payload.data.status },
        currency: { id: action.payload.data.currency },
      });
      state.saveStatus = fetchStatuses.success;
    });
    builder.addCase(savePortfolio.rejected, (state, action) => {
      state.saveStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deletePortfolio.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
    });
    builder.addCase(deletePortfolio.fulfilled, (state) => {
      state.editPortfolio = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deletePortfolio.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditPortfolio.type, (state) => {
      state.editPortfolio = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.saveStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanPortfolios.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const portfolioReducer = portfoliosSlice.reducer;
