import { createTheme, ThemeOptions } from '@mui/material';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import COLORS from '../constants/colors';

const palette = {
  primary: {
    main: COLORS.PRIMARY_BLUE,
  },
  secondary: {
    main: COLORS.SECONDARY_GREEN,
  },
};
const components = {
  MuiAppBar: {
    styleOverrides: {
      colorPrimary: {
        backgroundColor: '#fcfcfb',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
};

const rtlTypography = {
  typography: {
    fontFamily: '"Cairo" ,sans-serif',
    bodySmallAr: {
      fontFamily: "'Cairo', sans-serif",
      fontWeight: 400,
      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
      lineHeight: '20px',
      textTransform: 'capitalize',
    },
    largeDisplayH: {
      fontFamily: "'Cairo', sans-serif",
      fontSize: 'clamp(2rem,3vw + 1rem,2.5rem)',
      fontWeight: 500,
      lineHeight: '56px',
    },
    cairoHeader1B: {
      fontFamily: "'Cairo', sans-serif",
      fontSize: 'clamp(2rem,3vw + 1rem,2.5rem)',
      fontWeight: 600,
      lineHeight: '75px',
    },
    cairoHeader3: {
      fontFamily: "'Cairo', sans-serif",
      fontSize: '30px',
      fontWeight: 500,
      lineHeight: '40px',
    },
    cairoHeader3Custom: {
      fontFamily: "'Cairo', sans-serif",
      fontSize: 'clamp(1.5rem,2vw + 1rem,1.875rem)',
      fontWeight: 500,
      lineHeight: '40px',
    },
    cairoHeader2: {
      fontFamily: '"Cairo" ,sans-serif',
      fontSize: 'clamp(2rem,4vw + 1rem,2.5rem)',
      fontWeight: 500,
      lineHeight: '48px',
    },
    cairoR: {
      fontFamily: '"Cairo" ,sans-serif',
      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
      fontWeight: 400,
      lineHeight: '20px',
    },
    cairoBody: {
      fontFamily: '"Cairo" ,sans-serif',
      fontSize: 'clamp(1rem,6vw + 1rem,1.125rem)',
      fontWeight: 400,
      lineHeight: '28px',
    },
    cairoButton: {
      fontFamily: '"Cairo" ,sans-serif',
      fontSize: 'clamp(1rem,1vw + 1rem,1.25rem)',
      fontWeight: 600,
      lineHeight: '32px',
    },
    cairoButtonR: {
      fontFamily: '"Cairo" ,sans-serif',
      fontSize: 'clamp(1rem,1vw + 1rem,1.25rem)',
      fontWeight: 400,
      lineHeight: '28px',
    },
    cairoBold: {
      fontFamily: '"Cairo" ,sans-serif',
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '20px',
    },
    cairoM: {
      fontFamily: '"Cairo" ,sans-serif',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '24px',
    },
    smallDisplayH: {
      fontFamily: 'Cairo',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 'clamp(2rem,3vw + 1rem,2.75rem)',
      lineHeight: '48px',
    },
    bodyLarge: {
      fontFamily: 'Cairo',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 'clamp(1rem,6vw + 1rem,1.125rem)',
      lineHeight: '28px',
    },
    // bodyLargeM: {
    //   fontFamily: 'Cairo',
    //   fontStyle: 'normal',
    //   fontWeight: 500,
    //   fontSize: 'clamp(1rem,6vw + 1rem,1.125rem)',
    //   lineHeight: '28px',
    // },
    bodyMedium: {
      fontFamily: 'Cairo',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '24px',
    },
    bodyMediumB: {
      fontFamily: 'Cairo',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '24px',
    },
    bodyMediumM: {
      fontFamily: 'Cairo',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: '24px',
    },
    bodySmall: {
      fontFamily: 'Cairo',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
      lineHeight: '24px',
    },
    cairoBodySmall: {
      fontFamily: 'Cairo',
      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
      fontWeight: 600,
      lineHeight: '20px',
    },
    h1: {
      fontFamily: "'Cairo', sans-serif",
      fontSize: 'clamp(2rem,3vw + 1rem,2.5rem)',
      fontWeight: 500,
      lineHeight: '48px',
    },
    h2: {
      fontFamily: "'Cairo', sans-serif",
      fontSize: 'clamp(1.5rem,2vw + 1rem,2rem)',
      fontWeight: 500,
      lineHeight: '40px',
    },
    h3: {
      fontFamily: "'Cairo', sans-serif",
      fontSize: 'clamp(1.25rem,3vw + 1rem,1.75rem)',
      fontWeight: 600,
      lineHeight: '36px',
    },
    h4: {
      fontFamily: "'Cairo', sans-serif",
      fontSize: 'clamp(1rem,0.5vw + 1rem,1.25rem)',
      fontWeight: 600,
      lineHeight: '28px',
    },
    h6: {
      fontFamily: 'Cairo',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 'clamp(1rem,0.5vw + 1rem,1.25rem)',
      lineHeight: '28px',
    },
    body2: {
      fontFamily: 'Cairo',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
      lineHeight: '20px',
    },
    cairoR16: {
      fontFamily: 'Cairo',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '20px',
    },
    cairoH4: {
      fontFamily: 'Cairo',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 'clamp(1rem,0.5vw + 1rem,1.25rem)',
      lineHeight: '28px',
    },
    cairoH5: {
      fontFamily: 'Cairo',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 'clamp(1rem,1vw + 1rem,1.5rem)',
      lineHeight: '32px',
    },
    cairoBodyB: {
      fontFamily: 'Cairo',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '20px',
    },
    cairoBodyMB: {
      fontFamily: 'Cairo',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 'clamp(1rem,0.5vw + 1rem,1.25rem)',
      lineHeight: '28px',
    },
  },
};

const ltrTypography = {
  typography: {
    fontFamily: "'Inter', sans-serif",
    cairoM: {
      fontFamily: '"Cairo" ,sans-serif',
      fontSize: '1rem',
      fontWeight: 500,
      lineHeight: '24px',
    },
    smallDisplayH: {
      fontFamily: "'Inter', sans-serif",
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 'clamp(1.25rem,1.25vw + 1rem,2.75rem)',
      lineHeight: '48px',
    },
    largeDisplayH: {
      fontFamily: "'Inter', sans-serif",
      fontSize: 'clamp(2rem,3vw + 1rem,2.5rem)',
      fontWeight: 500,
      lineHeight: '56px',
    },
    bodyLarge: {
      fontFamily: "'Inter', sans-serif",
      fontSize: 'clamp(1rem,0.2vw + 1rem,1.13rem)',
      fontWeight: 400,
      lineHeight: '28px',
    },
    // bodyLargeM: {
    //   fontFamily: "'Inter', sans-serif",
    //   fontStyle: 'normal',
    //   fontWeight: 500,
    //   fontSize: 'clamp(1rem,6vw + 1rem,1.125rem)',
    //   lineHeight: '28px',
    // },
    bodySmall: {
      fontFamily: "'Inter', sans-serif",
      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
      fontWeight: 400,
      lineHeight: '24px',
    },
    bodySmallB: {
      fontFamily: "'Inter', sans-serif",
      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
      fontWeight: 600,
      lineHeight: '20px',
    },
    bodySmallM: {
      fontFamily: "'Inter', sans-serif",
      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
      fontWeight: 500,
      lineHeight: '20px',
    },
    h1: {
      fontFamily: "'Inter', sans-serif",
      fontSize: 'clamp(2rem,3vw + 1rem,2.5rem)',
      fontWeight: 500,
      lineHeight: '48px',
    },
    h1B: {
      fontFamily: "'Inter', sans-serif",
      fontSize: 'clamp(1.375rem,2vw + 1rem,2.25rem)',
      fontWeight: 600,
    },
    h2: {
      fontFamily: "'Inter', sans-serif",
      fontSize: '2.25rem',
      fontWeight: 500,
      lineHeight: '44px',
    },
    h3: {
      fontFamily: "'Inter', sans-serif",
      fontSize: 'clamp(1.5rem,2vw + 1rem,2rem)',
      fontWeight: 500,
      lineHeight: '40px',
    },
    h4: {
      fontFamily: "'Inter', sans-serif",
      fontSize: 'clamp(1.25rem,3vw + 1rem,1.75rem)',
      fontWeight: 500,
      lineHeight: '36px',
    },
    h5: {
      fontFamily: "'Inter', sans-serif",
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 'clamp(1rem,1vw + 1rem,1.5rem)',
      lineHeight: '32px',
    },
    h6: {
      fontFamily: "'Inter', sans-serif",
      fontSize: 'clamp(1rem,0.5vw + 1rem,1.25rem)',
      fontWeight: 500,
      lineHeight: '28px',
    },
    bodyMedium: {
      fontFamily: "'Inter', sans-serif",
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '24px',
    },
    bodyMediumB: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '24px',
    },
    body2: {
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 'clamp(0.5rem,1vw + 1rem,0.875rem)',
      lineHeight: '20px',
    },
  },
};

const ltrTheme = createTheme({
  palette,
  components,
  direction: 'ltr',
  ...ltrTypography,
  spacing: 4,
} as ThemeOptions);
const rtlTheme = createTheme({
  palette,
  components,
  direction: 'rtl',
  ...rtlTypography,
  spacing: 4,
} as ThemeOptions);

const cacheLtr = createCache({
  key: 'muiltr',
});

const cacheRtl = createCache({
  key: 'muirtl',
  // prefixer is the only stylis plugin by default, so when
  // overriding the plugins you need to include it explicitly
  // if you want to retain the auto-prefixing behavior.
  stylisPlugins: [prefixer, rtlPlugin],
});

export { ltrTheme, rtlTheme, cacheLtr, cacheRtl };
