import { PaginatedRequest } from '../types/api';
import { toQueryParams } from '../utils/request';
import { FetchHyperPaySessionsResponse } from '../types/hyper-pay';
import { ApiService } from './common/ApiService';

class HyperPayService extends ApiService {
  pathname = 'admin/hyper-pay';

  fetchSessions = async (data: PaginatedRequest) =>
    this.get<FetchHyperPaySessionsResponse>(`${this.pathname}/sessions?${toQueryParams(data)}`);
}

export const hyperPayService = new HyperPayService();
