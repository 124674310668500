import { ActionType } from '../../types/action';

const readPermission = {
  resource: 'kyc',
  action: ActionType.READ,
};
const updatePermission = {
  resource: 'kyc',
  action: ActionType.UPDATE,
};

export const Kyc = {
  read: readPermission,
  update: updatePermission,
};
