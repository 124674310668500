import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { portfolioConfigService } from '../../../api';
import { CreatePortfolioConfigRequest, UpdatePortfolioConfigRequest } from '../../../types/portfolioConfig';

export const fetchPortfolioConfigs = createAsyncThunk('portfolioConfig/fetch-all', () =>
  portfolioConfigService.fetchPortfolioConfigs(),
);

export const fetchPortfolioConfig = createAsyncThunk('portfolioConfig/get', (key: string) =>
  portfolioConfigService.fetchPortfolioConfig(key),
);

export const deletePortfolioConfig = createAsyncThunk('portfolioConfig/delete', (key: string) =>
  portfolioConfigService.deletePortfolioConfig(key),
);

export const createPortfolioConfigItem = createAsyncThunk(
  'portfolioConfig/create',
  (portfolioConfig: CreatePortfolioConfigRequest) => portfolioConfigService.createPortfolioConfig(portfolioConfig),
);

export const updatePortfolioConfig = createAsyncThunk(
  'portfolioConfig/update',
  (portfolioConfig: UpdatePortfolioConfigRequest) => portfolioConfigService.updatePortfolioConfig(portfolioConfig),
);

export const updatePortfolioConfigs = createAsyncThunk(
  'portfolioConfig/update-all',
  (portfolioConfigs: UpdatePortfolioConfigRequest[]) => portfolioConfigService.updatePortfolioConfigs(portfolioConfigs),
);

export const resetEditPortfolioConfig = createAction('portfolioConfig/reset-edit');

export const cleanPortfolioConfigs = createAction('portfolioConfig/clean');
