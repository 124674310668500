import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { userGroupService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { CreateUserGroupRequest, UpdateUserGroupRequest } from '../../../types/userGroup';

export const fetchUserGroups = createAsyncThunk('userGroup/fetch-all', (data: PaginatedRequest) =>
  userGroupService.fetchUserGroups(data),
);

export const fetchUserGroup = createAsyncThunk('userGroup/get', (id: number) => userGroupService.fetchUserGroup(id));

export const deleteUserGroup = createAsyncThunk('userGroup/delete', (id: number) =>
  userGroupService.deleteUserGroup(id),
);

export const setDefaultUserGroup = createAsyncThunk('userGroup/set-default', (id: number) =>
  userGroupService.setDefaultUserGroup(id),
);

export const createUserGroup = createAsyncThunk('userGroup/create', (userGroup: CreateUserGroupRequest) =>
  userGroupService.createUserGroup(userGroup),
);

export const updateUserGroup = createAsyncThunk('userGroup/update', (userGroup: UpdateUserGroupRequest) =>
  userGroupService.updateUserGroup(userGroup),
);

export const resetEditUserGroup = createAction('userGroup/reset-edit');

export const cleanUserGroups = createAction('userGroup/clean');
