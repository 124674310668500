import { ApiResponse } from './api';
import { User } from './user';
import { UserBank } from './userBank';
import { Role } from './role';
import { UserFee } from './userFee';

export enum WithdrawStatus {
  PROCESSING = 'Processing',
  SUCCESS = 'Success',
  FAILED = 'Failed',
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export enum UserFeeDeductionStatus {
  PROCESSING = 'Processing',
  SUCCESS = 'Success',
  FAILED = 'Failed',
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export enum AnbPaymentStatus {
  INITIATED = 'INITIATED',
  QUEUED = 'QUEUED',
  CANCELED = 'CANCELED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  PROCESSED = 'PROCESSED',
  PAID = 'PAID',
}

export enum PaymentStatementStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
}

export enum StatementType {
  VIBAN_INCOMING = 'VIBAN_INCOMING',
  MASTERCARD_INCOMING = 'MASTERCARD_INCOMING',
  TRANSFER_IN = 'TRANSFER_IN',
  TRANSFER_OUT = 'TRANSFER_OUT',
  WITHDRAWAL = 'WITHDRAWAL',
  FEE = 'FEE',
  OTHER = 'OTHER',
}

export enum PaymentApiService {
  ANB_API = 'ANB_API',
  MASTERCARD_API = 'MASTERCARD_API',
  ANB_WEBHOOK_API = 'ANB_WEBHOOK_API',
  MASTERCARD_WEBHOOK_API = 'MASTERCARD_WEBHOOK_API',
}

export type WithdrawRequest = {
  id: number;
  user: User;
  userId: number;
  paymentId: string;
  amount: number;
  status: WithdrawStatus;
  userBank: UserBank;
  userBankId: number;
  destinationUserBank: UserBank;
  destinationUserBankId: number;
  reviewedAt: Date;
  reviewedBy: Date;
  reviewComment: string;
  createdAt: Date;
  updatedAt: Date;
};

export type UserFeeDeductionRequest = {
  id: number;
  userId: number;
  userFee: UserFee;
  paymentId: string;
  totalFee: number;
  managementFee: number;
  vat: number;
  status: UserFeeDeductionStatus;
  userBankId: number;
  feeCollectionBankAccountNumber: string;
  reviewedAt: Date;
  reviewedBy: Date;
  reviewComment: string;
  createdAt: Date;
  updatedAt: Date;
};

export type AnbPaymentDetails = {
  id: string;
  sequenceNumber: string;
  transactionReferenceNumber: string;
  externalReferenceNumber: string;
  uetrNumber: string;
  status: AnbPaymentStatus;
  exchangeRate: number | string;
  transactionComment: string;
  createdAt: string;
  updatedAt: string;
};

export type PaymentStatement = {
  id: number;
  service: PaymentApiService;
  amount: number;
  userId: number;
  type: StatementType;
  status: PaymentStatementStatus;
  statementId: string;
  groupId: string;
  error?: string;
  createdAt: string;
  updatedAt: string;
};

export type PaymentApiLog = {
  id: number;
  service: PaymentApiService;
  urlResource: string;
  method: string;
  metadata: IAnyObject;
  payload: IAnyObject;
  response: IAnyObject;
  createdAt: string;
  updatedAt: string;
};

export type AnbBalance = {
  accountNumber: string;
  currency: string;
  clearedBalance: number;
};

export enum StatementActorEnum {
  SATELLIE = 'SATELLIE',
  ALPACA = 'ALPACA',
  JADWA = 'JADWA',
  ANB_FEE = 'ANB_FEE',
}

export type FetchAnbPaymentDetailsResponse = ApiResponse<AnbPaymentDetails>;
export type FetchPaymentStatementsResponse = ApiResponse<{ items: PaymentStatement[]; totalItems: number }>;
export type FetchPaymentApiLogsResponse = ApiResponse<{ items: PaymentApiLog[]; totalItems: number }>;
export type FetchAnbBalanceResponse = ApiResponse<AnbBalance>;
export type FetchWithdrawRequestsResponse = { items: WithdrawRequest[]; totalItems: number };
export type UpdateWithdrawRequestRequest = Pick<WithdrawRequest, 'id' | 'status' | 'reviewComment'>;
export type UpdateWithdrawRequestResponse = WithdrawRequest;

export type FetchUserFeeDeductionRequestsResponse = { items: UserFeeDeductionRequest[]; totalItems: number };
export type UpdateUserFeeDeductionRequestRequest = Pick<UserFeeDeductionRequest, 'id' | 'status' | 'reviewComment'>;
export type UpdateUserFeeDeductionRequestResponse = UserFeeDeductionRequest;
