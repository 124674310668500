import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { GridRenderCellParams, DataGridPremiumProps, GridColDef } from '@mui/x-data-grid-premium';
import { CopyToClipboardButton } from '../../../../components/CopyToClipboardButton';
import { Table } from '../../../../components/Table';
import { TableRef } from '../../../../components/Table/Table';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { fetchAlpacaTransfers } from '../../../../redux/modules/alpaca/alpaca.actions';
import { alpacaTransfersByRequestIdSelector } from '../../../../redux/modules/alpaca/alpaca.selectors';
import { ReducerState } from '../../../../redux/store.types';
import { AlpacaTransferDirection, AlpacaTransferStatus, IAlpacaTransfer } from '../../../../types/alpaca';
import { round2Format } from '../../../../utils/number';
import { AlpacaTransferDetailPanelContent } from './AlpacaTransferDetailPanelContent';

export function AlpacaTransfersTable() {
  const requestId = 'alpaca-transfer-list';
  const tableRef = useRef<TableRef>(null);
  const [columns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
    },
    {
      field: 'transferId',
      headerName: 'Transfer Id',
      type: 'string',
      width: 300,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return <CopyToClipboardButton value={params.value || '-'} />;
      },
    },
    {
      field: 'ntaId',
      headerName: 'NTA Id',
      type: 'string',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return <CopyToClipboardButton value={params.value || '-'} />;
      },
    },
    {
      field: 'anbTransferId',
      headerName: 'Anb Transfer Id',
      type: 'string',
      width: 300,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return <CopyToClipboardButton value={params.value || '-'} />;
      },
    },
    {
      field: 'fxRate',
      headerName: 'Fx Rate',
      type: 'number',
      width: 100,
      renderCell: (params) => (params.value ? round2Format(params.value) : ''),
    },
    {
      field: 'realFxRate',
      headerName: 'Real Fx Rate',
      type: 'number',
      width: 100,
      renderCell: (params) => (params.value ? round2Format(params.value) : ''),
    },
    {
      field: 'sarAmount',
      headerName: 'Sar Amount',
      type: 'number',
      width: 100,
      renderCell: (params) => (params.value ? round2Format(params.value) : ''),
    },
    {
      field: 'actualSarAmount',
      headerName: 'Actual Sar Amount',
      type: 'number',
      width: 100,
      renderCell: (params) => (params.value ? round2Format(params.value) : ''),
    },
    {
      field: 'usdAmount',
      headerName: 'Usd Amount',
      type: 'number',
      width: 100,
      renderCell: (params) => (params.value ? round2Format(params.value) : ''),
    },
    {
      field: 'originalUsdAmount',
      headerName: 'Original Usd Amount',
      type: 'number',
      width: 100,
      renderCell: (params) => (params.value ? round2Format(params.value) : ''),
    },
    {
      field: 'actualUsdAmount',
      headerName: 'Actual Usd Amount',
      type: 'number',
      width: 100,
      renderCell: (params) => (params.value ? round2Format(params.value) : ''),
    },
    {
      field: 'direction',
      headerName: 'Direction',
      type: 'singleSelect',
      valueOptions: Object.values(AlpacaTransferDirection),
      minWidth: 100,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(AlpacaTransferStatus),
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const { totalTransfers, transfers, fetchStatus } = useSelector((state: ReducerState) =>
    alpacaTransfersByRequestIdSelector(state, requestId),
  );

  const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridPremiumProps<IAlpacaTransfer>['getDetailPanelContent']>
  >(({ row }) => <AlpacaTransferDetailPanelContent alpacaTransfer={row} />, []);

  const getDetailPanelHeight = React.useCallback(() => 400, []);

  const loading = fetchStatus === fetchStatuses.pending;

  return (
    <Table
      ref={tableRef}
      wrapperHeight={700}
      fetchItems={fetchAlpacaTransfers}
      rows={transfers}
      columns={columns}
      loading={loading}
      rowCount={totalTransfers}
      density="compact"
      rowThreshold={0}
      getDetailPanelHeight={getDetailPanelHeight}
      getDetailPanelContent={getDetailPanelContent}
      fetchItemsCustomParams={{ requestId }}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      initialColumnVisibilityModel={{ ntaId: false, anbTransferId: false }}
      toolbarExportProps={{
        csvOptions: { fileName: `alpaca-transfers-${new Date().toLocaleString()}` },
        excelOptions: { fileName: `alpaca-transfers-${new Date().toLocaleString()}` },
      }}
    />
  );
}
