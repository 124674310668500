import { ApiResponse } from './api';
import { Benchmark } from './benchmark';
import { FundManager } from './fundManager';
import { IndexFund } from './indexFund';
import { Portfolio, PortfolioFileTypeOption } from './portfolio';
import { ProductType } from './productType';

export enum SubscribedPortfolioStatus {
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
}

export interface SubscribedPortfolioCurrencyOption {
  id: number;
  name: string;
}

export interface SubscribedPortfolioStatusOption {
  id: number;
  name: SubscribedPortfolioStatus;
}

export interface SubscribedPortfolioFile {
  id: number;
  portfolioFileType: PortfolioFileTypeOption;
  fileId: string;
  isPublic: boolean;
}

export interface SubscribedPortfolioAssociatedIndexFund {
  id: number;
  indexFund: IndexFund;
  weight: number;
  costBase: number;
  indexFundId: number;
  subscribedPortfolioId: number;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export type SubscribedPortfolio = {
  id: number;
  userId?: number;
  nameEn: string;
  nameAr: string;
  descriptionEn: string;
  descriptionAr: string;
  objectiveEn: string;
  objectiveAr: string;
  shariaaComplaint: boolean;
  createdBy: number;
  expectedMinimumReturn: number;
  targetExpectedReturn: number;
  expectedMaximumReturn: number;
  targetExpectedRisk: number;
  benchmark: Benchmark;
  productType: ProductType;
  sharpRatio: number;
  displayByGics: boolean;
  status: SubscribedPortfolioStatusOption;
  currency: SubscribedPortfolioCurrencyOption;
  manager: FundManager;
  files: SubscribedPortfolioFile[];
  associatedIndexFunds: SubscribedPortfolioAssociatedIndexFund[];
  subscribedPortfolioAssociatedIndexFund: SubscribedPortfolioAssociatedIndexFund[];
  originalPortfolio?: Portfolio;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchSubscribedPortfolioResponse = ApiResponse<SubscribedPortfolio>;
export type FetchSubscribedPortfoliosResponse = ApiResponse<{
  items: SubscribedPortfolio[];
  totalItems: number;
}>;
export type FetchSubscribedPortfolioStatusOptionsResponse = ApiResponse<SubscribedPortfolioStatusOption[]>;

export type UpdateSubscribedPortfolioStatusRequest = Pick<SubscribedPortfolio, 'id'> & {
  status: SubscribedPortfolioStatus;
};
export type UpdateSubscribedPortfolioStatusResponse = ApiResponse<{ message: string }>;

export type SaveSubscribedPortfolioRequest = Omit<
  SubscribedPortfolio,
  | 'id'
  | 'benchmark'
  | 'productType'
  | 'status'
  | 'currency'
  | 'manager'
  | 'assets'
  | 'files'
  | 'associatedIndexFunds'
  | 'subscribedPortfolioAssociatedIndexFund'
  | 'createdBy'
  | 'createdAt'
  | 'updatedAt'
> & {
  id?: number;
  files: { portfolioFileType: number; fileId: string; isPublic: boolean }[];
  associatedIndexFunds: { indexFund: number; weight: number }[];
  status: number;
  currency: number;
  manager: number;
  benchmark: number;
  productType: number;
};
export type CreateSubscribedPortfolioResponse = ApiResponse<SubscribedPortfolio>;

export type UpdateSubscribedPortfolioRequestFile = {
  id?: number;
  portfolioFileType: number;
  fileId: string;
  isPublic: boolean;
};
export type UpdateSubscribedPortfolioRequestAssociatedIndexFund = { id?: number; indexFund: number; weight: number };
export type UpdateSubscribedPortfolioRequest = Omit<
  SubscribedPortfolio,
  | 'status'
  | 'benchmark'
  | 'productType'
  | 'createdBy'
  | 'currency'
  | 'manager'
  | 'files'
  | 'associatedIndexFunds'
  | 'subscribedPortfolioAssociatedIndexFund'
  | 'createdAt'
  | 'updatedAt'
> & {
  files: UpdateSubscribedPortfolioRequestFile[];
  associatedIndexFunds: UpdateSubscribedPortfolioRequestAssociatedIndexFund[];
  status: number;
  currency: number;
  manager: number;
  benchmark: number;
  productType: number;
};
export type UpdateSubscribedPortfolioResponse = ApiResponse<SubscribedPortfolio>;
