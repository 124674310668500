import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { userFeeDeductionService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import {
  SaveUserFeeDeductionRequest,
  UpdateUserFeeDeductionRequest,
  UpdateUserFeeDeductionStatusRequest,
} from '../../../types/userFeeDeduction';

export const fetchUserFeeDeductions = createAsyncThunk(
  'user-fee-deduction/fetch-all',
  (data: PaginatedRequest & { requestId: string }, { rejectWithValue }) =>
    userFeeDeductionService.fetchUserFeeDeductions(data).catch((error) => rejectWithValue(error)),
);

export const fetchUserFeeDeduction = createAsyncThunk('user-fee-deduction/get', (id: number) =>
  userFeeDeductionService.fetchUserFeeDeduction(id),
);

export const deleteUserFeeDeduction = createAsyncThunk('user-fee-deduction/delete', (id: number) =>
  userFeeDeductionService.deleteUserFeeDeduction(id),
);

export const saveUserFeeDeduction = createAsyncThunk(
  'user-fee-deduction/save',
  (portfolio: SaveUserFeeDeductionRequest) => userFeeDeductionService.saveUserFeeDeduction(portfolio),
);

export const updateUserFeeDeduction = createAsyncThunk(
  'user-fee-deduction/update',
  (portfolio: UpdateUserFeeDeductionRequest) => userFeeDeductionService.updateUserFeeDeduction(portfolio),
);

export const updateUserFeeDeductionStatus = createAsyncThunk(
  'user-fee-deduction/update-status',
  (data: UpdateUserFeeDeductionStatusRequest) => userFeeDeductionService.updateUserFeeDeductionStatus(data),
);

export const fetchUserFeeDeductionStatusOptions = createAsyncThunk(
  'user-fee-deduction/fetch-portfolio-status-options',
  () => userFeeDeductionService.fetchUserFeeDeductionStatusOptions(),
);

export const cleanUserFeeDeductions = createAction('user-fee-deduction/clean');

export const resetEditUserFeeDeduction = createAction('user-fee-deduction/reset-edit');
