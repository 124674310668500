import { IndexFund } from '../types/indexFund';
import { UpdatePortfolioRequestAssociatedIndexFund } from '../types/portfolio';

function getSelectedIndexFund(indexFunds: IndexFund[], indexFundId: number) {
  return indexFunds.find((i) => i.id === indexFundId);
}

export const getAssetChartItems = (
  indexFunds: IndexFund[],
  associatedIndexFunds: UpdatePortfolioRequestAssociatedIndexFund[],
  cashAllocationPercentage: number,
) => {
  const assetsWeight = associatedIndexFunds.reduce(
    (res, item) => {
      const indexFund = getSelectedIndexFund(indexFunds, item.indexFund);

      if (!indexFund) {
        return res;
      }

      if (res[indexFund.asset.id]) {
        res[indexFund.asset.id].weight += +item.weight;
      } else {
        res[indexFund.asset.id] = { ...item };
      }

      return res;
    },
    {} as Record<number, UpdatePortfolioRequestAssociatedIndexFund>,
  );

  return [
    { label: 'Cash Allocation', value: cashAllocationPercentage },
    ...Object.values(assetsWeight).map((aif) => ({
      label: getSelectedIndexFund(indexFunds, aif.indexFund)?.asset?.nameEn || '',
      value: +aif.weight,
    })),
  ];
};

export const getAssetChartItemsByGics = (
  indexFunds: IndexFund[],
  associatedIndexFunds: UpdatePortfolioRequestAssociatedIndexFund[],
  cashAllocationPercentage: number,
) => {
  const weightByGics = associatedIndexFunds.reduce(
    (res, aif) => {
      const indexFund = indexFunds.find((fund) => fund.id === aif.indexFund);

      if (indexFund) {
        for (const gics of indexFund.gics as any) {
          res[gics.gics.nameEn] = (res[gics.gics.nameEn] || 0) + (gics.weight * aif.weight) / 100;
        }
      }

      return res;
    },
    {} as Record<string, number>,
  );

  return [
    { label: 'Cash Allocation', value: cashAllocationPercentage },
    ...Object.keys(weightByGics).map((key) => ({
      label: key,
      value: weightByGics[key],
    })),
  ];
};
