import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchFeeCalculationResponse, FetchFeeCalculationsResponse } from '../../../types/feeCalculation';
import { cleanFeeCalculations, fetchFeeCalculation, fetchFeeCalculations } from './feeCalculation.actions';
import { FeeCalculationState } from './feeCalculation.types';

const initialState: FeeCalculationState = {
  feeCalculations: [],
  totalFeeCalculations: 0,
  fetchStatus: fetchStatuses.idle,
  editFeeCalculation: null,

  error: null,
};

const feeCalculationsSlice = createSlice({
  name: 'feeCalculations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFeeCalculations.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchFeeCalculations.fulfilled, (state, action: PayloadAction<FetchFeeCalculationsResponse>) => {
      state.feeCalculations = action.payload.data?.items;
      state.totalFeeCalculations = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFeeCalculations.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFeeCalculation.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchFeeCalculation.fulfilled, (state, action: PayloadAction<FetchFeeCalculationResponse>) => {
      state.editFeeCalculation = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFeeCalculation.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(cleanFeeCalculations.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const feeCalculationReducer = feeCalculationsSlice.reducer;
