import { ApiService } from './common/ApiService';
import type { SearchNamesResponse } from '../types/names';

class NamesService extends ApiService {
  pathname = 'names';

  search = async (searchValue: string) => {
    const names = await this.get<SearchNamesResponse>(this.pathname);

    // This logic should be on backend side.
    return searchValue
      ? names.map(({ name }) => name).filter((name) => name.toLowerCase().includes(searchValue.toLowerCase()))
      : [];
  };
}

export const namesService = new NamesService();
