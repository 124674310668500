import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import JoinFullIcon from '@mui/icons-material/JoinFull';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CircularProgress from '@mui/material/CircularProgress';
import { FakeButtonLink } from '../../components/FakeLink';
import { useAppDispatch } from '../../redux/hooks';
import { getPrivateFile } from '../../redux/modules/media/media.actions';
import { mediaCacheSelector } from '../../redux/modules/media/media.selectors';
import { fetchBatchAttachments } from '../../redux/modules/feeBatch/feeBatch.actions';
import {
  batchAttachmentsLoadingSelector,
  batchAttachmentsSelector,
  feeBatchSelector,
} from '../../redux/modules/feeBatch/feeBatch.selectors';
import { locations } from '../../routes/locations';
import { FetchMediaFileResponse } from '../../types/media';
import { BatchAttachment, BatchAttachmentType } from '../../types/feeBatch';
import { isDispatchApiSuccess } from '../../utils/api';
import { formatDateTime } from '../../utils/date';
import { AddAttachmentDialog } from './AddAttachmentDialog';

interface AttachmentsTabProps {
  batchId: string;
}

const mediaCacheTTL = 5 * 60 * 1000; // 5 mins

export function AttachmentsTab({ batchId }: AttachmentsTabProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const { error } = useSelector(feeBatchSelector);
  const attachments = useSelector(batchAttachmentsSelector);
  const mediaCache = useSelector(mediaCacheSelector);
  const isLoading = useSelector(batchAttachmentsLoadingSelector);

  const openInNewTab = async (url: string) => {
    if (mediaCache[url]?.url && mediaCache[url].timestamp + mediaCacheTTL > Date.now()) {
      return window.open(mediaCache[url].url, '_blank', 'noopener,noreferrer');
    }

    const result = await dispatch(getPrivateFile(url));

    if (isDispatchApiSuccess<FetchMediaFileResponse>(result)) {
      window.open(result.payload.data.url, '_blank', 'noopener,noreferrer');
    } else {
      enqueueSnackbar(`Error: ${result?.error?.message}`, { variant: 'error' });
    }
  };

  useEffect(() => {
    if (batchId) {
      dispatch(fetchBatchAttachments({ batchId, sortField: 'id', sortOrder: 'desc' }));
    }
  }, []);

  const onAddNew = (): void => {
    setOpen(true);
  };

  const handleClose = (isReload: boolean) => {
    if (isReload) {
      dispatch(fetchBatchAttachments({ batchId, sortField: 'id', sortOrder: 'desc' }));
    }
    setOpen(false);
  };

  const renderCreatedByLink = (attachment: BatchAttachment) => (
    <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.employee(attachment.createdBy)}>
      Created By Employee&nbsp;({attachment?.createdBy})
    </FakeButtonLink>
  );

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}

      <AddAttachmentDialog open={open} batchId={batchId} onClose={handleClose} />

      <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px' }}>
        <Typography variant="h6">
          Attachments &nbsp;
          <Fab color="primary" size="small" aria-label="Create Action" onClick={onAddNew}>
            <AddIcon />
          </Fab>
        </Typography>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {isLoading && <CircularProgress />}
          {attachments.map((at, index) => (
            <React.Fragment key={`attachment-item-${at.id}`}>
              <ListItem alignItems="flex-start" secondaryAction={renderCreatedByLink(at)}>
                <ListItemAvatar
                  sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    alignSelf: 'center',
                  }}
                >
                  {at.batchAttachmentType.name === BatchAttachmentType.OTHER && <InsertDriveFileIcon />}
                  {at.batchAttachmentType.name === BatchAttachmentType.EXECUTION_FILE && <FactCheckIcon />}
                  {at.batchAttachmentType.name === BatchAttachmentType.CONSOLIDATE_EXECUTION_FILE && <JoinFullIcon />}
                </ListItemAvatar>
                <ListItemText
                  primary={`${at.batchAttachmentType.name} (${formatDateTime(at.createdAt as string)})`}
                  secondary={
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      endIcon={<OpenInNewIcon />}
                      onClick={() => openInNewTab(at.documentLink)}
                    >
                      {at.documentLink}
                    </Button>
                  }
                />
              </ListItem>
              {index < attachments.length - 1 && <Divider variant="inset" component="li" />}
            </React.Fragment>
          ))}
        </List>
      </Grid>
    </>
  );
}
