import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useAppDispatch } from '../../redux/hooks';
import { fetchPortfolioStats } from '../../redux/modules/analytics/analytics.actions';
import { analyticsSelector } from '../../redux/modules/analytics/analytics.selectors';
import { round2Format } from '../../utils/number';

export function OverallPerformanceWidget() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchPortfolioStats());
  }, []);

  const { portfolioStats } = useSelector(analyticsSelector);

  return (
    <Grid item container flexDirection="row" sx={{ mt: 7 }} xs={12}>
      {portfolioStats.map(({ statName, value }) => (
        <Grid item container sm={12} md={6} lg={4} sx={{ mb: 2 }} key={`portfolio-stat-widget-element-${statName}`}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1">{statName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} flexDirection="row" display="flex" alignItems="center">
            <Typography sx={{ ml: 1 }} variant="body2">
              {round2Format(+(value || 0))}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}
