import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { CSVLink } from 'react-csv';
import LoadingButton from '@mui/lab/LoadingButton';
import UploadIcon from '@mui/icons-material/Upload';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useAppDispatch } from '../../redux/hooks';
import {
  fetchOrderExecutionsReportByOrderId,
  reconcileOrderExecutionsByOrderId,
} from '../../redux/modules/portfolioOrder/portfolioOrder.actions';
import { portfolioOrderSelector } from '../../redux/modules/portfolioOrder/portfolioOrder.selectors';
import { isDispatchApiSuccess } from '../../utils/api';
import { prepareOrderExecutionsReportData } from '../../utils/portfolioOrder';
import { OrderExecutionReportType } from '../../types/orderExecution';

interface ImportReconciliationFileDialogProps {
  open: boolean;
  orderId: string;
  onClose: (isReload: boolean) => void;
}

export function ImportReconciliationFileDialog({ open, orderId, onClose }: ImportReconciliationFileDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File>();
  const csvOrderExecutionsReportLink = useRef<CSVLink>();
  const [reportData, setReportData] = useState('');

  const { reconcileOrderExecutionsStatus, error } = useSelector(portfolioOrderSelector);

  useEffect(() => {
    setFile(undefined);
  }, [open]);

  const onImportFile = async () => {
    if (file) {
      const res = await dispatch(reconcileOrderExecutionsByOrderId({ orderId, file }));

      if (isDispatchApiSuccess(res)) {
        enqueueSnackbar('Reconciliation file imported successfully!', { variant: 'success' });
        onClose(true);
      } else {
        enqueueSnackbar('Import Reconciliation file was failed!', { variant: 'error' });
      }
    }
  };

  const onDownloadReconciliationFile = async () => {
    const res: any = await dispatch(
      fetchOrderExecutionsReportByOrderId({
        orderId,
        reportType: OrderExecutionReportType.USER,
      }),
    );

    if (isDispatchApiSuccess(res)) {
      setReportData(prepareOrderExecutionsReportData(res.payload.data, ',', false));

      setTimeout(() => {
        if (csvOrderExecutionsReportLink.current) {
          csvOrderExecutionsReportLink.current.link.click();
        }
      }, 0);
    } else {
      enqueueSnackbar('Download Portfolio Order Executions Report failed!', { variant: 'error' });
    }
  };

  const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target?.files?.[0];

    if (newFile) {
      setFile(newFile);
    }
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Reconcile order: {orderId}</DialogTitle>
      <DialogContent>
        <DialogContentText display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          {error && <Alert severity="error">{error.message}</Alert>}
          <Button
            variant="outlined"
            component="label"
            aria-label="import reconciliation file"
            color="primary"
            startIcon={<UploadIcon />}
          >
            {file ? file.name : 'Select Reconciliation file'}
            <input ref={inputRef} hidden accept="text/csv" type="file" onChange={onChangeFile} />
          </Button>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between">
          <Grid item>
            <CSVLink
              data={reportData}
              filename={`order-execution-reconciliation-${orderId}.csv`}
              className="hidden"
              separator=","
              ref={csvOrderExecutionsReportLink}
              target="_blank"
            />
            <LoadingButton
              type="button"
              color="primary"
              variant="outlined"
              endIcon={<UploadIcon />}
              loading={reconcileOrderExecutionsStatus === fetchStatuses.pending}
              loadingPosition="end"
              onClick={onDownloadReconciliationFile}
            >
              Download Reconciliation File
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              type="button"
              color="primary"
              variant="contained"
              endIcon={<UploadIcon />}
              loading={reconcileOrderExecutionsStatus === fetchStatuses.pending}
              loadingPosition="end"
              onClick={onImportFile}
            >
              Import
            </LoadingButton>
            <Button sx={{ ml: 2 }} color="secondary" variant="contained" onClick={() => onClose(false)}>
              Discard
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
