import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddIcon from '@mui/icons-material/Add';
import { ProtectedContent } from '../../components/ProtectedContent';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useAppDispatch } from '../../redux/hooks';
import { fetchUserBankInfo } from '../../redux/modules/user/user.actions';
import { madkhoulBankInfoSelector, otherBankInfoSelector, userSelector } from '../../redux/modules/user/user.selectors';
import * as permissions from '../../utils/permissions';
import { AddFundsDialog } from './AddFundsDialog';

interface BankInfoTabProps {
  id: number;
}

export function BankInfoTab({ id }: BankInfoTabProps) {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const { error, fetchBankInfoStatus } = useSelector(userSelector);
  const bankInfo = useSelector(madkhoulBankInfoSelector);
  const otherBankInfoItems = useSelector(otherBankInfoSelector);
  const isLoading = fetchBankInfoStatus === fetchStatuses.pending;

  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';

  useEffect(() => {
    if (id) {
      dispatch(fetchUserBankInfo(id));
    }
  }, []);

  const onAddFunds = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      <Grid item container flexDirection="row" component={Paper} sx={{ p: '24px' }}>
        {isLoading ? (
          <Skeleton variant="rectangular" width="100%" height={80} />
        ) : (
          <>
            <Grid item sm={12} md={6}>
              <Typography variant="h6">Madkhol IBAN number</Typography>
              <Typography variant="body2">{bankInfo?.iban || '-'}</Typography>
            </Grid>
            <Grid item container sm={12} md={6}>
              <Grid item>
                <Typography variant="h6">Account number</Typography>
                <Typography variant="body2" id="AccountNumberValue">
                  {bankInfo?.userBankDetails?.accountNumber || '-'}
                </Typography>
              </Grid>
              {!isProduction && (
                <Grid item hidden={!bankInfo?.userBankDetails?.accountNumber}>
                  <ProtectedContent items={[permissions.UserBank.updateUserBankAmount]}>
                    <LoadingButton
                      id="AddMoneyButton"
                      color="primary"
                      variant="contained"
                      sx={{ ml: 2 }}
                      onClick={onAddFunds}
                      endIcon={<AddIcon />}
                      loadingPosition="end"
                    >
                      Add funds
                    </LoadingButton>
                  </ProtectedContent>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>
      <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }}>
        <Typography variant="h6">Other Bank Accounts</Typography>

        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {otherBankInfoItems.map((bo, index) => (
            <React.Fragment key={`bank-info-item-${bo.id}`}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar
                  sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    alignSelf: 'center',
                  }}
                >
                  <AccountBalanceIcon />
                </ListItemAvatar>
                <ListItemText
                  primary={bo.userBankDetails.beneficiaryName}
                  secondary={
                    <React.Fragment>
                      <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                        SWIFT:&nbsp;{bo.userBankDetails.swiftCode}
                      </Typography>
                      — IBAN:&nbsp;{bo.userBankDetails.iban}
                    </React.Fragment>
                  }
                />
              </ListItem>
              {index < otherBankInfoItems.length - 1 && <Divider variant="inset" component="li" />}
            </React.Fragment>
          ))}
        </List>
      </Grid>
      <AddFundsDialog open={open} onClose={handleClose} bankInfo={bankInfo} />
    </>
  );
}
