import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { IAsyncDialogProps } from '../../AsyncDialog/AsyncDialog';

export function CustomRowsPerPageDialog(props: IAsyncDialogProps<number, number>) {
  const [value, setValue] = useState<number>(+(props.data || 0));
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(true);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(+event.target.value || 0);
    setTouched(true);
  };

  const onOk = () => {
    if (!error && value) {
      return props.onOk(+value);
    }

    return setTouched(true);
  };

  useEffect(() => {
    setValue(+(props.data || 0));
    setError(true);
    setTouched(false);
  }, [props.open]);

  useEffect(() => {
    setError(!value);
  }, [value]);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>What is the number of rows per page?</DialogTitle>
      <DialogContent>
        <DialogContentText>Type the number of rows per page</DialogContentText>
        <TextField
          margin="normal"
          id="amount"
          label="Value"
          placeholder="Enter the value"
          variant="standard"
          type="number"
          value={value || 0}
          onChange={onChange}
          error={touched && error}
          helperText="Value is required"
          autoFocus
          fullWidth
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button onClick={onOk}>Set</Button>
      </DialogActions>
    </Dialog>
  );
}
