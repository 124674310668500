import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { FakeButtonLink } from '../../components/FakeLink';
import { Table } from '../../components/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { mapUserIdToStatementActor } from '../../constants/payment';
import { locations } from '../../routes/locations';
import { hyperPaySelector } from '../../redux/modules/hyperPay/hyper-pay.selectors';
import { HyperPaySessionStatus, HyperPaySessionType } from '../../types/hyper-pay';
import { fetchHyperPaySessions } from '../../redux/modules/hyperPay/hyper-pay.actions';

export function HyperPaySessionsTab() {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id' },
    {
      field: 'requestId',
      headerName: 'Request ID',
      width: 100,
    },
    {
      field: 'transactionId',
      headerName: 'Transaction ID',
      width: 100,
    },
    {
      field: 'userId',
      headerName: 'User Id',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        const staticStatementActor = mapUserIdToStatementActor[params.value];

        if (staticStatementActor) {
          return staticStatementActor;
        }

        return (
          <Tooltip title={params.value} followCursor>
            <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.user(params.value)}>
              {params.value}
            </FakeButtonLink>
          </Tooltip>
        );
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 100,
    },
    {
      field: 'currency',
      headerName: 'Currency',
      width: 100,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(HyperPaySessionStatus),
      width: 100,
    },
    {
      field: 'type',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: Object.values(HyperPaySessionType),
      width: 100,
    },
    {
      field: 'sessionId',
      headerName: 'Session ID',
      width: 150,
    },
    {
      field: 'descriptor',
      headerName: 'Descriptor',
      width: 150,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: false,
      type: 'dateTime',
      width: 170,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      sortable: false,
      type: 'dateTime',
      width: 170,
    },
  ]);
  const { totalHyperPaySessions, hyperPaySessions, fetchHyperPaySessionsStatus } = useSelector(hyperPaySelector);

  return (
    <Table
      fetchItems={fetchHyperPaySessions}
      rows={hyperPaySessions}
      columns={columns.current}
      loading={fetchHyperPaySessionsStatus === fetchStatuses.pending}
      rowCount={totalHyperPaySessions}
      initialColumnVisibilityModel={{ id: false, metadata: false }}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      toolbarExportProps={{
        excelOptions: { fileName: `statements-${Date.now()}` },
        csvOptions: { fileName: `statements-${Date.now()}` },
      }}
    />
  );
}
