import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { fundValuationFrequencyFormFields } from '../../forms/fields/formFields';
import { fundValuationFrequencyFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import {
  createFundValuationFrequency,
  updateFundValuationFrequency,
} from '../../redux/modules/fundValuationFrequency/fundValuationFrequency.actions';
import { fundValuationFrequencySelector } from '../../redux/modules/fundValuationFrequency/fundValuationFrequency.selectors';
import { FundValuationFrequency } from '../../types/fundValuationFrequency';

interface FundValuationFrequencyDialogProps {
  open: boolean;
  initialValue: FundValuationFrequency | null;
  onClose: (isReload: boolean) => void;
}

export function FundValuationFrequencyDialog({ open, initialValue, onClose }: FundValuationFrequencyDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, values, handleChange, touched, errors, submitForm, setValues, resetForm } = useFormik({
    initialValues: {
      [fundValuationFrequencyFormFields.nameEn]: '',
      [fundValuationFrequencyFormFields.nameAr]: '',
      [fundValuationFrequencyFormFields.descriptionEn]: '',
      [fundValuationFrequencyFormFields.descriptionAr]: '',
      [fundValuationFrequencyFormFields.expression]: '',
    },
    validationSchema: fundValuationFrequencyFormSchema,
    onSubmit: (formValues) => {
      if (initialValue?.id) {
        dispatch(updateFundValuationFrequency({ id: initialValue.id, ...formValues }));
      } else {
        dispatch(createFundValuationFrequency(formValues));
      }
    },
  });
  const { createStatus, updateStatus, error } = useSelector(fundValuationFrequencySelector);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    setValues({
      nameEn: initialValue?.nameEn || '',
      nameAr: initialValue?.nameAr || '',
      descriptionEn: initialValue?.descriptionEn || '',
      descriptionAr: initialValue?.descriptionAr || '',
      expression: initialValue?.expression || '',
    });
  }, [initialValue]);

  useEffect(() => {
    if (createStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Fun dValuation Frequency created!', { variant: 'success' });
    }
    if (createStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Fund Valuation Frequency creation error!', { variant: 'error' });
    }

    if (updateStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Fund Valuation Frequency updated!', { variant: 'success' });
    }
  }, [createStatus, updateStatus]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        {initialValue?.id ? 'Edit fund Valuation Frequency' : 'Create a new Fund Valuation Frequency'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error && <Alert severity="error">{error.message}</Alert>}
          Enter the Fund Valuation Frequency name
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="nameEn"
            name={fundValuationFrequencyFormFields.nameEn}
            label="Name En"
            value={values[fundValuationFrequencyFormFields.nameEn]}
            onChange={handleChange}
            error={touched.nameEn && !!errors.nameEn}
            helperText={touched.nameEn && errors.nameEn}
          />
          <TextField
            dir="rtl"
            margin="normal"
            required
            fullWidth
            id="nameAr"
            name={fundValuationFrequencyFormFields.nameAr}
            label="Name Ar"
            value={values[fundValuationFrequencyFormFields.nameAr]}
            onChange={handleChange}
            error={touched.nameAr && !!errors.nameAr}
            helperText={touched.nameAr && errors.nameAr}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            multiline
            id={fundValuationFrequencyFormFields.descriptionEn}
            name={fundValuationFrequencyFormFields.descriptionEn}
            label="Description En"
            value={values[fundValuationFrequencyFormFields.descriptionEn]}
            onChange={handleChange}
            error={touched.descriptionEn && !!errors.descriptionEn}
            helperText={touched.descriptionEn && errors.descriptionEn}
          />
          <TextField
            dir="rtl"
            margin="normal"
            required
            fullWidth
            multiline
            id={fundValuationFrequencyFormFields.descriptionAr}
            name={fundValuationFrequencyFormFields.descriptionAr}
            label="Description Ar"
            value={values[fundValuationFrequencyFormFields.descriptionAr]}
            onChange={handleChange}
            error={touched.descriptionAr && !!errors.descriptionAr}
            helperText={touched.descriptionAr && errors.descriptionAr}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            id={fundValuationFrequencyFormFields.expression}
            name={fundValuationFrequencyFormFields.expression}
            label="Expression"
            value={values[fundValuationFrequencyFormFields.expression]}
            onChange={handleChange}
            error={touched.expression && !!errors.expression}
            helperText={touched.expression && errors.expression}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={createStatus === fetchStatuses.pending}
          loadingPosition="end"
          onClick={submitForm}
        >
          {initialValue?.id ? 'Save' : 'Create'}
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
