import { ActionType } from '../../types/action';

const listEarlySignupUsersPermission = {
  resource: 'early-signup-users',
  action: ActionType.READ,
};

export const Anonymous = {
  listEarlySignupUsers: listEarlySignupUsersPermission,
};
