import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as sentryBrowser from '@sentry/browser';
import { FetchUserProfileResponse, LoginResponse } from '../../../types/auth';
import { cleanAuth } from './auth.actions';
import { AuthState } from './auth.types';

const initialState: AuthState = {
  user: null,
  accessToken: '',
  isLoggedIn: null,
  otpRequired: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<LoginResponse>) => {
      const { accessToken, otpRequired } = action.payload;

      state.otpRequired = Boolean(otpRequired);

      if (!otpRequired) {
        localStorage.setItem('accessToken', action.payload.accessToken);

        state.isLoggedIn = null;
        state.accessToken = accessToken;
      }
    },
    setUserProfile: (state, action: PayloadAction<FetchUserProfileResponse>) => {
      state.user = action.payload;

      sentryBrowser.setUser({
        id: `${action.payload.id}`,
        username: action.payload.name,
        email: action.payload.email,
      });

      state.isLoggedIn = true;
    },
    logOut: (state) => {
      state.user = null;
      state.isLoggedIn = false;
      state.accessToken = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(cleanAuth.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const { setCredentials, logOut, setUserProfile } = authSlice.actions;

export const authReducer = authSlice.reducer;
