import { ApiResponse } from './api';

export enum UserConfigKey {
  USER_AUTO_ACTIVATE = 'user_auto_activate',
  WITHDRAW_ENABLED = 'withdraw_enabled',
}

export type UserConfig = {
  key: UserConfigKey;
  value: string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchUserConfigResponse = ApiResponse<UserConfig>;
export type FetchUserConfigsResponse = ApiResponse<UserConfig[]>;

export type CreateUserConfigRequest = Omit<UserConfig, 'id' | 'createdAt' | 'updatedAt'>;
export type CreateUserConfigResponse = ApiResponse<UserConfig>;

export type UpdateUserConfigRequest = Omit<UserConfig, 'key' | 'createdAt' | 'updatedAt'> & { key: string };
export type UpdateUserConfigsRequest = {
  items: UpdateUserConfigRequest[];
};
export type UpdateUserConfigResponse = ApiResponse<UserConfig>;
