import { PaginatedRequest } from '../types/api';
import {
  CreateAssetRequest,
  CreateAssetResponse,
  FetchAssetsResponse,
  FetchAssetResponse,
  UpdateAssetRequest,
  UpdateAssetResponse,
} from '../types/asset';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class AssetService extends ApiService {
  pathname = 'admin';

  fetchAssets = async (data: PaginatedRequest) =>
    this.get<FetchAssetsResponse>(`${this.pathname}/asset?${toQueryParams(data)}`);

  fetchAsset = async (id: number) => this.get<FetchAssetResponse>(`${this.pathname}/asset/${id}`);

  deleteAsset = async (id: number) => this.delete<FetchAssetResponse>(`${this.pathname}/asset/${id}`);

  createAsset = async (asset: CreateAssetRequest) =>
    this.post<CreateAssetResponse, CreateAssetRequest>(`${this.pathname}/asset`, asset);

  updateAsset = async ({ id, ...asset }: UpdateAssetRequest) =>
    this.put<UpdateAssetResponse, Omit<UpdateAssetRequest, 'id'>>(`${this.pathname}/asset/${id}`, asset);
}

export const assetService = new AssetService();
