import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import {
  GridActionsCellItem,
  GridActionsCellItemProps,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { usePermissions, useSearchParamsState } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import {
  cleanUserGroups,
  deleteUserGroup,
  fetchUserGroups,
  setDefaultUserGroup,
} from '../../redux/modules/userGroup/userGroup.actions';
import { userGroupSelector } from '../../redux/modules/userGroup/userGroup.selectors';
import { UserGroup } from '../../types/userGroup';
import { isDispatchApiSuccess } from '../../utils/api';
import { parseJSON } from '../../utils/json';
import * as permissions from '../../utils/permissions';
import { UserGroupDialog } from './UserGroupDialog';

export function UserGroupList() {
  const dispatch = useAppDispatch();
  const tableRef = useRef<TableRef>(null);
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState<UserGroup | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const canDelete = usePermissions({ items: [permissions.UserGroup.delete] });
  const canUpdate = usePermissions({ items: [permissions.UserGroup.update] });
  const canUpdateDefault = usePermissions({ items: [permissions.UserGroup.updateDefault] });
  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'nameEn',
      headerName: 'Name En',
      flex: 1,
    },
    {
      field: 'nameAr',
      headerName: 'Name Ar',
      flex: 1,
    },
    {
      field: 'descriptionEn',
      headerName: 'Description En',
      flex: 1,
    },
    {
      field: 'descriptionAr',
      headerName: 'Description Ar',
      flex: 1,
    },
    {
      field: 'isDefault',
      headerName: 'Default',
      width: 120,
      renderCell: (params: GridRenderCellParams) => (params.value ? <PersonPinIcon /> : ''),
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 200,
    },
  ]);
  const [initialFilterModel, setInitialFilterModel] = useSearchParamsState('filter', '');
  const [initialPage, setInitialPage] = useSearchParamsState('page', '0');
  const [initialPageSize, setInitialPageSize] = useSearchParamsState('pageSize', '100');

  const { totalUserGroups, userGroups, fetchStatus, deleteStatus, updateDefaultStatus } =
    useSelector(userGroupSelector);
  const isLoading =
    fetchStatus === fetchStatuses.pending ||
    updateDefaultStatus === fetchStatuses.pending ||
    deleteStatus === fetchStatuses.pending;

  const handleClose = (isReload: boolean) => {
    if (isReload) {
      tableRef?.current?.reload();
    }

    setEditValue(null);
    setOpen(false);
  };

  const onDeleteUserGroup = async (id: number) => {
    const res = await dispatch(deleteUserGroup(id));

    if (isDispatchApiSuccess(res)) {
      handleClose(true);
      enqueueSnackbar('User Group deleted!', { variant: 'success' });
    } else {
      enqueueSnackbar('User Group deletion error!', { variant: 'error' });
    }
  };

  const onSetDefaultUserGroup = async (id: number) => {
    const res = await dispatch(setDefaultUserGroup(id));

    if (isDispatchApiSuccess(res)) {
      tableRef?.current?.reload();
      enqueueSnackbar('User Group default successfully changed!', { variant: 'success' });
    } else {
      enqueueSnackbar('Setting default User Group failed!', { variant: 'error' });
    }
  };

  const onEditUserGroup = (row: UserGroup): void => {
    setEditValue(row);
    setOpen(true);
  };

  const onAddNew = (): void => {
    setOpen(true);
  };

  useEffect(
    () => () => {
      dispatch(cleanUserGroups());
    },
    [],
  );

  useEffect(() => {
    const actionColumn = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      filterable: false,
      sortable: false,
      getActions: (params: GridRowParams) => {
        const actions: React.ReactElement<GridActionsCellItemProps>[] = [];

        if (canUpdate) {
          actions.push(
            <GridActionsCellItem
              key={`userGroup-list-update-${params.row.id}`}
              icon={<EditOutlinedIcon />}
              label="Actions"
              onClick={() => onEditUserGroup(params.row)}
            />,
          );
        }

        if (canDelete) {
          actions.push(
            <GridActionsCellItem
              key={`userGroup-list-delete-${params.row.id}`}
              icon={<DeleteOutlineOutlinedIcon />}
              disabled={deleteStatus === fetchStatuses.pending}
              label="Link"
              onClick={() => onDeleteUserGroup(params.row.id)}
            />,
          );
        }

        if (!params.row.isDefault && canUpdateDefault) {
          actions.push(
            <GridActionsCellItem
              key={`userGroup-list-set-default-${params.row.id}`}
              icon={<PersonPinIcon />}
              disabled={updateDefaultStatus === fetchStatuses.pending}
              label="Actions"
              onClick={() => onSetDefaultUserGroup(params.row.id)}
            />,
          );
        }

        return actions;
      },
    };

    if (canDelete || canUpdate || canUpdateDefault) {
      setColumns([actionColumn, ...columns.filter((i) => i.type !== 'actions')]);
    }
  }, [canDelete, canUpdate, canUpdateDefault, updateDefaultStatus, deleteStatus]);

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">
          User Groups &nbsp;
          <Fab color="primary" size="small" aria-label="Create User Group" onClick={onAddNew}>
            <AddIcon />
          </Fab>
        </Typography>
      </Box>

      <UserGroupDialog open={open} onClose={handleClose} initialValue={editValue} />

      <Table
        ref={tableRef}
        fetchItems={fetchUserGroups}
        rows={userGroups}
        columns={columns}
        loading={isLoading}
        rowCount={totalUserGroups}
        initialFilterModel={parseJSON(initialFilterModel)}
        initialPage={+initialPage}
        initialPageSize={+initialPageSize}
        onFilterModelChange={(filterModel) => setInitialFilterModel(JSON.stringify(filterModel))}
        onPageChange={(page) => setInitialPage(`${page}`)}
        onPageSizeChange={(pageSize) => setInitialPageSize(`${pageSize}`)}
      />
    </Container>
  );
}
