import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { fundValuationFrequencyService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import {
  CreateFundValuationFrequencyRequest,
  UpdateFundValuationFrequencyRequest,
} from '../../../types/fundValuationFrequency';

export const fetchFundValuationFrequencies = createAsyncThunk(
  'fundValuationFrequency/fetch-all',
  (data: PaginatedRequest) => fundValuationFrequencyService.fetchFundValuationFrequencies(data),
);

export const fetchFundValuationFrequenciesAutocomplete = createAsyncThunk(
  'fundValuationFrequency/fetch-autocomplete',
  (term: string) => fundValuationFrequencyService.fetchFundValuationFrequenciesAutocomplete(term),
);

export const fetchFundValuationFrequency = createAsyncThunk('fundValuationFrequency/get', (id: number) =>
  fundValuationFrequencyService.fetchFundValuationFrequency(id),
);

export const deleteFundValuationFrequency = createAsyncThunk('fundValuationFrequency/delete', (id: number) =>
  fundValuationFrequencyService.deleteFundValuationFrequency(id),
);

export const createFundValuationFrequency = createAsyncThunk(
  'fundValuationFrequency/create',
  (fundValuationFrequency: CreateFundValuationFrequencyRequest) =>
    fundValuationFrequencyService.createFundValuationFrequency(fundValuationFrequency),
);

export const updateFundValuationFrequency = createAsyncThunk(
  'fundValuationFrequency/update',
  (fundValuationFrequency: UpdateFundValuationFrequencyRequest) =>
    fundValuationFrequencyService.updateFundValuationFrequency(fundValuationFrequency),
);

export const resetEditFundValuationFrequency = createAction('fundValuationFrequency/reset-edit');

export const cleanFundValuationFrequencies = createAction('fundValuationFrequency/clean');
