import Typography from '@mui/material/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useSearchParamsState } from '../../hooks';
import { appConfigSelector } from '../../redux/modules/appConfig/appConfig.selectors';
import { UserFeeDeductionTable } from './UserFeeDeductionTable';
import { UserFeeDeductionTypes } from '../../types/userFeeDeduction';
import { MTabPanel } from '../../components/TabPanel';

const mapTabParamToValue: { [prop: string]: string } = {
  subscription: '1',
  redemption: '2',
  default: '1',
};
const mapTabValueToTabParam: { [prop: string]: string } = {
  1: 'subscription',
  2: 'redemption',
};

export function UserFeeDeductionList() {
  const [selectedTab, setSelectedTab] = useSearchParamsState('tab', 'default');

  const { fetchStatus, updateStatus } = useSelector(appConfigSelector);

  const isLoading = fetchStatus === fetchStatuses.pending || updateStatus === fetchStatuses.pending;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(mapTabValueToTabParam[newValue]);
  };

  try {
    return (
      <Container component="main" maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">
            User Fee Deductions &nbsp;
            {/* <ProtectedContent items={[permissions.UserPortfolioRequest.create]}> */}
            {/*   <Fab color="primary" size="small" aria-label="Create User IndexFund Request" onClick={onAddNew}> */}
            {/*     <AddIcon /> */}
            {/*   </Fab> */}
            {/* </ProtectedContent> */}
          </Typography>
        </Box>
        <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
          <UserFeeDeductionTable />
        </Box>
      </Container>
    );
  } catch (e: any) {
    return (
      <Container component="main" maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">User Portfolio Requests</Typography>
        </Box>
        <Typography>Unexpected error happens: ${e.message}</Typography>
      </Container>
    );
  }
}
