import { GridValueFormatterParams, GridNativeColTypes } from '@mui/x-data-grid-premium';

export const dateFormatter = (params: GridValueFormatterParams) =>
  new Date(params.value as string).toLocaleDateString('en-US');

export const timeFormatter = (params: GridValueFormatterParams) =>
  new Date(params.value as string).toLocaleTimeString('en-US');
export const dateTimeFormatter = (params: GridValueFormatterParams) =>
  new Date(params.value as string).toLocaleString('en-US');

export const mapColumTypeToFormatter: {
  [prop in GridNativeColTypes | string]: (params: GridValueFormatterParams) => any;
} = {
  date: dateFormatter,
  time: timeFormatter,
  dateTime: dateTimeFormatter,
};
