import { ActionType } from '../../types/action';

const listUserBankTransactionsPermission = {
  resource: 'user-bank-transactions',
  action: ActionType.READ,
};
const listUserBankTransactionsLogPermission = {
  resource: 'user-bank-transactions-log',
  action: ActionType.CREATE,
};
const updateUserBankAmountPermission = {
  resource: 'user-bank-amount',
  action: ActionType.UPDATE,
};
const addFundsPermission = {
  resource: 'user-bank-add-funds',
  action: ActionType.CREATE,
};

export const UserBank = {
  listUserBankTransactions: listUserBankTransactionsPermission,
  listUserBankTransactionsLog: listUserBankTransactionsLogPermission,
  updateUserBankAmount: updateUserBankAmountPermission,
  addFunds: addFundsPermission,
};
