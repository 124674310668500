import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchUserAddressResponse, FetchUserAddressesResponse } from '../../../types/userAddress';
import {
  fetchUserAddress,
  fetchUserAddresses,
  resetEditUserAddress,
  cleanUserAddresses,
  updateUserAddress,
  createUserAddress,
  deleteUserAddress,
  setPrimaryUserAddress,
} from './userAddress.actions';
import { UserAddressState } from './userAddress.types';

const initialState: UserAddressState = {
  userAddresses: [],
  totalUserAddresses: 0,
  editUserAddress: null,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  updatePrimaryStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const userAddressesSlice = createSlice({
  name: 'userAddresses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserAddresses.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchUserAddresses.fulfilled, (state, action: PayloadAction<FetchUserAddressesResponse>) => {
      state.userAddresses = action.payload.data?.items;
      state.totalUserAddresses = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserAddresses.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserAddress.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchUserAddress.fulfilled, (state, action: PayloadAction<FetchUserAddressResponse>) => {
      state.editUserAddress = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserAddress.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateUserAddress.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateUserAddress.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateUserAddress.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createUserAddress.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(createUserAddress.fulfilled, (state, action: PayloadAction<FetchUserAddressResponse>) => {
      state.editUserAddress = action.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createUserAddress.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteUserAddress.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteUserAddress.fulfilled, (state) => {
      state.editUserAddress = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteUserAddress.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(setPrimaryUserAddress.pending, (state) => {
      state.error = null;
      state.updatePrimaryStatus = fetchStatuses.pending;
    });
    builder.addCase(setPrimaryUserAddress.fulfilled, (state) => {
      state.updatePrimaryStatus = fetchStatuses.success;
    });
    builder.addCase(setPrimaryUserAddress.rejected, (state, action) => {
      state.updatePrimaryStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditUserAddress.type, (state) => {
      state.editUserAddress = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanUserAddresses.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const userAddressReducer = userAddressesSlice.reducer;
