import { HTMLAttributeAnchorTarget } from 'react';
import * as React from 'react';

interface FakeLinkProps
  extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  children: React.ReactNode;
  width?: string;
  target?: HTMLAttributeAnchorTarget;
}

export const FakeLink = React.forwardRef<any, FakeLinkProps>((props, ref) => {
  const { children, width = '100%', target = '_blank', ...rest } = props;

  return (
    <a
      ref={ref}
      onClick={(event) => event.preventDefault()}
      target={target || '_blank'}
      rel="noreferrer"
      style={{ width, display: 'flex', alignItems: 'center' }}
      {...rest}
    >
      {children}
    </a>
  );
});
