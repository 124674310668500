import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { GridFilterInputMultipleValueProps, useGridRootProps } from '@mui/x-data-grid-premium';
import React, { useId } from 'react';

export function CustomGridFilterInputMultipleValue(props: GridFilterInputMultipleValueProps) {
  const { item, applyValue, type, apiRef, focusElementRef, color, error, helperText, size, variant, ...other } = props;
  const TextFieldProps = {
    color,
    error,
    helperText,
    size,
    variant,
  };

  const [filterValueState, setFilterValueState] = React.useState(item.value || []);
  const id = useId();

  const rootProps = useGridRootProps();

  React.useEffect(() => {
    const itemValue = item.value ?? [];
    setFilterValueState(itemValue.map(String));
  }, [item.value]);

  const handleChange = React.useCallback<NonNullable<AutocompleteProps<string, true, false, true>['onChange']>>(
    (event, value) => {
      setFilterValueState(value.map(String));
      applyValue({ ...item, value: [...value] });
    },
    [applyValue, item],
  );

  const handlePaste = React.useCallback<NonNullable<AutocompleteProps<string, true, false, true>['onPaste']>>(
    (event) => {
      event.preventDefault();

      const text = event.clipboardData.getData('Text');
      let value: string[] = item.value || [];

      if (text?.includes('\n')) {
        value = value.concat(text.split('\n').filter(Boolean));
      }

      setFilterValueState(value.map(String));
      applyValue({ ...item, value: [...value] });
    },
    [applyValue, item],
  );

  return (
    <Autocomplete<string, true, false, true>
      multiple
      freeSolo
      options={[]}
      filterOptions={(options, params) => {
        const { inputValue } = params;
        return inputValue == null || inputValue === '' ? [] : [inputValue];
      }}
      id={id}
      value={filterValueState}
      onChange={handleChange}
      onPaste={handlePaste}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          // eslint-disable-next-line react/jsx-key
          <rootProps.slots.baseChip variant="outlined" size="small" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <rootProps.slots.baseTextField
          {...params}
          label={apiRef.current.getLocaleText('filterPanelInputLabel')}
          placeholder={apiRef.current.getLocaleText('filterPanelInputPlaceholder')}
          InputLabelProps={{
            ...params.InputLabelProps,
            shrink: true,
          }}
          inputRef={focusElementRef}
          type={type || 'text'}
          {...TextFieldProps}
          {...rootProps.slotProps?.baseTextField}
        />
      )}
      {...other}
    />
  );
}
