import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CameraFrontIcon from '@mui/icons-material/CameraFront';
import TaskIcon from '@mui/icons-material/Task';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppDispatch } from '../../../../redux/hooks';
import { getPrivateFile } from '../../../../redux/modules/media/media.actions';
import { mediaCacheSelector } from '../../../../redux/modules/media/media.selectors';
import { fetchUserAttachments } from '../../../../redux/modules/user/user.actions';
import {
  userAttachmentsLoadingSelector,
  userAttachmentsSelector,
  userSelector,
} from '../../../../redux/modules/user/user.selectors';
import { FetchMediaFileResponse } from '../../../../types/media';
import { DocumentTypes } from '../../../../types/user';
import { isDispatchApiSuccess } from '../../../../utils/api';
import { AddAttachmentDialog } from './AddAttachmentDialog';

interface AttachmentsTabProps {
  id: number;
}

const mediaCacheTTL = 5 * 60 * 1000; // 5 mins

export function AttachmentsTab({ id }: AttachmentsTabProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const { error } = useSelector(userSelector);
  const attachments = useSelector(userAttachmentsSelector);
  const mediaCache = useSelector(mediaCacheSelector);
  const isLoading = useSelector(userAttachmentsLoadingSelector);

  const openInNewTab = async (url: string) => {
    if (mediaCache[url]?.url && mediaCache[url].timestamp + mediaCacheTTL > Date.now()) {
      return window.open(mediaCache[url].url, '_blank', 'noopener,noreferrer');
    }

    const result = await dispatch(getPrivateFile(url));

    if (isDispatchApiSuccess<FetchMediaFileResponse>(result)) {
      window.open(result.payload.data.url, '_blank', 'noopener,noreferrer');
    } else {
      enqueueSnackbar(`Error: ${result?.error?.message}`, { variant: 'error' });
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchUserAttachments(id));
    }
  }, []);

  const onAddNew = (): void => {
    setOpen(true);
  };

  const handleClose = (isReload: boolean) => {
    if (isReload) {
      dispatch(fetchUserAttachments(id));
    }
    setOpen(false);
  };

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}

      {/* Max Number of Files to be uploaded is 5 */}
      {attachments.length < 5 && <AddAttachmentDialog open={open} userId={id} onClose={handleClose} />}

      <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px' }}>
        <Typography variant="h6">
          Attachments &nbsp;
          <Fab color="primary" size="small" aria-label="Create Action" onClick={onAddNew}>
            <AddIcon />
          </Fab>
        </Typography>

        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          {isLoading && <CircularProgress />}
          {attachments.map((at, index) => (
            <React.Fragment key={`attachment-item-${at.id}`}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar
                  sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    alignSelf: 'center',
                  }}
                >
                  {at.documentType.type === DocumentTypes.OTHER && <InsertDriveFileIcon />}
                  {at.documentType.type === DocumentTypes.SELFIE && <CameraFrontIcon />}
                  {at.documentType.type === DocumentTypes.VERIFICATION_DOCUMENT && <TaskIcon />}
                </ListItemAvatar>
                <ListItemText
                  primary={at.documentType.type}
                  secondary={
                    <Button
                      size="small"
                      variant="outlined"
                      color="primary"
                      endIcon={<OpenInNewIcon />}
                      onClick={() => openInNewTab(at.documentLink)}
                    >
                      {at.documentLink}
                    </Button>
                  }
                />
              </ListItem>
              {index < attachments.length - 1 && <Divider variant="inset" component="li" />}
            </React.Fragment>
          ))}
        </List>
      </Grid>
    </>
  );
}
