import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { portfolioService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { SavePortfolioRequest, UpdatePortfolioRequest, UpdatePortfolioStatusRequest } from '../../../types/portfolio';

export const fetchPortfolios = createAsyncThunk('portfolio/fetch-all', (data: PaginatedRequest) =>
  portfolioService.fetchPortfolios(data),
);

export const fetchPortfolio = createAsyncThunk('portfolio/get', (id: number) => portfolioService.fetchPortfolio(id));

export const deletePortfolio = createAsyncThunk('portfolio/delete', (id: number) =>
  portfolioService.deletePortfolio(id),
);

export const savePortfolio = createAsyncThunk('portfolio/save', (portfolio: SavePortfolioRequest) =>
  portfolioService.savePortfolio(portfolio),
);

export const updatePortfolio = createAsyncThunk('portfolio/update', (portfolio: UpdatePortfolioRequest) =>
  portfolioService.updatePortfolio(portfolio),
);

export const updatePortfolioStatus = createAsyncThunk('portfolio/update-status', (data: UpdatePortfolioStatusRequest) =>
  portfolioService.updatePortfolioStatus(data),
);

export const fetchPortfolioStatusOptions = createAsyncThunk('portfolio/fetch-portfolio-status-options', () =>
  portfolioService.fetchPortfolioStatusOptions(),
);

export const fetchPortfolioFileTypeOptions = createAsyncThunk('portfolio/fetch-portfolio-file-type-options', () =>
  portfolioService.fetchPortfolioFileTypeOptions(),
);

export const fetchPortfolioCurrencyOptions = createAsyncThunk('portfolio/fetch-portfolio-currency-options', () =>
  portfolioService.fetchPortfolioCurrencyOptions(),
);

export const cleanPortfolios = createAction('portfolio/clean');

export const resetEditPortfolio = createAction('portfolio/reset-edit');
