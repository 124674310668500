import { PaginatedRequest } from '../types/api';
import {
  CreateRolePermissionRequest,
  CreateRolePermissionResponse,
  FetchRolePermissionsResponse,
  FetchRolePermissionResponse,
  UpdateRolePermissionRequest,
  UpdateRolePermissionResponse,
  FetchAccessPermissionsResponse,
  UpdateRolePermissionsRequest,
  UpdateRolePermissionsResponse,
} from '../types/rolePermission';
import { ApiService } from './common/ApiService';

class RolePermissionService extends ApiService {
  pathname = 'admin';

  fetchRolePermissions = async (data: PaginatedRequest) =>
    this.get<FetchRolePermissionsResponse>(
      `${this.pathname}/role-permission?limit=${data.limit}&offset=${data.offset}`,
    );

  fetchAccessPermissions = async () => this.get<FetchAccessPermissionsResponse>(`${this.pathname}/access/permissions`);

  fetchMyPermissions = async () => this.get<FetchAccessPermissionsResponse>(`${this.pathname}/role-permission/my`);

  deleteRolePermission = async (id: number) =>
    this.delete<FetchRolePermissionResponse>(`${this.pathname}/role-permission/${id}`);

  createRolePermission = async (rolePermission: CreateRolePermissionRequest) =>
    this.post<CreateRolePermissionResponse, CreateRolePermissionRequest>(
      `${this.pathname}/role-permission`,
      rolePermission,
    );

  updateRolePermission = async ({ id, ...rolePermission }: UpdateRolePermissionRequest) =>
    this.put<UpdateRolePermissionResponse, Omit<UpdateRolePermissionRequest, 'id'>>(
      `${this.pathname}/role-permission/${id}`,
      rolePermission,
    );

  updateRolePermissions = async (rolePermissions: UpdateRolePermissionsRequest) =>
    this.put<UpdateRolePermissionsResponse, UpdateRolePermissionsRequest>(
      `${this.pathname}/role-permission`,
      rolePermissions,
    );
}

export const rolePermissionService = new RolePermissionService();
