import { useRef, useState } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { RtkQueryTable, RtkQueryTableModels } from '../../components/Table/RtkQueryTable';
import { converTableModelsToApiArgs } from '../../helpers/api';
import { TableRef } from '../../components/Table/Table';
import { useFetchJadwaTransfersQuery } from '../../redux/features/jadwa/jadwa.apiSlice';
import { round2Format } from '../../utils/number';
import { AlpacaTransferDirection, AlpacaTransferStatus } from '../../types/alpaca';

export function JadwaTransfersTab() {
  const tableRef = useRef<TableRef>(null);
  const [tableModels, setTableModels] = useState<RtkQueryTableModels>({
    paginationModel: { page: 0, pageSize: 100 },
  });
  const { data, isFetching } = useFetchJadwaTransfersQuery(converTableModelsToApiArgs(tableModels));

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 70,
    },
    {
      field: 'anbTransferId',
      headerName: 'ANB Transfer ID',
      width: 300,
    },
    {
      field: 'fxRate',
      headerName: 'Fx Rate',
      type: 'number',
      width: 100,
      renderCell: (params) => (params.value ? round2Format(params.value) : ''),
    },
    {
      field: 'realFxRate',
      headerName: 'Real Fx Rate',
      type: 'number',
      width: 100,
      renderCell: (params) => (params.value ? round2Format(params.value) : ''),
    },
    {
      field: 'actualAmount',
      headerName: 'Actual Amount',
      type: 'number',
      width: 120,
      renderCell: (params) => (params.value ? round2Format(params.value) : ''),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      width: 120,
      renderCell: (params) => (params.value ? round2Format(params.value) : ''),
    },
    {
      field: 'originalAmount',
      headerName: 'Original Amount',
      type: 'number',
      width: 120,
      renderCell: (params) => (params.value ? round2Format(params.value) : ''),
    },
    {
      field: 'currency',
      headerName: 'Currency',
      width: 100,
    },
    {
      field: 'direction',
      headerName: 'Direction',
      type: 'singleSelect',
      valueOptions: Object.values(AlpacaTransferDirection),
      minWidth: 100,
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(AlpacaTransferStatus),
      minWidth: 100,
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ];

  const onModelsChange = (models: RtkQueryTableModels) => {
    setTableModels(models);
  };

  return (
    <RtkQueryTable
      ref={tableRef}
      models={tableModels}
      onModelsChange={onModelsChange}
      rows={data?.items || []}
      columns={columns}
      loading={isFetching}
      rowCount={data?.totalItems || 0}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
    />
  );
}
