import { authApiSlice as apiSlice } from '../../../api/common/apiSlice';
import {
  FetchUserProfileResponse,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  LoginRequest,
  LoginResponse,
  RestorePasswordRequest,
  RestorePasswordResponse,
} from '../../../types/auth';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (data) => ({
        url: '/auth/auth/login',
        method: 'POST',
        body: data,
      }),
      extraOptions: {},
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/auth/auth/logout',
        method: 'POST',
        body: {},
      }),
    }),
    forgotPassword: builder.mutation<ForgotPasswordResponse, ForgotPasswordRequest>({
      query: (data) => ({
        url: '/auth/auth/forgot-password',
        method: 'POST',
        body: data,
      }),
    }),
    restorePassword: builder.mutation<RestorePasswordResponse, RestorePasswordRequest>({
      query: (data) => ({
        url: '/auth/auth/restore-password',
        method: 'POST',
        body: data,
      }),
    }),
    fetchUserProfile: builder.query<FetchUserProfileResponse, void>({
      query: () => ({
        url: '/auth/auth/check-auth',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useRestorePasswordMutation,
  useFetchUserProfileQuery,
} = authApiSlice;
