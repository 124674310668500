import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchUserPortfolioRequestResponse,
  FetchUserPortfolioRequestsResponse,
  FetchUserPortfolioRequestStatusOptionsResponse,
} from '../../../types/userPortfolioRequest';
import {
  fetchUserPortfolioRequest,
  fetchUserPortfolioRequests,
  resetEditUserPortfolioRequest,
  saveUserPortfolioRequest,
  deleteUserPortfolioRequest,
  cleanUserPortfolioRequests,
  updateUserPortfolioRequestStatus,
  fetchUserPortfolioRequestStatusOptions,
  updateUserPortfolioRequest,
} from './userPortfolioRequest.actions';
import { UserPortfolioRequestState } from './userPortfolioRequest.types';

const initialState: UserPortfolioRequestState = {
  userPortfolioRequests: {},
  totalUserPortfolioRequests: {},
  editUserPortfolioRequest: null,
  userPortfolioRequestStatusOptions: [],
  fetchUserPortfolioRequestStatusOptionsStatus: fetchStatuses.idle,
  fetchStatuses: {},
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  updateStatusStatus: fetchStatuses.idle,
  saveStatus: fetchStatuses.idle,
  error: null,
};

const userPortfolioRequestsSlice = createSlice({
  name: 'userPortfolioRequests',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserPortfolioRequests.pending, (state, action) => {
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(
      fetchUserPortfolioRequests.fulfilled,
      (state, action: PayloadAction<FetchUserPortfolioRequestsResponse, any, any>) => {
        const requestId = (action as any).meta.arg.requestId || (action as any).meta.requestId;

        state.fetchStatuses[requestId] = fetchStatuses.success;

        if (action.payload) {
          state.userPortfolioRequests[requestId] = action.payload.data?.items;
          state.totalUserPortfolioRequests[requestId] = action.payload.data?.totalItems;
        }
      },
    );
    builder.addCase(fetchUserPortfolioRequests.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.rejected;

      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchUserPortfolioRequest.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserPortfolioRequest.fulfilled,
      (state, action: PayloadAction<FetchUserPortfolioRequestResponse>) => {
        state.editUserPortfolioRequest = action.payload.data;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserPortfolioRequest.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserPortfolioRequestStatusOptions.pending, (state) => {
      state.error = null;
      state.fetchUserPortfolioRequestStatusOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserPortfolioRequestStatusOptions.fulfilled,
      (state, action: PayloadAction<FetchUserPortfolioRequestStatusOptionsResponse>) => {
        state.userPortfolioRequestStatusOptions = action.payload.data;
        state.fetchUserPortfolioRequestStatusOptionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserPortfolioRequestStatusOptions.rejected, (state, action) => {
      state.fetchUserPortfolioRequestStatusOptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateUserPortfolioRequestStatus.pending, (state) => {
      state.error = null;
      state.updateStatusStatus = fetchStatuses.pending;
    });
    builder.addCase(updateUserPortfolioRequestStatus.fulfilled, (state) => {
      state.updateStatusStatus = fetchStatuses.success;
    });
    builder.addCase(updateUserPortfolioRequestStatus.rejected, (state, action) => {
      state.updateStatusStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(saveUserPortfolioRequest.pending, (state) => {
      state.error = null;
      state.saveStatus = fetchStatuses.pending;
    });
    builder.addCase(
      saveUserPortfolioRequest.fulfilled,
      (state, action: PayloadAction<FetchUserPortfolioRequestResponse>) => {
        state.editUserPortfolioRequest = state.editUserPortfolioRequest || ({} as any);
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        Object.assign(state.editUserPortfolioRequest!, {
          ...action.payload.data,
          status: { id: action.payload.data.status },
          type: { id: action.payload.data.type },
        });
        state.saveStatus = fetchStatuses.success;
      },
    );
    builder.addCase(saveUserPortfolioRequest.rejected, (state, action) => {
      state.saveStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateUserPortfolioRequest.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
    });
    builder.addCase(updateUserPortfolioRequest.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateUserPortfolioRequest.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteUserPortfolioRequest.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
    });
    builder.addCase(deleteUserPortfolioRequest.fulfilled, (state) => {
      state.editUserPortfolioRequest = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteUserPortfolioRequest.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditUserPortfolioRequest.type, (state) => {
      state.editUserPortfolioRequest = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.saveStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanUserPortfolioRequests.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const userPortfolioRequestReducer = userPortfolioRequestsSlice.reducer;
