import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import CancelIcon from '@mui/icons-material/Cancel';
import { WithdrawStatus } from '../../types/payment';

export const withdrawRequestStatusToInfo: {
  [prop in WithdrawStatus]: {
    color: 'error' | 'info' | 'success' | 'warning';
    icon: React.ElementType;
  };
} = {
  [WithdrawStatus.PROCESSING]: {
    color: 'info',
    icon: InfoIcon,
  },
  [WithdrawStatus.SUCCESS]: {
    color: 'success',
    icon: CheckCircleIcon,
  },
  [WithdrawStatus.FAILED]: {
    color: 'error',
    icon: CancelIcon,
  },
  [WithdrawStatus.PENDING]: {
    color: 'info',
    icon: InfoIcon,
  },
  [WithdrawStatus.APPROVED]: {
    color: 'success',
    icon: CheckCircleIcon,
  },
  [WithdrawStatus.REJECTED]: {
    color: 'error',
    icon: CancelScheduleSendIcon,
  },
};
