import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { analyticsConfigService } from '../../../api';
import { CreateAnalyticsConfigRequest, UpdateAnalyticsConfigRequest } from '../../../types/analyticsConfig';

export const fetchAnalyticsConfigs = createAsyncThunk('analyticsConfig/fetch-all', () =>
  analyticsConfigService.fetchAnalyticsConfigs(),
);

export const fetchAnalyticsConfig = createAsyncThunk('analyticsConfig/get', (key: string) =>
  analyticsConfigService.fetchAnalyticsConfig(key),
);

export const deleteAnalyticsConfig = createAsyncThunk('analyticsConfig/delete', (key: string) =>
  analyticsConfigService.deleteAnalyticsConfig(key),
);

export const createAnalyticsConfigItem = createAsyncThunk(
  'analyticsConfig/create',
  (analyticsConfig: CreateAnalyticsConfigRequest) => analyticsConfigService.createAnalyticsConfig(analyticsConfig),
);

export const updateAnalyticsConfig = createAsyncThunk(
  'analyticsConfig/update',
  (analyticsConfig: UpdateAnalyticsConfigRequest) => analyticsConfigService.updateAnalyticsConfig(analyticsConfig),
);

export const updateAnalyticsConfigs = createAsyncThunk(
  'analyticsConfig/update-all',
  (analyticsConfigs: UpdateAnalyticsConfigRequest[]) => analyticsConfigService.updateAnalyticsConfigs(analyticsConfigs),
);

export const resetEditAnalyticsConfig = createAction('analyticsConfig/reset-edit');

export const cleanAnalyticsConfigs = createAction('analyticsConfig/clean');
