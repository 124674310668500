import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Table } from '../../components/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { fetchUserSignAgreements } from '../../redux/modules/user/user.actions';
import { userSelector } from '../../redux/modules/user/user.selectors';

interface UserSignAgreementsTabProps {
  id: number;
}

export function UserSignAgreementsTab({ id }: UserSignAgreementsTabProps) {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id' },
    {
      field: 'agreement.name',
      headerName: 'Agreement',
      sortable: false,
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => params.row?.agreement?.name,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: false,
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      sortable: false,
      type: 'date',
      width: 100,
    },
  ]);
  const {
    totalUserSignAgreements,
    userSignAgreements,
    fetchUserSignAgreements: fetchStatus,
  } = useSelector(userSelector);

  return (
    <Table
      fetchItems={fetchUserSignAgreements}
      fetchItemsCustomParams={{ userId: id }}
      rows={userSignAgreements}
      columns={columns.current}
      loading={fetchStatus === fetchStatuses.pending}
      rowCount={totalUserSignAgreements}
      disableColumnFilter
      disableColumnMenu
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      requiredFilters={[{ field: 'user.id', value: id, operator: 'is', id: 1 }]}
    />
  );
}
