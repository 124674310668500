import React from 'react';
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { reportSelector } from '../../redux/modules/report/report.selectors';
// import { PerformanceWidget } from './PerformanceWidget';
import { OverallPerformanceWidget } from './OverallPerformanceWidget';
import { PortfoliosPerformanceWidget } from './PortfoliosPerformanceWidget';

export function PortfolioTab() {
  const { error } = useSelector(reportSelector);

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      {/* <Grid container spacing={2} alignItems="center" justifyContent="center">
        <PerformanceWidget />
      </Grid> */}
      <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: 0 }}>
        <Grid item container sx={{ mb: 4 }}>
          <Typography variant="h6">Overall Performance</Typography>
          <OverallPerformanceWidget />
        </Grid>

        <Divider variant="inset" />

        <PortfoliosPerformanceWidget />
      </Grid>
    </>
  );
}
