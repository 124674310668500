import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { formFields } from '../../forms/fields/formFields';
import { resourceFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import { createResource, fetchAccessResources, updateResource } from '../../redux/modules/resource/resource.actions';
import { resourceSelector } from '../../redux/modules/resource/resource.selectors';
import { Resource } from '../../types/resource';

interface ResourceDialogProps {
  open: boolean;
  initialValue: Resource | null;
  onClose: (isReload: boolean) => void;
}

export function ResourceDialog({ open, initialValue, onClose }: ResourceDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, values, handleChange, touched, errors, submitForm, setValues, resetForm } = useFormik({
    initialValues: { [formFields.name]: '' },
    validationSchema: resourceFormSchema,
    onSubmit: (formValues) => {
      if (initialValue?.id) {
        dispatch(updateResource({ id: initialValue.id, ...formValues }));
      } else {
        dispatch(createResource(formValues));
      }
    },
  });
  const { accessResources, fetchStatus, createStatus, updateStatus, error } = useSelector(resourceSelector);
  const isLoading = fetchStatus === fetchStatuses.pending;

  useEffect(() => {
    dispatch(fetchAccessResources());
  }, []);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    setValues({ name: initialValue?.name || '' });
  }, [initialValue]);

  useEffect(() => {
    if (createStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Resource created!', { variant: 'success' });
    }
    if (createStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Resource creation error!', { variant: 'error' });
    }

    if (updateStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Resource updated!', { variant: 'success' });
    }
  }, [createStatus, updateStatus]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{initialValue?.id ? 'Edit resource' : 'Create a new Resource'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error && <Alert severity="error">{error.message}</Alert>}
          Enter the resource name
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={80} />
          ) : (
            <FormControl fullWidth margin="normal">
              <InputLabel id="resource-label">Resource</InputLabel>
              <Select
                labelId="resource-label"
                name={formFields.name}
                value={values[formFields.name]}
                label="Resource"
                onChange={handleChange}
                error={touched.name && !!errors.name}
              >
                {accessResources.map((value) => (
                  <MenuItem key={value} value={value}>
                    {value.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={createStatus === fetchStatuses.pending}
          loadingPosition="end"
          onClick={submitForm}
        >
          {initialValue?.id ? 'Save' : 'Create'}
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
