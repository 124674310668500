export const EducationLevel = {
  en: [
    {
      label: 'Elementary',
      value: 'ELEMENTARY',
    },
    {
      label: 'Middle School',
      value: 'MIDDLE_SCHOOL',
    },
    {
      label: 'High School',
      value: 'HIGH_SCHOOL',
    },
    {
      label: 'Bachelor',
      value: 'BACHELOR',
    },
    {
      label: 'Higher Education (Masters, Ph.D)',
      value: 'HIGHER_EDUCATION',
    },
  ],
  ar: [
    {
      label: 'ابتدائي',
      value: 'ELEMENTARY',
    },
    {
      label: 'المتوسطة',
      value: 'MIDDLE_SCHOOL',
    },
    {
      label: 'الثانوية',
      value: 'HIGH_SCHOOL',
    },
    {
      label: 'جامعي',
      value: 'BACHELOR',
    },
    {
      label: 'دراسات عليا',
      value: 'HIGHER_EDUCATION',
    },
  ],
};
