import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchProductTypeFileTypeOptionsResponse,
  FetchProductTypeResponse,
  FetchProductTypesResponse,
} from '../../../types/productType';
import {
  fetchProductType,
  fetchProductTypes,
  resetEditProductType,
  cleanProductTypes,
  updateProductType,
  createProductType,
  deleteProductType,
  fetchProductTypeFileTypeOptions,
} from './product-type.actions';
import { ProductTypeState } from './product-type.types';

const initialState: ProductTypeState = {
  productTypes: [],
  totalProductTypes: 0,
  editProductType: null,
  productTypeFileTypeOptions: [],
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  fetchFileTypeOptionsStatus: fetchStatuses.idle,
  error: null,
};

const productTypesSlice = createSlice({
  name: 'productTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProductTypes.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchProductTypes.fulfilled, (state, action: PayloadAction<FetchProductTypesResponse>) => {
      state.productTypes = action.payload.data?.items;
      state.totalProductTypes = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchProductTypes.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchProductType.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchProductType.fulfilled, (state, action: PayloadAction<FetchProductTypeResponse>) => {
      state.editProductType = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchProductType.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchProductTypeFileTypeOptions.pending, (state) => {
      state.fetchFileTypeOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchProductTypeFileTypeOptions.fulfilled,
      (state, action: PayloadAction<FetchProductTypeFileTypeOptionsResponse>) => {
        state.productTypeFileTypeOptions = action.payload.data;
        state.fetchFileTypeOptionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchProductTypeFileTypeOptions.rejected, (state, action) => {
      state.fetchFileTypeOptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateProductType.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateProductType.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateProductType.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createProductType.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(createProductType.fulfilled, (state, action: PayloadAction<FetchProductTypeResponse>) => {
      state.editProductType = action.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createProductType.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteProductType.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteProductType.fulfilled, (state) => {
      state.editProductType = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteProductType.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditProductType.type, (state) => {
      state.editProductType = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanProductTypes.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const productTypeReducer = productTypesSlice.reducer;
