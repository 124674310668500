import InputLabel from '@mui/material/InputLabel';
import React, { useEffect, useState } from 'react';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';

interface DecimalNumberFieldProps extends Omit<OutlinedInputProps, 'margin'> {
  maximumFractionDigits?: number;
  fullWidth?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  error?: boolean;
  disabled?: boolean;
  helperText?: string | boolean;
  skipChecks?: boolean;
}

export const DecimalNumberField = React.forwardRef(
  (props: DecimalNumberFieldProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      error,
      fullWidth,
      margin,
      helperText,
      maximumFractionDigits = 2,
      disabled = false,
      skipChecks = false,
      ...inputProps
    } = props;

    const [value, setValue] = useState<string | bigint>((props.value as string) || '');

    const parseValue = (v: string | number) => `${v}`.replace(/([\.\-\,])(?=.*\1)/g, '').replace(/[^.\-\d]/g, '');

    const formatValue = (v: string) => {
      if (skipChecks) {
        return v;
      }
      const numberValue = Number(v).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits });

      if (
        Number.isNaN(numberValue) ||
        Number.isNaN(v) ||
        numberValue === 'NaN' ||
        `${v}`.endsWith('.') ||
        `${v}`.endsWith('.0')
      ) {
        return v;
      }

      return numberValue;
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onChange) {
        props.onChange(event);
      }
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const initialValue = event.target.value;

      const min = props?.inputProps?.min ?? Number.MIN_SAFE_INTEGER;
      const max = props?.inputProps?.max ?? Number.MAX_SAFE_INTEGER;

      const newValue: string = parseValue(initialValue);
      const newValueNumber = +newValue;

      setValue(newValue);

      if (Number.isNaN(newValueNumber) || newValue.endsWith('.') || newValue.endsWith('.0')) {
        event.target.value = newValue;

        handleChange(event);
        return;
      }

      event.target.value = `${newValueNumber}`;

      if (min !== undefined && newValueNumber < min) {
        event.target.value = min;
      }
      if (max !== undefined && newValueNumber > max) {
        event.target.value = max;
      }

      setValue(event.target.value);
      handleChange(event);
    };

    useEffect(() => {
      setValue((props.value as string) || '');
    }, [props.value]);

    return (
      <FormControl variant="outlined" error={error} margin={margin} fullWidth={fullWidth}>
        <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
        <OutlinedInput
          inputRef={ref}
          disabled={disabled}
          {...inputProps}
          type="text"
          value={formatValue(value as string)}
          onChange={onChange}
          fullWidth={fullWidth}
        />
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  },
);
