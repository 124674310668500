import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import Chip from '@mui/material/Chip';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { consolidateRequestStatusToInfo } from '../../constants/tables/consolidateRequest';
import { fetchConsolidateFeeRequestsByBatchId } from '../../redux/modules/feeBatch/feeBatch.actions';
import { feeBatchSelector, viewDetailsByBatchIdSelector } from '../../redux/modules/feeBatch/feeBatch.selectors';
import { ConsolidateFeeRequestStatus } from '../../types/feeBatch';
import { round2Format, round4Format } from '../../utils/number';

interface ConsolidateFeeRequestsTabProps {
  batchId: string;
}

export function ConsolidateFeeRequestsTab({ batchId }: ConsolidateFeeRequestsTabProps) {
  const tableRef = useRef<TableRef>(null);
  const [columns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Id',
      width: 100,
    },
    {
      field: 'calculatedTotalFee',
      headerName: 'Calculated Total Fee',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'actualTotalFee',
      headerName: 'Actual Total Fee',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'feeCalculation',
      headerName: 'Fee Calculation',
      type: 'string',
      width: 200,
      valueGetter: (params) => params.row?.feeCalculation?.id,
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(ConsolidateFeeRequestStatus),
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = consolidateRequestStatusToInfo[params.row?.status?.name as ConsolidateFeeRequestStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.row?.status?.name;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.row?.status?.name} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 100,
    },
  ]);
  const { totalConsolidateFeeRequests, consolidateFeeRequests, fetchConsolidateFeeRequestsStatus } =
    useSelector(viewDetailsByBatchIdSelector);
  const { executeStatus, importExecutionFileStatus } = useSelector(feeBatchSelector);
  const loading =
    fetchConsolidateFeeRequestsStatus === fetchStatuses.pending ||
    importExecutionFileStatus === fetchStatuses.pending ||
    executeStatus === fetchStatuses.pending;

  useEffect(() => {
    if (importExecutionFileStatus === fetchStatuses.success || executeStatus === fetchStatuses.success) {
      tableRef.current?.reload();
    }
  }, [importExecutionFileStatus, executeStatus]);

  return (
    <Table
      ref={tableRef}
      fetchItems={fetchConsolidateFeeRequestsByBatchId}
      rows={consolidateFeeRequests}
      columns={columns}
      loading={loading}
      rowCount={totalConsolidateFeeRequests}
      fetchItemsCustomParams={{ batchId }}
      toolbarExportProps={{
        csvOptions: { fileName: `consolidate-fee-requests-${batchId}` },
        excelOptions: { fileName: `consolidate-fee-requests-${batchId}` },
      }}
    />
  );
}
