import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { userFeeSelector } from '../../../../redux/modules/userFee/userFee.selectors';
import { CopyToClipboardButton } from '../../../../components/CopyToClipboardButton';
import { round2Format } from '../../../../utils/number';

export function FeeDetailsTab() {
  const { userFees, fetchStatus } = useSelector(userFeeSelector);

  const isLoading = fetchStatus === fetchStatuses.pending;
  const totalBilable = userFees.reduce((sum, item) => sum + item.totalFee, 0);
  const totalManagmentFee = userFees.reduce((sum, item) => sum + item.managementFee, 0);
  const totalVat = userFees.reduce((sum, item) => sum + item.vat, 0);

  const renderTabList = () => (
    <Grid item container display="flex" flexDirection="row" sx={{ mt: '24px', height: '5px' }} spacing={2}>
      <Grid item container xs={8}>
        <Grid item xs={6}>
          <Typography variant="h6">Total Billable</Typography>
        </Grid>
        <Grid item xs={6}>
          <CopyToClipboardButton value={totalBilable.toString()} text={round2Format(totalBilable)} />
        </Grid>
      </Grid>
      <Grid item container xs={8}>
        <Grid item xs={6}>
          <Typography variant="h6">Total Managment Fee</Typography>
        </Grid>
        <Grid item xs={6}>
          <CopyToClipboardButton value={totalManagmentFee.toString()} text={round2Format(totalManagmentFee)} />
        </Grid>
      </Grid>
      <Grid item container xs={8}>
        <Grid item xs={6}>
          <Typography variant="h6">Total VAT</Typography>
        </Grid>
        <Grid item xs={6}>
          <CopyToClipboardButton value={totalVat.toString()} text={round2Format(totalVat)} />
        </Grid>
      </Grid>
    </Grid>
  );

  if (isLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  return userFees.length ? renderTabList() : <InsertPageBreakIcon />;
}
