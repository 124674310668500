import React from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useSearchParamsState } from '../../hooks';
import { appConfigSelector } from '../../redux/modules/appConfig/appConfig.selectors';
import { CashTab } from './CashTab';
import { PortfolioOrderExecutionsTab } from './PortfolioOrderExecutionsTab';
import { AlpacaOrdersFxRevenueTab } from './FxRevenue/AlpacaOrdersFxRevenueTab';
import { JadwaOrdersFxRevenueTab } from './FxRevenue/JadwaOrdersFxRevenueTab';
import { JadwaTransfersFxRevenueTab } from './FxRevenue/JadwaTransfersFxRevenueTab';
import { AlpacaTransfersFxRevenueTab } from './FxRevenue/AlpacaTransfersFxRevenueTab';
import { UnitsTab } from './UnitsTab';

const mapTabParamToValue: { [prop: string]: string } = {
  portfolioOrderExecutions: '1',
  cash: '2',
  units: '3',
  alpacaOrdersFxRevenue: '4',
  alpacaTransfersFxRevenue: '5',
  jadwaOrdersFxRevenue: '6',
  jadwaTransfersFxRevenue: '7',
  default: '1',
};
const mapTabValueToTabParam: { [prop: string]: string } = {
  1: 'portfolioOrderExecutions',
  2: 'cash',
  3: 'units',
  4: 'alpacaOrdersFxRevenue',
  5: 'alpacaTransfersFxRevenue',
  6: 'jadwaOrdersFxRevenue',
  7: 'jadwaTransfersFxRevenue',
};

export function Report() {
  const [selectedTab, setSelectedTab] = useSearchParamsState('tab', 'default');

  const { fetchStatus, updateStatus } = useSelector(appConfigSelector);

  const isLoading = fetchStatus === fetchStatuses.pending || updateStatus === fetchStatuses.pending;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(mapTabValueToTabParam[newValue]);
  };

  return (
    <Container component="main" maxWidth="xl">
      <TabContext value={mapTabParamToValue[selectedTab]}>
        <Box>
          <TabList
            value={mapTabParamToValue[selectedTab]}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="Report tabs"
          >
            <Tab
              icon={<ListAltIcon />}
              iconPosition="start"
              label="Portfolio Order Executions"
              value="1"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<ListAltIcon />}
              iconPosition="start"
              label="Cash"
              value="2"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<ListAltIcon />}
              iconPosition="start"
              label="Units"
              value="3"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<ListAltIcon />}
              iconPosition="start"
              label="Alpaca Orders Fx Revenue"
              value="4"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<ListAltIcon />}
              iconPosition="start"
              label="Alpaca Transfers Fx Revenue"
              value="5"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<ListAltIcon />}
              iconPosition="start"
              label="Jadwa Orders Fx Revenue"
              value="6"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<ListAltIcon />}
              iconPosition="start"
              label="Jadwa Transfers Fx Revenue"
              value="7"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <PortfolioOrderExecutionsTab />
        </TabPanel>
        <TabPanel value="2">
          <CashTab />
        </TabPanel>
        <TabPanel value="3">
          <UnitsTab />
        </TabPanel>
        <TabPanel value="4">
          <AlpacaOrdersFxRevenueTab />
        </TabPanel>
        <TabPanel value="5">
          <AlpacaTransfersFxRevenueTab />
        </TabPanel>
        <TabPanel value="6">
          <JadwaOrdersFxRevenueTab />
        </TabPanel>
        <TabPanel value="7">
          <JadwaTransfersFxRevenueTab />
        </TabPanel>
      </TabContext>
    </Container>
  );
}
