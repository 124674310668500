import _unionBy from 'lodash/unionBy';
import _difference from 'lodash/difference';
import { PortfolioOrderExecutionReport, PortfolioOrderExecutionReportIndexFund } from '../types/report';
// import { round2, round4 } from './number';

type ReportPropertyFunction = (item?: PortfolioOrderExecutionReportIndexFund) => number;

const addIndexFundReportProperty = (
  uniqIndexFunds: PortfolioOrderExecutionReportIndexFund[],
  indexFunds: PortfolioOrderExecutionReportIndexFund[],
  property: keyof PickByType<PortfolioOrderExecutionReportIndexFund, number> | ReportPropertyFunction,
  parser: ((v: number) => string | number) | null,
  csvDivider: string,
) => {
  const parseFn = parser ?? ((v: number) => v);

  // TODO: Remove after feature testing
  const uniqIndexFundIds = uniqIndexFunds.map((item) => item.id);
  const shouldOrder = uniqIndexFundIds.length === 6 && !_difference(uniqIndexFundIds, [3, 4, 9, 10, 11, 13]).length;
  let orderedUniqIndexFunds = uniqIndexFunds;

  if (process.env.REACT_APP_ENVIRONMENT === 'feature' && shouldOrder) {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    orderedUniqIndexFunds = [
      uniqIndexFunds.find((item) => item.id === 10)!,
      uniqIndexFunds.find((item) => item.id === 11)!,
      uniqIndexFunds.find((item) => item.id === 3)!,
      uniqIndexFunds.find((item) => item.id === 13)!,
      uniqIndexFunds.find((item) => item.id === 9)!,
      uniqIndexFunds.find((item) => item.id === 4)!,
    ];
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }

  const items = orderedUniqIndexFunds.map((uif) => {
    if (typeof property === 'function') {
      const indexFund = indexFunds.find((iif) => iif.id === uif.id);

      return parseFn(property(indexFund));
    }

    const indexFund = indexFunds.find((iif) => iif.id === uif.id) || { [property]: 0 };

    return parseFn(indexFund[property]);
  });

  return items.join(csvDivider) + csvDivider;
};

const toFields = (str: string) => (str ? [str] : []);

export const prepareOrderExecutionsReportData = (
  items: PortfolioOrderExecutionReport[],
  csvDivider: string,
  withEstimated = true,
) => {
  /**
   * TODO: Keep for local testing purposes
   * @description Creating a needed number of Users with needed number of IndexFunds
   */
  // const numOfUsers = 3;
  // const numOfFunds = 3;
  //
  // items = Array(numOfUsers)
  //   .fill(items[0])
  //   .map((i) => ({
  //     ...i,
  //     indexFunds: Array(numOfFunds)
  //       .fill(i.indexFunds[0])
  //       .map((it, ind) => ({ ...it, id: ind })),
  //   }));

  const uniqIndexFunds = items.reduce(
    (res, item) => _unionBy(res, item.indexFunds, 'id'),
    [] as PortfolioOrderExecutionReportIndexFund[],
  );

  const numOfIndexFunds = uniqIndexFunds.length - 1;
  const nEmptyFields = Array(numOfIndexFunds).fill(' ').join(csvDivider);
  const indexFundNames = uniqIndexFunds.map((item) => `(${item.id}) ${item.name}`).join(csvDivider);

  let data = [
    // First Headers
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    ...(items[0]?.pairRequestId ? [''] : []),
    ...(withEstimated
      ? [
          'Weights',
          ...toFields(nEmptyFields),
          '',
          'Estimated Amount',
          ...toFields(nEmptyFields),
          '',
          '',
          'Estimated Units',
          ...toFields(nEmptyFields),
          'Estimated Nav',
          ...toFields(nEmptyFields),
        ]
      : []),
    'Order Weights',
    ...toFields(nEmptyFields),
    'Actual Order Weights',
    ...toFields(nEmptyFields),
    'Actual Amount',
    ...toFields(nEmptyFields),
    'Actual Units',
    ...toFields(nEmptyFields),
    'Actual Nav',
    ...toFields(nEmptyFields),
    'Cash Returns',
    ...toFields(nEmptyFields),
    'Cost Base',
    ...toFields(nEmptyFields),
    'New Cost Base',
    ...toFields(nEmptyFields),
    'Total Cost Base',
    ...toFields(nEmptyFields),
    'New Total Cost Base',
    ...toFields(nEmptyFields),
    'Outstanding User Units',
    ...toFields(nEmptyFields),
    'Estimated Outstanding User Units',
    ...toFields(nEmptyFields),
    'Actual Outstanding User Units',
    ...toFields(nEmptyFields),
    'Total Required Units',
    ...toFields(nEmptyFields),
    'User Redemption Units',
    ...toFields(nEmptyFields),
    ...(withEstimated
      ? ['Satellite Amount', ...toFields(nEmptyFields), 'Satellite Units', ...toFields(nEmptyFields)]
      : []),
    'Satellite Actual Amount',
    ...toFields(nEmptyFields),
    'Satellite Actual Units',
    ...toFields(nEmptyFields),
    ...(withEstimated ? ['Total Amount', ...toFields(nEmptyFields), 'Total Units', ...toFields(nEmptyFields)] : []),
    'Estimated Market Value',
    ...toFields(nEmptyFields),
    'Actual Market Value',
    ...toFields(nEmptyFields),
    'Unexecuted Amount',
    ...toFields(nEmptyFields),
    'Unexecuted Units',
    ...toFields(nEmptyFields),
    // Second Headers
    '\nUser ID',
    'Custodian Number',
    'Request ID',
    ...(items[0]?.pairRequestId ? ['Pair Request ID'] : []),
    'Order Id',
    'Subscribed Portfolio Id',
    'Type',
    'Invested Amount',
    ...(withEstimated
      ? [
          ...toFields(indexFundNames),
          'Cash',
          ...toFields(indexFundNames),
          'Cash',
          'Total',
          ...toFields(indexFundNames),
          ...toFields(indexFundNames),
        ]
      : []),
    ...toFields(indexFundNames),
    ...toFields(indexFundNames),
    ...toFields(indexFundNames),
    ...toFields(indexFundNames),
    ...toFields(indexFundNames),
    ...toFields(indexFundNames),
    ...toFields(indexFundNames),
    ...toFields(indexFundNames),
    ...toFields(indexFundNames),
    ...toFields(indexFundNames),
    ...toFields(indexFundNames),
    ...toFields(indexFundNames),
    ...toFields(indexFundNames),
    ...toFields(indexFundNames),
    ...(withEstimated ? [...toFields(indexFundNames), ...toFields(indexFundNames)] : []),
    ...toFields(indexFundNames),
    ...toFields(indexFundNames),
    ...toFields(indexFundNames),
    ...(withEstimated ? [...toFields(indexFundNames), ...toFields(indexFundNames)] : []),
    ...toFields(indexFundNames),
    ...toFields(indexFundNames),
    ...toFields(indexFundNames),
    ...toFields(indexFundNames),
  ].join(csvDivider);

  const localRound2 = (v: number) => v; // round2
  const localRound4 = (v: number) => v; // round4

  for (const item of items) {
    data +=
      [
        `\n${item.userId}`,
        item.custodianNumber,
        item.userPortfolioRequestId,
        ...(item.pairRequestId ? [item.pairRequestId] : []),
        item.orderId,
        item.subscribedPortfolioId,
        item.type,
        localRound2(item.investedAmount),
      ].join(csvDivider) + csvDivider;

    if (withEstimated) {
      data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'weight', localRound2, csvDivider);

      data += `${localRound2(item.cashAllocationPercentage)}${csvDivider}`;

      data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'estimatedAmount', localRound2, csvDivider);

      data += [localRound2(item.cashAllocationValue), localRound2(item.investedAmount)].join(csvDivider) + csvDivider;

      data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'estimatedUnits', localRound4, csvDivider);

      data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'estimatedNav', localRound4, csvDivider);
    }

    data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'orderWeight', localRound2, csvDivider);

    data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'actualOrderWeight', localRound2, csvDivider);

    data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'actualAmount', localRound4, csvDivider);

    data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'actualUnits', localRound4, csvDivider);

    data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'actualNav', localRound4, csvDivider);

    data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'returnAmount', localRound2, csvDivider);

    data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'costBase', localRound2, csvDivider);

    data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'newCostBase', localRound2, csvDivider);

    data += addIndexFundReportProperty(
      uniqIndexFunds,
      item.indexFunds,
      (currentItem) => (currentItem?.outstandingUnits || 0) * (currentItem?.costBase || 0),
      localRound2,
      csvDivider,
    );

    data += addIndexFundReportProperty(
      uniqIndexFunds,
      item.indexFunds,
      (currentItem) => (currentItem?.actualOutstandingUnits || 0) * (currentItem?.newCostBase || 0),
      localRound2,
      csvDivider,
    );

    data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'outstandingUnits', localRound2, csvDivider);

    data += addIndexFundReportProperty(
      uniqIndexFunds,
      item.indexFunds,
      'estimatedOutstandingUnits',
      localRound2,
      csvDivider,
    );

    data += addIndexFundReportProperty(
      uniqIndexFunds,
      item.indexFunds,
      'actualOutstandingUnits',
      localRound2,
      csvDivider,
    );

    data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'totalRequiredUnits', localRound2, csvDivider);

    data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'userRedemptionUnits', localRound2, csvDivider);

    if (withEstimated) {
      data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'satelliteAmount', localRound2, csvDivider);

      data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'satelliteUnits', localRound4, csvDivider);
    }

    data += addIndexFundReportProperty(
      uniqIndexFunds,
      item.indexFunds,
      'satelliteActualAmount',
      localRound2,
      csvDivider,
    );

    data += addIndexFundReportProperty(
      uniqIndexFunds,
      item.indexFunds,
      'satelliteActualUnits',
      localRound4,
      csvDivider,
    );

    if (withEstimated) {
      data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'totalActualAmount', localRound2, csvDivider);

      data += addIndexFundReportProperty(
        uniqIndexFunds,
        item.indexFunds,
        (currentItem) => (currentItem?.totalActualUnits || 0) + (currentItem?.satelliteActualUnits || 0),
        localRound4,
        csvDivider,
      );
    }

    data += addIndexFundReportProperty(
      uniqIndexFunds,
      item.indexFunds,
      'estimatedMarketValue',
      localRound2,
      csvDivider,
    );

    data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'actualMarketValue', localRound4, csvDivider);

    data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'unexecutedAmount', localRound2, csvDivider);

    data += addIndexFundReportProperty(uniqIndexFunds, item.indexFunds, 'unexecutedUnits', localRound4, csvDivider);
  }

  return data;
};
