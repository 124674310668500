import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import _capitalize from 'lodash/capitalize';
import _upperCase from 'lodash/upperCase';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useAppDispatch } from '../../redux/hooks';
import { fetchServiceVersions } from '../../redux/modules/monitoring/monitoring.actions';
import { monitoringSelector } from '../../redux/modules/monitoring/monitoring.selectors';
import { ServiceVersion } from '../../types/monitoring';

export const ServiceVersionsTab = () => {
  const dispatch = useAppDispatch();

  const { serviceVersions, fetchServiceVersionsStatus } = useSelector(monitoringSelector);
  const isLoading = fetchServiceVersionsStatus === fetchStatuses.pending;

  useEffect(() => {
    dispatch(fetchServiceVersions());
  }, []);

  const renderServiceItem = (serviceName: string, versions: ServiceVersion) => (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" component="div">
          {_capitalize(_upperCase(serviceName))} {' Service'}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {`v${versions.version || '-'} (v${versions.coreVersion || '-'} core)`}
        </Typography>
        <Typography variant="body2">
          <b>Started: </b>
          {versions.uptime ? moment().subtract(versions.uptime, 'seconds').format('LLL') : '-'}
        </Typography>
      </CardContent>
    </Card>
  );

  if (isLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container spacing={2}>
      {Object.entries(serviceVersions).map(([serviceName, versions]) => (
        <Grid item key={`service-status-${serviceName}`} xs={3} sx={{ height: '100%' }}>
          {renderServiceItem(serviceName, versions)}
        </Grid>
      ))}
    </Grid>
  );
};
