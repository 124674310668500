import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AttachmentIcon from '@mui/icons-material/Attachment';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import InsightsIcon from '@mui/icons-material/Insights';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import React from 'react';
import { useSearchParamsState } from '../../hooks';
import { FundOrder } from '../../types/portfolioOrder';
import { AttachmentsTab } from './AttachmentsTab';
import { ConsolidateRequestsTab } from './ConsolidateRequestsTab';
import { FundOrdersTab } from './FundOrdersTab';
import { PortfolioOrdersWeightsTab } from './PortfolioOrdersWeightsTab';
import { PortfolioOrderTab } from './PortfolioOrderTab';
import { RequestsTab } from './RequestsTab';
import { SatelliteRequestsTab } from './SatelliteRequestsTab';
import { AlpacaTab } from './AlpacaTab';
import { JadwaTab } from './JadwaTab';

const mapTabParamToValue: Record<string, string> = {
  portfolioOrder: '1',
  fundOrders: '2',
  fundOrdersWeights: '3',
  subscriptionRequests: '4',
  satelliteRequests: '5',
  consolidateRequests: '6',
  attachments: '7',
  alpaca: '8',
  jadwa: '9',
  default: '1',
};
const mapTabValueToTabParam: Record<string, string> = {
  1: 'portfolioOrder',
  2: 'fundOrders',
  3: 'fundOrdersWeights',
  4: 'subscriptionRequests',
  5: 'satelliteRequests',
  6: 'consolidateRequests',
  7: 'attachments',
  8: 'alpaca',
  9: 'jadwa',
};

interface PortfolioOrderTabListProps {
  orderId?: string;
  fundOrdersKey: string;
  onSelectFundOrders: (ids: FundOrder[]) => void;
  showAlpacaTab: boolean;
  showJadwaTab: boolean;
}

export function PortfolioOrderTabList(props: PortfolioOrderTabListProps) {
  const { orderId, fundOrdersKey, onSelectFundOrders, showAlpacaTab, showJadwaTab } = props;

  const [selectedTab, setSelectedTab] = useSearchParamsState('tab', 'default');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(mapTabValueToTabParam[newValue]);
  };

  if (!orderId) {
    return <div>Loading...</div>;
  }

  return (
    <TabContext value={mapTabParamToValue[selectedTab]}>
      <Box sx={{ width: '100%' }}>
        <TabList
          value={mapTabParamToValue[selectedTab]}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="IndexFund Order tabs"
        >
          <Tab
            icon={<AccountBoxIcon />}
            iconPosition="start"
            label="Portfolio Order"
            value="1"
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<PersonSearchIcon />}
            iconPosition="start"
            label="Fund Orders"
            value="2"
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<PersonSearchIcon />}
            iconPosition="start"
            label="Weights"
            value="3"
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<HistoryEduIcon />}
            iconPosition="start"
            label="Subscription Requests"
            value="4"
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<HistoryEduIcon />}
            iconPosition="start"
            label="Satellite Requests"
            value="5"
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<HistoryEduIcon />}
            iconPosition="start"
            label="Consolidate Requests"
            value="6"
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<AttachmentIcon />}
            iconPosition="start"
            label="Attachments"
            value="7"
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          {showAlpacaTab && (
            <Tab
              icon={<InsightsIcon />}
              iconPosition="start"
              label="Alpaca"
              value="8"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
            />
          )}
          {showJadwaTab && (
            <Tab
              icon={<InsightsIcon />}
              iconPosition="start"
              label="Jadwa"
              value="9"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
            />
          )}
        </TabList>
      </Box>
      <TabPanel value="1">
        <PortfolioOrderTab orderId={orderId} />
      </TabPanel>
      <TabPanel value="2">
        <FundOrdersTab key={fundOrdersKey} onSelectFundOrders={onSelectFundOrders} orderId={orderId} />
      </TabPanel>
      <TabPanel value="3">
        <PortfolioOrdersWeightsTab orderId={orderId} />
      </TabPanel>
      <TabPanel value="4">
        <RequestsTab orderId={orderId} />
      </TabPanel>
      <TabPanel value="5">
        <SatelliteRequestsTab orderId={orderId} />
      </TabPanel>
      <TabPanel value="6">
        <ConsolidateRequestsTab orderId={orderId} />
      </TabPanel>
      <TabPanel value="7">
        <AttachmentsTab orderId={orderId} />
      </TabPanel>
      {showAlpacaTab && (
        <TabPanel value="8">
          <AlpacaTab orderId={orderId} />
        </TabPanel>
      )}
      {showJadwaTab && (
        <TabPanel value="9">
          <JadwaTab orderId={orderId} />
        </TabPanel>
      )}
    </TabContext>
  );
}
