import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayForWorkIcon from '@mui/icons-material/PlayForWork';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ForwardIcon from '@mui/icons-material/Forward';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { AsyncDialog, TypeConfirmationDialog } from '../../components/AsyncDialog';
import { AsyncDialogRef } from '../../components/AsyncDialog/AsyncDialog';
import { TypeConfirmationDialogData } from '../../components/AsyncDialog/TypeConfirmationDialog';
import { CopyToClipboardButton } from '../../components/CopyToClipboardButton';
import { FakeButtonLink } from '../../components/FakeLink';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useAppDispatch } from '../../redux/hooks';
import {
  batchExecutionBybatchIdSelector,
  batchExecutionSelector,
} from '../../redux/modules/batchExecution/batchExecution.selectors';
import {
  approveBatchExecutionsByBatchId,
  changeBatchExecutionsToReconciliationByBatchId,
  consolidateFeeCalculations,
  discardBatchExecutionsByBatchId,
  executeFeeBatch,
  fetchFeeCalculationsByBatchId,
  fetchFeeBatchExecutionsByBatchId,
  importConsolidateExecutionFileFeeBatch,
  uploadBatchAttachment,
  cancelExecutionFeeBatch,
  deleteFeeBatch,
} from '../../redux/modules/feeBatch/feeBatch.actions';
import { feeBatchSelector, viewDetailsByBatchIdSelector } from '../../redux/modules/feeBatch/feeBatch.selectors';
import { ReducerState } from '../../redux/store.types';
import { locations } from '../../routes/locations';
import { BatchExecutionReportType, BatchExecutionStatus } from '../../types/batchExecution';
import { BatchAttachmentType, FeeBatchStatus } from '../../types/feeBatch';
import { FeeCalculation, FeeCalculationTypes } from '../../types/feeCalculation';
import { UserFeeDeductionTypes } from '../../types/userFeeDeduction';
import { prepareBatchExecutionsReportData } from '../../utils/feeBatch';
import { ImportExecutionFileDialog } from './ImportExecutionFileDialog';
import { ImportReconciliationFileDialog } from './ImportReconciliationFileDialog';

const csvDivider = ','; // ';';
const fileSizeLimit = 1 * 1024 * 1024;

type FeeBatchActionsProps = {
  batchId: string;
  setFeeCalculationsKey: (v: string) => void;
  selectedFeeCalculations: FeeCalculation[];
  executionTemplateData: IAnyObject[];
  fetchFeeBatches: () => void;
  fetchBatchExecution: () => void;
  fetchConsolidateRequests: () => void;
};

export function FeeBatchActions(props: FeeBatchActionsProps) {
  const {
    batchId,
    setFeeCalculationsKey,
    selectedFeeCalculations,
    executionTemplateData,
    fetchFeeBatches,
    fetchBatchExecution,
    fetchConsolidateRequests,
  } = props;

  const { t } = useTranslation('apiError');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const asyncDialogRef = useRef<AsyncDialogRef<TypeConfirmationDialogData, boolean>>(null);
  const csvLink = useRef<CSVLink>();
  const csvOrderExecutionsReportLink = useRef<CSVLink>();
  const consolidateExecutionInputRef = React.useRef<HTMLInputElement>(null);
  const [reportData, setReportData] = useState('');
  const [openReconcileDialog, setOpenReconcileDialog] = useState(false);
  const [openImportExecutionFile, setOpenImportExecutionFile] = useState(false);
  const {
    executeStatus,
    consolidateStatus,
    importConsolidateExecutionFileStatus,
    // fetchOrderExecutionsReportStatus,
    discardBatchExecutionsStatus,
    changeBatchExecutionsToReconciliationStatus,
    reconcileBatchExecutionsStatus,
    approveBatchExecutionsStatus,
    cancelExecutionStatus,
    deleteStatus,
  } = useSelector(feeBatchSelector);
  const { fetchBatchExecutionByBatchIdStatus } = useSelector(batchExecutionSelector);
  const batchExecution = useSelector((state: ReducerState) => batchExecutionBybatchIdSelector(state, batchId || ''));
  const {
    feeBatches,
    fetchFeeCalculationsStatus,
    // consolidateRequests,
    // fetchConsolidateRequestsStatus,
  } = useSelector(viewDetailsByBatchIdSelector);
  const feeBatchStatus = feeBatches.length && feeBatches?.[0].status?.name;
  const consolidateStatusLoading = consolidateStatus === fetchStatuses.pending;
  const executeStatusLoading = executeStatus === fetchStatuses.pending;
  const importConsolidateExecutionFileStatusLoading = importConsolidateExecutionFileStatus === fetchStatuses.pending;
  // const fetchOrderExecutionsReportStatusLoading = fetchOrderExecutionsReportStatus === fetchStatuses.pending;
  const fetchBatchExecutionStatusLoading = fetchBatchExecutionByBatchIdStatus === fetchStatuses.pending;
  const changeBatchExecutionsToReconciliationStatusLoading =
    changeBatchExecutionsToReconciliationStatus === fetchStatuses.pending;
  const approveBatchExecutionsStatusLoading = approveBatchExecutionsStatus === fetchStatuses.pending;
  const reconcileBatchExecutionsStatusLoading = reconcileBatchExecutionsStatus === fetchStatuses.pending;
  const discardBatchExecutionsStatusLoading = discardBatchExecutionsStatus === fetchStatuses.pending;
  const cancelExecutionStatusLoading = cancelExecutionStatus === fetchStatuses.pending;
  const deleteStatusLoading = deleteStatus === fetchStatuses.pending;
  const exportExecutionTemplateLoading = fetchFeeCalculationsStatus === fetchStatuses.pending;
  const isAnyActionLoading =
    consolidateStatusLoading ||
    executeStatusLoading ||
    importConsolidateExecutionFileStatusLoading ||
    // fetchBatchExecutionsReportStatusLoading ||
    fetchBatchExecutionStatusLoading ||
    changeBatchExecutionsToReconciliationStatusLoading ||
    approveBatchExecutionsStatusLoading ||
    reconcileBatchExecutionsStatusLoading ||
    discardBatchExecutionsStatusLoading ||
    cancelExecutionStatusLoading ||
    deleteStatusLoading ||
    exportExecutionTemplateLoading;

  const executeBatch = async () => {
    if (batchId) {
      try {
        await dispatch(executeFeeBatch(batchId)).unwrap();

        setFeeCalculationsKey(`${Date.now()}`);
        fetchFeeBatches();
        fetchBatchExecution();
        enqueueSnackbar('Batch successfully executed!', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(`Batch execution failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`, {
          variant: 'error',
        });
      }
    }
  };

  const deleteBatch = async () => {
    if (batchId) {
      const isConfirmed = await asyncDialogRef.current?.show({
        title: 'Deleting a batch',
        description: (
          <>
            Please type the batch id &nbsp;
            <CopyToClipboardButton value={batchId} />
            &nbsp; to confirm
          </>
        ),
        value: batchId,
      });

      if (!isConfirmed) {
        return;
      }

      try {
        await dispatch(deleteFeeBatch(batchId)).unwrap();

        await navigate(locations.feeBatchList(), { replace: true });

        enqueueSnackbar('Batch successfully deleted!', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(`Batch deletion failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`, {
          variant: 'error',
        });
      }
    }
  };

  const consolidateOrders = async () => {
    if (selectedFeeCalculations.length) {
      try {
        await dispatch(consolidateFeeCalculations(selectedFeeCalculations.map((i) => i.id))).unwrap();

        fetchConsolidateRequests();
        enqueueSnackbar('Consolidate successfully!', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(`Consolidate failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`, {
          variant: 'error',
        });
      }
    }
  };

  const importExecutionFile = (): void => {
    setOpenImportExecutionFile(true);
  };

  const handleCloseImportExecutionFile = (isReload: boolean) => {
    setOpenImportExecutionFile(false);

    if (isReload) {
      fetchFeeBatches();
      fetchBatchExecution();
    }
  };

  const importConsolidateExecutionFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let file = event.target?.files?.[0];

    if (file && file.size > fileSizeLimit) {
      file = undefined;
      enqueueSnackbar('Allowed Size 1 MB!', { variant: 'error' });
    }

    if (file && batchId) {
      try {
        await dispatch(importConsolidateExecutionFileFeeBatch({ batchId, file })).unwrap();

        fetchConsolidateRequests();
        enqueueSnackbar('Consolidate execution file was imported successfully!', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(
          `Import consolidate execution file was failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`,
          { variant: 'error' },
        );
      }
    }

    if (consolidateExecutionInputRef.current) {
      consolidateExecutionInputRef.current.value = '';
    }
  };

  const exportExecutionTemplate = () => {
    if (csvLink.current) {
      csvLink.current.link.click();
    }
  };

  const uploadBatchExecutionsReport = async (data: string) => {
    if (batchId) {
      try {
        await dispatch(
          uploadBatchAttachment({
            batchId,
            fileName: `batch-execution-report-${batchId}.csv`,
            fileData: data,
            type: BatchAttachmentType.EXECUTION_REPORT_FILE,
          }),
        ).unwrap();

        enqueueSnackbar('Fee Batch Executions Report uploaded successfully!', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(
          `Upload Fee Batch Executions Report failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`,
          { variant: 'error' },
        );
      }
    }
  };

  // const downloadBatchExecutionsReport = async (reportType: OrderExecutionReportType) => {
  //   if (batchId) {
  //     try {
  //       const res = await dispatch(fetchBatchExecutionsReportByBatchId({ batchId, reportType })).unwrap();
  //       const data = prepareOrderExecutionsReportData(res.data, csvDivider);

  //       setReportData(data);

  //       // uploadOrderExecutionsReport(data);

  //       setTimeout(() => {
  //         if (csvOrderExecutionsReportLink.current) {
  //           csvOrderExecutionsReportLink.current.link.click();
  //         }
  //       }, 0);
  //     } catch (error: any) {
  //       enqueueSnackbar(
  //         `Download Portfolio Order Executions Report failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`,
  //         { variant: 'error' },
  //       );
  //     }
  //   }
  // };

  const discardBatchExecutions = async () => {
    if (batchId) {
      try {
        await dispatch(discardBatchExecutionsByBatchId(batchId)).unwrap();

        fetchFeeBatches();
        fetchBatchExecution();

        dispatch(fetchFeeCalculationsByBatchId({ batchId, limit: 25 }));

        enqueueSnackbar('Discard Fee Batch Executions successfully!', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(`Discard Fee Batch Executions failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`, {
          variant: 'error',
        });
      }
    }
  };

  const cancelBatchExecution = async () => {
    if (batchId) {
      try {
        await dispatch(cancelExecutionFeeBatch(batchId)).unwrap();

        fetchFeeBatches();
        fetchBatchExecution();

        dispatch(fetchFeeCalculationsByBatchId({ batchId, limit: 25 }));

        enqueueSnackbar('Cancel Fee Batch Executions successfully!', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(`Cancel Fee Batch Executions failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`, {
          variant: 'error',
        });
      }
    }
  };

  const changeBatchExecutionsToReconciliation = async () => {
    if (batchId) {
      try {
        await dispatch(changeBatchExecutionsToReconciliationByBatchId(batchId)).unwrap();

        fetchBatchExecution();

        enqueueSnackbar('Change Batch Executions To Reconciliation successfully!', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(
          `Change Batch Executions To Reconciliation was failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`,
          { variant: 'error' },
        );
      }
    }
  };

  const approveBatchExecutions = async () => {
    if (batchId) {
      try {
        await dispatch(approveBatchExecutionsByBatchId(batchId)).unwrap();

        fetchBatchExecution();

        enqueueSnackbar('Batch Executions Approved successfully!', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(`Approve Batch Executions was failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`, {
          variant: 'error',
        });
      }
    }
  };

  const handleReconcileClose = (isReload?: boolean) => {
    if (isReload) {
      // TODO: Un-comment when use reconcile
      // fetchPortfolioOrders();
      // fetchOrderExecution();
      // fetchSatelliteRequests();
    }
    setOpenReconcileDialog(false);
  };

  const reconcileOrderExecutions = async () => {
    if (batchId) {
      setOpenReconcileDialog(true);
    }
  };

  const canConsolidate = () =>
    feeBatchStatus === FeeBatchStatus.CREATED &&
    selectedFeeCalculations.find((fo) => fo.type.name === FeeCalculationTypes.REGULAR_FEE) &&
    selectedFeeCalculations.find((fo) => fo.type.name === FeeCalculationTypes.FULLY_PAID_MONTHLY_FEE) &&
    selectedFeeCalculations.find((fo) => fo.type.name === FeeCalculationTypes.FULL_REDEMPTION_FEE);

  const canExecute = () =>
    batchId &&
    (!batchExecution || batchExecution?.status?.name === BatchExecutionStatus.CREATED) &&
    feeBatchStatus === FeeBatchStatus.CREATED;
  // && fetchConsolidateRequestsStatus === fetchStatuses.success &&
  // !consolidateRequests.find((fo) =>
  //   [ConsolidateRequestStatus.CREATED, ConsolidateRequestStatus.IN_PROGRESS].includes(fo.status.name),
  // );

  const canDownloadOrderExecutionsReport = () =>
    batchId &&
    fetchBatchExecutionByBatchIdStatus === fetchStatuses.success &&
    batchExecution &&
    ![BatchExecutionStatus.CREATED, BatchExecutionStatus.EXECUTION].includes(batchExecution?.status?.name);

  const canDiscardOrderExecutions = () =>
    batchId &&
    fetchBatchExecutionByBatchIdStatus === fetchStatuses.success &&
    [BatchExecutionStatus.IN_REVIEW, BatchExecutionStatus.RECONCILIATION].includes(batchExecution?.status?.name);

  const canChangeOrderExecutionsToReconciliation = () =>
    batchId &&
    fetchBatchExecutionByBatchIdStatus === fetchStatuses.success &&
    [BatchExecutionStatus.IN_REVIEW].includes(batchExecution?.status?.name);

  const canReconcileOrderExecutions = () =>
    batchId &&
    fetchBatchExecutionByBatchIdStatus === fetchStatuses.success &&
    [BatchExecutionStatus.RECONCILIATION].includes(batchExecution?.status?.name);

  const canApproveOrderExecutions = () =>
    batchId &&
    fetchBatchExecutionByBatchIdStatus === fetchStatuses.success &&
    [BatchExecutionStatus.IN_REVIEW, BatchExecutionStatus.RECONCILIATION].includes(batchExecution?.status?.name);

  const canImportConsolidateExecutionFile = () => batchId;
  // && fetchConsolidateRequestsStatus === fetchStatuses.success &&
  // consolidateRequests.find((fo) =>
  //   [ConsolidateRequestStatus.CREATED, ConsolidateRequestStatus.IN_PROGRESS].includes(fo.status.name),
  // );

  return (
    <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" columnSpacing={2}>
      <Grid item display="flex" alignItems="center" flexDirection="row">
        {batchExecution && (
          <>
            <FakeButtonLink
              startIcon={<OpenInNewIcon />}
              href={locations.batchExecution(batchExecution.executionId)}
              width="auto"
            >
              Exec. Id:&nbsp;
            </FakeButtonLink>
            <CopyToClipboardButton value={batchExecution.executionId} />
          </>
        )}
      </Grid>
      <Grid item container flex={1} flexDirection="row" justifyContent="flex-end" alignItems="center" columnSpacing={2}>
        {canConsolidate() && (
          <Grid item>
            <Button
              id="ConsolidateButton"
              variant="outlined"
              color="primary"
              startIcon={<PlayArrowIcon />}
              onClick={consolidateOrders}
              disabled={isAnyActionLoading}
            >
              Consolidate
            </Button>
          </Grid>
        )}
        {canExecute() && (
          <>
            <Grid item>
              <Button
                id="ExecuteButton"
                variant="outlined"
                color="primary"
                startIcon={<PlayArrowIcon />}
                onClick={executeBatch}
                disabled={isAnyActionLoading}
              >
                Execute
              </Button>
            </Grid>
            <Grid item>
              <Button
                id="DeleteButton"
                variant="outlined"
                color="error"
                startIcon={<DeleteOutlineIcon />}
                onClick={deleteBatch}
                disabled={isAnyActionLoading}
              >
                Delete
              </Button>
            </Grid>
          </>
        )}
        {canImportConsolidateExecutionFile() && (
          <Grid item>
            <Button
              id="ImportConsolidateExecutionFileButton"
              variant="outlined"
              component="label"
              aria-label="import consolidate ex. file"
              color="primary"
              startIcon={<PlayForWorkIcon />}
              disabled={isAnyActionLoading}
            >
              Import consolidate ex. file
              <input
                ref={consolidateExecutionInputRef}
                hidden
                accept="text/csv"
                type="file"
                onChange={importConsolidateExecutionFile}
              />
            </Button>
          </Grid>
        )}
        {canDownloadOrderExecutionsReport() && (
          <>
            <Grid item>
              <CSVLink
                data={reportData}
                filename={`batch-execution-request-report-${batchId}.csv`}
                className="hidden"
                separator={csvDivider}
                ref={csvOrderExecutionsReportLink}
                target="_blank"
              />
              <Tooltip title={batchExecution?.reportsGenerated ? 'Download' : 'Waiting Generation'} followCursor>
                <Button
                  id="DownloadBatchExecutionsRequestReportButton"
                  variant="outlined"
                  component="label"
                  aria-label="Download Report by Request"
                  color="primary"
                  startIcon={batchExecution?.reportsGenerated ? <DownloadIcon /> : <HistoryToggleOffIcon />}
                  disabled={isAnyActionLoading || !batchExecution?.reportsGenerated}
                  // onClick={() => downloadOrderExecutionsReport(OrderExecutionReportType.REQUEST)}
                >
                  Download Report by Request
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <CSVLink
                data={reportData}
                filename={`batch-execution-user-report-${batchId}.csv`}
                className="hidden"
                separator={csvDivider}
                ref={csvOrderExecutionsReportLink}
                target="_blank"
              />
              <Tooltip title={batchExecution?.reportsGenerated ? 'Download' : 'Waiting Generation'} followCursor>
                <Button
                  id="DownloadBatchExecutionsUserReportButton"
                  variant="outlined"
                  component="label"
                  aria-label="Download Report by User"
                  color="primary"
                  startIcon={batchExecution?.reportsGenerated ? <DownloadIcon /> : <HistoryToggleOffIcon />}
                  disabled={isAnyActionLoading || !batchExecution?.reportsGenerated}
                  // onClick={() => downloadOrderExecutionsReport(OrderExecutionReportType.USER)}
                >
                  Download Report by User
                </Button>
              </Tooltip>
            </Grid>
          </>
        )}
        {canChangeOrderExecutionsToReconciliation() && (
          <Grid item>
            <Button
              id="ToReconciliationButton"
              variant="outlined"
              component="label"
              aria-label="To Reconciliation"
              color="success"
              startIcon={<ForwardIcon />}
              disabled={isAnyActionLoading}
              onClick={changeBatchExecutionsToReconciliation}
            >
              To Reconciliation
            </Button>
          </Grid>
        )}
        {canApproveOrderExecutions() && (
          <Grid item>
            <Button
              id="ApproveButton"
              variant="outlined"
              component="label"
              aria-label="Approve"
              color="success"
              startIcon={<DoneAllIcon />}
              disabled={isAnyActionLoading}
              onClick={approveBatchExecutions}
            >
              Approve
            </Button>
          </Grid>
        )}
        {canReconcileOrderExecutions() && (
          <Grid item>
            <Button
              id="ReconcileButton"
              variant="outlined"
              component="label"
              aria-label="Reconcile"
              color="warning"
              startIcon={<ForwardIcon />}
              disabled={isAnyActionLoading}
              onClick={reconcileOrderExecutions}
            >
              Reconcile
            </Button>
            <ImportReconciliationFileDialog
              open={openReconcileDialog}
              onClose={handleReconcileClose}
              orderId={batchId}
            />
          </Grid>
        )}
        {canDiscardOrderExecutions() && (
          <Grid item>
            <Button
              id="DiscardBatchExecutionsButton"
              variant="outlined"
              component="label"
              aria-label="Discard Execution"
              color="error"
              startIcon={<RestartAltIcon />}
              disabled={isAnyActionLoading}
              onClick={discardBatchExecutions}
            >
              Discard Execution
            </Button>
          </Grid>
        )}
        {feeBatchStatus === FeeBatchStatus.IN_PROGRESS && (
          <>
            <Grid item>
              <CSVLink
                data={executionTemplateData}
                filename={`execution-template-${batchId}.csv`}
                className="hidden"
                ref={csvLink}
                target="_blank"
              />
              <Button
                id="ExportExecutionTemplateButton"
                variant="outlined"
                component="label"
                aria-label="export exec. template"
                color="primary"
                startIcon={<DownloadIcon />}
                disabled={isAnyActionLoading}
                onClick={exportExecutionTemplate}
              >
                Export exec. template
              </Button>
            </Grid>
            <Grid item>
              <Button
                id="ImportExecutionFileButton"
                variant="outlined"
                component="label"
                aria-label="import execution file"
                color="primary"
                startIcon={<PlayForWorkIcon />}
                disabled={isAnyActionLoading}
                onClick={importExecutionFile}
              >
                Import execution file
              </Button>
            </Grid>
            <Grid item>
              <Button
                id="CancelBatchExecutionsButton"
                variant="outlined"
                component="label"
                aria-label="Cancel Execution"
                color="error"
                startIcon={<CancelIcon />}
                disabled={isAnyActionLoading}
                onClick={cancelBatchExecution}
              >
                Cancel Execution
              </Button>
            </Grid>
          </>
        )}
      </Grid>

      <ImportExecutionFileDialog
        open={openImportExecutionFile}
        onClose={handleCloseImportExecutionFile}
        batchId={batchId}
      />

      <AsyncDialog ref={asyncDialogRef} dialog={TypeConfirmationDialog} />
    </Grid>
  );
}
