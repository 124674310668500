import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { DownwardIcon, NoopIcon, UpwardIcon } from '../../../../components/Icon';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { fetchUserFeeHistory } from '../../../../redux/modules/userFee/userFee.actions';
import { userFeeSelector } from '../../../../redux/modules/userFee/userFee.selectors';
import { GridCellExpand, Table } from '../../../../components/Table';
import { round2Format } from '../../../../utils/number';
import { UserFeeTransactionTypes } from '../../../../types/userFee';
import { useAppDispatch } from '../../../../redux/hooks';

interface UserFeeHistoryTableProps {
  userFeeId: number;
}

export function UserFeeHistoryTable({ userFeeId }: UserFeeHistoryTableProps) {
  const dispatch = useAppDispatch();

  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id', filterable: false },
    {
      field: 'transactionType.name',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: Object.values(UserFeeTransactionTypes),
      width: 150,
      valueGetter: (params) => params.row?.transactionType?.name,
    },
    {
      field: 'totalMarketValue',
      headerName: 'Total Market Value',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'prevTotalFee',
      headerName: 'Prev Total Fee',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'prevManagementFee',
      headerName: 'Prev Management Fee',
      minWidth: 150,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'prevVat',
      headerName: 'Prev VAT',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'totalFee',
      headerName: 'Total Fee',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => {
        let Icon = NoopIcon;

        if (params.value > 0) {
          Icon = UpwardIcon;
        }

        if (params.value < 0) {
          Icon = DownwardIcon;
        }

        return (
          <>
            <Icon />
            {round2Format(params.value)}
          </>
        );
      },
    },
    {
      field: 'managementFee',
      headerName: 'Management Fee',
      minWidth: 150,
      type: 'number',
      flex: 1,
      renderCell: (params) => {
        let Icon = NoopIcon;

        if (params.value > 0) {
          Icon = UpwardIcon;
        }

        if (params.value < 0) {
          Icon = DownwardIcon;
        }

        return (
          <>
            <Icon />
            {round2Format(params.value)}
          </>
        );
      },
    },
    {
      field: 'vat',
      headerName: 'VAT',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => {
        let Icon = NoopIcon;

        if (params.value > 0) {
          Icon = UpwardIcon;
        }

        if (params.value < 0) {
          Icon = DownwardIcon;
        }

        return (
          <>
            <Icon />
            {round2Format(params.value)}
          </>
        );
      },
    },
    {
      field: 'newTotalFee',
      headerName: 'New Total Fee',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'newManagementFee',
      headerName: 'New Management Fee',
      minWidth: 150,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'newVat',
      headerName: 'New VAT',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'metadata',
      headerName: 'Metadata',
      sortable: false,
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <GridCellExpand value={JSON.stringify(params.value, null, 2) || ''} width={params.colDef.computedWidth} />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'dateTime',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const { userFeeHistory, totalUserFeeHistory, fetchUserFeeHistoryStatus } = useSelector(userFeeSelector);

  useEffect(() => {
    dispatch(
      fetchUserFeeHistory({
        limit: 100,
        offset: 0,
        sortField: 'id',
        sortOrder: 'desc',
        filter: JSON.stringify({
          items: [{ columnField: 'userFee', operatorValue: '=', value: `${userFeeId}` }],
        }),
      }),
    );
  }, [userFeeId]);

  return (
    <Table
      rows={userFeeHistory}
      columns={columns.current}
      loading={fetchUserFeeHistoryStatus === fetchStatuses.pending}
      rowCount={totalUserFeeHistory}
      disableRowGrouping
      density="compact"
      initialColumnVisibilityModel={{ id: false, metadata: false }}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      requiredFilters={[{ field: 'userFee', value: `${userFeeId}`, operator: 'is' }]}
      toolbarExportProps={{
        csvOptions: { fileName: `user-fee-${userFeeId}-history-${new Date().toLocaleString()}` },
        excelOptions: { fileName: `user-fee-${userFeeId}-history-${new Date().toLocaleString()}` },
      }}
      wrapperHeight={300}
    />
  );
}
