import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import List from '@mui/material/List';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { FakeLink } from '../../../components/FakeLink';
import { ProtectedContent } from '../../../components/ProtectedContent';
import COLORS from '../../../constants/colors';
import { locations } from '../../../routes/locations';
import { isSelectedItem, selectOpenedParentItem } from './helpers';
import { CustomListItemButton, Drawer, DrawerHeader } from './SidebarElements';
import { MenuItem, MenuItemParent, MenuItems, MenuItemSimple } from './types';
import packageJson from '../../../../package.json';

interface ISidebarProps {
  open: boolean;
  handleDrawerClose: () => void;
  theme: Theme;
  menuItems: MenuItems;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Sidebar({ open, handleDrawerClose, theme, menuItems }: ISidebarProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const openedParentItem = selectOpenedParentItem(menuItems, pathname);
  const [openItems, setItemsOpen] = React.useState<{ [prop: string]: boolean }>(
    openedParentItem ? { [openedParentItem.title]: true } : {},
  );

  const onClick = (menuItem: MenuItemSimple) => {
    if ('path' in menuItem) {
      navigate(menuItem.path);
      return;
    }

    menuItem.onClick();
  };

  const renderSimpleMenuItemLine = (menuItem: MenuItemSimple) => (
    <>
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: open ? '14px' : 'auto',
          width: '20px',
          height: '20px',
          justifyContent: 'center',
          color: isSelectedItem(menuItem, pathname) ? COLORS.WHITE : COLORS.PRIMARY_BLUE,
        }}
      >
        <menuItem.IconComponent />
      </ListItemIcon>
      <ListItemText primary={menuItem.title} sx={{ opacity: open ? 1 : 0 }} />
    </>
  );

  const renderSimpleMenuItem = (menuItem: MenuItemSimple) => (
    <ProtectedContent {...(menuItem.permissionProps || { items: [] })} key={`simple-menu-item-${menuItem.title}`}>
      <CustomListItemButton
        selected={isSelectedItem(menuItem, pathname)}
        onClick={() => onClick(menuItem)}
        sx={{
          height: '40px',
          justifyContent: open ? 'initial' : 'center',
          borderRadius: '4px',
          mb: '18px',
        }}
      >
        {'path' in menuItem ? (
          <FakeLink href={menuItem.path}>{renderSimpleMenuItemLine(menuItem)}</FakeLink>
        ) : (
          renderSimpleMenuItemLine(menuItem)
        )}
      </CustomListItemButton>
    </ProtectedContent>
  );

  const handleParentClick = (itemKey: string) => {
    setItemsOpen({
      ...openItems,
      [itemKey]: !openItems[itemKey],
    });
  };

  const renderParentMenuItem = (menuItem: MenuItemParent) => (
    <ProtectedContent {...(menuItem.permissionProps || { items: [] })} key={`parent-item-${menuItem.title}`}>
      <React.Fragment>
        <CustomListItemButton
          onClick={() => handleParentClick(menuItem.title)}
          sx={{
            height: '40px',
            justifyContent: open ? 'initial' : 'center',
            borderRadius: '4px',
            mb: '18px',
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? '14px' : 'auto',
              width: '20px',
              height: '20px',
              justifyContent: 'center',
            }}
          >
            <menuItem.IconComponent />
          </ListItemIcon>
          <ListItemText primary={menuItem.title} sx={{ opacity: open ? 1 : 0 }} />
          {openItems[menuItem.title] ? <ExpandLess /> : <ExpandMore />}
        </CustomListItemButton>
        <Collapse in={openItems[menuItem.title] || false} timeout="auto" unmountOnExit>
          <List component="div" sx={{ ml: 2 }}>
            {menuItem.children.map(renderSimpleMenuItem)}
          </List>
        </Collapse>
      </React.Fragment>
    </ProtectedContent>
  );

  const renderItem = (menuItem: MenuItem) => {
    if ('children' in menuItem) {
      return renderParentMenuItem(menuItem);
    }

    return renderSimpleMenuItem(menuItem);
  };

  return (
    <Drawer variant="permanent" open={open} elevation={0}>
      <DrawerHeader sx={{ height: '145px!important' }}>
        <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
          <Grid
            container
            justifyContent={open ? 'flex-start' : 'center'}
            alignItems="center"
            wrap="nowrap"
            sx={{ px: open ? '32px' : 0 }}
          >
            <img
              style={{
                transition: 'width .3s',
                // eslint-disable-next-line no-nested-ternary
                width: open ? (isArabic ? '130px' : '155px') : '55px',
                objectFit: 'cover',
                height: '32px',
                objectPosition: isArabic ? '100% 0' : '0 0',
              }}
              onClick={() => navigate(locations.dashboard())}
              src={isArabic ? '/images/logo-with-text-ar.png' : '/images/logo-with-text-en.png'}
              alt="madkhol logo"
            />
          </Grid>
        </Grid>
      </DrawerHeader>
      <Grid
        container
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flexWrap="nowrap"
        sx={{ px: '16px', height: '100%', overflowY: 'scroll' }}
      >
        {menuItems.map((items, listIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={`main-menu-item-${listIndex}`}>
            <List component="div" disablePadding sx={{ width: '100%' }}>
              {items.map(renderItem)}
            </List>
            {listIndex > 0 && listIndex < items.length && <Divider />}
          </React.Fragment>
        ))}
      </Grid>
      <Box display="flex" alignItems="center" justifyContent="center" marginTop="auto">
        <Typography variant="body2">
          {open ? 'Version:' : ''} {packageJson.version}
        </Typography>
      </Box>
    </Drawer>
  );
}

export default Sidebar;
