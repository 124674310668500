import * as yup from 'yup';
import '../common/extends';
import { EmployeeStatus } from '../../../types/employee';
import {
  actionFormFields,
  addFundsFormFields,
  addUserAttachmentFormFields,
  currencyFormFields,
  employeeFormFields,
  feeConfigFormFields,
  formFields,
  kycFormFields,
  portfolioFormFields,
  resourceFormFields,
  restoreAggregatedStatisticsFormFields,
  roleFormFields,
  rolePermissionFormFields,
  userCategoryFormFields,
  userFormFields,
} from '../../fields/formFields';
import { emailSchema, madkholEmailSchema, madkholSecretEmailSchema } from '../common/emailSchema';
import { passwordConfirmationSchema, passwordSchema, secretPasswordSchema } from '../common/passwordSchema';
import { phoneSchema } from '../common/phoneSchema';
import { otpSchema } from '../common/otpSchema';

export * from './configs';
export * from './asset';
export * from './benchmark';
export * from './fundGeographicalFocus';
export * from './fundManager';
export * from './fundAssetClass';
export * from './fundValuationDay';
export * from './fundValuationFrequency';
export * from './indexFund';
export * from './userGroup';
export * from './orderAttachment';
export * from './userAddress';
export * from './manualTransfer';
export * from './withdrawRequestRejection';
export * from './productType';
export * from './userFeeDeductionRequestRejection';
export * from './batchAttachment';

export const formSchema = yup.object({
  [formFields.email]: emailSchema,
  [formFields.password]: passwordSchema,
});

export const loginFormSchema = yup.object({
  [formFields.email]: madkholSecretEmailSchema,
  [formFields.password]: secretPasswordSchema,
});

export const getLoginFormSchema = (otpRequired = false) =>
  yup.object({
    ...(otpRequired ? { [formFields.otp]: otpSchema } : {}),
    [formFields.email]: madkholSecretEmailSchema,
    [formFields.password]: secretPasswordSchema,
  });

export const forgotPasswordFormSchema = yup.object({
  [formFields.email]: madkholSecretEmailSchema,
});

export const restorePasswordFormSchema = yup.object({
  [formFields.password]: secretPasswordSchema,
  [formFields.passwordConfirmation]: passwordConfirmationSchema,
  [formFields.token]: yup
    .string()
    .min(6, 'Token should be of minimum 6 characters length')
    .required('Token is required')
    .trim(),
});

const baseEmployeeFormSchema = {
  [employeeFormFields.email]: madkholEmailSchema,
  [employeeFormFields.name]: yup
    .string()
    .max(50, 'Name must be at most 50 characters')
    .required('Name is required')
    .trim(),
  [employeeFormFields.status]: yup.mixed<EmployeeStatus>().oneOf(Object.values(EmployeeStatus)).required(),
  [employeeFormFields.phone]: phoneSchema,
  [employeeFormFields.role]: yup.string().required('Role is required').trim(),
};

export const editEmployeeFormSchema = yup.object(baseEmployeeFormSchema);

export const createEmployeeFormSchema = yup.object({
  ...baseEmployeeFormSchema,
  [employeeFormFields.password]: passwordSchema,
  [employeeFormFields.passwordConfirmation]: passwordConfirmationSchema,
});

const getPortfolioFormBase = (cashAllocationPercentage: number) => ({
  [portfolioFormFields.nameEn]: yup
    .string()
    .max(100, 'Name En must be at most 100 characters')
    .required('Name En is required')
    .trim(),
  [portfolioFormFields.nameAr]: yup
    .string()
    .max(100, 'Name Ar must be at most 100 characters')
    .required('Name Ar is required')
    .trim(),
  [portfolioFormFields.descriptionEn]: yup
    .string()
    .max(250, 'Description En must be at most 250 characters')
    .required('Description En is required')
    .trim(),
  [portfolioFormFields.descriptionAr]: yup
    .string()
    .max(250, 'Description Ar must be at most 250 characters')
    .required('Description Ar is required')
    .trim(),
  [portfolioFormFields.objectiveEn]: yup
    .string()
    .max(100, 'Objective En must be at most 100 characters')
    .required('Objective En is required')
    .trim(),
  [portfolioFormFields.objectiveAr]: yup
    .string()
    .max(100, 'Objective Ar must be at most 100 characters')
    .required('Objective Ar is required')
    .trim(),
  [portfolioFormFields.shariaaComplaint]: yup.boolean().required('Shariaa Complaint is required'),
  [portfolioFormFields.status]: yup.number().positive().min(1, 'Status is required').required('Status is required'),
  [portfolioFormFields.currency]: yup
    .number()
    .positive()
    .min(1, 'Currency is required')
    .required('Currency is required'),
  [portfolioFormFields.manager]: yup.number().positive().min(1, 'Manager is required').required('Manager is required'),
  [portfolioFormFields.files]: yup.array().of(
    yup.object().shape({
      portfolioFileType: yup.number().positive().required('File Type is required'),
      fileId: yup.string().required('File is required'),
      isPublic: yup.boolean().required('Is public is required'),
    }),
  ),
  [portfolioFormFields.expectedMinimumReturn]: yup
    .string()
    .trim()
    .matches(/(?<=^| )\d+(\.\d+)?(?=$| )/, 'Expected Minimum Return must be a number between 0 and 100%')
    .test('min', 'Expected Minimum Return must be a number between 0 and 100%', (value) =>
      Boolean(value && +value >= 0),
    )
    .test('max', 'Expected Minimum Return must be a number between 0 and 100%', (value) =>
      Boolean(value && +value <= 100),
    )
    .required('Expected Minimum Return is required'),
  [portfolioFormFields.targetExpectedReturn]: yup
    .string()
    .trim()
    .matches(/(?<=^| )\d+(\.\d+)?(?=$| )/, 'Target Expected Return must be a number between 0 and 100%')
    .test('min', 'Target Expected Return must be a number between 0 and 100%', (value) => Boolean(value && +value >= 0))
    .test('max', 'Target Expected Return must be a number between 0 and 100%', (value) =>
      Boolean(value && +value <= 100),
    )
    .required('Target Expected Return is required'),
  [portfolioFormFields.expectedMaximumReturn]: yup
    .string()
    .trim()
    .matches(/(?<=^| )\d+(\.\d+)?(?=$| )/, 'Expected Maximum Return must be a number between 0 and 100%')
    .test('min', 'Expected Maximum Return must be a number between 0 and 100%', (value) =>
      Boolean(value && +value >= 0),
    )
    .test('max', 'Expected Maximum Return must be a number between 0 and 100%', (value) =>
      Boolean(value && +value <= 100),
    )
    .required('Expected Maximum Return is required'),
  [portfolioFormFields.targetExpectedRisk]: yup
    .string()
    .trim()
    .matches(/(?<=^| )\d+(\.\d+)?(?=$| )/, 'Target Expected Risk must be a number between 0 and 100%')
    .test('min', 'Target Expected Risk must be a number between 0 and 100%', (value) => Boolean(value && +value >= 0))
    .test('max', 'Target Expected Risk must be a number between 0 and 100%', (value) => Boolean(value && +value <= 100))
    .required('Target Expected Risk is required'),
  [portfolioFormFields.benchmark]: yup
    .number()
    .positive()
    .min(1, 'Benchmark is required')
    .required('Benchmark is required'),
  [portfolioFormFields.sharpRatio]: yup
    .string()
    .trim()
    .matches(/(?<=^| )\d+(\.\d+)?(?=$| )/, 'Sharp Ratio must be a number between 0 and 1%')
    .test('min', 'Sharp Ratio must be a number between 0 and 1%', (value) => Boolean(value && +value >= 0))
    .test('max', 'Sharp Ratio must be a number between 0 and 1%', (value) => Boolean(value && +value <= 100))
    .required('Sharp Ratio is required'),
  [portfolioFormFields.associatedIndexFunds]: yup
    .array()
    .of(
      yup.object().shape({
        indexFund: yup.number().positive().required('Index Fund is required'),
        weight: yup
          .number()
          .min(0.01, 'Weight should be of minimum 0.01%')
          .max(100, 'Weight should be of maximum 100%')
          .required('Weight is required'),
      }),
    )
    .min(1, 'Funds are required')
    .unique('Duplicated associated index fund', 'indexFund')
    .sumMin('Sum of weight should be 100', 'weight', 100 - cashAllocationPercentage)
    .sumMax('Sum of weight should be 100', 'weight', 100 - cashAllocationPercentage),
});

export const getPortfolioFormSchema = (cashAllocationPercentage: number) =>
  yup.object({
    ...getPortfolioFormBase(cashAllocationPercentage),
    [portfolioFormFields.userCategory]: yup
      .array()
      .of(yup.number())
      .min(1, 'User Category is required')
      .required('User Category is required'),
  });

export const getSubscribedPortfolioFormSchema = (cashAllocationPercentage: number) =>
  yup.object(getPortfolioFormBase(cashAllocationPercentage));

export const userCategoryFormSchema = yup.object({
  [userCategoryFormFields.nameEn]: yup.string().required('Name En is required').trim(),
  [userCategoryFormFields.nameAr]: yup.string().required(' Name Ar is required').trim(),
  [userCategoryFormFields.descriptionEn]: yup.string().required('Description En is required').trim(),
  [userCategoryFormFields.descriptionAr]: yup.string().required('Description Ar is required').trim(),
  [userCategoryFormFields.minScore]: yup.number().integer().min(0).max(100).required('Min Score is required'),
  [userCategoryFormFields.maxScore]: yup
    .number()
    .integer()
    .min(0)
    .max(100)
    .moreThan(yup.ref(userCategoryFormFields.minScore), 'Max Score must be greater than Min Score')
    .required('Max Score is required'),
});

export const getFeeConfigFormSchema = () =>
  yup.object({
    [feeConfigFormFields.formula]: yup.string().required('Formula is required').trim(),
    [feeConfigFormFields.percentage]: yup.number().required('Percentage is required'),
    [feeConfigFormFields.minSubscription]: yup.number().required('Min Subscription is required'),
    [feeConfigFormFields.vat]: yup.number().required('VAT is required'),
    [portfolioFormFields.status]: yup.number().positive().min(1, 'Status is required').required('Status is required'),
    [feeConfigFormFields.workDaysOnly]: yup.boolean(),
    [feeConfigFormFields.productTypeId]: yup.number().required('Product Type Id is required'),
    [feeConfigFormFields.calculationPeriodicity]: yup.string().required('Calculation Periodicity is required'),
    [feeConfigFormFields.deductionPeriodicity]: yup.string().required('Deduction Periodicity is required'),
  });

const baseUserFormSchema = {
  [userFormFields.mobile]: phoneSchema,
  [userFormFields.fullName]: yup
    .string()
    .max(150, 'Name must be at most 150 characters')
    .required('Full Name is required')
    .trim(),
  [userFormFields.status]: yup.number().positive().required('Status is required'),
  [userFormFields.group]: yup.number().positive().required('Group is required'),
};

export const editUserFormSchema = yup.object(baseUserFormSchema);

export const createUserFormSchema = yup.object({
  ...baseUserFormSchema,
  [userFormFields.email]: emailSchema,
});

export const editKycFormSchema = yup.object({
  [kycFormFields.fullNameEn]: yup
    .string()
    .max(150, 'Full Name must be at most 150 characters')
    .required('Full Name is required')
    .trim(),
  [kycFormFields.fullNameAr]: yup
    .string()
    .max(150, 'Full Name AR must be at most 150 characters')
    .required('Full Name is required')
    .trim(),
  [kycFormFields.firstNameEn]: yup
    .string()
    .max(100, 'First Name must be at most 100 characters')
    .required('First Name is required')
    .trim(),
  [kycFormFields.firstNameAr]: yup
    .string()
    .max(100, 'First Name AR must be at most 100 characters')
    .required('First Name AR is required')
    .trim(),
  [kycFormFields.fatherNameAr]: yup
    .string()
    .max(100, 'Father Name Ar must be at most 100 characters')
    .required('Father Name Ar is required')
    .trim(),
  [kycFormFields.fatherNameEn]: yup
    .string()
    .max(100, 'Father Name En must be at most 100 characters')
    .required('Father Name En is required')
    .trim(),
  [kycFormFields.grandFatherNameAr]: yup
    .string()
    .max(100, 'Grand Father Name Ar must be at most 100 characters')
    .required('Grand Father Name Ar is required')
    .trim(),
  [kycFormFields.grandFatherNameEn]: yup
    .string()
    .max(100, 'Grand Father Name En must be at most 100 characters')
    .required('Grand Father Name En is required')
    .trim(),
  [kycFormFields.familyNameEn]: yup
    .string()
    .max(100, 'Family Name must be at most 100 characters')
    .required('Family Name is required')
    .trim(),
  [kycFormFields.familyNameAr]: yup
    .string()
    .max(100, 'Family Name AR must be at most 100 characters')
    .required('Family Name AR is required')
    .trim(),
  [kycFormFields.type]: yup.number().positive().required('Type is required'),
  [kycFormFields.nationalId]: yup
    .string()
    .length(10, 'Must be exactly 10 digits')
    .matches(/^\d+$/, 'National Id  must be a number')
    .required('National Id is required')
    .trim(),
  [kycFormFields.nationalityEn]: yup.string().required('Nationality is required').trim(),
  [kycFormFields.nationalityAr]: yup.string().required('Nationality is required').trim(),
  [kycFormFields.country]: yup.string().required('Country is required').trim(),
  [kycFormFields.email]: emailSchema,
  [kycFormFields.phone]: phoneSchema,
  [kycFormFields.status]: yup.number().positive().required('Status is required'),
  [kycFormFields.address]: yup
    .string()
    .max(300, 'Address must be at most 300 characters')
    .required('Address is required')
    .trim(),
  [kycFormFields.birthDate]: yup.date().nullable().required('Birth Date is required'),
  [kycFormFields.gender]: yup.number().positive().required('Gender is required'),
  [kycFormFields.idIssueDate]: yup.date().nullable().required('Id Issue Date is required'),
  [kycFormFields.idExpiryDate]: yup.date().nullable().required('Id Expiry Date is required'),
  [kycFormFields.maritalStatus]: yup.string().trim(),
  [kycFormFields.educationLevel]: yup.string().trim(),
  [kycFormFields.companyName]: yup.string().trim(),
  [kycFormFields.employerAddress]: yup.string().trim(),
  [kycFormFields.issueLocationAr]: yup.string().trim(),
  [kycFormFields.issueLocationEn]: yup.string().trim(),
  [kycFormFields.assuranceLevel]: yup.string().trim(),
  [kycFormFields.language]: yup.string().trim(),
  [kycFormFields.fiveYearsFinancialSector]: yup.boolean(),
  [kycFormFields.fiveYearsFinancialSectorDescription]: yup
    .string()
    .trim()
    .when('fiveYearsFinancialSector', {
      is: true,
      then: (schema) => schema.required('Five Years Financial Sector Description is required'),
    }),
  [kycFormFields.boardDirectorsMember]: yup.boolean(),
  [kycFormFields.boardDirectorsMemberDescription]: yup
    .string()
    .trim()
    .when('boardDirectorsMember', {
      is: true,
      then: (schema) => schema.required('Board Directors Member Description is required'),
    }),
  [kycFormFields.individualWithHighProfilePoliticalRole]: yup.boolean(),
  [kycFormFields.individualWithHighProfilePoliticalRoleDescription]: yup
    .string()
    .trim()
    .when('individualWithHighProfilePoliticalRole', {
      is: true,
      then: (schema) => schema.required('Individual With High Profile Political Role Description is required'),
    }),
  [kycFormFields.notBeneficialOwner]: yup.boolean(),
  [kycFormFields.notBeneficialOwnerDescription]: yup
    .string()
    .trim()
    .when('notBeneficialOwner', {
      is: true,
      then: (schema) => schema.required('Not Beneficial Owner Description is required'),
    }),
  [kycFormFields.iqamaExpiryDate]: yup
    .date()
    .nullable()
    .when('nationality', {
      is: (nationality) => nationality === 'SA',
      then: (schema) => schema.required('Iqama Expiry Date is required'),
    }),
});

export const roleFormSchema = yup.object({
  [roleFormFields.name]: yup.string().required('Role Name is required').trim(),
});

export const currencyFormSchema = yup.object({
  [currencyFormFields.name]: yup.string().required('Currency Name is required').trim(),
  [currencyFormFields.code]: yup.string().required('Currency code is required').trim(),
});

export const actionFormSchema = yup.object({
  [actionFormFields.name]: yup.string().required('Action Name is required').trim(),
  [actionFormFields.type]: yup.string().required('Type is required').trim(),
  [actionFormFields.resource]: yup.number().positive().required('Resource is required'),
});

export const resourceFormSchema = yup.object({
  [resourceFormFields.name]: yup.string().required('Resource Name is required').trim(),
});

export const addUserAttachmentFormSchema = yup.object({
  [addUserAttachmentFormFields.documentType]: yup.number().positive().required('Document type is required'),
  [addUserAttachmentFormFields.documentLink]: yup.string().required('Document Link is required').trim(),
});

export const rolePermissionFormSchema = yup.object({
  [rolePermissionFormFields.attributes]: yup.array().of(yup.string()).required('Attributes are required'),
  [rolePermissionFormFields.role]: yup.number().positive().required('Role is required'),
  [rolePermissionFormFields.action]: yup.number().positive().required('Action is required'),
});

export const addFundsFormSchema = yup.object({
  [addFundsFormFields.amount]: yup.number().min(1).positive().required('Amount is required'),
  [addFundsFormFields.debitAccount]: yup.string().required('Debit Account is required').trim(),
});

export const restoreAggregatedStatisticsFormSchema = yup.object({
  [restoreAggregatedStatisticsFormFields.date]: yup.date().nullable().required('Date is required'),
});
