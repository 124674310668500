import React from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useSearchParamsState } from '../../hooks';
import { appConfigSelector } from '../../redux/modules/appConfig/appConfig.selectors';
import { IndexFundNavTab } from './IndexFundNavTab';
import { IndexFundNavHistoryTab } from './IndexFundNavHistoryTab';

const mapTabParamToValue: { [prop: string]: string } = {
  navs: '1',
  history: '2',
  default: '1',
};
const mapTabValueToTabParam: { [prop: string]: string } = {
  1: 'navs',
  2: 'history',
};

export function IndexFundNav() {
  const [selectedTab, setSelectedTab] = useSearchParamsState('tab', 'default');

  const { fetchStatus, updateStatus } = useSelector(appConfigSelector);

  const isLoading = fetchStatus === fetchStatuses.pending || updateStatus === fetchStatuses.pending;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(mapTabValueToTabParam[newValue]);
  };

  return (
    <Container component="main" maxWidth="xl">
      <TabContext value={mapTabParamToValue[selectedTab]}>
        <Box>
          <TabList
            value={mapTabParamToValue[selectedTab]}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="index fund tabs"
          >
            <Tab
              icon={<AttachMoneyIcon />}
              iconPosition="start"
              label="Navs"
              value="1"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
            <Tab
              icon={<PriceCheckIcon />}
              iconPosition="start"
              label="History"
              value="2"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
              disabled={isLoading}
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <IndexFundNavTab />
        </TabPanel>
        <TabPanel value="2">
          <IndexFundNavHistoryTab />
        </TabPanel>
      </TabContext>
    </Container>
  );
}
