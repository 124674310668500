import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { FeeBatchExecutionStatus } from '../../types/feeBatch';

export const feeBatchExecutionStatusToInfo: {
  [prop in FeeBatchExecutionStatus]: {
    color: 'error' | 'info' | 'success' | 'warning';
    icon: React.ElementType;
    menuAction: string;
  };
} = {
  [FeeBatchExecutionStatus.EXECUTION]: {
    color: 'info',
    menuAction: 'To Execution',
    icon: InfoIcon,
  },
  [FeeBatchExecutionStatus.IN_REVIEW]: {
    color: 'info',
    menuAction: 'To Review',
    icon: InfoIcon,
  },
  [FeeBatchExecutionStatus.RECONCILIATION]: {
    color: 'warning',
    menuAction: 'To Reconciliation',
    icon: WarningIcon,
  },
  [FeeBatchExecutionStatus.APPROVED]: {
    color: 'success',
    menuAction: 'Change to waiting cast approval',
    icon: CheckCircleIcon,
  },
};
