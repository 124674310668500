import {
  CreateFeesConfigRequest,
  CreateFeesConfigResponse,
  FetchFeesConfigResponse,
  FetchFeesConfigsResponse,
  UpdateFeesConfigRequest,
  UpdateFeesConfigResponse,
  UpdateFeesConfigsRequest,
} from '../types/feesConfig';
import { ApiService } from './common/ApiService';

class FeesConfigService extends ApiService {
  pathname = 'admin/fees-config';

  fetchFeesConfigs = async () => this.get<FetchFeesConfigsResponse>(`${this.pathname}`);

  fetchFeesConfig = async (key: string) => this.get<FetchFeesConfigResponse>(`${this.pathname}/${key}`);

  deleteFeesConfig = async (key: string) => this.delete<FetchFeesConfigResponse>(`${this.pathname}/${key}`);

  createFeesConfig = async (action: CreateFeesConfigRequest) =>
    this.post<CreateFeesConfigResponse, CreateFeesConfigRequest>(`${this.pathname}`, action);

  updateFeesConfig = async ({ key, ...action }: UpdateFeesConfigRequest) =>
    this.put<UpdateFeesConfigResponse, Omit<UpdateFeesConfigRequest, 'key'>>(`${this.pathname}/${key}`, action);

  updateFeesConfigs = async (data: UpdateFeesConfigRequest[]) =>
    this.put<FetchFeesConfigsResponse, UpdateFeesConfigsRequest>(`${this.pathname}`, { items: data });
}

export const feesConfigService = new FeesConfigService();
