import { createDraftSafeSelector, createSelector } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { ReducerState } from '../../store.types';

export const mediaSelector = ({ media }: ReducerState) => media;
export const mediaCacheSelector = createDraftSafeSelector(mediaSelector, ({ cache }) => cache);
export const mediaLoadingSelector = createSelector(
  mediaSelector,
  ({ fetchStatus }) => fetchStatus === fetchStatuses.pending,
);
