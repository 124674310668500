import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { UserFeeValidationStatus } from '../../types/userFeeValidation';

export const userFeeValidationStatusToInfo: {
  [prop in UserFeeValidationStatus]: {
    color: 'error' | 'info' | 'success' | 'warning';
    icon: React.ElementType;
    menuAction: string;
  };
} = {
  [UserFeeValidationStatus.PENDING]: {
    color: 'info',
    menuAction: 'pending',
    icon: InfoIcon,
  },
  [UserFeeValidationStatus.MATCHED]: {
    color: 'success',
    menuAction: 'Matched',
    icon: CheckCircleIcon,
  },
  [UserFeeValidationStatus.MINOR_DIFFERENCE]: {
    color: 'warning',
    menuAction: 'Minor Difference',
    icon: WarningIcon,
  },
  [UserFeeValidationStatus.MATERIAL_DIFFERENCE]: {
    color: 'error',
    menuAction: 'Material Difference',
    icon: CheckCircleIcon,
  },
};
