import { ApiResponse } from './api';
import { Resource } from './resource';

export enum ActionType {
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}

export type Action = {
  id: number;
  name: string;
  type: ActionType;
  resource: Resource;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchActionResponse = ApiResponse<Action>;
export type FetchActionsResponse = ApiResponse<{ items: Action[]; totalItems: number }>;
export type FetchActionsAutocompleteResponse = ApiResponse<Action[]>;

export type CreateActionRequest = Pick<Action, 'name'> & { type: string; resource: number };
export type CreateActionResponse = ApiResponse<Action>;

export type UpdateActionRequest = Pick<Action, 'id' | 'name'> & { type: string; resource: number };
export type UpdateActionResponse = ApiResponse<Action>;
