import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchUserCategoryResponse, FetchUserCategorysResponse } from '../../../types/userCategory';
import {
  fetchUserCategory,
  fetchUserCategorys,
  resetEditUserCategory,
  cleanUserCategorys,
  updateUserCategory,
  createUserCategory,
  deleteUserCategory,
} from './userCategory.actions';
import { UserCategoryState } from './userCategory.types';

const initialState: UserCategoryState = {
  userCategorys: [],
  totalUserCategorys: 0,
  editUserCategory: null,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const userCategorysSlice = createSlice({
  name: 'userCategorys',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserCategorys.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchUserCategorys.fulfilled, (state, action: PayloadAction<FetchUserCategorysResponse>) => {
      state.userCategorys = action.payload.data?.items;
      state.totalUserCategorys = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserCategorys.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserCategory.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchUserCategory.fulfilled, (state, action: PayloadAction<FetchUserCategoryResponse>) => {
      state.editUserCategory = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserCategory.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateUserCategory.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateUserCategory.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateUserCategory.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createUserCategory.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(createUserCategory.fulfilled, (state, action: PayloadAction<FetchUserCategoryResponse>) => {
      state.editUserCategory = action.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createUserCategory.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteUserCategory.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteUserCategory.fulfilled, (state) => {
      state.editUserCategory = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteUserCategory.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditUserCategory.type, (state) => {
      state.editUserCategory = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanUserCategorys.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const userCategoryReducer = userCategorysSlice.reducer;
