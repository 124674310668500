import AccountBoxIcon from '@mui/icons-material/AccountBox';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParamsState } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import { cleanMonitoring } from '../../redux/modules/monitoring/monitoring.actions';
import { monitoringSelector } from '../../redux/modules/monitoring/monitoring.selectors';
import { resetUserApiStatuses } from '../../redux/modules/user/user.actions';
import { QueueStatusTab } from './QueueStatusTab';
import { ServiceVersionsTab } from './ServiceVersionsTab';

const mapTabParamToValue: Record<string, string> = {
  serviceVersions: '1',
  queueStatus: '2',
  default: '1',
};

const mapTabValueToTabParam: Record<string, string> = {
  1: 'serviceVersions',
  2: 'queueStatus',
};

export const Dashboard = () => {
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useSearchParamsState('tab', 'default');

  const { error } = useSelector(monitoringSelector);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(resetUserApiStatuses());
    setSelectedTab(mapTabValueToTabParam[newValue]);
  };

  useEffect(
    () => () => {
      dispatch(cleanMonitoring);
    },
    [],
  );

  return (
    <Container component="main" maxWidth="xl">
      {error && <Alert severity="error">{error.message}</Alert>}

      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">Dashboard &nbsp;</Typography>
      </Box>

      <TabContext value={mapTabParamToValue[selectedTab]}>
        <Box>
          <TabList
            value={mapTabParamToValue[selectedTab]}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="Dashbaord tabs"
          >
            <Tab
              icon={<AccountBoxIcon />}
              iconPosition="start"
              label="Service Versions"
              value="1"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
            />
            <Tab
              icon={<PersonSearchIcon />}
              iconPosition="start"
              label="Queue Status"
              value="2"
              sx={{ textTransform: 'capitalize' }}
              disableRipple
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <ServiceVersionsTab />
        </TabPanel>
        <TabPanel value="2">
          <QueueStatusTab />
        </TabPanel>
      </TabContext>
    </Container>
  );
};
