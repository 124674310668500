import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AttachmentIcon from '@mui/icons-material/Attachment';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import InsightsIcon from '@mui/icons-material/Insights';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import React from 'react';
import { useSearchParamsState } from '../../hooks';
import { FeeCalculation } from '../../types/feeCalculation';
import { AttachmentsTab } from './AttachmentsTab';
import { ConsolidateFeeRequestsTab } from './ConsolidateFeeRequestsTab';
import { FeeCalculationsTab } from './FeeCalculationsTab';
import { FeeValidationTab } from './FeeValidationTab';
import { FeeBatchTab } from './FeeBatchTab';
import { DeductionsTab } from './DeductionsTab';

const mapTabParamToValue: Record<string, string> = {
  feeBatch: '1',
  feeCalculations: '2',
  feeDeductions: '3',
  feeValidations: '4',
  consolidateRequests: '5',
  attachments: '6',
  default: '1',
};
const mapTabValueToTabParam: Record<string, string> = {
  1: 'feeBatch',
  2: 'feeCalculations',
  3: 'feeDeductions',
  4: 'feeValidations',
  5: 'consolidateRequests',
  6: 'attachments',
};

interface PortfolioOrderTabListProps {
  batchId?: string;
  feeCalculationsKey: string;
  onSelectFeeCalculations: (ids: FeeCalculation[]) => void;
}

export function FeeBatchTabList(props: PortfolioOrderTabListProps) {
  const { batchId, feeCalculationsKey, onSelectFeeCalculations } = props;

  const [selectedTab, setSelectedTab] = useSearchParamsState('tab', 'default');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(mapTabValueToTabParam[newValue]);
  };

  if (!batchId) {
    return <div>Loading...</div>;
  }

  return (
    <TabContext value={mapTabParamToValue[selectedTab]}>
      <Box sx={{ width: '100%' }}>
        <TabList
          value={mapTabParamToValue[selectedTab]}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="Fee Batch tabs"
        >
          <Tab
            icon={<AccountBoxIcon />}
            iconPosition="start"
            label="Fee Batch"
            value="1"
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<PersonSearchIcon />}
            iconPosition="start"
            label="Fee Calcultions"
            value="2"
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<HistoryEduIcon />}
            iconPosition="start"
            label="Fee Deduction"
            value="3"
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<HistoryEduIcon />}
            iconPosition="start"
            label="Fee Validation"
            value="4"
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<HistoryEduIcon />}
            iconPosition="start"
            label="Consolidate Requests"
            value="5"
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
          <Tab
            icon={<AttachmentIcon />}
            iconPosition="start"
            label="Attachments"
            value="6"
            sx={{ textTransform: 'capitalize' }}
            disableRipple
          />
        </TabList>
      </Box>
      <TabPanel value="1">
        <FeeBatchTab batchId={batchId} />
      </TabPanel>
      <TabPanel value="2">
        <FeeCalculationsTab
          key={feeCalculationsKey}
          onSelectFeeCalculations={onSelectFeeCalculations}
          batchId={batchId}
        />
      </TabPanel>
      <TabPanel value="3">
        <DeductionsTab batchId={batchId} />
      </TabPanel>
      <TabPanel value="4">
        <FeeValidationTab batchId={batchId} />
      </TabPanel>
      <TabPanel value="5">
        <ConsolidateFeeRequestsTab batchId={batchId} />
      </TabPanel>
      <TabPanel value="6">
        <AttachmentsTab batchId={batchId} />
      </TabPanel>
    </TabContext>
  );
}
