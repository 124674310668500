import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchFundValuationFrequencyResponse,
  FetchFundValuationFrequenciesAutocompleteResponse,
  FetchFundValuationFrequenciesResponse,
} from '../../../types/fundValuationFrequency';
import {
  fetchFundValuationFrequency,
  fetchFundValuationFrequencies,
  resetEditFundValuationFrequency,
  cleanFundValuationFrequencies,
  updateFundValuationFrequency,
  createFundValuationFrequency,
  deleteFundValuationFrequency,
  fetchFundValuationFrequenciesAutocomplete,
} from './fundValuationFrequency.actions';
import { FundValuationFrequencyState } from './fundValuationFrequency.types';

const initialState: FundValuationFrequencyState = {
  fundValuationFrequencies: [],
  fundValuationFrequenciesAutocomplete: [],
  totalFundValuationFrequencies: 0,
  editFundValuationFrequency: null,
  fetchAutocompleteStatus: fetchStatuses.idle,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const fundValuationFrequenciesSlice = createSlice({
  name: 'fundValuationFrequencies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFundValuationFrequencies.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchFundValuationFrequencies.fulfilled,
      (state, action: PayloadAction<FetchFundValuationFrequenciesResponse>) => {
        state.fundValuationFrequencies = action.payload.data?.items;
        state.totalFundValuationFrequencies = action.payload.data?.totalItems;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFundValuationFrequencies.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFundValuationFrequenciesAutocomplete.pending, (state) => {
      state.fetchAutocompleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchFundValuationFrequenciesAutocomplete.fulfilled,
      (state, action: PayloadAction<FetchFundValuationFrequenciesAutocompleteResponse>) => {
        state.fundValuationFrequenciesAutocomplete = action.payload.data;
        state.fetchAutocompleteStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFundValuationFrequenciesAutocomplete.rejected, (state, action) => {
      state.fetchAutocompleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFundValuationFrequency.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchFundValuationFrequency.fulfilled,
      (state, action: PayloadAction<FetchFundValuationFrequencyResponse>) => {
        state.editFundValuationFrequency = action.payload.data;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFundValuationFrequency.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateFundValuationFrequency.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateFundValuationFrequency.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateFundValuationFrequency.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createFundValuationFrequency.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(
      createFundValuationFrequency.fulfilled,
      (state, action: PayloadAction<FetchFundValuationFrequencyResponse>) => {
        state.editFundValuationFrequency = action.payload.data;
        state.createStatus = fetchStatuses.success;
      },
    );
    builder.addCase(createFundValuationFrequency.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteFundValuationFrequency.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteFundValuationFrequency.fulfilled, (state) => {
      state.editFundValuationFrequency = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteFundValuationFrequency.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditFundValuationFrequency.type, (state) => {
      state.editFundValuationFrequency = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanFundValuationFrequencies.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const fundValuationFrequencyReducer = fundValuationFrequenciesSlice.reducer;
