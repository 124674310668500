import { ActionType } from '../../types/action';

const listPermission = {
  resource: 'report-portfolio-order-execution',
  action: ActionType.READ,
};

export const ReportPermission = {
  list: listPermission,
};
