import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { benchmarkFormFields } from '../../forms/fields/formFields';
import { benchmarkFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import { createBenchmark, updateBenchmark } from '../../redux/modules/benchmark/benchmark.actions';
import { benchmarkSelector } from '../../redux/modules/benchmark/benchmark.selectors';
import { Benchmark } from '../../types/benchmark';

interface BenchmarkDialogProps {
  open: boolean;
  initialValue: Benchmark | null;
  onClose: (isReload: boolean) => void;
}

export function BenchmarkDialog({ open, initialValue, onClose }: BenchmarkDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, values, handleChange, touched, errors, submitForm, setValues, resetForm } = useFormik({
    initialValues: {
      [benchmarkFormFields.nameEn]: '',
      [benchmarkFormFields.nameAr]: '',
    },
    validationSchema: benchmarkFormSchema,
    onSubmit: (formValues) => {
      if (initialValue?.id) {
        dispatch(updateBenchmark({ id: initialValue.id, ...formValues }));
      } else {
        dispatch(createBenchmark(formValues));
      }
    },
  });
  const { createStatus, updateStatus, error } = useSelector(benchmarkSelector);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    setValues({
      nameEn: initialValue?.nameEn || '',
      nameAr: initialValue?.nameAr || '',
    });
  }, [initialValue]);

  useEffect(() => {
    if (createStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Benchmark created!', { variant: 'success' });
    }
    if (createStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Benchmark creation error!', { variant: 'error' });
    }

    if (updateStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Benchmark updated!', { variant: 'success' });
    }
  }, [createStatus, updateStatus]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{initialValue?.id ? 'Edit benchmark' : 'Create a new Benchmark'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error && <Alert severity="error">{error.message}</Alert>}
          Enter the Benchmark name
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="nameEn"
            name={benchmarkFormFields.nameEn}
            label="Name En"
            value={values[benchmarkFormFields.nameEn]}
            onChange={handleChange}
            error={touched.nameEn && !!errors.nameEn}
            helperText={touched.nameEn && errors.nameEn}
          />
          <TextField
            dir="rtl"
            margin="normal"
            required
            fullWidth
            id="nameAr"
            name={benchmarkFormFields.nameAr}
            label="Name Ar"
            value={values[benchmarkFormFields.nameAr]}
            onChange={handleChange}
            error={touched.nameAr && !!errors.nameAr}
            helperText={touched.nameAr && errors.nameAr}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={createStatus === fetchStatuses.pending}
          loadingPosition="end"
          onClick={submitForm}
        >
          {initialValue?.id ? 'Save' : 'Create'}
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
