import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import uuid4 from 'uuid4';
import Container from '@mui/material/Container';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { GridFilterItem } from '@mui/x-data-grid-premium';
import { MTabPanel } from '../../components/TabPanel';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useSearchParamsState } from '../../hooks';
import { appConfigSelector } from '../../redux/modules/appConfig/appConfig.selectors';
import { PortfolioOrderTable } from './PortfolioOrderTable';
import { UserPortfolioRequestType } from '../../types/userPortfolioRequest';
import QuickFilter from '../../components/QuickFilter';
import { authSelector } from '../../redux/features/auth/auth.selectors';

const mapTabParamToValue: { [prop: string]: string } = {
  subscription: '1',
  redemption: '2',
  default: '1',
};
const mapTabValueToTabParam: { [prop: string]: string } = {
  1: 'subscription',
  2: 'redemption',
};

export function PortfolioOrderList() {
  const [selectedTab, setSelectedTab] = useSearchParamsState('tab', 'default');

  const { fetchStatus, updateStatus } = useSelector(appConfigSelector);

  const isLoading = fetchStatus === fetchStatuses.pending || updateStatus === fetchStatuses.pending;

  const [products, setProducts] = useState([]);
  const [fundCategories, setFundCategories] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [selectedFundCategories, setSelectedFundCategories] = useState<string[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
  const [initiateFilters, setInitiateFilters] = useState<boolean>(true);
  const [productsExternalFilter, setProductsExternalFilter] = useState<GridFilterItem | object>({});
  const [fundCategoriesExternalFilter, setFundCategoriesExternalFilter] = useState<GridFilterItem | object>({});
  const [statusesExternalFilter, setStatusesExternalFilter] = useState<GridFilterItem | object>({});
  const { user } = useSelector(authSelector);
  let filters = localStorage.getItem(`portfolioOrderListFiltersSelection${mapTabParamToValue[selectedTab]}`);

  const setDataToStorage = (data, currentTab) => {
    if (!user?.id || initiateFilters) return;
    const parsedFilters = filters ? JSON.parse(filters)[user?.id] : {};

    localStorage.setItem(
      `portfolioOrderListFiltersSelection${mapTabParamToValue[currentTab || selectedTab]}`,
      JSON.stringify({
        [user?.id]: { ...parsedFilters, ...data },
      }),
    );
  };

  const onSelectProduct = (filter, currentTab = '', changeTab = false) => {
    switch (filter) {
      case 'Select All':
        // eslint-disable-next-line no-case-declarations
        const allProducts = products.filter((item) => item !== 'Select All' && item !== 'Clear All');
        setSelectedProducts(allProducts);
        if (currentTab) setDataToStorage({ selectedProducts: allProducts }, currentTab);
        setProductsExternalFilter({
          field: 'userPortfolio.subscribedPortfolio.productType.nameEn',
          operator: 'isAnyOf',
          id: uuid4(),
          value: allProducts,
        });
        break;

      case 'Clear All':
        setSelectedProducts([]);
        if (currentTab) setDataToStorage({ selectedProducts: [] }, currentTab);

        setProductsExternalFilter({});
        break;

      default: {
        let newSelectedProducts = selectedProducts.includes(filter)
          ? selectedProducts.filter((item) => item !== filter)
          : [...selectedProducts, filter];
        if (currentTab) setDataToStorage({ selectedProducts: newSelectedProducts }, currentTab);
        else if (selectedProducts.length) newSelectedProducts = [filter];
        else newSelectedProducts = [...selectedProducts, filter];

        setSelectedProducts(newSelectedProducts);
        setTimeout(
          () => {
            setProductsExternalFilter({
              field: 'userPortfolioRequest.userPortfolio.subscribedPortfolio.productType.nameEn',
              operator: 'isAnyOf',
              id: uuid4(),
              value: newSelectedProducts,
            });
          },
          changeTab ? 3500 : 0,
        );
      }
    }
  };

  const onSelectFundCategory = (filter, currentTab = '', changeTab = false) => {
    switch (filter) {
      case 'Select All': {
        const allFundCategories = fundCategories.filter((item) => item !== 'Select All' && item !== 'Clear All');
        setSelectedFundCategories(allFundCategories);
        if (currentTab) setDataToStorage({ selectedFundCategories: allFundCategories }, currentTab);
        setFundCategoriesExternalFilter({
          field: 'fundCategory.name',
          operator: 'isAnyOf',
          id: uuid4(),
          value: allFundCategories,
        });
        break;
      }

      case 'Clear All':
        setSelectedFundCategories([]);
        if (currentTab) setDataToStorage({ selectedFundCategories: [] }, currentTab);
        setFundCategoriesExternalFilter({});
        break;

      default: {
        let newSelectedFundCategories = selectedFundCategories.includes(filter)
          ? selectedFundCategories.filter((item) => item !== filter)
          : [...selectedFundCategories, filter];
        if (currentTab) setDataToStorage({ selectedFundCategories: newSelectedFundCategories }, currentTab);
        else if (selectedFundCategories.length) newSelectedFundCategories = [filter];
        else newSelectedFundCategories = [...selectedFundCategories, filter];

        setSelectedFundCategories(newSelectedFundCategories);
        setTimeout(
          () => {
            setFundCategoriesExternalFilter({
              field: 'userPortfolioRequest.fundCategory.name',
              operator: 'isAnyOf',
              id: uuid4(),
              value: newSelectedFundCategories,
            });
          },
          changeTab ? 3500 : 0,
        );
      }
    }
  };

  const onSelectStatus = (filter, currentTab = '', changeTab = false) => {
    switch (filter) {
      case 'Select All':
        // eslint-disable-next-line no-case-declarations
        const allStatuses = statuses.filter((item) => item !== 'Select All' && item !== 'Clear All');
        setSelectedStatuses(allStatuses);
        if (currentTab) setDataToStorage({ selectedStatuses: allStatuses }, currentTab);
        setStatusesExternalFilter({
          field: 'status.name',
          operator: 'isAnyOf',
          id: uuid4(),
          value: allStatuses,
        });
        break;

      case 'Clear All':
        setSelectedStatuses([]);
        if (currentTab) setDataToStorage({ selectedStatuses: [] }, currentTab);

        setStatusesExternalFilter({});
        break;

      default: {
        let newSelectedStatuses = selectedStatuses.includes(filter)
          ? selectedStatuses.filter((item) => item !== filter)
          : [...selectedStatuses, filter];
        if (currentTab) setDataToStorage({ selectedStatuses: newSelectedStatuses }, currentTab);
        else if (selectedStatuses.length) newSelectedStatuses = [filter];
        else newSelectedStatuses = [...selectedStatuses, filter];

        setSelectedStatuses(newSelectedStatuses);
        setTimeout(
          () => {
            setStatusesExternalFilter({
              field: 'status.name',
              operator: 'isAnyOf',
              id: uuid4(),
              value: newSelectedStatuses,
            });
          },
          changeTab ? 3500 : 0,
        );
      }
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(mapTabValueToTabParam[newValue]);
    filters = localStorage.getItem(`portfolioOrderListFiltersSelection${newValue}`);
    if (!user?.id) return;

    if (filters) {
      const parsedFilters = JSON.parse(filters)[user?.id];
      if (parsedFilters?.selectedProducts?.length) {
        setSelectedProducts(parsedFilters?.selectedProducts);
        setTimeout(() => {
          setProductsExternalFilter({
            field: 'userPortfolioRequest.userPortfolio.subscribedPortfolio.productType.nameEn',
            operator: 'isAnyOf',
            id: uuid4(),
            value: parsedFilters?.selectedProducts,
          });
        }, 3500);
      } else onSelectProduct('Clear All');
      if (parsedFilters?.selectedFundCategories?.length) {
        setSelectedFundCategories(parsedFilters?.selectedFundCategories);
        setTimeout(() => {
          setFundCategoriesExternalFilter({
            field: 'userPortfolioRequest.fundCategory.name',
            operator: 'isAnyOf',
            id: uuid4(),
            value: parsedFilters?.selectedFundCategories,
          });
        }, 3500);
      } else onSelectFundCategory('Clear All');
      if (parsedFilters?.selectedStatuses?.length) {
        setSelectedStatuses(parsedFilters?.selectedStatuses);
        setTimeout(() => {
          setStatusesExternalFilter({
            field: 'status.name',
            operator: 'isAnyOf',
            id: uuid4(),
            value: parsedFilters?.selectedStatuses,
          });
        }, 3500);
      } else onSelectStatus('Clear All');
    } else {
      onSelectProduct('Clear All');
      onSelectFundCategory('Clear All');
      onSelectStatus('Clear All');
    }
  };

  useEffect(() => {
    if (!user?.id || !initiateFilters) return;
    setInitiateFilters(false);
    onSelectProduct('Clear All');
    onSelectFundCategory('Clear All');
    onSelectStatus('Clear All');
    if (filters) {
      const parsedFilters = JSON.parse(filters)[user?.id];
      if (parsedFilters?.selectedProducts?.length) {
        setSelectedProducts(parsedFilters?.selectedProducts);
        setTimeout(() => {
          setProductsExternalFilter({
            field: 'userPortfolioRequest.userPortfolio.subscribedPortfolio.productType.nameEn',
            operator: 'isAnyOf',
            id: uuid4(),
            value: parsedFilters?.selectedProducts,
          });
        }, 3500);
      } else onSelectProduct('Clear All');
      if (parsedFilters?.selectedFundCategories?.length) {
        setSelectedFundCategories(parsedFilters?.selectedFundCategories);
        setTimeout(() => {
          setFundCategoriesExternalFilter({
            field: 'userPortfolioRequest.fundCategory.name',
            operator: 'isAnyOf',
            id: uuid4(),
            value: parsedFilters?.selectedFundCategories,
          });
        }, 3500);
      } else onSelectFundCategory('Clear All');
      if (parsedFilters?.selectedStatuses?.length) {
        setSelectedStatuses(parsedFilters?.selectedStatuses);
        setTimeout(() => {
          setStatusesExternalFilter({
            field: 'status.name',
            operator: 'isAnyOf',
            id: uuid4(),
            value: parsedFilters?.selectedStatuses,
          });
        }, 3500);
      } else onSelectStatus('Clear All');
    }
  }, [user?.id]);

  if (!user?.id) return null;

  try {
    return (
      <Container component="main" maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">
            Portfolio Orders &nbsp;
            {/* <ProtectedContent items={[permissions.UserPortfolioRequest.create]}> */}
            {/*   <Fab color="primary" size="small" aria-label="Create User IndexFund Request" onClick={onAddNew}> */}
            {/*     <AddIcon /> */}
            {/*   </Fab> */}
            {/* </ProtectedContent> */}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="bodyMediumB" mr={2}>
            Products
          </Typography>
          <QuickFilter
            key="Products"
            filters={products}
            onSelectFilter={onSelectProduct}
            selectedFilters={selectedProducts}
            selectedTab={selectedTab}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="bodyMediumB" mr={2}>
            Fund Category
          </Typography>
          <QuickFilter
            key="Fund Category"
            filters={fundCategories}
            onSelectFilter={onSelectFundCategory}
            selectedFilters={selectedFundCategories}
            color="success"
            selectedTab={selectedTab}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="bodyMediumB" mr={2}>
            Status
          </Typography>
          <QuickFilter
            key="Status"
            filters={statuses}
            onSelectFilter={onSelectStatus}
            selectedFilters={selectedStatuses}
            color="warning"
            selectedTab={selectedTab}
          />
        </Box>
        <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
          <TabContext value={mapTabParamToValue[selectedTab]}>
            <Box>
              <TabList
                value={mapTabParamToValue[selectedTab]}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="user portfolio request list tabs"
              >
                <Tab
                  icon={<TrendingUpIcon />}
                  iconPosition="start"
                  label="Subscription"
                  value="1"
                  sx={{ textTransform: 'capitalize' }}
                  disableRipple
                  disabled={isLoading}
                />
                <Tab
                  icon={<TrendingDownIcon />}
                  iconPosition="start"
                  label="Redemption"
                  value="2"
                  sx={{ textTransform: 'capitalize' }}
                  disableRipple
                  disabled={isLoading}
                />
              </TabList>
            </Box>
            <MTabPanel value="1">
              {(selectedTab === mapTabValueToTabParam[1] || selectedTab === 'default') && (
                <PortfolioOrderTable
                  type={UserPortfolioRequestType.SUBSCRIPTION}
                  setProducts={(ps) => setProducts(ps)}
                  setFundCategories={(fcs) => setFundCategories(fcs)}
                  setStatuses={(fcs) => setStatuses(fcs)}
                  externalFilter={[
                    { ...productsExternalFilter },
                    { ...fundCategoriesExternalFilter },
                    { ...statusesExternalFilter },
                  ].filter((filter) => Object.keys(filter).length > 0)}
                />
              )}
            </MTabPanel>
            <MTabPanel value="2">
              {selectedTab === mapTabValueToTabParam[2] && (
                <PortfolioOrderTable
                  type={UserPortfolioRequestType.REDEMPTION}
                  setProducts={(ps) => setProducts(ps)}
                  setFundCategories={(fcs) => setFundCategories(fcs)}
                  setStatuses={(fcs) => setStatuses(fcs)}
                  externalFilter={[
                    { ...productsExternalFilter },
                    { ...fundCategoriesExternalFilter },
                    { ...statusesExternalFilter },
                  ].filter((filter) => Object.keys(filter).length > 0)}
                />
              )}
            </MTabPanel>
          </TabContext>
        </Box>
      </Container>
    );
  } catch (e: any) {
    return (
      <Container component="main" maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Portfolio Orders</Typography>
        </Box>
        <Typography>Unexpected error happens: ${e.message}</Typography>
      </Container>
    );
  }
}
