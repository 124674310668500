import { apiSlice } from '../../../api/common/apiSlice';
import { ApiTagType } from '../../../constants/apiTagType';
import { SubscribedPortfolio } from '../../../types/subscribedPortfolio';

export const subscriberPortfolioApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchSubscribedPortfolioByUserId: builder.query<SubscribedPortfolio, number>({
      query: (userId) => ({
        url: `/admin/subscribed-portfolio/user/${userId}`,
        method: 'GET',
      }),
      providesTags: (res, error, req) => [{ type: ApiTagType.SubscriberPortfolio as const, id: req }],
    }),
  }),
});

export const { useFetchSubscribedPortfolioByUserIdQuery } = subscriberPortfolioApiSlice;
