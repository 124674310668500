import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchUserCustodianDataResponse } from '../../../types/userCustodianData';
import { fetchUserCustodianData, cleanUserCustodianData } from './userCustodianData.actions';
import { UserCustodianDataState } from './userCustodianData.types';

const initialState: UserCustodianDataState = {
  data: null,
  fetchStatus: fetchStatuses.idle,
  error: null,
};

const userCustodianDataSlice = createSlice({
  name: 'userCustodianData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserCustodianData.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserCustodianData.fulfilled,
      (state, action: PayloadAction<FetchUserCustodianDataResponse>) => {
        state.data = action.payload.data;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserCustodianData.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(cleanUserCustodianData.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const userCustodianDataReducer = userCustodianDataSlice.reducer;
