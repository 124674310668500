import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import Alert from '@mui/material/Alert';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { currencyFormFields } from '../../forms/fields/formFields';
import { currencyFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import { createCurrency, updateCurrency } from '../../redux/modules/currency/currency.actions';
import { currencySelector } from '../../redux/modules/currency/currency.selectors';
import { Currency } from '../../types/currency';

interface CurrencyDialogProps {
  open: boolean;
  initialValue: Currency | null;
  onClose: (isReload: boolean) => void;
}

export function CurrencyDialog({ open, initialValue, onClose }: CurrencyDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, values, handleChange, touched, errors, submitForm, setValues, resetForm } = useFormik({
    initialValues: { [currencyFormFields.name]: '', [currencyFormFields.code]: '' },
    validationSchema: currencyFormSchema,
    onSubmit: (formValues) => {
      if (initialValue?.id) {
        dispatch(updateCurrency({ id: initialValue.id, ...formValues }));
      } else {
        dispatch(createCurrency(formValues));
      }
    },
  });
  const { createStatus, updateStatus, error } = useSelector(currencySelector);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    setValues({ name: initialValue?.name || '', code: initialValue?.code || '' });
  }, [initialValue]);

  useEffect(() => {
    if (createStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Currency created!', { variant: 'success' });
    }
    if (createStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Currency creation error!', { variant: 'error' });
    }

    if (updateStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Currency updated!', { variant: 'success' });
    }
  }, [createStatus, updateStatus]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{initialValue?.id ? 'Edit currency' : 'Create a new Currency'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error && <Alert severity="error">{error.message}</Alert>}
          Enter the currency name
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="currencyName"
            name={currencyFormFields.name}
            label="Currency Name"
            type="currencyName"
            value={values[currencyFormFields.name]}
            onChange={handleChange}
            error={touched.name && !!errors.name}
            helperText={touched.name && errors.name}
            inputProps={{ style: { textTransform: 'uppercase' } }}
            autoComplete="currency-name"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="currencyCode"
            name={currencyFormFields.code}
            label="Currency Code"
            type="currencyCode"
            value={values[currencyFormFields.code]}
            onChange={handleChange}
            error={touched.code && !!errors.code}
            helperText={touched.code && errors.code}
            inputProps={{ style: { textTransform: 'lowercase' } }}
            autoComplete="currency-code"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={createStatus === fetchStatuses.pending}
          loadingPosition="end"
          onClick={submitForm}
        >
          {initialValue?.id ? 'Save' : 'Create'}
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
