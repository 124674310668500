import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CopyToClipboardButton } from '../../components/CopyToClipboardButton';
import { locations } from '../../routes/locations';
import { OrderExecutionTable } from './OrderExecutionTable';
import { PortfolioOrderTable } from './PortfolioOrderTable';

export function OrderExecution() {
  const executionId = String(useParams().id);
  const navigate = useNavigate();

  useEffect(() => {
    if (!executionId) {
      return navigate(locations.orderExecutionList(), { replace: true });
    }
  }, [executionId]);

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography color="text.primary" variant="h4" sx={{ pb: 5 }}>
          Order Execution Details
        </Typography>
      </Box>

      <Grid container rowSpacing={2}>
        <Grid item container flexDirection="column" component={Paper} sx={{ p: 4 }} rowSpacing={2}>
          <Grid item container>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Execution Id</Typography>
            </Grid>
            <Grid item xs={6}>
              <CopyToClipboardButton value={executionId} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sx={{ width: '100%' }}>
          <OrderExecutionTable executionId={executionId} />
        </Grid>
        <Grid item sx={{ width: '100%' }}>
          <PortfolioOrderTable executionId={executionId} />
        </Grid>
      </Grid>
    </Container>
  );
}
