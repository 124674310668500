import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import LoginIcon from '@mui/icons-material/Login';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Copyright } from '../../components/Copyright';
import { formFields } from '../../forms/fields/formFields';
import { getLoginFormSchema } from '../../forms/validationSchema/formSchema';
import { parseApiError } from '../../helpers/api';
import { useLoginMutation } from '../../redux/features/auth/auth.apiSlice';
import { setCredentials } from '../../redux/features/auth/auth.slice';
import { useAppDispatch } from '../../redux/hooks';
import { locations } from '../../routes/locations';
import { LoginResponse } from '../../types/auth';

const OTP_LENGTH = 4;

export function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  const [login, { isLoading, error }] = useLoginMutation();

  const [isOtpRequired, setIsOtpRequired] = useState(false);
  const [formSchema, setFormSchema] = useState(getLoginFormSchema(false));
  const { handleSubmit, values, handleChange, touched, errors, setValues } = useFormik({
    initialValues: {
      [formFields.email]: '',
      [formFields.password]: '',
      [formFields.otp]: '',
    },
    validationSchema: formSchema,
    initialTouched: { [formFields.email]: true, [formFields.password]: true },
    onSubmit: async (formValues) => {
      try {
        const res: LoginResponse = await login({
          ...formValues,
          email: formValues.email.trim(),
        }).unwrap();

        dispatch(setCredentials(res));

        if (res?.otpRequired) {
          setIsOtpRequired(res.otpRequired);
          setFormSchema(getLoginFormSchema(res.otpRequired));
        }

        if (res?.accessToken) {
          navigate((state as { from?: string })?.from || locations.dashboard(), { replace: true });
        }
      } catch (err) {
        await setValues({ ...values, otp: '' });
      }
    },
  });

  const onForgotPassword = () => {
    navigate(locations.forgotPassword());
  };

  const validateOtp = (value) => !Number.isNaN(Number.parseInt(value, 10));

  const handleChangeOtp = async (value: string) => {
    await setValues({ ...values, otp: value });

    if (value.length === OTP_LENGTH) {
      handleSubmit();
    }
  };

  const renderLoginForm = () => (
    <>
      <TextField
        id="email"
        fullWidth
        name={formFields.email}
        label="Email"
        value={values[formFields.email]}
        onChange={handleChange}
        error={touched.email && !!errors.email}
        helperText={touched.email && errors.email}
        autoComplete="username"
        margin="normal"
        required
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="password"
        name={formFields.password}
        label="Password"
        type="password"
        value={values[formFields.password]}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleChange({
            target: {
              name: formFields.password,
              type: 'password',
              value: event.target.value.replace(/\s/g, ''),
            },
          });
        }}
        error={touched.password && !!errors.password}
        helperText={touched.password && errors.password}
        autoComplete="current-password"
      />
    </>
  );

  const renderOtpForm = () => (
    <FormControl fullWidth margin="normal">
      <MuiOtpInput value={values.otp} length={OTP_LENGTH} onChange={handleChangeOtp} validateChar={validateOtp} />
      {touched.otp && !!errors.otp && <FormHelperText error>{errors.otp}</FormHelperText>}
    </FormControl>
  );

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ height: '100%', mb: 16 }}>
          <img
            style={{
              transition: 'width .3s',
              objectFit: 'contain',
              objectPosition: isArabic ? '100% 0' : '0 0',
            }}
            width="100%"
            height="100%"
            onClick={() => navigate(locations.home())}
            src={isArabic ? '/images/logo-with-text-ar.png' : '/images/logo-with-text-en.png'}
            alt="madkhol logo"
          />
        </Box>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form onSubmit={handleSubmit}>
          {isOtpRequired ? renderOtpForm() : renderLoginForm()}
          {error && <Alert severity="error">{parseApiError(error)?.data?.message}</Alert>}
          <LoadingButton
            type="submit"
            color="primary"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            endIcon={<LoginIcon />}
            loading={isLoading}
            loadingPosition="end"
          >
            Sign In
          </LoadingButton>
          <Grid container>
            <Grid item xs>
              <Link variant="body2" onClick={onForgotPassword}>
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
}
