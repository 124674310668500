import AttachmentIcon from '@mui/icons-material/Attachment';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { addOrderAttachmentFormFields } from '../../forms/fields/formFields';
import { addOrderAttachmentFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import { uploadPrivate } from '../../redux/modules/media/media.actions';
import { mediaLoadingSelector } from '../../redux/modules/media/media.selectors';
import {
  createOrderAttachment,
  fetchOrderAttachmentsTypeOptions,
} from '../../redux/modules/portfolioOrder/portfolioOrder.actions';
import { portfolioOrderSelector } from '../../redux/modules/portfolioOrder/portfolioOrder.selectors';
import { UploadEntity, UploadMediaFileResponse } from '../../types/media';
import { OrderAttachmentType } from '../../types/portfolioOrder';
import { isDispatchApiSuccess } from '../../utils/api';

interface AddAttachmentDialogProps {
  orderId: string;
  open: boolean;
  onClose: (isReload: boolean) => void;
}

// Allowed Formats
const acceptFiles =
  'text/csv' +
  // .xlsx
  ',application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' +
  // .doc
  ',application/msword' +
  // .docx
  ',application/vnd.openxmlformats-officedocument.wordprocessingml.document' +
  ',application/pdf';
// Allowed Size 2 MB per each file
const fileSizeLimit = 2 * 1024 * 1024;

const mapDocumentTypeToEntity: Record<OrderAttachmentType, UploadEntity> = {
  [OrderAttachmentType.EXECUTION_REPORT_FILE]: UploadEntity.orderFile,
  [OrderAttachmentType.EXECUTION_FILE]: UploadEntity.orderFile,
  [OrderAttachmentType.CONSOLIDATE_EXECUTION_FILE]: UploadEntity.orderFile,
  [OrderAttachmentType.OTHER]: UploadEntity.other,
};

export function AddAttachmentDialog({ open, onClose, orderId }: AddAttachmentDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    values,
    handleChange,
    touched,
    errors,
    isValid,
    submitForm,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      [addOrderAttachmentFormFields.orderAttachmentType]: 0,
      [addOrderAttachmentFormFields.documentLink]: '',
    },
    validationSchema: addOrderAttachmentFormSchema,
    onSubmit: async (formValues) => {
      const result: any = await dispatch(
        createOrderAttachment({
          orderId,
          orderAttachmentTypeId: formValues.orderAttachmentType,
          documentLink: formValues.documentLink,
        }),
      );

      if (isDispatchApiSuccess(result)) {
        enqueueSnackbar('Attachment created!', { variant: 'success' });
      } else {
        enqueueSnackbar(`Create Attachment Error: ${result?.error?.message}`, { variant: 'error' });
      }
    },
  });
  const { orderAttachmentsTypeOptions, createOrderAttachmentStatus, error } = useSelector(portfolioOrderSelector);
  const isMediaLoading = useSelector(mediaLoadingSelector);
  const isLoading = createOrderAttachmentStatus === fetchStatuses.pending;

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];

    if (!file) {
      return;
    }

    if (file.size > fileSizeLimit) {
      await setFieldTouched(addOrderAttachmentFormFields.documentLink, true);
      return setFieldError(addOrderAttachmentFormFields.documentLink, 'Allowed Size 2 MB');
    }

    const orderAttachmentType = orderAttachmentsTypeOptions.find(
      (item) => item.id === values[addOrderAttachmentFormFields.orderAttachmentType],
    );

    if (!orderAttachmentType) {
      return;
    }

    const entity = mapDocumentTypeToEntity[orderAttachmentType.name];

    const result = await dispatch(uploadPrivate({ file, entity }));

    if (isDispatchApiSuccess<UploadMediaFileResponse>(result)) {
      await setFieldValue(addOrderAttachmentFormFields.documentLink, result.payload.data.filename);
    } else {
      enqueueSnackbar(`Error uploading: ${result?.error?.message}`, { variant: 'error' });
    }
  };

  useEffect(() => {
    if (open) {
      dispatch(fetchOrderAttachmentsTypeOptions());
    } else {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    if (!values[addOrderAttachmentFormFields.orderAttachmentType] && orderAttachmentsTypeOptions.length) {
      const otherType = orderAttachmentsTypeOptions.find((oat) => oat.name === OrderAttachmentType.OTHER);

      if (otherType?.id) {
        setFieldValue(addOrderAttachmentFormFields.orderAttachmentType, otherType.id);
      }
    }
  }, [orderAttachmentsTypeOptions]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Add an attachment</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error && <Alert severity="error">{error.message}</Alert>}
          Enter the action name
        </DialogContentText>
        <Grid
          onSubmit={handleSubmit}
          component="form"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={80} />
          ) : (
            <FormControl fullWidth margin="normal">
              <InputLabel id="attachment-type-label">Attachment Type</InputLabel>
              <Select
                labelId="attachment-type-label"
                name={addOrderAttachmentFormFields.orderAttachmentType}
                value={values[addOrderAttachmentFormFields.orderAttachmentType]}
                label="Attachment Type"
                onChange={handleChange}
                error={touched.orderAttachmentType && !!errors.orderAttachmentType}
              >
                {orderAttachmentsTypeOptions.map((oat) => (
                  <MenuItem key={oat.id} value={oat.id}>
                    {oat.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            disabled
            id={addOrderAttachmentFormFields.documentLink}
            name={addOrderAttachmentFormFields.documentLink}
            label="Document link"
            type="text"
            value={values[addOrderAttachmentFormFields.documentLink]}
            error={touched.documentLink && !!errors.documentLink}
            helperText={touched.documentLink && errors.documentLink}
          />
          <LoadingButton
            variant="contained"
            component="label"
            loading={isMediaLoading}
            endIcon={<AttachmentIcon />}
            disabled={!values[addOrderAttachmentFormFields.orderAttachmentType]}
          >
            Upload file
            <input hidden accept={acceptFiles} type="file" onChange={uploadFile} />
          </LoadingButton>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={isLoading}
          disabled={!isValid}
          loadingPosition="end"
          onClick={submitForm}
        >
          Create Attachment
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
