import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, FormHelperText, TextField, TextFieldProps } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Chip from '@mui/material/Chip';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import { useFormik } from 'formik';
import debounce from 'lodash/debounce';
import { useSnackbar } from 'notistack';
import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { rolePermissionFormFields } from '../../forms/fields/formFields';
import { rolePermissionFormSchema } from '../../forms/validationSchema/formSchema';
import { parseApiError } from '../../helpers/api';
import { useFetchRolesQuery } from '../../redux/features/role/role.apiSlice';
import {
  useCreateRolePermissionMutation,
  useUpdateRolePermissionMutation,
} from '../../redux/features/rolePermission/rolePermission.apiSlice';
import { useAppDispatch } from '../../redux/hooks';
import { fetchActionsAutocomplete } from '../../redux/modules/action/action.actions';
import { actionSelector } from '../../redux/modules/action/action.selectors';
import { Action } from '../../types/action';
import { RolePermission } from '../../types/rolePermission';

interface RolePermissionDialogProps {
  open: boolean;
  initialValue: RolePermission | null;
  onClose: (isReload: boolean) => void;
}

export function RolePermissionDialog({ open, initialValue, onClose }: RolePermissionDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [initialized, setInitialized] = useState(false);
  const [actionValue, setActionValue] = useState<Action | null>(null);

  const [updateRolePermission, { isLoading: isUpdateRoleLoading, error: updateError }] =
    useUpdateRolePermissionMutation();
  const [createRolePermission, { isLoading: isCreateRoleLoading, error: createError }] =
    useCreateRolePermissionMutation();
  const { data: roles, isSuccess: isFetchRolesSuccess } = useFetchRolesQuery({ limit: 100, offset: 0 });

  const { handleSubmit, values, handleChange, touched, errors, submitForm, setFieldValue, setValues, resetForm } =
    useFormik({
      initialValues: {
        [rolePermissionFormFields.attributes]: initialValue?.attributes || ['*'],
        [rolePermissionFormFields.role]: initialValue?.role?.id || '',
        [rolePermissionFormFields.action]: initialValue?.action?.id || '',
      } as { attributes: string[]; role: number; action: number },
      validationSchema: rolePermissionFormSchema,
      onSubmit: async (formValues) => {
        const isUpdate = initialValue?.id;
        try {
          if (isUpdate) {
            await updateRolePermission({ id: initialValue.id, ...formValues }).unwrap();
          } else {
            await createRolePermission(formValues).unwrap();
          }

          enqueueSnackbar(isUpdate ? 'Role Permission created!' : 'Role Permission updated!', { variant: 'success' });

          onClose(true);
        } catch (err) {
          enqueueSnackbar(isUpdate ? 'Role Permission creation error!' : 'Role Permission update error!', {
            variant: 'error',
          });
        }
      },
    });
  const { actionsAutocomplete, fetchAutocompleteStatus } = useSelector(actionSelector);
  const actionsLoading = fetchAutocompleteStatus === fetchStatuses.pending;
  const isLoading = isUpdateRoleLoading || isCreateRoleLoading;

  useEffect(() => {
    if (open) {
      dispatch(fetchActionsAutocomplete(initialValue?.action?.name || ''));
    } else {
      resetForm();
      setInitialized(false);
      setActionValue(null);
    }
  }, [open]);

  useEffect(() => {
    if (initialized) {
      setValues({
        [rolePermissionFormFields.attributes]: initialValue?.attributes || ['*'],
        [rolePermissionFormFields.role]: initialValue?.role?.id || '',
        [rolePermissionFormFields.action]: initialValue?.action?.id || '',
      } as { attributes: string[]; role: number; action: number });
      const action = actionsAutocomplete.find((r) => r.id === initialValue?.action?.id);

      if (action) {
        setActionValue(action);
      }
    }
  }, [initialized]);

  useEffect(() => {
    if (fetchAutocompleteStatus === fetchStatuses.success && !initialized) {
      setInitialized(true);
    }
  }, [fetchAutocompleteStatus]);

  const onAutocompleteSearch = useCallback(
    debounce((_, searchValue) => {
      if (searchValue) {
        dispatch(fetchActionsAutocomplete(searchValue));
      }
    }),
    [],
  );

  const onAutocompleteChange = (_: SyntheticEvent, newValue: Action | null) => {
    setActionValue(newValue);
    setFieldValue(rolePermissionFormFields.action, newValue?.id || '');
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{initialValue?.id ? 'Edit Role Permission' : 'Create a new Role Permission'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {updateError && <Alert severity="error">{parseApiError(updateError)?.data?.message}</Alert>}
          {createError && <Alert severity="error">{parseApiError(createError)?.data?.message}</Alert>}
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <Autocomplete
            disableCloseOnSelect
            multiple
            title="attributes"
            id="attributes"
            options={[]}
            freeSolo
            renderTags={(value: any, getTagProps) =>
              (value as string[]).map((option: string, index: number) => (
                // eslint-disable-next-line react/jsx-key
                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
              ))
            }
            value={values[rolePermissionFormFields.attributes]}
            onChange={(e, v) =>
              handleChange({
                target: {
                  name: rolePermissionFormFields.attributes,
                  type: 'attributes',
                  value: v,
                },
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="Attributes"
                placeholder="Attributes"
                margin="normal"
                required
                fullWidth
                id="attributes"
                name={rolePermissionFormFields.attributes}
                type="attributes"
                error={touched.attributes && !!errors.attributes}
                helperText={touched.attributes && errors.attributes}
              />
            )}
          />
          <Autocomplete
            title="action"
            options={actionsLoading ? [] : actionsAutocomplete}
            getOptionLabel={(option: Action) => option?.name}
            renderInput={(params: TextFieldProps) => (
              <TextField
                {...params}
                margin="normal"
                required
                fullWidth
                id="action"
                name={rolePermissionFormFields.action}
                label="Action"
                type="action"
                error={touched.action && !!errors.action}
                helperText={touched.action && errors.action}
              />
            )}
            onInputChange={onAutocompleteSearch}
            onChange={onAutocompleteChange}
            loading={actionsLoading}
            value={actionValue}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={80} />
          ) : (
            <FormControl fullWidth margin="normal" error={touched.role && !!errors.role}>
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                name={rolePermissionFormFields.role}
                value={values[rolePermissionFormFields.role]}
                label="Role"
                onChange={handleChange}
                disabled={isFetchRolesSuccess}
                error={touched.role && !!errors.role}
              >
                {(roles?.items || []).map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
              {touched.role && <FormHelperText>{errors.role}</FormHelperText>}
            </FormControl>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={isLoading}
          loadingPosition="end"
          onClick={submitForm}
        >
          {initialValue?.id ? 'Save' : 'Create'}
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
