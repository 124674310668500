import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { fundManagerService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { CreateFundManagerRequest, UpdateFundManagerRequest } from '../../../types/fundManager';

export const fetchFundManagers = createAsyncThunk('fundManager/fetch-all', (data: PaginatedRequest) =>
  fundManagerService.fetchFundManagers(data),
);

export const fetchFundManagersAutocomplete = createAsyncThunk('fundManager/fetch-autocomplete', (term: string) =>
  fundManagerService.fetchFundManagersAutocomplete(term),
);

export const fetchFundManager = createAsyncThunk('fundManager/get', (id: number) =>
  fundManagerService.fetchFundManager(id),
);

export const deleteFundManager = createAsyncThunk('fundManager/delete', (id: number) =>
  fundManagerService.deleteFundManager(id),
);

export const createFundManager = createAsyncThunk('fundManager/create', (fundManager: CreateFundManagerRequest) =>
  fundManagerService.createFundManager(fundManager),
);

export const updateFundManager = createAsyncThunk('fundManager/update', (fundManager: UpdateFundManagerRequest) =>
  fundManagerService.updateFundManager(fundManager),
);

export const resetEditFundManager = createAction('fundManager/reset-edit');

export const cleanFundManagers = createAction('fundManager/clean');
