const originalError = console.error;

console.error = function customError(...args) {
  if (/MUI: Invalid license key/gm.test(args?.[0])) {
    return;
  }

  originalError(...args);
};

export default {};
