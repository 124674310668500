import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { portfolioOrderService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import {
  CreateOrderAttachmentRequest,
  CreatePortfolioOrderRequest,
  ImportExecutionPortfolioOrder,
  ReconcileOrderExecutionsByOrderIdRequest,
  SavePortfolioOrderRequest,
  UpdatePortfolioOrderRequest,
  UpdatePortfolioOrderStatusRequest,
  UploadOrderAttachmentRequest,
} from '../../../types/portfolioOrder';
import { OrderExecutionReportType } from '../../../types/orderExecution';

export const fetchPortfolioOrders = createAsyncThunk(
  'portfolioOrder/fetch-all',
  (data: PaginatedRequest & { requestId: string }, { rejectWithValue }) =>
    portfolioOrderService.fetchPortfolioOrders(data).catch((error) => rejectWithValue(error)),
);

export const fetchPortfolioOrdersByExecutionId = createAsyncThunk(
  'portfolioOrder/fetch-all-by-execution-id',
  (data: PaginatedRequest & { executionId: string; requestId: string }, { rejectWithValue }) =>
    portfolioOrderService.fetchPortfolioOrdersByExecutionId(data).catch((error) => rejectWithValue(error)),
);

export const fetchOrderAttachments = createAsyncThunk(
  'portfolioOrder/get-attachments',
  (
    data: PaginatedRequest & {
      orderId: string;
    },
    { rejectWithValue },
  ) => portfolioOrderService.fetchOrderAttachments(data).catch((error) => rejectWithValue(error)),
);

export const fetchOrderAttachmentsTypeOptions = createAsyncThunk(
  'portfolioOrder/fetch-order-attachments-type-options',
  (_, { rejectWithValue }) =>
    portfolioOrderService.fetchOrderAttachmentsTypeOptions().catch((error) => rejectWithValue(error)),
);

export const fetchPortfolioOrdersByOrderId = createAsyncThunk(
  'portfolioOrder/fetch-all-by-order-id',
  (
    data: PaginatedRequest & {
      orderId: string;
    },
    { rejectWithValue },
  ) => portfolioOrderService.fetchPortfolioOrdersByOrderId(data).catch((error) => rejectWithValue(error)),
);

export const fetchPortfolioOrderExecutionsByOrderId = createAsyncThunk(
  'portfolioOrder/fetch-executions-by-order-id',
  (orderId: string, { rejectWithValue }) =>
    portfolioOrderService.fetchPortfolioOrderExecutionsByOrderId(orderId).catch((error) => rejectWithValue(error)),
);

export const fetchOrderExecutionsReportByOrderId = createAsyncThunk(
  'portfolioOrder/fetch-order-executions-report-by-order-id',
  (payload: { orderId: string; reportType: OrderExecutionReportType }, { rejectWithValue }) =>
    portfolioOrderService
      .fetchOrderExecutionsReportByOrderId(payload.orderId, payload.reportType)
      .catch((error) => rejectWithValue(error)),
);

export const discardOrderExecutionsByOrderId = createAsyncThunk(
  'portfolioOrder/discard-order-executions-by-order-id',
  (orderId: string, { rejectWithValue }) =>
    portfolioOrderService.discardOrderExecutionsByOrderId(orderId).catch((error) => rejectWithValue(error)),
);

export const approveOrderExecutionsByOrderId = createAsyncThunk(
  'portfolioOrder/approve-order-executions-by-order-id',
  (orderId: string, { rejectWithValue }) =>
    portfolioOrderService.approveOrderExecutionsByOrderId(orderId).catch((error) => rejectWithValue(error)),
);

export const changeOrderExecutionsToReconciliationByOrderId = createAsyncThunk(
  'portfolioOrder/change-order-executions-to-reconciliation-by-order-id',
  (orderId: string, { rejectWithValue }) =>
    portfolioOrderService
      .changeOrderExecutionsToReconciliationByOrderId(orderId)
      .catch((error) => rejectWithValue(error)),
);

export const reconcileOrderExecutionsByOrderId = createAsyncThunk(
  'portfolioOrder/reconcile-order-executions-by-order-id',
  (payload: ReconcileOrderExecutionsByOrderIdRequest, { rejectWithValue }) =>
    portfolioOrderService.reconcileOrderExecutionsByOrderId(payload).catch((error) => rejectWithValue(error)),
);

export const fetchUserPortfolioRequestsByOrderId = createAsyncThunk(
  'portfolioOrder/user-portfolio-request/fetch-all-by-order-id',
  (
    data: PaginatedRequest & {
      orderId: string;
    },
    { rejectWithValue },
  ) => portfolioOrderService.fetchUserPortfolioRequestsByOrderId(data).catch((error) => rejectWithValue(error)),
);

export const fetchFundOrdersByOrderId = createAsyncThunk(
  'portfolioOrder/fund-orders/fetch-all-by-order-id',
  (
    data: PaginatedRequest & {
      orderId: string;
    },
    { rejectWithValue },
  ) => portfolioOrderService.fetchFundOrdersByOrderId(data).catch((error) => rejectWithValue(error)),
);

export const fetchPortfolioOrderWeightsByOrderId = createAsyncThunk(
  'portfolioOrder/fetch-weights-by-order-id',
  (
    data: PaginatedRequest & {
      orderId: string;
    },
    { rejectWithValue },
  ) => portfolioOrderService.fetchPortfolioOrderWeightsByOrderId(data).catch((error) => rejectWithValue(error)),
);

export const fetchSatelliteRequestsByOrderId = createAsyncThunk(
  'portfolioOrder/satellite-requests/fetch-all-by-order-id',
  (
    data: PaginatedRequest & {
      orderId: string;
    },
    { rejectWithValue },
  ) => portfolioOrderService.fetchSatelliteRequestsByOrderId(data).catch((error) => rejectWithValue(error)),
);

export const fetchConsolidateRequestsByOrderId = createAsyncThunk(
  'portfolioOrder/consolidate-request/fetch-all-by-order-id',
  (
    data: PaginatedRequest & {
      orderId: string;
    },
    { rejectWithValue },
  ) => portfolioOrderService.fetchConsolidateRequestsByOrderId(data).catch((error) => rejectWithValue(error)),
);

export const fetchPortfolioOrder = createAsyncThunk('portfolioOrder/get', (id: number, { rejectWithValue }) =>
  portfolioOrderService.fetchPortfolioOrder(id).catch((error) => rejectWithValue(error)),
);

export const deletePortfolioOrder = createAsyncThunk('portfolioOrder/delete', (orderId: string, { rejectWithValue }) =>
  portfolioOrderService.deletePortfolioOrder(orderId).catch((error) => rejectWithValue(error)),
);

export const savePortfolioOrder = createAsyncThunk(
  'portfolioOrder/save',
  (portfolioOrder: SavePortfolioOrderRequest, { rejectWithValue }) =>
    portfolioOrderService.savePortfolioOrder(portfolioOrder).catch((error) => rejectWithValue(error)),
);

export const createPortfolioOrder = createAsyncThunk(
  'portfolioOrder/create',
  (payload: CreatePortfolioOrderRequest, { rejectWithValue }) =>
    portfolioOrderService.createPortfolioOrder(payload).catch((error) => rejectWithValue(error)),
);

export const updatePortfolioOrder = createAsyncThunk(
  'portfolioOrder/update',
  (portfolioOrder: UpdatePortfolioOrderRequest, { rejectWithValue }) =>
    portfolioOrderService.updatePortfolioOrder(portfolioOrder).catch((error) => rejectWithValue(error)),
);

export const updatePortfolioOrderStatus = createAsyncThunk(
  'portfolioOrder/update-status',
  (data: UpdatePortfolioOrderStatusRequest, { rejectWithValue }) =>
    portfolioOrderService.updatePortfolioOrderStatus(data).catch((error) => rejectWithValue(error)),
);

export const executePortfolioOrder = createAsyncThunk(
  'portfolioOrder/execute',
  (orderId: string, { rejectWithValue }) =>
    portfolioOrderService.executePortfolioOrder(orderId).catch((error) => rejectWithValue(error)),
);

export const cancelExecutionPortfolioOrder = createAsyncThunk(
  'portfolioOrder/cancel-execution',
  (orderId: string, { rejectWithValue }) =>
    portfolioOrderService.cancelExecutionPortfolioOrder(orderId).catch((error) => rejectWithValue(error)),
);

export const consolidateFundOrders = createAsyncThunk(
  'portfolioOrder/consolidate-fund-orders',
  (fundOrderIds: number[], { rejectWithValue }) =>
    portfolioOrderService.consolidateFundOrders(fundOrderIds).catch((error) => rejectWithValue(error)),
);

export const importExecutionFilePortfolioOrder = createAsyncThunk(
  'portfolioOrder/import-execution-file',
  (payload: ImportExecutionPortfolioOrder, { rejectWithValue }) =>
    portfolioOrderService.importExecutionFilePortfolioOrder(payload).catch((error) => rejectWithValue(error)),
);

export const importConsolidateExecutionFilePortfolioOrder = createAsyncThunk(
  'portfolioOrder/import-consolidate-execution-file',
  (payload: ImportExecutionPortfolioOrder, { rejectWithValue }) =>
    portfolioOrderService
      .importConsolidateExecutionFilePortfolioOrder(payload)
      .catch((error) => rejectWithValue(error)),
);

export const fetchPortfolioOrderStatusOptions = createAsyncThunk(
  'portfolioOrder/fetch-portfolio-order-status-options',
  (_, { rejectWithValue }) =>
    portfolioOrderService.fetchPortfolioOrderStatusOptions().catch((error) => rejectWithValue(error)),
);

export const createOrderAttachment = createAsyncThunk(
  'portfolioOrder/create-attachment',
  (
    req: CreateOrderAttachmentRequest & {
      orderId: string;
    },
    { rejectWithValue },
  ) => portfolioOrderService.createOrderAttachment(req).catch((error) => rejectWithValue(error)),
);

export const uploadOrderAttachment = createAsyncThunk(
  'portfolioOrder/upload-attachment',
  (
    req: UploadOrderAttachmentRequest & {
      orderId: string;
    },
    { rejectWithValue },
  ) => portfolioOrderService.uploadOrderAttachment(req).catch((error) => rejectWithValue(error)),
);

export const cleanPortfolioOrders = createAction('portfolioOrder/clean');

export const cleanViewDetailsByOrderId = createAction('portfolioOrder/clean-view-details-by-order-id');

export const resetEditPortfolioOrder = createAction('portfolioOrder/reset-edit');
