import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useAppDispatch } from '../../redux/hooks';
import { fetchAnbBalance } from '../../redux/modules/payment/payment.actions';
import { paymentSelector } from '../../redux/modules/payment/payment.selectors';
import { fetchTradingAccount } from '../../redux/modules/alpaca/alpaca.actions';
import { alpacaSelector } from '../../redux/modules/alpaca/alpaca.selectors';
import { round2Format } from '../../utils/number';

export function SystemLiveWidget() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAnbBalance());
    dispatch(fetchTradingAccount());
  }, []);

  const { anbBalance } = useSelector(paymentSelector);
  const { tradingAccount } = useSelector(alpacaSelector);

  return (
    <Grid item container flexDirection="row" sx={{ mt: 0 }} spacing={2} xs={12}>
      <Grid item container sm={12} md={6}>
        <Grid item xs={12} sm={6}>
          <Typography>ANB Balance</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2">{round2Format(+(anbBalance?.clearedBalance || 0))}</Typography>
        </Grid>
      </Grid>
      <Grid item container sm={12} md={6}>
        <Grid item xs={12} sm={6}>
          <Typography>Alpaca Balance</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2">{round2Format(+(tradingAccount?.cash || 0))}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
