import * as yup from 'yup';
import { benchmarkFormFields } from '../../fields/formFields';

export const benchmarkFormSchema = yup.object({
  [benchmarkFormFields.nameEn]: yup
    .string()
    .max(150, 'Name En must be at most 150 characters')
    .required('Name En is required')
    .trim(),
  [benchmarkFormFields.nameAr]: yup
    .string()
    .max(150, 'Name Ar must be at most 150 characters')
    .required('Name Ar is required')
    .trim(),
});
