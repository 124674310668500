import React from 'react';
import { DownwardIcon, NoopIcon, UpwardIcon } from '../Icon';

interface ChangesProps {
  prev: number;
  next: number;
}

export function Changes({ prev, next }: ChangesProps) {
  let Icon = NoopIcon;

  if (prev < next) {
    Icon = UpwardIcon;
  }

  if (prev > next) {
    Icon = DownwardIcon;
  }

  return <Icon />;
}
