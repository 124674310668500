import { createSlice } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { uploadPrivate, uploadPublic, getPrivateFile } from './media.actions';
import { MediaState } from './media.types';

const initialState: MediaState = {
  cache: {},
  fetchStatus: fetchStatuses.idle,
  error: null,
};

const mediaSlice = createSlice({
  name: 'media',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPrivateFile.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(getPrivateFile.fulfilled, (state, action) => {
      const name = action.payload.data.url.split('/').pop() || Math.random().toString(36).substring(2);
      state.cache[name] = {
        ...action.payload.data,
        timestamp: Date.now(),
      };
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(getPrivateFile.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(uploadPrivate.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(uploadPrivate.fulfilled, (state, action) => {
      state.cache[action.payload.data.filename] = {
        ...action.payload.data,
        timestamp: Date.now(),
      };
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(uploadPrivate.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(uploadPublic.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(uploadPublic.fulfilled, (state) => {
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(uploadPublic.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });
  },
});

export const mediaReducer = mediaSlice.reducer;
