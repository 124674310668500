import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import Tooltip from '@mui/material/Tooltip';
import { FakeButtonLink } from '../../components/FakeLink';
import { Table } from '../../components/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { fetchSatelliteAccountUnits } from '../../redux/modules/satelliteAccount/satelliteAccount.actions';
import { satelliteAccountSelector } from '../../redux/modules/satelliteAccount/satelliteAccount.selectors';
import { locations } from '../../routes/locations';
import { SatelliteAccountUnitStatus } from '../../types/satelliteAccount';
import { round2Format, round4Format } from '../../utils/number';

export function SatelliteAccountUnitsTable() {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id', aggregable: false },
    {
      field: 'indexFund.nameEn',
      headerName: 'Index Fund',
      sortable: false,
      aggregable: false,
      valueGetter: (params) => {
        if (!params.row?.indexFund?.id) {
          return '';
        }

        return `(${params.row?.indexFund?.id}) ${params.row?.indexFund?.nameEn}`;
      },
      groupingValueGetter: (params) => params.row?.indexFund?.nameEn,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return (
          <Tooltip title={params.row?.indexFund?.name} followCursor>
            <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.indexFund(params.row?.indexFund?.id)}>
              {params.value}
            </FakeButtonLink>
          </Tooltip>
        );
      },
      flex: 1,
    },
    {
      field: 'numOfUnits',
      headerName: 'Num Of Units',
      type: 'number',
      width: 200,
      aggregable: false,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        round4Format(value);
      },
    },
    {
      field: 'entryPrice',
      headerName: 'Entry Price',
      type: 'number',
      width: 100,
      aggregable: false,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        round2Format(value);
      },
    },
    {
      field: 'totalEntryValue',
      headerName: 'Total Entry Value',
      type: 'number',
      width: 200,
      valueGetter: (params) => (params.row?.entryPrice || 0) * (params?.row?.numOfUnits || 0),
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'totalMarketValue',
      headerName: 'Total Market Value',
      type: 'number',
      width: 200,
      valueGetter: (params) => (params.row?.indexFund?.netAssetValuePerUnit || 0) * (params?.row?.numOfUnits || 0),
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(SatelliteAccountUnitStatus),
      minWidth: 120,
      valueGetter: (params) => params.row?.status?.name,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      width: 100,
      sortable: false,
      aggregable: false,
      groupable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 100,
      aggregable: false,
      groupable: false,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const { totalSatelliteAccountUnits, satelliteAccountUnits, fetchUnitStatus } = useSelector(satelliteAccountSelector);

  return (
    <Table
      wrapperHeight={300}
      fetchItems={fetchSatelliteAccountUnits}
      rows={satelliteAccountUnits}
      columns={columns.current}
      loading={fetchUnitStatus === fetchStatuses.pending}
      rowCount={totalSatelliteAccountUnits}
      disableColumnFilter
      disableColumnMenu
      density="compact"
      initialColumnVisibilityModel={{ id: false, 'status.name': false }}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      initialAggregationModel={{ totalEntryValue: 'sum', totalMarketValue: 'sum' }}
    />
  );
}
