import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Table } from '../../components/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { fetchFundNavHistory } from '../../redux/modules/indexFund/indexFund.actions';
import { indexFundSelector } from '../../redux/modules/indexFund/indexFund.selectors';
import { locations } from '../../routes/locations';
import { round2Format } from '../../utils/number';
import { FakeButtonLink } from '../../components/FakeLink';

export function IndexFundNavHistoryTab() {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id' },
    {
      field: 'indexFund.source.name',
      headerName: 'Source',
      sortable: false,
      width: 180,
      valueGetter: (params) => params.row?.indexFund?.source?.name,
    },
    {
      field: 'indexFund.code',
      headerName: 'Code',
      sortable: false,
      width: 100,
      valueGetter: (params) => params.row?.indexFund?.code,
    },
    {
      field: 'indexFund.id',
      headerName: 'Fund Id',
      width: 100,
      valueGetter: (params) => params.row?.indexFund?.id,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return (
          <Tooltip title={params.value} followCursor>
            <FakeButtonLink startIcon={<OpenInNewIcon />} href={locations.indexFund(params.row?.indexFund?.id)}>
              {params.value}
            </FakeButtonLink>
          </Tooltip>
        );
      },
    },
    {
      field: 'indexFund.nameEn',
      headerName: 'Fund Name',
      width: 200,
      flex: 1,
      valueGetter: (params) => params.row?.indexFund?.nameEn,
    },
    {
      field: 'nav',
      headerName: 'Nav',
      sortable: false,
      width: 100,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'originalNav',
      headerName: 'Original Nav',
      sortable: false,
      width: 100,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'fxRate',
      headerName: 'FX Rate',
      sortable: false,
      width: 100,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'indexFund.currency.name',
      headerName: 'Currency',
      sortable: false,
      width: 100,
      valueGetter: (params) => params.row?.indexFund?.currency?.name,
    },
    {
      field: 'updatedAt',
      headerName: 'Last Update',
      sortable: false,
      type: 'dateTime',
      width: 200,
    },
  ]);
  const { totalFundNavHistoryItems, fundNavHistoryItems, fetchFundNavHistoryStatus } = useSelector(indexFundSelector);

  return (
    <Table
      fetchItems={fetchFundNavHistory}
      rows={fundNavHistoryItems}
      columns={columns.current}
      loading={fetchFundNavHistoryStatus === fetchStatuses.pending}
      rowCount={totalFundNavHistoryItems}
      initialColumnVisibilityModel={{ id: false }}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
    />
  );
}
