import { ActionType } from '../../types/action';

const listPermission = {
  resource: 'user-portfolio-requests',
  action: ActionType.READ,
};

const statusUpdatePermission = {
  resource: 'user-portfolio-request-status',
  action: ActionType.UPDATE,
};

export const UserPortfolioRequest = {
  list: listPermission,
  statusUpdate: statusUpdatePermission,
};
