import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { IJadwaOrder } from '../../../../types/jadwa';

interface JadwaOrderProps {
  order: IJadwaOrder;
}

export function JadwaOrder({ order }: JadwaOrderProps) {
  return (
    <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: 0 }} spacing={2}>
      <Typography variant="h6">Order</Typography>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Step</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{order.step}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Fund Order Id</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{order.fundOrderId}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Side</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{order.side}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Error</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{order.error}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
