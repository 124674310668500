import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFetchUserProfileQuery } from '../redux/features/auth/auth.apiSlice';
import { setUserProfile } from '../redux/features/auth/auth.slice';
import { useAppDispatch } from '../redux/hooks';
import { authSelector } from '../redux/features/auth/auth.selectors';
import { locations } from '../routes/locations';

export function useAutoLogin() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isLoggedIn } = useSelector(authSelector);

  const { data: userProfile } = useFetchUserProfileQuery(undefined, { skip: isLoggedIn !== null });

  useEffect(() => {
    if (isLoggedIn === false) {
      navigate(locations.login(), {
        replace: true,
        state: { from: pathname },
      });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (userProfile) {
      dispatch(setUserProfile(userProfile));
    }
  }, [userProfile]);
}
