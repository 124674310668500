import * as yup from 'yup';

export const passwordSchema = yup.string().required('Password is required').trim().strongPassword();
export const secretPasswordSchema = yup.string().required('Password is required').trim().strongPassword({
  minLengthMessage: 'Password incorrect',
  maxLengthMessage: 'Password incorrect',
  lowerCaseMessage: 'Password incorrect',
  consecutiveMessage: 'Password incorrect',
  sequentialMessage: 'Password incorrect',
  upperCaseMessage: 'Password incorrect',
  digitMessage: 'Password incorrect',
  specialMessage: 'Password incorrect',
});

export const passwordConfirmationSchema = yup.string().oneOf([yup.ref('password'), undefined], 'Passwords must match');
