import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { benchmarkService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { CreateBenchmarkRequest, ImportBenchmarkNavDateFile, UpdateBenchmarkRequest } from '../../../types/benchmark';

export const fetchBenchmarks = createAsyncThunk('benchmark/fetch-all', (data: PaginatedRequest) =>
  benchmarkService.fetchBenchmarks(data),
);

export const fetchBenchmark = createAsyncThunk('benchmark/get', (id: number) => benchmarkService.fetchBenchmark(id));

export const deleteBenchmark = createAsyncThunk('benchmark/delete', (id: number) =>
  benchmarkService.deleteBenchmark(id),
);

export const createBenchmark = createAsyncThunk('benchmark/create', (benchmark: CreateBenchmarkRequest) =>
  benchmarkService.createBenchmark(benchmark),
);

export const updateBenchmark = createAsyncThunk('benchmark/update', (benchmark: UpdateBenchmarkRequest) =>
  benchmarkService.updateBenchmark(benchmark),
);

export const importNavDataFile = createAsyncThunk(
  'benchmark/import-nav-data-file',
  (payload: ImportBenchmarkNavDateFile) => benchmarkService.importNavDataFile(payload),
);

export const fetchNavDataByFund = createAsyncThunk('benchmark/fetch-nav-data-by-fund', (id: number) =>
  benchmarkService.fetchNavDataByFund(id),
);

export const resetEditBenchmark = createAction('benchmark/reset-edit');

export const cleanBenchmarks = createAction('benchmark/clean');
