import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { IAlpacaOrder } from '../../../../types/alpaca';
import { AlpacaActions } from './AlpacaActions';
import { AlpacaEventsTable } from './AlpacaEventsTable';
import { AlpacaJournal } from './AlpacaJournal';
import { AlpacaTransfer } from './AlpacaTransfer';
import { AlpacaOrder } from './AlpacaOrder';

interface AlpacaDetailsProps {
  order: IAlpacaOrder;
  refresh: () => void;
}

export const AlpacaDetails = React.memo(({ order, refresh }: AlpacaDetailsProps) => (
  <>
    <AlpacaActions order={order} refresh={refresh} />
    <AlpacaOrder order={order} />
    {order?.alpacaJournal && <AlpacaJournal alpacaJournal={order.alpacaJournal} />}
    {order?.alpacaTransfer && <AlpacaTransfer alpacaTransfer={order.alpacaTransfer} />}
    <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}>
      <Typography variant="h6">Events</Typography>
      <AlpacaEventsTable
        journalId={order.alpacaJournal?.journalId}
        transferId={order.alpacaTransfer?.transferId}
        ntaId={order.alpacaTransfer?.ntaId}
        orderId={order.alpacaOrderId}
      />
    </Grid>
  </>
));
