import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchConsolidateRequestsResponse,
  FetchFundOrdersResponse,
  FetchPortfolioOrderResponse,
  FetchPortfolioOrdersResponse,
  FetchPortfolioOrderStatusOptionsResponse,
  FetchSatelliteAccountRequestsResponse,
  FetchOrderAttachmentsTypeOptionsResponse,
  FetchOrderAttachmentsResponse,
  FetchPortfolioOrderExecutionsResponse,
  FetchPortfolioOrderWeightsResponse,
  CreatePortfolioOrderResponse,
} from '../../../types/portfolioOrder';
import { FetchPortfolioOrderExecutionReportResponse } from '../../../types/report';
import { FetchUserPortfolioRequestsResponse } from '../../../types/userPortfolioRequest';
import {
  fetchPortfolioOrder,
  createOrderAttachment,
  fetchPortfolioOrders,
  resetEditPortfolioOrder,
  savePortfolioOrder,
  deletePortfolioOrder,
  cleanPortfolioOrders,
  updatePortfolioOrderStatus,
  fetchPortfolioOrderStatusOptions,
  createPortfolioOrder,
  fetchPortfolioOrdersByOrderId,
  fetchUserPortfolioRequestsByOrderId,
  cleanViewDetailsByOrderId,
  fetchFundOrdersByOrderId,
  fetchSatelliteRequestsByOrderId,
  executePortfolioOrder,
  importExecutionFilePortfolioOrder,
  consolidateFundOrders,
  fetchConsolidateRequestsByOrderId,
  importConsolidateExecutionFilePortfolioOrder,
  fetchOrderAttachmentsTypeOptions,
  fetchOrderAttachments,
  fetchPortfolioOrderExecutionsByOrderId,
  fetchOrderExecutionsReportByOrderId,
  discardOrderExecutionsByOrderId,
  changeOrderExecutionsToReconciliationByOrderId,
  approveOrderExecutionsByOrderId,
  reconcileOrderExecutionsByOrderId,
  uploadOrderAttachment,
  fetchPortfolioOrderWeightsByOrderId,
  fetchPortfolioOrdersByExecutionId,
  cancelExecutionPortfolioOrder,
} from './portfolioOrder.actions';
import { PortfolioOrderState } from './portfolioOrder.types';

const initialState: PortfolioOrderState = {
  portfolioOrders: {},
  totalPortfolioOrders: {},
  editPortfolioOrder: null,
  viewDetailsByOrderId: {
    portfolioOrders: [],
    totalPortfolioOrders: 0,
    userPortfolioRequests: [],
    totalUserPortfolioRequests: 0,
    fundOrders: [],
    totalFundOrders: 0,
    fetchFundOrdersStatus: fetchStatuses.idle,
    satelliteAccountRequests: [],
    totalSatelliteAccountRequests: 0,
    fetchSatelliteAccountRequestsStatus: fetchStatuses.idle,
    consolidateRequests: [],
    totalConsolidateRequests: 0,
    fetchConsolidateRequestsStatus: fetchStatuses.idle,
    fetchPortfolioOrdersStatus: fetchStatuses.idle,
    fetchUserPortfolioRequestsStatus: fetchStatuses.idle,

    portfolioOrderWeights: [],
    totalPortfolioOrderWeights: 0,
    fetchPortfolioOrderWeightsStatus: fetchStatuses.idle,
  },
  portfolioOrderStatusOptions: [],
  fetchPortfolioOrderStatusOptionsStatus: fetchStatuses.idle,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatusStatus: fetchStatuses.idle,
  saveStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  executeStatus: fetchStatuses.idle,
  cancelExecutionStatus: fetchStatuses.idle,
  consolidateStatus: fetchStatuses.idle,
  importExecutionFileStatus: fetchStatuses.idle,
  importConsolidateExecutionFileStatus: fetchStatuses.idle,

  orderAttachments: [],
  totalOrderAttachments: 0,
  fetchOrderAttachmentsStatus: fetchStatuses.idle,

  orderAttachmentsTypeOptions: [],
  fetchOrderAttachmentsTypeOptionsStatus: fetchStatuses.idle,
  createOrderAttachmentStatus: fetchStatuses.idle,
  uploadOrderAttachmentStatus: fetchStatuses.idle,

  portfolioOrderExecutions: [],
  fetchPortfolioOrderExecutionsStatus: fetchStatuses.idle,

  orderExecutionsReportData: [],
  fetchOrderExecutionsReportStatus: fetchStatuses.idle,

  discardOrderExecutionsStatus: fetchStatuses.idle,

  changeOrderExecutionsToReconciliationStatus: fetchStatuses.idle,

  reconcileOrderExecutionsStatus: fetchStatuses.idle,

  approveOrderExecutionsStatus: fetchStatuses.idle,

  fetchStatuses: {},

  error: null,
};

const portfolioOrdersSlice = createSlice({
  name: 'portfolioOrders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPortfolioOrders.pending, (state, action) => {
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(fetchPortfolioOrders.fulfilled, (state, action: PayloadAction<FetchPortfolioOrdersResponse>) => {
      const requestId = (action as any).meta.arg.requestId || (action as any).meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.success;

      if (action.payload) {
        state.portfolioOrders[requestId] = action.payload.data?.items;
        state.totalPortfolioOrders[requestId] = action.payload.data?.totalItems;
      }
    });
    builder.addCase(fetchPortfolioOrders.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.rejected;

      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchPortfolioOrdersByExecutionId.pending, (state, action) => {
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.pending;
      state.error = null;
    });
    builder.addCase(
      fetchPortfolioOrdersByExecutionId.fulfilled,
      (state, action: PayloadAction<FetchPortfolioOrdersResponse>) => {
        const requestId = (action as any).meta.arg.requestId || (action as any).meta.requestId;

        state.fetchStatuses[requestId] = fetchStatuses.success;

        if (action.payload) {
          state.portfolioOrders[requestId] = action.payload.data?.items;
          state.totalPortfolioOrders[requestId] = action.payload.data?.totalItems;
        }
      },
    );
    builder.addCase(fetchPortfolioOrdersByExecutionId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;
      const requestId = action.meta.arg.requestId || action.meta.requestId;

      state.fetchStatuses[requestId] = fetchStatuses.rejected;

      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchOrderAttachments.pending, (state) => {
      state.error = null;
      state.fetchOrderAttachmentsStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchOrderAttachments.fulfilled, (state, action: PayloadAction<FetchOrderAttachmentsResponse>) => {
      state.orderAttachments = action.payload.data?.items;
      state.totalOrderAttachments = action.payload.data?.totalItems;
      state.fetchOrderAttachmentsStatus = fetchStatuses.success;
    });
    builder.addCase(fetchOrderAttachments.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.fetchOrderAttachmentsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchOrderAttachmentsTypeOptions.pending, (state) => {
      state.error = null;
      state.fetchOrderAttachmentsTypeOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchOrderAttachmentsTypeOptions.fulfilled,
      (state, action: PayloadAction<FetchOrderAttachmentsTypeOptionsResponse>) => {
        state.orderAttachmentsTypeOptions = action.payload.data;
        state.fetchOrderAttachmentsTypeOptionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchOrderAttachmentsTypeOptions.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.fetchOrderAttachmentsTypeOptionsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchPortfolioOrdersByOrderId.pending, (state) => {
      state.error = null;
      state.viewDetailsByOrderId.fetchPortfolioOrdersStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchPortfolioOrdersByOrderId.fulfilled,
      (state, action: PayloadAction<FetchPortfolioOrdersResponse>) => {
        state.viewDetailsByOrderId.portfolioOrders = action.payload.data?.items;
        state.viewDetailsByOrderId.totalPortfolioOrders = action.payload.data?.totalItems;
        state.viewDetailsByOrderId.fetchPortfolioOrdersStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchPortfolioOrdersByOrderId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.viewDetailsByOrderId.fetchPortfolioOrdersStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchPortfolioOrderWeightsByOrderId.pending, (state) => {
      state.error = null;
      state.viewDetailsByOrderId.fetchPortfolioOrderWeightsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchPortfolioOrderWeightsByOrderId.fulfilled,
      (state, action: PayloadAction<FetchPortfolioOrderWeightsResponse>) => {
        state.viewDetailsByOrderId.portfolioOrderWeights = action.payload.data?.items;
        state.viewDetailsByOrderId.totalPortfolioOrderWeights = action.payload.data?.totalItems;
        state.viewDetailsByOrderId.fetchPortfolioOrderWeightsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchPortfolioOrderWeightsByOrderId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.viewDetailsByOrderId.fetchPortfolioOrderWeightsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchPortfolioOrderExecutionsByOrderId.pending, (state) => {
      state.error = null;
      state.fetchPortfolioOrderExecutionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchPortfolioOrderExecutionsByOrderId.fulfilled,
      (state, action: PayloadAction<FetchPortfolioOrderExecutionsResponse>) => {
        state.portfolioOrderExecutions = action.payload.data;
        // state.portfolioOrderExecutions = [];
        state.fetchPortfolioOrderExecutionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchPortfolioOrderExecutionsByOrderId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.fetchPortfolioOrderExecutionsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchOrderExecutionsReportByOrderId.pending, (state) => {
      state.error = null;
      state.fetchOrderExecutionsReportStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchOrderExecutionsReportByOrderId.fulfilled,
      (state, action: PayloadAction<FetchPortfolioOrderExecutionReportResponse>) => {
        state.orderExecutionsReportData = action.payload.data;
        state.fetchOrderExecutionsReportStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchOrderExecutionsReportByOrderId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.fetchOrderExecutionsReportStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(discardOrderExecutionsByOrderId.pending, (state) => {
      state.error = null;
      state.discardOrderExecutionsStatus = fetchStatuses.pending;
    });
    builder.addCase(discardOrderExecutionsByOrderId.fulfilled, (state) => {
      state.discardOrderExecutionsStatus = fetchStatuses.success;
    });
    builder.addCase(discardOrderExecutionsByOrderId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.discardOrderExecutionsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(reconcileOrderExecutionsByOrderId.pending, (state) => {
      state.error = null;
      state.reconcileOrderExecutionsStatus = fetchStatuses.pending;
    });
    builder.addCase(reconcileOrderExecutionsByOrderId.fulfilled, (state) => {
      state.reconcileOrderExecutionsStatus = fetchStatuses.success;
    });
    builder.addCase(reconcileOrderExecutionsByOrderId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.reconcileOrderExecutionsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(approveOrderExecutionsByOrderId.pending, (state) => {
      state.error = null;
      state.approveOrderExecutionsStatus = fetchStatuses.pending;
    });
    builder.addCase(approveOrderExecutionsByOrderId.fulfilled, (state) => {
      state.approveOrderExecutionsStatus = fetchStatuses.success;
    });
    builder.addCase(approveOrderExecutionsByOrderId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.approveOrderExecutionsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(changeOrderExecutionsToReconciliationByOrderId.pending, (state) => {
      state.error = null;
      state.changeOrderExecutionsToReconciliationStatus = fetchStatuses.pending;
    });
    builder.addCase(changeOrderExecutionsToReconciliationByOrderId.fulfilled, (state) => {
      state.changeOrderExecutionsToReconciliationStatus = fetchStatuses.success;
    });
    builder.addCase(changeOrderExecutionsToReconciliationByOrderId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.changeOrderExecutionsToReconciliationStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchUserPortfolioRequestsByOrderId.pending, (state) => {
      state.error = null;
      state.viewDetailsByOrderId.fetchUserPortfolioRequestsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchUserPortfolioRequestsByOrderId.fulfilled,
      (state, action: PayloadAction<FetchUserPortfolioRequestsResponse>) => {
        state.viewDetailsByOrderId.userPortfolioRequests = action.payload.data?.items;
        state.viewDetailsByOrderId.totalUserPortfolioRequests = action.payload.data?.totalItems;
        state.viewDetailsByOrderId.fetchUserPortfolioRequestsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserPortfolioRequestsByOrderId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.viewDetailsByOrderId.fetchUserPortfolioRequestsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchFundOrdersByOrderId.pending, (state) => {
      state.error = null;
      state.viewDetailsByOrderId.fetchFundOrdersStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchFundOrdersByOrderId.fulfilled, (state, action: PayloadAction<FetchFundOrdersResponse>) => {
      state.viewDetailsByOrderId.fundOrders = action.payload.data?.items;
      state.viewDetailsByOrderId.totalFundOrders = action.payload.data?.totalItems;
      state.viewDetailsByOrderId.fetchFundOrdersStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFundOrdersByOrderId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.viewDetailsByOrderId.fetchFundOrdersStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchSatelliteRequestsByOrderId.pending, (state) => {
      state.error = null;
      state.viewDetailsByOrderId.fetchSatelliteAccountRequestsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchSatelliteRequestsByOrderId.fulfilled,
      (state, action: PayloadAction<FetchSatelliteAccountRequestsResponse>) => {
        state.viewDetailsByOrderId.satelliteAccountRequests = action.payload.data?.items;
        state.viewDetailsByOrderId.totalSatelliteAccountRequests = action.payload.data?.totalItems;
        state.viewDetailsByOrderId.fetchSatelliteAccountRequestsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchSatelliteRequestsByOrderId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.viewDetailsByOrderId.fetchSatelliteAccountRequestsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchConsolidateRequestsByOrderId.pending, (state) => {
      state.error = null;
      state.viewDetailsByOrderId.fetchConsolidateRequestsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchConsolidateRequestsByOrderId.fulfilled,
      (state, action: PayloadAction<FetchConsolidateRequestsResponse>) => {
        state.viewDetailsByOrderId.consolidateRequests = action.payload.data?.items;
        state.viewDetailsByOrderId.totalConsolidateRequests = action.payload.data?.totalItems;
        state.viewDetailsByOrderId.fetchConsolidateRequestsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchConsolidateRequestsByOrderId.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.viewDetailsByOrderId.fetchConsolidateRequestsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchPortfolioOrder.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchPortfolioOrder.fulfilled, (state, action: PayloadAction<FetchPortfolioOrderResponse>) => {
      state.editPortfolioOrder = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchPortfolioOrder.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.fetchStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(fetchPortfolioOrderStatusOptions.pending, (state) => {
      state.error = null;
      state.fetchPortfolioOrderStatusOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchPortfolioOrderStatusOptions.fulfilled,
      (state, action: PayloadAction<FetchPortfolioOrderStatusOptionsResponse>) => {
        state.portfolioOrderStatusOptions = action.payload.data;
        state.fetchPortfolioOrderStatusOptionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchPortfolioOrderStatusOptions.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.fetchPortfolioOrderStatusOptionsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(updatePortfolioOrderStatus.pending, (state) => {
      state.error = null;
      state.updateStatusStatus = fetchStatuses.pending;
    });
    builder.addCase(updatePortfolioOrderStatus.fulfilled, (state) => {
      state.updateStatusStatus = fetchStatuses.success;
    });
    builder.addCase(updatePortfolioOrderStatus.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.updateStatusStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(savePortfolioOrder.pending, (state) => {
      state.error = null;
      state.saveStatus = fetchStatuses.pending;
    });
    builder.addCase(savePortfolioOrder.fulfilled, (state, action: PayloadAction<CreatePortfolioOrderResponse>) => {
      state.editPortfolioOrder = state.editPortfolioOrder || ({} as any);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      // Object.assign(state.editPortfolioOrder!, {
      //   ...action.payload.data,
      //   status: { id: action.payload.data.status },
      //   userPortfolioRequest: { id: action.payload.data.userPortfolioRequest },
      // });
      state.saveStatus = fetchStatuses.success;
    });
    builder.addCase(savePortfolioOrder.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.saveStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(createPortfolioOrder.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
    });
    builder.addCase(createPortfolioOrder.fulfilled, (state) => {
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createPortfolioOrder.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.createStatus = fetchStatuses.rejected;
      state.fetchPortfolioOrderExecutionsStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(executePortfolioOrder.pending, (state) => {
      state.error = null;
      state.executeStatus = fetchStatuses.pending;
    });
    builder.addCase(executePortfolioOrder.fulfilled, (state) => {
      state.executeStatus = fetchStatuses.success;
    });
    builder.addCase(executePortfolioOrder.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.executeStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(cancelExecutionPortfolioOrder.pending, (state) => {
      state.error = null;
      state.cancelExecutionStatus = fetchStatuses.pending;
    });
    builder.addCase(cancelExecutionPortfolioOrder.fulfilled, (state) => {
      state.cancelExecutionStatus = fetchStatuses.success;
    });
    builder.addCase(cancelExecutionPortfolioOrder.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.cancelExecutionStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(consolidateFundOrders.pending, (state) => {
      state.error = null;
      state.consolidateStatus = fetchStatuses.pending;
    });
    builder.addCase(consolidateFundOrders.fulfilled, (state) => {
      state.consolidateStatus = fetchStatuses.success;
    });
    builder.addCase(consolidateFundOrders.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.consolidateStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(importExecutionFilePortfolioOrder.pending, (state) => {
      state.error = null;
      state.importExecutionFileStatus = fetchStatuses.pending;
    });
    builder.addCase(importExecutionFilePortfolioOrder.fulfilled, (state) => {
      state.importExecutionFileStatus = fetchStatuses.success;
    });
    builder.addCase(importExecutionFilePortfolioOrder.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.importExecutionFileStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(importConsolidateExecutionFilePortfolioOrder.pending, (state) => {
      state.error = null;
      state.importConsolidateExecutionFileStatus = fetchStatuses.pending;
    });
    builder.addCase(importConsolidateExecutionFilePortfolioOrder.fulfilled, (state) => {
      state.importConsolidateExecutionFileStatus = fetchStatuses.success;
    });
    builder.addCase(importConsolidateExecutionFilePortfolioOrder.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.importConsolidateExecutionFileStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(deletePortfolioOrder.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
    });
    builder.addCase(deletePortfolioOrder.fulfilled, (state) => {
      state.editPortfolioOrder = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deletePortfolioOrder.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.deleteStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(createOrderAttachment.pending, (state) => {
      state.error = null;
      state.createOrderAttachmentStatus = fetchStatuses.idle;
    });
    builder.addCase(createOrderAttachment.fulfilled, (state) => {
      state.createOrderAttachmentStatus = fetchStatuses.success;
    });
    builder.addCase(createOrderAttachment.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.createOrderAttachmentStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(uploadOrderAttachment.pending, (state) => {
      state.error = null;
      state.uploadOrderAttachmentStatus = fetchStatuses.idle;
    });
    builder.addCase(uploadOrderAttachment.fulfilled, (state) => {
      state.uploadOrderAttachmentStatus = fetchStatuses.success;
    });
    builder.addCase(uploadOrderAttachment.rejected, (state, action) => {
      const payload = (action.payload || {}) as IAnyObject;

      state.uploadOrderAttachmentStatus = fetchStatuses.rejected;
      state.error = { ...action.error, ...payload };
    });

    builder.addCase(resetEditPortfolioOrder.type, (state) => {
      state.editPortfolioOrder = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.saveStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanViewDetailsByOrderId.type, (state) => {
      state.viewDetailsByOrderId = initialState.viewDetailsByOrderId;
    });

    builder.addCase(cleanPortfolioOrders.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const portfolioOrderReducer = portfolioOrdersSlice.reducer;
