import { FetchAggregatedStatisticsResponse, RestoreAggregatedStatisticsRequest } from '../types/aggregated-statistic';
import { PaginatedRequest } from '../types/api';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class AggregatedStatisticService extends ApiService {
  pathname = 'admin/aggregated-statistics';

  fetchAggregatedStatistics = async (data: PaginatedRequest) =>
    this.get<FetchAggregatedStatisticsResponse>(`${this.pathname}?${toQueryParams(data)}`);

  restoreAggragatedStatistics = async (data: RestoreAggregatedStatisticsRequest) =>
    this.post(`${this.pathname}/restore`, data);
}

export const aggregatedStatisticService = new AggregatedStatisticService();
