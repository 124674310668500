import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { GridActionsCellItem, GridActionsCellItemProps, GridRowParams, GridColDef } from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { usePermissions, useSearchParamsState } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import {
  cleanUserCategorys,
  deleteUserCategory,
  fetchUserCategorys,
} from '../../redux/modules/userCategory/userCategory.actions';
import { userCategorySelector } from '../../redux/modules/userCategory/userCategory.selectors';
import { UserCategory } from '../../types/userCategory';
import { parseJSON } from '../../utils/json';
import * as permissions from '../../utils/permissions';
import { UserCategoryDialog } from './UserCategoryDialog';
import { ProtectedContent } from '../../components/ProtectedContent';

export function UserCategoryList() {
  const dispatch = useAppDispatch();
  const tableRef = useRef<TableRef>(null);
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState<UserCategory | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'nameEn',
      headerName: 'Name En',
      flex: 1,
    },
    {
      field: 'nameAr',
      headerName: 'Name Ar',
      flex: 1,
    },
    {
      field: 'descriptionEn',
      headerName: 'Description En',
      flex: 1,
    },
    {
      field: 'descriptionAr',
      headerName: 'Description Ar',
      flex: 1,
    },
    {
      field: 'minScore',
      headerName: 'Min Score',
      flex: 1,
    },
    {
      field: 'maxScore',
      headerName: 'Max Score',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 100,
    },
  ]);
  const canDelete = usePermissions({ items: [permissions.UserCategory.delete] });
  const canUpdate = usePermissions({ items: [permissions.UserCategory.update] });
  const [initialFilterModel, setInitialFilterModel] = useSearchParamsState('filter', '');
  const [initialPage, setInitialPage] = useSearchParamsState('page', '0');
  const [initialPageSize, setInitialPageSize] = useSearchParamsState('pageSize', '100');

  const onDeleteUserCategory = (id: number) => {
    dispatch(deleteUserCategory(id));
  };

  const onEditUserCategory = (row: UserCategory): void => {
    setEditValue(row);
    setOpen(true);
  };

  const { totalUserCategorys, userCategorys, fetchStatus, deleteStatus } = useSelector(userCategorySelector);

  const onAddNew = (): void => {
    setOpen(true);
  };

  const handleClose = (isReload: boolean) => {
    if (isReload) {
      tableRef?.current?.reload();
    }
    setEditValue(null);
    setOpen(false);
  };

  useEffect(() => {
    const actionColumn = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      filterable: false,
      sortable: false,
      getActions: (params: GridRowParams) => {
        const actions: React.ReactElement<GridActionsCellItemProps>[] = [];

        if (canDelete) {
          actions.push(
            <GridActionsCellItem
              key={`userCategory-list-delete-${params.row.id}`}
              icon={<DeleteOutlineOutlinedIcon />}
              label="Link"
              onClick={() => onDeleteUserCategory(params.row.id)}
            />,
          );
        }

        if (canUpdate) {
          actions.push(
            <GridActionsCellItem
              key={`userCategory-list-update-${params.row.id}`}
              icon={<EditOutlinedIcon />}
              label="Actions"
              onClick={() => onEditUserCategory(params.row)}
            />,
          );
        }

        return actions;
      },
    };

    if (canDelete || canUpdate) {
      setColumns([actionColumn, ...columns]);
    }
  }, [canDelete, canUpdate]);

  useEffect(
    () => () => {
      dispatch(cleanUserCategorys());
    },
    [],
  );

  useEffect(() => {
    if (deleteStatus === fetchStatuses.success) {
      handleClose(true);
      enqueueSnackbar('UserCategory deleted!', { variant: 'success' });
    }
    if (deleteStatus === fetchStatuses.rejected) {
      enqueueSnackbar('UserCategory deletion error!', { variant: 'error' });
    }
  }, [deleteStatus]);

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">
          User Categories &nbsp;
          <ProtectedContent items={[permissions.UserCategory.create]}>
            <Fab color="primary" size="small" aria-label="Create UserCategory" onClick={onAddNew}>
              <AddIcon />
            </Fab>
          </ProtectedContent>
        </Typography>
      </Box>

      <UserCategoryDialog open={open} onClose={handleClose} initialValue={editValue} />

      <Table
        ref={tableRef}
        fetchItems={fetchUserCategorys}
        rows={userCategorys}
        columns={columns}
        loading={fetchStatus === fetchStatuses.pending}
        rowCount={totalUserCategorys}
        initialFilterModel={parseJSON(initialFilterModel)}
        initialPage={+initialPage}
        initialPageSize={+initialPageSize}
        onFilterModelChange={(filterModel) => setInitialFilterModel(JSON.stringify(filterModel))}
        onPageChange={(page) => setInitialPage(`${page}`)}
        onPageSizeChange={(pageSize) => setInitialPageSize(`${pageSize}`)}
      />
    </Container>
  );
}
