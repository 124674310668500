import { FetchQueueStatusResponse, FetchServiceVersionsResponse } from '../types/monitoring';
import { ApiService } from './common/ApiService';

class MonitoringService extends ApiService {
  pathname = 'admin';

  fetchServiceVersions = async () => this.get<FetchServiceVersionsResponse>(`${this.pathname}/version`);

  fetchQueueStatus = async () => this.get<FetchQueueStatusResponse>(`${this.pathname}/monitoring/queue/status`);
  fetchQueueMessages = async (queue: string) => this.get<any>(`${this.pathname}/monitoring/queue/${queue}`);
}

export const monitoringService = new MonitoringService();
