import { createSlice, PayloadAction, AnyAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchQueueStatusResponse, FetchServiceVersionsResponse } from '../../../types/monitoring';
import { fetchQueueStatus, cleanMonitoring, fetchQueueMessages, fetchServiceVersions } from './monitoring.actions';
import { MonitoringState } from './monitoring.types';

const initialState: MonitoringState = {
  queueStatus: [],
  queueMessages: {},
  serviceVersions: {},
  fetchQueueMessagesStatus: {},
  fetchQueueStatusStatus: fetchStatuses.idle,
  fetchServiceVersionsStatus: fetchStatuses.idle,
  error: null,
};

const monitoringSlice = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchQueueStatus.pending, (state) => {
      state.fetchQueueStatusStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchQueueStatus.fulfilled, (state, action: PayloadAction<FetchQueueStatusResponse>) => {
      state.queueStatus = action.payload.data;
      state.fetchQueueStatusStatus = fetchStatuses.success;
    });
    builder.addCase(fetchQueueStatus.rejected, (state, action) => {
      state.fetchQueueStatusStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchServiceVersions.pending, (state) => {
      state.fetchServiceVersionsStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchServiceVersions.fulfilled, (state, action: PayloadAction<FetchServiceVersionsResponse>) => {
      state.serviceVersions = action.payload.data;
      state.fetchServiceVersionsStatus = fetchStatuses.success;
    });
    builder.addCase(fetchServiceVersions.rejected, (state, action) => {
      state.fetchServiceVersionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchQueueMessages.pending, (state, action) => {
      state.fetchQueueMessagesStatus[action.meta.arg] = fetchStatuses.pending;
    });
    builder.addCase(fetchQueueMessages.fulfilled, (state, action: AnyAction) => {
      state.fetchQueueMessagesStatus[action.meta.arg] = fetchStatuses.pending;
      state.queueMessages[action.meta.arg] = action.payload.data;
    });
    builder.addCase(fetchQueueMessages.rejected, (state, action) => {
      state.fetchQueueMessagesStatus[action.meta.arg] = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(cleanMonitoring.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const monitoringReducer = monitoringSlice.reducer;
