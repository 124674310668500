// eslint-disable-next-line import/no-unresolved
import { stringQuoteOnlyIfNecessary } from '@json2csv/formatters';
import { FormatterTypes, Parser } from '@json2csv/plainjs';
import { CashReportItem } from '../types/report';

export const prepareCashReportData = (items: CashReportItem[], csvDivider: string) => {
  try {
    const parser = new Parser({
      fields: [
        { label: 'Date', value: 'date' },
        { label: 'Custodian Id', value: 'custodianNumber' },
        { label: 'User Id', value: 'userId' },
        { label: 'VIBAN', value: 'viban' },
        { label: 'VIBAN Incoming', value: 'vibanIncoming' },
        { label: 'Invested Amount', value: 'investedAmount' },
        { label: 'Jadwa Invested amount', value: 'investedAmountAlpaca' },
        { label: 'Alpaca Invested amount', value: 'investedAmountJadwa' },
        { label: 'Jadwa Incoming', value: 'jadwaIncoming' },
        { label: 'Alpaca Incoming', value: 'alpacaIncoming' },
        { label: 'Jadwa Outgoing', value: 'jadwaOutgoing' },
        { label: 'Alpaca Outgoing', value: 'alpacaOutgoing' },
        { label: 'Total Cash Available', value: 'totalCashAvailable' },
        { label: 'Cash On Hold', value: 'cashOnHold' },
        { label: 'Cash Allocation Value', value: 'cashAllocationValue' },
        { label: 'Fee Amount', value: 'feeAmount' },
        { label: 'Anb Sof Cash Balance', value: 'anbSofCashBalance' },
        { label: 'Anb Eof Cash Balance', value: 'anbEofCashBalance' },
        { label: 'Anb Cash Balance', value: 'anbCashBalance' },
        { label: 'Net Withdrawals', value: 'netWithdrawals' },
      ],
      delimiter: csvDivider,
      formatters: {
        [FormatterTypes.header]: stringQuoteOnlyIfNecessary(),
        [FormatterTypes.string]: stringQuoteOnlyIfNecessary(),
      },
    });

    const reportItems = items.map((item) => {
      const reportItem = {
        date: item.date,
        custodianNumber: item.custodianNumber,
        userId: item.userId,
        viban: item.viban,
        vibanIncoming: item.vibanIncoming,
        investedAmount: item.investedAmount,
        investedAmountAlpaca: item.investedAmountAlpaca,
        investedAmountJadwa: item.investedAmountJadwa,
        jadwaIncoming: item.jadwaIncoming,
        alpacaIncoming: item.alpacaIncoming,
        jadwaOutgoing: item.jadwaOutgoing,
        alpacaOutgoing: item.alpacaOutgoing,
        totalCashAvailable: item.totalCashAvailable,
        cashOnHold: item.cashOnHold,
        cashAllocationValue: item.cashAllocationValue,
        feeAmount: item.feeAmount,
        anbSofCashBalance: item.anbSofCashBalance,
        anbEofCashBalance: item.anbEofCashBalance,
        anbCashBalance: item.anbCashBalance,
        netWithdrawals: item.netWithdrawals,
      };

      for (const asset of item.assets) {
        reportItem[asset.nameEn] = asset.profit;
      }

      return reportItem;
    });

    const csv = parser.parse(reportItems);

    return csv;
  } catch (err) {
    console.error('[prepareCashReportData error]', err);

    return '';
  }
};
