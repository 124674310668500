import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { userAddressService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { CreateUserAddressRequest, UpdateUserAddressRequest } from '../../../types/userAddress';

export const fetchUserAddresses = createAsyncThunk('userAddress/fetch-all', (data: PaginatedRequest) =>
  userAddressService.fetchUserAddresses(data),
);

export const fetchUserAddress = createAsyncThunk('userAddress/get', (id: number) =>
  userAddressService.fetchUserAddress(id),
);

export const deleteUserAddress = createAsyncThunk('userAddress/delete', (id: number) =>
  userAddressService.deleteUserAddress(id),
);

export const setPrimaryUserAddress = createAsyncThunk('userAddress/set-default', (id: number) =>
  userAddressService.setPrimaryUserAddress(id),
);

export const createUserAddress = createAsyncThunk('userAddress/create', (userAddress: CreateUserAddressRequest) =>
  userAddressService.createUserAddress(userAddress),
);

export const updateUserAddress = createAsyncThunk('userAddress/update', (userAddress: UpdateUserAddressRequest) =>
  userAddressService.updateUserAddress(userAddress),
);

export const resetEditUserAddress = createAction('userAddress/reset-edit');

export const cleanUserAddresses = createAction('userAddress/clean');
