import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { FeeBatchStatus } from '../../types/feeBatch';

export const feeBatchStatusToInfo: {
  [prop in FeeBatchStatus]: {
    color: 'error' | 'info' | 'success' | 'warning';
    icon: React.ElementType;
    menuAction: string;
  };
} = {
  [FeeBatchStatus.CREATED]: {
    color: 'info',
    menuAction: 'Change to Created',
    icon: InfoIcon,
  },
  [FeeBatchStatus.IN_PROGRESS]: {
    color: 'info',
    menuAction: 'Change to In Progress',
    icon: InfoIcon,
  },
  [FeeBatchStatus.CANCELLED]: {
    color: 'error',
    menuAction: 'Cancel',
    icon: CheckCircleIcon,
  },
  [FeeBatchStatus.PARTIALLY_PUSHED]: {
    color: 'warning',
    menuAction: 'Change to waiting cast approval',
    icon: WarningIcon,
  },
  [FeeBatchStatus.PUSHED]: {
    color: 'warning',
    menuAction: 'Change to approved by cast',
    icon: WarningIcon,
  },
  [FeeBatchStatus.PARTIALLY_EXECUTED]: {
    color: 'success',
    menuAction: 'Partially execute',
    icon: CheckCircleIcon,
  },
  [FeeBatchStatus.EXECUTED]: {
    color: 'success',
    menuAction: 'Change to executed',
    icon: CheckCircleIcon,
  },
};
