import { ActionType } from '../../types/action';

const listPermission = {
  resource: 'portfolio-orders',
  action: ActionType.READ,
};

const createPermission = {
  resource: 'fee-batch',
  action: ActionType.CREATE,
};
const updatePermission = {
  resource: 'fee-batch',
  action: ActionType.UPDATE,
};
const readPermission = {
  resource: 'fee-batch',
  action: ActionType.READ,
};
const deletePermission = {
  resource: 'fee-batch',
  action: ActionType.DELETE,
};
const executePermission = {
  resource: 'fee-batch-execute',
  action: ActionType.CREATE,
};

export const FeeBatch = {
  list: listPermission,
  read: readPermission,
  create: createPermission,
  update: updatePermission,
  delete: deletePermission,
  execute: executePermission,
};
