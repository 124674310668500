import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { fundValuationDayService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { CreateFundValuationDayRequest, UpdateFundValuationDayRequest } from '../../../types/fundValuationDay';

export const fetchFundValuationDays = createAsyncThunk('fundValuationDay/fetch-all', (data: PaginatedRequest) =>
  fundValuationDayService.fetchFundValuationDays(data),
);

export const fetchFundValuationDaysAutocomplete = createAsyncThunk(
  'fundValuationDay/fetch-autocomplete',
  (term: string) => fundValuationDayService.fetchFundValuationDaysAutocomplete(term),
);

export const fetchFundValuationDay = createAsyncThunk('fundValuationDay/get', (id: number) =>
  fundValuationDayService.fetchFundValuationDay(id),
);

export const deleteFundValuationDay = createAsyncThunk('fundValuationDay/delete', (id: number) =>
  fundValuationDayService.deleteFundValuationDay(id),
);

export const createFundValuationDay = createAsyncThunk(
  'fundValuationDay/create',
  (fundValuationDay: CreateFundValuationDayRequest) => fundValuationDayService.createFundValuationDay(fundValuationDay),
);

export const updateFundValuationDay = createAsyncThunk(
  'fundValuationDay/update',
  (fundValuationDay: UpdateFundValuationDayRequest) => fundValuationDayService.updateFundValuationDay(fundValuationDay),
);

export const resetEditFundValuationDay = createAction('fundValuationDay/reset-edit');

export const cleanFundValuationDays = createAction('fundValuationDay/clean');
