import {
  CreatePortfolioConfigRequest,
  CreatePortfolioConfigResponse,
  FetchPortfolioConfigResponse,
  FetchPortfolioConfigsResponse,
  UpdatePortfolioConfigRequest,
  UpdatePortfolioConfigResponse,
  UpdatePortfolioConfigsRequest,
} from '../types/portfolioConfig';
import { ApiService } from './common/ApiService';

class PortfolioConfigService extends ApiService {
  pathname = 'admin/portfolio-config';

  fetchPortfolioConfigs = async () => this.get<FetchPortfolioConfigsResponse>(`${this.pathname}`);

  fetchPortfolioConfig = async (key: string) => this.get<FetchPortfolioConfigResponse>(`${this.pathname}/${key}`);

  deletePortfolioConfig = async (key: string) => this.delete<FetchPortfolioConfigResponse>(`${this.pathname}/${key}`);

  createPortfolioConfig = async (action: CreatePortfolioConfigRequest) =>
    this.post<CreatePortfolioConfigResponse, CreatePortfolioConfigRequest>(`${this.pathname}`, action);

  updatePortfolioConfig = async ({ key, ...action }: UpdatePortfolioConfigRequest) =>
    this.put<UpdatePortfolioConfigResponse, Omit<UpdatePortfolioConfigRequest, 'key'>>(
      `${this.pathname}/${key}`,
      action,
    );

  updatePortfolioConfigs = async (data: UpdatePortfolioConfigRequest[]) =>
    this.put<FetchPortfolioConfigsResponse, UpdatePortfolioConfigsRequest>(`${this.pathname}`, { items: data });
}

export const portfolioConfigService = new PortfolioConfigService();
