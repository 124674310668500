import { FetchMediaFileResponse, UploadMediaFileRequest, UploadMediaFileResponse } from '../types/media';
import { ApiService } from './common/ApiService';

class MediaService extends ApiService {
  pathname = 'media';

  constructor() {
    super(process.env.REACT_APP_MEDIA_API_URI);
  }

  uploadPrivate = async ({ file, entity }: UploadMediaFileRequest) => {
    const formData = new FormData();
    formData.append('file', file);

    return this.post<UploadMediaFileResponse, FormData>(`${this.pathname}/upload/${entity}/private`, formData);
  };

  uploadPublic = async ({ file, entity }: UploadMediaFileRequest) => {
    const formData = new FormData();
    formData.append('file', file);

    return this.post<UploadMediaFileResponse, FormData>(`${this.pathname}/upload/${entity}/public`, formData);
  };

  getPrivateFile = async (fileName: string) =>
    this.get<FetchMediaFileResponse>(`${this.pathname}/upload/private/${fileName}`);

  getPublicFile = async (fileName: string) =>
    this.get<FetchMediaFileResponse>(`${this.pathname}/upload/public/${fileName}`);
}

export const mediaService = new MediaService();
