import FiberNewIcon from '@mui/icons-material/FiberNew';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CopyToClipboardButton } from '../../../../components/CopyToClipboardButton';
import { ProtectedContent } from '../../../../components/ProtectedContent';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { useAppDispatch } from '../../../../redux/hooks';
import { createAlpacaAccount, fetchAccountByUserId } from '../../../../redux/modules/alpaca/alpaca.actions';
import { alpacaSelector } from '../../../../redux/modules/alpaca/alpaca.selectors';
import * as permissions from '../../../../utils/permissions';
import { AlpacaEventsTable } from './AlpacaEventsTable';

interface AlpacaTabProps {
  id: number;
}

export function AlpacaTab({ id }: AlpacaTabProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { account, error, createAlpacaAccountStatus } = useSelector(alpacaSelector);

  useEffect(() => {
    if (id) {
      dispatch(fetchAccountByUserId(id));
    }
  }, []);

  const createAccount = async () => {
    if (id) {
      try {
        await dispatch(createAlpacaAccount(id)).unwrap();

        enqueueSnackbar('Alpaca Account created successfully!', { variant: 'success' });
      } catch (err: any) {
        enqueueSnackbar('Create Alpaca Account was failed', { variant: 'error' });
      }
      dispatch(fetchAccountByUserId(id));
    }
  };

  const renderAccountInfo = () => {
    if (!account) {
      return (
        <Grid item>
          <ProtectedContent items={[permissions.AlpacaPermissions.createAccount]}>
            <LoadingButton
              id="CreateAccountButton"
              color="primary"
              variant="contained"
              sx={{ ml: 2 }}
              onClick={createAccount}
              startIcon={<FiberNewIcon />}
              loading={createAlpacaAccountStatus === fetchStatuses.pending}
              loadingPosition="end"
            >
              Create Account
            </LoadingButton>
          </ProtectedContent>
        </Grid>
      );
    }

    return (
      <>
        <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: 0 }} spacing={2}>
          <Grid item container xs={8}>
            <Grid item xs={6}>
              <Typography variant="h6">Account Id</Typography>
            </Grid>
            <Grid item xs={6}>
              <CopyToClipboardButton text={account.id} value={account.id} />
            </Grid>
          </Grid>
          <Grid item container xs={8}>
            <Grid item xs={6}>
              <Typography variant="h6">Status</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">{account.status}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }} spacing={2}>
          <Typography variant="h6">Events</Typography>
          <AlpacaEventsTable accountId={account.id} />
        </Grid>
      </>
    );
  };

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      {renderAccountInfo()}
    </>
  );
}
