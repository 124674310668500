import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchAppConfigResponse, FetchAppConfigsResponse } from '../../../types/appConfig';
import {
  fetchAppConfig,
  fetchAppConfigs,
  resetEditAppConfig,
  cleanAppConfigs,
  updateAppConfig,
  createAppConfigItem,
  deleteAppConfig,
} from './appConfig.actions';
import { AppConfigState } from './appConfig.types';

const initialState: AppConfigState = {
  appConfigs: [],
  editAppConfig: null,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const appConfigsSlice = createSlice({
  name: 'appConfigs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAppConfigs.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchAppConfigs.fulfilled, (state, appConfig: PayloadAction<FetchAppConfigsResponse>) => {
      state.appConfigs = appConfig.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchAppConfigs.rejected, (state, appConfig) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = appConfig.error;
    });

    builder.addCase(fetchAppConfig.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchAppConfig.fulfilled, (state, appConfig: PayloadAction<FetchAppConfigResponse>) => {
      state.editAppConfig = appConfig.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchAppConfig.rejected, (state, appConfig) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = appConfig.error;
    });

    builder.addCase(updateAppConfig.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateAppConfig.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateAppConfig.rejected, (state, appConfig) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = appConfig.error;
    });

    builder.addCase(createAppConfigItem.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(createAppConfigItem.fulfilled, (state, appConfig: PayloadAction<FetchAppConfigResponse>) => {
      state.editAppConfig = appConfig.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createAppConfigItem.rejected, (state, appConfig) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = appConfig.error;
    });

    builder.addCase(deleteAppConfig.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteAppConfig.fulfilled, (state) => {
      state.editAppConfig = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteAppConfig.rejected, (state, appConfig) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = appConfig.error;
    });

    builder.addCase(resetEditAppConfig.type, (state) => {
      state.editAppConfig = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanAppConfigs.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const appConfigReducer = appConfigsSlice.reducer;
