import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchFundCategoryOptionsResponse,
  FetchFundNabHistoryResponse,
  FetchFundNavDataResponse,
  FetchFundRiskLevelOptionsResponse,
  FetchFundSourceOptionsResponse,
  FetchGicsOptionsResponse,
  FetchFundStatusOptionsResponse,
  FetchFundTypeOptionsResponse,
  FetchIndexFundResponse,
  FetchIndexFundsResponse,
} from '../../../types/indexFund';
import {
  bulkUpdateIndexFundNavs,
  changeDisplayCustom,
  cleanIndexFunds,
  createIndexFund,
  deleteIndexFund,
  exportFundAndBenchmarkNavData,
  fetchFundCategoryOptions,
  fetchFundNavHistory,
  fetchFundRiskLevelOptions,
  fetchFundSourceOptions,
  fetchFundStatusOptions,
  fetchFundTypeOptions,
  fetchGicsOptions,
  fetchIndexFund,
  fetchIndexFunds,
  fetchNavDataByFund,
  getExampleNavDataFile,
  importNavDataFile,
  resetEditIndexFund,
  updateIndexFund,
} from './indexFund.actions';
import { IndexFundState } from './indexFund.types';

const initialState: IndexFundState = {
  indexFunds: [],
  totalIndexFunds: 0,
  editIndexFund: null,
  fundRiskLevelOptions: [],
  fundCategoryOptions: [],
  fundStatusOptions: [],
  fundTypeOptions: [],
  fundSourceOptions: [],
  gicsOptions: [],
  fundNavData: [],
  fundNavHistoryItems: [],
  totalFundNavHistoryItems: 0,
  fetchFundNavHistoryStatus: fetchStatuses.idle,
  fetchFundRiskLevelOptionsStatus: fetchStatuses.idle,
  fetchFundCategoryOptionsStatus: fetchStatuses.idle,
  fetchFundStatusOptionsStatus: fetchStatuses.idle,
  fetchFundTypeOptionsStatus: fetchStatuses.idle,
  fetchFundSourceOptionsStatus: fetchStatuses.idle,
  fetchGicsOptionsStatus: fetchStatuses.idle,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  importNavDataFileStatus: fetchStatuses.idle,
  getExampleNavDataFileStatus: fetchStatuses.idle,
  exportFundAndBenchmarkNavDataStatus: fetchStatuses.idle,
  fetchFundNavDataByFundStatus: fetchStatuses.idle,
  error: null,
};

const IndexFundsSlice = createSlice({
  name: 'IndexFunds',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchIndexFunds.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchIndexFunds.fulfilled, (state, action: PayloadAction<FetchIndexFundsResponse>) => {
      state.indexFunds = action.payload.data?.items;
      state.totalIndexFunds = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchIndexFunds.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFundNavHistory.pending, (state) => {
      state.fetchFundNavHistoryStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchFundNavHistory.fulfilled, (state, action: PayloadAction<FetchFundNabHistoryResponse>) => {
      state.fundNavHistoryItems = action.payload.data?.items;
      state.totalFundNavHistoryItems = action.payload.data?.totalItems;
      state.fetchFundNavHistoryStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFundNavHistory.rejected, (state, action) => {
      state.fetchFundNavHistoryStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchIndexFund.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchIndexFund.fulfilled, (state, action: PayloadAction<FetchIndexFundResponse>) => {
      state.editIndexFund = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchIndexFund.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFundRiskLevelOptions.pending, (state) => {
      state.error = null;
      state.fetchFundRiskLevelOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchFundRiskLevelOptions.fulfilled,
      (state, action: PayloadAction<FetchFundRiskLevelOptionsResponse>) => {
        state.fundRiskLevelOptions = action.payload.data;
        state.fetchFundRiskLevelOptionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFundRiskLevelOptions.rejected, (state, action) => {
      state.fetchFundRiskLevelOptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFundCategoryOptions.pending, (state) => {
      state.error = null;
      state.fetchFundCategoryOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchFundCategoryOptions.fulfilled,
      (state, action: PayloadAction<FetchFundCategoryOptionsResponse>) => {
        state.fundCategoryOptions = action.payload.data;
        state.fetchFundCategoryOptionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFundCategoryOptions.rejected, (state, action) => {
      state.fetchFundCategoryOptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFundStatusOptions.pending, (state) => {
      state.error = null;
      state.fetchFundStatusOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchFundStatusOptions.fulfilled,
      (state, action: PayloadAction<FetchFundStatusOptionsResponse>) => {
        state.fundStatusOptions = action.payload.data;
        state.fetchFundStatusOptionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFundStatusOptions.rejected, (state, action) => {
      state.fetchFundStatusOptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFundTypeOptions.pending, (state) => {
      state.error = null;
      state.fetchFundTypeOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchFundTypeOptions.fulfilled, (state, action: PayloadAction<FetchFundTypeOptionsResponse>) => {
      state.fundTypeOptions = action.payload.data;
      state.fetchFundTypeOptionsStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFundTypeOptions.rejected, (state, action) => {
      state.fetchFundTypeOptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFundSourceOptions.pending, (state) => {
      state.error = null;
      state.fetchFundSourceOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchFundSourceOptions.fulfilled,
      (state, action: PayloadAction<FetchFundSourceOptionsResponse>) => {
        state.fundSourceOptions = action.payload.data;
        state.fetchFundSourceOptionsStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFundSourceOptions.rejected, (state, action) => {
      state.fetchFundSourceOptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchGicsOptions.pending, (state) => {
      state.error = null;
      state.fetchGicsOptionsStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchGicsOptions.fulfilled, (state, action: PayloadAction<FetchGicsOptionsResponse>) => {
      state.gicsOptions = action.payload.data;
      state.fetchGicsOptionsStatus = fetchStatuses.success;
    });
    builder.addCase(fetchGicsOptions.rejected, (state, action) => {
      state.fetchGicsOptionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateIndexFund.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateIndexFund.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateIndexFund.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(bulkUpdateIndexFundNavs.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
    });
    builder.addCase(bulkUpdateIndexFundNavs.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(bulkUpdateIndexFundNavs.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createIndexFund.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(createIndexFund.fulfilled, (state, action: PayloadAction<FetchIndexFundResponse>) => {
      state.editIndexFund = action.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createIndexFund.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteIndexFund.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteIndexFund.fulfilled, (state) => {
      state.editIndexFund = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteIndexFund.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(importNavDataFile.pending, (state) => {
      state.error = null;
      state.importNavDataFileStatus = fetchStatuses.pending;
    });
    builder.addCase(importNavDataFile.fulfilled, (state) => {
      state.importNavDataFileStatus = fetchStatuses.success;
    });
    builder.addCase(importNavDataFile.rejected, (state, action) => {
      state.importNavDataFileStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchNavDataByFund.pending, (state) => {
      state.error = null;
      state.fetchFundNavDataByFundStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchNavDataByFund.fulfilled, (state, action: PayloadAction<FetchFundNavDataResponse>) => {
      state.fundNavData = action.payload.data;
      state.fetchFundNavDataByFundStatus = fetchStatuses.success;
    });
    builder.addCase(fetchNavDataByFund.rejected, (state, action) => {
      state.fetchFundNavDataByFundStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(getExampleNavDataFile.pending, (state) => {
      state.error = null;
      state.getExampleNavDataFileStatus = fetchStatuses.pending;
    });
    builder.addCase(getExampleNavDataFile.fulfilled, (state) => {
      state.getExampleNavDataFileStatus = fetchStatuses.success;
    });
    builder.addCase(getExampleNavDataFile.rejected, (state, action) => {
      state.getExampleNavDataFileStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(exportFundAndBenchmarkNavData.pending, (state) => {
      state.error = null;
      state.exportFundAndBenchmarkNavDataStatus = fetchStatuses.pending;
    });
    builder.addCase(exportFundAndBenchmarkNavData.fulfilled, (state) => {
      state.exportFundAndBenchmarkNavDataStatus = fetchStatuses.success;
    });
    builder.addCase(exportFundAndBenchmarkNavData.rejected, (state, action) => {
      state.exportFundAndBenchmarkNavDataStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditIndexFund.type, (state) => {
      state.editIndexFund = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanIndexFunds.type, (state) => {
      Object.assign(state, initialState);
    });

    builder.addCase(changeDisplayCustom, (state, action) => {
      // eslint-disable-next-line no-confusing-arrow
      state.indexFunds = state.indexFunds.map((fund) =>
        fund.id === action.payload.id ? { ...fund, customDisplay: !fund.customDisplay } : fund,
      );
    });
  },
});

export const indexFundReducer = IndexFundsSlice.reducer;
