import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { jadwaService } from '../../../api';
import { TriggerJadwaTransferRequest, UpdateJadwaTransferRequest } from '../../../types/jadwa';

export const updateJadwaTransfer = createAsyncThunk('jadwa/update-transfer', (transfer: UpdateJadwaTransferRequest) =>
  jadwaService.updateJadwaTransfer(transfer),
);

export const triggerJadwaTransfer = createAsyncThunk('jadwa/trigger-transfer', (data: TriggerJadwaTransferRequest) =>
  jadwaService.triggerJadwaTransfer(data),
);

export const fetchOrdersByMadkholOrderId = createAsyncThunk(
  'jadwa/fetch-orders-by-madkhol-order-id',
  (madkholOrderId: string) => jadwaService.fetchOrdersByMadkholOrderId(madkholOrderId),
);

export const cleanJadwas = createAction('jadwa/clean');
