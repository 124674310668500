import { PaginatedRequest } from '../types/api';
import {
  CreateFeeAccountRequest,
  CreateFeeAccountResponse,
  FetchFeeAccountsResponse,
  FetchFeeAccountResponse,
  UpdateFeeAccountRequest,
  UpdateFeeAccountResponse,
  FetchFeeAccountDetailsHistoryResponse,
} from '../types/feeAccount';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class FeeAccountService extends ApiService {
  pathname = 'admin/fee-account';

  fetchFeeAccounts = async () => this.get<FetchFeeAccountsResponse>(`${this.pathname}`);

  fetchFeeAccountDetailsHistory = async (data: PaginatedRequest) =>
    this.get<FetchFeeAccountDetailsHistoryResponse>(`${this.pathname}/history?${toQueryParams(data)}`);

  fetchFeeAccount = async (id: number) => this.get<FetchFeeAccountResponse>(`${this.pathname}/${id}`);

  deleteFeeAccount = async (id: number) => this.delete<FetchFeeAccountResponse>(`${this.pathname}/${id}`);

  createFeeAccount = async (action: CreateFeeAccountRequest) =>
    this.post<CreateFeeAccountResponse, CreateFeeAccountRequest>(`${this.pathname}`, action);

  updateFeeAccount = async ({ id, ...action }: UpdateFeeAccountRequest) =>
    this.put<UpdateFeeAccountResponse, Omit<UpdateFeeAccountRequest, 'id'>>(`${this.pathname}/${id}`, action);
}

export const feeAccountService = new FeeAccountService();
