import { Action } from './action';
import { AppConfig } from './app-config';
import { Anonymous } from './anonymous';
import { Benchmark } from './benchmark';
import { Currency } from './currency';
import { OrderExecution } from './orderExecution';
import { Portfolio } from './portfolio';
import { PortfolioOrder } from './portfolioOrder';
import { Resource } from './resource';
import { Role } from './role';
import { RolePermission } from './rolePermission';
import { UserCategory } from './userCategory';
import { FundGeographicalFocus } from './fund-geographical-focus';
import { FundManager } from './fund-manager';
import { FundAssetClass } from './fund-asset-class';
import { FundValuationDay } from './fund-valuation-day';
import { FundValuationFrequency } from './fund-valuation-frequency';
import { UserGroup } from './user-group';
import { User } from './user';
import { UserPortfolioRequest } from './userPortfolioRequest';
import { UserFeeDeduction } from './userFeeDeduction';
import { FeeBatch } from './feeBatch';
import { BatchExecution } from './batchExecution';
import { FeeConfig } from './feeConfig';

export * from './employee';
export * from './alpaca';
export * from './asset';
export * from './indexFund';
export * from './kyc';
export * from './payment';
export * from './subscribedPortfolio';
export * from './user-bank';
export * from './productType';
export * from './report';
export * from './jadwa';
export * from './analytics';

export {
  Action,
  AppConfig,
  Anonymous,
  Benchmark,
  Currency,
  OrderExecution,
  Portfolio,
  PortfolioOrder,
  Resource,
  Role,
  RolePermission,
  UserCategory,
  FundGeographicalFocus,
  FundManager,
  FundAssetClass,
  FundValuationDay,
  FundValuationFrequency,
  UserGroup,
  User,
  UserPortfolioRequest,
  UserFeeDeduction,
  FeeBatch,
  BatchExecution,
  FeeConfig,
};

export const ReadPermissionManagement = [Action.list, Resource.list, Role.list, RolePermission.list];
export const ReadPortfolioManagement = [Currency.list, UserCategory.list, Benchmark.list, Portfolio.list];
export const ReadUserManagement = [UserGroup.list, User.list, Anonymous.listEarlySignupUsers];
export const ReadFundManagement = [
  FundGeographicalFocus.list,
  FundManager.list,
  FundAssetClass.list,
  FundValuationDay.list,
  FundValuationFrequency.list,
];
export const ReadOrderManagement = [OrderExecution.list, PortfolioOrder.list, UserPortfolioRequest.list];
export const ReadFeeManagement = [BatchExecution.list, FeeBatch.list, UserFeeDeduction.list, FeeConfig.list];
export const Settings = [AppConfig.list];
