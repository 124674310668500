export const hasConsecutiveCharacters = (s: string[]) => {
  if (s.length < 3) {
    return false;
  }

  let consCount = 1;

  for (let i = 1; i < s.length; i++) {
    if (s[i].charCodeAt(0) - s[i - 1].charCodeAt(0) === 1) {
      consCount += 1;
    } else {
      consCount = 1;
    }

    if (consCount >= 3) {
      return true;
    }
  }

  return false;
};
