import { ActionType } from '../../types/action';

const listPermission = {
  resource: 'role-permission',
  action: ActionType.READ,
};

const updateAllPermission = {
  resource: 'role-permissions',
  action: ActionType.UPDATE,
};

export const RolePermission = {
  list: listPermission,
  updateAll: updateAllPermission,
};
