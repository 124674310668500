import { createSelector } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { BankType } from '../../../types/user';
import { ReducerState } from '../../store.types';

export const userSelector = ({ user }: ReducerState) => user;
export const userLoadingSelector = createSelector(
  userSelector,
  ({ fetchStatus }) => fetchStatus === fetchStatuses.pending,
);
export const userKycLoadingSelector = createSelector(
  userSelector,
  ({ fetchKycStatus }) => fetchKycStatus === fetchStatuses.pending,
);
export const userBankInfoLoadingSelector = createSelector(
  userSelector,
  ({ fetchBankInfoStatus }) => fetchBankInfoStatus === fetchStatuses.pending,
);
export const madkhoulBankInfoSelector = createSelector(userSelector, ({ editUserBankInfo }) =>
  editUserBankInfo.find((item) => item.bankType.type === BankType.VIRTUAL),
);
export const otherBankInfoSelector = createSelector(userSelector, ({ editUserBankInfo }) =>
  editUserBankInfo.filter((item) => item.bankType.type === BankType.OTHER),
);
export const userTransactionsSelector = createSelector(userSelector, (state) => ({
  userTransactionItems: state.userTransactionItems,
  totalUserTransactionItems: state.totalUserTransactionItems,
  fetchUserTransactionsStatus: state.fetchUserTransactionsStatus,
}));
export const userStatusOptionsLoadingSelector = createSelector(
  userSelector,
  ({ fetchUserStatusOptionsStatus }) => fetchUserStatusOptionsStatus === fetchStatuses.pending,
);
export const kycTypeOptionsLoadingSelector = createSelector(
  userSelector,
  ({ fetchKycTypeOptionsStatus }) => fetchKycTypeOptionsStatus === fetchStatuses.pending,
);
export const kycStatusOptionsLoadingSelector = createSelector(
  userSelector,
  ({ fetchKycStatusOptionsStatus }) => fetchKycStatusOptionsStatus === fetchStatuses.pending,
);
export const kycGenderOptionsLoadingSelector = createSelector(
  userSelector,
  ({ fetchKycGenderOptionsStatus }) => fetchKycGenderOptionsStatus === fetchStatuses.pending,
);
export const userAttachmentsLoadingSelector = createSelector(
  userSelector,
  ({ fetchUserAttachmentsStatus }) => fetchUserAttachmentsStatus === fetchStatuses.pending,
);
export const userAttachmentsSelector = createSelector(userSelector, ({ userAttachments }) => userAttachments);
export const userSubscriptionsSelector = createSelector(userSelector, (state) => ({
  userSubscriptionItems: state.userSubscriptionItems,
  totalUserSubscriptionItems: state.totalUserSubscriptionItems,
  fetchUserSubscriptionsStatus: state.fetchUserSubscriptionsStatus,
}));
