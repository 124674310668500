import { ApiResponse } from './api';
import { IndexFund } from './indexFund';
import { UserPortfolioRequest } from './userPortfolioRequest';

export enum PortfolioOrderStatus {
  CREATED = 'Created',
  IN_PROGRESS = 'In Progress',
  CANCELLED = 'Cancelled',
  PARTIALLY_PUSHED = 'Partially Pushed',
  PUSHED = 'Pushed',
  PARTIALLY_EXECUTED = 'Partially Executed',
  EXECUTED = 'Executed',
}

export interface PortfolioOrderStatusOption {
  id: number;
  name: PortfolioOrderStatus;
}

export enum PortfolioOrderExecutionStatus {
  EXECUTION = 'Execution',
  IN_REVIEW = 'In Review',
  RECONCILIATION = 'Reconciliation',
  APPROVED = 'Approved',
}

export interface PortfolioOrderExecutionStatusOption {
  id: number;
  name: PortfolioOrderExecutionStatus;
}

export enum FundOrderType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  REDEMPTION = 'REDEMPTION',
}

export interface FundOrderTypeOption {
  id: number;
  name: FundOrderType;
}

export enum FundOrderStatus {
  CREATED = 'Created',
  APPROVED = 'Approved',
  RETURNED_FOR_CORRECTION = 'Returned For Correction',
  REJECTED = 'Rejected',
  EXECUTING = 'Executing',
  DONE = 'Done',
  FAILED = 'Failed',
}

export interface FundOrderStatusOption {
  id: number;
  name: FundOrderStatus;
}

export enum SatelliteAccountRequestStatus {
  CREATED = 'Created',
  IN_PROGRESS = 'In Progress',
  REJECTED = 'Rejected',
  EXECUTED = 'Executed',
}

export interface SatelliteAccountRequestStatusOption {
  id: number;
  name: SatelliteAccountRequestStatus;
}

export enum ConsolidateRequestStatus {
  CREATED = 'Created',
  IN_PROGRESS = 'In Progress',
  REJECTED = 'Rejected',
  EXECUTED = 'Executed',
}

export interface ConsolidateRequestStatusOption {
  id: number;
  name: ConsolidateRequestStatus;
}

export enum OrderAttachmentType {
  EXECUTION_REPORT_FILE = 'Execution Report File',
  EXECUTION_FILE = 'Execution File',
  CONSOLIDATE_EXECUTION_FILE = 'Consolidate Execution File',
  OTHER = 'Other',
}

export interface OrderAttachmentTypeOption {
  id: number;
  name: OrderAttachmentType;
}

export interface OrderAttachment {
  id: number;
  orderId: string;
  createdBy: number;
  documentLink: string;
  orderAttachmentType: OrderAttachmentTypeOption;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export interface PortfolioOrderWeight {
  id: number;
  userId: number;
  weight: number;
  actualWeight: number;
  amount: number;
  actualAmount: number;
  units: number;
  actualUnits: number;
  satelliteAmount: number;
  satelliteUnits: number;
  createdAt: string;
  updatedAt: string;
}

export type FundOrder = {
  id: number;
  estimatedAmount: number;
  actualAmount: number;
  satelliteAmount: number;
  sharesCount: number;
  indexFund: IndexFund;
  status: FundOrderStatusOption;
  type: FundOrderTypeOption;
  orderId: string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type SatelliteAccountRequest = {
  id: number;
  amount: number;
  numOfUnits: number;
  actualAmount: number;
  actualNumOfUnits: number;
  fundOrder: FundOrder;
  status: SatelliteAccountRequestStatusOption;
  orderId: string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type ConsolidateRequest = {
  id: number;
  sellAmount: number;
  sellNumOfUnits: number;
  buyAmount: number;
  buyNumOfUnits: number;
  fundOrder: FundOrder;
  status: ConsolidateRequestStatusOption;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type PortfolioOrder = {
  id: number;
  userPortfolioRequest: UserPortfolioRequest;
  status: PortfolioOrderStatusOption;
  orderId: string;
  employeeId: number;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type PortfolioOrderExecution = {
  id: number;
  portfolioOrder: PortfolioOrder;
  status: PortfolioOrderExecutionStatusOption;
  orderId: string;
  userId: number;
  indexFunds: {
    amount: number;
    nav: number;
    indexFundId: number;
    numOfUnits: number;
  }[];
  returnAmount: number;
  amount: number;
  hold: number;
  cashAllocationValue: number;
  portfolioOrderWeights?: PortfolioOrderWeight[];
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchPortfolioOrderResponse = ApiResponse<PortfolioOrder>;
export type FetchPortfolioOrdersResponse = ApiResponse<{
  items: PortfolioOrder[];
  totalItems: number;
}>;
export type FetchPortfolioOrderExecutionsResponse = ApiResponse<PortfolioOrderExecution[]>;
export type FetchFundOrdersResponse = ApiResponse<{
  items: FundOrder[];
  totalItems: number;
}>;
export type FetchSatelliteAccountRequestsResponse = ApiResponse<{
  items: SatelliteAccountRequest[];
  totalItems: number;
}>;
export type FetchConsolidateRequestsResponse = ApiResponse<{
  items: ConsolidateRequest[];
  totalItems: number;
}>;
export type FetchPortfolioOrderWeightsResponse = ApiResponse<{
  items: PortfolioOrderWeight[];
  totalItems: number;
}>;
export type FetchPortfolioOrderStatusOptionsResponse = ApiResponse<PortfolioOrderStatusOption[]>;
export type FetchOrderAttachmentsTypeOptionsResponse = ApiResponse<OrderAttachmentTypeOption[]>;
export type FetchOrderAttachmentsResponse = ApiResponse<{
  items: OrderAttachment[];
  totalItems: number;
}>;

export type UpdatePortfolioOrderStatusRequest = Pick<PortfolioOrder, 'id'> & { status: PortfolioOrderStatus };
export type UpdatePortfolioOrderStatusResponse = ApiResponse<{ message: string }>;

export type CreatePortfolioOrderRequest = { userPortfolioRequestIds: number[] };
export type SavePortfolioOrderRequest = Omit<
  PortfolioOrder,
  'id' | 'status' | 'userPortfolioRequest' | 'createdAt' | 'updatedAt'
> & {
  status: number;
  userPortfolioRequest: number;
};
export type CreatePortfolioOrderResponse = ApiResponse<{ orderId: string; executionId: string }>;

export type CreateOrderAttachmentRequest = {
  orderAttachmentTypeId: number;
  documentLink: string;
};
export type CreateOrderAttachmentResponse = ApiResponse<OrderAttachment>;

export type UploadOrderAttachmentRequest = {
  type: OrderAttachmentType;
  fileData: string;
  fileName: string;
};
export type UploadOrderAttachmentResponse = ApiResponse<OrderAttachment>;

export type ImportExecutionPortfolioOrder = { orderId: string; file: File; base?: 'amount' | 'units' };

export type UpdatePortfolioOrderRequest = Omit<
  PortfolioOrder,
  'status' | 'userPortfolioRequest' | 'createdAt' | 'updatedAt'
> & {
  status: number;
  userPortfolioRequest: number;
};
export type UpdatePortfolioOrderResponse = ApiResponse<PortfolioOrder>;

export type ReconcileOrderExecutionsByOrderIdRequest = {
  orderId: string;
  file: File;
};
