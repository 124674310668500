import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/system/Box';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Tab from '@mui/material/Tab';
import { useAppDispatch } from '../../redux/hooks';
import { fetchOrdersByMadkholOrderId } from '../../redux/modules/alpaca/alpaca.actions';
import { alpacaOrderByRequestIdSelector, alpacaSelector } from '../../redux/modules/alpaca/alpaca.selectors';
import { ReducerState } from '../../redux/store.types';
import { AlpacaDetails } from './components/alpaca';

interface AlpacaTabProps {
  orderId: string;
}

export function AlpacaTab({ orderId }: AlpacaTabProps) {
  const dispatch = useAppDispatch();
  const { error } = useSelector(alpacaSelector);
  const { orders } = useSelector((state: ReducerState) => alpacaOrderByRequestIdSelector(state, 'by-madkhol-order-id'));
  const [selectedTab, setSelectedTab] = React.useState<string>('0');

  const fetchOrders = useCallback(() => {
    dispatch(fetchOrdersByMadkholOrderId(orderId));
  }, [orderId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (orderId) {
      fetchOrders();
    }
  }, []);

  const renderOrders = () => {
    if (!orders.length) {
      return;
    }

    return (
      <TabContext value={selectedTab}>
        <Box sx={{ width: '100%' }}>
          <TabList
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="alpaca fund orders"
          >
            {orders.map((order, index) => (
              <Tab
                key={`alpaca-fund-order-${order.id}`}
                value={index.toString()}
                label={`Fund Order #${order.fundOrderId}`}
                sx={{ textTransform: 'capitalize' }}
                disableRipple
              />
            ))}
          </TabList>
        </Box>
        {orders.map((order, index) => (
          <TabPanel key={`alpaca-fund-order-panel-${order.id}`} value={index.toString()} sx={{ width: '100%' }}>
            <AlpacaDetails order={order} refresh={fetchOrders} />
          </TabPanel>
        ))}
      </TabContext>
    );
  };

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      {renderOrders()}
    </>
  );
}
