import * as yup from 'yup';
import { fundManagerFormFields } from '../../fields/formFields';

export const fundManagerFormSchema = yup.object({
  [fundManagerFormFields.nameEn]: yup
    .string()
    .max(150, 'Name En must be at most 150 characters')
    .required('Name En is required')
    .trim(),
  [fundManagerFormFields.nameAr]: yup
    .string()
    .max(150, 'Name Ar must be at most 150 characters')
    .required('Name Ar is required')
    .trim(),
  [fundManagerFormFields.descriptionEn]: yup
    .string()
    .max(150, 'Description En must be at most 150 characters')
    .required('Description En is required')
    .trim(),
  [fundManagerFormFields.descriptionAr]: yup
    .string()
    .max(150, 'Description Ar must be at most 150 characters')
    .required('Description Ar is required')
    .trim(),
  [fundManagerFormFields.locationEn]: yup
    .string()
    .max(150, 'Location En must be at most 150 characters')
    .required('Location En is required')
    .trim(),
  [fundManagerFormFields.locationAr]: yup
    .string()
    .max(150, 'Location Ar must be at most 150 characters')
    .required('Location Ar is required')
    .trim(),
});
