import { ActionType } from '../../types/action';

const listPermission = {
  resource: 'employees',
  action: ActionType.READ,
};

const createPermission = {
  resource: 'employee',
  action: ActionType.CREATE,
};
const updatePermission = {
  resource: 'employee',
  action: ActionType.UPDATE,
};
const readPermission = {
  resource: 'employee',
  action: ActionType.READ,
};
const deletePermission = {
  resource: 'employee',
  action: ActionType.DELETE,
};
const statusUpdatePermission = {
  resource: 'employee-status',
  action: ActionType.UPDATE,
};
const resetPasswordPermission = {
  resource: 'employee-reset-password',
  action: ActionType.UPDATE,
};

export const Employee = {
  list: listPermission,
  read: readPermission,
  create: createPermission,
  update: updatePermission,
  delete: deletePermission,
  statusUpdate: statusUpdatePermission,
  resetPassword: resetPasswordPermission,
};
