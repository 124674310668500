import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchAnalyticsConfigResponse, FetchAnalyticsConfigsResponse } from '../../../types/analyticsConfig';
import {
  fetchAnalyticsConfig,
  fetchAnalyticsConfigs,
  resetEditAnalyticsConfig,
  cleanAnalyticsConfigs,
  updateAnalyticsConfig,
  createAnalyticsConfigItem,
  deleteAnalyticsConfig,
} from './analyticsConfig.actions';
import { AnalyticsConfigState } from './analyticsConfig.types';

const initialState: AnalyticsConfigState = {
  analyticsConfigs: [],
  editAnalyticsConfig: null,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const analyticsConfigsSlice = createSlice({
  name: 'analyticsConfigs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAnalyticsConfigs.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchAnalyticsConfigs.fulfilled,
      (state, analyticsConfig: PayloadAction<FetchAnalyticsConfigsResponse>) => {
        state.analyticsConfigs = analyticsConfig.payload.data;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchAnalyticsConfigs.rejected, (state, analyticsConfig) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = analyticsConfig.error;
    });

    builder.addCase(fetchAnalyticsConfig.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchAnalyticsConfig.fulfilled,
      (state, analyticsConfig: PayloadAction<FetchAnalyticsConfigResponse>) => {
        state.editAnalyticsConfig = analyticsConfig.payload.data;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchAnalyticsConfig.rejected, (state, analyticsConfig) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = analyticsConfig.error;
    });

    builder.addCase(updateAnalyticsConfig.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateAnalyticsConfig.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateAnalyticsConfig.rejected, (state, analyticsConfig) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = analyticsConfig.error;
    });

    builder.addCase(createAnalyticsConfigItem.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(
      createAnalyticsConfigItem.fulfilled,
      (state, analyticsConfig: PayloadAction<FetchAnalyticsConfigResponse>) => {
        state.editAnalyticsConfig = analyticsConfig.payload.data;
        state.createStatus = fetchStatuses.success;
      },
    );
    builder.addCase(createAnalyticsConfigItem.rejected, (state, analyticsConfig) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = analyticsConfig.error;
    });

    builder.addCase(deleteAnalyticsConfig.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteAnalyticsConfig.fulfilled, (state) => {
      state.editAnalyticsConfig = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteAnalyticsConfig.rejected, (state, analyticsConfig) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = analyticsConfig.error;
    });

    builder.addCase(resetEditAnalyticsConfig.type, (state) => {
      state.editAnalyticsConfig = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanAnalyticsConfigs.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const analyticsConfigReducer = analyticsConfigsSlice.reducer;
