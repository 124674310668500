import { ApiResponse } from './api';

export enum MastercardSessionStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
  EXPIRED = 'expired',
}

export type MastercardSession = {
  id: number;
  orderId: string;
  transactionId: string;
  userId: number;
  amount: number;
  currency: string;
  status: MastercardSessionStatus;
  sessionId: string;
  successIndicator: string;
  createdAt: Date;
  updatedAt: Date;
};

export type FetchMastercardSessionsResponse = ApiResponse<{ items: MastercardSession[]; totalItems: number }>;
