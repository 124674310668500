import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { orderExecutionService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';

export const fetchOrderExecutions = createAsyncThunk(
  'orderExecution/fetch-all',
  (data: PaginatedRequest & { requestId: string }, { rejectWithValue }) =>
    orderExecutionService.fetchOrderExecutions(data).catch((error) => rejectWithValue(error)),
);

export const fetchOrderExecutionByOrderId = createAsyncThunk(
  'orderExecution/fetch-by-order-id',
  (orderId: string, { rejectWithValue }) =>
    orderExecutionService.fetchOrderExecutionByOrderId(orderId).catch((error) => rejectWithValue(error)),
);

export const cleanOrderExecutions = createAction('orderExecution/clean');
