import { PaginatedRequest } from '../types/api';
import {
  CreateFundGeographicalFocusRequest,
  CreateFundGeographicalFocusResponse,
  FetchFundGeographicalFocusResponse,
  FetchFundGeographicalFocusesResponse,
  UpdateFundGeographicalFocusRequest,
  UpdateFundGeographicalFocusResponse,
} from '../types/fundGeographicalFocus';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class FundGeographicalFocusService extends ApiService {
  pathname = 'admin';

  fetchFundGeographicalFocuses = async (data: PaginatedRequest) =>
    this.get<FetchFundGeographicalFocusesResponse>(`${this.pathname}/fund-geographical-focus?${toQueryParams(data)}`);

  fetchFundGeographicalFocus = async (id: number) =>
    this.get<FetchFundGeographicalFocusResponse>(`${this.pathname}/fund-geographical-focus/${id}`);

  deleteFundGeographicalFocus = async (id: number) =>
    this.delete<FetchFundGeographicalFocusResponse>(`${this.pathname}/fund-geographical-focus/${id}`);

  createFundGeographicalFocus = async (fundGeographicalFocus: CreateFundGeographicalFocusRequest) =>
    this.post<CreateFundGeographicalFocusResponse, CreateFundGeographicalFocusRequest>(
      `${this.pathname}/fund-geographical-focus`,
      fundGeographicalFocus,
    );

  updateFundGeographicalFocus = async ({ id, ...fundGeographicalFocus }: UpdateFundGeographicalFocusRequest) =>
    this.put<UpdateFundGeographicalFocusResponse, Omit<UpdateFundGeographicalFocusRequest, 'id'>>(
      `${this.pathname}/fund-geographical-focus/${id}`,
      fundGeographicalFocus,
    );
}

export const fundGeographicalFocusService = new FundGeographicalFocusService();
