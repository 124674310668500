import { apiSlice } from '../../../api/common/apiSlice';
import { PaginatedRequest } from '../../../types/api';
import { toQueryParams } from '../../../utils/request';
import { ApiTagType } from '../../../constants/apiTagType';
import { FetchJadwaTransfersResponse } from '../../../types/jadwa';

export const jadwaApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchJadwaTransfers: builder.query<FetchJadwaTransfersResponse, PaginatedRequest>({
      query: (data) => ({
        url: `/admin/jadwa/transfers?${toQueryParams(data)}`,
        method: 'GET',
      }),
      providesTags: (res) => {
        if (res) {
          return [
            ...(res.items || []).map(({ id }) => ({ type: ApiTagType.JadwaTransfer as const, id })),
            { type: ApiTagType.JadwaTransfer, id: 'LIST' },
          ];
        }
        return [{ type: ApiTagType.JadwaTransfer, id: 'LIST' }];
      },
    }),
  }),
});

export const { useFetchJadwaTransfersQuery } = jadwaApiSlice;
