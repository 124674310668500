import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { reportService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { ReconcileSystemUnitsReportRequest } from '../../../types/report';

export const fetchPortfolioOrderExecutionReport = createAsyncThunk(
  'report/fetch-portfolio-order-execution',
  (data: PaginatedRequest, { rejectWithValue }) =>
    reportService.fetchPortfolioOrderExecutionReport(data).catch((error) => rejectWithValue(error)),
);

export const fetchCashReport = createAsyncThunk('report/fetch-cash', (data: PaginatedRequest, { rejectWithValue }) =>
  reportService.fetchCashReport(data).catch((error) => rejectWithValue(error)),
);

export const fetchSystemCashReport = createAsyncThunk(
  'report/fetch-system-cash',
  (data: PaginatedRequest, { rejectWithValue }) =>
    reportService.fetchSystemCashReport(data).catch((error) => rejectWithValue(error)),
);

export const reconcileSystemCashReport = createAsyncThunk(
  'report/reconcile-system-units',
  (data: ReconcileSystemUnitsReportRequest, { rejectWithValue }) =>
    reportService.reconcileSystemUnitsReport(data).catch((error) => rejectWithValue(error)),
);

export const fetchUnitsReport = createAsyncThunk('report/fetch-units', (data: PaginatedRequest, { rejectWithValue }) =>
  reportService.fetchUnitsReport(data).catch((error) => rejectWithValue(error)),
);

export const fetchSystemUnitsReport = createAsyncThunk('report/fetch-system-units', () =>
  reportService.fetchSystemUnitsReport(),
);

export const fetchFxRevenueAlpacaOrdersReport = createAsyncThunk(
  'report/fx-revenue-alpaca-orders',
  (data: PaginatedRequest, { rejectWithValue }) =>
    reportService.fetchFxRevenueAlpacaOrdersReport(data).catch(rejectWithValue),
);

export const fetchFxRevenueAlpacaTransfersReport = createAsyncThunk(
  'report/fx-revenue-alpaca-transfers',
  (data: PaginatedRequest, { rejectWithValue }) =>
    reportService.fetchFxRevenueAlpacaTransfersReport(data).catch(rejectWithValue),
);

export const fetchFxRevenueJadwaOrdersReport = createAsyncThunk(
  'report/fx-revenue-jadwa-orders',
  (data: PaginatedRequest, { rejectWithValue }) =>
    reportService.fetchFxRevenueJadwaOrdersReport(data).catch(rejectWithValue),
);

export const fetchFxRevenueJadwaTransfersReport = createAsyncThunk(
  'report/fx-revenue-jadwa-transfers',
  (data: PaginatedRequest, { rejectWithValue }) =>
    reportService.fetchFxRevenueJadwaTransfersReport(data).catch(rejectWithValue),
);

export const cleanReport = createAction('report/clean');
