import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarExportProps,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import React from 'react';

interface CustomToolbarProps {
  children?: JSX.Element | JSX.Element[];
  exportProps?: Partial<GridToolbarExportProps>;
}

export function CustomToolbar({ children, exportProps }: CustomToolbarProps) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport {...exportProps} />
      {children}
    </GridToolbarContainer>
  );
}
