import RefreshIcon from '@mui/icons-material/Refresh';
import { LoadingButton } from '@mui/lab';
import { FormControl, FormHelperText } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { useAppDispatch } from '../../../../redux/hooks';
import { restoreAggragatedStatistics } from '../../../../redux/modules/aggregated-statistic/aggregated-statistic.actions';
import { aggregatedStatisticSelector } from '../../../../redux/modules/aggregated-statistic/aggregated-statistic.selectors';
import { restoreAggregatedStatisticsFormFields } from '../../../../forms/fields/formFields';
import { restoreAggregatedStatisticsFormSchema } from '../../../../forms/validationSchema/formSchema';

interface RestoreAggregatedStatisticsDialogProps {
  open: boolean;
  subscribedPortfolioId: number;
  onClose: (isReload: boolean) => void;
}

export function RestoreAggregatedStatisticsDialog({
  open,
  onClose,
  subscribedPortfolioId,
}: RestoreAggregatedStatisticsDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { restoreStatus } = useSelector(aggregatedStatisticSelector);
  const { handleSubmit, values, setFieldValue, touched, errors, submitForm } = useFormik({
    initialValues: {
      [restoreAggregatedStatisticsFormFields.date]: new Date(),
    },
    validationSchema: restoreAggregatedStatisticsFormSchema,
    onSubmit: async (formValues) => {
      try {
        await dispatch(
          restoreAggragatedStatistics({
            ...formValues,
            subscribedPortfolioId,
          }),
        ).unwrap();
        onClose(true);
        enqueueSnackbar('Done!', { variant: 'success' });
      } catch (err: any) {
        enqueueSnackbar(`Failed to restore aggregated statistics: ${err.message}`, { variant: 'error' });
      }
    },
  });

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Restore Aggregated Statistics</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth margin="none" error={touched.date && !!errors.date}>
            <DatePicker
              label="Start Date"
              value={values[restoreAggregatedStatisticsFormFields.date]}
              onChange={(date) => {
                setFieldValue(restoreAggregatedStatisticsFormFields.date, date);
              }}
              slotProps={{
                textField: {
                  name: restoreAggregatedStatisticsFormFields.date,
                  id: restoreAggregatedStatisticsFormFields.date,
                  label: 'Start Date',
                  fullWidth: true,
                  margin: 'normal',
                },
              }}
              disableFuture
            />
            {touched.date && <FormHelperText>{errors.date as string}</FormHelperText>}
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<RefreshIcon />}
          loading={restoreStatus === fetchStatuses.pending}
          loadingPosition="end"
          onClick={submitForm}
        >
          Restore
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
