import { PaginatedRequest } from '../types/api';
import {
  CreateUserGroupRequest,
  CreateUserGroupResponse,
  FetchUserGroupResponse,
  FetchUserGroupsResponse,
  UpdateUserGroupRequest,
  UpdateUserGroupResponse,
} from '../types/userGroup';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class UserGroupService extends ApiService {
  pathname = 'admin/user-group';

  fetchUserGroups = async (data: PaginatedRequest) =>
    this.get<FetchUserGroupsResponse>(`${this.pathname}?${toQueryParams(data)}`);

  fetchUserGroup = async (id: number) => this.get<FetchUserGroupResponse>(`${this.pathname}/${id}`);

  deleteUserGroup = async (id: number) => this.delete<FetchUserGroupResponse>(`${this.pathname}/${id}`);

  setDefaultUserGroup = async (id: number) =>
    this.put<FetchUserGroupResponse, object>(`${this.pathname}/${id}/default`, {});

  createUserGroup = async (userGroup: CreateUserGroupRequest) =>
    this.post<CreateUserGroupResponse, CreateUserGroupRequest>(`${this.pathname}`, userGroup);

  updateUserGroup = async ({ id, ...userGroup }: UpdateUserGroupRequest) =>
    this.put<UpdateUserGroupResponse, Omit<UpdateUserGroupRequest, 'id'>>(`${this.pathname}/${id}`, userGroup);
}

export const userGroupService = new UserGroupService();
