import * as React from 'react';
import { useTabContext } from '@mui/lab/TabContext';
import { TabPanelProps } from '@mui/lab/TabPanel';

interface MTabPanelProps extends TabPanelProps {
  className?: string;
  style?: React.CSSProperties;
}

export function MTabPanel(props: MTabPanelProps) {
  const { children, className, style, value: id } = props;

  const context = useTabContext();

  if (context === null) {
    throw new TypeError('No TabContext provided');
  }
  const tabId = context.value;

  return (
    <div
      className={className}
      style={{
        width: '100%',
        minHeight: '100%',
        margin: 0,
        padding: 0,
        display: 'flex',
        ...style,
        position: 'absolute',
        left: 0,
        opacity: id === tabId ? 1 : 0,
        visibility: id === tabId ? 'visible' : 'hidden',
      }}
    >
      {children}
    </div>
  );
}
