import {
  FetchJadwaOrdersByMadkholOrderIdResponse,
  TriggerJadwaTransferRequest,
  TriggerJadwaTransferResponse,
  UpdateJadwaTransferRequest,
  UpdateJadwaTransferResponse,
} from '../types/jadwa';
import { ApiService } from './common/ApiService';

class JadwaService extends ApiService {
  pathname = 'admin/jadwa';

  updateJadwaTransfer = async ({ id, ...data }: UpdateJadwaTransferRequest) =>
    this.put<UpdateJadwaTransferResponse, Omit<UpdateJadwaTransferRequest, 'id'>>(
      `${this.pathname}/transfer/${id}`,
      data,
    );

  triggerJadwaTransfer = async (data: TriggerJadwaTransferRequest) =>
    this.post<TriggerJadwaTransferResponse, TriggerJadwaTransferRequest>(`${this.pathname}/transfer/trigger`, data);

  fetchOrdersByMadkholOrderId = async (madkholOrderId: string) =>
    this.get<FetchJadwaOrdersByMadkholOrderIdResponse>(`${this.pathname}/orders/${madkholOrderId}`);
}

export const jadwaService = new JadwaService();
