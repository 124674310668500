import { PaginatedRequest } from '../types/api';
import { OrderExecutionReportType } from '../types/orderExecution';
import {
  SavePortfolioOrderRequest,
  CreatePortfolioOrderResponse,
  FetchPortfolioOrdersResponse,
  FetchPortfolioOrderResponse,
  UpdatePortfolioOrderRequest,
  UpdatePortfolioOrderResponse,
  UpdatePortfolioOrderStatusRequest,
  UpdatePortfolioOrderStatusResponse,
  FetchPortfolioOrderStatusOptionsResponse,
  CreatePortfolioOrderRequest,
  FetchFundOrdersResponse,
  FetchSatelliteAccountRequestsResponse,
  ImportExecutionPortfolioOrder,
  FetchConsolidateRequestsResponse,
  CreateOrderAttachmentRequest,
  CreateOrderAttachmentResponse,
  FetchOrderAttachmentsTypeOptionsResponse,
  FetchOrderAttachmentsResponse,
  FetchPortfolioOrderExecutionsResponse,
  ReconcileOrderExecutionsByOrderIdRequest,
  UploadOrderAttachmentRequest,
  UploadOrderAttachmentResponse,
  FetchPortfolioOrderWeightsResponse,
} from '../types/portfolioOrder';
import { FetchPortfolioOrderExecutionReportResponse } from '../types/report';
import { FetchUserPortfolioRequestsResponse } from '../types/userPortfolioRequest';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class PortfolioOrderService extends ApiService {
  pathname = 'admin';

  fetchPortfolioOrders = async (data: PaginatedRequest) =>
    this.get<FetchPortfolioOrdersResponse>(`${this.pathname}/portfolio-order?${toQueryParams(data)}`);

  fetchPortfolioOrdersByExecutionId = async ({ executionId, ...data }: PaginatedRequest & { executionId: string }) =>
    this.get<FetchPortfolioOrdersResponse>(
      `${this.pathname}/portfolio-order/execution/${executionId}?${toQueryParams(data)}`,
    );

  fetchOrderAttachmentsTypeOptions = async () =>
    this.get<FetchOrderAttachmentsTypeOptionsResponse>(`${this.pathname}/order-attachment/attachment-type/options`);

  fetchPortfolioOrdersByOrderId = async ({ orderId, ...data }: PaginatedRequest & { orderId: string }) =>
    this.get<FetchPortfolioOrdersResponse>(`${this.pathname}/portfolio-order/${orderId}/?${toQueryParams(data)}`);

  fetchPortfolioOrderExecutionsByOrderId = async (orderId: string) =>
    this.get<FetchPortfolioOrderExecutionsResponse>(`${this.pathname}/portfolio-order/${orderId}/executions`);

  fetchOrderExecutionsReportByOrderId = async (orderId: string, reportType: OrderExecutionReportType) =>
    this.get<FetchPortfolioOrderExecutionReportResponse>(
      `${this.pathname}/portfolio-order/${orderId}/executions-report`,
      {
        params: { reportType },
      },
    );

  discardOrderExecutionsByOrderId = async (orderId: string) =>
    this.post(`${this.pathname}/portfolio-order/${orderId}/executions-discard`, {});

  approveOrderExecutionsByOrderId = async (orderId: string) =>
    this.post(`${this.pathname}/portfolio-order/${orderId}/executions-approve`, {});

  changeOrderExecutionsToReconciliationByOrderId = async (orderId: string) =>
    this.post(`${this.pathname}/portfolio-order/${orderId}/executions-to-reconciliation`, {});

  reconcileOrderExecutionsByOrderId = async ({ orderId, file }: ReconcileOrderExecutionsByOrderIdRequest) => {
    const formData = new FormData();
    formData.append('file', file);

    return this.post(`${this.pathname}/portfolio-order/${orderId}/executions-reconcile`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  fetchUserPortfolioRequestsByOrderId = async ({ orderId, ...data }: PaginatedRequest & { orderId: string }) =>
    this.get<FetchUserPortfolioRequestsResponse>(
      `${this.pathname}/portfolio-order/${orderId}/user-portfolio-request?${toQueryParams(data)}`,
    );

  fetchFundOrdersByOrderId = async ({ orderId, ...data }: PaginatedRequest & { orderId: string }) =>
    this.get<FetchFundOrdersResponse>(`${this.pathname}/portfolio-order/${orderId}/fund-order?${toQueryParams(data)}`);

  fetchPortfolioOrderWeightsByOrderId = async ({ orderId, ...data }: PaginatedRequest & { orderId: string }) =>
    this.get<FetchPortfolioOrderWeightsResponse>(
      `${this.pathname}/portfolio-order/${orderId}/weight?${toQueryParams(data)}`,
    );

  fetchSatelliteRequestsByOrderId = async ({ orderId, ...data }: PaginatedRequest & { orderId: string }) =>
    this.get<FetchSatelliteAccountRequestsResponse>(
      `${this.pathname}/satellite-request/order/${orderId}?${toQueryParams(data)}`,
    );

  fetchConsolidateRequestsByOrderId = async ({ orderId, ...data }: PaginatedRequest & { orderId: string }) =>
    this.get<FetchConsolidateRequestsResponse>(
      `${this.pathname}/portfolio-order/${orderId}/consolidate-request?${toQueryParams(data)}`,
    );

  fetchPortfolioOrder = async (id: number) =>
    this.get<FetchPortfolioOrderResponse>(`${this.pathname}/portfolio-order/${id}`);

  deletePortfolioOrder = async (orderId: string) =>
    this.delete<FetchPortfolioOrderResponse>(`${this.pathname}/portfolio-order/${orderId}`);

  createPortfolioOrder = async (portfolio: CreatePortfolioOrderRequest) =>
    this.post<CreatePortfolioOrderResponse, CreatePortfolioOrderRequest>(`${this.pathname}/portfolio-order`, portfolio);

  savePortfolioOrder = async (portfolio: SavePortfolioOrderRequest) =>
    this.post<CreatePortfolioOrderResponse, SavePortfolioOrderRequest>(`${this.pathname}/portfolio-order`, portfolio);

  updatePortfolioOrder = async ({ id, ...portfolio }: UpdatePortfolioOrderRequest) =>
    this.put<UpdatePortfolioOrderResponse, Omit<UpdatePortfolioOrderRequest, 'id'>>(
      `${this.pathname}/portfolio-order/${id}`,
      portfolio,
    );

  updatePortfolioOrderStatus = async ({ id, status }: UpdatePortfolioOrderStatusRequest) =>
    this.put<UpdatePortfolioOrderStatusResponse, Omit<UpdatePortfolioOrderStatusRequest, 'id'>>(
      `${this.pathname}/portfolio-order/${id}/status`,
      { status },
    );

  executePortfolioOrder = async (orderId: string) =>
    this.post(`${this.pathname}/portfolio-order/${orderId}/execute`, {});

  cancelExecutionPortfolioOrder = async (orderId: string) =>
    this.post(`${this.pathname}/portfolio-order/${orderId}/cancel-execution`, {});

  consolidateFundOrders = async (fundOrderIds: number[]) =>
    this.post(`${this.pathname}/portfolio-order/consolidate`, { fundOrderIds });

  importExecutionFilePortfolioOrder = async (payload: ImportExecutionPortfolioOrder) => {
    const formData = new FormData();
    formData.append('file', payload.file);

    if (payload.base) {
      formData.append('base', payload.base);
    }

    return this.post(`${this.pathname}/portfolio-order/${payload.orderId}/import-execution-file`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  importConsolidateExecutionFilePortfolioOrder = async (payload: ImportExecutionPortfolioOrder) => {
    const formData = new FormData();
    formData.append('file', payload.file);

    return this.post(
      `${this.pathname}/portfolio-order/${payload.orderId}/import-consolidate-execution-file`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );
  };

  fetchPortfolioOrderStatusOptions = async () =>
    this.get<FetchPortfolioOrderStatusOptionsResponse>(`${this.pathname}/portfolio-order/status/options`);

  fetchOrderAttachments = async ({ orderId, ...data }: PaginatedRequest & { orderId: string }) =>
    this.get<FetchOrderAttachmentsResponse>(`${this.pathname}/order-attachment/${orderId}?${toQueryParams(data)}`);

  createOrderAttachment = async ({ orderId, ...data }: CreateOrderAttachmentRequest & { orderId: string }) =>
    this.post<CreateOrderAttachmentResponse, Omit<CreateOrderAttachmentRequest, 'id'>>(
      `${this.pathname}/order-attachment/${orderId}`,
      data,
    );

  uploadOrderAttachment = async (data: UploadOrderAttachmentRequest & { orderId: string }) => {
    const blob = new Blob([data.fileData], { type: 'text/csv' });
    const formData = new FormData();
    formData.append('file', blob, data.fileName);

    return this.post<UploadOrderAttachmentResponse, FormData>(
      `${this.pathname}/order-attachment/${data.orderId}/upload?type=${data.type}`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );
  };
}

export const portfolioOrderService = new PortfolioOrderService();
