import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { GridActionsCellItemProps, GridRenderCellParams, GridColDef, GridRowParams } from '@mui/x-data-grid-premium';
import { GridActionsCellItemLink } from '../../components/FakeLink';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { feeBatchStatusToInfo } from '../../constants/tables/feeBatch';
import { feeBatchExecutionStatusToInfo } from '../../constants/tables/feeBatchExecution';
import { usePermissions } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import { feeBatchByRequestIdSelector } from '../../redux/modules/feeBatch/feeBatch.selectors';
import { fetchFeeBatchesByExecutionId } from '../../redux/modules/feeBatch/feeBatch.actions';
import { ReducerState } from '../../redux/store.types';
import { locations } from '../../routes/locations';
import { FeeBatchExecutionStatus, FeeBatchStatus } from '../../types/feeBatch';
import { UserFeeDeductionTypes } from '../../types/userFeeDeduction';
import * as permissions from '../../utils/permissions';

interface FeeBatchTableProps {
  executionId: string;
}

export function FeeBatchTable({ executionId }: FeeBatchTableProps) {
  const requestId = `fee-batch-list-by-execution-id-${executionId}`;
  useAppDispatch();
  const tableRef = useRef<TableRef>(null);
  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
    },
    {
      field: 'batchId',
      headerName: 'Batch Id',
      type: 'string',
      width: 300,
    },
    {
      field: 'userFeeDeduction.id',
      headerName: 'Deduction Id',
      valueGetter: (params) => params.row?.userFeeDeduction?.id,
      width: 130,
    },
    {
      field: 'userFeeDeduction.type.name',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: Object.values(UserFeeDeductionTypes),
      minWidth: 150,
      valueGetter: (params) => params.row?.userFeeDeduction?.type?.name,
    },
    {
      field: 'userFeeDeduction.totalFee',
      headerName: 'Total Fee',
      type: 'number',
      valueGetter: (params) => params.row?.userFeeDeduction?.totalFee,
      width: 100,
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(FeeBatchStatus),
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = feeBatchStatusToInfo[params.row?.status?.name as FeeBatchStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.row?.status?.name;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.row?.status?.name} />;
      },
    },
    {
      field: 'feeBatchExecution.status',
      headerName: 'Execution Status',
      type: 'singleSelect',
      sortable: false,
      filterable: false,
      valueOptions: Object.values(FeeBatchExecutionStatus),
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => params.value,
      valueGetter: (params) => params.row.feeBatchExecution?.[0]?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = feeBatchExecutionStatusToInfo[params.value as FeeBatchExecutionStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.value;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.value} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const canDoActions = usePermissions({ items: [permissions.PortfolioOrder.execute] });
  const { totalfeeBatchs, feeBatches, fetchStatus } = useSelector((state: ReducerState) =>
    feeBatchByRequestIdSelector(state, requestId),
  );
  const loading = fetchStatus === fetchStatuses.pending;

  useEffect(() => {
    const actionColumn = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      filterable: false,
      sortable: false,
      getActions: (params: GridRowParams) => {
        const actions: React.ReactElement<GridActionsCellItemProps>[] = [];

        if (`${params.id}`.split('/').length === 2) {
          const batchId = `${params.id}`.split('/').pop();

          actions.push(
            <GridActionsCellItemLink
              key={`${batchId}-fee-batch-list-actions`}
              icon={<OpenInNewIcon />}
              label="Link"
              href={locations.feeBatch(batchId)}
            />,
          );
        }

        return actions;
      },
    };

    if (canDoActions) {
      setColumns([actionColumn, ...columns]);
    }
  }, [canDoActions]);

  try {
    return (
      <>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Fee Batches</Typography>
        </Box>
        <Table
          ref={tableRef}
          wrapperHeight={300}
          fetchItems={fetchFeeBatchesByExecutionId}
          rows={feeBatches}
          columns={columns}
          loading={loading}
          rowCount={totalfeeBatchs}
          disableColumnFilter
          disableColumnMenu
          density="compact"
          disableRowGrouping={false}
          rowGroupingColumnMode="single"
          initialRowGroupingModel={['batchId']}
          defaultGroupingExpansionDepth={2}
          fetchItemsCustomParams={{ requestId, executionId }}
          initialColumnVisibilityModel={{ batchId: false }}
          initialSortModel={[{ field: 'id', sort: 'desc' }]}
          sortingMode="client"
        />
      </>
    );
  } catch (e: any) {
    return (
      <>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Fee Batches</Typography>
        </Box>
        <Typography>Unexpected error happens: ${e.message}</Typography>
      </>
    );
  }
}
