import { PaginatedRequest } from '../types/api';
import {
  CreateUserCategoryRequest,
  CreateUserCategoryResponse,
  FetchUserCategorysResponse,
  FetchUserCategoryResponse,
  UpdateUserCategoryRequest,
  UpdateUserCategoryResponse,
} from '../types/userCategory';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class UserCategoryService extends ApiService {
  pathname = 'admin';

  fetchUserCategorys = async (data: PaginatedRequest) =>
    this.get<FetchUserCategorysResponse>(`${this.pathname}/user-category?${toQueryParams(data)}`);

  fetchUserCategory = async (id: number) => this.get<FetchUserCategoryResponse>(`${this.pathname}/user-category/${id}`);

  deleteUserCategory = async (id: number) =>
    this.delete<FetchUserCategoryResponse>(`${this.pathname}/user-category/${id}`);

  createUserCategory = async (userCategory: CreateUserCategoryRequest) =>
    this.post<CreateUserCategoryResponse, CreateUserCategoryRequest>(`${this.pathname}/user-category`, userCategory);

  updateUserCategory = async ({ id, ...userCategory }: UpdateUserCategoryRequest) =>
    this.put<UpdateUserCategoryResponse, Omit<UpdateUserCategoryRequest, 'id'>>(
      `${this.pathname}/user-category/${id}`,
      userCategory,
    );
}

export const userCategoryService = new UserCategoryService();
