import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ColorlibConnector, PortfolioOrderStepIcon } from '../../components/Stepper';
import { useAppDispatch } from '../../redux/hooks';
import { fetchBatchExecutionByBatchId } from '../../redux/modules/batchExecution/batchExecution.actions';
import { batchExecutionBybatchIdSelector } from '../../redux/modules/batchExecution/batchExecution.selectors';
import {
  cleanViewDetailsByBatchId,
  // fetchConsolidateRequestsByOrderId,
  fetchFeeCalculationsByBatchId,
  fetchFeeBatchesByBatchId,
  fetchUserFeeDeductionsByBatchId,
} from '../../redux/modules/feeBatch/feeBatch.actions';
import { viewDetailsByBatchIdSelector } from '../../redux/modules/feeBatch/feeBatch.selectors';
import { ReducerState } from '../../redux/store.types';
import { locations } from '../../routes/locations';
import { FeeBatchExecutionStatus, FeeBatchStatus } from '../../types/feeBatch';
import { FeeCalculation } from '../../types/feeCalculation';
import { FeeBatchActions } from './FeeBatchActions';
import { FeeBatchTabList } from './FeeBatchTabList';

const steps = ['File execution', 'Review and Validation', 'Reconciliation', 'Approved'];

const mapFeeBatchStatusToStep: Record<string, number> = {
  [FeeBatchStatus.IN_PROGRESS]: 0,
  [FeeBatchStatus.EXECUTED]: 0,
};

const mapBatchExecutionStatusToStep: Record<string, number> = {
  [FeeBatchExecutionStatus.EXECUTION]: 0,
  [FeeBatchExecutionStatus.IN_REVIEW]: 1,
  [FeeBatchExecutionStatus.RECONCILIATION]: 2,
  [FeeBatchExecutionStatus.APPROVED]: 3,
};

export function FeeBatch() {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [feeCalculationsKey, setFeeCalculationsKey] = useState<string>(`${Date.now()}`);
  const [executionTemplateData, setExecutionTemplateData] = useState<IAnyObject[]>([]);
  const [selectedFeeCalculations, setSelectedFeeCalculations] = useState<FeeCalculation[]>([]);
  const batchExecution = useSelector((state: ReducerState) => batchExecutionBybatchIdSelector(state, id || ''));
  const { feeBatches, feeCalculations } = useSelector(viewDetailsByBatchIdSelector);
  const feeBatchStatus = feeBatches.length && feeBatches?.[0].status?.name;

  const fetchFeeBatches = useCallback(() => {
    if (id) {
      dispatch(fetchFeeBatchesByBatchId({ limit: 10, offset: 0, batchId: id }));
    }
  }, []);

  const fetchBatchExecution = useCallback(() => {
    if (id) {
      dispatch(fetchBatchExecutionByBatchId(id));
    }
  }, []);

  const fetchConsolidateRequests = useCallback(() => {
    if (id) {
      // dispatch(fetchConsolidateRequestsByOrderId({ limit: 10, offset: 0, orderId: id }));
    }
  }, []);

  const fetchUserFeeDeductions = useCallback(() => {
    if (id) {
      dispatch(fetchUserFeeDeductionsByBatchId({ limit: 10, offset: 0, batchId: id }));
    }
  }, []);

  useEffect(() => {
    if (!id) {
      return navigate(locations.feeBatchList(), { replace: true });
    }

    dispatch(fetchFeeCalculationsByBatchId({ batchId: id, limit: 25 }));
  }, [id]);

  useEffect(() => {
    fetchFeeBatches();
    fetchBatchExecution();
    fetchConsolidateRequests();
    fetchUserFeeDeductions();

    return () => {
      dispatch(cleanViewDetailsByBatchId);
    };
  }, []);

  useEffect(() => {
    const data = feeCalculations.map((fc) => ({
      feeCalculationId: fc.id,
      actualTotalFee: fc.calculatedTotalFee,
      status: 'done',
    }));

    setExecutionTemplateData(data);
  }, [feeCalculations]);

  const onSelectFeeCalculations = (ids: FeeCalculation[]) => setSelectedFeeCalculations(ids);

  const getFeeBatchStep = () => {
    if (batchExecution) {
      return mapBatchExecutionStatusToStep[batchExecution?.status?.name];
    }

    return mapFeeBatchStatusToStep[feeBatchStatus];
  };

  return (
    <Container component="main" maxWidth="xl">
      {getFeeBatchStep() !== undefined && (
        <Stack sx={{ width: '100%', paddingBottom: 2 }} spacing={4}>
          <Stepper alternativeLabel activeStep={getFeeBatchStep()} connector={<ColorlibConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={PortfolioOrderStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      )}

      {id && (
        <FeeBatchActions
          batchId={id}
          setFeeCalculationsKey={setFeeCalculationsKey}
          selectedFeeCalculations={selectedFeeCalculations}
          executionTemplateData={executionTemplateData}
          fetchFeeBatches={fetchFeeBatches}
          fetchBatchExecution={fetchBatchExecution}
          fetchConsolidateRequests={fetchConsolidateRequests}
        />
      )}

      <FeeBatchTabList
        batchId={id}
        feeCalculationsKey={feeCalculationsKey}
        onSelectFeeCalculations={onSelectFeeCalculations}
      />
    </Container>
  );
}
