import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { fundAssetClassService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { CreateFundAssetClassRequest, UpdateFundAssetClassRequest } from '../../../types/fundAssetClass';

export const fetchFundAssetClasses = createAsyncThunk('fundAssetClass/fetch-all', (data: PaginatedRequest) =>
  fundAssetClassService.fetchFundAssetClasses(data),
);

export const fetchFundAssetClassesAutocomplete = createAsyncThunk('fundAssetClass/fetch-autocomplete', (term: string) =>
  fundAssetClassService.fetchFundAssetClassesAutocomplete(term),
);

export const fetchFundAssetClass = createAsyncThunk('fundAssetClass/get', (id: number) =>
  fundAssetClassService.fetchFundAssetClass(id),
);

export const deleteFundAssetClass = createAsyncThunk('fundAssetClass/delete', (id: number) =>
  fundAssetClassService.deleteFundAssetClass(id),
);

export const createFundAssetClass = createAsyncThunk(
  'fundAssetClass/create',
  (fundAssetClass: CreateFundAssetClassRequest) => fundAssetClassService.createFundAssetClass(fundAssetClass),
);

export const updateFundAssetClass = createAsyncThunk(
  'fundAssetClass/update',
  (fundAssetClass: UpdateFundAssetClassRequest) => fundAssetClassService.updateFundAssetClass(fundAssetClass),
);

export const resetEditFundAssetClass = createAction('fundAssetClass/reset-edit');

export const cleanFundAssetClass = createAction('fundAssetClass/clean');
