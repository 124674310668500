import {
  CreateUserConfigRequest,
  CreateUserConfigResponse,
  FetchUserConfigsResponse,
  FetchUserConfigResponse,
  UpdateUserConfigRequest,
  UpdateUserConfigResponse,
  UpdateUserConfigsRequest,
} from '../types/userConfig';
import { ApiService } from './common/ApiService';

class UserConfigService extends ApiService {
  pathname = 'admin/user-config';

  fetchUserConfigs = async () => this.get<FetchUserConfigsResponse>(`${this.pathname}`);

  fetchUserConfig = async (key: string) => this.get<FetchUserConfigResponse>(`${this.pathname}/${key}`);

  deleteUserConfig = async (key: string) => this.delete<FetchUserConfigResponse>(`${this.pathname}/${key}`);

  createUserConfig = async (action: CreateUserConfigRequest) =>
    this.post<CreateUserConfigResponse, CreateUserConfigRequest>(`${this.pathname}`, action);

  updateUserConfig = async ({ key, ...action }: UpdateUserConfigRequest) =>
    this.put<UpdateUserConfigResponse, Omit<UpdateUserConfigRequest, 'key'>>(`${this.pathname}/${key}`, action);

  updateUserConfigs = async (data: UpdateUserConfigRequest[]) =>
    this.put<FetchUserConfigsResponse, UpdateUserConfigsRequest>(`${this.pathname}`, { items: data });
}

export const userConfigService = new UserConfigService();
