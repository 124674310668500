import { ApiResponse } from './api';

export enum FeesConfigKey {
  FEE_AUTO_APPROVAL = 'fee_auto_approval',
}

export type FeesConfig = {
  key: FeesConfigKey;
  value: string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchFeesConfigResponse = ApiResponse<FeesConfig>;
export type FetchFeesConfigsResponse = ApiResponse<FeesConfig[]>;

export type CreateFeesConfigRequest = Omit<FeesConfig, 'id' | 'createdAt' | 'updatedAt'>;
export type CreateFeesConfigResponse = ApiResponse<FeesConfig>;

export type UpdateFeesConfigRequest = Omit<FeesConfig, 'key' | 'createdAt' | 'updatedAt'> & { key: string };
export type UpdateFeesConfigsRequest = {
  items: UpdateFeesConfigRequest[];
};
export type UpdateFeesConfigResponse = ApiResponse<FeesConfig>;
