import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IAsyncDialogProps } from '../../../../components/AsyncDialog/AsyncDialog';
import { DecimalNumberField } from '../../../../components/DecimalNumberField';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { updateJadwaTransferFormFields } from '../../../../forms/fields/formFields/jadwa';
import { updateJadwaTransferFormSchema } from '../../../../forms/validationSchema/formSchema/jadwa';
import { jadwaSelector } from '../../../../redux/modules/jadwa/jadwa.selectors';
import { JadwaTransferStatus } from '../../../../types/jadwa';

export function UpdateTransferDialog(props: IAsyncDialogProps<{ id: number }>) {
  const { handleSubmit, values, handleChange, touched, errors, isValid, submitForm, resetForm } = useFormik({
    initialValues: {
      [updateJadwaTransferFormFields.amount]: 0,
      [updateJadwaTransferFormFields.status]: JadwaTransferStatus.APPROVED,
    },
    validationSchema: updateJadwaTransferFormSchema,
    onSubmit: async (formValues) => {
      props.onOk({ ...formValues, amount: +formValues.amount });
    },
  });
  const { updateTransferStatus, error } = useSelector(jadwaSelector);

  useEffect(() => {
    if (!props.open) {
      resetForm();
    }
  }, [props.open]);

  return (
    <Dialog open={props.open} fullWidth>
      <DialogTitle>Update Transfer</DialogTitle>
      <DialogContent>
        <DialogContentText>{error && <Alert severity="error">{error.message}</Alert>}</DialogContentText>
        <Grid
          onSubmit={handleSubmit}
          component="form"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <FormControl fullWidth margin="normal">
            <InputLabel id="transfer-status-label">Status</InputLabel>
            <Select
              labelId="transfer-status-label"
              name={updateJadwaTransferFormFields.status}
              value={values[updateJadwaTransferFormFields.status]}
              label="Transfer Status"
              onChange={handleChange}
              error={touched.status && !!errors.status}
            >
              {[JadwaTransferStatus.APPROVED].map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DecimalNumberField
            margin="normal"
            fullWidth
            label="Amount"
            type="number"
            id={updateJadwaTransferFormFields.amount}
            name={updateJadwaTransferFormFields.amount}
            value={values[updateJadwaTransferFormFields.amount]}
            onChange={handleChange}
            error={touched.amount && !!errors.amount}
            helperText={touched.amount && errors.amount}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="contained" onClick={props.onClose}>
          Cancel
        </Button>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={updateTransferStatus === fetchStatuses.pending}
          disabled={!isValid}
          loadingPosition="end"
          onClick={submitForm}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
