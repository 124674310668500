import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { userGroupFormFields } from '../../forms/fields/formFields';
import { userGroupFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import { createUserGroup, updateUserGroup } from '../../redux/modules/userGroup/userGroup.actions';
import { userGroupSelector } from '../../redux/modules/userGroup/userGroup.selectors';
import { UserGroup } from '../../types/userGroup';
import { isDispatchApiSuccess } from '../../utils/api';

interface UserGroupDialogProps {
  open: boolean;
  initialValue: UserGroup | null;
  onClose: (isReload: boolean) => void;
}

export function UserGroupDialog({ open, initialValue, onClose }: UserGroupDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, values, handleChange, touched, errors, submitForm, setValues, resetForm } = useFormik({
    initialValues: {
      [userGroupFormFields.nameEn]: '',
      [userGroupFormFields.nameAr]: '',
      [userGroupFormFields.descriptionEn]: '',
      [userGroupFormFields.descriptionAr]: '',
    },
    validationSchema: userGroupFormSchema,
    onSubmit: async (formValues) => {
      if (initialValue?.id) {
        const res = await dispatch(updateUserGroup({ id: initialValue.id, ...formValues }));

        if (isDispatchApiSuccess(res)) {
          onClose(true);
          enqueueSnackbar('User Group updated!', { variant: 'success' });
        } else {
          enqueueSnackbar('User Group update error!', { variant: 'error' });
        }
      } else {
        const res = await dispatch(createUserGroup(formValues));

        if (isDispatchApiSuccess(res)) {
          onClose(true);
          enqueueSnackbar('User Group created!', { variant: 'success' });
        } else {
          enqueueSnackbar('User Group creation error!', { variant: 'error' });
        }
      }
    },
  });
  const { createStatus, updateStatus, error } = useSelector(userGroupSelector);
  const isLoading = createStatus === fetchStatuses.pending || updateStatus === fetchStatuses.pending;

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    setValues({
      nameEn: initialValue?.nameEn || '',
      nameAr: initialValue?.nameAr || '',
      descriptionEn: initialValue?.descriptionEn || '',
      descriptionAr: initialValue?.descriptionAr || '',
    });
  }, [initialValue]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{initialValue?.id ? 'Edit User Group' : 'Create a new User Group'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error && <Alert severity="error">{error.message}</Alert>}
          Enter the User Group name
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="nameEn"
            name={userGroupFormFields.nameEn}
            label="Name En"
            value={values[userGroupFormFields.nameEn]}
            onChange={handleChange}
            error={touched.nameEn && !!errors.nameEn}
            helperText={touched.nameEn && errors.nameEn}
          />
          <TextField
            dir="rtl"
            margin="normal"
            required
            fullWidth
            id="nameAr"
            name={userGroupFormFields.nameAr}
            label="Name Ar"
            value={values[userGroupFormFields.nameAr]}
            onChange={handleChange}
            error={touched.nameAr && !!errors.nameAr}
            helperText={touched.nameAr && errors.nameAr}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            multiline
            id={userGroupFormFields.descriptionEn}
            name={userGroupFormFields.descriptionEn}
            label="Description En"
            value={values[userGroupFormFields.descriptionEn]}
            onChange={handleChange}
            error={touched.descriptionEn && !!errors.descriptionEn}
            helperText={touched.descriptionEn && errors.descriptionEn}
          />
          <TextField
            dir="rtl"
            margin="normal"
            required
            fullWidth
            multiline
            id={userGroupFormFields.descriptionAr}
            name={userGroupFormFields.descriptionAr}
            label="Description Ar"
            value={values[userGroupFormFields.descriptionAr]}
            onChange={handleChange}
            error={touched.descriptionAr && !!errors.descriptionAr}
            helperText={touched.descriptionAr && errors.descriptionAr}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={isLoading}
          loadingPosition="end"
          onClick={submitForm}
        >
          {initialValue?.id ? 'Save' : 'Create'}
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
