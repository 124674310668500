import { ActionType } from '../../types/action';

const readOrderPermission = {
  resource: 'jadwa-order',
  action: ActionType.READ,
};
const triggerTransferPermission = {
  resource: 'jadwa-transfer-trigger',
  action: ActionType.CREATE,
};
const updateTransferPermission = {
  resource: 'jadwa-transfer',
  action: ActionType.UPDATE,
};

export const JadwaPermissions = {
  readOrder: readOrderPermission,
  triggerTransfer: triggerTransferPermission,
  update: updateTransferPermission,
};
