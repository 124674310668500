export enum userAddressFormFields {
  streetName = 'streetName',
  city = 'city',
  address2 = 'address2',
  address1 = 'address1',
  additionalNumber = 'additionalNumber',
  district = 'district',
  unitNumber = 'unitNumber',
  isPrimaryAddress = 'isPrimaryAddress',
  buildingNumber = 'buildingNumber',
  postCode = 'postCode',
  locationCoordinates = 'locationCoordinates',
}
