import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

interface CopyToClipboardButtonProps {
  value: string;
  text?: string;
}

export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({ text, value }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(value);
  };

  const renderButton = () => (
    <Button onClick={handleClick} endIcon={<ContentCopyIcon />} sx={{ p: 0 }} disabled={!value}>
      {text || value}
    </Button>
  );

  return (
    <>
      {value ? (
        <Tooltip title={`Click to copy '${text || value}'`} followCursor>
          {renderButton()}
        </Tooltip>
      ) : (
        renderButton()
      )}
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        message="Copied to clipboard"
      />
    </>
  );
};
