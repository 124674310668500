import {
  FetchAlpacaAccountByUserIdResponse,
  FetchTradingAccountResponse,
  FetchAlpacaEventsRequest,
  FetchAlpacaEventsResponse,
  FetchAlpacaOrdersByMadkholOrderIdResponse,
  FetchAlpacaOrdersResponse,
  FetchAlpacaTransfersResponse,
} from '../types/alpaca';
import { PaginatedRequest } from '../types/api';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class AlpacaService extends ApiService {
  pathname = 'admin/alpaca';

  fetchEvents = async (data: FetchAlpacaEventsRequest) =>
    this.get<FetchAlpacaEventsResponse>(`${this.pathname}/events?${toQueryParams(data)}`);

  fetchOrders = async (data: PaginatedRequest) =>
    this.get<FetchAlpacaOrdersResponse>(`${this.pathname}/orders?${toQueryParams(data)}`);

  fetchTransfers = async (data: PaginatedRequest) =>
    this.get<FetchAlpacaTransfersResponse>(`${this.pathname}/transfers?${toQueryParams(data)}`);

  fetchOrdersByMadkholOrderId = async (madkholOrderId: string) =>
    this.get<FetchAlpacaOrdersByMadkholOrderIdResponse>(`${this.pathname}/orders/${madkholOrderId}`);

  sendOrderToJournalQueue = async (madkholOrderId: string) =>
    this.post(`${this.pathname}/manual/order/${madkholOrderId}/journal-queue`, {});

  sendOrderToCompleteQueue = async (madkholOrderId: string) =>
    this.post(`${this.pathname}/manual/order/${madkholOrderId}/complete-queue`, {});

  sendOrderToTransferQueue = async (madkholOrderId: string) =>
    this.post(`${this.pathname}/manual/order/${madkholOrderId}/transfer-queue`, {});

  sendOrderToOrderQueue = async (madkholOrderId: string) =>
    this.post(`${this.pathname}/manual/order/${madkholOrderId}/order-queue`, {});

  fetchAccountByUserId = async (userId: number) =>
    this.get<FetchAlpacaAccountByUserIdResponse>(`${this.pathname}/account/${userId}`);

  createAccount = async (userId: number) => this.post(`${this.pathname}/account`, { userId });

  fetchTradingAccount = async () => this.get<FetchTradingAccountResponse>(`${this.pathname}/trading-account`);
}

export const alpacaService = new AlpacaService();
