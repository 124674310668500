import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Box from '@mui/system/Box';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import _lowerCase from 'lodash/lowerCase';
import { useAppDispatch } from '../../redux/hooks';
import { fetchUserWorldCheckScreen } from '../../redux/modules/user/user.actions';
import { userSelector } from '../../redux/modules/user/user.selectors';
import { CopyToClipboardButton } from '../../components/CopyToClipboardButton';

interface WorldCheckTabProps {
  userId: number;
}

export function WorldCheckTab({ userId }: WorldCheckTabProps) {
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = React.useState<string>('0');
  const { userWorldCheckScreenData, error } = useSelector(userSelector);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserWorldCheckScreen(Number(userId)));
    }
  }, []);

  const getWorldCheckStatus = (data: IAnyObject | null) => {
    if (!data) {
      return 'Not Found';
    }

    return data?.screenResult ? 'Success' : 'Failure';
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const renderAttributeValue = (
    key: string,
    value: string | number | boolean | object | object[],
    isFormat = false,
  ) => {
    if (Array.isArray(value)) {
      return value.map((item) => renderAttributeValue(key, item, isFormat)).join('\n');
    }

    if (key === 'matchedCriteria') {
      return Object.entries(value)
        .map(([k, v]) => `{ "${k}": ${renderAttributeValue(k, v, isFormat)} }`)
        .join('\n');
    }

    if (typeof value === 'object') {
      return JSON.stringify(value, isFormat ? null : undefined, isFormat ? 2 : 0);
    }

    if (typeof value === 'boolean') {
      return value ? 'TRUE' : 'FALSE';
    }

    return value;
  };

  const renderDataItems = () => {
    if (!userWorldCheckScreenData?.data.length) {
      return;
    }

    return (
      <TabContext value={selectedTab}>
        <Box sx={{ width: '100%' }}>
          <TabList
            value={selectedTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="world-check-data-item"
          >
            {userWorldCheckScreenData.data.map((item, index) => (
              <Tab
                key={`world-check-data-item-${item.id}`}
                value={index.toString()}
                label={`Case #${item.id}`}
                sx={{ textTransform: 'capitalize' }}
                disableRipple
              />
            ))}
          </TabList>
        </Box>
        {userWorldCheckScreenData.data.map((item, index) => (
          <TabPanel key={`world-check-data-item-panel-${item.id}`} value={index.toString()} sx={{ width: '100%' }}>
            <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: 0 }} spacing={2}>
              <Typography variant="h6">Result Data</Typography>

              <Grid container spacing={2}>
                <Grid item container xs={12}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">id</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <CopyToClipboardButton value={item.id} />
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">Type</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body2" component="pre" style={{ overflow: 'auto' }}>
                      {item.type}
                    </Typography>
                  </Grid>
                </Grid>
                {Object.entries(item.attributes as Record<string, any>).map(([key, value]) => (
                  <Grid key={`world-check-attribute-${key}`} item container xs={12}>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
                        {_lowerCase(key)}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body2" component="pre" style={{ overflow: 'auto' }}>
                        {renderAttributeValue(key, value)}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </TabPanel>
        ))}
      </TabContext>
    );
  };

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: 0 }}>
        <Grid item container xs={8} spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6">World Check Status</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">{getWorldCheckStatus(userWorldCheckScreenData)}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: '24px' }}>
        <Grid item container xs={8} spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6">User Data</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" component="pre" style={{ overflow: 'auto' }}>
              {userWorldCheckScreenData?.user ? JSON.stringify(userWorldCheckScreenData.user, null, 2) : '-'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {renderDataItems()}
    </>
  );
}
