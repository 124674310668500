import { useEffect, useState } from 'react';
import { PermissionProps } from '../layouts/AppPageLayout/Sidebar/types';
import { useFetchMyPermissionsQuery } from '../redux/features/rolePermission/rolePermission.apiSlice';

export function usePermissions({ items, checkType = 'every' }: PermissionProps) {
  const [hasPermission, setHasPermission] = useState(false);
  const { data: myPermissions } = useFetchMyPermissionsQuery();

  useEffect(() => {
    const allPermissions = items.map(({ resource, action }) => (myPermissions || {})[resource]?.includes(action));

    const hasPermissions = checkType === 'every' ? allPermissions.every(Boolean) : allPermissions.some(Boolean);

    setHasPermission(hasPermissions);
  }, [myPermissions]);

  return hasPermission;
}
