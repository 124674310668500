import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSnackbar } from 'notistack';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useAppDispatch } from '../../redux/hooks';
import { fetchAnbPaymentDetails } from '../../redux/modules/payment/payment.actions';
import { anbPaymentDetailsSelector, paymentSelector } from '../../redux/modules/payment/payment.selectors';
import { ReducerState } from '../../redux/store.types';

export function AnbPaymentDetails() {
  const { t } = useTranslation('apiError');
  const dispatch = useAppDispatch();
  const [anbPaymentId, setAnbPaymentId] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const { fetchAnbPaymentDetailsStatus, error } = useSelector(paymentSelector);
  const paymentDetails = useSelector((state: ReducerState) => anbPaymentDetailsSelector(state, anbPaymentId));

  const fetchAnbPaymentDetailsById = async () => {
    if (anbPaymentId) {
      try {
        await dispatch(fetchAnbPaymentDetails(anbPaymentId)).unwrap();

        enqueueSnackbar('Anb Payment details found successfully!', { variant: 'success' });
      } catch (err: any) {
        enqueueSnackbar(`Fetch Anb Payment details failed: ${t(err?.message)} ${JSON.stringify(err?.data)}`, {
          variant: 'error',
        });
      }
    }
  };

  const renderPaymentDetails = () => {
    if (error) {
      return (
        <>
          <Divider variant="inset" />
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant="subtitle1">Payment Details</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                <Alert severity="error">{error.message}</Alert>
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    }

    return (
      <>
        <Divider variant="inset" />
        <Grid item container xs={12}>
          <Grid item xs={6}>
            <Typography variant="subtitle1">Payment Details</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" component="pre" style={{ padding: 8, overflow: 'auto' }}>
              {paymentDetails ? JSON.stringify(paymentDetails, null, 2) : 'Not found'}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Grid
      item
      container
      flexDirection="row"
      justifyContent="space-around"
      alignItems="center"
      component={Paper}
      sx={{ p: '24px' }}
    >
      {error && <Alert severity="error">{error.message}</Alert>}

      <Grid item sx={{ pb: 5 }} xs={12}>
        <Typography variant="h6">Check Payment Details</Typography>
      </Grid>

      <Grid item xs={6}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="anbPaymentId"
          name="anbPaymentId"
          type="string"
          label="Anb Payment Id"
          value={anbPaymentId}
          onChange={(event) => setAnbPaymentId(event.target.value)}
        />
      </Grid>

      <Grid item xs={6}>
        <LoadingButton
          type="button"
          color="primary"
          size="small"
          variant="text"
          endIcon={<SavedSearchIcon />}
          disabled={!anbPaymentId}
          loading={fetchAnbPaymentDetailsStatus === fetchStatuses.pending}
          loadingPosition="end"
          onClick={fetchAnbPaymentDetailsById}
        >
          Check
        </LoadingButton>
      </Grid>

      {renderPaymentDetails()}
    </Grid>
  );
}
