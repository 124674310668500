import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { useAppDispatch } from '../../redux/hooks';
import { fetchSystemCashReport } from '../../redux/modules/report/report.actions';
import { systemReportByDateSelector } from '../../redux/modules/report/report.selectors';
import { DownwardIcon, NoopIcon, UpwardIcon } from '../../components/Icon';
import { ReducerState } from '../../redux/store.types';
import { SystemCashReport } from '../../types/report';
import { round2Format } from '../../utils/number';

type SystemCashWidgetItemElement = {
  propName: keyof SystemCashReport;
  title: string;
  formatFn: (v?: any) => string;
};

type SystemCashWidgetItem = {
  title: string;
  elements: SystemCashWidgetItemElement[];
};

const getDate = (date?: Date) => (date ? moment(date).format('DD-MM-YYYY HH:mm') : '-');
const getFilterDate = (date?: Date) => (date ? moment(date).format('DD-MM-YYYY') : '-');
const numberFormatter = (v?: number) => round2Format(v || 0);
const dateFormatter = (v?: Date) => getDate(v);

const systemCashWidgets: SystemCashWidgetItem[] = [
  {
    title: 'Wallet values',
    elements: [
      { propName: 'totalCashAvailable', title: 'Total Cash Available', formatFn: numberFormatter },
      { propName: 'cashOnHold', title: 'Total Cash On Hold', formatFn: numberFormatter },
      { propName: 'cashAllocation', title: 'Cash Allocation', formatFn: numberFormatter },
    ],
  },
  {
    title: 'Day activities',
    elements: [
      { propName: 'updatedAt', title: 'Last Update', formatFn: dateFormatter },
      { propName: 'startOfDayBalance', title: 'System Start Balance', formatFn: numberFormatter },
      { propName: 'userIncoming', title: 'User Incoming', formatFn: numberFormatter },
      { propName: 'alpacaIncoming', title: 'Alpaca Incoming', formatFn: numberFormatter },
      { propName: 'jadwaIncoming', title: 'Jadwa Incoming', formatFn: numberFormatter },
      // { propName: 'mastercardIncoming', title: 'Mastercard Incoming', formatFn: numberFormatter },
      { propName: 'netWithdrawals', title: 'User Outgoing', formatFn: numberFormatter },
      { propName: 'alpacaOutgoing', title: 'Alpaca Outgoing', formatFn: numberFormatter },
      { propName: 'jadwaOutgoing', title: 'Jadwa Outgoing', formatFn: numberFormatter },
      { propName: 'feeAmount', title: 'Fee Amount', formatFn: numberFormatter },
      { propName: 'interdayActivities', title: 'Interday Activities', formatFn: numberFormatter },
      { propName: 'systemEndOfDayBalance', title: 'System EOD Balance', formatFn: numberFormatter },
      { propName: 'endOfDayBalance', title: 'ANB EOD Statement', formatFn: numberFormatter },
    ],
  },
];

export function SystemCashWidget() {
  const dispatch = useAppDispatch();
  const [filterDate, setFilterDate] = useState(new Date());
  const prevDate = new Date(filterDate);
  const nextDate = new Date(filterDate);

  prevDate.setDate(filterDate.getDate() - 1);
  prevDate.setHours(0, 0, 0, 0);
  nextDate.setDate(filterDate.getDate() + 1);
  nextDate.setHours(0, 0, 0, 0);

  const doFetchSystemCashReport = useCallback((searchValue: Date) => {
    if (searchValue) {
      dispatch(
        fetchSystemCashReport({
          filter: JSON.stringify({
            items: [
              {
                columnField: 'date',
                operatorValue: '=',
                value: getDate(searchValue).split(' ')[0],
              },
            ],
          }),
        }),
      );
    }
  }, []);

  useEffect(() => {
    const init = () => {
      doFetchSystemCashReport(prevDate);
      doFetchSystemCashReport(filterDate);
    };

    init();
  }, []);

  const { systemReport } = useSelector((state: ReducerState) =>
    systemReportByDateSelector(state, getFilterDate(filterDate)),
  );
  const { systemReport: prevSystemReport } = useSelector((state: ReducerState) =>
    systemReportByDateSelector(state, getFilterDate(prevDate)),
  );

  useEffect(() => {
    doFetchSystemCashReport(prevDate);
    doFetchSystemCashReport(filterDate);
  }, [filterDate]);

  const incrementFilterDate = (increment: number) => {
    const newFilterDate = new Date(filterDate);

    newFilterDate.setHours(0, 0, 0, 0);
    newFilterDate.setDate(filterDate.getDate() + increment);

    if (newFilterDate > new Date()) {
      return;
    }

    setFilterDate(newFilterDate);
  };

  const renderPrevValue = (currentValue?: number | string | Date, prevValue?: number | string | Date) => {
    if (typeof currentValue !== 'number' || typeof prevValue !== 'number' || currentValue === prevValue) {
      return <NoopIcon />;
    }

    const isMore = currentValue > prevValue;

    return (
      <Tooltip
        title={`${round2Format(Math.abs(currentValue - prevValue))} ${isMore ? 'more' : 'less'} `}
        followCursor
        enterTouchDelay={0}
      >
        <Grid display="flex" alignItems="center" justifyContent="center">
          {isMore ? <UpwardIcon /> : <DownwardIcon />}
        </Grid>
      </Tooltip>
    );
  };

  const renderItem = ({ propName, title, formatFn }: SystemCashWidgetItemElement, index: number) => (
    <Grid item container sm={12} md={6} lg={4} sx={{ mb: 2 }} key={`system-cash-widget-element-${index}-${propName}`}>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">{title}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} flexDirection="row" display="flex" alignItems="center">
        {renderPrevValue(systemReport?.[propName], prevSystemReport?.[propName])}
        <Typography sx={{ ml: 1 }} variant="body2">
          {formatFn(systemReport?.[propName] || '')}
        </Typography>
      </Grid>
    </Grid>
  );

  const renderSystemCashWidgets = () => (
    <Grid item container flexDirection="row" sx={{ mt: 0 }} xs={12}>
      {systemCashWidgets.map(({ title, elements }, i: number) => (
        <Grid container flexDirection="column" key={`system-cash-widget-${title}`} sx={{ mb: 4 }}>
          <Grid item container xs={12}>
            <Typography variant="subtitle1" fontWeight="bold">
              {title}
            </Typography>

            <Grid item container flexDirection="row" sx={{ mt: 0 }} xs={12}>
              {elements.map(renderItem)}
            </Grid>

            {i < systemCashWidgets.length - 1 && (
              <Grid item sx={{ my: 2 }} xs={12}>
                <Divider variant="inset" />
              </Grid>
            )}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Grid item container sx={{ mt: 4 }}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h6">System Cash Report</Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" alignItems="center">
            <Button
              sx={{ mx: 1 }}
              variant="outlined"
              size="large"
              onClick={() => incrementFilterDate(-1)}
              // disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <DatePicker
              label="Date"
              value={filterDate}
              onChange={(v: Date | null) => {
                if (v) {
                  v.setHours(0, 0, 0, 0);
                  setFilterDate(v);
                }
              }}
              maxDate={new Date()}
              slotProps={{
                textField: {
                  margin: 'none',
                  disabled: true,
                },
              }}
            />
            <Button
              sx={{ mx: 1 }}
              variant="outlined"
              size="large"
              onClick={() => incrementFilterDate(1)}
              disabled={nextDate > new Date()}
              aria-label="move selected right"
            >
              &gt;
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={{ my: 4, width: '100%' }}>
        <Divider variant="inset" />
      </Grid>

      {renderSystemCashWidgets()}
    </Grid>
  );
}
