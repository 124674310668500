import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { feeCalculationService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';

export const fetchFeeCalculations = createAsyncThunk('fee-calculation/fetch-all', (data: PaginatedRequest) =>
  feeCalculationService.fetchFeeCalculations(data),
);

export const fetchFeeCalculation = createAsyncThunk('fee-calculation/fetch-by-id', (id: number) =>
  feeCalculationService.fetchFeeCalculation(id),
);

export const cleanFeeCalculations = createAction('fee-calculation/clean');
