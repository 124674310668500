import { apiSlice } from '../../../api/common/apiSlice';
import { PaginatedRequest } from '../../../types/api';
import { toQueryParams } from '../../../utils/request';
import { ApiTagType } from '../../../constants/apiTagType';
import { FetchAlpacaOrdersExResponse } from '../../../types/alpaca';

export const alpacaApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchOrders: builder.query<FetchAlpacaOrdersExResponse, PaginatedRequest>({
      query: (data) => ({
        url: `/admin/alpaca/orders?${toQueryParams(data)}`,
        method: 'GET',
      }),
      providesTags: (res) => {
        if (res) {
          return [
            ...(res.items || []).map(({ id }) => ({ type: ApiTagType.AlpacaOrder as const, id })),
            { type: ApiTagType.AlpacaOrder, id: 'LIST' },
          ];
        }
        return [{ type: ApiTagType.AlpacaOrder, id: 'LIST' }];
      },
    }),
  }),
});

export const { useFetchOrdersQuery } = alpacaApiSlice;
