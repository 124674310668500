import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchPortfolioConfigResponse, FetchPortfolioConfigsResponse } from '../../../types/portfolioConfig';
import {
  fetchPortfolioConfig,
  fetchPortfolioConfigs,
  resetEditPortfolioConfig,
  cleanPortfolioConfigs,
  updatePortfolioConfig,
  createPortfolioConfigItem,
  deletePortfolioConfig,
} from './portfolioConfig.actions';
import { PortfolioConfigState } from './portfolioConfig.types';

const initialState: PortfolioConfigState = {
  portfolioConfigs: [],
  editPortfolioConfig: null,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const portfolioConfigsSlice = createSlice({
  name: 'portfolioConfigs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPortfolioConfigs.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchPortfolioConfigs.fulfilled,
      (state, portfolioConfig: PayloadAction<FetchPortfolioConfigsResponse>) => {
        state.portfolioConfigs = portfolioConfig.payload.data;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchPortfolioConfigs.rejected, (state, portfolioConfig) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = portfolioConfig.error;
    });

    builder.addCase(fetchPortfolioConfig.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchPortfolioConfig.fulfilled,
      (state, portfolioConfig: PayloadAction<FetchPortfolioConfigResponse>) => {
        state.editPortfolioConfig = portfolioConfig.payload.data;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchPortfolioConfig.rejected, (state, portfolioConfig) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = portfolioConfig.error;
    });

    builder.addCase(updatePortfolioConfig.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updatePortfolioConfig.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updatePortfolioConfig.rejected, (state, portfolioConfig) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = portfolioConfig.error;
    });

    builder.addCase(createPortfolioConfigItem.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(
      createPortfolioConfigItem.fulfilled,
      (state, portfolioConfig: PayloadAction<FetchPortfolioConfigResponse>) => {
        state.editPortfolioConfig = portfolioConfig.payload.data;
        state.createStatus = fetchStatuses.success;
      },
    );
    builder.addCase(createPortfolioConfigItem.rejected, (state, portfolioConfig) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = portfolioConfig.error;
    });

    builder.addCase(deletePortfolioConfig.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deletePortfolioConfig.fulfilled, (state) => {
      state.editPortfolioConfig = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deletePortfolioConfig.rejected, (state, portfolioConfig) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = portfolioConfig.error;
    });

    builder.addCase(resetEditPortfolioConfig.type, (state) => {
      state.editPortfolioConfig = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanPortfolioConfigs.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const portfolioConfigReducer = portfolioConfigsSlice.reducer;
