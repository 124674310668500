import type {
  LoginResponse,
  LoginRequest,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  RestorePasswordRequest,
  RestorePasswordResponse,
  FetchUserProfileResponse,
} from '../types/auth';
import { ApiService } from './common/ApiService';

class AuthService extends ApiService {
  pathname = 'auth';

  constructor() {
    super(process.env.REACT_APP_AUTH_API_URI);
  }

  login = async (data: LoginRequest) => this.post<LoginResponse, LoginRequest>(`${this.pathname}/auth/login`, data);

  logout = async () => this.post(`${this.pathname}/auth/logout`, {});

  fetchUserProfile = async () => this.get<FetchUserProfileResponse>(`${this.pathname}/auth/check-auth`);

  forgotPassword = async (data: ForgotPasswordRequest) =>
    this.post<ForgotPasswordResponse, ForgotPasswordRequest>(`${this.pathname}/auth/forgot-password`, data);

  restorePassword = async (data: RestorePasswordRequest) =>
    this.post<RestorePasswordResponse, RestorePasswordRequest>(`${this.pathname}/auth/restore-password`, data);
}

export const authService = new AuthService();
