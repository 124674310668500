import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { GridActionsCellItemProps, GridRenderCellParams, GridColDef, GridRowParams } from '@mui/x-data-grid-premium';
import { GridActionsCellItemLink } from '../../components/FakeLink';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { portfolioOrderStatusToInfo } from '../../constants/tables/portfolioOrder';
import { portfolioOrderExecutionStatusToInfo } from '../../constants/tables/portfolioOrderExecution';
import { usePermissions } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import { portfolioOrderByRequestIdSelector } from '../../redux/modules/portfolioOrder/portfolioOrder.selectors';
import { cleanPortfolioOrders, fetchPortfolioOrders } from '../../redux/modules/portfolioOrder/portfolioOrder.actions';
import { ReducerState } from '../../redux/store.types';
import { locations } from '../../routes/locations';
import { FundCategory } from '../../types/indexFund';
import { PortfolioOrderExecutionStatus, PortfolioOrderStatus } from '../../types/portfolioOrder';
import { UserPortfolioRequestType } from '../../types/userPortfolioRequest';
import * as permissions from '../../utils/permissions';

interface PortfolioOrderTableProps {
  requestIds: number[];
}

export function PortfolioOrderTable({ requestIds }: PortfolioOrderTableProps) {
  const requestId = 'user-portfolio-request-portfolio-order-list';
  const dispatch = useAppDispatch();
  const tableRef = useRef<TableRef>(null);
  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Id',
      width: 50,
    },
    {
      field: 'orderId',
      headerName: 'Order Id',
      type: 'string',
      width: 300,
    },
    {
      field: 'userPortfolioRequest.fundCategory.name',
      headerName: 'Fund Category',
      type: 'singleSelect',
      valueOptions: Object.values(FundCategory),
      width: 130,
      valueGetter: (params) => params.row?.userPortfolioRequest?.fundCategory?.name,
    },
    {
      field: 'userPortfolioRequest.id',
      headerName: 'Request Id',
      valueGetter: (params) => params.row?.userPortfolioRequest?.id,
      width: 130,
    },
    {
      field: 'userPortfolioRequest.type.name',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: Object.values(UserPortfolioRequestType),
      minWidth: 150,
      valueGetter: (params) => params.row?.userPortfolioRequest?.type?.name,
    },
    {
      field: 'userPortfolioRequest.userPortfolio.fundAmount',
      headerName: 'Amount',
      type: 'number',
      valueGetter: (params) => {
        if (params.row?.userPortfolioRequest?.type?.name === UserPortfolioRequestType.REDEMPTION) {
          return params.row?.userPortfolioRequest?.changes?.redeemAll
            ? 'all'
            : params.row?.userPortfolioRequest?.changes?.amount;
        }

        return params.row?.userPortfolioRequest?.userPortfolio?.fundAmount;
      },
      width: 100,
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(PortfolioOrderStatus),
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = portfolioOrderStatusToInfo[params.row?.status?.name as PortfolioOrderStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.row?.status?.name;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.row?.status?.name} />;
      },
    },
    {
      field: 'portfolioOrderExecutions.status',
      headerName: 'Execution Status',
      type: 'singleSelect',
      sortable: false,
      filterable: false,
      valueOptions: Object.values(PortfolioOrderExecutionStatus),
      minWidth: 150,
      flex: 1,
      valueFormatter: (params) => params.value,
      valueGetter: (params) => params.row.portfolioOrderExecutions?.[0]?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = portfolioOrderExecutionStatusToInfo[params.value as PortfolioOrderExecutionStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.value;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.value} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const canDoActions = usePermissions({ items: [permissions.PortfolioOrder.execute] });
  const { totalPortfolioOrders, portfolioOrders, fetchStatus } = useSelector((state: ReducerState) =>
    portfolioOrderByRequestIdSelector(state, requestId),
  );
  const loading = fetchStatus === fetchStatuses.pending;

  useEffect(
    () => () => {
      dispatch(cleanPortfolioOrders());
    },
    [],
  );

  useEffect(() => {
    const actionColumn = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      filterable: false,
      sortable: false,
      getActions: (params: GridRowParams) => {
        const actions: React.ReactElement<GridActionsCellItemProps>[] = [];

        actions.push(
          <GridActionsCellItemLink
            key={`${params.id}-portfolio-order-list-actions`}
            icon={<OpenInNewIcon />}
            label="Link"
            href={locations.portfolioOrder(params.row.orderId)}
          />,
        );

        return actions;
      },
    };

    if (canDoActions) {
      setColumns([actionColumn, ...columns]);
    }
  }, [canDoActions]);

  try {
    return (
      <>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Portfolio Orders</Typography>
        </Box>
        <Table
          ref={tableRef}
          wrapperHeight={300}
          fetchItems={fetchPortfolioOrders}
          rows={portfolioOrders}
          columns={columns}
          loading={loading}
          rowCount={totalPortfolioOrders}
          disableColumnFilter
          disableColumnMenu
          density="compact"
          fetchItemsCustomParams={{ requestId }}
          initialColumnVisibilityModel={{ 'userPortfolioRequest.id': true }}
          requiredFilters={[{ field: 'userPortfolioRequest.id', value: requestIds, operator: 'isAnyOf' }]}
          initialSortModel={[{ field: 'id', sort: 'desc' }]}
        />
      </>
    );
  } catch (e: any) {
    return (
      <>
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Portfolio Orders</Typography>
        </Box>
        <Typography>Unexpected error happens: ${e.message}</Typography>
      </>
    );
  }
}
