import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchFeesConfigResponse, FetchFeesConfigsResponse } from '../../../types/feesConfig';
import {
  fetchFeesConfig,
  fetchFeesConfigs,
  resetEditFeesConfig,
  cleanFeesConfigs,
  updateFeesConfig,
  createFeesConfigItem,
  deleteFeesConfig,
} from './feesConfig.actions';
import { FeesConfigState } from './feesConfig.types';

const initialState: FeesConfigState = {
  feesConfigs: [],
  editFeesConfig: null,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const feesConfigsSlice = createSlice({
  name: 'feesConfigs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFeesConfigs.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchFeesConfigs.fulfilled, (state, feesConfig: PayloadAction<FetchFeesConfigsResponse>) => {
      state.feesConfigs = feesConfig.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFeesConfigs.rejected, (state, feesConfig) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = feesConfig.error;
    });

    builder.addCase(fetchFeesConfig.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchFeesConfig.fulfilled, (state, feesConfig: PayloadAction<FetchFeesConfigResponse>) => {
      state.editFeesConfig = feesConfig.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchFeesConfig.rejected, (state, feesConfig) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = feesConfig.error;
    });

    builder.addCase(updateFeesConfig.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateFeesConfig.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateFeesConfig.rejected, (state, feesConfig) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = feesConfig.error;
    });

    builder.addCase(createFeesConfigItem.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(createFeesConfigItem.fulfilled, (state, feesConfig: PayloadAction<FetchFeesConfigResponse>) => {
      state.editFeesConfig = feesConfig.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createFeesConfigItem.rejected, (state, feesConfig) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = feesConfig.error;
    });

    builder.addCase(deleteFeesConfig.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteFeesConfig.fulfilled, (state) => {
      state.editFeesConfig = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteFeesConfig.rejected, (state, feesConfig) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = feesConfig.error;
    });

    builder.addCase(resetEditFeesConfig.type, (state) => {
      state.editFeesConfig = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanFeesConfigs.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const feesConfigReducer = feesConfigsSlice.reducer;
