import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { resourceService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { CreateResourceRequest, UpdateResourceRequest } from '../../../types/resource';

export const fetchAccessResources = createAsyncThunk('resource/fetch-access', () => resourceService.fetchAccessResources());

export const fetchResources = createAsyncThunk('resource/fetch-all', (data: PaginatedRequest) => resourceService.fetchResources(data));

export const fetchResourcesAutocomplete = createAsyncThunk('resource/fetch-autocomplete', (term: string) => resourceService.fetchResourcesAutocomplete(term));

export const fetchResource = createAsyncThunk('resource/get', (id: number) => resourceService.fetchResource(id));

export const deleteResource = createAsyncThunk('resource/delete', (id: number) => resourceService.deleteResource(id));

export const createResource = createAsyncThunk('resource/create', (resource: CreateResourceRequest) => resourceService.createResource(resource));

export const updateResource = createAsyncThunk('resource/update', (resource: UpdateResourceRequest) => resourceService.updateResource(resource));

export const resetEditResource = createAction('resource/reset-edit');

export const cleanResources = createAction('resource/clean');
