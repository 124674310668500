import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { GridCellExpand, Table } from '../../../../components/Table';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { fetchUserTransactions } from '../../../../redux/modules/user/user.actions';
import { userTransactionsSelector } from '../../../../redux/modules/user/user.selectors';
import { round2Format, round4Format } from '../../../../utils/number';

interface UserTransactionsTableProps {
  userId: number;
}

const mapDetailsPropertyToFormatFn: Record<string, (v: number) => string> = {
  nav: round2Format,
  numOfUnits: round4Format,
  costBase: round2Format,
  leftUnits: round4Format,
  totalCostBase: round2Format,
  profit: round2Format,
  reconcile: round2Format,
};

export function UserTransactionsTable({ userId }: UserTransactionsTableProps) {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id' },
    {
      field: 'activity.activity',
      headerName: 'activity',
      width: 200,
      valueGetter: (params) => params.row?.activity?.activity,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 150,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'details',
      headerName: 'Details',
      sortable: false,
      flex: 1,
      valueFormatter: (params) => JSON.stringify(params.value, null, 2) || '',
      renderCell: (params: GridRenderCellParams<IAnyObject>) => {
        const value = {
          ...(params.value || {}),
        };

        for (const [key, formatFn] of Object.entries(mapDetailsPropertyToFormatFn)) {
          if (value && value.hasOwnProperty(key)) {
            value[key] = formatFn(value[key]);
          }
        }

        return <GridCellExpand value={JSON.stringify(value, null, 2) || ''} width={params.colDef.computedWidth} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: false,
      type: 'date',
      width: 100,
    },
  ]);
  const { totalUserTransactionItems, userTransactionItems, fetchUserTransactionsStatus } =
    useSelector(userTransactionsSelector);

  return (
    <Table
      fetchItems={fetchUserTransactions}
      fetchItemsCustomParams={{ userId }}
      rows={userTransactionItems}
      columns={columns.current}
      loading={fetchUserTransactionsStatus === fetchStatuses.pending}
      rowCount={totalUserTransactionItems}
      disableColumnFilter
      disableColumnMenu
      density="compact"
      initialColumnVisibilityModel={{ id: false }}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      wrapperHeight={300}
      toolbarExportProps={{
        csvOptions: { fileName: `user-${userId}-transactions-${Date.now()}` },
        excelOptions: { fileName: `user-${userId}-transactions-${Date.now()}` },
      }}
    />
  );
}
