import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { GridActionsCellItem, GridActionsCellItemProps, GridColDef, GridRowParams } from '@mui/x-data-grid-premium';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { fetchSystemUnitsReport } from '../../redux/modules/report/report.actions';
import { reportSelector } from '../../redux/modules/report/report.selectors';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { Table } from '../../components/Table';
import { SystemUnitsReport } from '../../types/report';
import { SystemUnitsReconcileDialog } from './SystemUnitsReconcileDialog';
import { FundSource } from '../../types/indexFund';
import { TableRef } from '../../components/Table/Table';

export function SystemUnitsWidget() {
  const { systemUnitsReport, fetchSystemUnitsReportStatus } = useSelector(reportSelector);
  const [reconcileItem, setReconcileItem] = useState<SystemUnitsReport | undefined>(undefined);
  const tableRef = useRef<TableRef>(null);

  const onReconcileUnits = (row: SystemUnitsReport) => {
    setReconcileItem(row);
  };
  const onReconcileDialogCLose = (reload: boolean) => {
    if (reload) {
      tableRef?.current?.reload();
    }
    setReconcileItem(undefined);
  };

  const columns = useRef<GridColDef[]>([
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      filterable: false,
      sortable: false,
      getActions: ({ row }: GridRowParams) => {
        const actions: React.ReactElement<GridActionsCellItemProps>[] = [];
        if (row.indexFund.source?.name !== FundSource.ALPACA_MARKET) {
          actions.push(
            <GridActionsCellItem
              key={`userGroup-list-update-${row.id}`}
              icon={<EditOutlinedIcon />}
              label="Actions"
              onClick={() => onReconcileUnits(row)}
            />,
          );
        }
        return actions;
      },
    },
    {
      headerName: 'Index Fund',
      field: 'indexFund',
      type: 'string',
      width: 300,
      valueFormatter: (params) => params.value.nameEn,
    },
    {
      headerName: 'System Units',
      field: 'systemUnits',
      type: 'string',
      width: 150,
    },
    {
      headerName: 'Actual Units (external)',
      field: 'externalUnits',
      type: 'string',
      width: 150,
      valueGetter: ({ row }) => row.externalUnits + row.reconciledExternal,
    },
    {
      headerName: 'Diff',
      field: 'diff',
      type: 'string',
      width: 150,
      valueGetter: ({ row }) => Math.abs(row.systemUnits - row.externalUnits - row.reconciledExternal),
    },
    {
      headerName: 'Total Cost base',
      field: 'totalCostBase',
      type: 'number',
      width: 150,
    },
    {
      headerName: 'Per unit Cost base',
      field: 'perUnitCostBase',
      type: 'number',
      width: 150,
      valueGetter: ({ row }) => {
        const perUnitPrice = row.totalCostBase / row.systemUnits;
        return Number.isNaN(perUnitPrice) ? 0 : perUnitPrice;
      },
    },
  ]);

  return (
    <>
      <Table
        ref={tableRef}
        wrapperHeight={410}
        fetchItems={fetchSystemUnitsReport}
        rows={systemUnitsReport}
        columns={columns.current}
        loading={fetchSystemUnitsReportStatus === fetchStatuses.pending}
        rowCount={systemUnitsReport.length}
        hideFooter
        disableColumnFilter
      />
      <SystemUnitsReconcileDialog systemUnits={reconcileItem} onClose={onReconcileDialogCLose} />
    </>
  );
}
