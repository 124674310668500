import { GridActionsCellItem, GridActionsCellItemProps } from '@mui/x-data-grid-premium';
import React, { RefAttributes } from 'react';
import { useNavigate } from 'react-router-dom';
import { FakeLink } from './FakeLink';

type GridLinkActionProps = { href: string } & GridActionsCellItemProps & RefAttributes<HTMLButtonElement>;

export const GridActionsCellItemLink = ({ href, ...props }: GridLinkActionProps) => {
  const navigate = useNavigate();

  return (
    <FakeLink href={href}>
      <GridActionsCellItem {...props} onClick={() => navigate(href)} />
    </FakeLink>
  );
};
