import { ActionType } from '../../types/action';

const listPermission = {
  resource: 'portfolios',
  action: ActionType.READ,
};

const createPermission = {
  resource: 'portfolio',
  action: ActionType.CREATE,
};
const updatePermission = {
  resource: 'portfolio',
  action: ActionType.UPDATE,
};
const readPermission = {
  resource: 'portfolio',
  action: ActionType.READ,
};
const deletePermission = {
  resource: 'portfolio',
  action: ActionType.DELETE,
};
const statusUpdatePermission = {
  resource: 'portfolio-status',
  action: ActionType.UPDATE,
};

export const Portfolio = {
  list: listPermission,
  read: readPermission,
  create: createPermission,
  update: updatePermission,
  delete: deletePermission,
  statusUpdate: statusUpdatePermission,
};
