import React, { useRef, useEffect, useImperativeHandle, useState } from 'react';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridRowSelectionModel,
  GridSortModel,
  GridColDef,
  GridRowGroupingModel,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
  LicenseInfo,
  GridToolbarExportProps,
  GridAggregationModel,
  GridApi,
  GridPaginationModel,
  GridFilterItem,
} from '@mui/x-data-grid-premium';
import { useAppDispatch } from '../../redux/hooks';
import { CustomFooter } from './components/CustomFooter';
import { CustomToolbar } from './components/CustomToolbar';
import { mapColumTypeToFilterOperators } from './filterOperators';
import { mapColumTypeToFormatter } from './valueFormatter';
import { mapColumTypeToGetter } from './valueGetter';

LicenseInfo.setLicenseKey('xxxxx');

export interface TableRef {
  reload: () => void;
  setFilterModel: (filterModel: GridFilterModel) => void;
  getApi: () => GridApi;
}

interface ITableProps
  extends Omit<
    DataGridPremiumProps,
    | 'sortModel'
    | 'filterModel'
    | 'rowSelectionModel'
    | 'rowGroupingModel'
    | 'columnVisibilityModel'
    | 'page'
    | 'pageSize'
    | 'onPageSizeChange'
    | 'onPageChange'
    | 'onSortModelChange'
    | 'onSelectionModelChange'
    | 'onFilterModelChange'
    | 'onColumnVisibilityModelChange'
    | 'onRowGroupingModelChange'
  > {
  initialPage?: number;
  initialPageSize?: number;
  initialFilterModel?: GridFilterModel;
  initialSortModel?: GridSortModel;
  initialRowGroupingModel?: GridRowGroupingModel;
  initialColumnVisibilityModel?: GridColumnVisibilityModel;
  initialAggregationModel?: GridAggregationModel;
  requiredFilters?: GridFilterItem[];
  externalFilter?: GridFilterItem[];
  rowSelectionModel?: GridRowSelectionModel;
  onSelectionModelChange?: (selectionModel: GridRowSelectionModel) => void;
  onAggregationModelChange?: (aggregationModel: GridAggregationModel) => void;
  onFilterModelChange?: (filterModel: GridFilterModel) => void;
  onPageChange?: (newPage: number) => void;
  onPageSizeChange?: (newPageSize: number) => void;
  onSortModelChange?: (sortModel: GridSortModel) => void;
  customToolbarItems?: React.JSX.Element | React.JSX.Element[];
  toolbarExportProps?: Partial<GridToolbarExportProps>;
  timesToSkipFilters?: number;
  // eslint-disable-next-line @typescript-eslint/ban-types
  fetchItems?: Function;
  fetchItemsCustomParams?: object;
  wrapperHeight?: number;
  debug?: boolean;
}

const mergeFilters = (newItems: GridFilterItem[], requiredItems: GridFilterItem[]) => {
  const nextItems = [...requiredItems];

  for (const newItem of newItems) {
    if (!nextItems.find((item) => item.id === newItem.id)) {
      nextItems.push(newItem);
    }
  }

  return nextItems;
};

const defaultPageSizeOptions = [100, 500, 1000];

export const Table = React.forwardRef<TableRef, ITableProps>((allProps: ITableProps, ref) => {
  const { columns: _columns, ...props } = allProps;
  const dispatch = useAppDispatch();
  const apiRef = useGridApiRef();
  const isInitialMount = useRef(false);
  const [skippedFilterModel, setSkippedFilterModel] = useState(props.timesToSkipFilters ?? 1);
  const [skippedSortModel, setSkippedSortModel] = useState(0);
  const [skippedPaginationModel, setSkippedPaginationModel] = useState(0);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [rowGroupingModel, setRowGroupingModel] = React.useState<GridRowGroupingModel>(
    props?.initialRowGroupingModel || [],
  );
  const [models, setModels] = React.useState<{
    paginationModel: GridPaginationModel;
    sortModel: GridSortModel;
    filterModel: GridFilterModel;
    columnVisibilityModel: GridColumnVisibilityModel;
  }>({
    columnVisibilityModel: props?.initialColumnVisibilityModel || {},
    filterModel: props?.initialFilterModel || { items: [...(props.requiredFilters || [])] },
    sortModel: props?.initialSortModel || [],
    paginationModel: {
      page: props?.initialPage || 0,
      pageSize: props?.initialPageSize || defaultPageSizeOptions[0],
    },
  });
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      ...(apiRef.current?.exportState ? apiRef.current?.exportState() : {}),
      sorting: { sortModel: props?.initialSortModel || [] },
      filter: { filterModel: props?.initialFilterModel || { items: [...(props.requiredFilters || [])] } },
      columns: { columnVisibilityModel: props?.initialColumnVisibilityModel || {} },
      rowGrouping: { model: props?.initialRowGroupingModel },
      aggregation: { model: props?.initialAggregationModel },
      pagination: {
        paginationModel: {
          page: props?.initialPage || 0,
          pageSize: props?.initialPageSize || defaultPageSizeOptions[0],
        },
      },
    },
  });
  const pageSizeOptions = [
    ...new Set((allProps.pageSizeOptions || defaultPageSizeOptions).concat(models.paginationModel.pageSize)),
  ];

  const debugLog = (msg: string, logData?: object) => {
    if (props.debug) {
      console.debug(`[Table ${msg}]`, logData);
    }
  };

  const onPaginationModelChange = React.useCallback(
    (newPaginationModel: GridPaginationModel) => {
      debugLog('onPaginationModelChange', newPaginationModel);

      if (skippedPaginationModel > 0 && props.initialSortModel) {
        setSkippedPaginationModel((prev) => prev - 1);
        return;
      }

      // if (props.onPaginationModelChange) {
      //   props.onPaginationModelChange(newPaginationModel);
      // }

      setModels((prev) => ({
        ...prev,
        paginationModel: !newPaginationModel
          ? {
              page: props?.initialPage || 0,
              pageSize: props?.initialPageSize || 10,
            }
          : newPaginationModel,
      }));
    },
    [skippedPaginationModel],
  );

  const onSortModelChange = React.useCallback(
    (newSortModel: GridSortModel) => {
      debugLog('onSortModelChange', newSortModel);

      if (skippedSortModel > 0 && props.initialSortModel) {
        setSkippedSortModel((prev) => prev - 1);
        return;
      }

      if (props.onSortModelChange) {
        props.onSortModelChange(newSortModel);
      }

      setModels((prev) => ({
        ...prev,
        sortModel: !newSortModel?.length ? props?.initialSortModel || [] : newSortModel,
      }));
    },
    [skippedSortModel],
  );

  const onFilterModelChange = React.useCallback(
    (newFilterModel: GridFilterModel) => {
      debugLog('onFilterModelChange', newFilterModel);

      if (!skippedFilterModel && props.initialFilterModel) {
        setSkippedFilterModel((prev) => prev - 1);
        return;
      }

      const nextFilterModel = {
        ...newFilterModel,
        items: mergeFilters(newFilterModel.items, props.requiredFilters || []),
      };

      setModels((prev) => ({
        ...prev,
        filterModel: nextFilterModel,
      }));

      if (props.onFilterModelChange) {
        props.onFilterModelChange(nextFilterModel);
      }
    },
    [skippedFilterModel],
  );

  const onRowSelectionModelChange = React.useCallback((newSelectionModel: GridRowSelectionModel) => {
    debugLog('onRowSelectionModelChange', newSelectionModel);

    if (props.onSelectionModelChange) {
      props.onSelectionModelChange(newSelectionModel);
    }
  }, []);

  const onAggregationModelChange = React.useCallback((newAggregationModel: GridAggregationModel) => {
    debugLog('onAggregationModelChange', newAggregationModel);

    if (props.onAggregationModelChange) {
      props.onAggregationModelChange(newAggregationModel);
    }
  }, []);

  const onColumnVisibilityModelChange = React.useCallback((newColumnVisibilityModel: GridColumnVisibilityModel) => {
    debugLog('onColumnVisibilityModelChange', newColumnVisibilityModel);

    setModels((prev) => ({
      ...prev,
      columnVisibilityModel: newColumnVisibilityModel,
    }));
  }, []);

  const onRowGroupingModelChange = React.useCallback((newRowGroupingModel: GridRowGroupingModel) => {
    debugLog('onRowGroupingModelChange', newRowGroupingModel);

    setRowGroupingModel(newRowGroupingModel);
  }, []);

  const fetchItems = () => {
    const customProps = props?.fetchItemsCustomParams || {};

    if (!props.fetchItems) {
      return;
    }

    const normalizedFilterModel = {
      ...models.filterModel,
      items: models.filterModel.items.map(({ value, operator, field }) => ({
        value,
        columnField: field,
        operatorValue: operator,
      })),
    };

    dispatch(
      props.fetchItems({
        ...customProps,
        limit: models.paginationModel.pageSize,
        offset: models.paginationModel.page * models.paginationModel.pageSize,
        sortField: models.sortModel?.[0]?.field,
        sortOrder: models.sortModel?.[0]?.sort,
        filter: JSON.stringify(normalizedFilterModel),
      }),
    );
  };

  useEffect(() => {
    if (props.externalFilter && props.fetchItems) {
      const newFilterModel = { items: props.externalFilter };
      onFilterModelChange({ ...newFilterModel });
    }
  }, [JSON.stringify(props.externalFilter)]);

  useEffect(() => {
    debugLog('useEffect:models', models);

    fetchItems();
  }, [models.paginationModel, models.sortModel, models.filterModel, models.columnVisibilityModel]);

  useEffect(() => {
    debugLog('useEffect:_columns', _columns);

    // eslint-disable-next-line react/prop-types
    const newColumns = _columns.map((c: GridColDef) => {
      const colDef: GridColDef = { ...c };

      const valueFormatter = (c.type && mapColumTypeToFormatter[c.type]) || c.valueFormatter;
      const valueGetter = c.valueGetter ?? (c.type ? mapColumTypeToGetter[c.type] : undefined);
      const filterOperators =
        c.filterOperators ?? (c.type ? mapColumTypeToFilterOperators[c.type] : mapColumTypeToFilterOperators.string);

      if (valueFormatter) {
        colDef.valueFormatter = valueFormatter;
      }

      if (valueGetter) {
        colDef.valueGetter = valueGetter;
      }

      if (filterOperators) {
        colDef.filterOperators = filterOperators;
      }

      return colDef;
    });

    setColumns(newColumns);
    // eslint-disable-next-line react/prop-types
  }, [_columns]);

  useImperativeHandle(ref, () => ({
    reload: () => {
      debugLog('useImperativeHandle:reload');
      fetchItems();
    },
    setFilterModel: (filterModel: GridFilterModel) => {
      debugLog('setFilterModel:filterModel');
      onFilterModelChange(filterModel);
    },
    getApi: () => {
      debugLog('setFilterModel:getApi');
      return apiRef.current;
    },
  }));

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      Array.from(document.querySelectorAll('.MuiDataGrid-main > div'))
        .filter((el) =>
          ['MUI X: Missing license key', 'MUI X: Invalid license key', 'MUI X Invalid license key'].includes(
            el.textContent || '',
          ),
        )
        .map((e) => {
          e.textContent = '';

          return e;
        });
    }
  });

  return (
    <div style={{ height: allProps.wrapperHeight || 700, width: '100%' }}>
      <DataGridPremium
        apiRef={apiRef}
        disableDensitySelector
        disableRowGrouping
        disableRowSelectionOnClick
        disableColumnPinning
        pageSizeOptions={pageSizeOptions}
        slots={{
          toolbar: () =>
            CustomToolbar({ children: props.customToolbarItems || [], exportProps: props.toolbarExportProps }),
          // eslint-disable-next-line react/no-unstable-nested-components
          footer: (footerProps) => CustomFooter(footerProps),
        }}
        slotProps={{
          footer: {
            apiRef: apiRef.current,
          },
        }}
        paginationMode="server"
        sortingMode="server"
        filterMode="server"
        rowGroupingColumnMode="single"
        // TODO: Breaks filters
        groupingColDef={
          props.groupingColDef ||
          (rowGroupingModel.length ? { filterable: false, sortable: false, hideable: false } : undefined)
        }
        pagination
        {...props}
        columns={columns}
        onPaginationModelChange={onPaginationModelChange}
        paginationModel={models.paginationModel}
        sortModel={models.sortModel}
        onSortModelChange={onSortModelChange}
        filterModel={models.filterModel}
        rowSelectionModel={props.rowSelectionModel}
        onRowSelectionModelChange={onRowSelectionModelChange}
        onAggregationModelChange={onAggregationModelChange}
        columnVisibilityModel={models.columnVisibilityModel}
        onFilterModelChange={onFilterModelChange}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        rowGroupingModel={rowGroupingModel}
        onRowGroupingModelChange={onRowGroupingModelChange}
        initialState={initialState}
      />
    </div>
  );
});
