import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { appConfigService } from '../../../api';
import { CreateAppConfigRequest, UpdateAppConfigRequest } from '../../../types/appConfig';

export const fetchAppConfigs = createAsyncThunk('appConfig/fetch-all', () => appConfigService.fetchAppConfigs());

export const fetchAppConfig = createAsyncThunk('appConfig/get', (key: string) => appConfigService.fetchAppConfig(key));

export const deleteAppConfig = createAsyncThunk('appConfig/delete', (key: string) =>
  appConfigService.deleteAppConfig(key),
);

export const createAppConfigItem = createAsyncThunk('appConfig/create', (appConfig: CreateAppConfigRequest) =>
  appConfigService.createAppConfig(appConfig),
);

export const updateAppConfig = createAsyncThunk('appConfig/update', (appConfig: UpdateAppConfigRequest) =>
  appConfigService.updateAppConfig(appConfig),
);

export const updateAppConfigs = createAsyncThunk('appConfig/update-all', (appConfigs: UpdateAppConfigRequest[]) =>
  appConfigService.updateAppConfigs(appConfigs),
);

export const resetEditAppConfig = createAction('appConfig/reset-edit');

export const cleanAppConfigs = createAction('appConfig/clean');
