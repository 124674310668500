import { createSelector } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { ReducerState } from '../../store.types';
import { UserPortfolioRequestState } from './userPortfolioRequest.types';

export const userPortfolioRequestSelector = ({ userPortfolioRequest }: ReducerState) => userPortfolioRequest;
export const userPortfolioRequestLoadingSelector = createSelector(
  userPortfolioRequestSelector,
  ({ fetchStatus }) => fetchStatus === fetchStatuses.pending,
);

export const userPortfolioRequestByRequestIdSelector = createSelector(
  userPortfolioRequestSelector,
  (state: ReducerState, requestId: string) => requestId,
  (userPortfolioRequest: UserPortfolioRequestState, requestId: string) => ({
    userPortfolioRequests: userPortfolioRequest.userPortfolioRequests[requestId] || [],
    totalUserPortfolioRequests: userPortfolioRequest.totalUserPortfolioRequests[requestId] || 0,
    fetchStatus: userPortfolioRequest.fetchStatuses[requestId],
  }),
);
