import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { aggregatedStatisticService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { RestoreAggregatedStatisticsRequest } from '../../../types/aggregated-statistic';

export const fetchAggregatedStatistics = createAsyncThunk('aggregatedStatistic/fetch-all', (data: PaginatedRequest) =>
  aggregatedStatisticService.fetchAggregatedStatistics(data),
);

export const restoreAggragatedStatistics = createAsyncThunk(
  'aggregatedStatistics/restore',
  (data: RestoreAggregatedStatisticsRequest) => aggregatedStatisticService.restoreAggragatedStatistics(data),
);

export const cleanAggregatedStatistics = createAction('aggregatedStatistic/clean');
