import { ApiResponse } from './api';
import { UserFee } from './userFee';

export enum UserFeeDeductionStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  APPROVED = 'APPROVED',
  WAITING_CAST_APPROVAL = 'WAITING_CAST_APPROVAL',
  APPROVED_BY_CAST = 'APPROVED_BY_CAST',
  CREATED = 'CREATED',
  REJECTED = 'REJECTED',
  FAILED = 'FAILED',
  PARTIAL_APPROVED_BY_CAST = 'PARTIAL_APPROVED_BY_CAST',
  PARTIAL_APPROVED = 'PARTIAL_APPROVED',
  CANCELED = 'CANCELED',
}

export enum UserFeeDeductionTypes {
  WALLET = 'WALLET',
  CACHE_ALLOCATION = 'CACHE_ALLOCATION',
}

export interface UserFeeDeductionTypeOption {
  id: number;
  name: UserFeeDeductionTypes;
}

export interface UserFeeDeductionStatusOption {
  id: number;
  name: UserFeeDeductionStatus;
}

export type UserFeeDeduction = {
  id: number;
  totalFee: number;
  managementFee: number;
  vat: number;
  userId: number;
  reviewedAt: Date | string | null;
  reviewedBy: number | null;
  reviewComment: string | null;
  status: UserFeeDeductionStatusOption;
  type: UserFeeDeductionTypeOption;
  userFee: UserFee;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchUserFeeDeductionResponse = ApiResponse<UserFeeDeduction>;
export type FetchUserFeeDeductionsResponse = ApiResponse<{
  items: UserFeeDeduction[];
  totalItems: number;
}>;
export type FetchUserFeeDeductionStatusOptionsResponse = ApiResponse<UserFeeDeductionStatusOption[]>;

export type UpdateUserFeeDeductionStatusRequest = Pick<UserFeeDeduction, 'id'> & {
  status: UserFeeDeductionStatus;
};
export type UpdateUserFeeDeductionStatusResponse = ApiResponse<{ message: string }>;

export type SaveUserFeeDeductionRequest = Omit<
  UserFeeDeduction,
  'id' | 'status' | 'type' | 'userFee' | 'createdAt' | 'updatedAt'
> & {
  status: number;
  type: number;
};
export type CreateUserFeeDeductionResponse = ApiResponse<UserFeeDeduction>;

export type UpdateUserFeeDeductionRequest = Omit<
  UserFeeDeduction,
  'status' | 'type' | 'userFee' | 'createdAt' | 'updatedAt'
> & {
  status: number;
  type: number;
};
export type UpdateUserFeeDeductionResponse = ApiResponse<UserFeeDeduction>;
