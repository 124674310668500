import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { FakeButtonLink } from '../../components/FakeLink';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { fetchPortfolioOrderWeightsByOrderId } from '../../redux/modules/portfolioOrder/portfolioOrder.actions';
import {
  portfolioOrderSelector,
  viewDetailsByOrderIdSelector,
} from '../../redux/modules/portfolioOrder/portfolioOrder.selectors';
import { locations } from '../../routes/locations';
import { round2Format, round4Format } from '../../utils/number';

interface FundOrdersTabProps {
  orderId: string;
}

export function PortfolioOrdersWeightsTab({ orderId }: FundOrdersTabProps) {
  const tableRef = useRef<TableRef>(null);
  const { fetchPortfolioOrderWeightsStatus, portfolioOrderWeights, totalPortfolioOrderWeights } =
    useSelector(viewDetailsByOrderIdSelector);
  const { executeStatus, importExecutionFileStatus } = useSelector(portfolioOrderSelector);
  const loading =
    fetchPortfolioOrderWeightsStatus === fetchStatuses.pending ||
    importExecutionFileStatus === fetchStatuses.pending ||
    executeStatus === fetchStatuses.pending;

  const getColumns = (): GridColDef[] => [
    {
      field: 'indexFund.nameEn',
      headerName: 'Fund',
      type: 'string',
      flex: 1,
      aggregable: false,
      sortable: false,
      disableColumnMenu: true,
      groupingValueGetter: (params) => `(${params.row?.indexFund?.id}) ${params.row?.indexFund?.nameEn}`,
      valueGetter: (params) => {
        if (!params.row?.indexFund?.id) {
          return '';
        }
        return `(${params.row?.indexFund?.id}) ${params.row?.indexFund?.nameEn}`;
      },
    },
    {
      field: 'userId',
      headerName: 'User',
      type: 'number',
      width: 120,
      sortable: false,
      aggregable: false,
      disableColumnMenu: true,
      groupingValueGetter: (params) => params.row?.userId,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return (
          <FakeButtonLink href={locations.user(params.value)} startIcon={<OpenInNewIcon />}>
            {params.value}
          </FakeButtonLink>
        );
      },
    },
    {
      field: 'requestId',
      headerName: 'Request',
      type: 'number',
      width: 120,
      sortable: false,
      aggregable: false,
      disableColumnMenu: true,
      valueGetter: (params: GridRenderCellParams) => params.row?.portfolioOrder?.userPortfolioRequestId,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return;
        }

        return (
          <FakeButtonLink
            href={locations.userPortfolioRequest(params.row?.portfolioOrder?.userPortfolioRequest.userPortfolioId)}
            startIcon={<OpenInNewIcon />}
          >
            {params.value}
          </FakeButtonLink>
        );
      },
    },
    {
      field: 'weight',
      headerName: 'Weight',
      type: 'number',
      width: 80,
      groupable: false,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return `${round2Format(value)}%`;
      },
    },
    {
      field: 'actualWeight',
      headerName: 'Actual Weight',
      type: 'number',
      width: 120,
      groupable: false,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return `${round2Format(value)}%`;
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      width: 100,
      groupable: false,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return round2Format(value);
      },
    },
    {
      field: 'actualAmount',
      headerName: 'Actual Amount',
      type: 'number',
      width: 130,
      groupable: false,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return round2Format(value || 0);
      },
    },
    {
      field: 'units',
      headerName: 'Units',
      type: 'number',
      width: 100,
      groupable: false,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return round4Format(value);
      },
    },
    {
      field: 'actualUnits',
      headerName: 'Actual Units',
      type: 'number',
      width: 100,
      groupable: false,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return round4Format(value || 0);
      },
    },
    {
      field: 'satelliteAmount',
      headerName: 'Satellite Amount',
      type: 'number',
      width: 130,
      groupable: false,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return round2Format(value || 0);
      },
    },
    {
      field: 'satelliteUnits',
      headerName: 'Satellite Units',
      type: 'number',
      width: 130,
      groupable: false,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return round4Format(value);
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      width: 100,
      aggregable: false,
      groupable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 100,
      aggregable: false,
      groupable: false,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [columns, setColumns] = useState<GridColDef[]>(getColumns());

  return (
    <Table
      ref={tableRef}
      fetchItems={fetchPortfolioOrderWeightsByOrderId}
      rows={portfolioOrderWeights}
      columns={columns}
      loading={loading}
      rowCount={totalPortfolioOrderWeights}
      pageSizeOptions={[1000]}
      initialPageSize={1000}
      fetchItemsCustomParams={{ orderId }}
      disableRowGrouping={false}
      rowGroupingColumnMode="single"
      initialRowGroupingModel={['indexFund.nameEn', 'userId']}
      defaultGroupingExpansionDepth={1}
      sortingMode="client"
      initialColumnVisibilityModel={{ 'indexFund.nameEn': false, userId: false }}
      initialAggregationModel={{
        weight: 'sum',
        actualWeight: 'sum',
        amount: 'sum',
        actualAmount: 'sum',
        units: 'sum',
        actualUnits: 'sum',
        satelliteAmount: 'sum',
        satelliteUnits: 'sum',
      }}
      toolbarExportProps={{
        csvOptions: { fileName: `portfolio-order-weights-${orderId}` },
        excelOptions: { fileName: `fund-orders-weights-${orderId}` },
      }}
    />
  );
}
