import { createSelector } from '@reduxjs/toolkit';
import { FetchRolesResponse } from '../../../types/role';
import { RootState } from '../../store';

export const rolesSelector = (state: RootState) =>
  (Object.values(state.api.queries).find((api) => api?.endpointName === 'fetchRoles')?.data as FetchRolesResponse)
    ?.items || [];

export const nonSuperAdminRoleSelector = createSelector(rolesSelector, (roles) =>
  roles.filter((r) => r.name !== 'SUPER_ADMIN'),
);
