import { ApiResponse } from './api';

export enum ConfigKey {
  CACHE_ALLOCATION_PERCENTAGE = 'cache_allocation_percentage',
  WEB_APP_VERSION = 'web_app_version',
  UPLOAD_FLOW_ENABLED = 'upload_flow_enabled',
}

export type AppConfig = {
  key: ConfigKey;
  value: string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchAppConfigResponse = ApiResponse<AppConfig>;
export type FetchAppConfigsResponse = ApiResponse<AppConfig[]>;

export type CreateAppConfigRequest = Omit<AppConfig, 'id' | 'createdAt' | 'updatedAt'>;
export type CreateAppConfigResponse = ApiResponse<AppConfig>;

export type UpdateAppConfigRequest = Omit<AppConfig, 'key' | 'createdAt' | 'updatedAt'> & { key: string };
export type UpdateAppConfigsRequest = {
  items: UpdateAppConfigRequest[];
};
export type UpdateAppConfigResponse = ApiResponse<AppConfig>;
