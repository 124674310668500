import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { useSnackbar } from 'notistack';
import SwitchAccessShortcutAddIcon from '@mui/icons-material/SwitchAccessShortcutAdd';
import Button from '@mui/material/Button';
import {
  GridColDef,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import { GridCellExpand, Table } from '../../components/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { fetchCashReport } from '../../redux/modules/report/report.actions';
import { reportSelector } from '../../redux/modules/report/report.selectors';
import { prepareCashReportData } from '../../utils/report';
import { round2Format } from '../../utils/number';

interface CustomToolbarProps {
  onExportReport: () => void;
}

const csvDivider = ',';

function CustomToolbar({ onExportReport }: CustomToolbarProps) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <Button id="ExportCashReport" startIcon={<SwitchAccessShortcutAddIcon />} onClick={onExportReport}>
        Export Report
      </Button>
    </GridToolbarContainer>
  );
}

export function CashTable() {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id' },
    {
      headerName: 'Date',
      field: 'date',
      type: 'string',
      width: 100,
    },
    {
      headerName: 'Custodian Number',
      field: 'custodianNumber',
      type: 'string',
      width: 100,
    },
    {
      headerName: 'User Id',
      field: 'userId',
    },
    {
      headerName: 'VIBAN',
      field: 'viban',
      type: 'string',
      width: 100,
    },
    {
      headerName: 'Viban Incoming',
      field: 'vibanIncoming',
      type: 'number',
    },
    {
      headerName: 'Invested Amount',
      field: 'investedAmount',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      headerName: 'Invested Amount',
      field: 'investedAmountAlpaca',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      headerName: 'Invested Amount',
      field: 'investedAmountJadwa',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      headerName: 'Current Market Value',
      field: 'currentMarketValue',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      headerName: 'Total Cash Available',
      field: 'totalCashAvailable',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      headerName: 'Cash On Hold',
      field: 'cashOnHold',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      headerName: 'Cash Allocation Value',
      field: 'cashAllocationValue',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      headerName: 'Fee Amount',
      field: 'feeAmount',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      headerName: 'Anb Eof Cash Balance',
      field: 'anbEofCashBalance',
      type: 'string',
      width: 100,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      headerName: 'Net Withdrawals',
      field: 'netWithdrawals',
      type: 'number',
      width: 100,
      valueFormatter: (params) => round2Format(params.value),
    },
    {
      field: 'assets',
      headerName: 'Assets',
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <GridCellExpand value={JSON.stringify(params.value, null, 2) || ''} width={params.colDef.computedWidth} />
      ),
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: false,
      type: 'date',
      width: 100,
    },
    // {
    //   field: 'updatedAt',
    //   headerName: 'Updated',
    //   type: 'date',
    //   width: 100,
    // },
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const [reportData, setReportData] = useState('');
  const csvCashReportLink = useRef<CSVLink>();

  const { cashItems, totalCashItems, fetchCashReportItemsStatus } = useSelector(reportSelector);

  const onExportReport = () => {
    try {
      const data = prepareCashReportData(cashItems, csvDivider);

      setReportData(data);

      setTimeout(() => {
        if (csvCashReportLink.current) {
          csvCashReportLink.current.link.click();
        }
      }, 0);
    } catch (error: any) {
      enqueueSnackbar(`Export Cash Report failed: ${error?.message}`, { variant: 'error' });
    }
  };

  return (
    <>
      <Table
        wrapperHeight={700}
        fetchItems={fetchCashReport}
        rows={cashItems}
        columns={columns.current}
        loading={fetchCashReportItemsStatus === fetchStatuses.pending}
        rowCount={totalCashItems}
        slots={{
          toolbar: () => CustomToolbar({ onExportReport }),
        }}
        density="compact"
        initialColumnVisibilityModel={{ id: false, custodianNumber: true, viban: true }}
        initialSortModel={[{ field: 'id', sort: 'desc' }]}
      />

      <CSVLink
        data={reportData}
        filename={`cash-report-${new Date().toLocaleString()}.csv`}
        className="hidden"
        separator={csvDivider}
        ref={csvCashReportLink}
        target="_blank"
      />
    </>
  );
}
