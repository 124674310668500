import Typography from '@mui/material/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { appConfigSelector } from '../../redux/modules/appConfig/appConfig.selectors';
import { FeeBatchTable } from './FeeBatchTable';

export function FeeBatchList() {
  const { fetchStatus, updateStatus } = useSelector(appConfigSelector);

  const isLoading = fetchStatus === fetchStatuses.pending || updateStatus === fetchStatuses.pending;

  try {
    return (
      <Container component="main" maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">
            Fee Batches &nbsp;
            {/* <ProtectedContent items={[permissions.UserPortfolioRequest.create]}> */}
            {/*   <Fab color="primary" size="small" aria-label="Create User IndexFund Request" onClick={onAddNew}> */}
            {/*     <AddIcon /> */}
            {/*   </Fab> */}
            {/* </ProtectedContent> */}
          </Typography>
        </Box>
        <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
          <FeeBatchTable />
        </Box>
      </Container>
    );
  } catch (e: any) {
    return (
      <Container component="main" maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Fee Batches</Typography>
        </Box>
        <Typography>Unexpected error happens: ${e.message}</Typography>
      </Container>
    );
  }
}
