import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { initSentry } from '../../helpers/sentry';
import { Error } from '../../pages/Error';
import { cacheLtr, cacheRtl, ltrTheme, rtlTheme } from '../../helpers/theme';
import { AppRoutes } from '../../routes/AppRoutes';
import { ScrollToTop } from '../ScrollToTop';
import './reset.scss';

initSentry();

export function AppView() {
  const { i18n } = useTranslation();
  const isArabic = i18n.language === 'ar';

  return (
    <CacheProvider value={isArabic ? cacheRtl : cacheLtr}>
      <ThemeProvider theme={isArabic ? rtlTheme : ltrTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <Sentry.ErrorBoundary fallback={<Error />}>
              <ScrollToTop />
              <SnackbarProvider maxSnack={10}>
                <AppRoutes />
              </SnackbarProvider>
            </Sentry.ErrorBoundary>
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}
