import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { userService, kycService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { UpdateKycRequest } from '../../../types/kyc';
import {
  CreateUserAttachmentsRequest,
  CreateUserRequest,
  WithdrawUserRequest,
  UpdateUserRequest,
  UpdateUserStatusRequest,
} from '../../../types/user';

export const fetchUsers = createAsyncThunk('user/fetch-all', (data: PaginatedRequest) => userService.fetchUsers(data));

export const fetchUser = createAsyncThunk('user/get', (id: number) => userService.fetchUser(id));

export const fetchUserWorldCheckScreen = createAsyncThunk('user/get-world-check-screen', (id: number) =>
  userService.fetchUserWorldCheckScreen(id),
);

export const fetchUserSignAgreements = createAsyncThunk('user/fetch-sign-agreements', (data: PaginatedRequest) =>
  userService.fetchUserSignAgreements(data),
);

export const fetchUserHistory = createAsyncThunk('user/get-history', (data: PaginatedRequest & { userId: number }) =>
  userService.fetchUserHistory(data),
);

export const fetchUserTransactions = createAsyncThunk(
  'user/fetch-user-transactions',
  (data: PaginatedRequest & { userId: number }) => userService.fetchUserTransactions(data),
);

export const fetchUserBankInfo = createAsyncThunk('user/get-bank-info', (userId: number) =>
  userService.fetchUserBankInfo(userId),
);

export const fetchUserSurveyResults = createAsyncThunk(
  'user/get-survey-results',
  (data: PaginatedRequest & { userId: number }) => userService.fetchUserSurveyResults(data),
);

export const fetchUserAttachments = createAsyncThunk('user/get-attachments', (userId: number) =>
  userService.fetchUserAttachments(userId),
);

export const fetchUserStatusOptions = createAsyncThunk('user/fetch-user-status-options', () =>
  userService.fetchUserStatusOptions(),
);

export const fetchUserAttachmentsDocumentTypeOptions = createAsyncThunk(
  'user/fetch-user-attachments-document-type-options',
  () => userService.fetchUserAttachmentsDocumentTypeOptions(),
);

export const createUserAttachments = createAsyncThunk(
  'user/create-attachments',
  (req: CreateUserAttachmentsRequest & { userId: number }) => userService.createUserAttachments(req),
);

export const createUser = createAsyncThunk('user/create', (user: CreateUserRequest) => userService.createUser(user));

export const updateUser = createAsyncThunk('user/update', (user: UpdateUserRequest) => userService.updateUser(user));

export const updateUserStatus = createAsyncThunk('user/update-status', (data: UpdateUserStatusRequest) =>
  userService.updateUserStatus(data),
);

export const fetchKycByUserId = createAsyncThunk('user/get-kyc-by-user-id', (id: number) =>
  kycService.fetchKycByUserId(id),
);

export const fetchKycTypeOptions = createAsyncThunk('user/fetch-kyc-type-options', () =>
  kycService.fetchKycTypeOptions(),
);

export const fetchKycStatusOptions = createAsyncThunk('user/fetch-kyc-status-options', () =>
  kycService.fetchKycStatusOptions(),
);

export const fetchKycGenderOptions = createAsyncThunk('user/fetch-kyc-gender-options', () =>
  kycService.fetchKycGenderOptions(),
);

export const updateKyc = createAsyncThunk('user/update-kyc', (req: UpdateKycRequest) => kycService.updateKyc(req));

export const withdraw = createAsyncThunk('user/withdraw', (req: WithdrawUserRequest) => userService.withdraw(req));

export const fetchUserSubscriptions = createAsyncThunk(
  'user/fetch-user-subscriptions',
  (data: PaginatedRequest & { userId: number }) => userService.fetchUserSubscriptions(data),
);

export const cleanUsers = createAction('user/clean');

export const resetEditUser = createAction('user/reset-edit');

export const resetUserApiStatuses = createAction('user/reset-api-statuses');
