import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchUserGroupResponse, FetchUserGroupsResponse } from '../../../types/userGroup';
import {
  fetchUserGroup,
  fetchUserGroups,
  resetEditUserGroup,
  cleanUserGroups,
  updateUserGroup,
  createUserGroup,
  deleteUserGroup,
  setDefaultUserGroup,
} from './userGroup.actions';
import { UserGroupState } from './userGroup.types';

const initialState: UserGroupState = {
  userGroups: [],
  totalUserGroups: 0,
  editUserGroup: null,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  updateDefaultStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const userGroupsSlice = createSlice({
  name: 'userGroups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserGroups.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchUserGroups.fulfilled, (state, action: PayloadAction<FetchUserGroupsResponse>) => {
      state.userGroups = action.payload.data?.items;
      state.totalUserGroups = action.payload.data?.totalItems;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserGroups.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchUserGroup.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(fetchUserGroup.fulfilled, (state, action: PayloadAction<FetchUserGroupResponse>) => {
      state.editUserGroup = action.payload.data;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchUserGroup.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateUserGroup.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateUserGroup.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateUserGroup.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createUserGroup.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(createUserGroup.fulfilled, (state, action: PayloadAction<FetchUserGroupResponse>) => {
      state.editUserGroup = action.payload.data;
      state.createStatus = fetchStatuses.success;
    });
    builder.addCase(createUserGroup.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteUserGroup.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteUserGroup.fulfilled, (state) => {
      state.editUserGroup = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteUserGroup.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(setDefaultUserGroup.pending, (state) => {
      state.error = null;
      state.updateDefaultStatus = fetchStatuses.pending;
    });
    builder.addCase(setDefaultUserGroup.fulfilled, (state) => {
      state.updateDefaultStatus = fetchStatuses.success;
    });
    builder.addCase(setDefaultUserGroup.rejected, (state, action) => {
      state.updateDefaultStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditUserGroup.type, (state) => {
      state.editUserGroup = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanUserGroups.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const userGroupReducer = userGroupsSlice.reducer;
