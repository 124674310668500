import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { CopyToClipboardButton } from '../../../../components/CopyToClipboardButton';
import { userCustodianDataSelector } from '../../../../redux/modules/userCustodianData/userCustodianData.selectors';
import { UserBankInfo } from '../../../../types/user';
import { round2Format } from '../../../../utils/number';

interface BankDetailsTabListProps {
  userBankInfo: UserBankInfo;
}

export function BankDetailsTab({ userBankInfo }: BankDetailsTabListProps) {
  const { data: custodianData } = useSelector(userCustodianDataSelector);

  return (
    <Grid item container display="flex" flexDirection="row" sx={{ mt: '24px' }} spacing={2}>
      <Grid item container xs={8}>
        <Grid item xs={6}>
          <Typography variant="h6">Current Balance</Typography>
        </Grid>
        <Grid item xs={6}>
          <CopyToClipboardButton
            value={(userBankInfo?.userBankDetails?.amount || 0).toString()}
            text={round2Format(userBankInfo?.userBankDetails?.amount || 0)}
          />
        </Grid>
      </Grid>
      <Grid item container xs={8}>
        <Grid item xs={6}>
          <Typography variant="h6">Hold amount</Typography>
        </Grid>
        <Grid item xs={6}>
          <CopyToClipboardButton
            value={(userBankInfo?.userBankDetails?.hold || 0).toString()}
            text={round2Format(userBankInfo?.userBankDetails?.hold || 0)}
          />
        </Grid>
      </Grid>
      <Grid item container xs={8}>
        <Grid item xs={6}>
          <Typography variant="h6">Cash Allocation Value</Typography>
        </Grid>
        <Grid item xs={6}>
          <CopyToClipboardButton
            value={(userBankInfo?.userBankDetails?.cashAllocationValue || 0).toString()}
            text={round2Format(userBankInfo?.userBankDetails?.cashAllocationValue || 0)}
          />
        </Grid>
      </Grid>
      <Grid item container xs={8}>
        <Grid item xs={6}>
          <Typography variant="h6">Unsettled Fee</Typography>
        </Grid>
        <Grid item xs={6}>
          <CopyToClipboardButton
            value={(userBankInfo?.userBankDetails?.unsettledFee || 0).toString()}
            text={round2Format(userBankInfo?.userBankDetails?.unsettledFee || 0)}
          />
        </Grid>
      </Grid>
      <Grid item container xs={8}>
        <Grid item xs={6}>
          <Typography variant="h6">VIBAN</Typography>
        </Grid>
        <Grid item xs={6}>
          <CopyToClipboardButton value={userBankInfo?.iban || '-'} />
        </Grid>
      </Grid>
      <Grid item container xs={8}>
        <Grid item xs={6}>
          <Typography variant="h6">Custodian Reference Number</Typography>
        </Grid>
        <Grid item xs={6}>
          <CopyToClipboardButton value={(custodianData?.referenceNumber || '-').toString()} />
        </Grid>
      </Grid>
    </Grid>
  );
}
