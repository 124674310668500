import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { GridCellExpand, Table } from '../../../../components/Table';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { fetchAlpacaEvents } from '../../../../redux/modules/alpaca/alpaca.actions';
import { alpacaSelector } from '../../../../redux/modules/alpaca/alpaca.selectors';

interface AlpacaEventsTableProps {
  accountId: string;
}

export function AlpacaEventsTable({ accountId }: AlpacaEventsTableProps) {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id' },
    {
      field: 'resource',
      headerName: 'Resource',
      type: 'string',
      width: 200,
    },
    {
      field: 'payload',
      headerName: 'Payload',
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <GridCellExpand value={JSON.stringify(params.value, null, 2) || ''} width={params.colDef.computedWidth} />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      width: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const { events, totalEvents, fetchStatus } = useSelector(alpacaSelector);

  return (
    <Table
      fetchItems={fetchAlpacaEvents}
      fetchItemsCustomParams={{ payloadFilter: JSON.stringify({ account_id: accountId }) }}
      rows={events}
      columns={columns.current}
      loading={fetchStatus === fetchStatuses.pending}
      rowCount={totalEvents}
      disableColumnFilter
      disableColumnMenu
      slots={{}}
      // initialColumnVisibilityModel={{ id: false }}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      // initialAggregationModel={{ estimatedAmount: 'sum' }}
      wrapperHeight={300}
    />
  );
}
