import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { paymentService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';

export const fetchAnbPaymentDetails = createAsyncThunk('payment/fetch-anb-payment-details', (paymentId: string) =>
  paymentService.fetchAnbPaymentDetails(paymentId),
);
export const fetchStatements = createAsyncThunk('payment/fetch-payment-statements', (data: PaginatedRequest) =>
  paymentService.fetchStatements(data),
);
export const fetchApiLogs = createAsyncThunk('payment/fetch-payment-api-logs', (data: PaginatedRequest) =>
  paymentService.fetchApiLogs(data),
);

export const fetchAnbBalance = createAsyncThunk('payment/anb-balance', () => paymentService.fetchAnbBalance());

export const cleanPayments = createAction('payment/clean');
