import {
  CreateJadwaConfigRequest,
  CreateJadwaConfigResponse,
  FetchJadwaConfigsResponse,
  FetchJadwaConfigResponse,
  UpdateJadwaConfigRequest,
  UpdateJadwaConfigResponse,
  UpdateJadwaConfigsRequest,
} from '../types/jadwaConfig';
import { ApiService } from './common/ApiService';

class JadwaConfigService extends ApiService {
  pathname = 'admin/jadwa-config';

  fetchJadwaConfigs = async () => this.get<FetchJadwaConfigsResponse>(`${this.pathname}`);

  fetchJadwaConfig = async (key: string) => this.get<FetchJadwaConfigResponse>(`${this.pathname}/${key}`);

  deleteJadwaConfig = async (key: string) => this.delete<FetchJadwaConfigResponse>(`${this.pathname}/${key}`);

  createJadwaConfig = async (action: CreateJadwaConfigRequest) =>
    this.post<CreateJadwaConfigResponse, CreateJadwaConfigRequest>(`${this.pathname}`, action);

  updateJadwaConfig = async ({ key, ...action }: UpdateJadwaConfigRequest) =>
    this.put<UpdateJadwaConfigResponse, Omit<UpdateJadwaConfigRequest, 'key'>>(`${this.pathname}/${key}`, action);

  updateJadwaConfigs = async (data: UpdateJadwaConfigRequest[]) =>
    this.put<FetchJadwaConfigsResponse, UpdateJadwaConfigsRequest>(`${this.pathname}`, { items: data });
}

export const jadwaConfigService = new JadwaConfigService();
