import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Table } from '../../../components/Table';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { fetchFxRevenueJadwaOrdersReport } from '../../../redux/modules/report/report.actions';
import { reportSelector } from '../../../redux/modules/report/report.selectors';

export function JadwaOrdersFxRevenueTable() {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id' },
    {
      headerName: 'Order ID',
      field: 'orderId',
      type: 'string',
      width: 100,
    },
    {
      headerName: 'Transfer ID',
      field: 'transferId',
      type: 'string',
      width: 100,
    },
    {
      headerName: 'Type',
      field: 'type',
    },
    {
      headerName: 'SAR Amount',
      field: 'sarAmount',
      type: 'number',
      width: 100,
    },
    {
      headerName: 'System FX',
      field: 'systemFxRate',
      type: 'number',
      width: 100,
    },
    {
      headerName: 'Real FX',
      field: 'exchangeRate',
      type: 'number',
      width: 100,
    },
    {
      headerName: 'System USD Amount',
      field: 'systemUsdAmount',
      type: 'number',
      width: 100,
    },
    {
      headerName: 'Real USD Amount',
      field: 'realUsdAmount',
      type: 'number',
      width: 100,
    },
    {
      headerName: 'USD Revenue',
      field: 'usdRevenue',
      type: 'number',
      width: 100,
    },
    {
      headerName: 'SAR Revenue',
      field: 'sarRevenue',
      type: 'number',
      width: 100,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: false,
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 100,
    },
  ]);

  const { jadwaFxRevenueOrders, totalJadwaFxRevenueOrders, fetchJadwaFxRevenueOrdersStatus } =
    useSelector(reportSelector);

  return (
    <Table
      wrapperHeight={700}
      fetchItems={fetchFxRevenueJadwaOrdersReport}
      rows={jadwaFxRevenueOrders}
      columns={columns.current}
      loading={fetchJadwaFxRevenueOrdersStatus === fetchStatuses.pending}
      rowCount={totalJadwaFxRevenueOrders}
      initialColumnVisibilityModel={{ id: false }}
      initialSortModel={[{ field: 'createdAt', sort: 'desc' }]}
    />
  );
}
