import { PaginatedRequest } from '../types/api';
import {
  SaveUserIndexFundRequest,
  CreateUserIndexFundResponse,
  FetchUserIndexFundsResponse,
  FetchUserIndexFundResponse,
  UpdateUserIndexFundRequest,
  UpdateUserIndexFundResponse,
} from '../types/userIndexFund';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class UserIndexFundService extends ApiService {
  pathname = 'admin';

  fetchUserIndexFunds = async (data: PaginatedRequest) =>
    this.get<FetchUserIndexFundsResponse>(`${this.pathname}/user-index-fund?${toQueryParams(data)}`);

  fetchUserIndexFundsByUser = async ({ userId, ...data }: PaginatedRequest & { userId: number }) =>
    this.get<FetchUserIndexFundsResponse>(`${this.pathname}/user-index-fund/user/${userId}?${toQueryParams(data)}`);

  fetchUserIndexFund = async (id: number) =>
    this.get<FetchUserIndexFundResponse>(`${this.pathname}/user-index-fund/${id}`);

  deleteUserIndexFund = async (id: number) =>
    this.delete<FetchUserIndexFundResponse>(`${this.pathname}/user-index-fund/${id}`);

  saveUserIndexFund = async (portfolio: SaveUserIndexFundRequest) =>
    this.post<CreateUserIndexFundResponse, SaveUserIndexFundRequest>(`${this.pathname}/user-index-fund`, portfolio);

  updateUserIndexFund = async ({ id, ...portfolio }: UpdateUserIndexFundRequest) =>
    this.put<UpdateUserIndexFundResponse, Omit<UpdateUserIndexFundRequest, 'id'>>(
      `${this.pathname}/user-index-fund/${id}`,
      portfolio,
    );
}

export const userIndexFundService = new UserIndexFundService();
