import { ApiResponse } from './api';
import { UserGroup } from './userGroup';

export enum UserStatus {
  REGISTERED = 'Registered',
  PENDING = 'Pending',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  BLOCKED = 'Blocked',
  MANUAL_CHECK_REQUIRED = 'Manual Check Required',
  WAITING_ALPACA_ACTIVATION = 'Waiting Alpaca Activation',
}

export interface UserStatusOption {
  id: number;
  name: UserStatus;
}

export type User = {
  id: number;
  fullName: string;
  nationalIdNumber: string;
  mobile: string;
  creationSrc: string;
  email: string;
  disableReason: string;
  approvalReason: string;
  errorMessage: string;
  status: UserStatusOption;
  group: UserGroup;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type UserHistory = {
  id: number;
  userId: number;
  employeeId: number;
  prevValue: User;
  nextValue: User;
  createdAt: Date | string;
  updatedAt: Date | string;
};

interface UserTransactionActivity {
  id: number;
  activity: string;
  description_en: string;
  description_ar: string;
}

export type UserTransaction = {
  id: number;
  amount: number;
  activity: UserTransactionActivity;
  userId: number;
  details: IAnyObject;
  createdAt: Date | string;
};

export type UserSubscription = {
  id: number;
  amount: number;
  startDate: Date | string;
  endDate: Date | string | null;
  endDateType: string;
  frequencyUnit: string;
  status: string;
  registrationToken: string;
  merchantTransactionId: string;
  paymentBrand: string;
  last4Digits: string;
  registrationPayload: IAnyObject;
  isPrimaryCard: boolean;
  isDeleted: boolean;
  deletedAt: Date | string | null;
  canceledAt: Date | string | null;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export enum BankType {
  VIRTUAL = 'VIRTUAL',
  OTHER = 'OTHER',
}

export type BankItemBankType = {
  id: number;
  description: string;
  type: BankType;
};

export type BankItemUserBankDetails = {
  id: number;
  accountNumber: string;
  amount: number;
  cashAllocationValue: number;
  beneficiaryAddress1: string;
  beneficiaryAddress2: string;
  beneficiaryName: string;
  hold: number;
  iban: string;
  swiftCode: string;
  unsettledFee: number;
};

export type UserBankInfo = {
  id: number;
  bankType: BankItemBankType;
  iban: string;
  isPrimaryAccount: boolean;
  swiftCode: string;
  userBankDetails: BankItemUserBankDetails;
};

export enum DocumentTypes {
  SELFIE = 'Selfie',
  VERIFICATION_DOCUMENT = 'Verification Document',
  ACCOUNT_STATEMENT = 'Account Statement',
  TRADE_CONFIRMATION = 'Trade Confirmation',
  OTHER = 'Other',
}

export type UserAttachmentDocumentType = {
  id: number;
  type: DocumentTypes;
};

export type UserAttachment = {
  id: number;
  documentLink: string;
  documentType: UserAttachmentDocumentType;
  createdAt: Date | string;
};

export enum UserSurveyResultStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
}

export interface UserSurveyResultStatusOption {
  id: number;
  status: UserSurveyResultStatus;
  type: UserSurveyResultStatus;
}

export interface UserSurveyResultRiskGroupOption {
  id: number;
  type: string;
  status: string;
}

export type UserSurveyResult = {
  id: number;
  riskGroup: UserSurveyResultRiskGroupOption;
  status: UserSurveyResultStatusOption;
  createdAt: Date;
  updatedAt: Date;
};

export enum UserAgreementName {
  ACCOUNT_OPENING_AGREEMENT = 'account_opening_agreement',
  CHANGE_ALLOCATION_AGREEMENT = 'change_allocation_agreement',
}

export type UserAgreement = {
  id: number;
  name: UserAgreementName;
};

export type UserSignAgreement = {
  id: number;
  agreement: UserAgreement;
  createdAt: Date;
  updatedAt: Date;
};

export type UserWorldCheckScreenData = IAnyObject;

export type FetchUserResponse = ApiResponse<User>;
export type FetchUserWorldCheckScreenResponse = ApiResponse<UserWorldCheckScreenData>;
export type FetchUserSignAgreementsResponse = ApiResponse<{
  items: UserSignAgreement[];
  totalItems: number;
}>;
export type FetchUsersResponse = ApiResponse<{
  items: User[];
  totalItems: number;
}>;
export type FetchUserStatusOptionsResponse = ApiResponse<UserStatusOption[]>;
export type FetchUserAttachmentsDocumentTypeOptionsResponse = ApiResponse<UserAttachmentDocumentType[]>;
export type FetchUserHistoryResponse = ApiResponse<{
  items: UserHistory[];
  totalItems: number;
}>;
export type FetchUserTransactionsResponse = ApiResponse<{
  items: UserTransaction[];
  totalItems: number;
}>;
export type FetchUserBankInfoResponse = ApiResponse<UserBankInfo[]>;
export type FetchUserAttachmentsResponse = ApiResponse<UserAttachment[]>;
export type FetchUserSurveyResultsResponse = ApiResponse<{
  items: UserSurveyResult[];
  totalItems: number;
}>;

export type CreateUserAttachmentsRequest = {
  items: {
    documentTypeId: number;
    link: string;
  }[];
};
export type CreateUserAttachmentResponse = ApiResponse<UserAttachment>;

export type CreateUserRequest = Pick<User, 'mobile' | 'email'> & {
  status: number;
  group: number;
};
export type CreateUserResponse = ApiResponse<User>;

export type UpdateUserRequest = Omit<
  User,
  | 'status'
  | 'group'
  | 'disableReason'
  | 'approvalReason'
  | 'errorMessage'
  | 'nationalIdNumber'
  | 'email'
  | 'creationSrc'
  | 'createdAt'
  | 'updatedAt'
> & {
  status: number;
  group: number;
};
export type UpdateUserResponse = ApiResponse<{ message: string }>;

export type UpdateUserStatusRequest = Pick<User, 'id' | 'disableReason' | 'approvalReason'> & {
  status: UserStatus;
};
export type UpdateUserStatusResponse = ApiResponse<{ message: string }>;

export type WithdrawUserRequest = { id: number; amount: number };
export type WithdrawUserResponse = ApiResponse<{ message: string }>;

export type FetchUserSubscriptionsResponse = ApiResponse<{
  items: UserSubscription[];
  totalItems: number;
}>;
