import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IAsyncDialogProps } from './AsyncDialog';

export interface ConfirmationDialogData {
  title: string | React.JSX.Element;
  description: string | React.JSX.Element;
  acceptButtonText?: string;
  rejectButtonText?: string;
}

export function ConfirmationDialog(props: IAsyncDialogProps<ConfirmationDialogData, boolean>) {
  return (
    <Dialog open={props.open} onClose={() => props.onOk(false)}>
      <DialogTitle>{props.data?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.data?.description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onOk(true)}>{props.data?.acceptButtonText || 'Yes'}</Button>
        <Button onClick={() => props.onOk(false)}>{props.data?.rejectButtonText || 'Cancel'}</Button>
      </DialogActions>
    </Dialog>
  );
}
