import * as yup from 'yup';
import { configsFormFields } from '../../fields/formFields';

export const configsFormSchema = yup.object({
  [configsFormFields.configs]: yup
    .array()
    .of(
      yup.object().shape({
        keyName: yup.string().required('Key Name is required').trim(),
        value: yup.string().required('Value is required').trim().nullable(),
      }),
    )
    .min(1, 'Configs required')
    .unique('Duplicated key name', 'keyName'),
});
