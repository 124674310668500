import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { StyledTableCell } from './PermissionsTableElements';

type PermissionsTableResourceRowRoleCellProps = {
  resource: string;
  roleName: string;
  checked: boolean;
  onChange: (data: { resource: string; roleName: string; checked: boolean }) => void;
};

export const PermissionsTableResourceRowRoleCell: React.FC<PermissionsTableResourceRowRoleCellProps> = React.memo(
  (props) => {
    const { resource, roleName, checked, onChange } = props;

    return (
      <StyledTableCell key={`${resource}-all-${roleName}`} className="normal resource" align="left">
        <FormControlLabel
          label="All"
          labelPlacement="start"
          control={
            <Checkbox
              size="small"
              sx={{ zIndex: 1 }}
              name={`permission-all-${resource}`}
              checked={checked}
              onChange={(e, v) => onChange({ resource, roleName, checked: v })}
            />
          }
        />
      </StyledTableCell>
    );
  },
);
