import { ApiResponse } from './api';
import { IndexFund } from './indexFund';

export enum SatelliteAccountUnitStatus {
  EXECUTED = 'Executed',
}

export interface SatelliteAccountUnitStatusOption {
  id: number;
  name: SatelliteAccountUnitStatus;
}

export type SatelliteAccount = {
  id: number;
  balance: number;
  hold: number;
  accountNumber: string;
  addressAr: string;
  addressEn: string;
  custodianReferenceNumber: string;
  email: string;
  familyNameEn: string;
  firstNameEn: string;
  gender: string;
  iban: string;
  idExpiryDate: string;
  idNumber: string;
  idType: string;
  isDeleted: boolean;
  nationalityEn: string;
  phone: string;
  residenceEn: string;
  secondNameEn: string;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type SatelliteAccountDetailsHistory = {
  id: number;
  satelliteAccountDetails: SatelliteAccount;
  satelliteAccountDetailsId: number;

  /**
   * Old / Current value
   */
  prevBalance: number;
  prevHold: number;

  /**
   * Increment value
   */
  balance: number;
  hold: number;

  /**
   * New value
   */
  newBalance: number;
  newHold: number;

  metadata: IAnyObject;

  createdAt: Date | string;
  updatedAt: Date | string;
};

export type SatelliteAccountUnit = {
  id: number;
  numOfUnits: number;
  entryPrice: number;
  indexFund: IndexFund;
  status: SatelliteAccountUnitStatusOption;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchSatelliteAccountResponse = ApiResponse<SatelliteAccount>;
export type FetchSatelliteAccountsResponse = ApiResponse<{
  items: SatelliteAccount[];
  totalItems: number;
}>;

export type FetchSatelliteAccountDetailsHistoryResponse = ApiResponse<{
  items: SatelliteAccountDetailsHistory[];
  totalItems: number;
}>;

export type CreateSatelliteAccountRequest = Omit<SatelliteAccount, 'id' | 'createdAt' | 'updatedAt'>;
export type CreateSatelliteAccountResponse = ApiResponse<SatelliteAccount>;

export type UpdateSatelliteAccountRequest = Omit<SatelliteAccount, 'createdAt' | 'updatedAt'>;
export type UpdateSatelliteAccountResponse = ApiResponse<SatelliteAccount>;

export type FetchSatelliteAccountUnitResponse = ApiResponse<SatelliteAccountUnit>;
export type FetchSatelliteAccountUnitsResponse = ApiResponse<{
  items: SatelliteAccountUnit[];
  totalItems: number;
}>;

export type CreateSatelliteAccountUnitRequest = Omit<
  SatelliteAccountUnit,
  'id' | 'indexFund' | 'status' | 'createdAt' | 'updatedAt'
> & {
  indexFund: number;
  status: number;
};
export type CreateSatelliteAccountUnitResponse = ApiResponse<SatelliteAccountUnit>;

export type UpdateSatelliteAccountUnitRequest = Omit<
  SatelliteAccountUnit,
  'indexFund' | 'status' | 'createdAt' | 'updatedAt'
> & {
  indexFund: number;
  status: number;
};
export type UpdateSatelliteAccountUnitResponse = ApiResponse<SatelliteAccountUnit>;
