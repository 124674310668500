import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { apiSlice, authApiSlice, mediaApiSlice } from '../api/common/apiSlice';
import { actionReducer } from './modules/action/action.reducer';
import { aggregatedStatisticReducer } from './modules/aggregated-statistic/aggregated-statistic.reducer';
import { alpacaReducer } from './modules/alpaca/alpaca.reducer';
import { alpacaConfigReducer } from './modules/alpacaConfig/alpacaConfig.reducer';
import { anonymousReducer } from './modules/anonymous/anonymous.reducer';
import { appConfigReducer } from './modules/appConfig/appConfig.reducer';
import { benchmarkReducer } from './modules/benchmark/benchmark.reducer';
import { fundGeographicalFocusReducer } from './modules/fundGeographicalFocus/fundGeographicalFocus.reducer';
import { fundManagerReducer } from './modules/fundManager/fundManager.reducer';
import { fundAssetClassReducer } from './modules/fundAssetClass/fundAssetClass.reducer';
import { fundValuationDayReducer } from './modules/fundValuationDay/fundValuationDay.reducer';
import { fundValuationFrequencyReducer } from './modules/fundValuationFrequency/fundValuationFrequency.reducer';
import { jadwaReducer } from './modules/jadwa/jadwa.reducer';
import { mediaReducer } from './modules/media/media.reducer';
import { monitoringReducer } from './modules/monitoring/monitoring.reducer';
import { namesReducer } from './modules/names/names.reducer';
import { authReducer } from './features/auth/auth.slice';
import { employeeReducer } from './modules/employee/employee.reducer';
import { orderExecutionReducer } from './modules/orderExecution/orderExecution.reducer';
import { paymentReducer } from './modules/payment/payment.reducer';
import { portfolioOrderReducer } from './modules/portfolioOrder/portfolioOrder.reducer';
import { reportReducer } from './modules/report/report.reducer';
import { resourceReducer } from './modules/resource/resource.reducer';
import { satelliteAccountReducer } from './modules/satelliteAccount/satelliteAccount.reducer';
import { subscribedPortfolioReducer } from './modules/subscribedPortfolio/subscribedPortfolio.reducer';
import { userReducer } from './modules/user/user.reducer';
import { portfolioReducer } from './modules/portfolio/portfolio.reducer';
import { currencyReducer } from './modules/currency/currency.reducer';
import { userAddressReducer } from './modules/userAddress/userAddress.reducer';
import { userBankReducer } from './modules/userBank/userBank.reducer';
import { userCategoryReducer } from './modules/userCategory/userCategory.reducer';
import { assetReducer } from './modules/asset/asset.reducer';
import { indexFundReducer } from './modules/indexFund/indexFund.reducer';
import { userConfigReducer } from './modules/userConfig/userConfig.reducer';
import { userCustodianDataReducer } from './modules/userCustodianData/userCustodianData.reducer';
import { userGroupReducer } from './modules/userGroup/userGroup.reducer';
import { userIndexFundReducer } from './modules/userIndexFund/userIndexFund.reducer';
import { userPortfolioRequestReducer } from './modules/userPortfolioRequest/userPortfolioRequest.reducer';
import { alpacaTransferReducer } from './modules/alpacaTransfer/alpaca-transfer.reducer';
import { portfolioConfigReducer } from './modules/portfolioConfig/portfolioConfig.reducer';
import { mastercardReducer } from './modules/mastercard/mastercard.reducer';
import { hyperPayReducer } from './modules/hyperPay/hyper-pay.reducer';
import { jadwaConfigReducer } from './modules/jadwaConfig/jadwaConfig.reducer';
import { analyticsReducer } from './modules/analytics/analytics.reducer';
import { analyticsConfigReducer } from './modules/analyticsConfig/analyticsConfig.reducer';
import { productTypeReducer } from './modules/productType/product-type.reducer';
import { feeConfigReducer } from './modules/feeConfig/feeConfig.reducer';
import { userFeeReducer } from './modules/userFee/userFee.reducer';
import { feeCalculationReducer } from './modules/feeCalculation/feeCalculation.reducer';
import { userFeeDeductionReducer } from './modules/userFeeDeduction/userFeeDeduction.reducer';
import { feeBatchReducer } from './modules/feeBatch/feeBatch.reducer';
import { batchExecutionReducer } from './modules/batchExecution/batchExecution.reducer';
import { feeAccountReducer } from './modules/feeAccount/feeAccount.reducer';
import { feesConfigReducer } from './modules/feesConfig/feesConfig.reducer';

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [authApiSlice.reducerPath]: authApiSlice.reducer,
    [mediaApiSlice.reducerPath]: mediaApiSlice.reducer,
    auth: authReducer,
    anonymous: anonymousReducer,
    aggregatedStatistic: aggregatedStatisticReducer,
    names: namesReducer,
    employee: employeeReducer,
    resource: resourceReducer,
    action: actionReducer,
    user: userReducer,
    portfolio: portfolioReducer,
    portfolioOrder: portfolioOrderReducer,
    subscribedPortfolio: subscribedPortfolioReducer,
    userPortfolioRequest: userPortfolioRequestReducer,
    currency: currencyReducer,
    asset: assetReducer,
    indexFund: indexFundReducer,
    userCategory: userCategoryReducer,
    media: mediaReducer,
    fundGeographicalFocus: fundGeographicalFocusReducer,
    fundManager: fundManagerReducer,
    fundAssetClass: fundAssetClassReducer,
    fundValuationDay: fundValuationDayReducer,
    fundValuationFrequency: fundValuationFrequencyReducer,
    benchmark: benchmarkReducer,
    appConfig: appConfigReducer,
    alpacaConfig: alpacaConfigReducer,
    jadwaConfig: jadwaConfigReducer,
    portfolioConfig: portfolioConfigReducer,
    userConfig: userConfigReducer,
    userIndexFund: userIndexFundReducer,
    userBank: userBankReducer,
    satelliteAccount: satelliteAccountReducer,
    userGroup: userGroupReducer,
    userAddress: userAddressReducer,
    userCustodianData: userCustodianDataReducer,
    alpaca: alpacaReducer,
    jadwa: jadwaReducer,
    report: reportReducer,
    payment: paymentReducer,
    monitoring: monitoringReducer,
    alpacaTransfer: alpacaTransferReducer,
    orderExecution: orderExecutionReducer,
    mastercard: mastercardReducer,
    hyperPay: hyperPayReducer,
    analytics: analyticsReducer,
    analyticsConfig: analyticsConfigReducer,
    productType: productTypeReducer,
    feeConfig: feeConfigReducer,
    userFee: userFeeReducer,
    feeCalculation: feeCalculationReducer,
    userFeeDeduction: userFeeDeductionReducer,
    feeBatch: feeBatchReducer,
    batchExecution: batchExecutionReducer,
    feeAccount: feeAccountReducer,
    feesConfig: feesConfigReducer,
  },
  middleware: (getDM) => getDM().concat([apiSlice.middleware, authApiSlice.middleware, mediaApiSlice.middleware]),
  devTools: true,
});

// enable listener behavior for the store
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
