import React from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import HotTubIcon from '@mui/icons-material/HotTub';
import ReplayIcon from '@mui/icons-material/Replay';
import { locations } from '../../routes/locations';

export const Error = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate(locations.home());
  };

  return (
    <Container component="main" maxWidth="xl" sx={{ height: '100vh' }}>
      <Grid container flexDirection="column" alignItems="center" justifyContent="center" height="100%">
        <Grid item>
          <HotTubIcon sx={{ fontSize: 80 }} />
        </Grid>
        <Grid item>
          <Typography component="h3" variant="h3" color="text.primary">
            Not Found
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="h6" variant="body2" color="text.secondary">
            Either you typed a wrong URL, or you followed a bad link.
          </Typography>
        </Grid>
        <Grid item sx={{ mt: 2 }}>
          <Button variant="outlined" color="primary" startIcon={<ReplayIcon />} onClick={goHome}>
            Go Home
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
