import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import DialogContent from '@mui/material/DialogContent';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { SystemUnitsReport } from '../../types/report';
import { fundUnitsReportReconcileFormFields } from '../../forms/fields/formFields/fundUnitsReportReconcile';
import { fundUnitsReportReconcileFormSchema } from '../../forms/validationSchema/formSchema/fundUnitsReportReconcile';
import { DecimalNumberField } from '../../components/DecimalNumberField';
import { useAppDispatch } from '../../redux/hooks';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { reportSelector } from '../../redux/modules/report/report.selectors';
import { reconcileSystemCashReport } from '../../redux/modules/report/report.actions';

interface SystemUnitsReconcileDialogProps {
  systemUnits: SystemUnitsReport | undefined;
  onClose: (isReload: boolean) => void;
}

export function SystemUnitsReconcileDialog({ systemUnits, onClose }: SystemUnitsReconcileDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { reconcileSystemCashReportStatus } = useSelector(reportSelector);
  const { handleSubmit, values, handleChange, touched, errors, submitForm, setValues, resetForm } = useFormik({
    initialValues: {
      [fundUnitsReportReconcileFormFields.reconciledExternal]: 0,
    },
    validationSchema: fundUnitsReportReconcileFormSchema,
    onSubmit: async (formValues) => {
      if (systemUnits) {
        dispatch(reconcileSystemCashReport({ ...systemUnits, ...formValues }));
      }
    },
  });

  useEffect(() => {
    setValues({
      reconciledExternal: systemUnits?.reconciledExternal || 0,
    });
  }, [systemUnits]);

  useEffect(() => {
    if (reconcileSystemCashReportStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('System unit report reconciled!', { variant: 'success' });
    }
    if (reconcileSystemCashReportStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Error in reconciling system unit report!', { variant: 'error' });
    }
  }, [reconcileSystemCashReportStatus]);

  return (
    <Dialog open={!!systemUnits}>
      <DialogTitle>{`Reconcile diff for ${systemUnits?.indexFund.nameEn}`}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <DecimalNumberField
            margin="normal"
            fullWidth
            type="number"
            skipChecks
            maximumFractionDigits={30}
            id={fundUnitsReportReconcileFormFields.reconciledExternal}
            name={fundUnitsReportReconcileFormFields.reconciledExternal}
            label="Reconcile value"
            value={values[fundUnitsReportReconcileFormFields.reconciledExternal]}
            onChange={handleChange}
            error={touched.reconciledExternal && !!errors.reconciledExternal}
            helperText={touched.reconciledExternal && errors.reconciledExternal}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          // loading={isLoading}
          loadingPosition="end"
          onClick={submitForm}
        >
          Save
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
