import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { GridActionsCellItem, GridActionsCellItemProps, GridRowParams, GridColDef } from '@mui/x-data-grid-premium';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { ImportBenchmarkNavDataFileDialog } from '../../components/ImportBenchmarkNavDataFileDialog';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { usePermissions, useSearchParamsState } from '../../hooks';
import { useAppDispatch } from '../../redux/hooks';
import { cleanBenchmarks, deleteBenchmark, fetchBenchmarks } from '../../redux/modules/benchmark/benchmark.actions';
import { benchmarkSelector } from '../../redux/modules/benchmark/benchmark.selectors';
import { Benchmark } from '../../types/benchmark';
import { Portfolio } from '../../types/portfolio';
import { parseJSON } from '../../utils/json';
import * as permissions from '../../utils/permissions';
import { BenchmarkDialog } from './BenchmarkDialog';

export function BenchmarkList() {
  const dispatch = useAppDispatch();
  const tableRef = useRef<TableRef>(null);
  const [openImportBenchmark, setOpenImportBenchmark] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Portfolio>();
  const [actionMenu, setActionMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [open, setOpen] = useState(false);
  const [editValue, setEditValue] = useState<Benchmark | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const canDelete = usePermissions({ items: [permissions.Benchmark.delete] });
  const canUpdate = usePermissions({ items: [permissions.Benchmark.update] });
  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: 'nameEn',
      headerName: 'Name En',
      flex: 1,
    },
    {
      field: 'nameAr',
      headerName: 'Name Ar',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      width: 100,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      width: 100,
    },
  ]);
  const [initialFilterModel, setInitialFilterModel] = useSearchParamsState('filter', '');
  const [initialPage, setInitialPage] = useSearchParamsState('page', '0');
  const [initialPageSize, setInitialPageSize] = useSearchParamsState('pageSize', '100');
  const [initialSortModel, setInitialSortModel] = useSearchParamsState('sort', '');

  const { totalBenchmarks, benchmarks, fetchStatus, deleteStatus } = useSelector(benchmarkSelector);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>, data: Portfolio) => {
    setSelectedRow(data);
    setActionMenu(
      actionMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
          }
        : null,
    );
  };

  const handleCloseMenu = () => {
    setActionMenu(null);
  };

  const onDeleteBenchmark = (id: number) => {
    dispatch(deleteBenchmark(id));
  };

  const onEditBenchmark = (row: Benchmark): void => {
    setEditValue(row);
    setOpen(true);
  };

  const onAddNew = (): void => {
    setOpen(true);
  };

  const importBenchmarkNavDataFile = (): void => {
    setOpenImportBenchmark(true);
    handleCloseMenu();
  };

  const handleBenchmarkClose = () => {
    setOpenImportBenchmark(false);
  };

  const handleClose = (isReload: boolean) => {
    if (isReload) {
      tableRef?.current?.reload();
    }

    setEditValue(null);
    setOpen(false);
  };

  useEffect(
    () => () => {
      dispatch(cleanBenchmarks());
    },
    [],
  );

  useEffect(() => {
    if (deleteStatus === fetchStatuses.success) {
      handleClose(true);
      enqueueSnackbar('Benchmark deleted!', { variant: 'success' });
    }
    if (deleteStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Benchmark deletion error!', { variant: 'error' });
    }
  }, [deleteStatus]);

  useEffect(() => {
    const actionColumn = {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      hideable: false,
      filterable: false,
      sortable: false,
      minWidth: 120,
      getActions: (params: GridRowParams) => {
        const actions: React.ReactElement<GridActionsCellItemProps>[] = [];

        if (canDelete) {
          actions.push(
            <GridActionsCellItem
              key={`benchmark-list-delete-${params.row.id}`}
              icon={<DeleteOutlineOutlinedIcon />}
              label="Link"
              onClick={() => onDeleteBenchmark(params.row.id)}
            />,
          );
        }

        if (canUpdate) {
          actions.push(
            <GridActionsCellItem
              key={`benchmark-list-update-${params.row.id}`}
              icon={<EditOutlinedIcon />}
              label="Edit"
              onClick={() => onEditBenchmark(params.row)}
            />,
          );
          actions.push(
            <GridActionsCellItem
              key={`benchmark-list-actions-${params.row.id}`}
              icon={<MoreVertIcon />}
              label="Actions"
              onClick={(e) => handleMenuOpen(e, params.row)}
            />,
          );
        }

        return actions;
      },
    };

    if (canDelete || canUpdate) {
      setColumns([actionColumn, ...columns]);
    }
  }, [canDelete, canUpdate]);

  return (
    <Container component="main" maxWidth="xl">
      <Box sx={{ pb: 5 }}>
        <Typography variant="h4">
          Benchmarks &nbsp;
          <Fab color="primary" size="small" aria-label="Create Benchmark" onClick={onAddNew}>
            <AddIcon />
          </Fab>
        </Typography>
      </Box>

      <BenchmarkDialog open={open} onClose={handleClose} initialValue={editValue} />

      <Table
        ref={tableRef}
        fetchItems={fetchBenchmarks}
        rows={benchmarks}
        columns={columns}
        loading={fetchStatus === fetchStatuses.pending}
        rowCount={totalBenchmarks}
        initialFilterModel={parseJSON(initialFilterModel)}
        initialPage={+initialPage}
        initialPageSize={+initialPageSize}
        initialSortModel={parseJSON(initialSortModel)}
        onSortModelChange={(sortModel) => setInitialSortModel(JSON.stringify(sortModel))}
        onFilterModelChange={(filterModel) => setInitialFilterModel(JSON.stringify(filterModel))}
        onPageChange={(page) => setInitialPage(`${page}`)}
        onPageSizeChange={(pageSize) => setInitialPageSize(`${pageSize}`)}
      />

      <Menu
        open={actionMenu !== null}
        onClose={handleCloseMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          actionMenu !== null
            ? {
                top: actionMenu.mouseY,
                left: actionMenu.mouseX,
              }
            : undefined
        }
      >
        <MenuItem
          key="benchmark-list-menu-action"
          onClick={() => selectedRow && importBenchmarkNavDataFile()}
          disabled={!selectedRow}
        >
          Import Benchmark Nav Data File
        </MenuItem>
      </Menu>
      {selectedRow && (
        <ImportBenchmarkNavDataFileDialog
          open={openImportBenchmark}
          onClose={handleBenchmarkClose}
          benchmark={selectedRow}
        />
      )}
    </Container>
  );
}
