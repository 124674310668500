import * as yup from 'yup';
import { fundValuationFrequencyFormFields } from '../../fields/formFields';

export const fundValuationFrequencyFormSchema = yup.object({
  [fundValuationFrequencyFormFields.nameEn]: yup
    .string()
    .max(150, 'Name En must be at most 150 characters')
    .required('Name En is required')
    .trim(),
  [fundValuationFrequencyFormFields.nameAr]: yup
    .string()
    .max(150, 'Name Ar must be at most 150 characters')
    .required('Name Ar is required')
    .trim(),
  [fundValuationFrequencyFormFields.descriptionEn]: yup
    .string()
    .max(150, 'Description En must be at most 150 characters')
    .required('Description En is required')
    .trim(),
  [fundValuationFrequencyFormFields.descriptionAr]: yup
    .string()
    .max(150, 'Description Ar must be at most 150 characters')
    .required('Description Ar is required')
    .trim(),
  [fundValuationFrequencyFormFields.expression]: yup
    .string()
    .max(150, 'Expression must be at most 150 characters')
    .required('Expression is required')
    .trim(),
});
