export enum productTypeFormFields {
  nameEn = 'nameEn',
  nameAr = 'nameAr',
  descriptionEn = 'descriptionEn',
  descriptionAr = 'descriptionAr',
  minimumPortfolioInvestment = 'minimumPortfolioInvestment',
  minimumAddFundInvestment = 'minimumAddFundInvestment',
  minimumRedemption = 'minimumRedemption',
  fee = 'fee',
  scoreDependent = 'scoreDependent',
  isCustom = 'isCustom',
  disabled = 'disabled',
  files = 'files',
}
