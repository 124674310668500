import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import Alert from '@mui/material/Alert';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { formFields } from '../../forms/fields/formFields';
import { roleFormSchema } from '../../forms/validationSchema/formSchema';
import { parseApiError } from '../../helpers/api';
import { useCreateRoleMutation, useUpdateRoleMutation } from '../../redux/features/role/role.apiSlice';
import { Role } from '../../types/role';

interface RoleDialogProps {
  open: boolean;
  initialValue: Role | null;
  onClose: (isReload: boolean) => void;
}

export function RoleDialog({ open, initialValue, onClose }: RoleDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [updateRole, { isLoading: isUpdateRoleLoading, error: updateError }] = useUpdateRoleMutation();
  const [createRole, { isLoading: isCreateRoleLoading, error: createError }] = useCreateRoleMutation();
  const isLoading = isUpdateRoleLoading || isCreateRoleLoading;

  const { handleSubmit, values, handleChange, touched, errors, submitForm, setValues, resetForm } = useFormik({
    initialValues: { [formFields.name]: '' },
    validationSchema: roleFormSchema,
    onSubmit: async (formValues) => {
      const isUpdate = initialValue?.id;
      try {
        if (isUpdate) {
          await updateRole({ id: initialValue.id, ...formValues }).unwrap();
        } else {
          await createRole(formValues).unwrap();
        }

        enqueueSnackbar(isUpdate ? 'Role created!' : 'Role updated!', { variant: 'success' });

        onClose(true);
      } catch (err) {
        enqueueSnackbar(isUpdate ? 'Role creation error!' : 'Role update error!', {
          variant: 'error',
        });
      }
    },
  });

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    setValues({ name: initialValue?.name || '' });
  }, [initialValue]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{initialValue?.id ? 'Edit role' : 'Create a new Role'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {updateError && <Alert severity="error">{parseApiError(updateError)?.data?.message}</Alert>}
          {createError && <Alert severity="error">{parseApiError(createError)?.data?.message}</Alert>}
          Enter the role name
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="roleName"
            name={formFields.name}
            label="Role Name"
            type="roleName"
            value={values[formFields.name]}
            onChange={handleChange}
            error={touched.name && !!errors.name}
            helperText={touched.name && errors.name}
            inputProps={{ style: { textTransform: 'uppercase' } }}
            autoComplete="role-name"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={isLoading}
          loadingPosition="end"
          onClick={submitForm}
        >
          {initialValue?.id ? 'Save' : 'Create'}
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
