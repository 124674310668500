import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import LoadingButton from '@mui/lab/LoadingButton';
import ReportIcon from '@mui/icons-material/Report';
import { useSnackbar } from 'notistack';
import { useAppDispatch } from '../../redux/hooks';
import { runAlpacaPrefunding } from '../../redux/modules/alpacaTransfer/alpaca-transfer.actions';
import { AlpacaTransfersTable } from './components/alpaca/AlpacaTransfersTable';
import { ManualAlpacaTransferDialog } from './ManualAlpacaTransferDialog';
import { alpacaTransferSelector } from '../../redux/modules/alpacaTransfer/alpaca-transfer.selectors';
import { fetchStatuses } from '../../constants/fetchStatuses';

export function AlpacaTransfersTab() {
  const dispatch = useAppDispatch();
  const { createStatus } = useSelector(alpacaTransferSelector);
  const [isOpen, setIsOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onRunPrefunding = async () => {
    dispatch(runAlpacaPrefunding());
  };

  useEffect(() => {
    if (createStatus === fetchStatuses.success) {
      enqueueSnackbar('Transfer created!', { variant: 'success' });
    }
    if (createStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Failed to create a transfer!', { variant: 'error' });
    }
  }, [createStatus]);

  const onCreateTransfer = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Grid
        item
        container
        flexDirection="row"
        justifyContent="space-around"
        component={Paper}
        sx={{ p: '24px', mb: 6 }}
      >
        <Grid item>
          <LoadingButton
            type="button"
            color="info"
            variant="contained"
            endIcon={<ReportIcon />}
            loadingPosition="end"
            onClick={onRunPrefunding}
          >
            Run prefunding
          </LoadingButton>
        </Grid>
        <Grid item>
          <LoadingButton
            type="button"
            color="info"
            variant="contained"
            endIcon={<ReportIcon />}
            loadingPosition="end"
            onClick={onCreateTransfer}
          >
            Create transfer
          </LoadingButton>
        </Grid>
      </Grid>

      <AlpacaTransfersTable />

      <ManualAlpacaTransferDialog open={isOpen} onClose={onClose} />
    </>
  );
}
