import { apiSlice } from '../../../api/common/apiSlice';
import { toQueryParams } from '../../../utils/request';
import { FetchLedgerAccountResponse, FetchLedgerResponse, LedgerRequestParams } from '../../../types/ledger';

export const ledgerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchCashLedgers: builder.query<FetchLedgerResponse, LedgerRequestParams>({
      query: (data) => ({
        url: `/admin/ledger/cash-ledger?${toQueryParams(data)}`,
        method: 'GET',
      }),
    }),
    fetchUnitsLedgers: builder.query<FetchLedgerResponse, LedgerRequestParams>({
      query: (data) => ({
        url: `/admin/ledger/units-ledger?${toQueryParams(data)}`,
        method: 'GET',
      }),
    }),
    fetchCashAccounts: builder.query<FetchLedgerAccountResponse, void>({
      query: () => ({
        url: '/admin/ledger/accounts/cash',
        method: 'GET',
      }),
    }),
    fetchUnitsAccounts: builder.query<FetchLedgerAccountResponse, void>({
      query: () => ({
        url: '/admin/ledger/accounts/units',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useFetchCashLedgersQuery,
  useFetchUnitsLedgersQuery,
  useFetchCashAccountsQuery,
  useFetchUnitsAccountsQuery,
} = ledgerApiSlice;
