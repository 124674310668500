import { FetchEarlySignupUsersResponse } from '../types/anonymous';
import { PaginatedRequest } from '../types/api';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class AnonymousService extends ApiService {
  pathname = 'admin/anonymous';

  fetchEarlySignupUsers = async (data: PaginatedRequest) =>
    this.get<FetchEarlySignupUsersResponse>(`${this.pathname}/signup?${toQueryParams(data)}`);
}

export const anonymousService = new AnonymousService();
