import * as yup from 'yup';
import { indexFundBulkUpdateNavFormFields, indexFundFormFields } from '../../fields/formFields';

export const indexFundFormSchema = yup.object({
  [indexFundFormFields.bloombergTicker]: yup.string().required('Bloomberg Ticker is required').trim(),
  [indexFundFormFields.code]: yup.string().required('Code is required').trim(),
  [indexFundFormFields.nameEn]: yup.string().required('Name En is required').trim(),
  [indexFundFormFields.nameAr]: yup.string().required('Name Ar is required').trim(),
  [indexFundFormFields.secondNameEn]: yup.string().required('Second Name En is required').trim(),
  [indexFundFormFields.secondNameAr]: yup.string().required('Second Name Ar is required').trim(),
  [indexFundFormFields.asset]: yup.number().positive().required('Asset is required'),
  [indexFundFormFields.source]: yup.number().min(0).required('Source is required'),
  [indexFundFormFields.assetClass]: yup.number().positive().required('Asset Class is required'),
  [indexFundFormFields.strategy]: yup.string().required('Strategy is required'),
  [indexFundFormFields.manager]: yup.number().positive().required('Manager is required'),
  [indexFundFormFields.objectiveEn]: yup.string().required('Objective En is required').trim(),
  [indexFundFormFields.objectiveAr]: yup.string().required('Objective Ar is required').trim(),
  [indexFundFormFields.geographicalFocus]: yup.number().positive().required('Geographical Focus is required'),
  [indexFundFormFields.shariaaComplaint]: yup.boolean().required('Shariaa Complaint is required'),
  [indexFundFormFields.allowFractions]: yup.boolean().required('Allow Fractions is required'),
  [indexFundFormFields.expenseRatio]: yup.number().nullable(),
  [indexFundFormFields.valuationDay]: yup.number().positive().required('ValuationDay is required'),
  [indexFundFormFields.valuationFrequency]: yup.number().positive().required('ValuationFrequency is required'),
  [indexFundFormFields.type]: yup.number().positive().required('Type is required'),
  [indexFundFormFields.category]: yup.number().positive().required('Category is required'),
  [indexFundFormFields.currency]: yup.number().positive().required('Currency is required'),
  [indexFundFormFields.status]: yup.number().positive().required('Status is required'),
  [indexFundFormFields.netAssetValuePerUnit]: yup
    .string()
    .trim()
    .matches(/(?<=^| )\d+(\.\d+)?(?=$| )/, 'Net Asset Value Per Unit must be a number')
    .test('min', 'Net Asset Value Per Unit must be a positive number', (value) => Boolean(value && +value >= 0))
    .nullable(),
  [indexFundFormFields.assetUnderManagement]: yup
    .string()
    .trim()
    .matches(/(?<=^| )\d+(\.\d+)?(?=$| )/, 'Asset Under Management must be a number')
    .test('min', 'Asset Under Management must be a positive number', (value) => Boolean(value && +value >= 0))
    .nullable(),
  [indexFundFormFields.inceptionDate]: yup.string().required('Inception Date is required').trim(),
  [indexFundFormFields.managementFee]: yup
    .string()
    .trim()
    .matches(/(?<=^| )\d+(\.\d+)?(?=$| )/, 'Management Fee must be a number between 0 and 100%')
    .test('min', 'Management Fee must be a number between 0 and 100%', (value) => Boolean(value && +value >= 0))
    .test('max', 'Management Fee must be a number between 0 and 100%', (value) => Boolean(value && +value <= 100))
    .required('Management Fee is required'),
  [indexFundFormFields.minimumSubscription]: yup
    .string()
    .trim()
    .matches(/(?<=^| )\d+(\.\d+)?(?=$| )/, 'Minimum Subscription must be a number')
    .test('min', 'Minimum Subscription must be a positive number', (value) => Boolean(value && +value >= 0))
    .required('Minimum Subscription is required'),
  [indexFundFormFields.minimumRedemption]: yup
    .string()
    .trim()
    .matches(/(?<=^| )\d+(\.\d+)?(?=$| )/, 'Minimum Redemption must be a number')
    .test('min', 'Minimum Redemption must be a positive number', (value) => Boolean(value && +value >= 0))
    .required('Minimum Redemption is required'),
  [indexFundFormFields.subscriptionFee]: yup
    .string()
    .trim()
    .matches(/(?<=^| )\d+(\.\d+)?(?=$| )/, 'Subscription Fee must be a number between 0 and 100%')
    .test('min', 'Subscription Fee must be a number between 0 and 100%', (value) => Boolean(value && +value >= 0))
    .test('max', 'Subscription Fee must be a number between 0 and 100%', (value) => Boolean(value && +value <= 100))
    .required('Subscription Fee is required'),
  [indexFundFormFields.redemptionFee]: yup
    .string()
    .trim()
    .matches(/(?<=^| )\d+(\.\d+)?(?=$| )/, 'Redemption Fee must be a number between 0 and 100%')
    .test('min', 'Redemption Fee must be a number between 0 and 100%', (value) => Boolean(value && +value >= 0))
    .test('max', 'Redemption Fee must be a number between 0 and 100%', (value) => Boolean(value && +value <= 100))
    .required('Redemption Fee is required'),
  [indexFundFormFields.otherFee]: yup
    .string()
    .trim()
    .matches(/(?<=^| )\d+(\.\d+)?(?=$| )/, 'Other Fee must be a number between 0 and 100%')
    .test('min', 'Other Fee must be a number between 0 and 100%', (value) => Boolean(value && +value >= 0))
    .test('max', 'Other Fee must be a number between 0 and 100%', (value) => Boolean(value && +value <= 100))
    .required('Other Fee is required'),
  [indexFundFormFields.benchmark]: yup.string().required('Benchmark is required').trim(),
  [indexFundFormFields.topHoldings]: yup.string().nullable().trim(),
  [indexFundFormFields.topHoldingsAr]: yup.string().nullable().trim(),
  [indexFundFormFields.numberHoldings]: yup.number().integer().nullable(),
  [indexFundFormFields.riskLevel]: yup.number().required('Risk Level is required'),
  [indexFundFormFields.y1mReturn]: yup.number().nullable(),
  [indexFundFormFields.y3mReturn]: yup.number().nullable(),
  [indexFundFormFields.y5mReturn]: yup.number().nullable(),
  [indexFundFormFields.y1mStdev]: yup.number().nullable(),
  [indexFundFormFields.y3mStdev]: yup.number().nullable(),
  [indexFundFormFields.y5mStdev]: yup.number().nullable(),
  [indexFundFormFields.y1mReturnRisk]: yup.number().nullable(),
  [indexFundFormFields.y3mReturnRisk]: yup.number().nullable(),
  [indexFundFormFields.y5mReturnRisk]: yup.number().nullable(),
  [indexFundFormFields.attachments]: yup.array().of(
    yup.object().shape({
      documentLink: yup.string().required('File is required'),
    }),
  ),
});

export const indexFundBulkUpdateNavFormSchema = yup.object({
  [indexFundBulkUpdateNavFormFields.items]: yup.array().of(
    yup.object().shape({
      id: yup.number().positive().required('Index Fund Id is required'),
      nav: yup
        .string()
        .trim()
        .matches(/(?<=^| )\d+(\.\d+)?(?=$| )/, 'Nav must be a number')
        .test('min', 'Nav must be a positive number', (value) => Boolean(value && +value >= 0))
        .nullable(),
    }),
  ),
});
