import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import Chip from '@mui/material/Chip';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { fetchFeeCalculations } from '../../../../redux/modules/feeCalculation/feeCalculation.actions';
import { Table } from '../../../../components/Table';
import { round2Format } from '../../../../utils/number';
import { FeeCalculationStatus, FeeCalculationTypes } from '../../../../types/feeCalculation';
import { useAppDispatch } from '../../../../redux/hooks';
import { feeCalculationSelector } from '../../../../redux/modules/feeCalculation/feeCalculation.selectors';
import { feeCalculationStatusToInfo } from '../../../../constants/tables/feeCalculation';

interface FeeCalculationTableProps {
  userFeeId: number;
}

export function FeeCalculationTable({ userFeeId }: FeeCalculationTableProps) {
  const dispatch = useAppDispatch();

  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id', filterable: false },
    {
      field: 'type',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: Object.values(FeeCalculationTypes),
      width: 150,
      valueGetter: (params) => params.row?.type?.name,
    },
    {
      field: 'date',
      headerName: 'Calculation Date',
      type: 'dateTime',
      width: 200,
      renderCell: (params) => params.value.toISOString().substring(0, 10),
    },
    {
      field: 'totalMarketValue',
      headerName: 'Total Market Value',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'calculatedTotalFee',
      headerName: 'Estemated Total Fee',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'actualTotalFee',
      headerName: 'Actual Total Fee',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'managementFee',
      headerName: 'Management Fee',
      minWidth: 150,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'vat',
      headerName: 'VAT',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(FeeCalculationStatus),
      minWidth: 150,
      flex: 1,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = feeCalculationStatusToInfo[params.row?.status?.name as FeeCalculationStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.row?.status?.name;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.row?.status?.name} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'dateTime',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const { feeCalculations, totalFeeCalculations, fetchStatus } = useSelector(feeCalculationSelector);

  useEffect(() => {
    dispatch(
      fetchFeeCalculations({
        limit: 100,
        offset: 0,
        sortField: 'id',
        sortOrder: 'desc',
        filter: JSON.stringify({
          items: [{ columnField: 'userFee', operatorValue: '=', value: `${userFeeId}` }],
        }),
      }),
    );
  }, [userFeeId]);

  return (
    <Table
      rows={feeCalculations}
      columns={columns.current}
      loading={fetchStatus === fetchStatuses.pending}
      rowCount={totalFeeCalculations}
      disableRowGrouping
      density="compact"
      initialColumnVisibilityModel={{ id: false, metadata: false }}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      requiredFilters={[{ field: 'userFee', value: `${userFeeId}`, operator: 'is' }]}
      toolbarExportProps={{
        csvOptions: { fileName: `user-fee-${userFeeId}-calculation-${new Date().toLocaleString()}` },
        excelOptions: { fileName: `user-fee-${userFeeId}-calculation-${new Date().toLocaleString()}` },
      }}
      wrapperHeight={300}
    />
  );
}
