import React from 'react';
import { useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import DownloadIcon from '@mui/icons-material/Download';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useAppDispatch } from '../../redux/hooks';
import { exportFundAndBenchmarkNavData } from '../../redux/modules/indexFund/indexFund.actions';
import { indexFundSelector } from '../../redux/modules/indexFund/indexFund.selectors';
import { IndexFund } from '../../types/indexFund';

interface ExportNavDataButtonProps {
  indexFund?: IndexFund | null;
}

export function ExportNavDataButton({ indexFund }: ExportNavDataButtonProps) {
  const dispatch = useAppDispatch();

  const { exportFundAndBenchmarkNavDataStatus } = useSelector(indexFundSelector);

  const onExportNavData = async () => {
    if (!indexFund) {
      return;
    }

    const res = await dispatch(exportFundAndBenchmarkNavData(indexFund.id));

    const blob = new Blob([res.payload as string], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'fund-and-benchmark-nav-data.csv';
    link.click();
    link.remove();
  };

  return (
    <LoadingButton
      type="button"
      color="primary"
      variant="outlined"
      endIcon={<DownloadIcon />}
      disabled={!indexFund?.id}
      loading={exportFundAndBenchmarkNavDataStatus === fetchStatuses.pending}
      loadingPosition="end"
      onClick={onExportNavData}
    >
      Export Fund & Benchmark Historical Data
    </LoadingButton>
  );
}
