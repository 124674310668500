import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { useState } from 'react';
import * as React from 'react';

interface GridCellExpandProps {
  ExpandedComponent?: React.JSX.Element;
  showPopperAlways?: boolean;
  value: string;
  width: number;
}

function isOverflown(element: Element): boolean {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

export const GridCellExpand = React.memo((props: GridCellExpandProps) => {
  const { width, ExpandedComponent, value, showPopperAlways = false } = props;
  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleCopyClick = () => {
    setSnackbarOpen(true);
    navigator.clipboard.writeText(value);
  };

  const handleMouseEnter = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const isCurrentlyOverflown = isOverflown(cellValue.current!);
    setShowPopper(isCurrentlyOverflown || showPopperAlways);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: 1,
        height: 1,
        position: 'relative',
        display: 'flex',
      }}
    >
      {showPopper && (
        <Box
          ref={cellDiv}
          sx={{
            height: 1,
            width,
            display: 'block',
            position: 'absolute',
            top: 0,
          }}
        />
      )}
      <Box ref={cellValue} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
          sx={{ position: 'relative' }}
        >
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <Paper elevation={1} style={{ minHeight: wrapper.current!.offsetHeight - 3 }}>
            {ExpandedComponent || (
              <Typography variant="body2" component="pre" style={{ padding: 8, overflow: 'auto' }}>
                {value}
              </Typography>
            )}
          </Paper>
          <IconButton
            aria-label="copy"
            color="primary"
            size="small"
            sx={{ position: 'absolute', right: '3px', top: '3px' }}
            onClick={handleCopyClick}
          >
            <CopyAllIcon />
          </IconButton>
        </Popper>
      )}
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={2000}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        message="Copied to clipboard"
      />
    </Box>
  );
});
