import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Outlet } from 'react-router-dom';
import packageJson from '../../package.json';

export default function PublicPageLayout() {
  return (
    <>
      <Outlet />
      <Box display="flex" alignItems="center" justifyContent="center" bottom="0" position="absolute" width="100%">
        <Typography variant="body2">Version: {packageJson.version}</Typography>
      </Box>
    </>
  );
}
