import {
  FetchKycGenderOptionsResponse,
  FetchKycResponse,
  FetchKycStatusOptionsResponse,
  FetchKycTypeOptionsResponse,
  UpdateKycRequest,
  UpdateKycResponse,
} from '../types/kyc';
import { ApiService } from './common/ApiService';

class KycService extends ApiService {
  pathname = 'admin';

  fetchKycByUserId = async (id: number) => this.get<FetchKycResponse>(`${this.pathname}/kyc/user/${id}`);

  fetchKycTypeOptions = async () => this.get<FetchKycTypeOptionsResponse>(`${this.pathname}/kyc/type/options`);

  fetchKycStatusOptions = async () => this.get<FetchKycStatusOptionsResponse>(`${this.pathname}/kyc/status/options`);

  fetchKycGenderOptions = async () => this.get<FetchKycGenderOptionsResponse>(`${this.pathname}/kyc/gender/options`);

  updateKyc = async ({ userId, ...kyc }: UpdateKycRequest) =>
    this.put<UpdateKycResponse, Omit<UpdateKycRequest, 'userId'>>(`${this.pathname}/kyc/user/${userId}`, kyc);
}

export const kycService = new KycService();
