import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { HyperPayState } from './hyper-pay.types';
import { fetchHyperPaySessions } from './hyper-pay.actions';
import { FetchHyperPaySessionsResponse } from '../../../types/hyper-pay';

const initialState: HyperPayState = {
  hyperPaySessions: [],
  totalHyperPaySessions: 0,
  fetchHyperPaySessionsStatus: fetchStatuses.idle,
  error: null,
};

const hyperPaySlice = createSlice({
  name: 'hyperPay',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchHyperPaySessions.pending, (state) => {
      state.fetchHyperPaySessionsStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchHyperPaySessions.fulfilled, (state, action: PayloadAction<FetchHyperPaySessionsResponse>) => {
      state.hyperPaySessions = action.payload.data.items;
      state.totalHyperPaySessions = action.payload.data.totalItems;
      state.fetchHyperPaySessionsStatus = fetchStatuses.success;
    });
    builder.addCase(fetchHyperPaySessions.rejected, (state, action) => {
      state.fetchHyperPaySessionsStatus = fetchStatuses.rejected;
      state.error = action.error;
    });
  },
});

export const hyperPayReducer = hyperPaySlice.reducer;
