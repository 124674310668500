import { ApiResponse } from './api';

export enum KycStatus {
  PENDING = 'Pending',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum KycType {
  CITIZEN = 'Citizen',
  RESIDENT = 'Resident',
}

export enum KycGender {
  MALE = 'Male',
  FEMALE = 'Female',
}

export interface KycTypeOption {
  id: number;
  name: KycType;
}

export interface KycStatusOption {
  id: number;
  name: KycStatus;
}

export interface KycGenderOption {
  id: number;
  name: KycGender;
}

export interface Kyc {
  id: number;
  nationalId: string;
  fullNameEn: string;
  fullNameAr: string;
  firstNameEn: string;
  firstNameAr: string;
  fatherNameAr: string;
  fatherNameEn: string;
  grandFatherNameAr: string;
  grandFatherNameEn: string;
  familyNameEn: string;
  familyNameAr: string;
  nationalityEn: string;
  nationalityAr: string;
  issueLocationAr: string;
  issueLocationEn: string;
  assuranceLevel: string;
  language: string;
  type: KycTypeOption;
  status: KycStatusOption;
  email: string;
  phone: string;
  address: string;
  birthDate: Date;
  gender: KycGenderOption;
  idIssueDate: Date;
  idExpiryDate: Date;
  iqamaExpiryDate?: Date;
  maritalStatus?: string;
  educationLevel?: string;
  companyName?: string;
  confirmDate?: Date | string;
  jobTitle?: string;
  employerAddress?: string;
  country?: string;
  fiveYearsFinancialSector?: boolean;
  fiveYearsFinancialSectorDescription?: string;
  boardDirectorsMember?: boolean;
  boardDirectorsMemberDescription?: string;
  individualWithHighProfilePoliticalRole?: boolean;
  individualWithHighProfilePoliticalRoleDescription?: string;
  notBeneficialOwner?: boolean;
  notBeneficialOwnerDescription?: string;
  createdAt: Date | string;
  updatedAt: Date | string;
}

export type FetchKycResponse = ApiResponse<Kyc>;
export type FetchKycTypeOptionsResponse = ApiResponse<KycTypeOption[]>;
export type FetchKycStatusOptionsResponse = ApiResponse<KycStatusOption[]>;
export type FetchKycGenderOptionsResponse = ApiResponse<KycGenderOption[]>;

export type UpdateKycRequest = Omit<Kyc, 'type' | 'status' | 'gender' | 'createdAt' | 'updatedAt'> & {
  userId: number;
  type: number;
  status: number;
  gender: number;
};
export type UpdateKycResponse = ApiResponse<{ message: string }>;
