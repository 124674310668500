import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../../../constants/fetchStatuses';
import { userAddressFormFields } from '../../../../forms/fields/formFields';
import { userAddressFormSchema } from '../../../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../../../redux/hooks';
import { actionSelector } from '../../../../redux/modules/action/action.selectors';
import { createUserAddress, updateUserAddress } from '../../../../redux/modules/userAddress/userAddress.actions';
import { UserAddress } from '../../../../types/userAddress';

interface AddressDialogProps {
  open: boolean;
  userId: number;
  initialValue: UserAddress | null;
  onClose: (isReload: boolean) => void;
}

export function AddressDialog({ open, userId, initialValue, onClose }: AddressDialogProps) {
  const { t } = useTranslation('apiError');
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, values, handleChange, touched, errors, submitForm, setFieldValue, resetForm } = useFormik({
    initialValues: {
      [userAddressFormFields.streetName]: initialValue?.streetName || '',
      [userAddressFormFields.city]: initialValue?.city || '',
      [userAddressFormFields.address2]: initialValue?.address2 || '',
      [userAddressFormFields.address1]: initialValue?.address1 || '',
      [userAddressFormFields.additionalNumber]: initialValue?.additionalNumber || '',
      [userAddressFormFields.district]: initialValue?.district || '',
      [userAddressFormFields.unitNumber]: initialValue?.unitNumber || '',
      [userAddressFormFields.isPrimaryAddress]: initialValue?.isPrimaryAddress || false,
      [userAddressFormFields.buildingNumber]: initialValue?.buildingNumber || '',
      [userAddressFormFields.postCode]: initialValue?.postCode || '',
      [userAddressFormFields.locationCoordinates]: initialValue?.locationCoordinates || '',
    },
    validationSchema: userAddressFormSchema,
    onSubmit: async (formValues) => {
      try {
        if (initialValue?.id) {
          await dispatch(updateUserAddress({ id: initialValue.id, userId, ...formValues })).unwrap();
        } else {
          await dispatch(createUserAddress({ ...formValues, userId })).unwrap();
        }

        enqueueSnackbar(`Address ${initialValue?.id ? 'updated' : 'created'} successfully!`, { variant: 'success' });

        onClose(true);
      } catch (err: any) {
        enqueueSnackbar(
          `${initialValue?.id ? 'Update' : 'Create'} address failed: ${t(err?.message)} ${JSON.stringify(err?.data)}`,
          {
            variant: 'error',
          },
        );
      }
    },
  });
  const { createStatus, updateStatus, error } = useSelector(actionSelector);
  const isLoading = createStatus === fetchStatuses.pending || updateStatus === fetchStatuses.pending;

  const onResetForm = () => {
    if (initialValue) {
      resetForm({
        values: {
          [userAddressFormFields.streetName]: initialValue?.streetName || '',
          [userAddressFormFields.city]: initialValue?.city || '',
          [userAddressFormFields.address2]: initialValue?.address2 || '',
          [userAddressFormFields.address1]: initialValue?.address1 || '',
          [userAddressFormFields.additionalNumber]: initialValue?.additionalNumber || '',
          [userAddressFormFields.district]: initialValue?.district || '',
          [userAddressFormFields.unitNumber]: initialValue?.unitNumber || '',
          [userAddressFormFields.isPrimaryAddress]: initialValue?.isPrimaryAddress || false,
          [userAddressFormFields.buildingNumber]: initialValue?.buildingNumber || '',
          [userAddressFormFields.postCode]: initialValue?.postCode || '',
          [userAddressFormFields.locationCoordinates]: initialValue?.locationCoordinates || '',
        },
      });
    } else {
      resetForm();
    }
  };

  useEffect(() => {
    onResetForm();
  }, [initialValue]);

  useEffect(() => {
    if (!open) {
      onResetForm();
    }
  }, [open]);

  useEffect(() => {
    if (createStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Address created!', { variant: 'success' });
    }
    if (createStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Address creation error!', { variant: 'error' });
    }

    if (updateStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Address updated!', { variant: 'success' });
    }
  }, [createStatus, updateStatus]);

  useEffect(() => {
    const address1 =
      // eslint-disable-next-line
      values[userAddressFormFields.buildingNumber] +
      ' ' +
      values[userAddressFormFields.streetName] +
      ' - ' +
      values[userAddressFormFields.district];

    setFieldValue(userAddressFormFields.address1, address1);
  }, [
    values[userAddressFormFields.buildingNumber],
    values[userAddressFormFields.streetName],
    values[userAddressFormFields.district],
  ]);

  useEffect(() => {
    const address2 =
      // eslint-disable-next-line
      values[userAddressFormFields.city] +
      ' ' +
      values[userAddressFormFields.postCode] +
      ' - ' +
      values[userAddressFormFields.additionalNumber];

    setFieldValue(userAddressFormFields.address2, address2);
  }, [
    values[userAddressFormFields.city],
    values[userAddressFormFields.postCode],
    values[userAddressFormFields.additionalNumber],
  ]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{initialValue?.id ? 'Edit Address' : 'Create a new Address'}</DialogTitle>
      <DialogContent>
        <DialogContentText>{error && <Alert severity="error">{error.message}</Alert>}</DialogContentText>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} flexDirection="column" paddingX={2} paddingBottom={3}>
            <Grid item>
              {isLoading ? (
                <Skeleton variant="rectangular" width="100%" height={80} />
              ) : (
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  id={userAddressFormFields.buildingNumber}
                  name={userAddressFormFields.buildingNumber}
                  label="Building Number"
                  type="string"
                  value={values[userAddressFormFields.buildingNumber]}
                  onChange={handleChange}
                  error={touched.buildingNumber && !!errors.buildingNumber}
                  helperText={touched.buildingNumber && errors.buildingNumber}
                  autoComplete="buildingNumber"
                />
              )}
            </Grid>
            <Grid item>
              {isLoading ? (
                <Skeleton variant="rectangular" width="100%" height={80} />
              ) : (
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  id={userAddressFormFields.streetName}
                  name={userAddressFormFields.streetName}
                  label="Street Name"
                  type="string"
                  value={values[userAddressFormFields.streetName]}
                  onChange={handleChange}
                  error={touched.streetName && !!errors.streetName}
                  helperText={touched.streetName && errors.streetName}
                  autoComplete="streetName"
                />
              )}
            </Grid>
            <Grid item>
              {isLoading ? (
                <Skeleton variant="rectangular" width="100%" height={80} />
              ) : (
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  id={userAddressFormFields.district}
                  name={userAddressFormFields.district}
                  label="District"
                  type="string"
                  value={values[userAddressFormFields.district]}
                  onChange={handleChange}
                  error={touched.district && !!errors.district}
                  helperText={touched.streetName && errors.district}
                  autoComplete="district"
                />
              )}
            </Grid>
            <Grid item>
              {isLoading ? (
                <Skeleton variant="rectangular" width="100%" height={80} />
              ) : (
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  id={userAddressFormFields.address1}
                  name={userAddressFormFields.address1}
                  label="Address 1"
                  type="string"
                  disabled
                  value={values[userAddressFormFields.address1]}
                  onChange={handleChange}
                  error={touched.address1 && !!errors.address1}
                  helperText={touched.address1 && errors.address1}
                  autoComplete="address1"
                />
              )}
            </Grid>
            <Grid item>
              {isLoading ? (
                <Skeleton variant="rectangular" width="100%" height={80} />
              ) : (
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  id={userAddressFormFields.city}
                  name={userAddressFormFields.city}
                  label="City"
                  type="string"
                  value={values[userAddressFormFields.city]}
                  onChange={handleChange}
                  error={touched.city && !!errors.city}
                  helperText={touched.city && errors.city}
                  autoComplete="city"
                />
              )}
            </Grid>
            <Grid item>
              {isLoading ? (
                <Skeleton variant="rectangular" width="100%" height={80} />
              ) : (
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  id={userAddressFormFields.postCode}
                  name={userAddressFormFields.postCode}
                  label="Post Code"
                  type="string"
                  value={values[userAddressFormFields.postCode]}
                  onChange={handleChange}
                  error={touched.postCode && !!errors.postCode}
                  helperText={touched.postCode && errors.postCode}
                  autoComplete="postCode"
                />
              )}
            </Grid>
            <Grid item>
              {isLoading ? (
                <Skeleton variant="rectangular" width="100%" height={80} />
              ) : (
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  id={userAddressFormFields.additionalNumber}
                  name={userAddressFormFields.additionalNumber}
                  label="additionalNumber"
                  type="string"
                  value={values[userAddressFormFields.additionalNumber]}
                  onChange={handleChange}
                  error={touched.additionalNumber && !!errors.additionalNumber}
                  helperText={touched.additionalNumber && errors.additionalNumber}
                  autoComplete="additionalNumber"
                />
              )}
            </Grid>
            <Grid item>
              {isLoading ? (
                <Skeleton variant="rectangular" width="100%" height={80} />
              ) : (
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  id={userAddressFormFields.address2}
                  name={userAddressFormFields.address2}
                  label="Address 2"
                  type="string"
                  disabled
                  value={values[userAddressFormFields.address2]}
                  onChange={handleChange}
                  error={touched.address2 && !!errors.address2}
                  helperText={touched.address2 && errors.address2}
                  autoComplete="address2"
                />
              )}
            </Grid>
            <Grid item>
              {isLoading ? (
                <Skeleton variant="rectangular" width="100%" height={80} />
              ) : (
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  id={userAddressFormFields.unitNumber}
                  name={userAddressFormFields.unitNumber}
                  label="Unit Number"
                  type="string"
                  value={values[userAddressFormFields.unitNumber]}
                  onChange={handleChange}
                  error={touched.unitNumber && !!errors.unitNumber}
                  helperText={touched.unitNumber && errors.unitNumber}
                  autoComplete="unitNumber"
                />
              )}
            </Grid>
            <Grid item>
              {isLoading ? (
                <Skeleton variant="rectangular" width="100%" height={80} />
              ) : (
                <TextField
                  margin="dense"
                  required
                  fullWidth
                  id={userAddressFormFields.locationCoordinates}
                  name={userAddressFormFields.locationCoordinates}
                  label="Location Coordinates"
                  type="string"
                  value={values[userAddressFormFields.locationCoordinates]}
                  onChange={handleChange}
                  error={touched.locationCoordinates && !!errors.locationCoordinates}
                  helperText={touched.locationCoordinates && errors.locationCoordinates}
                  autoComplete="locationCoordinates"
                />
              )}
            </Grid>
            <Grid item>
              {isLoading ? (
                <Skeleton variant="rectangular" width={142} height={38} sx={{ mt: 2 }} />
              ) : (
                <FormControlLabel
                  sx={{ mt: 2 }}
                  label="Is Primary Address"
                  labelPlacement="start"
                  componentsProps={{
                    typography: { textTransform: 'capitalize' },
                  }}
                  id={userAddressFormFields.isPrimaryAddress}
                  name={userAddressFormFields.isPrimaryAddress}
                  control={
                    <Switch
                      id={userAddressFormFields.isPrimaryAddress}
                      name={userAddressFormFields.isPrimaryAddress}
                      checked={values[userAddressFormFields.isPrimaryAddress]}
                      onChange={handleChange}
                    />
                  }
                />
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={createStatus === fetchStatuses.pending}
          loadingPosition="end"
          onClick={submitForm}
        >
          {initialValue?.id ? 'Save' : 'Create'}
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
