import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { fundAssetClassFormFields } from '../../forms/fields/formFields';
import { fundAssetClassFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import { createFundAssetClass, updateFundAssetClass } from '../../redux/modules/fundAssetClass/fundAssetClass.actions';
import { fundAssetClassSelector } from '../../redux/modules/fundAssetClass/fundAssetClass.selectors';
import { FundAssetClass } from '../../types/fundAssetClass';

interface FundAssetClassDialogProps {
  open: boolean;
  initialValue: FundAssetClass | null;
  onClose: (isReload: boolean) => void;
}

export function FundAssetClassDialog({ open, initialValue, onClose }: FundAssetClassDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, values, handleChange, touched, errors, submitForm, setValues, resetForm } = useFormik({
    initialValues: {
      [fundAssetClassFormFields.nameEn]: '',
      [fundAssetClassFormFields.nameAr]: '',
      [fundAssetClassFormFields.descriptionEn]: '',
      [fundAssetClassFormFields.descriptionAr]: '',
    },
    validationSchema: fundAssetClassFormSchema,
    onSubmit: (formValues) => {
      if (initialValue?.id) {
        dispatch(updateFundAssetClass({ id: initialValue.id, ...formValues }));
      } else {
        dispatch(createFundAssetClass(formValues));
      }
    },
  });
  const { createStatus, updateStatus, error } = useSelector(fundAssetClassSelector);

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    setValues({
      nameEn: initialValue?.nameEn || '',
      nameAr: initialValue?.nameAr || '',
      descriptionEn: initialValue?.descriptionEn || '',
      descriptionAr: initialValue?.descriptionAr || '',
    });
  }, [initialValue]);

  useEffect(() => {
    if (createStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Fund Strategy created!', { variant: 'success' });
    }
    if (createStatus === fetchStatuses.rejected) {
      enqueueSnackbar('Fund Strategy creation error!', { variant: 'error' });
    }

    if (updateStatus === fetchStatuses.success) {
      onClose(true);
      enqueueSnackbar('Fund Strategy updated!', { variant: 'success' });
    }
  }, [createStatus, updateStatus]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{initialValue?.id ? 'Edit fund asset class' : 'Create a new fund asset class'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error && <Alert severity="error">{error.message}</Alert>}
          Enter the Fund Strategy name
        </DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="nameEn"
            name={fundAssetClassFormFields.nameEn}
            label="Name En"
            value={values[fundAssetClassFormFields.nameEn]}
            onChange={handleChange}
            error={touched.nameEn && !!errors.nameEn}
            helperText={touched.nameEn && errors.nameEn}
          />
          <TextField
            dir="rtl"
            margin="normal"
            required
            fullWidth
            id="nameAr"
            name={fundAssetClassFormFields.nameAr}
            label="Name Ar"
            value={values[fundAssetClassFormFields.nameAr]}
            onChange={handleChange}
            error={touched.nameAr && !!errors.nameAr}
            helperText={touched.nameAr && errors.nameAr}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            multiline
            id={fundAssetClassFormFields.descriptionEn}
            name={fundAssetClassFormFields.descriptionEn}
            label="Description En"
            value={values[fundAssetClassFormFields.descriptionEn]}
            onChange={handleChange}
            error={touched.descriptionEn && !!errors.descriptionEn}
            helperText={touched.descriptionEn && errors.descriptionEn}
          />
          <TextField
            dir="rtl"
            margin="normal"
            required
            fullWidth
            multiline
            id={fundAssetClassFormFields.descriptionAr}
            name={fundAssetClassFormFields.descriptionAr}
            label="Description Ar"
            value={values[fundAssetClassFormFields.descriptionAr]}
            onChange={handleChange}
            error={touched.descriptionAr && !!errors.descriptionAr}
            helperText={touched.descriptionAr && errors.descriptionAr}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={createStatus === fetchStatuses.pending}
          loadingPosition="end"
          onClick={submitForm}
        >
          {initialValue?.id ? 'Save' : 'Create'}
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
