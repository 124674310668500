import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Chip from '@mui/material/Chip';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Table } from '../../components/Table';
import { TableRef } from '../../components/Table/Table';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { feeBatchStatusToInfo } from '../../constants/tables/feeBatch';
import { fetchFeeBatchesByBatchId } from '../../redux/modules/feeBatch/feeBatch.actions';
import { feeBatchSelector, viewDetailsByBatchIdSelector } from '../../redux/modules/feeBatch/feeBatch.selectors';
import { FeeBatchStatus } from '../../types/feeBatch';

interface FeeBatchTabProps {
  batchId: string;
}

export function FeeBatchTab({ batchId }: FeeBatchTabProps) {
  const tableRef = useRef<TableRef>(null);
  const [columns] = useState<GridColDef[]>([
    {
      field: 'id',
      headerName: 'Id',
      width: 100,
    },
    {
      field: 'batchId',
      headerName: 'Batch Id',
      type: 'string',
      width: 350,
    },
    {
      field: 'userFeeDeduction.id',
      headerName: 'Request Id',
      valueGetter: (params) => params.row?.userFeeDeduction?.id,
      width: 120,
    },
    {
      field: 'status.name',
      headerName: 'Status',
      type: 'singleSelect',
      valueOptions: Object.values(FeeBatchStatus),
      minWidth: 200,
      flex: 1,
      valueGetter: (params) => params.row?.status?.name,
      renderCell: (params: GridRenderCellParams) => {
        const info = feeBatchStatusToInfo[params.row?.status?.name as FeeBatchStatus] || {};
        const Icon = info?.icon;

        if (!Icon) {
          return params.row?.status?.name;
        }

        return <Chip variant="outlined" color={info?.color} icon={<Icon />} label={params.row?.status?.name} />;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'date',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      type: 'date',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const { totalFeeBatches, feeBatches, fetchFeeBatchesStatus } = useSelector(viewDetailsByBatchIdSelector);
  const { executeStatus, importExecutionFileStatus } = useSelector(feeBatchSelector);
  const loading =
    fetchFeeBatchesStatus === fetchStatuses.pending ||
    importExecutionFileStatus === fetchStatuses.pending ||
    executeStatus === fetchStatuses.pending;

  useEffect(() => {
    if (importExecutionFileStatus === fetchStatuses.success || executeStatus === fetchStatuses.success) {
      tableRef.current?.reload();
    }
  }, [importExecutionFileStatus, executeStatus]);

  return (
    <Table
      ref={tableRef}
      fetchItems={fetchFeeBatchesByBatchId}
      rows={feeBatches}
      columns={columns}
      loading={loading}
      rowCount={totalFeeBatches}
      pageSizeOptions={[50, 100, 300]}
      initialPageSize={50}
      fetchItemsCustomParams={{ batchId }}
      toolbarExportProps={{
        csvOptions: { fileName: `fee-batches-${batchId}` },
        excelOptions: { fileName: `fee-batches-${batchId}` },
      }}
    />
  );
}
