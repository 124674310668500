import { createSlice } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { fetchSearchNames } from './names.actions';
import { NamesState } from './names.types';

const initialState: NamesState = {
  data: [],
  fetchStatus: fetchStatuses.idle,
  error: null,
};

const namesSlice = createSlice({
  name: 'names',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSearchNames.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(fetchSearchNames.fulfilled, (state, action) => {
      state.data = action.payload;
      state.fetchStatus = fetchStatuses.success;
    });
    builder.addCase(fetchSearchNames.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });
  },
});

export const namesReducer = namesSlice.reducer;
