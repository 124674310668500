import { PaginatedRequest } from '../types/api';
import {
  SaveUserPortfolioRequestRequest,
  CreateUserPortfolioRequestResponse,
  FetchUserPortfolioRequestsResponse,
  FetchUserPortfolioRequestResponse,
  UpdateUserPortfolioRequestRequest,
  UpdateUserPortfolioRequestResponse,
  UpdateUserPortfolioRequestStatusRequest,
  UpdateUserPortfolioRequestStatusResponse,
  FetchUserPortfolioRequestStatusOptionsResponse,
} from '../types/userPortfolioRequest';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class UserPortfolioRequestService extends ApiService {
  pathname = 'admin';

  fetchUserPortfolioRequests = async (data: PaginatedRequest) =>
    this.get<FetchUserPortfolioRequestsResponse>(`${this.pathname}/user-portfolio-request?${toQueryParams(data)}`);

  fetchUserPortfolioRequest = async (id: number) =>
    this.get<FetchUserPortfolioRequestResponse>(`${this.pathname}/user-portfolio-request/${id}`);

  deleteUserPortfolioRequest = async (id: number) =>
    this.delete<FetchUserPortfolioRequestResponse>(`${this.pathname}/user-portfolio-request/${id}`);

  saveUserPortfolioRequest = async (portfolio: SaveUserPortfolioRequestRequest) =>
    this.post<CreateUserPortfolioRequestResponse, SaveUserPortfolioRequestRequest>(
      `${this.pathname}/user-portfolio-request`,
      portfolio,
    );

  updateUserPortfolioRequest = async ({ id, ...portfolio }: UpdateUserPortfolioRequestRequest) =>
    this.put<UpdateUserPortfolioRequestResponse, Omit<UpdateUserPortfolioRequestRequest, 'id'>>(
      `${this.pathname}/user-portfolio-request/${id}`,
      portfolio,
    );

  updateUserPortfolioRequestStatus = async ({ id, status }: UpdateUserPortfolioRequestStatusRequest) =>
    this.put<UpdateUserPortfolioRequestStatusResponse, Omit<UpdateUserPortfolioRequestStatusRequest, 'id'>>(
      `${this.pathname}/user-portfolio-request/${id}/status`,
      { status },
    );

  fetchUserPortfolioRequestStatusOptions = async () =>
    this.get<FetchUserPortfolioRequestStatusOptionsResponse>(`${this.pathname}/user-portfolio-request/status/options`);
}

export const userPortfolioRequestService = new UserPortfolioRequestService();
