import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { IAsyncDialogProps } from '../../components/AsyncDialog/AsyncDialog';

export function WithdrawAmountDialog(props: IAsyncDialogProps) {
  const [amount, setAmount] = useState<number>(+props.data || 0);
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState(true);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(+event.target.value || 0);
    setTouched(true);
  };

  const onOk = () => {
    if (!error && amount) {
      return props.onOk(+amount);
    }

    return setTouched(true);
  };

  useEffect(() => {
    setAmount(+props.data || 0);
    setError(true);
    setTouched(false);
  }, [props.open]);

  useEffect(() => {
    setError(!amount);
  }, [amount]);

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>What is the amount?</DialogTitle>
      <DialogContent>
        <DialogContentText>Type the redemption amount.</DialogContentText>
        <TextField
          margin="normal"
          id="amount"
          label="Amount"
          placeholder="Enter the amount"
          variant="standard"
          type="number"
          value={amount || 0}
          onChange={onChange}
          error={touched && error}
          helperText="Amount is required"
          autoFocus
          fullWidth
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button onClick={onOk}>Withdraw</Button>
      </DialogActions>
    </Dialog>
  );
}
