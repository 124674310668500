import { createSelector } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { AnbPaymentDetails } from '../../../types/payment';
import { ReducerState } from '../../store.types';

export const paymentSelector = ({ payment }: ReducerState) => payment;
export const anbPaymentDetailsLoadingSelector = createSelector(
  paymentSelector,
  ({ fetchAnbPaymentDetailsStatus }) => fetchAnbPaymentDetailsStatus === fetchStatuses.pending,
);
export const allAnbPaymentDetailsSelector = createSelector(paymentSelector, (state) => state.anbPaymentDetails);

export const anbPaymentDetailsSelector = createSelector(
  allAnbPaymentDetailsSelector,
  (state: ReducerState, paymentId: string | null) => paymentId,
  (anbPaymentDetails: Record<string, AnbPaymentDetails>, paymentId: string | null) => {
    if (paymentId) {
      return anbPaymentDetails[paymentId];
    }

    return null;
  },
);
