import { ApiResponse } from './api';
import { FeeConfig } from './feeConfig';

export enum UserFeeStatus {
  CREATED = 'Created',
  ACTIVE = 'Active',
  CLOSED = 'Closed',
  DELETED = 'Deleted',
}

export enum UserFeeTransactionTypes {
  REGULAR_FEE_CALCULATION = 'Regular Fee Calculation',
  FULLY_PAID_MONTHLY_FEE_CALCULATION = 'Fully Paid Monthly Fee Calculation',
  FULL_REDEMPTION_FEE_CALCULATION = 'Full Redemption Fee Calculation',
  DEDUCTION = 'Deduction',
  FEE_CONFIG_UPDATE = 'Fee Config Update',
}

export interface UserFeeStatusOption {
  id: number;
  name: UserFeeStatus;
}

export interface UserFeeHistoryTransactionTypeOption {
  id: number;
  name: UserFeeTransactionTypes;
}

export type UserFeeHistory = {
  id: number;
  totalMarketValue: number;
  prevTotalFee: number;
  prevManagementFee: number;
  prevVat: number;
  totalFee: number;
  managementFee: number;
  vat: number;
  newTotalFee: number;
  newManagementFee: number;
  newVat: number;
  transactionType: UserFeeHistoryTransactionTypeOption;
  metadata: IAnyObject;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type UserFee = {
  id: number;
  feeConfig: FeeConfig;
  userId: number;
  productId: number;
  totalFee: number;
  managementFee: number;
  vat: number;
  status: UserFeeStatusOption;
  productTypeId: number;
  isDeleted: boolean;
  deletedAt: Date | string | null;
  calculatedAt: Date | string | null;
  deductedAt: Date | string | null;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type FetchUserFeeResponse = ApiResponse<UserFee>;
export type FetchUserFeesResponse = ApiResponse<{
  items: UserFee[];
  totalItems: number;
}>;
export type FetchUserFeeHistoryResponse = ApiResponse<{
  items: UserFeeHistory[];
  totalItems: number;
}>;

export type SaveUserFeeRequest = Omit<UserFee, 'id' | 'status' | 'createdAt' | 'updatedAt'> & {
  productId: number;
  userId: number;
  status: number;
  feeConfig: number;
};
export type CreateUserFeeResponse = ApiResponse<UserFee>;

export type UpdateUserFeeRequest = Omit<UserFee, 'status' | 'createdAt' | 'updatedAt'> & {
  productId: number;
  userId: number;
  status: number;
  feeConfig: number;
};
export type UpdateUserFeeResponse = ApiResponse<UserFee>;
