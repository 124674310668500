import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchFundGeographicalFocusResponse,
  FetchFundGeographicalFocusesResponse,
} from '../../../types/fundGeographicalFocus';
import {
  fetchFundGeographicalFocus,
  fetchFundGeographicalFocuses,
  resetEditFundGeographicalFocus,
  cleanFundGeographicalFocuses,
  updateFundGeographicalFocus,
  createFundGeographicalFocus,
  deleteFundGeographicalFocus,
} from './fundGeographicalFocus.actions';
import { FundGeographicalFocusState } from './fundGeographicalFocus.types';

const initialState: FundGeographicalFocusState = {
  fundGeographicalFocuses: [],
  totalFundGeographicalFocuses: 0,
  editFundGeographicalFocus: null,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  error: null,
};

const fundGeographicalFocusesSlice = createSlice({
  name: 'fundGeographicalFocus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFundGeographicalFocuses.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchFundGeographicalFocuses.fulfilled,
      (state, action: PayloadAction<FetchFundGeographicalFocusesResponse>) => {
        state.fundGeographicalFocuses = action.payload.data?.items;
        state.totalFundGeographicalFocuses = action.payload.data?.totalItems;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFundGeographicalFocuses.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchFundGeographicalFocus.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchFundGeographicalFocus.fulfilled,
      (state, action: PayloadAction<FetchFundGeographicalFocusResponse>) => {
        state.editFundGeographicalFocus = action.payload.data;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchFundGeographicalFocus.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(updateFundGeographicalFocus.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateFundGeographicalFocus.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateFundGeographicalFocus.rejected, (state, action) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(createFundGeographicalFocus.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(
      createFundGeographicalFocus.fulfilled,
      (state, action: PayloadAction<FetchFundGeographicalFocusResponse>) => {
        state.editFundGeographicalFocus = action.payload.data;
        state.createStatus = fetchStatuses.success;
      },
    );
    builder.addCase(createFundGeographicalFocus.rejected, (state, action) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(deleteFundGeographicalFocus.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteFundGeographicalFocus.fulfilled, (state) => {
      state.editFundGeographicalFocus = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteFundGeographicalFocus.rejected, (state, action) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(resetEditFundGeographicalFocus.type, (state) => {
      state.editFundGeographicalFocus = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanFundGeographicalFocuses.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const fundGeographicalFocusReducer = fundGeographicalFocusesSlice.reducer;
