import { PaginatedRequest } from '../types/api';
import { FetchOrderExecutionByOrderIdResponse, FetchOrderExecutionsResponse } from '../types/orderExecution';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class OrderExecutionService extends ApiService {
  pathname = 'admin/order-execution';

  fetchOrderExecutions = async (data: PaginatedRequest) =>
    this.get<FetchOrderExecutionsResponse>(`${this.pathname}?${toQueryParams(data)}`);

  fetchOrderExecutionByOrderId = async (orderId: string) =>
    this.get<FetchOrderExecutionByOrderIdResponse>(`${this.pathname}/order/${orderId}`);
}

export const orderExecutionService = new OrderExecutionService();
