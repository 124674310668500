import { createSelector } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { ReducerState } from '../../store.types';
import { ReportState } from './report.types';

export const reportSelector = ({ report }: ReducerState) => report;
export const systemReportByDateSelector = createSelector(
  reportSelector,
  (state: ReducerState, date: string) => date,
  (report: ReportState, date: string) => ({
    systemReport: report.systemReport[date],
    fetchStatus: report.fetchSystemCashReportStatus,
  }),
);
export const reportPortfolioOrderExecutionsSelector = createSelector(
  reportSelector,
  ({ portfolioOrderExecutions }) => portfolioOrderExecutions,
);
export const reportPortfolioOrderExecutionsLoadingSelector = createSelector(
  reportSelector,
  ({ fetchPortfolioOrderExecutionsStatus }) => fetchPortfolioOrderExecutionsStatus === fetchStatuses.pending,
);
