import { createSelector } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { ReducerState } from '../../store.types';
import { UserFeeDeductionState } from './userFeeDeduction.types';

export const userFeeDeductionSelector = ({ userFeeDeduction }: ReducerState) => userFeeDeduction;
export const userFeeDeductionLoadingSelector = createSelector(
  userFeeDeductionSelector,
  ({ fetchStatus }) => fetchStatus === fetchStatuses.pending,
);

export const userFeeDeductionByRequestIdSelector = createSelector(
  userFeeDeductionSelector,
  (state: ReducerState, requestId: string) => requestId,
  (userFeeDeduction: UserFeeDeductionState, requestId: string) => ({
    userFeeDeductions: userFeeDeduction.userFeeDeductions[requestId] || [],
    totalUserFeeDeductions: userFeeDeduction.totalUserFeeDeductions[requestId] || 0,
    fetchStatus: userFeeDeduction.fetchStatuses[requestId],
  }),
);
