import AttachmentIcon from '@mui/icons-material/Attachment';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { addBatchAttachmentFormFields } from '../../forms/fields/formFields';
import { addBatchAttachmentFormSchema } from '../../forms/validationSchema/formSchema';
import { useAppDispatch } from '../../redux/hooks';
import { uploadPrivate } from '../../redux/modules/media/media.actions';
import { mediaLoadingSelector } from '../../redux/modules/media/media.selectors';
import { createBatchAttachment, fetchBatchAttachmentsTypeOptions } from '../../redux/modules/feeBatch/feeBatch.actions';
import { feeBatchSelector } from '../../redux/modules/feeBatch/feeBatch.selectors';
import { UploadEntity, UploadMediaFileResponse } from '../../types/media';
import { BatchAttachmentType } from '../../types/feeBatch';
import { isDispatchApiSuccess } from '../../utils/api';

interface AddAttachmentDialogProps {
  batchId: string;
  open: boolean;
  onClose: (isReload: boolean) => void;
}

// Allowed Formats
const acceptFiles =
  'text/csv' +
  // .xlsx
  ',application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' +
  // .doc
  ',application/msword' +
  // .docx
  ',application/vnd.openxmlformats-officedocument.wordprocessingml.document' +
  ',application/pdf';
// Allowed Size 2 MB per each file
const fileSizeLimit = 2 * 1024 * 1024;

const mapDocumentTypeToEntity: Record<BatchAttachmentType, UploadEntity> = {
  [BatchAttachmentType.EXECUTION_REPORT_FILE]: UploadEntity.orderFile,
  [BatchAttachmentType.EXECUTION_FILE]: UploadEntity.orderFile,
  [BatchAttachmentType.CONSOLIDATE_EXECUTION_FILE]: UploadEntity.orderFile,
  [BatchAttachmentType.OTHER]: UploadEntity.other,
};

export function AddAttachmentDialog({ open, onClose, batchId }: AddAttachmentDialogProps) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    handleSubmit,
    values,
    handleChange,
    touched,
    errors,
    isValid,
    submitForm,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      [addBatchAttachmentFormFields.batchAttachmentType]: 0,
      [addBatchAttachmentFormFields.documentLink]: '',
    },
    validationSchema: addBatchAttachmentFormSchema,
    onSubmit: async (formValues) => {
      const result: any = await dispatch(
        createBatchAttachment({
          batchId,
          batchAttachmentTypeId: formValues.batchAttachmentType,
          documentLink: formValues.documentLink,
        }),
      );

      if (isDispatchApiSuccess(result)) {
        enqueueSnackbar('Attachment created!', { variant: 'success' });
      } else {
        enqueueSnackbar(`Create Attachment Error: ${result?.error?.message}`, { variant: 'error' });
      }
    },
  });
  const { batchAttachmentsTypeOptions, createBatchAttachmentStatus, error } = useSelector(feeBatchSelector);
  const isMediaLoading = useSelector(mediaLoadingSelector);
  const isLoading = createBatchAttachmentStatus === fetchStatuses.pending;

  const uploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];

    if (!file) {
      return;
    }

    if (file.size > fileSizeLimit) {
      await setFieldTouched(addBatchAttachmentFormFields.documentLink, true);
      return setFieldError(addBatchAttachmentFormFields.documentLink, 'Allowed Size 2 MB');
    }

    const batchAttachmentType = batchAttachmentsTypeOptions.find(
      (item) => item.id === values[addBatchAttachmentFormFields.batchAttachmentType],
    );

    if (!batchAttachmentType) {
      return;
    }

    const entity = mapDocumentTypeToEntity[batchAttachmentType.name];

    const result = await dispatch(uploadPrivate({ file, entity }));

    if (isDispatchApiSuccess<UploadMediaFileResponse>(result)) {
      await setFieldValue(addBatchAttachmentFormFields.documentLink, result.payload.data.filename);
    } else {
      enqueueSnackbar(`Error uploading: ${result?.error?.message}`, { variant: 'error' });
    }
  };

  useEffect(() => {
    if (open) {
      dispatch(fetchBatchAttachmentsTypeOptions());
    } else {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    if (!values[addBatchAttachmentFormFields.batchAttachmentType] && batchAttachmentsTypeOptions.length) {
      const otherType = batchAttachmentsTypeOptions.find((oat) => oat.name === BatchAttachmentType.OTHER);

      if (otherType?.id) {
        setFieldValue(addBatchAttachmentFormFields.batchAttachmentType, otherType.id);
      }
    }
  }, [batchAttachmentsTypeOptions]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Add an attachment</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error && <Alert severity="error">{error.message}</Alert>}
          Enter the action name
        </DialogContentText>
        <Grid
          onSubmit={handleSubmit}
          component="form"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={80} />
          ) : (
            <FormControl fullWidth margin="normal">
              <InputLabel id="attachment-type-label">Attachment Type</InputLabel>
              <Select
                labelId="attachment-type-label"
                name={addBatchAttachmentFormFields.batchAttachmentType}
                value={values[addBatchAttachmentFormFields.batchAttachmentType]}
                label="Attachment Type"
                onChange={handleChange}
                error={touched.batchAttachmentType && !!errors.batchAttachmentType}
              >
                {batchAttachmentsTypeOptions.map((oat) => (
                  <MenuItem key={oat.id} value={oat.id}>
                    {oat.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            disabled
            id={addBatchAttachmentFormFields.documentLink}
            name={addBatchAttachmentFormFields.documentLink}
            label="Document link"
            type="text"
            value={values[addBatchAttachmentFormFields.documentLink]}
            error={touched.documentLink && !!errors.documentLink}
            helperText={touched.documentLink && errors.documentLink}
          />
          <LoadingButton
            variant="contained"
            component="label"
            loading={isMediaLoading}
            endIcon={<AttachmentIcon />}
            disabled={!values[addBatchAttachmentFormFields.batchAttachmentType]}
          >
            Upload file
            <input hidden accept={acceptFiles} type="file" onChange={uploadFile} />
          </LoadingButton>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          type="button"
          color="primary"
          variant="contained"
          endIcon={<SaveIcon />}
          loading={isLoading}
          disabled={!isValid}
          loadingPosition="end"
          onClick={submitForm}
        >
          Create Attachment
        </LoadingButton>
        <Button color="secondary" variant="contained" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
