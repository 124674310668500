import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import {
  FetchSatelliteAccountDetailsHistoryResponse,
  FetchSatelliteAccountResponse,
  FetchSatelliteAccountsResponse,
  FetchSatelliteAccountUnitResponse,
  FetchSatelliteAccountUnitsResponse,
} from '../../../types/satelliteAccount';
import { fetchUserBankDetailsHistory } from '../userBank/userBank.actions';
import {
  fetchSatelliteAccount,
  fetchSatelliteAccounts,
  resetEditSatelliteAccount,
  cleanSatelliteAccounts,
  updateSatelliteAccount,
  createSatelliteAccountItem,
  deleteSatelliteAccount,
  resetEditSatelliteAccountUnit,
  deleteSatelliteAccountUnit,
  createSatelliteAccountItemUnit,
  updateSatelliteAccountUnit,
  fetchSatelliteAccountUnit,
  fetchSatelliteAccountUnits,
  fetchSatelliteAccountDetailsHistory,
} from './satelliteAccount.actions';
import { SatelliteAccountState } from './satelliteAccount.types';

const initialState: SatelliteAccountState = {
  satelliteAccounts: [],
  totalSatelliteAccounts: 0,
  editSatelliteAccount: null,
  satelliteAccountUnits: [],
  totalSatelliteAccountUnits: 0,
  editSatelliteAccountUnit: null,
  fetchStatus: fetchStatuses.idle,
  deleteStatus: fetchStatuses.idle,
  updateStatus: fetchStatuses.idle,
  createStatus: fetchStatuses.idle,
  fetchUnitStatus: fetchStatuses.idle,
  deleteUnitStatus: fetchStatuses.idle,
  updateUnitStatus: fetchStatuses.idle,
  createUnitStatus: fetchStatuses.idle,

  satelliteAccountDetailsHistory: [],
  totalSatelliteAccountDetailsHistory: 0,
  fetchSatelliteAccountDetailsHistoryStatus: fetchStatuses.idle,
  error: null,
};

const satelliteAccountsSlice = createSlice({
  name: 'satelliteAccounts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSatelliteAccounts.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchSatelliteAccounts.fulfilled,
      (state, satelliteAccount: PayloadAction<FetchSatelliteAccountsResponse>) => {
        state.satelliteAccounts = satelliteAccount.payload.data.items;
        state.totalSatelliteAccounts = satelliteAccount.payload.data.totalItems;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchSatelliteAccounts.rejected, (state, satelliteAccount) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = satelliteAccount.error;
    });

    builder.addCase(fetchUserBankDetailsHistory.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchSatelliteAccountDetailsHistory.fulfilled,
      (state, action: PayloadAction<FetchSatelliteAccountDetailsHistoryResponse>) => {
        state.satelliteAccountDetailsHistory = action.payload.data?.items;
        state.totalSatelliteAccountDetailsHistory = action.payload.data?.totalItems;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchUserBankDetailsHistory.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(fetchSatelliteAccountUnits.pending, (state) => {
      state.fetchUnitStatus = fetchStatuses.pending;
      state.deleteUnitStatus = fetchStatuses.idle;
      state.updateUnitStatus = fetchStatuses.idle;
      state.createUnitStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchSatelliteAccountUnits.fulfilled,
      (state, satelliteAccountUnit: PayloadAction<FetchSatelliteAccountUnitsResponse>) => {
        state.satelliteAccountUnits = satelliteAccountUnit.payload.data.items;
        state.totalSatelliteAccountUnits = satelliteAccountUnit.payload.data.totalItems;
        state.fetchUnitStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchSatelliteAccountUnits.rejected, (state, satelliteAccountUnit) => {
      state.fetchUnitStatus = fetchStatuses.rejected;
      state.error = satelliteAccountUnit.error;
    });

    builder.addCase(fetchSatelliteAccount.pending, (state) => {
      state.fetchStatus = fetchStatuses.pending;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchSatelliteAccount.fulfilled,
      (state, satelliteAccount: PayloadAction<FetchSatelliteAccountResponse>) => {
        state.editSatelliteAccount = satelliteAccount.payload.data;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchSatelliteAccount.rejected, (state, satelliteAccount) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = satelliteAccount.error;
    });

    builder.addCase(fetchSatelliteAccountUnit.pending, (state) => {
      state.fetchUnitStatus = fetchStatuses.pending;
      state.deleteUnitStatus = fetchStatuses.idle;
      state.updateUnitStatus = fetchStatuses.idle;
      state.createUnitStatus = fetchStatuses.idle;
    });
    builder.addCase(
      fetchSatelliteAccountUnit.fulfilled,
      (state, satelliteAccountUnit: PayloadAction<FetchSatelliteAccountUnitResponse>) => {
        state.editSatelliteAccountUnit = satelliteAccountUnit.payload.data;
        state.fetchUnitStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchSatelliteAccountUnit.rejected, (state, satelliteAccountUnit) => {
      state.fetchUnitStatus = fetchStatuses.rejected;
      state.error = satelliteAccountUnit.error;
    });

    builder.addCase(updateSatelliteAccount.pending, (state) => {
      state.error = null;
      state.updateStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(updateSatelliteAccount.fulfilled, (state) => {
      state.updateStatus = fetchStatuses.success;
    });
    builder.addCase(updateSatelliteAccount.rejected, (state, satelliteAccount) => {
      state.updateStatus = fetchStatuses.rejected;
      state.error = satelliteAccount.error;
    });

    builder.addCase(updateSatelliteAccountUnit.pending, (state) => {
      state.error = null;
      state.updateUnitStatus = fetchStatuses.pending;
      state.fetchUnitStatus = fetchStatuses.idle;
      state.deleteUnitStatus = fetchStatuses.idle;
      state.createUnitStatus = fetchStatuses.idle;
    });
    builder.addCase(updateSatelliteAccountUnit.fulfilled, (state) => {
      state.updateUnitStatus = fetchStatuses.success;
    });
    builder.addCase(updateSatelliteAccountUnit.rejected, (state, satelliteAccountUnit) => {
      state.updateUnitStatus = fetchStatuses.rejected;
      state.error = satelliteAccountUnit.error;
    });

    builder.addCase(createSatelliteAccountItem.pending, (state) => {
      state.error = null;
      state.createStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
    });
    builder.addCase(
      createSatelliteAccountItem.fulfilled,
      (state, satelliteAccount: PayloadAction<FetchSatelliteAccountResponse>) => {
        state.editSatelliteAccount = satelliteAccount.payload.data;
        state.createStatus = fetchStatuses.success;
      },
    );
    builder.addCase(createSatelliteAccountItem.rejected, (state, satelliteAccount) => {
      state.createStatus = fetchStatuses.rejected;
      state.error = satelliteAccount.error;
    });

    builder.addCase(createSatelliteAccountItemUnit.pending, (state) => {
      state.error = null;
      state.deleteUnitStatus = fetchStatuses.pending;
      state.fetchUnitStatus = fetchStatuses.idle;
      state.updateUnitStatus = fetchStatuses.idle;
      state.createUnitStatus = fetchStatuses.idle;
    });
    builder.addCase(
      createSatelliteAccountItemUnit.fulfilled,
      (state, satelliteAccount: PayloadAction<FetchSatelliteAccountUnitResponse>) => {
        state.editSatelliteAccountUnit = satelliteAccount.payload.data;
        state.createUnitStatus = fetchStatuses.success;
      },
    );
    builder.addCase(createSatelliteAccountItemUnit.rejected, (state, satelliteAccount) => {
      state.createUnitStatus = fetchStatuses.rejected;
      state.error = satelliteAccount.error;
    });

    builder.addCase(deleteSatelliteAccount.pending, (state) => {
      state.error = null;
      state.deleteStatus = fetchStatuses.pending;
      state.fetchStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteSatelliteAccount.fulfilled, (state) => {
      state.editSatelliteAccount = null;
      state.deleteStatus = fetchStatuses.success;
    });
    builder.addCase(deleteSatelliteAccount.rejected, (state, satelliteAccount) => {
      state.deleteStatus = fetchStatuses.rejected;
      state.error = satelliteAccount.error;
    });

    builder.addCase(deleteSatelliteAccountUnit.pending, (state) => {
      state.error = null;
      state.deleteUnitStatus = fetchStatuses.pending;
      state.fetchUnitStatus = fetchStatuses.idle;
      state.updateUnitStatus = fetchStatuses.idle;
      state.createUnitStatus = fetchStatuses.idle;
    });
    builder.addCase(deleteSatelliteAccountUnit.fulfilled, (state) => {
      state.editSatelliteAccountUnit = null;
      state.deleteUnitStatus = fetchStatuses.success;
    });
    builder.addCase(deleteSatelliteAccountUnit.rejected, (state, satelliteAccountUnit) => {
      state.deleteUnitStatus = fetchStatuses.rejected;
      state.error = satelliteAccountUnit.error;
    });

    builder.addCase(resetEditSatelliteAccount.type, (state) => {
      state.editSatelliteAccount = null;
      state.fetchStatus = fetchStatuses.idle;
      state.deleteStatus = fetchStatuses.idle;
      state.updateStatus = fetchStatuses.idle;
      state.createStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(resetEditSatelliteAccountUnit.type, (state) => {
      state.editSatelliteAccountUnit = null;
      state.fetchUnitStatus = fetchStatuses.idle;
      state.deleteUnitStatus = fetchStatuses.idle;
      state.updateUnitStatus = fetchStatuses.idle;
      state.createUnitStatus = fetchStatuses.idle;
      state.error = null;
    });

    builder.addCase(cleanSatelliteAccounts.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const satelliteAccountReducer = satelliteAccountsSlice.reducer;
