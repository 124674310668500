import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayForWorkIcon from '@mui/icons-material/PlayForWork';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ForwardIcon from '@mui/icons-material/Forward';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { AsyncDialog, TypeConfirmationDialog } from '../../components/AsyncDialog';
import { AsyncDialogRef } from '../../components/AsyncDialog/AsyncDialog';
import { TypeConfirmationDialogData } from '../../components/AsyncDialog/TypeConfirmationDialog';
import { CopyToClipboardButton } from '../../components/CopyToClipboardButton';
import { FakeButtonLink } from '../../components/FakeLink';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { useAppDispatch } from '../../redux/hooks';
import {
  orderExecutionByOrderIdSelector,
  orderExecutionSelector,
} from '../../redux/modules/orderExecution/orderExecution.selectors';
import {
  approveOrderExecutionsByOrderId,
  changeOrderExecutionsToReconciliationByOrderId,
  consolidateFundOrders,
  discardOrderExecutionsByOrderId,
  executePortfolioOrder,
  fetchFundOrdersByOrderId,
  fetchOrderExecutionsReportByOrderId,
  fetchSatelliteRequestsByOrderId,
  importConsolidateExecutionFilePortfolioOrder,
  uploadOrderAttachment,
  cancelExecutionPortfolioOrder,
  deletePortfolioOrder,
} from '../../redux/modules/portfolioOrder/portfolioOrder.actions';
import {
  portfolioOrderSelector,
  viewDetailsByOrderIdSelector,
} from '../../redux/modules/portfolioOrder/portfolioOrder.selectors';
import { ReducerState } from '../../redux/store.types';
import { locations } from '../../routes/locations';
import { OrderExecutionReportType, OrderExecutionStatus } from '../../types/orderExecution';
import {
  ConsolidateRequestStatus,
  FundOrder,
  FundOrderType,
  OrderAttachmentType,
  PortfolioOrderStatus,
} from '../../types/portfolioOrder';
import { UserPortfolioRequestType } from '../../types/userPortfolioRequest';
import { prepareOrderExecutionsReportData } from '../../utils/portfolioOrder';
import { ImportExecutionFileDialog } from './ImportExecutionFileDialog';
import { ImportReconciliationFileDialog } from './ImportReconciliationFileDialog';

const csvDivider = ','; // ';';
const fileSizeLimit = 1 * 1024 * 1024;

type PortfolioOrderActionsProps = {
  orderId: string;
  setFundOrdersKey: (v: string) => void;
  selectedFundOrders: FundOrder[];
  executionTemplateData: IAnyObject[];
  fetchPortfolioOrders: () => void;
  fetchOrderExecution: () => void;
  fetchSatelliteRequests: () => void;
  fetchConsolidateRequests: () => void;
};

export function PortfolioOrderActions(props: PortfolioOrderActionsProps) {
  const {
    orderId,
    setFundOrdersKey,
    selectedFundOrders,
    executionTemplateData,
    fetchPortfolioOrders,
    fetchOrderExecution,
    fetchSatelliteRequests,
    fetchConsolidateRequests,
  } = props;

  const { t } = useTranslation('apiError');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const asyncDialogRef = useRef<AsyncDialogRef<TypeConfirmationDialogData, boolean>>(null);
  const csvLink = useRef<CSVLink>();
  const csvOrderExecutionsReportLink = useRef<CSVLink>();
  const consolidateExecutionInputRef = React.useRef<HTMLInputElement>(null);
  const [reportData, setReportData] = useState('');
  const [openReconcileDialog, setOpenReconcileDialog] = useState(false);
  const [openImportExecutionFile, setOpenImportExecutionFile] = useState(false);
  const {
    executeStatus,
    consolidateStatus,
    importConsolidateExecutionFileStatus,
    fetchOrderExecutionsReportStatus,
    discardOrderExecutionsStatus,
    changeOrderExecutionsToReconciliationStatus,
    reconcileOrderExecutionsStatus,
    approveOrderExecutionsStatus,
    cancelExecutionStatus,
    deleteStatus,
  } = useSelector(portfolioOrderSelector);
  const { fetchOrderExecutionByOrderIdStatus } = useSelector(orderExecutionSelector);
  const orderExecution = useSelector((state: ReducerState) => orderExecutionByOrderIdSelector(state, orderId || ''));
  const {
    portfolioOrders,
    fetchFundOrdersStatus,
    fetchSatelliteAccountRequestsStatus,
    consolidateRequests,
    fetchConsolidateRequestsStatus,
  } = useSelector(viewDetailsByOrderIdSelector);
  const portfolioOrderStatus = portfolioOrders.length && portfolioOrders?.[0].status?.name;
  const consolidateStatusLoading = consolidateStatus === fetchStatuses.pending;
  const executeStatusLoading = executeStatus === fetchStatuses.pending;
  const importConsolidateExecutionFileStatusLoading = importConsolidateExecutionFileStatus === fetchStatuses.pending;
  const fetchOrderExecutionsReportStatusLoading = fetchOrderExecutionsReportStatus === fetchStatuses.pending;
  const fetchOrderExecutionStatusLoading = fetchOrderExecutionByOrderIdStatus === fetchStatuses.pending;
  const changeOrderExecutionsToReconciliationStatusLoading =
    changeOrderExecutionsToReconciliationStatus === fetchStatuses.pending;
  const approveOrderExecutionsStatusLoading = approveOrderExecutionsStatus === fetchStatuses.pending;
  const reconcileOrderExecutionsStatusLoading = reconcileOrderExecutionsStatus === fetchStatuses.pending;
  const discardOrderExecutionsStatusLoading = discardOrderExecutionsStatus === fetchStatuses.pending;
  const cancelExecutionStatusLoading = cancelExecutionStatus === fetchStatuses.pending;
  const deleteStatusLoading = deleteStatus === fetchStatuses.pending;
  const exportExecutionTemplateLoading =
    fetchFundOrdersStatus === fetchStatuses.pending || fetchSatelliteAccountRequestsStatus === fetchStatuses.pending;
  const isAnyActionLoading =
    consolidateStatusLoading ||
    executeStatusLoading ||
    importConsolidateExecutionFileStatusLoading ||
    fetchOrderExecutionsReportStatusLoading ||
    fetchOrderExecutionStatusLoading ||
    changeOrderExecutionsToReconciliationStatusLoading ||
    approveOrderExecutionsStatusLoading ||
    reconcileOrderExecutionsStatusLoading ||
    discardOrderExecutionsStatusLoading ||
    cancelExecutionStatusLoading ||
    deleteStatusLoading ||
    exportExecutionTemplateLoading;
  const isRedemption = portfolioOrders?.[0]?.userPortfolioRequest?.type?.name === UserPortfolioRequestType.REDEMPTION;
  const isRedeemAll = Boolean(portfolioOrders?.[0]?.userPortfolioRequest?.changes?.redeemAll);

  const executeOrder = async () => {
    if (orderId) {
      try {
        await dispatch(executePortfolioOrder(orderId)).unwrap();

        setFundOrdersKey(`${Date.now()}`);
        fetchPortfolioOrders();
        fetchOrderExecution();
        enqueueSnackbar('Order successfully executed!', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(`Order execution failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`, {
          variant: 'error',
        });
      }
    }
  };

  const deleteOrder = async () => {
    if (orderId) {
      const isConfirmed = await asyncDialogRef.current?.show({
        title: 'Deleting an order',
        description: (
          <>
            Please type the order id &nbsp;
            <CopyToClipboardButton value={orderId} />
            &nbsp; to confirm
          </>
        ),
        value: orderId,
      });

      if (!isConfirmed) {
        return;
      }

      try {
        await dispatch(deletePortfolioOrder(orderId)).unwrap();

        await navigate(locations.portfolioOrderList(), { replace: true });

        enqueueSnackbar('Order successfully deleted!', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(`Order deletion failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`, {
          variant: 'error',
        });
      }
    }
  };

  const consolidateOrders = async () => {
    if (selectedFundOrders.length) {
      try {
        await dispatch(consolidateFundOrders(selectedFundOrders.map((i) => i.id))).unwrap();

        fetchConsolidateRequests();
        enqueueSnackbar('Consolidate successfully!', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(`Consolidate failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`, {
          variant: 'error',
        });
      }
    }
  };

  const importExecutionFile = (): void => {
    setOpenImportExecutionFile(true);
  };

  const handleCloseImportExecutionFile = (isReload: boolean) => {
    setOpenImportExecutionFile(false);

    if (isReload) {
      fetchPortfolioOrders();
      fetchOrderExecution();
      fetchSatelliteRequests();
    }
  };

  const importConsolidateExecutionFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let file = event.target?.files?.[0];

    if (file && file.size > fileSizeLimit) {
      file = undefined;
      enqueueSnackbar('Allowed Size 1 MB!', { variant: 'error' });
    }

    if (file && orderId) {
      try {
        await dispatch(importConsolidateExecutionFilePortfolioOrder({ orderId, file })).unwrap();

        fetchConsolidateRequests();
        enqueueSnackbar('Consolidate execution file was imported successfully!', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(
          `Import consolidate execution file was failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`,
          { variant: 'error' },
        );
      }
    }

    if (consolidateExecutionInputRef.current) {
      consolidateExecutionInputRef.current.value = '';
    }
  };

  const exportExecutionTemplate = () => {
    if (csvLink.current) {
      csvLink.current.link.click();
    }
  };

  const uploadOrderExecutionsReport = async (data: string) => {
    if (orderId) {
      try {
        await dispatch(
          uploadOrderAttachment({
            orderId,
            fileName: `order-execution-report-${orderId}.csv`,
            fileData: data,
            type: OrderAttachmentType.EXECUTION_REPORT_FILE,
          }),
        ).unwrap();

        enqueueSnackbar('Portfolio Order Executions Report uploaded successfully!', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(
          `Upload Portfolio Order Executions Report failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`,
          { variant: 'error' },
        );
      }
    }
  };

  const downloadOrderExecutionsReport = async (reportType: OrderExecutionReportType) => {
    if (orderId) {
      try {
        const res = await dispatch(fetchOrderExecutionsReportByOrderId({ orderId, reportType })).unwrap();
        const data = prepareOrderExecutionsReportData(res.data, csvDivider);

        setReportData(data);

        // uploadOrderExecutionsReport(data);

        setTimeout(() => {
          if (csvOrderExecutionsReportLink.current) {
            csvOrderExecutionsReportLink.current.link.click();
          }
        }, 0);
      } catch (error: any) {
        enqueueSnackbar(
          `Download Portfolio Order Executions Report failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`,
          { variant: 'error' },
        );
      }
    }
  };

  const discardOrderExecutions = async () => {
    if (orderId) {
      try {
        await dispatch(discardOrderExecutionsByOrderId(orderId)).unwrap();

        fetchPortfolioOrders();
        fetchOrderExecution();
        fetchSatelliteRequests();

        dispatch(fetchFundOrdersByOrderId({ orderId, limit: 25 }));
        dispatch(fetchSatelliteRequestsByOrderId({ orderId }));

        enqueueSnackbar('Discard Portfolio Order Executions successfully!', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(
          `Discard Portfolio Order Executions failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`,
          { variant: 'error' },
        );
      }
    }
  };

  const cancelOrderExecution = async () => {
    if (orderId) {
      try {
        await dispatch(cancelExecutionPortfolioOrder(orderId)).unwrap();

        fetchPortfolioOrders();
        fetchOrderExecution();
        fetchSatelliteRequests();

        dispatch(fetchFundOrdersByOrderId({ orderId, limit: 25 }));
        dispatch(fetchSatelliteRequestsByOrderId({ orderId }));

        enqueueSnackbar('Cancel Portfolio Order Executions successfully!', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(
          `Cancel Portfolio Order Executions failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`,
          { variant: 'error' },
        );
      }
    }
  };

  const changeOrderExecutionsToReconciliation = async () => {
    if (orderId) {
      try {
        await dispatch(changeOrderExecutionsToReconciliationByOrderId(orderId)).unwrap();

        fetchOrderExecution();

        enqueueSnackbar('Change Order Executions To Reconciliation successfully!', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(
          `Change Order Executions To Reconciliation was failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`,
          { variant: 'error' },
        );
      }
    }
  };

  const approveOrderExecutions = async () => {
    if (orderId) {
      try {
        await dispatch(approveOrderExecutionsByOrderId(orderId)).unwrap();

        fetchOrderExecution();

        enqueueSnackbar('Order Executions Approved successfully!', { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(`Approve Order Executions was failed: ${t(error?.message)} ${JSON.stringify(error?.data)}`, {
          variant: 'error',
        });
      }
    }
  };

  const handleReconcileClose = (isReload?: boolean) => {
    if (isReload) {
      // TODO: Un-comment when use reconcile
      // fetchPortfolioOrders();
      // fetchOrderExecution();
      // fetchSatelliteRequests();
    }
    setOpenReconcileDialog(false);
  };

  const reconcileOrderExecutions = async () => {
    if (orderId) {
      setOpenReconcileDialog(true);
    }
  };

  const canConsolidate = () =>
    portfolioOrderStatus === PortfolioOrderStatus.CREATED &&
    selectedFundOrders.find((fo) => fo.type.name === FundOrderType.SUBSCRIPTION) &&
    selectedFundOrders.find((fo) => fo.type.name === FundOrderType.REDEMPTION);

  const canExecute = () =>
    orderId &&
    (!orderExecution || orderExecution?.status?.name === OrderExecutionStatus.CREATED) &&
    portfolioOrderStatus === PortfolioOrderStatus.CREATED &&
    fetchConsolidateRequestsStatus === fetchStatuses.success &&
    !consolidateRequests.find((fo) =>
      [ConsolidateRequestStatus.CREATED, ConsolidateRequestStatus.IN_PROGRESS].includes(fo.status.name),
    );

  const canDownloadOrderExecutionsReport = () =>
    orderId &&
    fetchOrderExecutionByOrderIdStatus === fetchStatuses.success &&
    orderExecution &&
    ![OrderExecutionStatus.CREATED, OrderExecutionStatus.EXECUTION].includes(orderExecution?.status?.name);

  const canDiscardOrderExecutions = () =>
    orderId &&
    fetchOrderExecutionByOrderIdStatus === fetchStatuses.success &&
    [OrderExecutionStatus.IN_REVIEW, OrderExecutionStatus.RECONCILIATION].includes(orderExecution?.status?.name);

  const canChangeOrderExecutionsToReconciliation = () =>
    orderId &&
    fetchOrderExecutionByOrderIdStatus === fetchStatuses.success &&
    [OrderExecutionStatus.IN_REVIEW].includes(orderExecution?.status?.name);

  const canReconcileOrderExecutions = () =>
    orderId &&
    fetchOrderExecutionByOrderIdStatus === fetchStatuses.success &&
    [OrderExecutionStatus.RECONCILIATION].includes(orderExecution?.status?.name);

  const canApproveOrderExecutions = () =>
    orderId &&
    fetchOrderExecutionByOrderIdStatus === fetchStatuses.success &&
    [OrderExecutionStatus.IN_REVIEW, OrderExecutionStatus.RECONCILIATION].includes(orderExecution?.status?.name);

  const canImportConsolidateExecutionFile = () =>
    orderId &&
    fetchConsolidateRequestsStatus === fetchStatuses.success &&
    consolidateRequests.find((fo) =>
      [ConsolidateRequestStatus.CREATED, ConsolidateRequestStatus.IN_PROGRESS].includes(fo.status.name),
    );

  return (
    <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" columnSpacing={2}>
      <Grid item display="flex" alignItems="center" flexDirection="row">
        {orderExecution && (
          <>
            <FakeButtonLink
              startIcon={<OpenInNewIcon />}
              href={locations.orderExecution(orderExecution.executionId)}
              width="auto"
            >
              Exec. Id:&nbsp;
            </FakeButtonLink>
            <CopyToClipboardButton value={orderExecution.executionId} />
          </>
        )}
      </Grid>
      <Grid item container flex={1} flexDirection="row" justifyContent="flex-end" alignItems="center" columnSpacing={2}>
        {canConsolidate() && (
          <Grid item>
            <Button
              id="ConsolidateButton"
              variant="outlined"
              color="primary"
              startIcon={<PlayArrowIcon />}
              onClick={consolidateOrders}
              disabled={isAnyActionLoading}
            >
              Consolidate
            </Button>
          </Grid>
        )}
        {canExecute() && (
          <>
            <Grid item>
              <Button
                id="ExecuteButton"
                variant="outlined"
                color="primary"
                startIcon={<PlayArrowIcon />}
                onClick={executeOrder}
                disabled={isAnyActionLoading}
              >
                Execute
              </Button>
            </Grid>
            <Grid item>
              <Button
                id="DeleteButton"
                variant="outlined"
                color="error"
                startIcon={<DeleteOutlineIcon />}
                onClick={deleteOrder}
                disabled={isAnyActionLoading}
              >
                Delete
              </Button>
            </Grid>
          </>
        )}
        {canImportConsolidateExecutionFile() && (
          <Grid item>
            <Button
              id="ImportConsolidateExecutionFileButton"
              variant="outlined"
              component="label"
              aria-label="import consolidate ex. file"
              color="primary"
              startIcon={<PlayForWorkIcon />}
              disabled={isAnyActionLoading}
            >
              Import consolidate ex. file
              <input
                ref={consolidateExecutionInputRef}
                hidden
                accept="text/csv"
                type="file"
                onChange={importConsolidateExecutionFile}
              />
            </Button>
          </Grid>
        )}
        {canDownloadOrderExecutionsReport() && (
          <>
            <Grid item>
              <CSVLink
                data={reportData}
                filename={`order-execution-request-report-${orderId}.csv`}
                className="hidden"
                separator={csvDivider}
                ref={csvOrderExecutionsReportLink}
                target="_blank"
              />
              <Tooltip title={orderExecution?.reportsGenerated ? 'Download' : 'Waiting Generation'} followCursor>
                <Button
                  id="DownloadOrderExecutionsRequestReportButton"
                  variant="outlined"
                  component="label"
                  aria-label="Download Report by Request"
                  color="primary"
                  startIcon={orderExecution?.reportsGenerated ? <DownloadIcon /> : <HistoryToggleOffIcon />}
                  disabled={isAnyActionLoading || !orderExecution?.reportsGenerated}
                  onClick={() => downloadOrderExecutionsReport(OrderExecutionReportType.REQUEST)}
                >
                  Download Report by Request
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              <CSVLink
                data={reportData}
                filename={`order-execution-user-report-${orderId}.csv`}
                className="hidden"
                separator={csvDivider}
                ref={csvOrderExecutionsReportLink}
                target="_blank"
              />
              <Tooltip title={orderExecution?.reportsGenerated ? 'Download' : 'Waiting Generation'} followCursor>
                <Button
                  id="DownloadOrderExecutionsUserReportButton"
                  variant="outlined"
                  component="label"
                  aria-label="Download Report by User"
                  color="primary"
                  startIcon={orderExecution?.reportsGenerated ? <DownloadIcon /> : <HistoryToggleOffIcon />}
                  disabled={isAnyActionLoading || !orderExecution?.reportsGenerated}
                  onClick={() => downloadOrderExecutionsReport(OrderExecutionReportType.USER)}
                >
                  Download Report by User
                </Button>
              </Tooltip>
            </Grid>
          </>
        )}
        {canChangeOrderExecutionsToReconciliation() && (
          <Grid item>
            <Button
              id="ToReconciliationButton"
              variant="outlined"
              component="label"
              aria-label="To Reconciliation"
              color="success"
              startIcon={<ForwardIcon />}
              disabled={isAnyActionLoading}
              onClick={changeOrderExecutionsToReconciliation}
            >
              To Reconciliation
            </Button>
          </Grid>
        )}
        {canApproveOrderExecutions() && (
          <Grid item>
            <Button
              id="ApproveButton"
              variant="outlined"
              component="label"
              aria-label="Approve"
              color="success"
              startIcon={<DoneAllIcon />}
              disabled={isAnyActionLoading}
              onClick={approveOrderExecutions}
            >
              Approve
            </Button>
          </Grid>
        )}
        {canReconcileOrderExecutions() && (
          <Grid item>
            <Button
              id="ReconcileButton"
              variant="outlined"
              component="label"
              aria-label="Reconcile"
              color="warning"
              startIcon={<ForwardIcon />}
              disabled={isAnyActionLoading}
              onClick={reconcileOrderExecutions}
            >
              Reconcile
            </Button>
            <ImportReconciliationFileDialog
              open={openReconcileDialog}
              onClose={handleReconcileClose}
              orderId={orderId}
            />
          </Grid>
        )}
        {canDiscardOrderExecutions() && (
          <Grid item>
            <Button
              id="DiscardOrderExecutionsButton"
              variant="outlined"
              component="label"
              aria-label="Discard Execution"
              color="error"
              startIcon={<RestartAltIcon />}
              disabled={isAnyActionLoading}
              onClick={discardOrderExecutions}
            >
              Discard Execution
            </Button>
          </Grid>
        )}
        {portfolioOrderStatus === PortfolioOrderStatus.IN_PROGRESS && (
          <>
            <Grid item>
              <CSVLink
                data={executionTemplateData}
                filename={`execution-template-${orderId}.csv`}
                className="hidden"
                ref={csvLink}
                target="_blank"
              />
              <Button
                id="ExportExecutionTemplateButton"
                variant="outlined"
                component="label"
                aria-label="export exec. template"
                color="primary"
                startIcon={<DownloadIcon />}
                disabled={isAnyActionLoading}
                onClick={exportExecutionTemplate}
              >
                Export exec. template
              </Button>
            </Grid>
            <Grid item>
              <Button
                id="ImportExecutionFileButton"
                variant="outlined"
                component="label"
                aria-label="import execution file"
                color="primary"
                startIcon={<PlayForWorkIcon />}
                disabled={isAnyActionLoading}
                onClick={importExecutionFile}
              >
                Import execution file
              </Button>
            </Grid>
            <Grid item>
              <Button
                id="CancelOrderExecutionsButton"
                variant="outlined"
                component="label"
                aria-label="Cancel Execution"
                color="error"
                startIcon={<CancelIcon />}
                disabled={isAnyActionLoading}
                onClick={cancelOrderExecution}
              >
                Cancel Execution
              </Button>
            </Grid>
          </>
        )}
      </Grid>

      <ImportExecutionFileDialog
        open={openImportExecutionFile}
        onClose={handleCloseImportExecutionFile}
        orderId={orderId}
        isRedeemAll={isRedeemAll}
        isRedemption={isRedemption}
      />

      <AsyncDialog ref={asyncDialogRef} dialog={TypeConfirmationDialog} />
    </Grid>
  );
}
