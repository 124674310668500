export const TABLE_COLORS = ['#A9CB85', '#7EA356', '#69C9DD', '#003E78', '#2482BE', '#66C2FE'];

export const getSaveChartColor = (itemsLength: number, index: number) => {
  if (itemsLength - index === 1 && itemsLength > 1) {
    const prevColor = getSaveChartColor(itemsLength, index - 1);
    const firstColor = getSaveChartColor(itemsLength, 0);
    const leftColors: string[] = TABLE_COLORS.filter((color) => color !== prevColor && color !== firstColor);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return leftColors.pop()!;
  }

  return TABLE_COLORS[index % TABLE_COLORS.length];
};
