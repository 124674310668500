import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid-premium';
import { GridCellExpand, Table } from '../../components/Table';
import { jsonFilterOperators } from '../../components/Table/components/filterOperators/JsonFilterOperators';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { fetchApiLogs } from '../../redux/modules/payment/payment.actions';
import { paymentSelector } from '../../redux/modules/payment/payment.selectors';
import { PaymentApiService } from '../../types/payment';

export function ApiLogsTab() {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id' },
    {
      field: 'service',
      headerName: 'Service',
      type: 'singleSelect',
      valueOptions: Object.values(PaymentApiService),
      width: 200,
    },
    {
      field: 'urlResource',
      headerName: 'Url Resource',
      width: 200,
    },
    {
      field: 'method',
      headerName: 'Method',
      type: 'singleSelect',
      valueOptions: ['GET', 'POST', 'PUT', 'DELETE'],
      width: 80,
    },
    {
      field: 'payload',
      headerName: 'Payload',
      sortable: false,
      type: 'json',
      filterOperators: jsonFilterOperators,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <GridCellExpand value={JSON.stringify(params.value, null, 2) || ''} width={params.colDef.computedWidth} />
      ),
    },
    {
      field: 'response',
      headerName: 'Response',
      sortable: false,
      type: 'json',
      filterOperators: jsonFilterOperators,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <GridCellExpand value={JSON.stringify(params.value, null, 2) || ''} width={params.colDef.computedWidth} />
      ),
    },
    {
      field: 'metadata',
      headerName: 'Metadata',
      sortable: false,
      type: 'json',
      filterOperators: jsonFilterOperators,
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <GridCellExpand value={JSON.stringify(params.value, null, 2) || ''} width={params.colDef.computedWidth} />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      sortable: false,
      type: 'dateTime',
      width: 170,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      sortable: false,
      type: 'dateTime',
      width: 170,
    },
  ]);
  const { totalPaymentApiLogs, paymentApiLogs, fetchPaymentApiLogsStatus } = useSelector(paymentSelector);

  return (
    <Table
      fetchItems={fetchApiLogs}
      rows={paymentApiLogs}
      columns={columns.current}
      loading={fetchPaymentApiLogsStatus === fetchStatuses.pending}
      rowCount={totalPaymentApiLogs}
      initialColumnVisibilityModel={{ id: false, metadata: false, updatedAt: false }}
      // fetchItemsCustomParams={{ payloadFilter: JSON.stringify({ date: '2023-05-30' }) }}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
    />
  );
}
