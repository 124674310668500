import { createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { feeAccountService } from '../../../api';
import { PaginatedRequest } from '../../../types/api';
import { CreateFeeAccountRequest, UpdateFeeAccountRequest } from '../../../types/feeAccount';

export const fetchFeeAccounts = createAsyncThunk('feeAccount/fetch-all', () => feeAccountService.fetchFeeAccounts());

export const fetchFeeAccountDetailsHistory = createAsyncThunk(
  'feeAccount/fetch-details-history',
  (data: PaginatedRequest) => feeAccountService.fetchFeeAccountDetailsHistory(data),
);

export const fetchFeeAccount = createAsyncThunk('feeAccount/get', (id: number) =>
  feeAccountService.fetchFeeAccount(id),
);

export const deleteFeeAccount = createAsyncThunk('feeAccount/delete', (id: number) =>
  feeAccountService.deleteFeeAccount(id),
);

export const createFeeAccountItem = createAsyncThunk('feeAccount/create', (feeAccount: CreateFeeAccountRequest) =>
  feeAccountService.createFeeAccount(feeAccount),
);

export const updateFeeAccount = createAsyncThunk('feeAccount/update', (feeAccount: UpdateFeeAccountRequest) =>
  feeAccountService.updateFeeAccount(feeAccount),
);

export const resetEditFeeAccount = createAction('feeAccount/reset-edit');

export const cleanFeeAccounts = createAction('feeAccount/clean');
