import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchStatuses } from '../../../constants/fetchStatuses';
import { FetchAggregatedStatisticsResponse } from '../../../types/aggregated-statistic';
import {
  fetchAggregatedStatistics,
  cleanAggregatedStatistics,
  restoreAggragatedStatistics,
} from './aggregated-statistic.actions';
import { AggregatedStatisticState } from './aggregated-statistic.types';

const initialState: AggregatedStatisticState = {
  aggregatedStatistics: [],
  totalAggregatedStatistics: 0,
  fetchStatus: fetchStatuses.idle,
  restoreStatus: fetchStatuses.idle,
  error: null,
};

const aggregatedStatisticsSlice = createSlice({
  name: 'aggregated-statistic',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAggregatedStatistics.pending, (state) => {
      state.error = null;
      state.fetchStatus = fetchStatuses.pending;
    });
    builder.addCase(
      fetchAggregatedStatistics.fulfilled,
      (state, action: PayloadAction<FetchAggregatedStatisticsResponse>) => {
        state.aggregatedStatistics = action.payload.data?.items;
        state.totalAggregatedStatistics = action.payload.data?.totalItems;
        state.fetchStatus = fetchStatuses.success;
      },
    );
    builder.addCase(fetchAggregatedStatistics.rejected, (state, action) => {
      state.fetchStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(restoreAggragatedStatistics.pending, (state) => {
      state.error = null;
      state.restoreStatus = fetchStatuses.pending;
    });
    builder.addCase(restoreAggragatedStatistics.fulfilled, (state) => {
      state.restoreStatus = fetchStatuses.success;
    });
    builder.addCase(restoreAggragatedStatistics.rejected, (state, action) => {
      state.restoreStatus = fetchStatuses.rejected;
      state.error = action.error;
    });

    builder.addCase(cleanAggregatedStatistics.type, (state) => {
      Object.assign(state, initialState);
    });
  },
});

export const aggregatedStatisticReducer = aggregatedStatisticsSlice.reducer;
