import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { CopyToClipboardButton } from '../../../../components/CopyToClipboardButton';
import { IAlpacaJournal } from '../../../../types/alpaca';
import { round2Format } from '../../../../utils/number';

interface AlpacaJournalProps {
  alpacaJournal: IAlpacaJournal;
}

export function AlpacaJournal({ alpacaJournal }: AlpacaJournalProps) {
  return (
    <Grid item container flexDirection="column" component={Paper} sx={{ p: '24px', mt: 6 }} spacing={2}>
      <Typography variant="h6">Journal</Typography>
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Id</Typography>
        </Grid>
        <Grid item xs={6}>
          <CopyToClipboardButton text={alpacaJournal.journalId} value={alpacaJournal.journalId} />
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Status</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{alpacaJournal.status}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Type</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{alpacaJournal.type}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Amount</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{round2Format(alpacaJournal.amount)}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Original Amount</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{round2Format(alpacaJournal.originalAmount)}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Actual Amount</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{round2Format(alpacaJournal.actualAmount)}</Typography>
        </Grid>
      </Grid>
      <Divider variant="inset" />
      <Grid item container>
        <Grid item xs={6}>
          <Typography variant="subtitle1">Journal Fx Rate</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">{alpacaJournal.fxRate}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
