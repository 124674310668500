export * from './configs';
export * from './asset';
export * from './benchmark';
export * from './fundGeographicalFocus';
export * from './fundManager';
export * from './fundAssetClass';
export * from './fundValuationDay';
export * from './fundValuationFrequency';
export * from './indexFund';
export * from './userGroup';
export * from './orderAttachment';
export * from './userAddress';
export * from './manualTransfer';
export * from './withdrawRequestReject';
export * from './productType';
export * from './userFeeDeductionRequestReject';
export * from './batchAttachment';

export enum formFields {
  email = 'email',
  password = 'password',
  passwordConfirmation = 'passwordConfirmation',
  gender = 'gender',
  age = 'age',
  date = 'date',
  time = 'time',
  haveChildren = 'haveChildren',
  name = 'name',
  type = 'type',
  resource = 'resource',
  token = 'token',
  otp = 'otp',
}

export enum employeeFormFields {
  email = 'email',
  password = 'password',
  passwordConfirmation = 'passwordConfirmation',
  status = 'status',
  name = 'name',
  role = 'role',
  phone = 'phone',
}

export enum portfolioFormFields {
  nameEn = 'nameEn',
  nameAr = 'nameAr',
  descriptionEn = 'descriptionEn',
  descriptionAr = 'descriptionAr',
  objectiveEn = 'objectiveEn',
  objectiveAr = 'objectiveAr',
  userCategory = 'userCategory',
  shariaaComplaint = 'shariaaComplaint',
  status = 'status',
  currency = 'currency',
  manager = 'manager',
  expectedMinimumReturn = 'expectedMinimumReturn',
  targetExpectedReturn = 'targetExpectedReturn',
  expectedMaximumReturn = 'expectedMaximumReturn',
  targetExpectedRisk = 'targetExpectedRisk',
  benchmark = 'benchmark',
  displayByGics = 'displayByGics',
  sharpRatio = 'sharpRatio',
  files = 'files',
  associatedIndexFunds = 'associatedIndexFunds',
  productType = 'productType',
}

export enum subscribedPortfolioFormFields {
  nameEn = 'nameEn',
  nameAr = 'nameAr',
  descriptionEn = 'descriptionEn',
  descriptionAr = 'descriptionAr',
  objectiveEn = 'objectiveEn',
  objectiveAr = 'objectiveAr',
  shariaaComplaint = 'shariaaComplaint',
  status = 'status',
  currency = 'currency',
  manager = 'manager',
  expectedMinimumReturn = 'expectedMinimumReturn',
  targetExpectedReturn = 'targetExpectedReturn',
  displayByGics = 'displayByGics',
  expectedMaximumReturn = 'expectedMaximumReturn',
  targetExpectedRisk = 'targetExpectedRisk',
  benchmark = 'benchmark',
  productType = 'productType',
  sharpRatio = 'sharpRatio',
  files = 'files',
  associatedIndexFunds = 'associatedIndexFunds',
}

export enum actionFormFields {
  name = 'name',
  type = 'type',
  resource = 'resource',
}

export enum roleFormFields {
  name = 'name',
}

export enum resourceFormFields {
  name = 'name',
}

export enum userCategoryFormFields {
  nameEn = 'nameEn',
  nameAr = 'nameAr',
  descriptionEn = 'descriptionEn',
  descriptionAr = 'descriptionAr',
  minScore = 'minScore',
  maxScore = 'maxScore',
}

export enum feeConfigFormFields {
  formula = 'formula',
  percentage = 'percentage',
  minSubscription = 'minSubscription',
  vat = 'vat',
  workDaysOnly = 'workDaysOnly',
  productTypeId = 'productTypeId',
  status = 'status',
  calculationPeriodicity = 'calculationPeriodicity',
  deductionPeriodicity = 'deductionPeriodicity',
}

export enum userFormFields {
  email = 'email',
  fullName = 'fullName',
  mobile = 'mobile',
  status = 'status',
  group = 'group',
}

export enum kycFormFields {
  id = 'id',
  fullNameEn = 'fullNameEn',
  firstNameEn = 'firstNameEn',
  familyNameEn = 'familyNameEn',
  fullNameAr = 'fullNameAr',
  firstNameAr = 'firstNameAr',
  familyNameAr = 'familyNameAr',
  fatherNameAr = 'fatherNameAr',
  fatherNameEn = 'fatherNameEn',
  grandFatherNameAr = 'grandFatherNameAr',
  grandFatherNameEn = 'grandFatherNameEn',
  nationalId = 'nationalId',
  type = 'type',
  nationalityEn = 'nationalityEn',
  nationalityAr = 'nationalityAr',
  country = 'country',
  email = 'email',
  status = 'status',
  phone = 'phone',
  address = 'address',
  birthDate = 'birthDate',
  gender = 'gender',
  idIssueDate = 'idIssueDate',
  maritalStatus = 'maritalStatus',
  educationLevel = 'educationLevel',
  companyName = 'companyName',
  employerAddress = 'employerAddress',
  issueLocationAr = 'issueLocationAr',
  issueLocationEn = 'issueLocationEn',
  assuranceLevel = 'assuranceLevel',
  language = 'language',
  idExpiryDate = 'idExpiryDate',
  fiveYearsFinancialSector = 'fiveYearsFinancialSector',
  fiveYearsFinancialSectorDescription = 'fiveYearsFinancialSectorDescription',
  boardDirectorsMember = 'boardDirectorsMember',
  boardDirectorsMemberDescription = 'boardDirectorsMemberDescription',
  individualWithHighProfilePoliticalRole = 'individualWithHighProfilePoliticalRole',
  individualWithHighProfilePoliticalRoleDescription = 'individualWithHighProfilePoliticalRoleDescription',
  notBeneficialOwner = 'notBeneficialOwner',
  notBeneficialOwnerDescription = 'notBeneficialOwnerDescription',
  iqamaExpiryDate = 'iqamaExpiryDate',
}

export enum rolePermissionFormFields {
  attributes = 'attributes',
  role = 'role',
  action = 'action',
}

export enum currencyFormFields {
  name = 'name',
  code = 'code',
}

export enum addUserAttachmentFormFields {
  documentType = 'documentType',
  documentLink = 'documentLink',
}

export enum addFundsFormFields {
  amount = 'amount',
  debitAccount = 'debitAccount',
}

export enum restoreAggregatedStatisticsFormFields {
  date = 'date',
}
