import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { fetchStatuses } from '../../constants/fetchStatuses';
import { fetchFeeConfigHistory } from '../../redux/modules/feeConfig/feeConfig.actions';
import { feeConfigSelector } from '../../redux/modules/feeConfig/feeConfig.selectors';
import { round2Format } from '../../utils/number';
import { GridCellExpand, Table } from '../../components/Table';

interface FeeConfigHistoryTableProps {
  id: number;
}

export function FeeConfigHistoryTable({ id }: FeeConfigHistoryTableProps) {
  const columns = useRef<GridColDef[]>([
    { field: 'id', headerName: 'Id', filterable: false },
    {
      field: 'prevFormula',
      headerName: 'Prev Formula',
      minWidth: 100,
      type: 'string',
      flex: 1,
    },
    {
      field: 'prevPercentage',
      headerName: 'Prev Percentage',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const percentageValue = round2Format(params.value);
        return <>{percentageValue}%</>;
      },
    },
    {
      field: 'prevMinSubscription',
      headerName: 'Prev Min Subscription',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'prevVat',
      headerName: 'Prev VAT',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const percentageValue = round2Format(params.value);
        return <>{percentageValue}%</>;
      },
    },
    {
      field: 'newFormula',
      headerName: 'New Formula',
      minWidth: 100,
      type: 'string',
      flex: 1,
    },
    {
      field: 'newPercentage',
      headerName: 'New Percentage',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const percentageValue = round2Format(params.value);
        return <>{percentageValue}%</>;
      },
    },
    {
      field: 'newMinSubscription',
      headerName: 'New Min Subscription',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params) => round2Format(params.value),
    },
    {
      field: 'newVat',
      headerName: 'New VAT',
      minWidth: 100,
      type: 'number',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const percentageValue = round2Format(params.value);
        return <>{percentageValue}%</>;
      },
    },
    {
      field: 'metadata',
      headerName: 'Metadata',
      sortable: false,
      width: 200,
      renderCell: (params: GridRenderCellParams) => (
        <GridCellExpand value={JSON.stringify(params.value, null, 2) || ''} width={params.colDef.computedWidth} />
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'dateTime',
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.value) {
          return '';
        }

        return params.formattedValue;
      },
    },
  ]);
  const { feeConfigHistory, totalFeeConfigHistory, fetchFeeConfigHistoryStatus } = useSelector(feeConfigSelector);

  return (
    <Table
      fetchItems={fetchFeeConfigHistory}
      rows={feeConfigHistory}
      columns={columns.current}
      loading={fetchFeeConfigHistoryStatus === fetchStatuses.pending}
      rowCount={totalFeeConfigHistory}
      disableRowGrouping
      density="compact"
      initialColumnVisibilityModel={{ id: false, metadata: false }}
      initialSortModel={[{ field: 'id', sort: 'desc' }]}
      requiredFilters={[{ field: 'feeConfig', value: `${id}`, operator: 'is', id: 0 }]}
      toolbarExportProps={{
        csvOptions: {
          fileName: `fee-config-${id}-history-${new Date().toLocaleString()}`,
        },
        excelOptions: {
          fileName: `fee-config-${id}-history-${new Date().toLocaleString()}`,
        },
      }}
      wrapperHeight={300}
    />
  );
}
