import TextField from '@mui/material/TextField';
import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IAsyncDialogProps } from './AsyncDialog';

export type TypeConfirmationDialogData = {
  title: string;
  description: React.JSX.Element | string;
  value: string;
};

export function TypeConfirmationDialog(props: IAsyncDialogProps<TypeConfirmationDialogData, boolean>) {
  const [confirmationValue, setConfirmationValue] = React.useState('');
  const [touched, setTouched] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTouched(true);
    setConfirmationValue(event.target.value);
  };

  const onConfirm = () => {
    if (confirmationValue === props.data?.value) {
      props.onOk(true);
    }
  };

  const keyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      onConfirm();
    }
  };

  useEffect(() => {
    if (!props.open) {
      setConfirmationValue('');
      setTouched(false);
    }
  }, [props.open]);

  return (
    <Dialog open={props.open} onClose={() => props.onOk(false)}>
      <DialogTitle>{props.data?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.data?.description}</DialogContentText>
        <TextField
          id="type-confirmation-value"
          fullWidth
          name="confirmation value"
          label="Confirmation"
          placeholder={props.data?.value}
          value={confirmationValue}
          onChange={handleChange}
          onKeyDown={keyDown}
          margin="normal"
          error={touched && confirmationValue !== props.data?.value}
          helperText={touched && `Please enter "${props.data?.value}" to confirm the operation`}
          required
          autoFocus
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} disabled={confirmationValue !== props.data?.value}>
          Confirm
        </Button>
        <Button onClick={() => props.onOk(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
