import { HTMLAttributeAnchorTarget } from 'react';
import * as React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { FakeLink } from './FakeLink';

interface FakeButtonLinkProps {
  color?: ButtonProps['color'];
  href: string;
  startIcon?: React.ReactNode;
  children: React.ReactNode;
  width?: string;
  target?: HTMLAttributeAnchorTarget;
}

export const FakeButtonLink = React.forwardRef<any, FakeButtonLinkProps>((props, ref) => {
  const { href, width, target = '_blank', startIcon, children, ...rest } = props;

  return (
    <FakeLink href={href} width={width}>
      <Button
        ref={ref}
        size="small"
        variant="text"
        color="primary"
        {...rest}
        startIcon={startIcon}
        onClick={() => window.open(href, target, 'noopener,noreferrer')}
      >
        {children}
      </Button>
    </FakeLink>
  );
});
