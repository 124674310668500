import { PaginatedRequest } from '../types/api';
import {
  CreateResourceRequest,
  CreateResourceResponse,
  FetchAccessResourcesResponse,
  FetchResourceResponse,
  FetchResourcesAutocompleteResponse,
  FetchResourcesResponse,
  UpdateResourceRequest,
  UpdateResourceResponse,
} from '../types/resource';
import { toQueryParams } from '../utils/request';
import { ApiService } from './common/ApiService';

class ResourceService extends ApiService {
  pathname = 'admin';

  fetchAccessResources = async () => this.get<FetchAccessResourcesResponse>(`${this.pathname}/access/resources`);

  fetchResources = async (data: PaginatedRequest) =>
    this.get<FetchResourcesResponse>(`${this.pathname}/resource?${toQueryParams(data)}`);

  fetchResourcesAutocomplete = async (term: string) =>
    this.get<FetchResourcesAutocompleteResponse>(`${this.pathname}/resource/autocomplete?term=${term}`);

  fetchResource = async (id: number) => this.get<FetchResourceResponse>(`${this.pathname}/resource/${id}`);

  deleteResource = async (id: number) => this.delete<FetchResourceResponse>(`${this.pathname}/resource/${id}`);

  createResource = async (resource: CreateResourceRequest) =>
    this.post<CreateResourceResponse, CreateResourceRequest>(`${this.pathname}/resource`, resource);

  updateResource = async ({ id, ...resource }: UpdateResourceRequest) =>
    this.put<UpdateResourceResponse, Omit<UpdateResourceRequest, 'id'>>(`${this.pathname}/resource/${id}`, resource);
}

export const resourceService = new ResourceService();
